<div class='pd-auth'>
	<div
		class='pd-auth-background'
		[ngClass]='imgClass'
	>
		<ng-container *ngIf='welcomeTitle else empty'>
			<p class='pd-auth-welcome-title'>
				Добро пожаловать в <a
				class='pd-auth-welcome-title-link'
				[routerLink]="['/']"
			><span class='pd-auth-main'>Profdepo</span><span class='pd-auth-additional'>.work</span></a></p>
		</ng-container>
		<ng-template #empty>
			<div [ngStyle]="{'width': '380px', 'height':'104px'}"></div>
		</ng-template>
	</div>
	<div class='pd-auth-container'>
		<ng-container *ngIf='!recoveryMode else backLink'>
		</ng-container>
		<ng-template #backLink>
			<ng-container *ngIf="method !== 'confirm-email' && method !=='reset-password'">
				<a
					class='pd-link pd-recovery-link new-color'
					(click)='back()'
					fitContent
				>
					<mat-icon svgIcon='arrow-left-2'></mat-icon>
					Назад
				</a>
			</ng-container>
		</ng-template>
		<router-outlet></router-outlet>
	</div>
</div>
