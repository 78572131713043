import { WorkStateValue } from '@models/work-view';
import { catchError, filter, finalize, switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import {
	ConfirmationDialogComponent,
	ConfirmationDialogType
} from '@shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { errorTitle } from '@utils/helpers/error-helpers';
import { pushFakeHistoryState } from '@utils/functions';
import { WorkExecutorMarkView } from '@models/work-executor-mark-view';
import { WorkMarksManagerDialogComponent } from '@shared/work-marks-manager-dialog/work-marks-manager-dialog.component';
import { WorkRequestState } from '@models/work-request-view';
import { Directive, EventEmitter, Output } from '@angular/core';
import { AppUserService } from '@core/services/app-user.service';
import { WorkService } from '@core/services/work.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '@core/services/loading.service';
import { WorkExecutorMarkService } from '@core/services/work-executor-mark.service';
import { WorkRequestService } from '@core/services/work-request.service';
import { WorkAbstractComponent } from '@shared/work-abstract-component/work-abstract-component';
import { NotificationService } from '@profdepo-ui/core';
import { YesNo } from '@models/enums';

@Directive()
export class WorkStateChangeManagerBase extends WorkAbstractComponent {
	@Output() changed = new EventEmitter<any>();
	unsubscribe: Subject<any> = new Subject<any>();

	constructor(
		private _appUserService: AppUserService,
		private _workService: WorkService,
		private _dialog: MatDialog,
		private _loadingService: LoadingService,
		private _notificationService: NotificationService,
		private _workExecutorMarkService: WorkExecutorMarkService,
		private _workRequestService: WorkRequestService
	) {
		super();
	}

	_goStateChange(workState: WorkStateValue): void {
		this._loadingService.changeLoading(true);
		this._workService.stateChange(this.workView.id, workState)
			.pipe(
				takeUntil(this.unsubscribe)
			)
			.subscribe(x => {
				this.changed.emit(this.workView);
				this._loadingService.changeLoading(false);
			});
	}

	_goDraft(): void {
		this._goStateChange(WorkStateValue.draft);
	}

	_goSeeking(): void {
		this._goStateChange(WorkStateValue.seeking);
	}

	_goExecutingAwait(): void {
		this._goStateChange(this.workView.isFreelance === YesNo.yes ? WorkStateValue.executingAwait : WorkStateValue.vacancyIsFinished);
	}

	_goExecuting(): void {
		this._goStateChange(WorkStateValue.executing);
	}

	_goSuccessed(): void {
		this._loadingService.changeLoading(true);
		this._dialog.open(ConfirmationDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Подтверждение',
					message: `Вы действительно хотите завершить работу ${this.workView.title}`,
					questionMark: true,
					type: ConfirmationDialogType.question,
				}
			})
			.afterClosed()
			.pipe(
				switchMap(() => this._workService.stateChange(this.workView.id, WorkStateValue.successed)),
				takeUntil(this.unsubscribe),
				catchError(err => {
					this._notificationService.showDanger('Невозможно завершить работу. Попробуйте снова')
					return of()
				}),
				finalize(() => {
					this._loadingService.changeLoading(false);
				})
			)
			.subscribe(result => {
				this.changed.emit(result);
			})
	}

	_goAssign(): void {
		pushFakeHistoryState();

		const workExecutorMarkView = new WorkExecutorMarkView();
		workExecutorMarkView.work = this.workView;

		this._dialog.open(WorkMarksManagerDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				minHeight: '300px',
				panelClass: ['pd-dialog'],
				closeOnNavigation: false,
				data: {
					title: 'Оценка специалиста',
					workView: workExecutorMarkView.work,
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
				finalize(() => {
					this.changed.emit(this.workView);
					this._loadingService.changeLoading(false);
				})
			)
			.subscribe({
				next: (data) => {
					this.changed.emit(this.workView);
					this._loadingService.changeLoading(false);
				},
				error: (err) => {
					this._notificationService.showDanger(errorTitle(err));
				}
			});
	}

	_goCancelByManager(): void {
		const workRequest = this.workView.requests
			.find(x => x.state === WorkRequestState.accepted);

		this._dialog.open(ConfirmationDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Подтверждение',
					message: 'Вы действительно хотите отказаться от выполнения работы назначенным специалистом',
					value: workRequest.executor.title,
					questionMark: true,
					type: ConfirmationDialogType.question,
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
				switchMap(() => this._workRequestService.cancelByClient(workRequest.id)
					.pipe(
						takeUntil(this.unsubscribe)
					)
				)
			)
			.subscribe({
				next: (data) => {
					this.changed.emit(this.workView);
				},
				error: (err) => {
					this._notificationService.showDanger(errorTitle(err));
				}
			});
	}
}
