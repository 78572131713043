import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

//Проверка на совпадении паролей, применяется для Form
export const checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
	const passwordControl = group.get('password') as FormControl;
	const confirmControl = group.get('passwordConfirm') as FormControl;
	if (passwordControl.value !== confirmControl.value) {
		passwordControl.setErrors({ ...passwordControl.errors, notSame: true }, {emitEvent: true});
		confirmControl.setErrors({ ...confirmControl.errors, notSame: true }, {emitEvent: true});
		return { notSame: true }
	}

	if (passwordControl.errors?.notSame) {
		const { notSame, ...errors } = passwordControl.errors
		passwordControl.setErrors(Object.keys(errors).length > 0 ? errors : null, { emitEvent: true })
	} else {
		passwordControl.setErrors(passwordControl.errors, { emitEvent: true });
	}

	if (confirmControl.errors?.notSame) {
		const { notSame, ...errors } = confirmControl.errors
		confirmControl.setErrors(Object.keys(errors).length > 0 ? errors : null, { emitEvent: true })
	} else {
		confirmControl.setErrors(confirmControl.errors, { emitEvent: true });
	}

	return null;
}
