<ng-container *ngIf="!(loadingData | async); else isLoading">
  <ng-container *ngIf="{ data: dataSource.data | async, count: dataSource.count | async } as both">
    <ng-container *ngIf="both.data.length">
      <button
        *ngIf="!isMobile"
        mat-icon-button
        class="pd-carousel-cards-button prev"
        [disabled]="carousel.index === 0"
        (click)="carousel.prev()">
        <mat-icon class="pd-icon" [svgIcon]="'additional-chevron-left'"></mat-icon>
      </button>
      <tui-carousel
        class="pd-carousel-cards"
        [itemsCount]="columnsCount"
        (indexChange)="onSwipe(carousel, both.data)"
        #carousel>
        <ng-container *ngFor="let element of both.data | slice: 0 : (pageIndex + 1) * pageSize; let i = index">
          <app-work-card-short *tuiItem [workView]="element">
          </app-work-card-short>
        </ng-container>
      </tui-carousel>
      <button
        *ngIf="!isMobile"
        mat-icon-button
        class="pd-carousel-cards-button next"
        [disabled]="isNextButtonDisabled(carousel, both.data)"
        (click)="goNextPage(carousel, both.data)">
        <mat-icon class="pd-icon" [svgIcon]="'additional-chevron-right'"></mat-icon>
      </button>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #isLoading>
  <div class="pd-basis-card-empty">
    <mat-spinner [diameter]="70" [strokeWidth]="5"></mat-spinner>
  </div>
</ng-template>
