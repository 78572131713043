<mat-form-field
	class='pd-input-form-field'
	appearance='fill'
	hideRequiredMarker
>
	<mat-icon
		*ngIf="iconPosition === 'left' && icon"
		class='pd-field-prefix-icon'
		[svgIcon]='icon'
		matPrefix
	></mat-icon>
	<mat-label>{{label}}</mat-label>
	<input
	    [formControl]='inputControl'
		[matAutocomplete]='auto'
		(blur)='onTouchedCallback()'
		(keydown.enter)='$event.preventDefault();'
		matInput
		[maxlength]="100"
	>
	<mat-icon
		*ngIf="iconPosition === 'right' && icon"
		class='pd-field-prefix-icon'
		[svgIcon]='icon'
		matPrefix
	></mat-icon>
	<mat-error>
		<ng-content select='mat-error'>
		</ng-content>
	</mat-error>
</mat-form-field>
<mat-autocomplete #auto='matAutocomplete'>
	<mat-option
		class='option'
		*ngFor='let option of options$ | async'
		(click)='addChip(option)'
		[value]='getChip(option, optionLabel)'
	>
		{{option[optionLabel] ?? option}}
	</mat-option>
</mat-autocomplete>

<ng-content>
</ng-content>

<app-chips-list>
	<app-chip
		*ngFor='let chip of selectedValues'
		(deleteChip)='deleteChip(isPrimitive(chip) ? chip : chip[optionKey])'
		[chipData]='getChip(chip, optionLabel)'
	>

	</app-chip>
</app-chips-list>
