import { Injectable } from '@angular/core';
import { AbstractModelService } from '@core/services/abstract-model.service';
import { HttpClient } from '@angular/common/http';
import { SubCategoryView } from '@models/sub-category-view';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class SubCategoryService {
	private subCategoriesUrl = 'api/SubCategories';

	constructor(private http: HttpClient, private modelService: AbstractModelService) {
	}

	/**
	 * Преобразование string в Date после десериализации
	 * @param subCategoryView подкатегория
	 */
	public static parseDates(subCategoryView: SubCategoryView): SubCategoryView {
		subCategoryView.createTime = new Date(subCategoryView.createTime);
		subCategoryView.modifyTime = new Date(subCategoryView.modifyTime);
		return subCategoryView;
	}

	all(): Observable<SubCategoryView[]> {
		const url = `${this.subCategoriesUrl}/All`;
		return this.http.get<SubCategoryView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => SubCategoryService.parseDates(x))),
			);
	}

	/**
	 * Возвращает первые 50 типов работ
	 */
	firstFifty(): Observable<SubCategoryView[]> {
		const url = `${this.subCategoriesUrl}/FirstFifty`;
		return this.http.get<SubCategoryView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => SubCategoryService.parseDates(x))),
			);
	}

	filter(query: string): Observable<SubCategoryView[]> {
		const url = `${this.subCategoriesUrl}/Filter`;
		return this.http.post<SubCategoryView[]>(url, { filter: query }, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => SubCategoryService.parseDates(x))),
			);
	}


}
