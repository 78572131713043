import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AggregatorService } from '@core/services/aggregator.service';
import { AppUserService } from '@core/services/app-user.service';
import { AuthService } from '@core/services/auth.service';
import { MaskitoOptions } from '@maskito/core';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { SupportDialogComponent } from '@public/support-dialog/support-dialog.component';
import { PhoneRegistrationRequest } from '@requests/registration-request';
import { personNamesRegEx, phoneNumberMask } from '@utils/constants';
import { filloutFormError } from '@utils/form-helper';
import { pushFakeHistoryState } from '@utils/functions';
import { AppUserRoleHintHelper } from '@utils/helpers/app-user-role-hint-helper';
import { errorTitle } from '@utils/helpers/error-helpers';
import { WhitespaceValidator } from '@utils/helpers/validator-helpers';
import { filter, Observable, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'pdw-job-search-engeneering-landing',
  templateUrl: './job-search-engeneering-landing.component.html',
  styleUrls: ['./job-search-engeneering-landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobSearchEngeneeringLandingComponent implements OnInit {
	private readonly dialog = inject(MatDialog);
	private readonly notificationService = inject(NotificationService);
	private readonly appUserService = inject(AppUserService);
	private readonly aggregatorService = inject(AggregatorService);
	private readonly destroy$ = inject(DestroyService);
	private readonly router = inject(Router);
	private readonly authService = inject(AuthService);
	private appUserRoleHintHelper: AppUserRoleHintHelper;

	public file: File = null;
	public jobSearchForm: FormGroup;
	public jobRegistrationForm: FormGroup;
	public method: string;
	public cities$: Observable<string[]>;
	public readonly maskitoOptions: MaskitoOptions = {
		mask: phoneNumberMask,
	};

	ngOnInit(): void {
		this.jobSearchForm = new FormGroup({
			firstName: new FormControl<string>("", [
				Validators.required,
				Validators.maxLength(40),
				Validators.minLength(2),
				Validators.pattern(personNamesRegEx),
				WhitespaceValidator,
			]),
			phoneNumber: new FormControl<string>("", [Validators.required, Validators.minLength(18)]),
		});

		this.jobRegistrationForm = new FormGroup({
			firstName: new FormControl<string>("", [
				Validators.required,
				Validators.maxLength(40),
				Validators.minLength(2),
				Validators.pattern(personNamesRegEx),
				WhitespaceValidator,
			]),
			profession: new FormControl<string>("", [
				Validators.required,
				Validators.maxLength(100),
				Validators.minLength(2),
				Validators.pattern(personNamesRegEx),
				WhitespaceValidator,
			]),
			city: new FormControl<string>("", [
				Validators.required,
				Validators.pattern(personNamesRegEx),
				WhitespaceValidator,
			]),
			file: new FormControl<File>(null, [Validators.required]),
			phoneNumber: new FormControl<string>("", [Validators.required, Validators.minLength(18)]),
		});
		this.cities$ = this.citiesFormControl.valueChanges.pipe(
			switchMap((value) => {
				return this.aggregatorService.filterCity(value);
			})
		);
	}

	onChangeFile(event: any) {
		this.file = event.target.files[0];
	}

	get jobSearchFormFirstNameFormControl(): FormControl {
		return this.jobSearchForm.get("firstName") as FormControl;
	}

	get jobSearchFormPhoneNumberControl(): FormControl {
		return this.jobSearchForm.get("phoneNumber") as FormControl;
	}

	get jobRegistrationFormPhoneNumberControl(): FormControl {
		return this.jobRegistrationForm.get("phoneNumber") as FormControl;
	}

	get jobRegistrationFormFirstNameControl(): FormControl {
		return this.jobRegistrationForm.get("firstName") as FormControl;
	}

	get professionFormControl(): FormControl {
		return this.jobRegistrationForm.get("profession") as FormControl;
	}

	get citiesFormControl(): FormControl {
		return this.jobRegistrationForm.get("city") as FormControl;
	}

	onSubmit({ value, valid }): void {
		if (valid) {
			const phoneNumber = value.phoneNumber.replace(/\D/g, "");
			const formattedPhoneNumber = `+7${phoneNumber.slice(1)}`;
			const formValue = {
				phone: formattedPhoneNumber,
				firstName: value.firstName,
			};

			this.appUserService
				.phoneNumberRegistration({
					...formValue,
					type: 1,
				} as PhoneRegistrationRequest)
				.pipe(
					switchMap(() => {
						return this.authService.loginPhone({ phoneNumber: formattedPhoneNumber }).pipe(
							switchMap(() =>
								this.appUserService.getActiveUserView().pipe(
									filter((x) => x !== null),
									takeUntil(this.destroy$)
								)
							)
						);
					})
				)
				.subscribe({
					next: (appUserView) => {
						this.appUserRoleHintHelper = new AppUserRoleHintHelper(this.appUserService);
						localStorage.removeItem("redirectTo");

						localStorage.removeItem("beforeAuth");

						this.router
							.navigate(["/careerConsultant"], {
								state: { afterRegistration: true },
							})
							.then(this.appUserRoleHintHelper.initAfterAuth);
					},
					error: (err) => {
						if (err instanceof HttpErrorResponse) {
							if (err.status === 400) {
								filloutFormError(this.jobSearchForm, err);
								this.jobSearchFormPhoneNumberControl.setErrors({ serverError: err.error });
							} else {
								this.notificationService.showDanger(errorTitle(err));
							}
						}
					},
				});
		}
	}

	onSubmitTwo({ value, valid }): void {
		if (valid) {
			const phoneNumber = value.phoneNumber.replace(/\D/g, "");
			const formattedPhoneNumber = `+7${phoneNumber.slice(1)}`;
			const formValue = {
				phone: formattedPhoneNumber,
				firstName: value.firstName,
				profession: value.profession,
				city: value.city,
			};
			this.appUserService
				.phoneNumberRegistration({
					...formValue,
					type: 1,
				} as PhoneRegistrationRequest)
				.pipe(
					switchMap(() => {
						return this.authService.loginPhone({ phoneNumber: formattedPhoneNumber }).pipe(
							switchMap(() =>
								this.appUserService.getActiveUserView().pipe(
									filter((x) => x !== null),
									takeUntil(this.destroy$)
								)
							)
						);
					})
				)
				.subscribe({
					next: (appUserView) => {
						this.appUserRoleHintHelper = new AppUserRoleHintHelper(this.appUserService);
						localStorage.removeItem("redirectTo");

						localStorage.removeItem("beforeAuth");

						this.router
							.navigate(["/careerConsultantResult"], {
								state: {
									city: this.jobRegistrationForm.value.city,
									profession: this.jobRegistrationForm.value.profession,
									file: this.file,
									afterRegistration: true,
								},
							})
							.then(this.appUserRoleHintHelper.initAfterAuth);
					},
					error: (err) => {
						if (err instanceof HttpErrorResponse) {
							if (err.status === 400) {
								filloutFormError(this.jobRegistrationForm, err);
								this.jobRegistrationFormPhoneNumberControl.setErrors({ serverError: err.error });
							} else {
								this.notificationService.showDanger(errorTitle(err));
							}
						}
					},
				});
		}
	}
	goSupport() {
		pushFakeHistoryState();
		this.dialog
			.open(SupportDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: "581px",
				minHeight: "320px",
				panelClass: ["pd-dialog"],
				data: {
					title: "Поддержка",
				},
			})
			.afterClosed()
			.pipe(filter((result) => result))
			.subscribe({
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				},
			});
	}
}
