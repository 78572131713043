import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'educationAdditional'
})
export class EducationAdditionalPipe extends AbstractModelPipe implements PipeTransform {
  properties = [
    {name: 'name', title: 'Проводившая организация', hint: 'Проводившая организация'},
    {name: 'sector', title: 'Программа, тема', hint: 'Программа'},
    {name: 'speciality', title: 'Назначение', hint: 'Назначение'},
    {name: 'year', title: 'Год окончания', hint: 'Год окончания обучения'},
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
  allProperties() {
    return this.properties;
  }
}
