import { EventEmitter, inject, Injectable } from '@angular/core';
import { AbstractModelService } from '@core/services/abstract-model.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WorkSpecTag, WorkStateValue, WorkView } from '@models/work-view';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { SortDirection } from '@angular/material/sort';
import { WorkStateView } from '@models/work-state-view';
import { FiltersService } from '@core/services/filters.service';
import { WorkViewShort } from '@models/work-view-short';
import { YesNo } from '@models/enums';

export class WorksFilter {
	any: string | null = null;
	specTag: WorkSpecTag = WorkSpecTag.all;
	states: WorkStateValue[] | null = null;
	planStartDate: string = null;
	duration: string = null;
	planCost: string = null;
	planEndDate: string = null;
	dates: string = null;
	isFreelance: boolean | YesNo = YesNo.none;
	workFormatIds: number[] | null = null;
	workTypeFilters: number[] = null;
	companies: number[] | null = null;
	categories: number[] | null = [];
	subCategories: number[] | null = [];
	cityId: number | null = 0;
}

@Injectable({
	providedIn: 'root'
})
export class WorkService {
	private worksUrl = 'api/Works';
	private worksHistoryUrl = 'api/WorkHistories/WorkHistory';
	private workViewCache: { id: number | string, observable: Observable<WorkView> }[] = [];
	private currentWorkViewSource = new BehaviorSubject<WorkView>(null);
	public currentCompanyHasWorks = new BehaviorSubject<boolean>(false);
	public needReloadId: number | null = null;
	public clearCacheEmitter = new EventEmitter<any>();
	private filtersService = inject(FiltersService);

	/**
	 * Флаг, после создания работы нужно перезагрузить карточки
	 */
	shouldReload = new BehaviorSubject<boolean>(false);

	constructor(private http: HttpClient, private modelService: AbstractModelService) {

	}

	/**
	 * Возвращает активное юр. лицо
	 */
	currentWorkView = this.currentWorkViewSource.asObservable();

	/**
	 * Делает активным указанное работу
	 * @param id идентификатор работы
	 */
	changeCurrentWorkView(id: string | number): Observable<WorkView> {
		if (id) {
			return this.getWorkView(id)
				.pipe(
					tap(workView => this.currentWorkViewSource.next(workView))
				);
		} else {
			console.warn('Invalid type of id argument in changeCurrentWork', id);
			return of(null);
		}
	}

	/**
	 * Запрос работы в состоянии history
	 * @param id идентификатор history
	 */
	getHistoryWorkView(id: string | number): Observable<WorkView> {
		const url = `${this.worksHistoryUrl}/${id}`;
		return this.http.get<WorkView>(url, this.modelService.httpOptions)
			.pipe(
				map(x => WorkService.parseDates(x)),
			)
	}

	vacancyCreate(vacancy: WorkView): Observable<WorkView> {
		return this.http.post<WorkView>(`${this.worksUrl}/VacancyCreate`, vacancy);
	}

	freelanceCreate(freelance: WorkView): Observable<WorkView> {
		return this.http.post<WorkView>(`${this.worksUrl}/FreelanceCreate`, freelance);
	}

	vacancyUpdate(id: string | number, vacancy: WorkView): Observable<WorkView> {
		return this.http.put<WorkView>(`${this.worksUrl}/VacancyUpdate/${id}`, vacancy);
	}

	freelanceUpdate(id: string | number, freelance: WorkView): Observable<WorkView> {
		return this.http.put<WorkView>(`${this.worksUrl}/FreelanceUpdate/${id}`, freelance);
	}

	/**
	 * Преобразование string в Date после десериализации
	 * @param workView работа
	 */
	public static parseDates(workView: WorkView): WorkView {
		if (workView.startDate) {
			workView.startDate = new Date(workView.startDate);
		}
		if (workView.endDate) {
			workView.endDate = new Date(workView.endDate);
		}

		if (workView.states?.length) {
			workView.states.map(x => {
				x.createTime = new Date(x.createTime);
			});
		}

		if (workView.timed) {
			workView.timed.start = new Date(workView.timed.start);
		}
		workView.createTime = new Date(workView.createTime);
		workView.modifyTime = new Date(workView.modifyTime);
		return workView;
	}

	/**
	 * Загружает, кэширует и возвращает работу
	 * @param id идентификатор работы для загрузки данных с сервера или получения его из массива кэша
	 */
	getWorkView(id: number | string): Observable<WorkView> {
		if (id) {
			if (!this.workViewCache || !this.workViewCache.find(x => x.id === id)) {
				const url = `${this.worksUrl}/${id}`;
				this.workViewCache.push({
					id,
					observable: this.http.get<WorkView>(url, this.modelService.httpOptions)
						.pipe(
							takeUntil(this.clearCacheEmitter),
							map(x => WorkService.parseDates(x)),
							shareReplay(1)
						)
				});
			}
			return this.workViewCache.find(x => x.id === id).observable;
		} else {
			console.warn('Invalid type of id argument in getWorkView', id);
			return of(null);
		}
	}

	allCountGet(query: HttpParams | { [p: string]: string | number }): Observable<number> {
		return this.http.get<number>(`${this.worksUrl}/allCountGet`, {
			params: query
		});
	}

	vacanciesInSeeking(params: string): Observable<Array<WorkViewShort>> {
		if (params) {
			return this.http.get<Array<WorkViewShort>>(`${this.worksUrl}/VacanciesInSeeking?${params.replace('%2C', ',')}`)
		}
		return this.http.get<Array<WorkViewShort>>(`${this.worksUrl}/VacanciesInSeeking`)
	}

	/**
	 * Очищает кэш с указанной работой
	 * @param id идентификатор работы для очистки записи в кэше
	 */
	clearWorkView(id: number): void {
		if (id && typeof id === 'number') {
			if (this.workViewCache) {
				const index = this.workViewCache.findIndex(x => x.id === id);
				if (index >= 0) {
					this.workViewCache.splice(index, 1);
				}
			}
		} else {
			console.warn('Invalid type of id argument in clearWorkView', id);
		}
	}

	/**
	 * Очищает кэш работ
	 */
	clearWorkViews(): void {
		if (this.workViewCache) {
			this.clearCacheEmitter.emit('clearCache');
			this.workViewCache = [];
		} else {
			console.warn('workViewCache is empty');
		}
	}

	/**
	 * Добавление новой работы
	 * @param workView работа
	 */
	add(workView: WorkView): Observable<WorkView> {

		return this.http.post<WorkView>(this.worksUrl, workView, this.modelService.httpOptions)
			.pipe(
				map(x => WorkService.parseDates(x)),
			);
	}

	/**
	 * Изменение существующей работы
	 * @param workView работа
	 */
	update(workView: WorkView): Observable<WorkView> {
		const url = `${this.worksUrl}/${workView.id}`;
		return this.http.put<WorkView>(url, workView, this.modelService.httpOptions)
			.pipe(
				map(x => WorkService.parseDates(x)),
				tap(x => {
					this.clearWorkView(x.id);
				})
			);
	}

	delete(id: number): Observable<any> {
		const url = `${this.worksUrl}/${id}`;
		return this.http.delete<any>(url, this.modelService.httpOptions)
			.pipe(
				tap(() => this.clearWorkView(id))
			);
	}

	call(method: string, workView: WorkView): Observable<WorkView> {
		if (method === 'create' || method === 'replace') {
			return this.freelanceCreate(workView);
		}
		return this.freelanceUpdate(workView.id, workView);
	}

	private prepareFilter(worksFilter: WorksFilter): WorksFilter {
		if (!worksFilter) {
			return { ...new WorksFilter(), any: '', isFreelance: this.filtersService.isFreelance$.value };
		}
		const allowedFilters = new Set([
			'isFreelance',
			'hardSkillsIds',
			'states',
			'planStartDate',
			'planEndDate',
			'companies',
			'specTag',
			'duration',
			'cityId',
			'skillIds',
			'planCost',
			'workFormatIds',
			'ratingMoreThan',
			'workTypeFilters',
			'subCategories',
			'categories',
			'any'
		])
		Object.keys(worksFilter).forEach(key => {
			const isFilterAvailable = !allowedFilters.has(key) && worksFilter[key]
			if (isFilterAvailable && !(worksFilter[key]?.endsWith('%') && worksFilter[key]?.startsWith('%'))) {
				worksFilter[key] = '%' + worksFilter[key] + '%';
			}
		});
		return worksFilter;
	}

	private dataSource(url: string,
					   filter: WorksFilter = null,
					   sort = null,
					   pageIndex = 0,
					   pageSize = 0): Observable<WorkView[]> {
		return this.http.post<WorkView[]>(url, this.prepareFilter(filter), {
				params: new HttpParams()
					.set('sort', sort)
					.set('pageIndex', pageIndex.toString())
					.set('pageSize', pageSize.toString()),
				headers: this.modelService.httpOptions.headers
			})
			.pipe(
				map(xx => Array.isArray(xx) ? xx.map(x => WorkService.parseDates(x)) : xx)
			);
	}

	private getDataSource(url: string,
					   filter: WorksFilter = null,
					   sort = null,
					   pageIndex = 0,
					   pageSize = 0,
					   companies = 0
					   ): Observable<WorkView[]> {
		return this.http.get<WorkView[]>(url, {
				params: new HttpParams()
					.set('sort', sort)
					.set('pageIndex', pageIndex.toString())
					.set('pageSize', pageSize.toString())
					.set("companies", companies)
					,
				headers: this.modelService.httpOptions.headers
			})
			.pipe(
				map(xx => Array.isArray(xx) ? xx.map(x => WorkService.parseDates(x)) : xx)
			);
	}

	private dataSourceShort(url: string,
							filter: WorksFilter = null,
							sort = null,
							pageIndex = 0,
							pageSize = 0): Observable<WorkViewShort[]> {
		return this.http.post<WorkViewShort[]>(url, this.prepareFilter(filter), {
			params: new HttpParams()
				.set('sort', sort)
				.set('pageIndex', pageIndex.toString())
				.set('pageSize', pageSize.toString()),
			headers: this.modelService.httpOptions.headers
		})
	}

	private dataSourceDropOne(url: string,
							  filter: WorksFilter = null,
							  sort = null,
							  pageIndex = 0,
							  pageSize = 0,
							  dropOne = false): Observable<WorkView[]> {
		return this.http.post<WorkView[]>(url, this.prepareFilter(filter), {
				params: new HttpParams()
					.set('sort', sort)
					.set('pageIndex', pageIndex.toString())
					.set('pageSize', pageSize.toString())
					.set('dropOne', dropOne),
				headers: this.modelService.httpOptions.headers
			})
			.pipe(
				map(xx => Array.isArray(xx) ? xx.map(x => WorkService.parseDates(x)) : xx)
			);
	}

	private dataSourceCount(url: string, filter: WorksFilter = null): Observable<number> {
		return this.http.post<number>(url, this.prepareFilter(filter), this.modelService.httpOptions);
	}

	/**
	 * Возвращает список всех работ, соответствующих критериям фильтра
	 * @param filter
	 * @param sort
	 * @param pageIndex
	 * @param pageSize
	 */
	all(filter: WorksFilter,
		sort = null,
		pageIndex = 0,
		pageSize = 0,
		companies?
		): Observable<WorkView[]> {
		const url = `${this.worksUrl}/VacanciesInSeeking`;
		return this.getDataSource(url, filter, sort, pageIndex, pageSize, companies);
	}

	allShort(filter: WorksFilter,
			 sort = null,
			 pageIndex = 0,
			 pageSize = 0): Observable<WorkViewShort[]> {
		const url = `${this.worksUrl}/AllShort`;
		return this.dataSourceShort(url, filter, sort, pageIndex, pageSize);
	}

	allCount(filter: any): Observable<number> {
		const url = `${this.worksUrl}/AllCount`;
		return this.dataSourceCount(url, filter);
	}

	/**
	 * Возвращает список всех работ компаний, в которых пользователь является менеджером, соответствующих критериям фильтра
	 * @param filter
	 * @param sort
	 * @param pageIndex
	 * @param pageSize
	 */
	manager(filter: WorksFilter,
			sort = null,
			pageIndex = 0,
			pageSize = 0): Observable<WorkView[] | any> {
		const url = `${this.worksUrl}/Manager`;
		return this.dataSource(url, filter, sort, pageIndex, pageSize);
	}


	localManager(filter: WorksFilter,
				 sort = null,
				 pageIndex = 0,
				 pageSize = 0): Observable<WorkView[] | any> {
		return this.http.post<WorkView[]>(`${this.worksUrl}/Manager`, filter, {
			params: new HttpParams()
				.set('sort', sort)
				.set('pageIndex', pageIndex.toString())
				.set('pageSize', pageSize.toString()),
			headers: this.modelService.httpOptions.headers
		}).pipe(
			map(xx => Array.isArray(xx) ? xx.map(x => WorkService.parseDates(x)) : xx)
		);
	}

	managerCount(filter: WorksFilter): Observable<number> {
		const url = `${this.worksUrl}/ManagerCount`;
		return this.dataSourceCount(url, filter);
	}

	/**
	 * Возвращает список всех работ, в которых активный пользователь является менеджером или владельцем, и соответствующих критериям фильтра
	 * @param filter
	 * @param sort
	 * @param pageIndex
	 * @param pageSize
	 */
	available(filter: WorksFilter,
			  sort = null,
			  pageIndex = 0,
			  pageSize = 0): Observable<WorkView[]> {
		const url = `${this.worksUrl}/Available`;
		return this.dataSource(url, filter, sort, pageIndex, pageSize);
	}

	availableCount(filter: WorksFilter): Observable<number> {
		const url = `${this.worksUrl}/AvailableCount`;
		return this.dataSourceCount(url, filter);
	}

	/**
	 * Возвращает список всех работ, соответствующих критериям фильтра
	 * @param id идентификатор организации
	 * @param filter
	 * @param sort
	 * @param pageIndex
	 * @param pageSize
	 * @param dropOne
	 */
	company(id: number,
			filter: WorksFilter,
			sort = null,
			pageIndex = 0,
			pageSize = 0,
			dropOne = false): Observable<WorkView[]> {
		const url = `${this.worksUrl}/Company/${id}`;
		return this.dataSourceDropOne(url, filter, sort, pageIndex, pageSize, dropOne);
	}

	companyCount(id: number, filter: WorksFilter): Observable<number> {
		const url = `${this.worksUrl}/CompanyCount/${id}`;
		return this.dataSourceCount(url, filter);
	}

	/**
	 * Возвращает список оценок специалиста
	 * @param appUserId идентификтор специалиста
	 * @param categoryId идентификатор категории
	 * @param pageIndex
	 * @param pageSize
	 */
	ofUserCategory(appUserId: string, categoryId: number, pageIndex = 0, pageSize = 0): Observable<WorkView[]> {
		const url = `${this.worksUrl}/ofUserCategory/${appUserId}/${categoryId}`;
		return this.http.post<WorkView[]>(url, null, {
			params: new HttpParams()
				.set('pageIndex', pageIndex.toString())
				.set('pageSize', pageSize.toString()),
			headers: this.modelService.httpOptions.headers
		});
	}

	ofUserCategoryCount(appUserId: string, categoryId: number): Observable<number> {
		const url = `${this.worksUrl}/ofUserCategoryCount/${appUserId}/${categoryId}`;
		return this.http.post<number>(url, null, this.modelService.httpOptions);
	}

	canUpdate(id: number): Observable<boolean> {
		const url = `${this.worksUrl}/CanUpdate/${id}`;
		return this.http.get<boolean>(url, this.modelService.httpOptions);
	}

	states(id: number): Observable<WorkStateView[]> {
		const url = `${this.worksUrl}/States/${id}`;
		return this.http.get<WorkStateView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => {
					x.createTime = new Date(x.createTime);
					return x;
				})),
			);
	}

	stateChange(id: number, workState: WorkStateValue): Observable<any> {
		const url = `${this.worksUrl}/StateChange/${id}/${workState}`;
		return this.http.get<any>(url, this.modelService.httpOptions)
			.pipe(
				tap(() => this.clearWorkView(id))
			);
	}

	/**
	 * Добавляет в избранное работу
	 * @param id
	 */
	subscribe(id: number): Observable<any> {
		const url = `${this.worksUrl}/Subscribe/${id}`;
		return this.http.get<any>(url, this.modelService.httpOptions);
	}

	unsubscribe(id: number): Observable<any> {
		const url = `${this.worksUrl}/Unsubscribe/${id}`;
		return this.http.get<any>(url, this.modelService.httpOptions);
	}

	/**
	 * Вычисляет следующее значение сортировки в зависимости от текущего
	 * @param direction
	 */
	sortNext(direction: SortDirection): SortDirection {
		switch (direction) {
			case 'asc':
				return 'desc';
			default:
				return 'asc';
		}
	}

	requestedForManager(): Observable<WorkView[]> {
		const url = `${this.worksUrl}/RequestedForManager`;
		return this.http.get<WorkView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => WorkService.parseDates(x))),
			);
	}

	moderate(): Observable<WorkView[]> {
		const url = `${this.worksUrl}/Moderate`;
		return this.http.get<WorkView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => WorkService.parseDates(x))),
			);
	}

	public static stateColorClass(state: WorkStateValue): string | null {
		switch (state) {
			case WorkStateValue.draft:
				return 'draft';
			case WorkStateValue.seeking:
				return 'seeking';
			case WorkStateValue.packaged:
			case WorkStateValue.executingAwait:
			case WorkStateValue.moderateFail:
			case WorkStateValue.checking:
				return 'packaged'
			case WorkStateValue.executing:
				return 'executing'
			case WorkStateValue.successed:
				return 'finished'
			case WorkStateValue.failedBySpecialist:
			case WorkStateValue.failedByClient:
				return 'failed'
			case WorkStateValue.abstractFinished:
				return 'abstract-finished'
			case WorkStateValue.vacancyIsFinished:
				return 'abstract-finished'
		}
		return null;
	}

	public static StateToAbstractFinish(state: WorkStateValue): WorkStateValue {
		if (state >= WorkStateValue.successed && state !== WorkStateValue.moderateFail)
			return WorkStateValue.abstractFinished;

		return state;
	}

	public static StateToAbstractFinishExcludeSuccessed(state: WorkStateValue): WorkStateValue {
		if (state > WorkStateValue.successed && state !== WorkStateValue.moderateFail)
			return WorkStateValue.abstractFinished;

		return state;
	}

	public static StateExclusiveForSpecialist(state: WorkStateValue, isActiveUserAssigned: boolean): WorkStateValue {
		if (!isActiveUserAssigned) {
			return WorkService.StateToAbstractFinishExcludeSuccessed(state);
		}

		if (state === WorkStateValue.failedByClient) {
			return WorkStateValue.successed;
		}

		if (state === WorkStateValue.failedBySpecialist) {
			return WorkStateValue.failedBySpecialist;
		}

		if (state === WorkStateValue.successed) {
			return WorkStateValue.abstractFinished;
		}

		return state;
	}

	/**
	 * Проверяет есть ли значения в фильтре
	 * @param worksFilter
	 */
	public static hasFilter(worksFilter: WorksFilter): boolean {
		if (!worksFilter) {
			return false;
		}

		return !!worksFilter.any ||
			!!worksFilter.states ||
			!!worksFilter.planEndDate ||
			!!worksFilter.planCost ||
			!!worksFilter.planStartDate ||
			!!worksFilter.companies;
	}
}
