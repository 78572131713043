import { FormArray, FormGroup } from '@angular/forms';
import { AppUserType } from '@models/app-user-view';
import { EducationView } from '@models/education-view';
import { ExperienceView } from '@models/experience-view';
import { HardSkillView } from '@models/hard-skill-view';

export enum STEP {
	EDUCATION = 0,
	EXP = 1,
	HARD_SKILLS = 2,
	PASSWORD = 3
}

export interface FormEventData {
	parentForm: FormGroup | FormArray,
	index: number
}

export interface TwoFactorRegistrationRequest {
	userName: string,
	email: string,
	password: string,
	repeatPassword: string,
	type: AppUserType,
	lastUrl: string,
	educations?: EducationView[],
	experiences?: Omit<ExperienceView, 'tillNow'>[],
	hardSkills?: HardSkillView[]
}
