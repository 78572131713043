import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root'
})
export class HeaderService {
	constructor(
		private titleService: Title
	) {
	}

	changeCurrentTitle(primary: string) {
		this.titleService.setTitle((environment.test ? 'TEST ' : '') + primary);
	}
}
