import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, switchMap } from 'rxjs';
import { WorkRequestService } from '@core/services/work-request.service';
import { Router } from '@angular/router';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { WorkRequestState, WorkRequestView } from '@models/work-request-view';
import { pushFakeHistoryState } from '@utils/functions';
import {
	ConfirmationDialogComponent,
	ConfirmationDialogType
} from '@shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { errorTitle } from '@utils/helpers/error-helpers';
import { MatDialog } from '@angular/material/dialog';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { YesNo } from '@models/enums';

@Component({
	selector: 'pdw-work-requests-specialist-card',
	templateUrl: './work-requests-specialist-card.component.html',
	styleUrls: ['./work-requests-specialist-card.component.scss'],
	providers: [DestroyService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkRequestsSpecialistCardComponent implements OnInit {
	@Output() changed = new EventEmitter<any>();
	@Input() workRequestView: WorkRequestView = null;
	protected readonly WorkRequestState = WorkRequestState;
	protected readonly YesNo = YesNo;

	countOfSkills: number = 0;

	get WorkRequestView(): WorkRequestView {
		return this._WorkRequestView.getValue();
	}

	private _WorkRequestView = new BehaviorSubject<WorkRequestView>(null);
	currentWorkRequestView = new BehaviorSubject<WorkRequestView>(null);
	loadingData = new BehaviorSubject<boolean>(false);

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private workRequestService: WorkRequestService,
		private notificationService: NotificationService,
		private destroy$: DestroyService
	) {
	}

	ngOnInit(): void {
		this.countOfSkills = this.workRequestView?.work.hardSkills?.length;
		this._WorkRequestView
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe((WorkRequestView: WorkRequestView) => {
				this.currentWorkRequestView.next(WorkRequestView);
			});
	}

	/**
	 * Отменяет отклик
	 * @param workExecutorRequestView
	 * @param event
	 */
	goDelete(workExecutorRequestView: WorkRequestView, event: MouseEvent): void {
		event.preventDefault();
		event.stopPropagation();

		pushFakeHistoryState();
		this.dialog.open(ConfirmationDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Внимание',
					message: 'Вы действительно хотите отменить отклик на',
					value: workExecutorRequestView.work.title,
					questionMark: true,
					type: ConfirmationDialogType.question,
				}
			})
			.afterClosed()
			.pipe(
				tap(() => this.loadingData.next(true)),
				filter(result => result),
				switchMap(() => this.workRequestService.delete(workExecutorRequestView.id)
					.pipe(
						takeUntil(this.destroy$)
					)
				)
			)
			.subscribe({
				next: () => {
					this.changed.emit('Delete request');
				},
				error: (err) => {
					this.loadingData.next(false);
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	/**
	 * Принимает приглашение
	 * @param workExecutorRequestView
	 * @param event
	 */
	goAccept(workExecutorRequestView: WorkRequestView, event: MouseEvent): void {
		event.stopPropagation();
		event.preventDefault();

		this.router.navigate([`/works/${this.workRequestView.work.id}/details`]);
	}

	/**
	 * Отклоняет приглашение
	 * @param workExecutorRequestView
	 * @param event
	 */
	goDecline(workExecutorRequestView: WorkRequestView, event: MouseEvent): void {
		event.preventDefault();
		event.stopImmediatePropagation();

		pushFakeHistoryState();
		this.dialog.open(ConfirmationDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Внимание',
					message: 'Вы действительно хотите отклонить отклик на ',
					value: workExecutorRequestView.work.title,
					questionMark: true,
					type: ConfirmationDialogType.question,
				}
			})
			.afterClosed()
			.pipe(
				tap(() => this.loadingData.next(true)),
				filter(result => result),
				switchMap(() => this.workRequestService.decline(workExecutorRequestView.id)
					.pipe(
						takeUntil(this.destroy$)
					)
				)
			)
			.subscribe({
				next: () => {
					this.changed.emit('Decline request');
				},
				error: (err) => {
					this.loadingData.next(false);
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	stateColorClass(workRequestView: WorkRequestView): string {
		return WorkRequestService.stateColorClass(workRequestView.state, workRequestView.direction);
	}
}
