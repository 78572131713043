<form
	class='education-form'
	[formGroup]='formGroup'
>
	<div class='education-row-delete'>
		<p class='education-title'>
			Учебное заведение {{index + 1}}
		</p>

		<button
			(click)='deleteCurrentGroup()'
			*ngIf='index > 0'
		>
			<mat-icon svgIcon='additional-trash-2'></mat-icon>
		</button>
	</div>
	<div class='education-row'>
		<mat-form-field
			class='pd-input-form-field pd-dialog-half-row'
			appearance='fill'
			hideRequiredMarker
		>
			<mat-label>{{ 'year' | education: 'propertyTitle' }}</mat-label>
			<pd-select
				formControlName='year'
			>
				<pd-select-option
					*ngFor='let item of years'
					[value]='item'
				>
					{{item}}
				</pd-select-option>
			</pd-select>
			<button
				class='pd-icon-button gray'
				matSuffix
				mat-icon-button
				type='button'
			>
				<mat-icon
					class='pd-icon'
					svgIcon='arrow-down-2'
				></mat-icon>
			</button>
			<mat-error
				*ngIf='yearControl.errors?.required'
				i18n='mat-error@@register-password-required'
			>Обязательное поле
			</mat-error>
		</mat-form-field>

		<mat-form-field
			class='pd-input-form-field pd-dialog-half-row'
			appearance='fill'
			hideRequiredMarker
		>
			<mat-label>{{ 'level' | education: 'propertyTitle' }}</mat-label>
			<pd-select
				formControlName='level'
			>
				<pd-select-option
					*ngFor='let item of EDUCATION_LEVEL | educationLevel'
					[value]='+item.key'
				>
					{{item.value}}
				</pd-select-option>
			</pd-select>
			<button
				class='pd-icon-button gray'
				matSuffix
				mat-icon-button
				type='button'
			>
				<mat-icon
					class='pd-icon'
					svgIcon='arrow-down-2'
				></mat-icon>
			</button>
			<mat-error
				*ngIf='level.errors?.required'
				i18n='mat-error@@register-password-required'
			>Обязательное поле
			</mat-error>
		</mat-form-field>
	</div>
	<mat-form-field
		class='pd-input-form-field'
		appearance='fill'
		hideRequiredMarker
	>
		<mat-label>{{ 'name' | education: 'propertyTitle' }}</mat-label>
		<input
			matInput
			formControlName='name'
			required
			maxlength='150'
		>
		<mat-error
			*ngIf='name.errors?.maxlength'
			i18n='mat-error@@register-password-required'
		>Максимальное количество символов не может превышать 150
		</mat-error>
		<mat-error
			*ngIf='name.errors?.required'
			i18n='mat-error@@register-password-required'
		>Обязательное поле
		</mat-error>
	</mat-form-field>
	<!--Sector-->
	<mat-form-field
		class='pd-input-form-field'
		appearance='fill'
	>
		<mat-label>{{ 'sector' | education: 'propertyTitle' }}</mat-label>
		<input
			matInput
			formControlName='sector'
			maxlength='150'
		>
		<mat-error
			*ngIf='sector.errors?.maxlength'
			i18n='mat-error@@register-password-required'
		>Максимальное количество символов не может превышать 200
		</mat-error>
	</mat-form-field>
	<mat-form-field
		class='pd-input-form-field'
		appearance='fill'
	>
		<mat-label>{{ 'speciality' | education: 'propertyTitle' }}</mat-label>
		<input
			matInput
			formControlName='speciality'
			maxlength='150'
		>

		<mat-error
			*ngIf='speciality.errors?.maxlength'
			i18n='mat-error@@register-password-required'
		>Максимальное количество символов не может превышать 150
		</mat-error>
	</mat-form-field>
</form>
