import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
export interface ConfirmationDialogData {
  title: string;
  message: string;
  value: string;
  questionMark: boolean;
  type: ConfirmationDialogType;
}

export enum ConfirmationDialogType {
  question = 1,
  warning
}

export enum RequisitesNoteConfirmationDialogValue {
  fit,
  skip
}

@Component({
  selector: 'app-user-requisites-note-confirmation',
  templateUrl: './app-user-requisites-note-confirmation.component.html',
})
export class AppUserRequisitesNoteConfirmationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AppUserRequisitesNoteConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
  ) {
  }

  onSkip(): void {
    this.dialogRef.close(RequisitesNoteConfirmationDialogValue.skip);
  }

  onFit(): void {
    this.dialogRef.close(RequisitesNoteConfirmationDialogValue.fit);
  }
}
