<ng-container *ngIf='!(loading$ | async); else isLoading'>
	<ng-container *ngIf='{ data: dataSource.data | async, count: dataSource.count | async } as both'>
		<ng-container *ngIf='both.count && both.data.length; else emptySource'>
			<div
				pdwScrollPagination
				[maxCount]='dataSource.count | async'
				[currentIndex]='pageIndex'
				(emitterOnBottom)='goNextPageByData($event)'
				(emitterOnInit)='loadByObject($event)'
				class='pd-requests pd-manager-requests cards-container'
			>
				<pdw-work-requests-manager-card
					*ngFor='let element of both.data | slice: 0 : (pageIndex + 1) * pageSize'
					class='card'
					[WorkRequestView]='element'
					[dataSource]='dataSource'
					(changed)='reload()'
				>
				</pdw-work-requests-manager-card>
			</div>

			<p
				*ngIf='(remain | async) > 0 && (update$ | async); else noRemain'
				class='pd-basis-no-remain'
			>Загружаем</p>


			<ng-template #noRemain>
				<p
					*ngIf='pageIndex !== 0'
					class='pd-basis-no-remain'
				>Вы посмотрели все отклики</p>
			</ng-template>
		</ng-container>
		<ng-template #emptySource>
			<div class='pd-basis-card-empty pd-basis-card-empty-requests'>
				<div class='pd-basis-card-empty-inner'>
					<mat-icon
						class='pd-icon'
						[svgIcon]="'additional2-alert-exclamation-2-100-100'"
					></mat-icon>
					<h2 class='pd-basis-card-empty-title'>Откликов пока нет</h2>
					<p class='pd-basis-card-empty-subtitle'>Подберите специалиста для ваших задач самостоятельно или
						дождитесь откликов от исполнителей</p>
					<button
						mat-raised-button
						[routerLink]="['/users']"
						class='pd-button'
					>
						Найти специалиста
					</button>
				</div>
			</div>
		</ng-template>
	</ng-container>
</ng-container>
<ng-template #isLoading>
	<div class='pd-basis-card-empty'>
		<div class='pd-basis-card-empty-inner'>
			<mat-spinner
				[diameter]='70'
				[strokeWidth]='5'
			></mat-spinner>
		</div>
	</div>
</ng-template>
