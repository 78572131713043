import { Pipe, PipeTransform } from '@angular/core';
import { AbstractModelPipe } from '@public/pipes/abstract-model.pipe';

@Pipe({
	name: 'workFilterDialog'
})
export class WorkFilterDialogPipe extends AbstractModelPipe implements PipeTransform {
	properties = [
		{ name: 'states', title: 'Статус', hint: 'Выберите статус работы' },
		{ name: 'planEndDate', title: 'Дата окончания', hint: 'С какого числа', secondHint: 'По какое число' },
		{ name: 'planCost', title: 'Бюджет в ₽', hint: 'От', secondHint: 'До' },
		{ name: 'companies', title: 'Наименование компании', hint: 'Введите наименование' },
		{ name: 'company', title: 'Наименование компании', hint: 'Введите наименование' },
		{ name: 'planStartDate', title: 'Дата начала', hint: 'С какого числа', secondHint: 'По какое число' },
		{ name: 'duration', title: 'Продолжительность в днях', hint: 'От', secondHint: 'До' },
		{ name: 'cities', title: 'Город', hint: 'Введите название города' },
		{ name: 'cityIds', title: 'Город', hint: 'Введите название города' },
		{ name: 'workFormatIds', title: 'Формат работы', hint: '' },
		{ name: 'workTypeFilters', title: 'График работы', hint: '' },
		{ name: 'workFormat', title: 'Формат работы', hint: '' },
		{ name: 'workType', title: 'График работы', hint: '' },
		{ name: 'skillIds', title: 'Сфера деятельности', hint: 'Выберите' },
		{ name: 'salary', title: 'Уровень дохода в ₽', hint: 'От', secondHint: 'До' },
		{ name: 'ratingMoreThen', title: '', hint: 'Рейтинг не менее' },
		{ name: 'specTag', title: 'Теги', hint: '' },
		{ name: 'budgetCost', title: 'Бюджет в ₽', hint: 'От', secondHint: 'До' },
		{ name: 'schedules', title: 'График работы', hint: '' },
	];

	transform(value: any, type: string): any {
		return this.getTransform(value, type);
	}

	allProperties() {
		return this.properties;
	}
}
