import { AppUserView } from '@models/app-user-view';
import { WorkView } from '@models/work-view';
import { WorkRequestChatMessageView } from '@models/work-request-chat-message-view';

export const WorkRequestTitles = {
	primaryTitleItems: 'Отклики',
};

export enum WorkRequestState {
	none = -1,
	sent,
	accepted,
	declined,
	cancelledBySpecialist,
	cancelledByClient,
	CandidatesList
}

export enum WorkRequestDirection {
	None = -1,
	fromExecutor,
	fromManager
}

export class WorkRequestView {
	public id: number;
	public work: WorkView;
	public executor: AppUserView;
	public direction: WorkRequestDirection;
	public state: WorkRequestState;
	public newMessagesCount: number;
	public lastWorkRequestChatMessage: WorkRequestChatMessageView;
	public createAppUser: AppUserView;
	public modifyAppUser: AppUserView;
	public createTime: Date;
	public modifyTime: Date;
}
