import { AppUserView } from "./app-user-view";
import { YesNo } from "./enums";

export enum InnStateValue {
  none = -1,
  selfImpolyeed,
  notSelfEmployeed,
  incorrect,
  abstractFinished = 99
}

export interface AppUserInnStateView {
	createAppUser: AppUserView;
	createTime: string;
	id: number;
	appUser: AppUserView;
	isLast: YesNo;
	value: InnStateValue;
}
