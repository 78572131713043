<div *ngIf='currentAppUserView | async as currentAppUser'>
	<div
		class='pd-profile-about-top-wrapper'
		[ngClass]="(isEducationsExist() && currentAppUser?.educations?.length) ? '' : 'empty'"
	>
		<h3 class='pd-profile-subtitle'>
			<mat-icon
				class='pd-icon light-gray'
				svgIcon='additional-check-rectangle'
			></mat-icon>
			Повышение квалификации, курсы
		</h3>
		<ng-container *ngIf='canUpdate'>
			<button
				*ngIf='isEducationsExist() && currentAppUser?.educations?.length; else noEducationAdditionals'
				class='pd-button-secondary small'
				mat-raised-button
				(click)='goUpdateEducationAdditionals()'
			>
				<mat-icon
					class='pd-icon'
					svgIcon='additional2-edit'
				></mat-icon>
				<p class='pd-profile-button-text'>
					Редактировать
				</p>
			</button>
			<ng-template #noEducationAdditionals>
				<button
					class='pd-button-secondary small'
					mat-raised-button
					(click)='goUpdateEducationAdditionals()'
				>
					<mat-icon
						class='pd-icon'
						svgIcon='additional-plus'
					></mat-icon>
					<p class='pd-profile-button-text'>Добавить</p>
				</button>
			</ng-template>
		</ng-container>
	</div>
	<ng-container *ngIf='isEducationsExist() && currentAppUser?.educations?.length'>
		<mat-table
			class='pd-profile-table'
			[dataSource]='educationAdditionalDataSource'
			multiTemplateDataRows
		>

			<!-- Period Column -->
			<ng-container matColumnDef='period'>
				<mat-cell
					class='pd-profile-table-period pd-profile-table-pointed-cell'
					*matCellDef='let element'
				>
					<p class='pd-profile-text'>{{ element.year }}</p>
				</mat-cell>
			</ng-container>

			<!-- Description Column -->
			<ng-container matColumnDef='description'>
				<mat-cell *matCellDef='let element'>
					<div class='pd-profile-inner-column'>
						<h3 class='pd-profile-text-medium pd-profile-table-description-name'>{{ element.name }}</h3>
						<p class='pd-profile-text gray'>{{ element.sector }}</p>
					</div>
				</mat-cell>
			</ng-container>
			<mat-row
				class='pd-profile-table-period-row'
				*matRowDef="let row; columns: ['period', 'description']"
			></mat-row>
		</mat-table>
	</ng-container>
</div>
