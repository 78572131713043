import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CompanyDetailsDialogData} from "@shared/company-legal-details-dialog/company-legal-details-dialog.component";
import {CompanyView} from "@models/company-view";
import {BehaviorSubject, find, Subject} from "rxjs";
import {IndustryService} from "@core/services/industry.service";
import {debounceTime, distinctUntilChanged, filter, takeUntil} from "rxjs/operators";
import {IndustryView} from "@models/industry-view";
import {MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import {FlatTreeControl} from "@angular/cdk/tree";
import {SelectionModel} from "@angular/cdk/collections";
import {groupByField} from "@utils/helpers/array-helpers";
import {errorTitle} from "@utils/helpers/error-helpers";
import {CompanyService} from "@core/services/company.service";
import {FormControl} from "@angular/forms";
import {NotificationService} from "@profdepo-ui/core";

export class TreeNode {
  id?: number;
  name: string;
  children?: TreeNode[];
}

export class TreeFlatNode {
  id?: number;
  name: string;
  level: number;
  expandable: boolean;
  visible: boolean = true;
}

@Component({
  selector: 'app-company-industries-dialog',
  templateUrl: './company-industries-dialog.component.html',
})
export class CompanyIndustriesDialogComponent implements OnInit, OnDestroy {
  dataSource: MatTreeFlatDataSource<TreeNode, TreeFlatNode>;
  treeControl: FlatTreeControl<TreeFlatNode>;
  treeFlattener: MatTreeFlattener<TreeNode, TreeFlatNode>;
  treeFilter = new FormControl('');
  industryViewsSource: IndustryView[];
  industrySelection: SelectionModel<IndustryView>;
  emptySourceOnFilter = new BehaviorSubject<boolean>(false);
  saving = new BehaviorSubject<boolean>(false);
  unsubscribe: Subject<any> = new Subject<any>();
  constructor(
    public dialogRef: MatDialogRef<CompanyDetailsDialogData, boolean | CompanyView>,
    @Inject(MAT_DIALOG_DATA) public data: CompanyDetailsDialogData,
    private industryService: IndustryService,
    private companyService: CompanyService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.industryService.all()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe({
        next:(industryViews: IndustryView[]) => {
          this.industryViewsSource = industryViews;
          this.treeFlattener = new MatTreeFlattener(
            this._transformer,
            node => node.level,
            node => node.expandable,
            node => node.children,
          );
          this.treeControl = new FlatTreeControl<TreeFlatNode>(
            node => node.level,
            node => node.expandable,
          );
          this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener, this.buildTree(industryViews));
          // let tmp = this.treeControl.dataNodes
          //   .filter(x => x.level === 0 && this.data.companyView.industries.some(i => i.section === x.name));
          this.treeControl.dataNodes
            .filter(x => x.level === 0 && this.data.companyView.industries.some(i => i.section === x.name))
            .forEach(x => {
              this.treeControl.expand(x)
            });

          this.industrySelection = new SelectionModel<IndustryView>(true, this.industryViewsSource
            .filter(x => this.data.companyView.industries.some(i => i.id === x.id)));
        }
      });

    this.treeFilter.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged(),
        debounceTime(300),
        filter(x => x !== null)
      ).subscribe({
      next: v => {
        if (v.length > 1){
          this.filterByName(v);
        } else {
          this.onClearFilter();
        }
      }
    });
  }
  private _transformer = (node: TreeNode, level: number) => {
    let treeFlatNode = new TreeFlatNode();
    treeFlatNode.id = node.id;
    treeFlatNode.name = node.name;
    treeFlatNode.level = level;
    treeFlatNode.expandable = !!node.children && node.children.length > 0;
    return treeFlatNode;
  };
  hasChild = (_: number, node: TreeFlatNode) => node.expandable;
  buildTree(industries: IndustryView[]): TreeNode[] {
    return groupByField(industries, 'section').map(x => {
      let item = new TreeNode();
      item.name = x.section;
      item.children = x.group.map(w => {
        let subItem = new TreeNode();
        subItem.id = w.id;
        subItem.name = w.name;
        return subItem;
      })
      return item;
    });
  }
  isSelected(node: TreeFlatNode): boolean {
    return this.industrySelection.selected.some(x => x.id === node.id);
  }
  selectionToggle(node: TreeFlatNode): void {
    let industryView = this.industryViewsSource.find(x => x.id === node.id);
    if (this.isSelected(node)) {
      this.industrySelection.deselect(industryView)
    } else {
      this.industrySelection.select(industryView);
    }
  }
  disabled(node: TreeFlatNode): boolean {
    return this.industrySelection.selected.length >= 5 && !this.isSelected(node);
  }
  private concatToIds(array: any[]) : string {
    return array
      .map(x => x.id)
      .sort((a, b) => a - b)
      .join(':');
  }
  canSave(): boolean {
    if (this.industrySelection) {
      return this.concatToIds(this.industrySelection.selected) !== this.concatToIds(this.data.companyView.industries);
    }
    return true;
  }
  filterByName(term: string): void {
    const filteredItems = this.treeControl.dataNodes.filter(
      x => x.name.toLowerCase().indexOf(term.toLowerCase()) === -1 && x.expandable === false
    );

    const parentNodesToHide = this.treeControl.dataNodes.filter(x => x.expandable === true);
    const tmpSource = this.industryViewsSource;
    const tmpTreeSource = this.treeControl.dataNodes;
    const parentNodesToShow = [];

    filteredItems.forEach(x => {
      x.visible = false;
    });

    const visibleItems = this.treeControl.dataNodes.filter(
     x => x.name.toLowerCase().indexOf(term.toLowerCase()) > -1 && x.expandable === false
    );

    visibleItems.forEach(x => {
      x.visible = true;
      const parentNodeName = tmpSource.find(n => n.id === x.id).section;
      const indexOfHiddenNode = parentNodesToHide.findIndex(x => x.name === parentNodeName);
      if (parentNodeName && indexOfHiddenNode >= 0){
        parentNodesToHide.splice( indexOfHiddenNode,1);
      }
      const indexOfShownNode = parentNodesToShow.findIndex(x => x.name === parentNodeName);
      if (parentNodeName && indexOfShownNode < 0){
        parentNodesToShow.push(tmpTreeSource.find(x => x.name === parentNodeName));
      }
    });

    if (visibleItems.length === 0){
      this.emptySourceOnFilter.next(true);
    } else {
      this.emptySourceOnFilter.next(false);
    }

    parentNodesToHide.forEach(x => {
      x.visible = false;
      this.treeControl.collapse(x);
    });
    parentNodesToShow.forEach(x => {
      x.visible = true;
      x.expanded = true;
      this.treeControl.expand(x);
    });
  }

  onClearFilter(): void {
    const parentNodes = this.treeControl.dataNodes.filter(x => x.expandable === true);
    const parentNodeNames: string[] = [];
    this.emptySourceOnFilter.next(false);

    this.treeControl.dataNodes.forEach((x, i) => {
      x.visible = true;

      const selectedItem = this.industrySelection.selected.find(r => r.name === x.name);
      if (selectedItem){
        parentNodeNames.push(selectedItem.section);
      }
    });

    parentNodes.forEach(x => {
      if (parentNodeNames.includes(x.name)){
        this.treeControl.expand(x);
      } else {
        this.treeControl.collapse(x);
      }
    });
  }

  goSave(): void {
    // copy form values to object
    let companyView = Object.assign({}, this.data.companyView);
    companyView.industries = this.industrySelection.selected;
    // update data
    this.companyService.callCompanyView(this.data.method, companyView)
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe({
        next: (companyView) => {
          this.saving.next(false);
          this.dialogRef.close(companyView);
        },
        error: (err) => {
          this.saving.next(false);
          this.notificationService.showDanger(errorTitle(err));
        }
      });
  }
  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }
}
