<ng-container *ngIf='currentWorkView | async as workView'>
	<ng-content select='pdw-work-state-change-specialist'></ng-content>
	<ng-container *pdwCompanyChecker='workView'>
		<ng-content select='pdw-work-state-change-manager'></ng-content>
	</ng-container>

	<div
		class='pd-work-states-list'
		*ngIf='workStateHistoryList'
	>
		<ng-container *ngFor='let item of workStateHistoryList; let last = last'>
			<ng-container *ngIf='!item.fake; else fakeItem'>
				<div class='pd-work-states-item'>
					<div class='pd-work-states-item-icon-wrapper'>
						<mat-icon
							class='pd-icon'
							[svgIcon]="'additional2-check-ring'"
						>
						</mat-icon>
						<div class='pd-work-states-item-line'></div>
					</div>
					<pdw-work-state [value]='item.value'></pdw-work-state>
					<p class='pd-work-states-item-date'>{{item.date | date:'dd.MM.YYYY'}}</p>
				</div>
			</ng-container>
			<ng-template #fakeItem>
				<div class='pd-work-states-item fake'>
					<div class='pd-work-states-item-icon-wrapper'>
						<mat-icon
							class='pd-icon'
							[svgIcon]="'additional-circle'"
						>
						</mat-icon>
						<div
							*ngIf='!last'
							class='pd-work-states-item-line'
						></div>
					</div>
					<pdw-work-state [value]='item.value'></pdw-work-state>
					<p class='pd-work-states-item-date'>-</p>
				</div>
			</ng-template>
		</ng-container>
	</div>
</ng-container>
