import {AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppUserView} from "@models/app-user-view";
import {BehaviorSubject, fromEvent, Subject} from "rxjs";
import {CategoryMarkView} from "@models/category-mark-view";
import {distinctUntilChanged, filter, map, takeUntil} from "rxjs/operators";
import {AppUserCategoryWorksDataSource} from "@core/dataSources/app-user-category-works-data-source";
import {WorkService} from "@core/services/work.service";
import {calcColumnsCount, onResizeCardsContainer} from "@utils/functions";
import {UtilsService} from "@core/services/utils.service";

@Component({
  selector: 'app-app-user-category-works',
  templateUrl: './app-user-category-works.component.html',
})
export class AppUserCategoryWorksComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input('currentUserView')
  set currentUserView(value: AppUserView) {
    this._currentUserView.next(value);
  };

  get currentUserView(): AppUserView {
    return this._currentUserView.getValue();
  }

  @Input('categoryMarkView') categoryMarkView: CategoryMarkView;

  private _currentUserView = new BehaviorSubject<AppUserView>(null);
  currentAppUserView = new BehaviorSubject<AppUserView>(null);
  dataSource: AppUserCategoryWorksDataSource;
  unsubscribe: Subject<any> = new Subject<any>();
  columnsCount: number = 0;
  minCardWidth: number = 392;
  @ViewChild('cardsContainer', {read: ElementRef}) cardsContainer: ElementRef;

  constructor(
    private workService: WorkService,
    private zone: NgZone,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this._currentUserView
      .pipe(
        takeUntil(this.unsubscribe),
        filter(x => x !== null && this.categoryMarkView !== undefined),
      ).subscribe({
      next: appUserView => {
        this.dataSource = new AppUserCategoryWorksDataSource(appUserView, this.categoryMarkView.category, this.workService);
        this.currentAppUserView.next(appUserView);
      }
    });

    fromEvent(window, 'resize')
      .pipe(
        takeUntil(this.unsubscribe),
        map(() => this.cardsContainer.nativeElement.offsetWidth),
        distinctUntilChanged()
      ).subscribe({
      next: x =>{
        this.minCardWidth = onResizeCardsContainer(this.cardsContainer,
          this.utilsService.mobileQuery980px.value ? 0 : -80);
        this.columnsCount = calcColumnsCount(this.cardsContainer, this.minCardWidth,
          this.utilsService.mobileQuery980px.value ? 0 : -80);
      }
    });
  }

  ngAfterViewInit() {
    this.zone.onStable
      .pipe(
        filter(() => this.cardsContainer !== undefined),
        distinctUntilChanged(),
      )
      .subscribe(columnsCount => {
        window.dispatchEvent(new Event('resize'));
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }

}
