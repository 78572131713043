<div class='pd-dialog-header'>
	<p class='pd-dialog-header-title'>{{ data.title }}</p>
	<button
		class='pd-icon-button'
		mat-icon-button
		[mat-dialog-close]='false'
	>
		<mat-icon svgIcon='interface-close'></mat-icon>
	</button>
</div>
<tui-scrollbar class='pd-scrollbar pd-dialog-scroll'>
	<div class='pd-dialog-content large-padding'>
		<div class='pd-dialog-row'>
			<pdw-alert *ngIf="isEditMode === true"
				[alertType]="'secondary'"
				[canClose]='false'
			>
				{{ getNoteText() }} <button class="pd-button-sorting" (click)="goSupport()">Службу поддержки</button>
			</pdw-alert>
			<pdw-alert *ngIf="isEditMode === false"
				[alertType]="'secondary'"
				[canClose]='false'
			>
				{{ getNoteText() }}
			</pdw-alert>

		</div>
		<div *ngIf='companyViewForm'>
			<form
				id='infoForm'
				[formGroup]='companyViewForm'
				(ngSubmit)='onSubmit(companyViewForm)'
			>
				<div
					class='pd-dialog-column'
					[class.pd-dialog-column-edit-mode]='isEditMode'
				>
					<div
						class='pd-dialog-row'
						[class.pd-dialog-row-edit-mode]='isEditMode'
					>
						<!--  Inn   -->
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
							hideRequiredMarker
						>
							<mat-label>{{ inn | company: 'propertyTitle' }}</mat-label>
							<input
								matInput
								formControlName='inn'
								type='text'
								[maskito]="maskitoOptions"
								[matAutocomplete]='apiCompanies'
							>
							<mat-autocomplete
								#apiCompanies='matAutocomplete'
								class='pd-autocomplete'
								autoSelectActiveOption
								(optionSelected)='chooseBranch($event)'
							>
								<tui-scrollbar class='pd-scrollbar pd-autocomplete-scrollbar'>
									<mat-option
										class='pd-option'
										*ngFor='let item of companiesFromApi; let idx = index'
										[value]='getCurrentInn()'
										[id]='getCompanyKpp(item)'
									>
										{{getCompanyName(item)}}
									</mat-option>
									<mat-option
										[value]='inn.value'
										[id]="'0'"
										class='pd-option'
										*ngIf='companiesFromApi.length > 0'
									>
										УКАЗАТЬ ФИЛИАЛ ВРУЧНУЮ
									</mat-option>
								</tui-scrollbar>
							</mat-autocomplete>
							<mat-error
								*ngIf='inn.errors?.required'
								i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-inn-required'
							>Обязательное поле
							</mat-error>
							<mat-error
								*ngIf='inn.errors?.minlength && !inn.errors?.pattern'
								i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-inn-maxlength-10'
							>Введите минимум 10 символов!
							</mat-error>
							<mat-error
								*ngIf='inn.errors?.maxlength && !inn.errors?.pattern'
								i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-inn-maxlength-12'
							>Введите максимум 12 символов!
							</mat-error>
							<mat-error
								*ngIf='inn.errors?.pattern'
								i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-inn-pattern'
							>Недопустимые символы в ИНН!
							</mat-error>
							<mat-error *ngIf='inn.errors?.serverError && !inn.errors?.pattern'>
								{{ inn?.errors?.serverError }}
							</mat-error>
							<mat-error
								*ngIf='inn.errors?.notFound'
								i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-inn-notFound'
							>Указанный ИНН не существует
							</mat-error>
							<mat-error
								*ngIf='inn.errors?.registered'
								i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-inn-registered'
							>ИНН уже зарегистрирован
							</mat-error>
						</mat-form-field>
						<div
							class='pd-dialog-row'
							[class.pd-dialog-row-edit-mode]='isEditMode'
						>
							<!--  Kpp   -->
							<mat-form-field
								class='pd-input-form-field pd-dialog-half-row'
								appearance='fill'
								hideRequiredMarker
							>
								<mat-label>{{ kpp | company: 'propertyTitle' }}</mat-label>
								<input
									matInput
									formControlName='kpp'
									type='text'
									[maskito]="maskitoOptions"
								>
								<mat-error
									*ngIf='kpp?.errors?.required'
									i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-kpp-required'
								>Обязательное поле
								</mat-error>
								<mat-error
									*ngIf='kpp?.errors?.minlength  && !kpp.errors?.pattern'
									i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-kpp-minlength'
								>Введите 9 символов!
								</mat-error>
								<mat-error
									*ngIf='kpp?.errors?.maxlength && !kpp.errors?.pattern'
									i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-kpp-maxlength'
								>Введите 9 символов!
								</mat-error>
								<mat-error
									*ngIf='kpp?.errors?.pattern'
									i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-kpp-pattern'
								>Недопустимые символы в КПП!
								</mat-error>
								<mat-error *ngIf='kpp?.errors?.serverError && !kpp.errors?.pattern'>
									{{ kpp?.errors?.serverError }}
								</mat-error>
								<mat-error
									*ngIf='kpp?.errors?.databaseKppMatch'
									i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-kpp-databaseKppMatch'
								>КПП уже зарегистрирован
								</mat-error>
							</mat-form-field>
							<!--  ОГРН   -->
							<mat-form-field
								class='pd-input-form-field pd-dialog-half-row'
								appearance='fill'
								hideRequiredMarker
							>
								<mat-label>{{ ogrn | company: 'propertyTitle' }}</mat-label>
								<input
									matInput
									formControlName='ogrn'
									type='text'
									[maskito]="maskitoOptions"
								>
								<mat-error
									*ngIf='ogrn?.errors?.required'
									i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-ogrn-required'
								>Обязательное поле
								</mat-error>
								<mat-error
									*ngIf='ogrn?.errors?.minlength && !ogrn.errors?.pattern'
									i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-ogrn-minlength'
								>Введите минимум 13 символов!
								</mat-error>
								<mat-error
									*ngIf='ogrn?.errors?.maxlength && !ogrn.errors?.pattern'
									i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-ogrn-maxlength'
								>Введите максимум 15 символов!
								</mat-error>
								<mat-error
									*ngIf='ogrn.errors?.pattern'
									i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-ogrn-pattern'
								>Недопустимые символы в ОГРН!
								</mat-error>
								<mat-error *ngIf='ogrn?.errors?.serverError && !ogrn.errors?.pattern'>
									{{ ogrn?.errors?.serverError }}
								</mat-error>
							</mat-form-field>
						</div>
					</div>

					<div
						class='pd-dialog-row'
						[class.pd-dialog-row-edit-mode]='isEditMode'
					>
						<!--  Name   -->
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
							hideRequiredMarker
						>
							<mat-label>{{ name | company: 'propertyTitle' }}</mat-label>
							<input
								matInput
								formControlName='name'
								type='text'
								>
							<mat-error
								*ngIf='name?.errors?.required'
								i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-name-required'
							>Обязательное поле
							</mat-error>
							<mat-error
								*ngIf='name?.errors?.maxlength'
								i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-name-maxlength'
							>Введите максимум 100 символов!
							</mat-error>
							<mat-error
								*ngIf='name?.errors?.minlength'
								i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-name-minlength'
							>Введите минимум 3 символа!
							</mat-error>
							<mat-error *ngIf='name?.errors?.serverError'>
								{{ name?.errors?.serverError }}
							</mat-error>
						</mat-form-field>

						<!--  Fullname   -->
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
							hideRequiredMarker
						>
							<mat-label>{{ fullName | company: 'propertyTitle' }}</mat-label>
							<input
								matInput
								formControlName='fullName'
								type='text'
							>
							<mat-error
								*ngIf='fullName.errors?.required'
								i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-fullName-required'
							>Обязательное поле
							</mat-error>
							<mat-error
								*ngIf='fullName.errors?.minlength'
								i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-fullName-minlength'
							>Введите минимум 6 символов!
							</mat-error>
							<mat-error
								*ngIf='fullName.errors?.maxlength'
								i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-fullName-minlength'
							>Введите максимум 150 символов!
							</mat-error>
							<mat-error *ngIf='fullName.errors?.serverError'>
								{{ fullName.errors?.serverError }}
							</mat-error>
						</mat-form-field>
					</div>

					<div
						class='pd-dialog-row'
						formGroupName='legalFormGroup'
						[class.pd-dialog-row-edit-mode]='isEditMode'
					>
						<!--  Address   -->
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
							hideRequiredMarker
						>
							<mat-label>{{ 'legal' | address: 'propertyTitle' }}</mat-label>
							<input
								matInput
								formControlName='street'
								type='text'
							>
							<mat-error *ngIf='street?.errors?.serverError'>
								{{ street?.errors?.serverError }}
							</mat-error>
						</mat-form-field>
					</div>

					<div class='pd-dialog-row'>
						<!--  Email   -->
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
							hideRequiredMarker
						>
							<input
								matInput
								type='email'
								formControlName='email'
							>
							<mat-label>{{ 'mail' | email: 'propertyTitle' }}</mat-label>
							<mat-error
								*ngIf='email?.errors?.pattern'
								i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-email-email'
							>Эл. почта введена не корректно!
							</mat-error>
							<mat-error
								*ngIf='email.errors?.maxlength'
								i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-email-maxlength'
							>
								Введите максимум 150 символов!
							</mat-error>
							<mat-error *ngIf='email.errors?.serverError'>
								{{ email.errors?.serverError }}
							</mat-error>
						</mat-form-field>

						<!--  URL   -->
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
							hideRequiredMarker
						>
							<mat-label>{{ url | company: 'propertyTitle' }}</mat-label>
							<input
								matInput
								formControlName='url'
								type='text'
							>
							<mat-error
								*ngIf='url.errors?.maxlength'
								i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-url-maxlength'
							>
								Введите максимум 150 символов!
							</mat-error>
							<mat-error
								*ngIf='url.errors?.pattern'
								i18n='mat-error|Заполнение данных о компании@@company-legal-details-dialog-url-pattern'
							>
								Недопустимый адрес сайта организации!
							</mat-error>
							<mat-error *ngIf='url.errors?.serverError'>
								{{ url?.errors?.serverError }}
							</mat-error>
						</mat-form-field>
					</div>
				</div>
			</form>
		</div>
	</div>
</tui-scrollbar>
<div class='pd-dialog-footer align-end'>
	<div class='pd-dialog-footer-buttons'>
		<mat-spinner
			diameter='30'
			style='margin-top: 5px;'
			*ngIf='(isCheckingInn | async)'
		></mat-spinner>
		<button
			mat-raised-button
			class='pd-button-secondary large'
			type='reset'
			[mat-dialog-close]='false'
			*ngIf='isEditMode'
		>
			Отменить
		</button>
		<button
			form='infoForm'
			mat-raised-button
			class='pd-button large'
			type='submit'
			[disabled]='(saving | async) || (isCheckingInn | async)'
		>
			<ng-container *ngIf='!(saving | async); else isSaving'>{{ getBtnText() }}</ng-container>
			<ng-template #isSaving>
				Сохраняем
			</ng-template>
		</button>
	</div>
</div>
