<div
	class='step'
	*ngFor='let i of Array(stepCount); let index = index;'
	[ngClass]='{
		"step-current": index === currentStep,
		"step-completed": index < currentStep,
		"step-not-reached": index > currentStep
	}'
>
</div>
