<div
	*ngLet='isSpecPath$ | async as isSpec'
	class='pdw-public'
	[class.pdw-spec-layout]='isSpec'

>
	<header
		class="pdw-public-header {{method === 'logout' ? 'pdw-public-header-visibility' : ''}}"
		#header
	>
		<div
			class='pdw-public-header-container pdw-public-container header'
			#headerContainer
		>
			<ul class='header-list-first'>
				<li>
					<a
						class='pdw-public-header-logo'
						[routerLink]="['/']"
					>Profdepo<span>.work</span></a>
				</li>

				<li class='header-list-item'>
					<a
						routerLinkActive='header-list-item-active'
						routerLink='/spec'
					>
						Специалистам
					</a>
				</li>
				<li class='header-list-item'>
					<a
						routerLinkActive='header-list-item-active'
						routerLink='/comp'
					>
						Заказчикам
					</a>
				</li>
			</ul>
			<div class='pdw-public-header-right-wrapper'>
				<a
					class='pdw-public-icon-link-profdepo'
					href='https://profdepo.ru'
					[class.isAuth]="currentUser$ | async"
				>Profdepo.ru</a>
				<button
					mat-raised-button
					class='pd-button burger override-button'
					(click)='menuOpen()'
				>
					<img
						class='pdw-landing-burger-img'
						src='../../../../../assets/landing/burger.svg'
						alt='landing-img'
					/>
				</button>
				<div
					class='profile-link-wrapper'
					*ngIf='currentUser$ | async as user; else noCurrentUser'
				>
					<pdw-user-profile-link [user]='user'></pdw-user-profile-link>
				</div>
				<ng-template #noCurrentUser>
					<a
						mat-raised-button
						class='pdw-public-w-icon-link-login top'
						[routerLink]="['/login']"
						disabled='false'
					>
						Войти
					</a>
				</ng-template>
			</div>
		</div>

		<!--    Панель МЕНЮ на мобильной версии-->
		<section
			class='pdw-public-menu'
			#menuMobile
			id='menu'
		>
			<div class='pdw-public-menu-container'>
				<button
					class='pd-icon-button pdw-public-close-menu'
					(click)='menuClose()'
				>
					<mat-icon
						class='pd-icon'
						[svgIcon]="'additional2-close-menu'"
					></mat-icon>
				</button>
				<p class='pdw-public-menu-title'>Profdepo<span>.work</span></p>
				<div
					class='pdw-public-menu-content'
					[ngStyle]="{display: isMenuOpen ? 'none' : 'flex'}"
				>

					<ng-container *ngIf='currentUser$ | async as user; else nonAuthUser'>
						<ng-container *ngIf="method !== 'logout'">
							<div class="spec-links-wrapper">
								<a class="spec-link" routerLinkActive='header-list-item-active' routerLink='/spec'>
									Специалистам
								</a>
								<a class="spec-link" routerLinkActive='header-list-item-active' routerLink='/comp'>
									Заказчикам
								</a>
							</div>
							<pdw-user-profile-link [user]='user'></pdw-user-profile-link>
						</ng-container>
					</ng-container>
					<ng-template #nonAuthUser>
						<div class="spec-links-wrapper">
							<a class="spec-link" routerLinkActive='header-list-item-active' routerLink='/spec'>
								Специалистам
							</a>
							<a class="spec-link" routerLinkActive='header-list-item-active' routerLink='/comp'>
								Заказчикам
							</a>
						</div>
						<a
							mat-raised-button
							class='pdw-public-menu-button '
							[routerLink]="['/login']"
						>Войти</a>

						<a
							class='pdw-public-menu-link'
							[routerLink]="['/register']"
						>
							Регистрация
						</a>
					</ng-template>
					<mat-divider class='pdw-public-divider'></mat-divider>

					<a
						href='/'
						class='pdw-public-menu-home-link'
					>
						Profdepo.work
					</a>
				</div>
			</div>
		</section>
	</header>

	<div class='pdw-public-content'>
		<router-outlet></router-outlet>
	</div>

	<footer class="pdw-public-footer {{method === 'logout' ? 'pdw-public-footer-visibility' : ''}} footer-container">
		<div class='pdw-public-footer-container pdw-public-container footer'>
			<div class='pdw-public-footer-wrapper'>
				<div class='pdw-public-footer-row footer-first-row'>
					<a routerLink='/' class='pdw-public-footer-title'>Profdepo<span>.work</span></a>
					<div class='pdw-public-footer-social-link'>
						<p class='pdw-public-footer-text pdw-public-footer-text-life'>Мы в соцсетях</p>
						<div class='pdw-public-footer-social-link-icons'>
							<a
								mat-icon-button
								class='pd-icon-button pdw-public-footer-social-link'
								href='https://t.me/profdepo_ru'
							>
								<mat-icon
									class='pdw-icon'
									[svgIcon]="'additional2-tg'"
								></mat-icon>
							</a>
							<a
								mat-icon-button
								class='pd-icon-button pdw-public-footer-social-link'
								href='https://vk.com/profdepo_ru'
							>
								<mat-icon
									class='pdw-icon'
									[svgIcon]="'additional2-vk'"
								></mat-icon>
							</a>
						</div>
					</div>
					<div class='pdw-public-footer-feedback'>
						<p class='pdw-public-footer-text pdw-public-footer-text-link'>Есть вопросы<br>или предложения?
						</p>
						<a
							class='pdw-public-w-icon-link'
							(click)='goSupport()'
						>
							<mat-icon svgIcon='math-sign-plus'>
							</mat-icon>
							<span>
								Напишите нам
							</span>
						</a>
					</div>
				</div>
				<div class='pdw-public-footer-border'></div>
				<div class='pdw-public-footer-documents'>
					<a
						class='pdw-public-footer-link'
						href='https://profdepo.ru/privacyPolicy'
					>Политика конфиденциальности</a>
					<a
						class='pdw-public-footer-link'
						href='https://profdepo.ru/termsOfUse'
					>Пользовательское соглашение</a>
					<a
						class='pdw-public-footer-link'
						href='https://profdepo.ru/transactionRules'
					>Правила заключения сделок</a>
					<a
						class='pdw-public-footer-link'
						href='https://profdepo.ru/infoServiceRules'
					>Правила оказания услуг</a>
				</div>
			</div>
		</div>
	</footer>
</div>
