import {AbstractModifiedModelView} from "@models/abstract-modified-model-view";

export const SkillMenuTitles = {
  icon: 'school',
  primaryTitleItems: 'Качество',
  descriptionItems: 'Все качества',
  primaryTitleCreate: 'Создание качества',
  descriptionCreate: 'Создание нового качества',
  descriptionItem: 'Качество',
};
export enum SkillStateValue
{
  none = -1,
  created,
  approved,
  declined
}
export class AbstractSkillView extends AbstractModifiedModelView {
  public id: number;
  public title: string;
  public titleLong: string;
  public section: string;
  public code: string;
  public name: string;
  public state: SkillStateValue;
  public personsCount: number;
  public tooltip: string;
  public kind: string;
}
