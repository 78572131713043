import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'pdw-dialog-footer',
	templateUrl: './dialog-footer.component.html',
	styleUrls: ['./dialog-footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class DialogFooterComponent {
}
