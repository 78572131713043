import { ChangeDetectorRef, inject, Pipe, PipeTransform } from '@angular/core';
import { AppUserService } from '@core/services/app-user.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError, finalize, map, takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DestroyService } from '@profdepo-ui/core';
import { HttpClient } from '@angular/common/http';
import { AbstractModelService } from '@core/services/abstract-model.service';

@Pipe({
	name: 'avatar',
	pure: true,
})
export class AvatarPipe<T extends { id?: string }> implements PipeTransform {

	userService = inject(AppUserService);
	sanitizer = inject(DomSanitizer);
	destroy$ = inject(DestroyService);
	cdr = inject(ChangeDetectorRef);
	modelService = inject(AbstractModelService);
	http = inject(HttpClient);

	transform(value: T | string, url: string = 'api/AppUsers/Avatar', effector?: () => void): Observable<SafeUrl> {
		const requestUrl = `${url}/` + (typeof value === 'object' ? value.id.toString() : value);

		return this.http.get(requestUrl, {
				headers: this.modelService.downloadImageHttpHeaders,
				observe: 'response',
				responseType: 'blob'
			})
			.pipe(map((avatar: any) => {
					const objectURL = URL.createObjectURL(avatar.body);
					return this.sanitizer.bypassSecurityTrustUrl(objectURL);
				}),
				catchError(() => {
					return EMPTY;
				}),
				finalize(() => {
					if (effector) {
						effector();
					}
				}),
				takeUntil(this.destroy$));
	}

}
