<div class="pd-dialog-header">
	<p class="pd-dialog-header-title">{{ data.title }}</p>
	<button class="pd-icon-button" mat-icon-button [mat-dialog-close]="false">
		<mat-icon svgIcon="interface-close"></mat-icon>
	</button>
</div>
<tui-scrollbar class="pd-scrollbar pd-dialog-scroll">
	<div class="pd-dialog-content">
		<div class="pd-dialog-confirmation">
			<p class="pd-dialog-confirmation-message">
				{{ data.message }}
			</p>
			<p *ngIf="data.value" class="pd-dialog-confirmation-value">{{ data.value | date : "dd.MM.yyyy" }}г.</p>
		</div>
	</div>
</tui-scrollbar>
<div class="pd-dialog-footer align-end">
	<div class="pd-dialog-footer-buttons">
		<button mat-raised-button class="pd-button-secondary large" (click)="onUpdate()">Обновить</button>
		<button mat-raised-button class="pd-button large" (click)="onNext()" [disabled]="!data.isNextBtnDisabled">
			Далее
		</button>
	</div>
</div>
