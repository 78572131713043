import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	LOCALE_ID,
	NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { WorkRequestView } from '@models/work-request-view';
import { ChatService } from '@core/services/chat.service';
import { ChatMessageService } from '@core/services/chat-message.service';
import { AppUserView } from '@models/app-user-view';
import { WorkRequestChatMessageView } from '@models/work-request-chat-message-view';
import { distinctUntilChanged, filter, finalize, take, takeUntil } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { BehaviorSubject, Subject } from 'rxjs';
import { YesNo } from '@models/enums';
import { MatTableDataSource } from '@public/components/mat-table-data-source';
import { TuiScrollService } from '@taiga-ui/cdk';
import { TuiScrollbarComponent } from '@taiga-ui/core';
import { Grouping, MatGroupBy } from '@public/components/mat-group-by';
import { DatePipe } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { NotificationService } from '@profdepo-ui/core';

class ContextMenuData {
	positionY: number;
	positionX: number;
	item: WorkRequestChatMessageView;
}

@Component({
	selector: 'app-work-requests-chat-messenger',
	templateUrl: './work-requests-chat-messenger.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class WorkRequestsChatMessengerComponent implements OnDestroy, OnChanges, OnInit {
	@Output() lastChatMessageChange? = new EventEmitter<WorkRequestChatMessageView>();
	@Output() lastChatMessageReceived = new EventEmitter<WorkRequestChatMessageView>();
	@Input() selectedWorkRequest: WorkRequestView;
	@Input() isSingleMessengerComponent?: boolean = false;
	@Input() user: AppUserView;
	@Input() disabled = false;
	@Output() isConnecting = new EventEmitter<boolean>();
	@ViewChild('scrollRef') messagesPlace: TuiScrollbarComponent;
	@ViewChild(MatSort, { static: false }) sort: MatSort;

	unsubscribe: Subject<any> = new Subject<any>();
	isLoad: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	dataSource: MatTableDataSource<WorkRequestChatMessageView>;
	groupBy = new MatGroupBy();
	datePipe: DatePipe;
	isEmpty: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	appUserView: AppUserView;
	oldMessage: WorkRequestChatMessageView;
	contextMenu = new BehaviorSubject<ContextMenuData>(null);

	constructor(
		private chatService: ChatService,
		private chatMessageService: ChatMessageService,
		private notificationService: NotificationService,
		private zone: NgZone,
		@Inject(TuiScrollService) private readonly scrollService: TuiScrollService,
		@Inject(LOCALE_ID) public locale: string
	) {
	}

	ngOnInit() {
		// добавление полученного письма
		this.chatService.chatMessageReceived
			.pipe(
				takeUntil(this.unsubscribe)
			)
			.subscribe({
				next: (data: WorkRequestChatMessageView) => {
					this.isLoad.next(true);
					if (this.dataSource.data.findIndex(x => x.id == data.id) == -1) this.dataSource.data.push(data);
					this.groupingBy();
					this.scrollToBottom(500);
					this.isEmpty.next(false);
					if (this.isSingleMessengerComponent) {
						this.lastChatMessageReceived.emit(data);
					}
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});

		this.chatService.chatMessageEdit
			.pipe(
				takeUntil(this.unsubscribe)
			)
			.subscribe({
				next: (data: WorkRequestChatMessageView) => {
					var message = this.dataSource.data.find(x => x.id == data.id);
					if (message != undefined) {
						message.message = data.message;
						message.isEdited = YesNo.yes;
						this.isLoad.next(true);
						this.isEmpty.next(false);
					}
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});

		this.chatService.chatMessageDelete
			.pipe(
				takeUntil(this.unsubscribe)
			)
			.subscribe({
				next: (id: number) => {
					this.isLoad.next(true);
					let ind = this.dataSource.data.findIndex(x => x.id == id);
					this.dataSource.data.splice(ind, 1);
					if (this.dataSource.data.length == 0) {
						this.isEmpty.next(true);
						this.lastChatMessageChange.emit(null);
					} else {
						this.lastChatMessageChange.emit(this.dataSource.data[this.dataSource.data.length - 1]);
						this.isEmpty.next(false);
					}
					this.dataSource.sort = this.sort;
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});

		this.chatService.reconnectionEvent.pipe(
			takeUntil(this.unsubscribe),
			filter(() => this.selectedWorkRequest !== null)
		).subscribe(() => {
			this.chatService.joinChat('WorkRequest-Chat', String(this.selectedWorkRequest.id)).then();
		})

		this.chatService.chatMessagesListUpdated.pipe(
			takeUntil(this.unsubscribe),
		).subscribe((data) => {
			//это пока что костыль потому что съезжает скролл
			this.dataSource.data = data;
		})

		this.datePipe = new DatePipe(this.locale);
	}

	//когда прилетел новый selectedWorkRequest
	ngOnChanges(changes: SimpleChanges): void {
		let change = changes['selectedWorkRequest'];
		// отключение от предыдущего чата
		if (change && change.previousValue) {
			this.chatService.leaveChat('WorkRequest-Chat', String(change.previousValue.id)).then();
		}
		if (!this.selectedWorkRequest) {
			return;
		}
		this.isConnecting.emit(true);
		this.appUserView = this.user?.id === this.selectedWorkRequest.work.manager?.id
			? this.selectedWorkRequest.executor
			: this.selectedWorkRequest.work.manager;
		//создание форм
		this.isLoad.next(false);
		this.isEmpty.next(false);

		this.chatService.connectionEstablished
			.pipe(
				filter(x => x),
				take(1)
			).subscribe({
			next: () => {
				this.chatService.joinChat('WorkRequest-Chat', String(this.selectedWorkRequest.id)).then();
				this.chatMessageService.all(this.selectedWorkRequest.id)
					.pipe(
						takeUntil(this.unsubscribe),
						finalize(() => this.isConnecting.emit(false))
					)
					.subscribe({
						next: (data: WorkRequestChatMessageView[]) => {
							this.isLoad.next(true);
							if (data.length == 0) this.isEmpty.next(true);
							this.dataSource = new MatTableDataSource<WorkRequestChatMessageView>(data);
							this.groupingBy();
						},
						error: (err) => {
							this.notificationService.showDanger(errorTitle(err));
						}
					});
				this.scrollToBottom(0);
			}
		})
	}

	// скролл вниз страницы
	scrollToBottom(duration: number) {
		this.zone.onStable
			.pipe(
				filter(() => this.messagesPlace !== undefined),
				distinctUntilChanged(),
			)
			.subscribe(() => {
				this.scrollService
					.scroll$(
						this.messagesPlace.browserScrollRef.nativeElement,
						(this.messagesPlace.browserScrollRef.nativeElement.scrollHeight -
							this.messagesPlace.browserScrollRef.nativeElement.clientHeight),
						0,
						duration)
					.subscribe();
			});
	}

	isMoreThenFiveMinute(chatMessage: WorkRequestChatMessageView): boolean {
		let now = new Date();
		let el = String(chatMessage.createTime);
		let createTime = new Date(Date.parse(el));
		return now.getTime() - createTime.getTime() > 300000
	}

	//установка меток даты и обновление после прилета нового сообщения
	groupingBy() {
		this.groupBy.grouping = new Grouping([{
			name: 'createTime',
			sort: 'asc',
		}]);
		this.groupBy.grouping.groupDataAccessor = (group) => {
			return this.datePipe.transform(group.value, 'd');
		}
		this.groupBy.grouping.groupingDataAccessor = (item, column) => {
			return this.datePipe.transform(item[column.name], 'd');
		}
		this.dataSource.groupBy = this.groupBy;
	}

	rightClick(event: MouseEvent, isUser: boolean, data: WorkRequestChatMessageView) {
		if (!isUser) return true;
		this.contextMenu.next({
			positionX: event.clientX - 150,
			positionY: event.clientY,
			item: data
		});
		return false;
	}

	editMessage(item: WorkRequestChatMessageView) {
		if (!this.isMoreThenFiveMinute(item)) {
			this.oldMessage = item;
		}
	}

	isEdited(value: string) {
		this.dataSource.data.find(x => x.id == this.oldMessage.id).message = value;
		this.dataSource.data.find(x => x.id == this.oldMessage.id).isEdited = YesNo.yes;
		this.oldMessage = null;
	}

	deleteMessage(item: WorkRequestChatMessageView) {
		if (this.oldMessage?.id == item.id) {
			this.oldMessage = null;
		}
		if (!this.isMoreThenFiveMinute(item)) {
			this.chatMessageService.delete(item.id)
				.pipe(
					takeUntil(this.unsubscribe)
				)
				.subscribe({
					next: () => {
						let ind = this.dataSource.data.findIndex(x => x.id == item.id);
						this.dataSource.data.splice(ind, 1);
						if (this.dataSource.data.length == 0) {
							this.isEmpty.next(true);
						} else {
							this.isEmpty.next(false);
						}
						if (this.dataSource.data.length != 0) {
							this.lastChatMessageChange.emit(this.dataSource.data[this.dataSource.data.length - 1]);
						} else {
							this.lastChatMessageChange.emit(null);
						}
						this.dataSource.sort = this.sort
					},
					error: (err) => {
						this.notificationService.showDanger(errorTitle(err));
					}
				});
		}
	}

	ngOnDestroy() {
		if (this.selectedWorkRequest) {
			this.chatService.leaveChat('WorkRequest-Chat', String(this.selectedWorkRequest.id)).then();
		}
		this.isLoad.next(false);
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}
}
