import { Pipe, PipeTransform } from '@angular/core';
import { AbstractExtensionEnumPipe } from '@public/pipes/abstract-extension-enum.pipe';

@Pipe({
	name: 'workRequestState'
})
export class WorkRequestStatePipe extends AbstractExtensionEnumPipe implements PipeTransform {
	values = {
		0: 'В ожидании',
		1: 'Принят',
		2: 'Отклонен',
		3: 'Отклонен',
		4: 'Отклонен',
		5: 'В списке кандидатов',
	};

	transform(value: any, ...args: any[]): any {
		return this.getTransform(value, args);
	}
}
