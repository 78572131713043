<mat-form-field
	class='pd-input-form-field custom-form-field'
	appearance='fill'
	hideRequiredMarker
>
	<div matPrefix>
		<ng-content select='mat-icon'>
		</ng-content>
	</div>
	<mat-label>
		<ng-content select='mat-label'>
		</ng-content>
	</mat-label>

	<input
		[formControl]='control'
		matInput
		[type]='type'
	/>

	<button
		*ngIf='displayEyeButton'
		tabindex='-1'
		mat-icon-button
		matSuffix
		class='pd-icon-button light-gray new-color'
		type='button'
		(click)='changeType()'
	>
		<mat-icon [svgIcon]="type === 'password' ? 'additional-view' : 'additional-view-hide'"></mat-icon>
	</button>
	<mat-error>
		<ng-content select='mat-error'>
		</ng-content>
	</mat-error>
</mat-form-field>
