<ng-container *ngIf="currentWorkView | async as workView">
  <div class="pd-work-card-short-inner" (mouseup)="openDialog()" [matTooltip]="getTooltip()">
    <div class="pd-work-card-short-header">
      <div class="pd-work-card-short-logo">
        <ng-container *ngIf="workView.company.hasLogo === 1; else noLogo">
          <img class="pd-work-card-short-logo-img"
               [src]="'/Logos/Originals/' + workView.company.id + workView.company.originalFilenameExtension"
               alt="Logo Image"/>
        </ng-container>
        <ng-template #noLogo>
          <mat-icon class="pd-work-card-short-no-logo-icon" [svgIcon]="'additional2-img-box'"></mat-icon>
        </ng-template>
      </div>
      <div class="pd-work-card-short-title">
        <p class="pd-work-card-short-title-text">{{workView.name}}</p>
        <div class="pd-work-card-short-title-company">
          <mat-icon class="pd-icon" svgIcon="additional-bag-2-check"></mat-icon>
          <p class="pd-work-card-short-title-company-text">{{workView.company.name}}</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
