import { Pipe, PipeTransform } from '@angular/core';
import { AbstractExtensionEnumPipe } from '@public/pipes/abstract-extension-enum.pipe';

@Pipe({
	name: 'workStateValue'
})
export class WorkStateValuePipe extends AbstractExtensionEnumPipe implements PipeTransform {
	values = {
		0: 'Создан черновик',
		1: 'Поиск специалистов',
		2: 'Специалист найден',
		3: 'Ожидание подтверждения',
		4: 'Работа выполняется',
		5: 'Проверяется',
		6: 'Выполнена успешно',
		7: 'Отправлена на проверку модератору',
		8: 'Не выполнена',
		9: 'Не выполнена',
		98: 'Вакансия закрыта',
		99: 'Вакансия закрыта'
	};

	transform(value: any, ...args: any[]): any {
		return this.getTransform(value, args);
	}
}
