import { Pipe, PipeTransform } from '@angular/core';
import { AbstractModelPipe } from '@public/pipes/abstract-model.pipe';

@Pipe({
	name: 'experience'
})
export class ExperiencePipe extends AbstractModelPipe implements PipeTransform {
	properties = [
		{ name: 'startDate', title: 'Дата начала', hint: 'Начало работы в организации' },
		{ name: 'endDate', title: 'Дата окончания', hint: 'Окончание работы в организации' },
		{ name: 'tillNow', title: 'По настоящее время', hint: 'Работа в организации по настоящее время' },
		{ name: 'companyName', title: 'Организация', hint: 'Организация' },
		{ name: 'company', title: 'Организация', hint: 'Организация' },
		{ name: 'position', title: 'Должность', hint: 'Должность' },
		{ name: 'description', title: 'Обязанности', hint: 'Описание обязанностей и достижений' },
	];

	transform(value: any, type: string): any {
		return this.getTransform(value, type);
	}

	allProperties() {
		return this.properties;
	}
}
