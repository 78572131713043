<ng-container *ngIf='!(loadingData | async); else isLoading'>
	<ng-container *ngIf='{ data: dataSource.data | async, count: dataSource.count | async } as both'>
		<ng-container *ngIf='both.count && both.data.length; else emptySource'>
			<div class='pd-requests pd-specialist-requests cards-container'>
				<pdw-work-requests-specialist-card
					*ngFor='let element of both.data | slice: 0 : (pageIndex + 1) * pageSize'
					[workRequestView]='element'
					(changed)='onChange($event)'
				>
				</pdw-work-requests-specialist-card>
			</div>
			<div *ngIf='remain | async as remain;'>
				<button
					class='pd-button-additional pd-button-additional-cards'
					(click)='goNextPage()'
					*ngIf='remain > 0; else noRemain'
					[disabled]='loadingNextPage | async'
				>
					{{loadingNextPage.value ? 'Загружаем' : 'Показать еще' }}
				</button>
			</div>
			<ng-template #noRemain>
				<ng-container *ngIf='pageIndex !== 0'>
					<p class='pd-basis-no-remain'>Вы посмотрели все отклики</p>
				</ng-container>
			</ng-template>
		</ng-container>
		<ng-template #emptySource>
			<div class='pd-basis-card-empty pd-basis-card-empty-requests'>
				<div class='pd-basis-card-empty-inner'>
					<mat-icon
						class='pd-icon'
						[svgIcon]="'additional2-alert-exclamation-2-100-100'"
					></mat-icon>
					<ng-container *ngIf='filterValues.any; else noFilterValues'>
						<h2 class='pd-basis-card-empty-title'>По вашему запросу ничего не найдено!</h2>
					</ng-container>
					<ng-template #noFilterValues>
						<h2 class='pd-basis-card-empty-title'>Откликов пока нет</h2>
						<p class='pd-basis-card-empty-subtitle'>Найдите работу самостоятельно или дождитесь интересных
							приглашений
							от компаний</p>
						<button
							mat-raised-button
							[routerLink]="['../works']"
							class='pd-button'
						>
							Найти работу
						</button>
					</ng-template>
				</div>
			</div>
		</ng-template>
	</ng-container>
</ng-container>
<ng-template #isLoading>
	<div class='pd-basis-card-empty'>
		<div class='pd-basis-card-empty-inner'>
			<mat-spinner
				[diameter]='70'
				[strokeWidth]='5'
			></mat-spinner>
		</div>
	</div>
</ng-template>
