<div class='pd-notification-card-body'>
	<div class='pd-notification-card-content'>
		<div class='pd-notification-card-title'>
			<a
				*ngIf='card.routerLink'
				class='pd-notification-card-title-primary pd-link'
				[routerLink]='card.routerLink'
			>{{card.primaryTitle}}</a>
			<ng-container *ngIf='card.secondaryTitle'>
				<mat-divider
					class='pd-divider'
					[vertical]='true'
				></mat-divider>
				<div class='pd-notification-card-title-primary'>{{card.secondaryTitle}}</div>
			</ng-container>
		</div>
		<p class='pd-notification-card-text'>{{card.text}}</p>
		<div class='pd-notification-card-bottom'>
			<a
				class='pd-notification-card-creator'
				[routerLink]="['/users', card.creator.id]"
			>{{editTitleCreator(card.creator.lastname, card.creator.firstname, card.creator.title)}}</a>
			<mat-divider
				class='pd-divider-gray'
				[vertical]='true'
			></mat-divider>
			<div class='pd-notification-card-date-full'>{{card.createTime | date: 'dd.MM.YYYY'}}</div>
			<mat-divider
				class='pd-divider-gray'
				[vertical]='true'
			></mat-divider>
			<div class='pd-notification-card-date-hour'>{{card.createTime | date: 'HH:mm'}}</div>
		</div>
	</div>
	<div class='pd-notification-card-buttons'>
		<div *ngIf='card.state === 0'>
			<button
				class='pd-icon-button'
				mat-icon-button
				[matMenuTriggerFor]='menu'
			>
				<mat-icon [svgIcon]="'interface-menu-kebab-vertical'"></mat-icon>
			</button>
			<mat-menu
				class='pd-menu'
				#menu='matMenu'
				[xPosition]="'before'"
			>
				<button
					class='pd-menu-item'
					mat-menu-item
					(click)='goMakeRead(card)'
					*ngIf='!isRequest(card)'
				>
					<mat-icon svgIcon='interface-check'></mat-icon>
					<span>Прочитано</span>
				</button>
				<ng-container *ngIf='isWorkRequest(card)'>
					<button
						class='pd-menu-item'
						mat-menu-item
						(click)='goAcceptWorkRequest(card)'
					>
						<mat-icon svgIcon='additional2-user-add'></mat-icon>
						<span>Принять</span>
					</button>
					<button
						class='pd-menu-item'
						mat-menu-item
						(click)='goDeclineWorkRequest(card)'
					>
						<mat-icon svgIcon='additional-user-no'></mat-icon>
						<span>Отклонить</span>
					</button>
				</ng-container>
			</mat-menu>
		</div>
	</div>
</div>
