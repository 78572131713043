import { Pipe, PipeTransform } from '@angular/core';
import { AbstractModelPipe } from '@public/pipes/abstract-model.pipe';

@Pipe({
	name: 'appUserFilterDialog'
})
export class AppUserFilterDialogPipe extends AbstractModelPipe implements PipeTransform {

	properties = [
		{ name: 'title', title: 'ФИО', hint: 'Введите ФИО' },
		{ name: 'subCategoriesIds', title: 'Тип работ', hint: 'Введите тип работ' },
		{ name: 'hardSkillsIds', title: 'Профессиональные навыки', hint: 'Введите навыки' },
		{ name: 'userHardSkills', title: 'Профессиональные навыки', hint: 'Введите навыки' },
		{ name: 'ratingMoreThen', title: '', hint: 'Рейтинг не менее' },
		{ name: 'profession', title: 'Профессия', hint: 'Введите профессию' },
		{ name: 'rewardFrom', title: 'Доход в Р', hint: 'От', secondHint: 'До' },
		{ name: 'cityId', title: 'Город', hint: 'Введите название города' },
		{ name: 'cityIds', title: 'Город', hint: 'Введите название города' },
		{ name: 'workFormatIds', title: 'Формат работы', hint: '' },
		{ name: 'workTypeFilters', title: 'График работы', hint: '' },
		{ name: 'isReadyToRelocate', title: '', hint: '' },
		{ name: 'salarySet', title: '', hint: '' },
		{ name: 'hasReward', title: '', hint: '' },
		{ name: 'specTag', title: '', hint: '' },
		{ name: 'planSalary', title: 'Заработная плата в ₽', hint: 'От', secondHint: 'До' },


	];

	transform(value: any, type: string): any {
		return this.getTransform(value, type);
	}

	allProperties() {
		return this.properties;
	}

}
