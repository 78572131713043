import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataSource } from '@core/dataSources/data-source';
import { ComplexFilterResult } from '@utils/helpers/complex-filter-helpers';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DestroyService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-simple-any-filter',
	templateUrl: './simple-any-filter.component.html',
	host: { 'class': 'pd-simple-any-filter' },
	providers: [DestroyService]
})
export class SimpleAnyFilterComponent implements OnInit {

	/**
	 * Источник данных для получения количества записей удовлетворяющих критериям поиска
	 */
	@Input() dataSource: DataSource<any>;

	@Input() placeholder: string;
	@Output() filterResult = new EventEmitter<ComplexFilterResult>();
	anyFilter = new FormControl();

	constructor(private destroy$: DestroyService) {
	}

	ngOnInit(): void {
		this.anyFilter.valueChanges
			.pipe(
				takeUntil(this.destroy$),
				distinctUntilChanged(),
				debounceTime(350)
			)
			.subscribe(query => {
				this.resultEmit(query);
			});
	}

	/**
	 * Вызывает событие завершение ввода критерия фильтра
	 * @param query критерий поиска по всему
	 */
	resultEmit(query: string): void {
		this.filterResult.emit(new ComplexFilterResult(query, []));
	}

	clearFilter(): void {
		this.anyFilter.setValue(null);
	}

}
