import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-sent-confirmation',
  templateUrl: './sent-confirmation.component.html',
})
export class SentConfirmationComponent implements OnInit {

  afterRegistration: boolean;
  afterForgotPassword: boolean;

  constructor(private router: Router) { }

  ngOnInit(): void {
    if(!history.state.afterForgotPassword && !history.state.afterRegistration){
      this.router.navigate(['/login']);
    }

    this.afterForgotPassword = history.state.afterForgotPassword ? history.state.afterForgotPassword : false;
    this.afterRegistration = history.state.afterRegistration ? history.state.afterRegistration : false;
  }

}
