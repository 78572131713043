<div class='pd-dialog-header'>
	<p class='pd-dialog-header-title'>Редактировать профиль</p>
	<button class='pd-icon-button' mat-icon-button [mat-dialog-close]='false'>
		<mat-icon svgIcon='interface-close'></mat-icon>
	</button>
</div>
<tui-scrollbar class='pd-scrollbar pd-dialog-scroll'>
	<div class='pd-dialog-content large-padding'>
		<div *ngIf='personalForm'>
			<form id='personalForm' [formGroup]='personalForm'>
				<div class='pd-dialog-column small-gap'>

					<!--  Lastname   -->
					<mat-form-field class='pd-input-form-field' appearance='fill' hideRequiredMarker>
						<mat-label>{{ lastname | appUser: 'propertyTitle' }}</mat-label>
						<input matInput formControlName='lastname' type='text'>
						<mat-error *ngIf='lastname.errors?.maxlength'
							i18n='mat-error@@app-user-personal-dialog-lastname-maxlength'>Введите максимум 40 символов!
						</mat-error>
						<mat-error *ngIf='lastname.errors?.pattern'
							i18n='mat-error@@app-user-personal-dialog-lastname-pattern'>Недопустимые символы!
						</mat-error>
						<mat-error *ngIf='lastname.errors?.whitespace'
							i18n='mat-error@@app-user-personal-dialog-lastname-whitespace'>Введите символы!
						</mat-error>
						<mat-error
							*ngIf='lastname.errors?.minlength && !lastname.errors?.whitespace && !lastname.errors?.pattern'>Введите
							от 2 до 40 символов
						</mat-error>
						<mat-error *ngIf='lastname.errors?.serverError'>
							{{ lastname.errors?.serverError }}
						</mat-error>
					</mat-form-field>
					<!--  Firstname   -->
					<mat-form-field class='pd-input-form-field' appearance='fill' hideRequiredMarker>
						<mat-label>{{ firstname | appUser: 'propertyTitle' }}</mat-label>
						<input matInput formControlName='firstname' type='text'>
						<mat-error *ngIf='firstname.errors?.maxlength'
							i18n='mat-error@@app-user-personal-dialog-firstname-maxlength'>Введите максимум 40 символов!
						</mat-error>
						<mat-error *ngIf='firstname.errors?.pattern'
							i18n='mat-error@@app-user-personal-dialog-firstname-pattern'>Недопустимые символы!
						</mat-error>
						<mat-error *ngIf='firstname.errors?.whitespace'
							i18n='mat-error@@app-user-personal-dialog-firstname-whitespace'>Введите символы!
						</mat-error>
						<mat-error
							*ngIf='firstname.errors?.minlength && !firstname.errors?.whitespace && !firstname.errors?.pattern'>Введите
							от 2 до 40 символов
						</mat-error>
						<mat-error *ngIf='firstname.errors?.serverError'>
							{{ firstname.errors?.serverError }}
						</mat-error>
					</mat-form-field>

					<!--  Middlename   -->
					<mat-form-field class='pd-input-form-field' appearance='fill' hideRequiredMarker>
						<mat-label>{{ middlename | appUser: 'propertyTitle' }}</mat-label>
						<input matInput formControlName='middlename' type='text'>
						<mat-error *ngIf='middlename.errors?.pattern'
							i18n='mat-error@@app-user-personal-dialog-middlename-pattern'>Недопустимые символы!
						</mat-error>
						<mat-error *ngIf='middlename.errors?.whitespace'
							i18n='mat-error@@app-user-personal-dialog-middlename-whitespace'>Введите символы!
						</mat-error>
						<mat-error
							*ngIf='middlename.errors?.minlength && !middlename.errors?.whitespace && !middlename.errors?.pattern'>Введите
							от 2 до 40 символов
						</mat-error>
						<mat-error *ngIf='middlename.errors?.maxlength'
							i18n='mat-error@@app-user-personal-dialog-lastname-maxlength'>Введите максимум 40 символов!
						</mat-error>
						<mat-error *ngIf='middlename.errors?.serverError'>
							{{ middlename.errors?.serverError }}
						</mat-error>
					</mat-form-field>

					<!--  Birthday Date   -->
					<mat-form-field class='pd-input-form-field' appearance='fill' hideRequiredMarker>
						<mat-label>{{ birthday | appUser: 'propertyTitle' }}</mat-label>
						<input matInput formControlName='birthday' [matDatepicker]='birthdayPicker'
							[maskito]='maskitoOptions' placeholder='00.00.0000'>
						<mat-datepicker-toggle class='pd-datepicker-toggle' matSuffix [for]='birthdayPicker'>
							<mat-icon svgIcon='arrow-down-2' matDatepickerToggleIcon></mat-icon>
						</mat-datepicker-toggle>
						<mat-datepicker [panelClass]="'pd-calendar'" #birthdayPicker
							[calendarHeaderComponent]='calendarHeader'></mat-datepicker>
						<mat-error
							*ngIf='birthday?.errors?.comingOfAge && !birthday?.errors?.dateIsEarlier && !birthday?.errors?.matDatepickerParse && !birthday?.errors?.dateIsLater'
							i18n='mat-error@@app-user-personal-dialog-birthday-comingOfAge'>
							Кажется, ошибка: возраст менее 18 лет.
						</mat-error>
						<mat-error
							*ngIf='birthday?.errors?.dateIsEarlier && !birthday?.errors?.comingOfAge && !birthday?.errors?.matDatepickerParse && !birthday?.errors?.dateIsLater'>
							Дата рождения не должна быть меньше 01.01.1900
						</mat-error>
						<mat-error
							*ngIf='birthday?.errors?.dateIsLater && !birthday?.errors?.dateIsEarlier && !birthday?.errors?.matDatepickerParse'>
							Дата не может быть больше текущей
						</mat-error>
						<mat-error
							*ngIf='birthday?.errors?.matDatepickerParse && !birthday?.errors?.comingOfAge && !birthday?.errors?.dateIsEarlier && !birthday?.errors?.dateIsLater'>
							Вы ввели не существующую дату
						</mat-error>
						<mat-error *ngIf='birthday?.errors?.serverError'>
							{{ birthday?.errors?.serverError }}
						</mat-error>
					</mat-form-field>
					<!--  Gender   -->
					<mat-form-field class='pd-input-form-field' appearance='fill' hideRequiredMarker>
						<mat-label>{{ gender | appUser: 'propertyTitle' }}</mat-label>
						<pd-select formControlName='gender'>
							<pd-select-option *ngFor='let item of genders' [value]='item'>
								{{ item | appUserDialogGender }}
							</pd-select-option>
						</pd-select>
						<button class='pd-icon-button gray' matSuffix mat-icon-button type='button'>
							<mat-icon class='pd-icon' svgIcon='arrow-down-2'></mat-icon>
						</button>
						<mat-error *ngIf='gender.errors?.min' i18n='mat-error@@app-user-personal-dialog-gender-min'>
							Не забудь заполнить это поле.
						</mat-error>
						<mat-error *ngIf='gender.errors?.serverError'>
							{{ gender.errors?.serverError }}
						</mat-error>
					</mat-form-field>

					<!--  PhoneNumber  -->
					<div class='pd-taiga-input-wrapper pd-taiga-phone-special'>
						<tui-input-phone [phoneMaskAfterCountryCode]="'(###) ###-##-##'"
							class='pd-taiga-input pd-taiga-input-w-prefix' formControlName='phoneNumber'
							[ngClass]="{'pd-taiga-input-empty': isTuiInputEmpty()}">
							{{ 'phoneNumber' | appUserContacts: 'propertyTitle' }}
							<input tuiTextfield autocomplete='tel' />
						</tui-input-phone>
						<mat-error *ngIf='phoneNumber?.errors?.minlength'>
							Недостаточное количество символов
						</mat-error>
						<mat-error *ngIf='phoneNumber?.errors?.serverError'>
							{{ phoneNumber?.errors?.serverError }}
						</mat-error>
						<mat-icon class='pd-taiga-input-prefix-icon' svgIcon='additional-phone-call'
							matPrefix></mat-icon>
					</div>

					<!--  City   -->
					<div class='pd-taiga-input-wrapper'>
						<pdw-chips-select [isMultiple]='false' [options]='getCities.bind(this)' formControlName='city'
							label='Город'>
							<pd-checkbox class='checkbox-relocate' label='' formControlName='isReadyToRelocate'>
								<span>
									Готов к переезду
								</span>

							</pd-checkbox>
						</pdw-chips-select>
					</div>

				</div>
			</form>
		</div>
	</div>
</tui-scrollbar>
<div class='pd-dialog-footer align-end'>
	<div class='pd-dialog-footer-buttons' *ngIf='personalForm'>
		<button mat-raised-button class='pd-button-secondary large' type='reset' [mat-dialog-close]='false'>
			Отменить
		</button>
		<button form='personalForm' mat-raised-button class='pd-button large' (click)="onSubmit(personalForm)"
			[disabled]='(loading$ | async)'>
			<ng-container *ngIf='!(loading$ | async); else isSaving'>Сохранить</ng-container>
			<ng-template #isSaving>
				Сохраняем
			</ng-template>
		</button>
	</div>
</div>