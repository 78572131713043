export enum EmailPurpose
{
  home,
  work,
  other
}

export class AbstractEmailView {
  public id: number;
  public address: string;
  public purpose: EmailPurpose;
}
