import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	Optional,
	Output,
	Self,
	EventEmitter, AfterViewInit
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, debounceTime, Observable, switchMap, takeUntil } from 'rxjs';
import { DestroyService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-chips-select',
	templateUrl: './chips-select.component.html',
	styleUrls: ['./chips-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService]
})
export class ChipsSelectComponent<T> implements ControlValueAccessor, OnInit, AfterViewInit {
	noop = () => {
	};

	optionsHandler: (query: any) => Observable<Array<T>>

	@Input()
	set options(handler: (query: any) => Observable<Array<T>> | null) {
		this.optionsHandler = handler;
	}

	@Input() optionKey: keyof T | null;
	@Input() optionLabel: string = 'name';
	@Input() isMultiple: boolean = false;
	@Input() placeholder: string;
	@Input() label: string;
	@Input() icon: string
	@Input() iconPosition: 'right' | 'left' = 'left';
	@Output() deleteChipEvent = new EventEmitter<void>();

	protected onTouchedCallback: () => void = this.noop;
	private onChangeCallback: (value: any) => void = this.noop;
	private _tmpValue: any;
	private selectionModel: SelectionModel<T>;

	get tmpValue(): any {
		return this._tmpValue;
	}

	get optionsResult(): Array<T> | null {
		return this._options;
	}

	private _options: Array<T> | null = null;

	options$ = new BehaviorSubject<Array<T> | null>(null);
	inputControl: FormControl<string> = new FormControl('');


	constructor(
		private destroy$: DestroyService,
		private cdr: ChangeDetectorRef,
		@Optional() @Self() private ngControl: NgControl
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = this;
		}
	}

	ngOnInit() {
		this.selectionModel = new SelectionModel<any>(this.isMultiple);

		this.ngControl.control.markAsTouched = this.inputControl.markAsTouched;

		if (this.tmpValue) {
			this.writeValue(this.tmpValue);
		}

		if (this.ngControl.value) {
			this.writeValue(this.ngControl.value);
		}

		this.inputControl.valueChanges
			.pipe(
				debounceTime(300),
				takeUntil(this.destroy$),
				switchMap(query => this.optionsHandler(query))
			)
			.subscribe(value => {
				this.inputControl.setErrors(this.ngControl.errors);
				this.options$.next(value);
				this._options = value;
			})
	}

	setDisabledState?(isDisabled: boolean): void {
		throw new Error('Method not implemented.');
	}

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	writeValue(value: Array<T> | T): void {
		if (!value) {
			if (this.selectionModel) {
				this.inputControl.reset('');
				this.selectionModel.clear(true);
				this.cdr.markForCheck();
			}
			return;
		}

		if (!this.isMultiple && !Array.isArray(value)) {
			this.selectionModel?.select(value)
		} else {
			if (Array.isArray(value)) {
				this.selectionModel?.select(...value);
			}
		}

		this._tmpValue = this.selectionModel ?? [];
		this.cdr.markForCheck();

		this.inputControl.reset('');
	}

	deleteChip(id: unknown) {
		if (!this.isMultiple) {
			this.selectionModel?.clear();
		} else {

			if (this.isPrimitive(this.selectedValues[0])) {
				this.selectionModel?.deselect(id as T);
			} else {
				this.selectionModel?.deselect(this.selectionModel?.selected.find(option => option[this.optionKey] === id));
			}
		}
		this.deleteChipEvent.emit();
		this.propagateChanges();
	}

	getChip(chip: T, label?: string): string | number {
		if (this.isPrimitive(chip)) {
			return chip as string | number;
		}
		return chip[label];
	}

	addChip(item: T) {
		this.inputControl.setErrors(this.ngControl.errors);
		if (!this.selectionModel) {
			this._tmpValue = item;
			return;
		}

		if (this.selectedValues.find(value => this.getOptionKey(value) === this.getOptionKey(item))) {
			this.inputControl.reset('');
			return;
		}

		this.selectionModel.select(item);
		this.propagateChanges();
		this.inputControl.reset('');
	}

	isPrimitive<T>(data: T): boolean {
		return typeof data === 'string' || typeof data === 'number';
	}

	private findOptionByLabel(label: number | string): T | undefined {
		if (this.options) {
			return undefined;
		}

		if (this.isPrimitive(this.selectedValues[0])) {
			return label as T;
		}
		return this.optionsResult.find(item => this.getOptionLabel(item) === label);
	}

	private findOptionalById(id: number | string): T | undefined {
		if (this.optionsResult) {
			return undefined;
		}

		return this.optionsResult.find(item => this.getOptionKey(item) === id);
	}

	get selectedValues() {
		return this.selectionModel?.selected;
	}

	private getOptionKey(option: T): string | number {
		if (this.isPrimitive(option)) {
			return option as string | number;
		}
		return option[this.optionKey] as string | number;
	}

	private getOptionLabel(option: T): string | number {
		if (this.isPrimitive(option)) {
			return option as string | number;
		}
		return option[this.optionLabel];
	}

	private propagateChanges(): void {
		if (this.selectedValues.length) {
			this.onChangeCallback(this.isMultiple ? this.selectedValues : this.selectedValues?.[0]);
			this.inputControl.setErrors(this.ngControl.errors);
			return
		}
		this.onChangeCallback(null);
		this.inputControl.setErrors(this.ngControl.errors);
	}

	ngAfterViewInit(): void {
		this.inputControl.setErrors(this.ngControl.errors);
	}
}
