<ng-container *ngIf='work'>
	<ng-container *ngIf='plateView; else stringView'>
		<p
			class='pd-work-cost-text'
			[ngClass]="{ 'pd-work-cost-text-no-cost' : !realCost() }"
		>
			<span>Бюджет: </span>
			{{ realCost() ? (realCost() | space) + ' ₽' : 'не указан' }}
		</p>
	</ng-container>
	<ng-template #stringView>
		<p
			class='pd-work-cost-text'
			[ngClass]="{ 'pd-work-cost-text-no-cost' : !realCost() }"
		>
			{{ realCost() ? (realCost() | space) + ' ₽' : 'Не указан' }}
		</p>
	</ng-template>
</ng-container>
