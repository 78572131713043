import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppUserView } from '@models/app-user-view';
import { NotificationsRequestService } from '@core/services/notifications-request.service';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { TrueLoadingService } from '@core/services/true-loading.service';

enum NotififcationState {
	actual = 0,
	chronology = 1
}

export interface NotificationsDialogData {
	appUserView: AppUserView;
}

@Component({
	selector: 'pdw-notifications-dialog',
	templateUrl: './notifications-dialog.component.html',
	providers: [DestroyService, TrueLoadingService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsDialogComponent implements OnInit {

	notificationState: NotififcationState = NotififcationState.actual;
	unread = new BehaviorSubject<number>(0);
	count = new BehaviorSubject<number>(0);

	constructor(
		public dialogRef: MatDialogRef<NotificationsDialogData, boolean>,
		@Inject(MAT_DIALOG_DATA) public data: NotificationsDialogData,
		private notificationService: NotificationService,
		private notificationRequestService: NotificationsRequestService,
		public loading$: TrueLoadingService,
		private destroy$: DestroyService
	) {
	}

	ngOnInit(): void {
		forkJoin([
			this.notificationRequestService.readCount(),
			this.notificationRequestService.unreadCount()
		])
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: (data: number[]) => {
					this.unread.next(data[0]);
					this.count.next(data[1]);
					this.loading$.next(true);
				},
				error: (err: any) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	onChange(event: any): void {
		this.notificationState = +event.value;
	}
}
