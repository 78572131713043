import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { DestroyService } from '@profdepo-ui/core';
import { Location } from '@angular/common';
import { NavigationService } from '@core/services/navigation.service';

@Component({
	selector: 'pdw-not-found-page',
	templateUrl: './not-found-page.component.html',
	styleUrls: ['./not-found-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService]
})
export class NotFoundPageComponent {
	route: string;

	constructor(private router: Router,
				private destroy$: DestroyService,
				private location: Location,
				private navigationService: NavigationService) {
		this.route = this.navigationService.getPreviousUrl() === '/404' ? '' : this.navigationService.getPreviousUrl();
	}
}
