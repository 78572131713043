<ng-container *ngIf="currentAppUserView | async">
  <div
    #cardsContainer
    class="pd-carousel-cards-wrapper"
    [ngClass]="{'empty' : (dataSource.data | async).length <= 0}">
    <pdw-works-category-card-slider
      [dataSource]="dataSource"
      [columnsCount]="columnsCount"
      [cardWidth]="minCardWidth"
    >
    </pdw-works-category-card-slider>
  </div>
</ng-container>

