import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	private history: Array<string> = [];

	constructor(private router: Router, private location: Location) {
	}

	public initHistory(): void {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				if (this.history.length) {
					localStorage.setItem('previousUrl', this.history[this.history.length - 1]);
				}
				this.history.push(event.urlAfterRedirects);
			}
		})
	}

	public getHistory(): string[] {
		return this.history;
	}

	public goBack(): void {
		this.history.pop();

		if (this.history.length > 0) {
			this.location.back()
		} else {
			this.router.navigateByUrl('/');
		}
	}

	public getPreviousUrl(): string {
		if (localStorage.getItem('previousUrl')) {
			return this.isLast404(localStorage.getItem('previousUrl'));
		}

		if (this.history.length > 0) {
			return this.isLast404(this.history[this.history.length - 1]);
		}

		return '';
	}

	private isLast404(route: string): string {
		if (route === '/404') {
			localStorage.setItem('previousUrl', '');
			this.history.push('');
		}
		return route === '/404' ? '' : route;
	}

}
