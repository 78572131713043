import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { filter, takeUntil } from 'rxjs/operators';
import { WorkFileView } from '@models/work-file-view';
import { WorkFileService } from '@core/services/work-file.service';
import { WorkAbstractComponent } from '@shared/work-abstract-component/work-abstract-component';
import { DestroyService } from '@profdepo-ui/core';

@Component({
	selector: 'app-work-files',
	templateUrl: './work-files.component.html',
	providers: [DestroyService]
})
export class WorkFilesComponent extends WorkAbstractComponent implements OnInit {
	workFileDataSource = new MatTableDataSource<WorkFileView>();
	constructor(
		private workFileService: WorkFileService,
		private destroy$: DestroyService
	) {
		super();
	}

	ngOnInit(): void {
		this.workViewSubject
			.pipe(
				filter(x => x !== null),
				takeUntil(this.destroy$)
			)
			.subscribe(x => {
				this.workFileDataSource = new MatTableDataSource<WorkFileView>(x.files);
			});
	}

	goDownload(workFileView: WorkFileView): void {
		this.workFileService.download(workFileView);
	}
}
