import {AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {AppUserMarksDataSource} from "@core/dataSources/app-user-marks-data-source";
import {BehaviorSubject, fromEvent} from "rxjs";
import {AppUserView} from "@models/app-user-view";
import {distinctUntilChanged, filter, map, take, takeUntil} from "rxjs/operators";
import {WorkExecutorMarkService} from "@core/services/work-executor-mark.service";
import {calcColumnsCount, onResizeCardsContainer} from "@utils/functions";
import { DestroyService } from '@profdepo-ui/core';

@Component({
  selector: 'pdw-app-user-marks',
  templateUrl: './app-user-marks.component.html',
})
export class AppUserMarksComponent implements OnInit, AfterViewInit {
  columnsCount: number = 0;
  minCardWidth: number = 392;
  @ViewChild('cardsContainer', {read: ElementRef}) cardsContainer: ElementRef;
  dataSource: AppUserMarksDataSource;

  @Input()
  set currentUserView(value: AppUserView) {
    this._currentUserView.next(value);
  };

  get currentUserView(): AppUserView {
    return this._currentUserView.getValue();
  }

  private _currentUserView = new BehaviorSubject<AppUserView>(null);

  constructor(
    private workExecutorMarkService: WorkExecutorMarkService,
    private zone: NgZone,
    private destroy$: DestroyService,
  ) {
  }

  ngOnInit() {
    this._currentUserView
      .pipe(
        filter(x => x !== null),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: appUserView => {
          this.dataSource = new AppUserMarksDataSource(appUserView, this.workExecutorMarkService);
        }
      });

    fromEvent(window, 'resize')
      .pipe(
        takeUntil(this.destroy$),
        map(() => this.cardsContainer.nativeElement.offsetWidth),
        distinctUntilChanged()
      ).subscribe({
      next: x =>{
        this.minCardWidth = onResizeCardsContainer(this.cardsContainer);
        this.columnsCount = calcColumnsCount(this.cardsContainer, this.minCardWidth);
      }
    });
  }

  ngAfterViewInit() {
    this.zone.onStable
      .pipe(
        filter(() => this.cardsContainer !== undefined),
        distinctUntilChanged(),
      )
      .subscribe(columnsCount => {
        window.dispatchEvent(new Event('resize'));
      });
  }

}
