<pdw-dialog-header title='Внимание'>
</pdw-dialog-header>
<pdw-dialog-body>
    <div class='pd-basis-card-empty-inner'>
        <mat-icon class='pd-icon change-role-dialog-icon' [svgIcon]="'additional2-alert-question-2-100-100'"></mat-icon>
        <div class="change-role-dialog-text-wrapper">
            <h2 class='title'>Вы действительно хотите сменить роль на: <span class="bold">"{{
                    changeTypeBtnTitle(activeUser.type) }}"</span></h2>
            <p class='subtitle'>Вы всегда можете сменить роль в личном кабинете.</p>
        </div>
    </div>
</pdw-dialog-body>
<div class='footer pd-dialog-footer'>
    <button mat-raised-button class='pd-button-secondary large' type='reset' [mat-dialog-close]='false'>
        Нет
    </button>
    <button mat-raised-button class='pd-button' (click)="goChangeType()">
        Да
    </button>
</div>