import {EventEmitter, Injectable} from '@angular/core';
import {environment} from "@env/environment";
import {HubConnection, HubConnectionBuilder, HubConnectionState} from "@microsoft/signalr";
import {BehaviorSubject} from "rxjs";
import {WorkRequestChatMessageView} from "@models/work-request-chat-message-view";
import {WorkRequestView} from "@models/work-request-view";
import {OneMinuteReconnectPolicy} from "@core/services/notice.service";

@Injectable({
  providedIn: 'root'
})
export class ChatService{
  private hubConnection: HubConnection;
  private reconnectionPolicy = new OneMinuteReconnectPolicy();

  chatMessageReceived = new EventEmitter<WorkRequestChatMessageView>();
  chatMessagesUpdated = new EventEmitter<boolean>();
  workRequestLastMessageUpdated = new EventEmitter<WorkRequestView>();
  workRequestAcceptUpdate = new EventEmitter<WorkRequestView>();
  chatMessagesListUpdated = new EventEmitter<WorkRequestChatMessageView[]>();
  chatMessageEdit = new EventEmitter<WorkRequestChatMessageView>();
  chatMessageDelete = new EventEmitter<number>();
  connectionEstablished = new BehaviorSubject<boolean>(false);
  joined = new BehaviorSubject<boolean>(false);
  reconnectionEvent = new EventEmitter();

  constructor() {
    this.createConnection();
    this.startConnection();
  }

  public reConnection(): void {
    if (this.hubConnection.state === HubConnectionState.Disconnected)
    {
      this.startConnection();
    }
  }

  private createConnection() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(environment.baseUrl + '/Chat', {
        accessTokenFactory: () => {
          return window.localStorage.getItem('auth_token'); },
      })
      .withAutomaticReconnect(this.reconnectionPolicy)
      .build();
  }

  private startConnection(): void {
    this.registerServerEvents();
    this.hubConnection
      .start()
      .then(() => {
        this.connectionEstablished.next(true);
      })
      .then(() => {
        return this.join();
      })
      .catch(err => {
        console.warn('Error while start connection, retrying: ', err);
      });

    this.hubConnection.onreconnecting(() => {
      this.connectionEstablished.next(false);
    });

    this.hubConnection.onreconnected(() =>{
      this.connectionEstablished.next(true);
      this.join().then(() => {
        console.log("reconnection event");
        if(this.joined.value)
          this.reconnectionEvent.emit();
      });
    })
  }

  public endConnection(): Promise<void> {
    if (this.hubConnection) {
      this.unregisterServerEvents();
      return this.leave()
        .then(() => {
          return this.hubConnection.stop()
        })
        .then(() => {
          console.log('endConnection')
          this.connectionEstablished.next(false);
        })
        .catch(err => {
          console.warn('Error while end connection: ', err);
        });
    }
    return new Promise<void>(null);
  }

  public async join(): Promise<string> {
    return this.hubConnection
      .invoke('Join')
      .then((value) => {
        console.warn('join chatService hubConnection state: ', value, this.hubConnection.state);
        this.joined.next(true);
        return value;
      })
      .catch(err => {
        console.warn('Error while join: ', err);
      });
  }

  public async leave(): Promise<string> {
    return this.hubConnection
      .invoke('Leave')
      .then((x) => {
        this.joined.next(false);
        return x;
      })
      .catch(err => {
        console.warn('Error while leave: ', err);
      });
  }

  /**
   * Войти в чат
   * @param action наименование (обозначение) чата
   * @param args аргументы чата необходимые для уникальности наименования
   */
  public async joinChat(action: string, args: string): Promise<string> {
    console.warn('joinChat hubConnection state: ', this.hubConnection.state);
    if (this.hubConnection && this.hubConnection.state === HubConnectionState.Connected) {
      return this.hubConnection
        .invoke('JoinChat', action, args)
        .then((value) => {
          console.warn('JoinChat value: ', value);
          return value;
        })
        .catch(err => {
          console.warn('Error while join: ', err);
        });
    }
    console.warn('Invalid hub connection on join chat! Check connection or state!')
    return new Promise<string>(null);
  }

  /**
   * Покинуть чат
   * @param action наименование (обозначение) чата
   * @param args аргументы чата необходимые для уникальности наименования
   */
  public async leaveChat(action: string, args: string): Promise<string> {
    console.warn('joinChat hubConnection state: ', this.hubConnection.state);
    if (this.hubConnection && this.hubConnection.state === HubConnectionState.Connected) {
      return this.hubConnection
        .invoke('LeaveChat', action, args)
        .then((value) => {
          console.warn('leaveChat value: ', value);
          return value;
        })
        .catch(err => {
          console.warn('Error while leave: ', err);
        });
    }
    console.warn('Invalid hub connection on leave chat! Check connection or state!')
    return new Promise<string>(null);
  }

  /**
   * Подписка на события поступающие от хаба
   * @private
   */
  private registerServerEvents(): void {
    this.hubConnection.on('MessageReceived', (data: any) => {
      this.chatMessageReceived.emit(data);
    });
    this.hubConnection.on('MessagesUpdated', (data: any) => {
      this.chatMessagesUpdated.emit(data);
    });
    this.hubConnection.on('MessagesListUpdated', (data: any) => {
      console.log('MessagesListUpdated from service');
      this.chatMessagesListUpdated.emit(data);
    });
    this.hubConnection.on('WorkRequestAcceptUpdate', (data: any) => {
      this.workRequestAcceptUpdate.emit(data);
    });
    this.hubConnection.on('WorkRequestLastMessageUpdated',(data:any) =>{
      console.log('WorkRequestLastMessageUpdated from service');
      this.workRequestLastMessageUpdated.emit(data);
    });
    this.hubConnection.on('WorkRequestChatMessageEdit', (EditChatMessage: WorkRequestChatMessageView) =>{
      this.chatMessageEdit.emit(EditChatMessage);
    });
    this.hubConnection.on('WorkRequestChatMessageDelete', (workRequestChatMessageId : number) => {
      this.chatMessageDelete.emit(workRequestChatMessageId);
    });
  }

  /**
   * Отписка от событий поступающих от хаба
   * @private
   */
  private unregisterServerEvents(): void {
    this.hubConnection.off('MessagesListUpdated');
    this.hubConnection.off('WorkRequestAcceptUpdate');
    this.hubConnection.off('WorkRequestLastMessageUpdated');
    this.hubConnection.off('ViewersChanged');
    this.hubConnection.off('MessagesUpdated');
    this.hubConnection.off('MessageReceived');
    this.hubConnection.off('WorkRequestChatMessageEdit');
    this.hubConnection.off('WorkRequestChatMessageDelete');
  }
}
