import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'companyFilterDialog'
})
export class CompanyFilterDialogPipe extends AbstractModelPipe implements PipeTransform {

  properties = [
    {name: 'industriesIds', title: 'Сферы деятельности', hint: 'Введите сферу деятельности'},
    {name: 'ratingMoreThen', title: 'Рейтинг не менее', hint: 'Рейтинг не менее'}
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
  allProperties() {
    return this.properties;
  }
}
