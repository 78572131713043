<ng-container *ngIf='!(isLoading | async)'>
	<ng-container *ngIf='hasData; else noData'>
		<div class='pd-work-plate-history'>
			<mat-expansion-panel
				class='pd-expansion-panel pd-work-history-panel'
				[hideToggle]='true'
				[expanded]='true'
			>
				<mat-expansion-panel-header>
					<div class='pd-work-title'>
						<h2 class='pd-work-title-text'>Заявки на изменение</h2>
						<span
							*ngIf='activeHistoriesCount > 0'
							class='pd-badge pd-badge-active'
						>{{activeHistoriesCount}}</span>
					</div>
					<button
						class='pd-icon-button'
						mat-icon-button
					>
						<mat-icon
							class='pd-icon pd-profile-block-toggle-icon'
							svgIcon='additional-chevron-down'
						>
						</mat-icon>
					</button>
				</mat-expansion-panel-header>
				<mat-table
					[dataSource]='dataSource'
					class='pd-work-history-table'
					multiTemplateDataRows
				>
					<!-- Info Column -->
					<ng-container matColumnDef='info'>
						<mat-cell
							*matCellDef='let element'
							class='pd-work-history-info-column'
						>
							<p class='pd-work-history-create-time'>
								{{ element.createTime | date:'dd.MM.YYYY - HH:mm'}}
							</p>
							<p class='pd-work-history-creator'>
								{{ creatorName(element) }}
							</p>
							<ng-container *ngIf='!isDescriptionRow(0, element); else descriptionValue'>
								<p
									class='pd-work-history-value'
									[class.accept-cell]="!isDraftHistory(element) && !isRejectHistory(element)"
									[ngClass]="{
										'draft' : isDraftHistory(element),
										'reject-cell': isRejectHistory(element)
									}"
								>
									{{getWorkHistoryStateTitle(element)}}
								</p>
							</ng-container>
							<ng-template #descriptionValue>
								<div class='pd-work-history-description-btn-wrapper'>
									<button
										class='pd-button-transparent pd-work-history-description-btn revert-font-color'
										(click)='(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()'
										[ngClass]="{'draft' : isDraftHistory(element)}"
									>
										<p class='pd-button-transparent-text'>
											{{getValueString(element)}}
										</p>
									</button>
								</div>
							</ng-template>
						</mat-cell>
					</ng-container>

					<!-- Action Column -->
					<ng-container matColumnDef='action'>
						<mat-cell
							*matCellDef='let element'
							class='pd-work-history-action-column'
						>
							<button
								*ngIf="canChangeState(element) | async"
								form='filterForm'
								mat-raised-button
								class='pd-button-secondary'
								type='button'
								(click)='onEdit(element)'
							>
								Перейти к изменениям
							</button>
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef='expandedDescription'>
						<mat-cell
							*matCellDef='let element'
							class='pd-work-history-expanded-column'
						>
							<div class='pd-work-history-expanded-filler'></div>
							<p
								[innerHTML]='(element.value.stringValue | appPlainTextFormat)'
								class='pd-work-history-expanded-description'
							>
							</p>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef='fakeColumn'>
						<mat-cell
							*matCellDef='let element'
							class='pd-work-history-fake-column'
						>

						</mat-cell>
					</ng-container>

					<mat-row
						class='pd-work-history-row'
						*matRowDef='let row; columns: displayedColumns;'
						[ngClass]="{'reject' : isRejectHistory(row), 'description-row': isDescriptionRow(0, row)}"
					></mat-row>
					<mat-row
						[@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'"
						class='pd-work-history-expanded-row'
						*matRowDef="let row; columns: ['expandedDescription', 'fakeColumn']; when: isDescriptionRow"
					></mat-row>
				</mat-table>
			</mat-expansion-panel>
		</div>
	</ng-container>
	<ng-template #noData>
		<div class='pd-work-history-no-data'>
			<mat-icon
				class='pd-icon'
				[svgIcon]="'additional2-info'"
			></mat-icon>
			Нет заявок на изменение
		</div>
		<h1></h1>
	</ng-template>
</ng-container>
