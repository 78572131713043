import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { MenuItem } from '@core/services/side-menu.service';
import { Router } from '@angular/router';

@Component({
	selector: 'pdw-menu-accordion-link',
	templateUrl: './menu-accordion-link.component.html',
	styleUrls: ['./menu-accordion-link.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class MenuAccordionLinkComponent {
	@Input() menuItem: MenuItem
	@Input() hideToggle: boolean = true;
	@Input() expanded: boolean = false;

	constructor(private router: Router) {
	}

	isActiveRoute(): boolean {
		return this.menuItem.children.some(route => this.router.url.includes(route.linkTo.toString()))
	}

}
