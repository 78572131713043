import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bank-account-form',
  templateUrl: './bank-account-form.component.html',
})
export class BankAccountFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
