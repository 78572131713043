import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { CompanyView } from '@models/company-view';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { AnySort } from '@utils/any-sort';
import { WorkService, WorksFilter } from '@core/services/work.service';
import { calcColumnsCount, onResizeCardsContainer } from '@utils/functions';
import { WorkStateValue } from '@models/work-view';
import { UtilsService } from '@core/services/utils.service';
import { AllWorkDataSource } from '@core/dataSources/all-work-data-source';
import { ManagerWorksDataSource } from '@core/dataSources/manager-works-data-source';
import { ManagerWorksDataSourceShort } from '@core/dataSources/manager-works-data-source-short';
import { AllWorkDataSourceShort } from '@core/dataSources/all-work-data-source-short';
import { DestroyService } from '@profdepo-ui/core';

@Component({
	selector: 'app-company-works-brief',
	templateUrl: './company-works-brief.component.html',
	styleUrls: ['./company-works-brief.component.scss'],
	providers: [DestroyService]
})
export class CompanyWorksBriefComponent implements OnInit {

	columnsCount: number = 0;
	minCardWidth: number = 392;
	@ViewChild('cardsContainer', { read: ElementRef }) cardsContainer: ElementRef;

	dataSource: ManagerWorksDataSource | AllWorkDataSource;
	dataSourceShort: ManagerWorksDataSourceShort | AllWorkDataSourceShort;
	sortValues = new AnySort('name', 'asc');
	filterValues = new WorksFilter();
	currentToggleValue$ = new BehaviorSubject<number>(0);
	currentVacancyCount$ = new BehaviorSubject<number>(0);
	currentFreelanceCount$ = new BehaviorSubject<number>(0);
	
	@Input()
	set companyView(value: CompanyView) {
		this._companyView.next(value);
	};

	get companyView(): CompanyView {
		return this._companyView.getValue();
	}

	@Input() canUpdate: boolean = false;

	private _companyView = new BehaviorSubject<CompanyView>(null);
	currentCompanyView = new BehaviorSubject<CompanyView>(null);

	constructor(
		private workService: WorkService,
		private zone: NgZone,
		private utilsService: UtilsService,
		private destroy$: DestroyService,
	) {
	}

	ngOnInit(): void {
		this._companyView
			.pipe(
				takeUntil(this.destroy$),
				filter(x => x !== null),
			)
			.subscribe((companyView: CompanyView) => {
				this.currentCompanyView.next(companyView);
				this.filterValues.states = [WorkStateValue.seeking];
				this.filterValues.companies = [companyView.id];
				if (this.canUpdate) {
					this.dataSource = new ManagerWorksDataSource(this.workService);
					this.dataSourceShort = new ManagerWorksDataSourceShort(this.workService)
				} else if (!this.canUpdate) {
					this.dataSource = new AllWorkDataSource(this.workService);
					this.dataSourceShort = new AllWorkDataSourceShort(this.workService);
				}
			});

		this.getVacancyAllCount();
		this.getFreelanceAllCount();

		fromEvent(window, 'resize')
			.pipe(
				takeUntil(this.destroy$),
				map(() => this.cardsContainer.nativeElement.offsetWidth),
				distinctUntilChanged()
			).subscribe({
			next: x => {
				this.minCardWidth = onResizeCardsContainer(this.cardsContainer,
					this.utilsService.mobileQuery980px.value ? 0 : -80);
				this.columnsCount = calcColumnsCount(this.cardsContainer, this.minCardWidth, this.utilsService.mobileQuery980px.value ? 0 : -80);
			}
		});
	}

	ngAfterViewInit() {
		this.zone.onStable
			.pipe(
				filter(() => this.cardsContainer !== undefined),
				distinctUntilChanged(),
			)
			.subscribe(columnsCount => {
				window.dispatchEvent(new Event('resize'));
			});
	}

	onChange(event): void {
		this.currentToggleValue$.next(event.value[0]);

		if (event.value[0] === 0) {
			const tmpFilter = new WorksFilter();
			tmpFilter.states = [1];
			tmpFilter.isFreelance = 0;
			if (!this.canUpdate) {
				tmpFilter.companies = [this.companyView.id];
			}
	
			tmpFilter.companies = [this.companyView.id];
			this.filterValues = tmpFilter;
			return;
		}
		const tmpFilter = new WorksFilter();
		tmpFilter.states = event.value;
		tmpFilter.isFreelance = 1;
		if (!this.canUpdate) {
			tmpFilter.companies = [this.companyView.id];
		}

		tmpFilter.companies = [this.companyView.id];
		this.filterValues = tmpFilter;
	}

	onChangeToggle(event) {
		this.currentToggleValue$.next(event.value[0]);
		const tmpFilter = new WorksFilter();
		tmpFilter.states = [1];
		tmpFilter.isFreelance = event.value[0];
		if (!this.canUpdate) {
			tmpFilter.companies = [this.companyView.id];
		}

		this.filterValues = tmpFilter;
	}

	getVacancyAllCount() {
		const queryVacancy = new WorksFilter();
		queryVacancy.states = [1];
		queryVacancy.isFreelance = 0;
		queryVacancy.companies = [this.companyView.id];
		this.workService.allCount(queryVacancy).pipe(takeUntil(this.destroy$)).subscribe(value => {
			this.currentVacancyCount$.next(value);
		});
	}

	getFreelanceAllCount() {
		const queryFreelance = new WorksFilter();
		queryFreelance.states = [1];
		queryFreelance.isFreelance = 1;
		queryFreelance.companies = [this.companyView.id];
		this.workService.allCount(queryFreelance).pipe(takeUntil(this.destroy$)).subscribe(value => {
			this.currentFreelanceCount$.next(value);
		});
	}
}
