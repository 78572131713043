import { ElementRef } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Добавляет fakeState в историю браузера. Использовать при открытии диалоговых окон.
 */
export const pushFakeHistoryState = () => {
	history.pushState({ fakeState: true }, null);
}

/**
 * Возвращает минимальную ширину карточки, в зависимости от размеров контейнера
 * @param cardsContainer
 * @param addingWidth
 */
export const onResizeCardsContainer = (cardsContainer: ElementRef, addingWidth: number = 0): number => {
	let minCardWidth: number = 0;
	const width = cardsContainer.nativeElement.offsetWidth + addingWidth;
	if (width < 1280 && width > 450) {
		minCardWidth = 378;
	} else if (width > 1280) {
		minCardWidth = 393;
	} else if (width < 450) {
		minCardWidth = 280;
	}

	return minCardWidth;
}

export const changeFilterPosOnResize = (filterContainer: ElementRef, width: number): number => {
	let result = 0;

	if (width >= 1220) {
		const diff = window.innerHeight - filterContainer.nativeElement.scrollHeight;
		result = diff < 92 ? diff : 92;
	} else if (width < 1220 && width > 980) {
		result = 92;
	} else if (width < 980) {
		result = 0
	}

	return result
}

/**
 * Возвращает количество колонок для карточек
 * @param cardsContainer
 * @param minCardWidth
 * @param addingWidth
 */
export const calcColumnsCount = (cardsContainer: ElementRef, minCardWidth: number, addingWidth: number = 0): number => {
	const width = cardsContainer.nativeElement.offsetWidth + addingWidth;
	return Math.floor((width + 20) / (minCardWidth + 20));
}

/**
 * Использовать для TUI_ICONS_PATH, как useValue. В компоненте, где для тайги нужны кастомные иконки
 * У иконки в теге <svg> обязательно должен быть id!!!
 * @param name имя иконки
 */
export function pdIconPath(name: string): string {
	return `assets/icons/${name.split('-')[0]}/${name}.svg#${name}`;
}

/**
 * Возвращает цвета для рейтинга от 0 до 100
 * @param value
 */
export const hundredRatingColorClass = (value: number): string => {
	if (value <= 24)
		return 'main-red';

	if (value > 24 && value <= 50)
		return 'main-orange';

	if (value > 50)
		return 'main-green'

	return '';
}

export const hundredRatingColorStyle = (value: number): string => {
	if (value <= 24)
		return 'var(--pd-base-02)';

	if (value > 24 && value <= 50)
		return 'var(--pd-base-04)';

	if (value > 50)
		return 'var(--pd-base-05)'

	return '';
}

/**
 * Валидируем контрол формы на дату не ранее переданого аргумента в milliseconds
 * @param value
 */
export function dateNotEarlierValidator(value: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (control.value === null) {
			return null;
		}
		const isEarlier = new Date(control.value) < new Date(value);
		return isEarlier ? { dateIsEarlier: true } : null;
	};
}

/**
 * Валидируем контрол формы на пустое строковое значение
 */
export function onlySpaceValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (control.value === null) {
			return null;
		}
		if (typeof control.value !== 'string') {
			return null;
		}
		if (!control.value.length) {
			return null;
		}
		const isNotOnlySpace = Boolean(control.value.trim());
		return isNotOnlySpace ? null : { onlySpace: true };
	};
}

/**
 * Валидируем контрол формы на дату не позднее переданого аргумента в milliseconds
 */
export function dateNotLaterValidator(value: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (control.value === null) {
			return null;
		}
		const isLater = new Date(control.value) > new Date(value);
		return isLater ? { dateIsLater: true } : null;
	};
}
