import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AppUserView } from "@models/app-user-view";
import { AppUserInnDialogComponent } from "@shared/app-user-inn-dialog/app-user-inn-dialog.component";
import { BehaviorSubject } from "rxjs";
import { ConfirmationDialogType, DestroyService } from "@profdepo-ui/core";
import { AppUserInnStepThreeConfirmationDialogComponent } from "@shared/app-user-inn-step-three-confirmation/app-user-inn-step-three-confirmation.component";
import { AppUserInnStepTwoConfirmationDialogComponent } from "@shared/app-user-inn-step-two-confirmation/app-user-inn-step-two-confirmation.component";
import { AppUserInnNoteConfirmationDialogComponent } from "@shared/app-user-inn-note-confirmation/app-user-inn-note-confirmation.component";
import { AppUserRequisitesDialogComponent } from "@shared/app-user-requisites-dialog/app-user-requisites-dialog.component";
import { AppUserRequisitesNoteConfirmationDialogComponent } from "@shared/app-user-requisites-note-confirmation/app-user-requisites-note-confirmation.component";


@Injectable({
	providedIn: 'root'
})
export class AppUserInnDialogService {
	constructor(
		private dialog: MatDialog
	) {}

	showInnVerificationStepOneDialog(currentAppUserView: BehaviorSubject<AppUserView>, destroy$: DestroyService): void {
		this.dialog.open(AppUserInnDialogComponent, {
		  autoFocus: true,
		  disableClose: false,
		  width: '581px',
		  minHeight: '320px',
		  panelClass: ['pd-dialog'],
		  data: {
			title: 'Шаг 1. Проверка ИНН',
			appUserView: currentAppUserView.value,
			parentUnsubscribe: destroy$,
		  }
		})
	}

	showInnVerificationStepThreeDialog(message: string, date: Date, type: ConfirmationDialogType, isNextBtnDisabled: boolean): MatDialogRef<AppUserInnStepThreeConfirmationDialogComponent> {
		return this.dialog.open(AppUserInnStepThreeConfirmationDialogComponent, {
			autoFocus: true,
			disableClose: false,
			width: '581px',
			panelClass: 'pd-dialog',
			data: {
				title: 'Шаг 3. Проверка статуса самозанятого',
				message,
				value: date,
				questionMark: true,
				type,
				isNextBtnDisabled
			}
			})
	}

	showInnVerificationStepTwoDialog(innValue: string, type: ConfirmationDialogType): MatDialogRef<AppUserInnStepTwoConfirmationDialogComponent> {
		return this.dialog.open(AppUserInnStepTwoConfirmationDialogComponent, {
			autoFocus: true,
			disableClose: false,
			width: '581px',
			panelClass: 'pd-dialog',
			data: {
			  title: 'Шаг 2. Проверка статуса самозанятого',
			  message: 'Ваш ИНН',
			  value: innValue,
			  questionMark: true,
			  type,
			}
		  })
	}

	showInnNoteConfirmationDialog(type: ConfirmationDialogType): MatDialogRef<AppUserInnNoteConfirmationDialogComponent> {
		return this.dialog.open(AppUserInnNoteConfirmationDialogComponent, {
			autoFocus: true,
			disableClose: false,
			width: '581px',
			panelClass: 'pd-dialog',
			data: {
			  title: 'Важно',
			  message: 'Без статуса самозанятого Вы не можете заключать сделки на платформе',
			  questionMark: true,
			  type,
			}
		  })
	}

	showRequisitesStepFourDialog(currentAppUserView: AppUserView): MatDialogRef<AppUserRequisitesDialogComponent> {
		return this.dialog.open(AppUserRequisitesDialogComponent, {
			autoFocus: true,
			disableClose: false,
			width: '581px',
			panelClass: 'pd-dialog',
			data: {
			  title: 'Шаг 4. Заполнение платежных реквизитов',
			  appUserView: currentAppUserView
			}
		  })
	}

	showRequisitesNoteConfirmationDialog(type: ConfirmationDialogType): MatDialogRef<AppUserRequisitesNoteConfirmationDialogComponent> {
		return this.dialog.open(AppUserRequisitesNoteConfirmationDialogComponent, {
			autoFocus: true,
			disableClose: false,
			width: '581px',
			panelClass: 'pd-dialog',
			data: {
			  title: 'Внимание!',
			  message: 'Без указания платежных реквизитов вы не сможете заключать сделки на платформе и получать вознаграждения',
			  questionMark: true,
			  type,
			}
		  })
	}
}
