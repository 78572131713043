import { Component, Input, OnInit } from '@angular/core';
import { AppUserView } from '@models/app-user-view';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { number2string } from '@utils/helpers/string-helpers';
import { DestroyService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-user-rating',
	templateUrl: './app-user-rating.component.html',
	providers: [DestroyService]
})
export class AppUserRatingComponent implements OnInit {
	@Input()
	set currentUserView(value: AppUserView) {
		this._currentUserView.next(value);
	};

	get currentUserView(): AppUserView {
		return this._currentUserView.getValue();
	}

	private _currentUserView = new BehaviorSubject<AppUserView>(null);
	currentAppUserView = new BehaviorSubject<AppUserView>(null);

	constructor(private destroy$: DestroyService) {
	}

	ngOnInit(): void {
		this._currentUserView
			.pipe(
				filter(x => x !== null),
				takeUntil(this.destroy$)
			)
			.subscribe((appUserView: AppUserView) => {
				this.currentAppUserView.next(appUserView);
			});

	}
}
