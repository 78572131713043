import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contentTypeToExtension'
})
export class ContentTypeToExtensionPipe implements PipeTransform {
  transform(value: string): unknown {
    switch (value) {
      case 'image/jpg':
      case 'image/jpeg':
      case 'image/png':
      case 'image/tif':
      case 'image/tiff':
        return value.substring(6);
      case 'image/svg+xml':
        return 'svg';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'xlsx';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'docx';
      case 'application/pdf':
        return 'pdf';
      default:
        return 'blank';
    }
  }
}
