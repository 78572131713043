import { Pipe, PipeTransform } from '@angular/core';
import {AbstractExtensionEnumPipe} from "@public/pipes/abstract-extension-enum.pipe";

@Pipe({
  name: 'companySpecTag'
})
export class CompanySpecTagPipe extends AbstractExtensionEnumPipe implements PipeTransform {
  values = {
    0: "Избранное",
    1: "Все",
  };

  transform(value: any, ...args: any[]): any {
    return this.getTransform(value, args);
  }
}
