import {WorkView} from "@models/work-view";
import {AbstractModifiedModelView} from "@models/abstract-modified-model-view";

export class AbstractWorkFileView extends AbstractModifiedModelView {
  public id: number;
  public work: WorkView;
  public title: string;
  public name: string;
  public description: string;
  public extension: string;
  public size: number;
  public contentType: string;
  public sourceFilename: string;
  public temporaryFilename: string;
}
