import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-chips-list',
  templateUrl: './chips-list.component.html',
  styleUrls: ['./chips-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipsListComponent  {
  @Input() isHorizontal: boolean = false;
}
