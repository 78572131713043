import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  // @ts-ignore
  private urlSource = new BehaviorSubject<string>(undefined);
  constructor(
    private router: Router,
  ) {
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.urlSource.next(event.urlAfterRedirects);
        }
      });
  }
  currentUrl = this.urlSource.asObservable();
}
