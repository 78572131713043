import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'skillState'
})
export class SkillStatePipe extends AbstractModelPipe implements PipeTransform {
  properties = [
    {name: 'skill', title: 'Специализация', hint: 'Специализация'},
    {name: 'isLast', title: 'Последнее', hint: 'Последнее значение'},
    {name: 'value', title: 'Значение', hint: 'Значение'},
    {name: 'сreateAppUser', title: 'Создал', hint: 'Автор изменения'},
    {name: 'createtime', title: 'Дата', hint: 'Дата изменения'},
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
  allProperties() {
    return this.properties;
  }
}
