import { InjectionToken } from "@angular/core";

/**
 * Номер телефона в международном формате regexp
 */
export const regexpInternationalPhoneNumber =
	/^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/;
/**
 * regex для латинского и русского алфавитов, включая пробелы и дефисы
 */
export const personNamesRegEx = /^[a-zа-яё\-\s]+$/iu;
/**
 * regex для серии и номера паспорта
 */
export const passportNumberRegEx = /^([0-9]{4}\s{1}[0-9]{6})?$/;
/**
 * regex для расчетного счета
 */
export const accountNumberRegEx = /^([0-9]{20})?$/;
/**
 * regex для бик банка
 */
export const bikNumberRegEx = /^([0-9]{9})?$/;
/**
 * mask для расчетного счета
 */
export const accountNumberMask = [
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
];
/**
 * mask для бик банка
 */
export const bikNumberMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
/**
 * mask для для даты в формате dd.MM.yyyy
 */
export const dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];
/**
 * regex для email
 */
export const emailRegEx = /^[a-zA-Z0-9а-яА-Я.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9а-яА-Я-]+(\.[a-zA-Zа-яА-Я_.-]{2,})+$/;
/**
 * дата 01.01.1900 в ms
 */
export const DATE_01011900_MS = -2209003353000;

export const phoneNumberMask = [
	"+",
	"7",
	" ",
	"(",
	/[0-9]/,
	/[0-9]/,
	/[0-9]/,
	")",
	" ",
	/[0-9]/,
	/[0-9]/,
	/[0-9]/,
	"-",
	/[0-9]/,
	/[0-9]/,
	"-",
	/[0-9]/,
	/[0-9]/,
];

/**
 * enum строковых значений сообщений об ошибках сервера
 */
enum ServerErrorsMessages {
	changePassFormEmailErrorMsg = "Пользователь с таким адресом уже зарегистрирован!",
}

/**
 * маппинг сообщений ошибок сервера и элементов валидации
 */
export const ServerErrorsVsFormControlsMap = {
	[ServerErrorsMessages.changePassFormEmailErrorMsg]: "email",
};
/**
 * pattern валидации пароля
 */
export const passwordPattern = "^(?=.*[A-ZА-Я])(?=.*[a-zа-я])(?=.*\\d).{8,20}$";
export const EMAIL_REGEX = new InjectionToken<string>("regex.email");
export const PASSWORD_REGEX = new InjectionToken<string>("regex.password");
