import {AppUserView} from "@models/app-user-view";
import {YesNo} from "@models/enums";
import {CompanyView} from "@models/company-view";

export class ExperienceView {
  public id: number;
  public appUser: AppUserView;
  public startDate: Date;
  public endDate: Date | null;
  public tillNow: YesNo;
  public companyName: string;
  public company: CompanyView;
  public position: string;
  public description: string;
  public createAppUser: AppUserView;
  public modifyAppUser: AppUserView;
  public createTime: Date;
  public modifyTime: Date;
}
