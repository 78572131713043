<mat-form-field
  class="pd-input-form-field pd-input-form-field-without-border"
  appearance="fill"
  hideRequiredMarker
  #formField>
  <mat-chip-list
    class="pd-chip-list"
    #chipList
  >
    <mat-icon class="pd-field-prefix-icon gray" svgIcon="general-search"></mat-icon>
    <ng-container *ngIf="!mobileQuery.matches; else oneChip">
      <tui-scrollbar appHorizontalScrollable class="pd-filter-scroll-in-mat pd-scrollbar">
        <mat-chip
          class="pd-chip"
          *ngFor="let filter of activeFilters | async"
          (removed)="removeFilter(filter.name)"
          [disableRipple]="true"
        >
          {{ titleFilter(filter) }}
          <span class="filter-value" [matTooltip]="tooltipFilter(filter)">
          : {{ valueFilter(filter) }}
        </span>
          <button class="pd-chip-remove-btn" matChipRemove>
            <mat-icon svgIcon="interface-close-small"></mat-icon>
          </button>
        </mat-chip>
      </tui-scrollbar>
    </ng-container>
    <ng-template #oneChip>
      <mat-chip
        class="pd-chip pd-chip-no-list"
        *ngIf="(activeFilters | async).length"
        [removable]="true"
        [disableRipple]="true"
      >
        <span class="filter-value">
          {{(activeFilters | async).length | numberToWordDeclension: ['фильтров', 'фильтр', 'фильтра'] : true}}
        </span>
        <button class="pd-chip-remove-btn" matChipRemove (click)="clearFilters()">
          <mat-icon svgIcon="interface-close-small"></mat-icon>
        </button>
      </mat-chip>
    </ng-template>
    <input
      type="text"
      matInput
      class="pd-chip-input"
      [placeholder]="(activeFilters | async).length ? '+ ' + placeholder : placeholder"
      [formControl]="anyFilter"
      [matChipInputFor]="chipList"
    >
    <div class="pd-input-suffix-two-btn" matSuffix>
      <ng-container *ngIf="!mobileQuery.matches">
        <button
          class="pd-icon-button with-line-height gray"
          mat-icon-button
          matTooltip="Очистить фильтр"
          *ngIf="(activeFilters | async).length || anyFilter.value"
          (click)="clearFilters()"
        >
          <mat-icon class="pd-icon" svgIcon="additional-close"></mat-icon>
        </button>
      </ng-container>
      <button
        class="pd-icon-button with-line-height gray"
        mat-icon-button
        matTooltip="Фильтр"
        (click)="goFilter()"
      >
        <mat-icon class="pd-icon" svgIcon="additional2-filter-alt"></mat-icon>
      </button>
    </div>
  </mat-chip-list>
</mat-form-field>
