<ng-container *ngIf='breadCrumbs'>
	<ng-container>
		<tui-scrollbar
			appHorizontalScrollable
			class='pd-scrollbar pd-bread-scroll'
		>
			<div
				class='pd-bread-wrapper'
				#links
			>
				<ng-container *ngIf='breadCrumbs.length === 1; else breadCrumbLinks'>
					<p class='pd-bread-text title'>{{breadCrumbs[0].label}}</p>
				</ng-container>
				<ng-template #breadCrumbLinks>
					<ng-container *ngFor='let item of breadCrumbs; first as isFirst; last as isLast'>
						<!--      itemLink.scrollWidth > 100 ? item.label : null-->
						<a
							class='pd-link'
							[matTooltip]='setToolTip(itemLink)'

							[class.first]='isFirst'
							*ngIf='!isLast'
							[routerLink]='item.path'
							[queryParams]='item.queryParams'
							#itemLink
						>{{item.label}}</a>
						<mat-icon
							*ngIf='!isLast'
							svgIcon='arrow-right-2'
							class='pd-bread-icon'
						></mat-icon>
						<p
							*ngIf='isLast'
							class='pd-bread-text last'
						>{{item.label}}</p>
					</ng-container>
				</ng-template>
			</div>
		</tui-scrollbar>
	</ng-container>
</ng-container>

