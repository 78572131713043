import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
export interface ConfirmationDialogData {
  title: string;
  message: string;
  value: string;
  questionMark: boolean;
  type: ConfirmationDialogType;
}

export enum ConfirmationDialogType {
  question = 1,
  warning
}

export enum StepTwoConfirmationDialogValue {
  check,
  noCheck
}
@Component({
  selector: 'app-user-inn-step-two-confirmation',
  templateUrl: './app-user-inn-step-two-confirmation.component.html',
})
export class AppUserInnStepTwoConfirmationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AppUserInnStepTwoConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
  ) {
  }

  onCheck(): void {
    this.dialogRef.close(StepTwoConfirmationDialogValue.check);
  }

  onNoCheck(): void {
    this.dialogRef.close(StepTwoConfirmationDialogValue.noCheck);
  }
}
