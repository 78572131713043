<form
	class='pd-recovery-form reset-password-without-margin'
	id='recoveryForm'
	[formGroup]='recoveryForm'
	(ngSubmit)='onSubmit(recoveryForm)'
>
	<div class='pd-form-hint forgot-password'>
		Успешно!<br>Придумайте новый пароль
	</div>
	<!--  Password   -->
	<mat-form-field
		class='pd-input-form-field custom-form-field'
		appearance='fill'
		hideRequiredMarker
	>
		<!--    <mat-icon class="pd-field-prefix-icon" svgIcon="additional-shield-done" matPrefix></mat-icon>-->
		<mat-label class='pd-auth-new-font'>Новый пароль</mat-label>
		<input
			matInput
			formControlName='password'
			[type]="passwordHide ? 'password' : 'text'"
		>
		<button
			mat-icon-button
			matSuffix
			class='pd-icon-button light-gray new-color'
			type='button'
			[attr.aria-label]="authPipe.transform('passwordHide', 'propertyTitle')"
			[attr.aria-pressed]='passwordHide'
			(click)='passwordHide = !passwordHide'
		>
			<mat-icon [svgIcon]="passwordHide ? 'additional-view' : 'additional-view-hide'"></mat-icon>
		</button>
		<mat-error
			*ngIf='password?.errors?.required'
			i18n='mat-error@@reset-password-password-required'
		>Обязательное поле
		</mat-error>
		<mat-error *ngIf='password?.errors?.pattern'>Пароль должен быть не менее 8 символов и содержать цифры и буквы большого и малого регистра</mat-error>
		<mat-error *ngIf='password?.errors?.serverError'>
			{{ password?.errors?.serverError }}
		</mat-error>
	</mat-form-field>
	<!--  PasswordConfirm   -->
	<mat-form-field
		class='pd-input-form-field custom-form-field'
		appearance='fill'
		hideRequiredMarker
	>
		<!--    <mat-icon class="pd-field-prefix-icon" svgIcon="interface-attention-circle" matPrefix></mat-icon>-->
		<mat-label class='pd-auth-new-font'>Повторите новый пароль</mat-label>
		<input
			matInput
			formControlName='passwordConfirm'
			[type]="passwordConfirmHide ? 'password' : 'text'"
			[errorStateMatcher]='matcher'
		>
		<button
			mat-icon-button
			matSuffix
			class='pd-icon-button light-gray new-color'
			type='button'
			[attr.aria-label]="authPipe.transform('passwordHide', 'propertyTitle')"
			[attr.aria-pressed]='passwordConfirmHide'
			(click)='passwordConfirmHide = !passwordConfirmHide'
		>
			<mat-icon [svgIcon]="passwordConfirmHide ? 'additional-view' : 'additional-view-hide'"></mat-icon>
		</button>
		<mat-error
			*ngIf='passwordConfirm?.errors?.required'
			i18n='mat-error@@reset-password-passwordConfirm-required'
		>Обязательное поле
		</mat-error>
		<mat-error *ngIf='passwordConfirm?.errors?.minlength'>Введите не менее 6 символов</mat-error>
		<mat-error
			*ngIf="recoveryForm?.hasError('notSame') && !passwordConfirm?.errors?.required"
			i18n='mat-error@@reset-password-passwordConfirm-notSame'
		>Указаные пароли не совпадают
		</mat-error>
		<mat-error *ngIf='passwordConfirm?.errors?.serverError'>
			{{ passwordConfirm?.errors?.serverError }}
		</mat-error>
	</mat-form-field>
	<button
		class='pd-button new-color'
		form='recoveryForm'
		type='submit'
		mat-raised-button
		color='primary'
		[disabled]='loading$ | async'
	>
		Изменить пароль
	</button>
</form>
