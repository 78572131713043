import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { combineLatestWith, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { MediaMatcher } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { UtilsService } from '@core/services/utils.service';

@Component({
	selector: 'pdw-market-layout',
	templateUrl: './market-layout.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./market-layout.component.scss']
})
export class MarketLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
	mobileQuery980px: boolean;
	unsubscribe: Subject<any> = new Subject<any>();
	hasScrolledMainPage$: Observable<boolean> = this.utilsService.hasScrolledMainPage.pipe(distinctUntilChanged());
	isVisibleHeader$: Observable<boolean> = this.utilsService.isVisibleHeader.pipe(distinctUntilChanged());

	@ViewChild('sideMenu')
	sideMenu: MatSidenav;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private media: MediaMatcher,
		private router: Router,
		private utilsService: UtilsService,
	) {
	}

	ngOnInit(): void {
		this.router.events
			.pipe(
				takeUntil(this.unsubscribe)
			)
			.subscribe({
				next: (event) => {
					if (event instanceof NavigationEnd && this.mobileQuery980px && this.sideMenu) {
						this.sideMenu.close();
					}
				}
			});

		this.utilsService.mobileQuery980px
			.pipe(
				takeUntil(this.unsubscribe),
			).subscribe({
			next: v => {
				this.mobileQuery980px = v;
			}
		});

		this.utilsService.shouldScrollTopOnInit
			.pipe(
				takeUntil(this.unsubscribe)
			)
			.subscribe({
				next: () => {
					document.scrollingElement.scrollTo(0, 0)
				}
			});
	}

	ngAfterViewInit() {
		if (this.sideMenu) {
			const root = document.querySelector('.pd-root');
			this.sideMenu.openedChange
				.pipe(
					takeUntil(this.unsubscribe),
					combineLatestWith(this.utilsService.mobileQuery980px
						.pipe(
							tap(x => {
								if (!x && root.classList.contains('side-menu-opened')) {
									root.classList.remove('side-menu-opened');
								}
							}),
						)
					),
					filter(x => x[1] === true),
				).subscribe({
				next: v => {
					if (v[0]) {
						root.classList.add('side-menu-opened');
					} else {
						root.classList.remove('side-menu-opened');
					}
				}
			});

		}
	}

	ngOnDestroy() {
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}
}
