import { Directive, HostBinding, Input } from '@angular/core';
import { hundredRatingColorStyle } from '@utils/functions';

@Directive({
	selector: '[pdwRatingTheme]'
})
export class RatingThemeDirective {
	@Input() styleName: 'background-color' | 'color' = 'color';
	@Input() percentage: number = 0;
	@HostBinding('style.background-color') get backgroundColor() {
		return this.styleName === 'background-color' ? hundredRatingColorStyle(this.percentage) : null;
	}

	@HostBinding('style.color') get color() {
		return this.styleName === 'color' ? hundredRatingColorStyle(this.percentage) : null;
	}

}
