import { Component, HostBinding, Input } from '@angular/core';
import { WorkAbstractComponent } from '@shared/work-abstract-component/work-abstract-component';
import { WorkView } from '@models/work-view';
import { WorkViewShort } from '@models/work-view-short';

@Component({
	selector: 'pdw-work-cost',
	templateUrl: './work-cost.component.html',
	host: { 'class': 'pd-work-cost' }
})
export class WorkCostComponent {
	/**
	 * Отображение в виде плитки
	 */
	@Input() plateView = true;
	@Input() work: WorkView | WorkViewShort;

	@HostBinding('class') get class() {
		return this.plateView ? 'plate-view' : '';
	}
	realCost(): number {
		return this.work.amount;
	}
}
