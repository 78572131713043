<ng-container *ngIf='avatarSrc && isDisplayed; else emptyTemplate'>
	<img
		*ngIf='avatarSrc; else emptyTemplate'
		class='image-avatar'
		[ngClass]='size'
		[src]='!isNotRequest ? (avatarSrc | avatar | async) : avatarSrc'
		[alt]='avatarSrc'
	/>
</ng-container>

<ng-template #emptyTemplate>
	<ng-content></ng-content>
</ng-template>
