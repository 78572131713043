<div fxLayout="column" fxLayoutGap="20px">
  <div>
    <mat-table matSort matSortActive="createtime" matSortDirection="desc" [dataSource]="dataSource" class="pd-width-full">

      <!-- Value Column -->
      <ng-container matColumnDef="value">
        <mat-header-cell matTooltip="{{ 'value' | skillState: 'propertyHint' }}" *matHeaderCellDef mat-sort-header>
          {{ 'value' | skillState: 'propertyTitle' }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.value | skillStateValue }}
        </mat-cell>
      </ng-container>

      <!-- CreateAppUser Column -->
      <ng-container matColumnDef="createAppUser">
        <mat-header-cell matTooltip="{{ 'сreateAppUser' | skillState: 'propertyHint' }}" *matHeaderCellDef mat-sort-header>
          {{ 'сreateAppUser' | skillState: 'propertyTitle' }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.createAppUser">
            <a [routerLink]="['/user', element.createAppUser.id]">
              {{ element.createAppUser.title }}
            </a>
          </ng-container>
        </mat-cell>
      </ng-container>

      <!-- Createtime Column -->
      <ng-container matColumnDef="createtime">
        <mat-header-cell
            class="header md"
            matTooltip="{{ 'createtime' | skillState: 'propertyHint' }}" *matHeaderCellDef mat-sort-header>
          {{ 'createtime' | skillState: 'propertyTitle' }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.createtime | date:'medium' }}
        </mat-cell>
      </ng-container>

      <!-- IsLast Column -->
      <ng-container matColumnDef="isLast">
        <mat-header-cell
            class="header xs-sm"
            matTooltip="{{ 'isLast' | skillState: 'propertyHint' }}" *matHeaderCellDef mat-sort-header>
          {{ 'isLast' | skillState: 'propertyTitle' }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="pd-table-icon-center">
            <mat-icon *ngIf="element.isLast" [color]="skillStateColor(element.value)">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!element.isLast" [color]="skillStateColor(element.value)">highlight_off</mat-icon>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Group header -->
      <ng-container matColumnDef="groupHeader">
        <mat-cell colspan="999" class="pd-table-cell-group" *matCellDef="let group">
          <div *ngIf="group.value">
            {{ group.value }}
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns | async"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns | async;"></mat-row>
    </mat-table>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="skillView">
    <button mat-raised-button
            matTooltip="Разрешить"
            color="primary"
            [disabled]="skillView.state === 1"
            (click)="goApprove()">
      Принять
    </button>
    <button mat-raised-button
            matTooltip="Отклонить"
            color="primary"
            [disabled]="skillView.state === 2"
            (click)="goDecline()">
      Отклонить
    </button>
  </div>
</div>
