<pdw-dialog-header [title]='data.title'></pdw-dialog-header>
<pdw-dialog-body>
	<form
		*ngIf='!(loading$ | async); else isLoading'
		id='formGroup'
		(ngSubmit)='onSubmit()'
		[formGroup]='formGroup'
	>
		<div class='pd-taiga-input-wrapper'>
			<mat-form-field
				class='pd-input-form-field'
				appearance='fill'
				hideRequiredMarker
			>
				<mat-label>{{ profession | appUser: 'propertyTitle' }}</mat-label>
				<input
					matInput
					formControlName='profession'
					type='text'
					maxlength='100'
				>
				<mat-error
					*ngIf='profession.errors?.pattern'
					i18n='mat-error@@app-user-personal-dialog-middlename-pattern'
				>Недопустимые символы!
				</mat-error>
				<mat-error
					*ngIf='profession.errors?.whitespace'
					i18n='mat-error@@app-user-personal-dialog-middlename-whitespace'
				>Введите символы!
				</mat-error>
				<mat-error
					*ngIf='profession.errors?.maxlength'
					i18n='mat-error@@app-user-personal-dialog-middlename-whitespace'
				>Введите максимум 100 символов
				</mat-error>
				<mat-error *ngIf='profession.errors?.serverError'>
					{{ profession.errors?.serverError }}
				</mat-error>
			</mat-form-field>
		</div>

		<!-- WorkType & WorkGraph -->
		<div class='row-checkbox'>
			<div>
				<p class='row-title'>
					График работы
				</p>
				<pdw-checkbox-list
					class='checkbox-relocate'
					label=''
					formControlName='workTypes'
					[options]='workTypesArray'
					name='name'
					optionKey='id'
				></pdw-checkbox-list>
			</div>

			<div>
				<p class='row-title'>
					Формат работы
				</p>
				<pdw-checkbox-list
					class='checkbox-relocate'
					label=''
					optionKey='id'
					formControlName='workFormats'
					[options]='workFormatsArray'
					name='name'
				></pdw-checkbox-list>
			</div>
		</div>

		<div>
			<p class='row-title'>
				Желаемый уровень дохода в &#8381;
			</p>
			<!--  preferredReward   -->
			<mat-form-field
				class='pd-input-form-field'
				appearance='fill'
				hideRequiredMarker
			>
				<mat-label>{{ preferredReward | appUser: 'propertyTitle' }}</mat-label>
				<input
					matInput
					formControlName='preferredReward'
					type='number'
				>
				<mat-error
					*ngIf='preferredReward.errors?.pattern'
					i18n='mat-error@@app-user-personal-dialog-lastname-pattern'
				>Недопустимые символы!
				</mat-error>

				<mat-error *ngIf='preferredReward.errors?.serverError'>
					{{ preferredReward.errors?.serverError }}
				</mat-error>
			</mat-form-field>
		</div>
	</form>
</pdw-dialog-body>
<pdw-dialog-footer>
	<div
		class='pd-dialog-footer-buttons'
		*ngIf='formGroup'
	>
		<button
			mat-raised-button
			class='pd-button-secondary large'
			type='reset'
			[mat-dialog-close]='false'
		>
			Отменить
		</button>
		<button
			form='formGroup'
			mat-raised-button
			class='pd-button large'
			type='submit'
			[disabled]='(saving$ | async) || (loading$ | async)'
		>
			<ng-container *ngIf='!(saving$ | async); else isSaving'>Сохранить</ng-container>
			<ng-template #isSaving>
				Сохраняем
			</ng-template>
		</button>
	</div>
</pdw-dialog-footer>


<ng-template #isLoading>
	<div class='pd-basis-card-empty'>
		<div class='pd-basis-card-empty-inner'>
			<mat-spinner
				[diameter]='70'
				[strokeWidth]='5'
			></mat-spinner>
		</div>
	</div>
</ng-template>
