import { Pipe, PipeTransform } from '@angular/core';
import {AbstractEnumPipe} from "@public/pipes/abstract-enum.pipe";

@Pipe({
  name: 'stateColor'
})
export class StateColorPipe extends AbstractEnumPipe implements PipeTransform {
  values = [
    'По умолчанию',
    'Красный',
    'Оранжевый',
    'Желтый',
    'Зеленый',
    'Голубой',
    'Синий',
    'Фиолетовый'
  ];
  transform(value: any, ...args: any[]): any {
    return this.getTransform(value, args);
  }
}
