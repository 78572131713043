import {
	Component,
	OnInit,
	Input,
	ElementRef,
	Renderer2, OnDestroy, Optional, Self
} from '@angular/core';
import {
	ControlValueAccessor,
	NgControl,
} from '@angular/forms';

@Component({
	selector: 'pd-rounded-checkbox',
	templateUrl: './rounded-checkbox.component.html',
	styleUrls: ['./rounded-checkbox.component.scss']
})
export class RoundedCheckboxComponent implements OnInit, ControlValueAccessor, OnDestroy {

	touched = false;

	private _unlistenFn: () => void;

	@Input() disabled: boolean = false;

	@Input()
	get checked() {
		return this.isChecked;
	}

	set checked(value: boolean) {
		this.isChecked = value
	}

	@Input() isDisableClick: boolean = false;

	constructor(
		private _element: ElementRef,
		private _renderer: Renderer2,
		@Optional() @Self() public ngControl: NgControl
	) {
		if (this.ngControl != null) {
			this.ngControl.valueAccessor = this;
		}
	}

	ngOnInit(): void {
		if (this.isDisableClick) {
			this._unlistenFn = this._renderer.listen(this._element.nativeElement, 'click', (event) => this.disableClick(event));
		}
	}

	ngOnDestroy() {
		if (this._unlistenFn) {
			this._unlistenFn();
		}
	}

	isChecked = false;
	onChange = (_ => {
	});
	onTouched = (() => {
	});

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	writeValue(obj: boolean): void {
		this.isChecked = obj;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onChanged($event) {
		this.isChecked = $event && $event.target && $event.target.checked;
		this.onChange(this.isChecked);
		this.markAsTouched();
	}

	disableClick(event: any): any {
		event.preventDefault();
	}
}
