import { AppUserView } from '@models/app-user-view';
import { AddressView } from '@models/address-view';
import { CompanyEmailView } from '@models/company-email-view';
import { CompanyPhoneView } from '@models/company-phone-view';
import { CompanyStateValue } from '@models/company-state-view';
import { YesNo } from '@models/enums';
import { IndustryView } from '@models/industry-view';
import { AbstractModifiedModelView } from '@models/abstract-modified-model-view';

export const MyCompanyMenuTitles = {
	icon: 'work',
	primaryTitleItems: 'Компании',
	descriptionItems: 'Все компании',
	primaryTitleCreate: 'Создание компании',
	descriptionCreate: 'Создание новой компании',
	primaryTitleItem: 'Компания',
	descriptionItem: 'Профиль компании',
};
export const CompanyMenuTitles = {
	icon: 'work',
	primaryTitleItems: 'Компании',
	descriptionItems: 'Все компании',
	primaryTitleCreate: 'Создание компании',
	descriptionCreate: 'Создание новой компании',
	primaryTitleItem: 'Компания',
	descriptionItem: 'Профиль компании',
};

export enum CompanySpecTag {
	none = -1,
	favorites,
	all,
}

export enum SpecialistsTag {
	none = -1,
	inSearch,
	requested,
	invited,
	inWork,
	favorites,
	all,
}

export class CompanyView extends AbstractModifiedModelView {
	public id: number;
	public owner: AppUserView;
	public title: string;
	public inn: string;
	public kpp: string;
	public ogrn: string;
	public okpo: string;
	public name: string;
	public fullName: string;
	public description: string;
	public legal: AddressView;
	public postal: AddressView;
	public postalIsSame: YesNo;
	public emails: CompanyEmailView[];
	public phones: CompanyPhoneView[];
	public state: CompanyStateValue;
	public hasLogo: YesNo;
	public originalFilenameExtension: string;
	public worksInSeeking: number;
	public freelanceInSeeking?: number;
	public worksInExecuting: number;
	public worksInFinished: number;
	public subscribed: YesNo;
	public industries: IndustryView[];
	public errors: any[];
	public url: string;
	public speedRatingPercent: number;
	public targetryRatingPercent: number;
	public adequacyRatingPercent: number;
	public ratingPercent: number;
	public cropperPosition: any;

	constructor() {
		super();
		this.name = '';
		this.fullName = '';
		this.inn = '';
		this.kpp = '';
		this.ogrn = '';
		this.okpo = '';
	}
}
