import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { EMAIL_REGEX, PASSWORD_REGEX, dateMask } from '@utils/constants';
import { AppUserService } from '@core/services/app-user.service';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { Router } from '@angular/router';
import { TrueLoadingService } from '@core/services/true-loading.service';
import { AppUserType } from '@models/app-user-view';
import { RegistrationRequest } from '@requests/registration-request';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { CustomerAndSpecialistRegisterForm} from './customer.landing.form';
import { AuthService } from '@core/services/auth.service';
import * as moment from 'moment/moment';
import { MaskitoOptions } from '@maskito/core';

@Component({
	selector: 'pdw-customer-landing',
	templateUrl: './customer-landing.component.html',
	styleUrls: ['./customer-landing.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerLandingComponent implements OnInit{

	formGroupOne: CustomerAndSpecialistRegisterForm = new CustomerAndSpecialistRegisterForm(this.regex, this.emailRegex);
	formGroupTwo: CustomerAndSpecialistRegisterForm = new CustomerAndSpecialistRegisterForm(this.regex, this.emailRegex);
	isLoggedIn: boolean;
	maskitoOptions: MaskitoOptions = {
		mask: dateMask,
	};
	constructor(
		private fb: FormBuilder,
		@Inject(PASSWORD_REGEX) private readonly regex: string,
		@Inject(EMAIL_REGEX) private readonly emailRegex: string,
		private authService: AuthService,
		private userService: AppUserService,
		private destroy$: DestroyService,
		private notificationService: NotificationService,
		private router: Router,
		public loading$: TrueLoadingService
	) {
		this.loading$.next(false);
	}
	ngOnInit(): void {
		this.isLoggedIn = this.authService.isLoggedIn()
	}

	submit(formGroup: CustomerAndSpecialistRegisterForm) {
		this.loading$.next(true);
		const birthday = moment(formGroup.birthday.value).format('YYYY-MM-DD');
		const updatedFormValue = { ...formGroup.value, birthday: birthday };
		this.userService.registration({
				...updatedFormValue,
				type: AppUserType.client
			} as RegistrationRequest)
			.pipe(
				catchError((error) => {
					this.notificationService.showDanger(error instanceof HttpErrorResponse && typeof error.error === 'object' ? error.error.message : error)
					return EMPTY;
				}),
				finalize(() => {
					this.loading$.next(false);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.notificationService.showSuccess('Пользователь успешно зарегистрирован');
				this.router.navigate(['/sentConfirmation'], {
					state: { afterRegistration: true },
				});
			})
	}

}

