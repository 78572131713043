export class ArrayHelpers {
}

export function swap(array: any[], a: number, b: number): any[] {
	if (a < 0 || a >= array.length || b < 0 || b >= array.length) {
		return array;
	}

	const temp = array[a];
	array[a] = array[b];
	array[b] = temp;

	return array;
}

export function groupBy(array: any[]): any[] {
	const groups = array
		.map(o => {
			return {
				id: o.id,
				name: o.name
			}
		})
		.reduce(function (accumulator, o) {
			if (accumulator[o.name]) {
				if (accumulator[o.name].indexOf(o.id) === -1) {
					accumulator[o.name].push(o.id);
				}
			} else {
				accumulator[o.name] = [o.id];
			}
			return accumulator;
		}, {});
	return Object.keys(groups)
		.map(key => {
			return {
				id: groups[key],
				name: key
			}
		});
}

export function groupByField(array: any[], field: string): any[] {
	if (!array) {
		return null;
	}
	const groups = array
		.reduce(function (accumulator, o) {
			if (accumulator[o[field]]) {
				if (accumulator[o[field]].indexOf(o.id) === -1) {
					accumulator[o[field]].push(o);
				}
			} else {
				accumulator[o[field]] = [o];
			}
			return accumulator;
		}, {});
	return Object.keys(groups)
		.map(key => {
			return {
				group: groups[key],
				[field]: key
			}
		});
}

export function findLastIndex(array: any[], key: string, value: any): number {
	const index = array.slice().reverse().findIndex(x => x[key] === value);
	const count = array.length - 1;
	return index >= 0 ? count - index : index;
}

export function sortByField(field) {
	return (a, b) => a[field] > b[field] ? -1 : 1;
}

export function sortByTwoField(field: string, secondField: string, descending: boolean): any {
	if (descending) {
		return (a, b) => a[field] < b[field] || a[secondField] < b[secondField] ? 1 : -1;
	}
	return (a, b) => a[field] < b[field] || a[secondField] < b[secondField] ? -1 : 1;
}
