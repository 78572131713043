import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'pdw-link-transition-confirm-dialog',
  templateUrl: './link-transition-confirm-dialog.component.html',
  styleUrls: ['./link-transition-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkTransitionConfirmDialogComponent {
  private companyViewUrl = inject(MAT_DIALOG_DATA);

  public url = this.companyViewUrl.companyViewUrl;
  constructor() { }

}
