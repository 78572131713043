<div class='title-block'>
	<button
		mat-raised-button
		class='pd-button-secondary return-button'
		(click)='setStep((STEP_PAGE - 1))'
		*ngIf='!checkCurrentStep(STEP.EDUCATION)'
	>
		Вернуться назад
	</button>
	<button
		mat-raised-button
		class='pd-button-secondary return-button'
		(click)='redirectBack()'
		*ngIf='checkCurrentStep(STEP.EDUCATION)'
	>
		Вернуться назад
	</button>
	<div class='title-logo-wrapper'>
		<span>
			Profdepo<span>.work</span>
		</span>
		<h1 [class.smaller-title]='checkCurrentStep(STEP.HARD_SKILLS)'>
			{{pageTitle}}
		</h1>
		<p *ngIf='checkCurrentStep(STEP.HARD_SKILLS)'>
			Например, "AutoCAD", "Проектирование".
		</p>
	</div>
</div>

<div class='form-wrapper'>
	<header>
		<pdw-steps
			class='steps-container'
			[currentStep]='STEP_PAGE'
			[stepCount]='4'
		></pdw-steps>
		<h1>
			{{label}}
		</h1>
	</header>
	<main>
		<form
			[ngSwitch]='STEP_PAGE'
			[formGroup]='formGroup'
		>
			<ng-container
				*ngSwitchCase='STEP.EDUCATION'
				formArrayName='education'
			>
				<div class='form-list'>
					<pdw-salary-register-education-form
						*ngFor='let form of educationForm.controls; let index = index'
						[formGroup]='form'
						[index]='index'
						[parentForm]='educationForm'
						(deleteFormGroup)='removeOneFormGroup($event)'
					></pdw-salary-register-education-form>
				</div>
				<button
					mat-raised-button
					class='pd-button-secondary add-button'
					(click)='addFormGroup(educationForm, newEducationGroupInstance)'
				>
					Добавить образование
				</button>
			</ng-container>
			<ng-container
				*ngSwitchCase='STEP.EXP'
				formArrayName='education'
			>
				<div class='form-list'>
					<pdw-salary-experience-form
						*ngFor='let experienceForm of experienceFormArray.controls; let index = index'
						[formGroup]='experienceForm'
						[index]='index'
						[parentForm]='experienceFormArray'
						(deleteFormGroup)='removeOneFormGroup($event)'
					></pdw-salary-experience-form>
				</div>
				<button
					mat-raised-button
					class='pd-button-secondary add-button'
					(click)='addFormGroup(experienceFormArray, newExperienceGroupInstance)'
				>
					Добавить опыт работы
				</button>
			</ng-container>
			<ng-container
				*ngSwitchCase='STEP.HARD_SKILLS'
			>
				<pdw-chips-select
					[isMultiple]='true'
					[formControl]='hardSkillsControl'
					label='Введите навык'
					[options]='getHardSkills.bind(this)'
					optionKey='id'
					optionLabel='titleLong'
				>
					<mat-error *ngIf='hardSkillsControl.errors?.required'>
						Обязательное поле
					</mat-error>
				</pdw-chips-select>
			</ng-container>
			<ng-container
				*ngSwitchCase='STEP.PASSWORD'
			>
				<pdw-salary-registration-form [formGroup]='registrationGroup'>

				</pdw-salary-registration-form>
			</ng-container>
		</form>
	</main>
	<footer>
		<button
			mat-raised-button
			class='pd-button-secondary'
			(click)='setNextStep(true)'
			*ngIf='checkCurrentStep(STEP.EDUCATION)'
		>
			Нет образования
		</button>
		<button
			mat-raised-button
			class='pd-button-secondary'
			(click)='setNextStep(true)'
			*ngIf='checkCurrentStep(STEP.EXP)'
		>
			У меня нет опыта
		</button>
		<button
			mat-raised-button
			class='pd-button-secondary'
			(click)='setNextStep(true)'
			*ngIf='checkCurrentStep(STEP.HARD_SKILLS)'
		>
			Продолжить без добавления
		</button>
		<button
			*ngIf='!checkCurrentStep(STEP.PASSWORD)'
			[disabled]='!isCurrentFormValid'
			mat-raised-button
			class='pd-button'
			(click)='setNextStep()'
		>
			Продолжить
		</button>

		<button
			*ngIf='checkCurrentStep(STEP.PASSWORD)'
			[disabled]='!isCurrentFormValid || (loading$ | async)'
			mat-raised-button
			class='pd-button'
			(click)='submitRegistration()'
		>
			Продолжить
		</button>
	</footer>
</div>
