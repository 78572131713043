<ng-container *ngIf="skillViewForm">
  <form id="skillViewForm" [formGroup]="skillViewForm" (ngSubmit)="onSubmit(skillViewForm)">
    <div fxLayout="column" fxLayoutAlign="start" fxFlex.gt-lg="35" fxLayoutGap="30px">
      <!--  Section  -->
      <div fxLayout="row">
        <mat-form-field fxFlex="100">
          <input
            matInput
            placeholder="{{ section | skill: 'propertyTitle' }}"
            [formControl]="section"
            [matAutocomplete]="sectionAuto"
            required>
          <mat-autocomplete #sectionAuto="matAutocomplete">
            <mat-option *ngFor="let item of sections | async" [value]="item">{{item}}</mat-option>
          </mat-autocomplete>
          <mat-hint>{{section | skill: 'propertyHint'}}</mat-hint>
          <mat-error *ngIf="section.errors?.required" i18n="mat-error|Создание навыка в админке@@skill-form-section-required">Обязательное поле</mat-error>
<!--          <mat-error *ngIf="section.errors?.objectRequired">Помещение должно быть выбрано из списка, а не введено вручную!</mat-error>-->
          <mat-error *ngIf="section.errors?.serverError">
            {{ section.errors?.serverError }}
          </mat-error>
        </mat-form-field>
      </div>

      <!--  Code   -->
      <div fxLayout="row">
        <mat-form-field fxFlex="100">
          <input matInput
                 placeholder="{{ code | skill: 'propertyTitle' }}"
                 formControlName="code">
          <mat-hint>{{ code | skill: 'propertyHint' }}</mat-hint>
          <mat-error *ngIf="code.errors?.serverError">
            {{ code.errors?.serverError }}
          </mat-error>
        </mat-form-field>
      </div>

      <!--  Name   -->
      <div fxLayout="row">
        <mat-form-field fxFlex="100">
          <input matInput
                 placeholder="{{ name | skill: 'propertyTitle' }}"
                 formControlName="name">
          <mat-hint>{{ name | skill: 'propertyHint' }}</mat-hint>
          <mat-error *ngIf="name.errors?.required"  i18n="mat-error|Создание навыка в админке@@skill-form-name-required">Обязательное поле</mat-error>
          <mat-error *ngIf="name.errors?.serverError">
            {{ name.errors?.serverError }}
          </mat-error>
        </mat-form-field>
      </div>

      <!--  Buttons Create and Save   -->
      <div class="uk-form-internal" fxLayout="row" fxLayoutGap="10px">
        <button mat-raised-button
                color="primary"
                [disabled]="(saving | async)"
                *ngIf="method === 'create' || method === 'replace'">
          <ng-container *ngIf="!(saving | async); else isSaving">Создать</ng-container>
          <ng-template #isSaving>
            Создание...
          </ng-template>
        </button>
        <button mat-raised-button
                color="primary"
                [disabled]="(saving | async)"
                [matTooltip]="'Сохранение изменений'"
                *ngIf="method === 'update'">
          <ng-container *ngIf="!(saving | async); else isSaving">Сохранить</ng-container>
          <ng-template #isSaving>
            Сохранение...
          </ng-template>
        </button>
        <button type="button"
                mat-raised-button
                matTooltip="Отмена изменений"
                (click)="goBack()">
          Отменить
        </button>
      </div>

    </div>
  </form>
</ng-container>
