import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ComplexFilterDialogComponent} from "@shared/complex-filter-dialog/complex-filter-dialog.component";
import {BehaviorSubject, Subject} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {debounceTime, distinctUntilChanged, filter, takeUntil} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {
  ComplexFilterComplete,
  ComplexFilterField,
  ComplexFilterHelpers,
  ComplexFilterResult
} from "@utils/helpers/complex-filter-helpers";
import {DataSource} from "@core/dataSources/data-source";
import {MediaMatcher} from "@angular/cdk/layout";
import {pushFakeHistoryState} from "@utils/functions";

@Component({
  selector: 'app-complex-filter',
  templateUrl: './complex-filter.component.html',
  host: {'class': 'pd-complex-filter'}
})
export class ComplexFilterComponent implements OnInit, OnDestroy {
  /**
   * Массив строк для склонения пр. [специалистов, специлаист, специалиста]
   * [0 или >= 5, для прочих, от 2 до 4]
   *
   * output => Подходит 1 специалист
   */
  @Input() counterWordsToDeclension: string[];
  /**
   * Источник данных для получения количества записей удовлетворяющих критериям поиска
   */
  @Input() dataSource: DataSource<any>;
  /**
   * Массив описаний атрибутов поиска
   */
  @Input() displayedFields: ComplexFilterField[];
  activeFilters = new BehaviorSubject<ComplexFilterComplete[]>([]);
  mobileQuery: MediaQueryList;
  anyFilter = new FormControl();
  @ViewChild("formField", {read: ElementRef}) formField: ElementRef;
  @Input('placeholder') placeholder: string;
  @Output() result = new EventEmitter<ComplexFilterResult>();
  unsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private dialog: MatDialog,
    private media: MediaMatcher,
  ) {
  }

  ngOnInit(): void {
    this.anyFilter.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged(),
        debounceTime(350)
      )
      .subscribe(query => {
        this.resultEmit(query);
      });

    this.mobileQuery = this.media.matchMedia('(max-width: 610px)');
  }

  /**
   * Возвращает подсказку для chip'а в фильтре
   * @param filter
   */
  tooltipFilter(filter: ComplexFilterComplete): string {
    const filterField = this.displayedFields.find(x => x.name === filter.name);

    return ComplexFilterHelpers.tooltipFilter(filter, filterField);
  }

  /**
   * Возвращает наименование атрибута для chip'а в фильтре
   * @param filter
   */
  titleFilter(filter: ComplexFilterComplete): string {
    return ComplexFilterHelpers.titleFilter(this.displayedFields, filter);
  }

  /**
   * Возвращает критерий для chip'а в фильтре
   * @param filter
   */
  valueFilter(filter: ComplexFilterComplete): string {
    const filterField = this.displayedFields.find(x => x.name === filter.name);

    return ComplexFilterHelpers.valueFilter(filter, filterField);
  }

  /**
   * Удаляет chip и фильтр по атрибуту
   * @param filterName
   */
  removeFilter(filterName: string): void {
    const tmp = this.activeFilters.value;
    ComplexFilterHelpers.removeFilter(tmp, filterName);
    this.activeFilters.next(tmp);
    this.resultEmit(this.anyFilter.value);
  }

  /**
   * Очищает все критерии фильтра
   */
  clearFilters(): void {
    this.anyFilter.setValue(null, {emitEvent: false});
    this.activeFilters.next([]);
    this.resultEmit(this.anyFilter.value);
  }

  /**
   * Открывает диалоговое окно фильтра
   */
  goFilter(): void {
    pushFakeHistoryState();

    const formFieldRect = this.formField.nativeElement.getBoundingClientRect();
    this.dialog.open(ComplexFilterDialogComponent, {
      position: {
        top: formFieldRect.y + 'px',
        left: formFieldRect.x + 'px'
      },
      width: this.formField.nativeElement.offsetWidth,
      data: {
        dataSource: this.dataSource,
        displayedFields: this.displayedFields,
        activeFilters: this.activeFilters.value,
        anyFilter: this.anyFilter.value,
        placeholder: this.placeholder,
        counterWordsToDeclension: this.counterWordsToDeclension,
      },
      panelClass: ['pd-complex-filter-dialog',],
      closeOnNavigation: false,
    })
      .afterClosed()
      .pipe(
        filter(x => x)
      )
      .subscribe((result: ComplexFilterResult) => {
        this.activeFilters.next(result.filters);
        this.anyFilter.setValue(result.any, {emitEvent: false});
        this.resultEmit(this.anyFilter.value);
      });
  }

  /**
   * Вызывает событие завершение ввода критерия фильтра
   * @param query критерий поиска по всему
   */
  resultEmit(query: string): void {
    this.result.emit(new ComplexFilterResult(query, this.activeFilters.value));
  }

  /**
   * Отписывает подписки при уничтожении компоненты
   */
  ngOnDestroy(): void {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();

    this.mobileQuery.removeAllListeners();
  }
}
