<div class='pdw-landing'>
	<section
		class='pdw-landing-start'
		id='start'
	>
		<div class='find-job-content-wrapper'>
			<ng-content select='[find-job-section]'></ng-content>
		</div>
		<div class='pdw-landing-container pdw-landing-start-container landing-container find-job-section'>
			<div
				class='pdw-landing-start-inner'
			>
				<h1 class='landing-title'>
					<p>
						Работа
					</p>
					<p class='landing-title-bold'>
						для инженеров
					</p>
					<p>
						на любой вкус
					</p>
				</h1>
				<div class='landing-undertitle-buttons-container'>
					<a
						mat-raised-button
						class='pd-landing-button pd-button'
						[routerLink]="['/users']"
					>Ищу
						специалиста</a>
					<a
						mat-raised-button
						class='pd-landing-button pd-button'
						[routerLink]="['/jobs/all']"
					>Ищу работу</a>
				</div>
			</div>
			<div class='image-title'>
				<img
					src='assets/landing/start.svg'
					alt='landing-img'
				/>
			</div>
		</div>
	</section>

	<section
		class='landing-container pdw-landing-scope pdw-landing-scope-margin'
		id='scope'
	>
		<div class='scope-section-content'>
			<ng-content select='[scope-section]'></ng-content>
		</div>
		<div class='pdw-landing-scope-container slide-cards-container'>
			<div
				class='pdw-landing-scope-inner pdw-landing-rating slide-card'
			>
				<img
					class='slide-card-image'
					src='assets/landing/woman-with-cat.svg'
					alt='landing-img'
				/>
				<div class='slide-card-info'>
					<p class='slide-card-title'>Фриланс и проектная работа</p>
					<p class='slide-card-subtitle'>Приглашайте проверенных инженеров-фрилансеров и платите за
						результат.</p>
					<a
						routerLink='/login'
						mat-raised-button
						class='pd-landing-button pd-button landing-card-button'
					>
						Создать работу
					</a>
				</div>
			</div>
			<div
				class='pdw-landing-scope-inner pdw-landing-money'
			>
				<div class='slide-card-empty'>
					<h3 class='slide-card-title slide-card-title-margin'>Найм инженеров
						на постоянную работу</h3>
					<p>
						Формируйте штат сотрудников.
					</p>
					<p>
						Здесь вы найдете тех,
					</p>
					<p>
						с кем сработаетесь на годы вперёд.
					</p>

					<a
						routerLink='/jobs/all'
						mat-raised-button
						class='pd-landing-button pd-button landing-card-button'
					>
						Создать вакансию
					</a>
				</div>
				<div class='slide-card-empty-image-wrapper'>
					<img
						class='slide-card-empty-image'
						src='assets/landing/vacancy.svg'
						alt='landing-img'
					/>
				</div>
			</div>
			<div
				class='pdw-landing-scope-inner pdw-landing-rating slide-card'
			>
				<img
					class='slide-card-image'
					src='assets/landing/user-passport.svg'
					alt='landing-img'
				/>
				<div class='slide-card-info'>
					<p class='slide-card-title'>ИИ-помощник в поиске
						и подборе кадров</p>
					<p class='slide-card-subtitle'>Автоматизируйте работу HR-менеджера
						с помощью новейших технологий и алгоритмов</p>

					<a
						routerLink='/users'
						mat-raised-button
						class='pd-landing-button pd-button landing-card-button'
					>
						Подобрать специалиста
					</a>
				</div>
			</div>
		</div>
	</section>

	<section class='professional-section landing-container'>
		<h1 class='professional-section-title'>
			{{(isMobileVersion$ | async) ? 'Профессионалы инженерной сферы:' : 'Профессионалы инженерной сферы:'}}
		</h1>
		<div class='professional-cards-container'>
			<div class='professional-cards-row'>
				<pdw-professional-card
					*ngFor='let data of filterCards(ProfessionalCardEnum.DEFAULT, (isMobileVersion$ | async)); trackBy:uniqItem'
					class='professional-card-desktop'
				>
					<ng-container *ngTemplateOutlet='cardProfessionalTemplate; context: {$implicit: data}'></ng-container>
				</pdw-professional-card>
			</div>
			<div class='professional-cards-row'>
				<pdw-professional-card
					*ngFor='let data of filterCards(ProfessionalCardEnum.SMALL); trackBy:uniqItem'
					class='professional-card-desktop-small'
				>
					<ng-container *ngTemplateOutlet='cardProfessionalTemplate; context: {$implicit: data}'></ng-container>
				</pdw-professional-card>
			</div>
		</div>

		<div class='ai-block'>
			<pdw-card-matched-employee
				[canRedirect]='false'
				[canOpenModal]='false'
				class='ai-card'
				[user]='user'
			>
			</pdw-card-matched-employee>
			<div class='ai-block-description'>
				<h1>
					Экономьте до 70% ресурсов
					при поиске специалистов
					с помощью ИИ
				</h1>
				<p>
					Алгоритм Profdepo AI сам подберет лучшего специалиста.
					Ваш HR-менеджер станет в 2 раза быстрее и эффективнее,
					а траты на HR упадут.
				</p>
				<br>
				<p>
					Надо просто зарегистрироваться.
				</p>

				<a
					mat-raised-button
					class='pd-landing-button pd-button ai-link'
					routerLink='/register'
				>
					Хочу зарегистрироваться
				</a>
			</div>
		</div>
	</section>

	<section
		class='pdw-landing-join join-section'
		id='join'
	>
		<div class='join-section-content'>
			<ng-content select='[join-section]'>
			</ng-content>
		</div>
		<div class='join-content-container'>
			<div class="join-content-wrapper">
				<div class='join-content'>
					<h2 class='join-content-title'>
						Присоединяйтесь к нам и добивайтесь успеха уже сейчас!
					</h2>
					<a
						mat-raised-button
						class='pd-landing-button pd-button find-spec'
						routerLink='/register'
					>Хочу быть с
						вами!</a>
				</div>
				<img
					class='join-content-image'
					src='assets/landing/join.svg'
					alt='landing-img'
				/>
			</div>
		</div>
	</section>

	<section class='news-section landing-container'>
		<div
			class='news-block'
		>
			<h1 class='news-title'>Новости</h1>
			<span>
				<p>Статьи из индустрии инжиниринга, строительства и IT.</p>
				<p>А также обновления и новости сервиса.</p>
			</span>
			<a
				mat-raised-button
				class='pd-landing-button pd-button news'
				href='https://t.me/profdepo_ru'
			>Больше новостей на
				Profdepo.ru</a>
		</div>

		<app-landing-news-posts

		></app-landing-news-posts>

	</section>
</div>

<ng-template
	#cardProfessionalTemplate
	let-option
>
	<a
	[routerLink]="isLoggedIn ? ['/users']: ['/register']"
		class='professional-link'
	>
		<div class='professional-card-info'>
			<h4>
				{{option.title}}
			</h4>
			<p>
				{{option.subtitle}}
			</p>
		</div>
		<div class='professional-card-bottom'>
			<p>
				{{option.count | numberToWordDeclension : ['специалистов', 'специалист', 'специалиста'] : true}}
			</p>

			<div>
				<img
					class='professional-card-icon'
					[src]='"assets/landing-icons/" + option.svgName + ".svg"'
				/>
			</div>
		</div>
	</a>
</ng-template>
