<pdw-dialog-header [title]='modifiedTitle'>
</pdw-dialog-header>

<pdw-dialog-body class='dialog-body'>
	<!-- <pdw-switch
		*ngIf='isCreatingStage'
		[formControl]='isFreelance'
		[names]='{first: "Вакансии", second: "Фриланс"}'
	></pdw-switch> -->

	<form
		class='form-container'
		[formGroup]='createForm'
	>
		<pdw-create-freelance-form
			[data]='formData'
			*ngIf='false; else workTemplate'
			[isEditMode]='isFreelanceEditMode'
		>
		</pdw-create-freelance-form>
		<ng-template #workTemplate>
			<pdw-create-work-form [data]='formData'>
			</pdw-create-work-form>
		</ng-template>
	</form>
</pdw-dialog-body>

<pdw-dialog-footer>
	<div class='pd-dialog-footer-buttons'>
		<button
			*ngIf='!isAcceptMode()'
			mat-raised-button
			class='pd-button-secondary large'
			type='reset'
			[mat-dialog-close]='false'
		>
			Отменить
		</button>
		<button
			*ngIf='isAcceptMode()'
			mat-raised-button
			class='pd-button-secondary large'
			type='button'
			(click)='rejectWork()'
		>
			Отклонить
		</button>
		<button
			mat-raised-button
			class='pd-button large'
			type='submit'
			(click)='submitWork()'
			[disabled]='!isValidForm || (loading$ | async) || !formHasChanged()'
		>
			{{(loading$ | async) ? 'Сохраняем' : isAcceptMode() ? 'Принять' : 'Сохранить'}}
		</button>
	</div>
</pdw-dialog-footer>
