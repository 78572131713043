<ng-container *ngIf='!(isLoading | async)'>
	<ng-container *ngIf='!(workMarkView | async) && canAssign'>
		<button
			class='pd-button-assign'
			(click)='goAssign()'
		>
			<mat-icon
				class='pd-icon'
				svgIcon='additional-star'
			></mat-icon>
			Оставьте отзыв
		</button>
	</ng-container>
	<ng-container *ngIf='(workExecutorMarkView | async) && isActiveUserAssigned'>
		<app-work-executor-mark-card [workExecutorMarkView]='workExecutorMarkView | async' [isShortMode]="true">
		</app-work-executor-mark-card>
	</ng-container>
	<ng-container *ngIf='(workMarkView | async) && !isActiveUserAssigned'>
		<pdw-work-mark-card [workMarkView]='workMarkView | async' [isToShowFullMode]="true">
		</pdw-work-mark-card>
	</ng-container>
</ng-container>
