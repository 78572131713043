import {
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	ViewChild
} from '@angular/core';
import { UtilsService } from '@core/services/utils.service';
import { ComplexFilterResult } from '@utils/helpers/complex-filter-helpers';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { pushFakeHistoryState } from '@utils/functions';
import { ComplexFilterDialogComponent } from '@shared/complex-filter-dialog/complex-filter-dialog.component';
import { ComplexFilterBase } from '@utils/helpers/complex-filter-base';
import { DestroyService } from '@profdepo-ui/core';
import { CompaniesFilterDialogComponent } from '@shared/companies-filter-dialog/companies-filter-dialog.component';
import { FormControl } from '@angular/forms';
import { UniversalWorkFilterDialogComponent } from '@shared/universal-work-filter-dialog/universal-work-filter-dialog.component';

@Component({
	selector: 'pdw-complex-filter-desktop',
	templateUrl: './complex-filter-desktop.component.html',
	styleUrls: ['./complex-filter-desktop.component.scss'],
	providers: [DestroyService]
})
export class ComplexFilterDesktopComponent extends ComplexFilterBase implements OnInit, OnChanges {
	@ViewChild('formField', { read: ElementRef }) formField: ElementRef;
	@Input() isCompaniesFilters = false;
	@Input() isUniversalWorksFilters = false;
	
	sortOptions = [
		{ id: 'modifytime', name: 'По новизне', direction: 'desc' },
		{ id: 'cost', name: 'По убыванию бюджета', direction: 'desc' },
		{ id: 'cost', name: 'По возрастанию бюджета', direction: 'asc' },
	];

	sortControl = new FormControl(this.sortOptions[0]);
	
	constructor(
		public utilsService: UtilsService,
		private dialog: MatDialog,
		private destroy$: DestroyService,
	) {
		super();
	}

	ngOnInit(): void {
		this.trueData = this.data;
		this.initialize();
		this.utilsService.mobileQuery1220px
			.pipe(
				takeUntil(this.destroy$),
				filter(x => x === false),
				debounceTime(0)
			)
			.subscribe({
				next: () => {
					this.fillForm(this.activeFilters.value);
				}
			});
	}

	/**
	 * Открывает диалоговое окно фильтра
	 */
	goFilter(): void {
		pushFakeHistoryState();

		const formFieldRect = this.formField.nativeElement.getBoundingClientRect();
		if (this.isCompaniesFilters) {
			this.dialog.open(CompaniesFilterDialogComponent, {
					position: {
						top: formFieldRect.y + 'px',
						left: formFieldRect.x + 'px'
					},
					width: this.formField.nativeElement.offsetWidth,
					data: {
						dataSource: this.data.dataSource,
						displayedFields: this.data.displayedFields,
						activeFilters: this.activeFilters.value,
						anyFilter: this.anyFilter.value,
						placeholder: this.data.placeholder,
						counterWordsToDeclension: this.data.counterWordsToDeclension,
					},
					panelClass: ['pd-complex-filter-dialog',],
					closeOnNavigation: false,
				})
				.afterClosed()
				.pipe(
					takeUntil(this.destroy$),
					filter(x => x)
				)
				.subscribe((result: ComplexFilterResult) => {
					this.activeFilters.next(result.filters);
					this.anyFilter.setValue(result.any, { emitEvent: false });
				});
			} else if (this.isUniversalWorksFilters) {
				this.dialog.open(UniversalWorkFilterDialogComponent, {
						position: {
							top: formFieldRect.y + 'px',
							left: formFieldRect.x + 'px'
						},
						width: this.formField.nativeElement.offsetWidth,
						data: {
							dataSource: this.data.dataSource,
							displayedFields: this.data.displayedFields,
							activeFilters: this.activeFilters.value,
							anyFilter: this.anyFilter.value,
							placeholder: this.data.placeholder,
							counterWordsToDeclension: this.data.counterWordsToDeclension,
						},
						panelClass: ['pd-complex-filter-dialog',],
						closeOnNavigation: false,
					})
					.afterClosed()
					.pipe(
						takeUntil(this.destroy$),
						filter(x => x)
					)
					.subscribe((result: ComplexFilterResult) => {
						this.activeFilters.next(result.filters);
						this.anyFilter.setValue(result.any, { emitEvent: false });
					});
			} else {
				this.dialog.open(ComplexFilterDialogComponent, {
						position: {
							top: formFieldRect.y + 'px',
							left: formFieldRect.x + 'px'
						},
						width: this.formField.nativeElement.offsetWidth,
						data: {
							dataSource: this.data.dataSource,
							displayedFields: this.data.displayedFields,
							activeFilters: this.activeFilters.value,
							anyFilter: this.anyFilter.value,
							placeholder: this.data.placeholder,
							counterWordsToDeclension: this.data.counterWordsToDeclension,
						},
						panelClass: ['pd-complex-filter-dialog',],
						closeOnNavigation: false,
					})
					.afterClosed()
					.pipe(
						takeUntil(this.destroy$),
						filter(x => x)
					)
					.subscribe((result: ComplexFilterResult) => {
						this.activeFilters.next(result.filters);
						this.anyFilter.setValue(result.any, { emitEvent: false });
					});
		}
	}

	checkboxListDisplayedName(arr: any[]): string | null {
		if (arr.length > 0) {
			return typeof arr[0] === 'object' ? 'name' : null;
		}
		return null;
	}


}
