import {Component, Input, OnInit} from '@angular/core';
import {WorkRequestChatMessageView} from "@models/work-request-chat-message-view";
import {BehaviorSubject,Subscription, timer} from "rxjs";

@Component({
  selector: 'app-work-requests-chat-messenger-countdown',
  templateUrl: './work-requests-chat-messenger-countdown.component.html',
})
export class WorkRequestsChatMessengerCountdownComponent implements OnInit {
  @Input() workRequestChatMessage: WorkRequestChatMessageView
  countDown: Subscription
  timer = new BehaviorSubject<Date>(null);
  constructor() { }

  ngOnInit(): void {
    let now = new Date();
    let el = String(this.workRequestChatMessage.createTime);
    let createTime = new Date(Date.parse(el));
    let tmp = 300000  - ( now.getTime() - createTime.getTime());
    this.timer.next(  new Date (tmp));
    this.countDown = timer(0, 1000).subscribe(
      () =>{
        let now = new Date();
        let tmp = 300000  - ( now.getTime() - createTime.getTime());
        this.timer.next(  new Date (tmp));
      }
    );
  }

}
