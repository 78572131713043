import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidators } from '@utils/validators/custom-validators';
import { DestroyService } from '@profdepo-ui/core';
import { catchError, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { TrueLoadingService } from '@core/services/true-loading.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SuccessVerificationDialogComponent } from '../success-verification-dialog/success-verification-dialog.component';
import { EMPTY } from 'rxjs';
import { AppUserView } from '@models/app-user-view';
import { AppUserService } from '@core/services/app-user.service';
import { VerificationData } from '../../../models/verification-data';
import { MaskitoOptions } from '@maskito/core';
import { dateMask } from '@utils/constants';

interface ControlInfo {
	name: string,
	errors: ValidationErrors
}

@Component({
	selector: 'pdw-verification-dialog',
	templateUrl: './verification-dialog.component.html',
	styleUrls: ['./verification-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, TrueLoadingService]
})
export class VerificationDialogComponent implements OnInit {

	verificationGroup: FormGroup;
	isReturned: boolean = false;
	readonly maskitoOptions: MaskitoOptions = {
		mask: [/\d/, /\d/, /\d/, /\d/, /\s/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
		preprocessors: [
			({ elementState, data }) => {
				if (data.length <= 11 && data.length >= 4) {
					const row = data.split('').map((v, index) => index === 4 ? v + '\xa0' : v).toString();
					return {
						elementState: elementState,
						data: row
					}
				}

				if (elementState.value.length === 3) {
					return {
						elementState: elementState,
						data: data + '\xa0'
					}
				}

				return {
					elementState: elementState,
					data: data
				}
			}
		]
	};
	public readonly dateMaskitoOptions: MaskitoOptions = {
		mask: dateMask,
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: AppUserView,
		private fb: FormBuilder,
		private destroy$: DestroyService,
		private dialog: MatDialog,
		public loading$: TrueLoadingService,
		private userService: AppUserService
	) {
		this.verificationGroup = this.fb.group({
			surname: [this.data.lastname, [Validators.required, Validators.maxLength(100)]],
			name: [this.data.firstname, [Validators.required, Validators.maxLength(100)]],
			patronymic: [this.data.middlename, [Validators.maxLength(100)]],
			birthday: [this.data.birthday, [Validators.required, CustomValidators.comingOfAgeValidator()]],
			passport: [null, [Validators.required, Validators.pattern('\\d{4}\\s\\d{6}')]]
		});
		this.loading$.next(false);
	}

	ngOnInit(): void {
		this.verificationGroup.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.isReturned = false;
			});
	}

	get isValid(): boolean {
		return this.verificationGroup.valid;
	}

	submit(): void {
		this.loading$.next(true);

		const d = new Date(this.verificationGroup.get('birthday').value);
		const date = new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes() - d.getTimezoneOffset());
		const data: VerificationData = {
			...this.verificationGroup.value,
			birthday: date,
			passport: this.verificationGroup.get('passport').value.toString().replace(/[^\x00-\x7F]/g, '')
		}

		this.userService.updateUserWithInn(data as VerificationData)
			.pipe(
				tap((user: AppUserView) => {
					if (user) {
						this.userService.setActiveUser(user);
						this.dialog.closeAll();
						this.dialog.open(SuccessVerificationDialogComponent, {
							autoFocus: true,
							disableClose: false,
							width: '581px',
							panelClass: ['pd-dialog', 'no-padding'],
						});
					}
				}),
				catchError(() => {
					this.isReturned = true;
					return EMPTY;
				}),
				finalize(() => {
					this.loading$.next(false);
				}),
				takeUntil(this.destroy$)
			).subscribe()
	}

	getErrorByControlName(name: string): ControlInfo {
		const control = this.verificationGroup.get(name);
		return { name: name, errors: control.errors }
	}
}
