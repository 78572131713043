<a *ngIf="item.children && item.children.length"
   mat-list-item
   [ngStyle]="{'padding-left': (depth * 24) + 'px'}"
   [routerLinkActive]="['is-active']"
   (click)="onItemSelected(item, $event)"
   class="pd-mat-list-item">
  <mat-icon mat-list-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
  <p mat-line>{{ item.title }}</p>
  <div class="pd-list-item-counter" *ngIf="item.counter">
    <span class="badge badge-primary">{{item.counter}}</span>
  </div>
  <span fxFlex *ngIf="item.children && item.children.length">
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">expand_more</mat-icon>
  </span>
  <p mat-line class="pd-menu-description" *ngIf="item.description">{{item.description}}</p>
</a>

<a *ngIf="!(item.children && item.children.length)"
   mat-list-item
   [ngStyle]="{'padding-left': (depth * 24) + 'px'}"
   [routerLink]="item.routerLink"
   [routerLinkActive]="['is-active']"
   class="pd-mat-list-item">
  <mat-icon mat-list-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
  <p mat-line>{{ item.title }}</p>
  <div class="pd-list-item-counter" *ngIf="item.counter">
    <span class="badge badge-primary">{{item.counter}}</span>
  </div>
  <p mat-line class="pd-menu-description" *ngIf="item.description">{{item.description}}</p>
</a>

<div *ngIf="expanded">
  <app-nav-menu-list-items *ngFor="let child of item.children" [item]="child" [depth]="depth + 1">
  </app-nav-menu-list-items>
</div>
