<mat-spinner
	diameter='60'
	strokeWidth='4'
	class='pd-spinner new-color'
	*ngIf='isLoading | async; else isLoaded'
></mat-spinner>
<ng-template #isLoaded>
	<ng-container *ngIf='data | async as data'>
		<ng-container *ngIf='data.length > 0; else empty'>
			<tui-scrollbar class='pd-scrollbar'>
				<div class='pdw-landing-news-posts-list'>
					<app-landing-news-post
						*ngFor='let item of data'
						[newsPostView]='item'
					></app-landing-news-post>
				</div>

			</tui-scrollbar>
		</ng-container>
		<ng-template #empty>
			<p class='pdw-landing-news-posts-empty'>
				Новостей пока нет.<br /> Как будут - сразу же опубликуем.
			</p>
		</ng-template>
	</ng-container>
</ng-template>

