import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
	selector: 'pdw-professional-card',
	templateUrl: './professional-card.component.html',
	styleUrls: ['./professional-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfessionalCardComponent {

}
