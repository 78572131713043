import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from '@core/services/utils.service';
import { LightModeWorkMarkView } from '@models/light-mode-work-mark-view';
import { WorkStateValue } from '@models/work-view';

@Component({
	selector: 'app-shortwork-mark-card-dialog',
	templateUrl: './short-work-mark-card-dialog.component.html',
	styleUrls: ['./short-work-mark-card-dialog.component.scss']
})
export class ShortWorkMarkCardDialogComponent {
	WorkState = WorkStateValue;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { title: string, workMarkView: LightModeWorkMarkView },
		public utilsService: UtilsService
	) {
	}


	averageMark(): number {
		const mark = this.data.workMarkView;
		return +((mark.speedValue + mark.targetryValue + mark.adequacyValue) / 3).toFixed(1);
	}

	protected readonly WorkStateValue = WorkStateValue;
}
