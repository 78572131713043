import {Component, Input} from '@angular/core';
import {CompanyEmailView} from "@models/company-email-view";

@Component({
  selector: 'app-email-details',
  templateUrl: './email-details.component.html',
})
export class EmailDetailsComponent {
  @Input() emailView: CompanyEmailView;
  constructor() { }
}
