import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(value: number): string {
    let type: string[] = [
      'Б',
      'КБ',
      'МБ',
      'ГБ',
    ]

    let tmp: number = value;
    let order: number = 0;

    while (tmp > 1024){
      tmp /= 1024;
      ++order;
    }

    return tmp.toFixed(2) + type[order];
  }

}
