import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'workFile'
})
export class WorkFilePipe extends AbstractModelPipe implements PipeTransform {
  properties = [
    {name: 'title', title: 'Наименование', hint: 'Наименование'},
    {name: 'work', title: 'Работа', hint: 'Работа'},
    {name: 'name', title: 'Наименование', hint: 'Наименование документа'},
    {name: 'description', title: 'Описание', hint: 'Описание документа'},
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
  allProperties() {
    return this.properties;
  }
}
