import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppUserService } from '@core/services/app-user.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-rating-one-small',
	templateUrl: './rating-one-small.component.html',
	styleUrls: ['./rating-one-small.component.scss']
})
export class RatingOneSmallComponent implements OnInit, OnDestroy {
	@Input()
	set percent(value: number) {
		this._percent.next(value);
	};

	get percent(): number {
		return this._percent.getValue();
	}

	/**
	 * Флаг - использовать входное значение, false - персчитать, как процентное значение
	 * default = false
	 */
	@Input() useInitValue = false;
	/**
	 * Количество знаков после запятой
	 * default = 1
	 */
	@Input() fractionDigits = 1;
	ratingColorClass: (value: number) => string = AppUserService.ratingColorClass;
	private _percent = new BehaviorSubject<number>(0);
	maxRating = 5;
	value: number;
	unsubscribe: Subject<any> = new Subject<any>();

	constructor() {
	}

	ngOnInit(): void {
		this._percent
			.pipe(
				takeUntil(this.unsubscribe),
				filter(x => x !== null)
			)
			.subscribe({
				next: v => {
					if (this.useInitValue) {
						this.value = v;
					} else {
						const tmpValue = v / 100 * this.maxRating;
						this.value = Math.round(tmpValue * 10) / 10;
					}
				}
			});
	}

	ngOnDestroy() {
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}

}
