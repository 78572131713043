<div
	*ngIf='currentCompanyView | async as currentCompanyView'
	class='pd-company-logo'
>
	<div
		class='pd-company-logo-wrapper'
		(mouseover)='setShowEditBtn()'
		(mouseleave)='setHideEditBtn()'
	>
		<ng-container *ngIf='currentCompanyView.hasLogo; else noAvatar'>
			<mat-spinner
				*ngIf='loading'
				[diameter]='35'
				[strokeWidth]='2'
			></mat-spinner>
			<div
				class='wrapper'
				*ngIf='currentCompanyView.hasLogo && showEditBtn'
			>
				<button
					*ngIf='canUpdate'
					class='pd-company-edit-btn'
					mat-raised-button
					(click)='goUpdateLogo()'
				>
					<mat-icon
						class='pd-icon'
						svgIcon='additional-camera'
					></mat-icon>
					<br>
					Сменить фото
					<p class='btn-subtext'>профиля</p>
				</button>
			</div>
			<img
				class='pd-company-logo-image'
				[ngStyle]="{visibility: loading ? 'hidden' : ''}"
				(load)='onLoad()'
				[src]="currentCompanyView | avatar: 'api/Companies/Logo' | async"
				alt='Avatar image'
			/>
		</ng-container>
		<ng-template #noAvatar>
			<mat-icon
				class='pd-company-logo-empty-icon'
				[ngClass]="{'can-update': canUpdate }"
				[svgIcon]="'additional2-img-box'"
			></mat-icon>
		</ng-template>
		<ng-container *ngIf='canUpdate'>
			<button
				*ngIf='!currentCompanyView.hasLogo'
				class='pd-button-secondary small pd-company-logo-btn'
				mat-raised-button
				(click)='goUpdateLogo()'
			>
				<mat-icon
					class='pd-icon'
					svgIcon='additional-plus'
				></mat-icon>
				Добавить логотип
			</button>
		</ng-container>
	</div>
</div>
