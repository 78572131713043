import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'space'
})
export class SpacePipe implements PipeTransform {
	/**
	 * Разделяеят число по разрядам символом (по умолчанию пробел)
	 * @param value
	 * @param separator
	 */
	transform(value: number, separator: string = '\xa0'): unknown {
		return Math.round(+value).toString().split(/(?=(?:\d{3})+(?!\d))/).join(separator);
	}

}
