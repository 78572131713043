import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'workExecutor'
})
export class WorkExecutorPipe extends AbstractModelPipe implements PipeTransform {
  properties = [
    {name: 'title', title: 'Наименование', hint: 'Наименование'},
    {name: 'work', title: 'Работа', hint: 'Работа'},
    {name: 'name', title: 'Наименование', hint: 'Наименование работы'},
    {name: 'requisition', title: 'Требования', hint: 'Требования к исполнителю'},
    {name: 'description', title: 'Что нужно сделать?', hint: 'Перечень основных решаемых вопросов'},
    {name: 'hardSkills', title: 'Профессиональные качества', hint: 'Требуемые профессиональные качества'},
    {name: 'planDuration', title: 'Плановая продолжительность в часах', hint: 'Плановая продолжительность участия исполнителя в часах', titleAlt: 'Продолжительность'},
    {name: 'duration', title: 'Продолжительность', hint: 'Фактическая продолжительность участия исполнителя'},
    {name: 'planCost', title: 'Плановое вознаграждение в рублях', hint: 'Плановое вознаграждение исполнителя в рублях', titleAlt: 'Бюджет в ₽'},
    {name: 'cost', title: 'Вознаграждение', hint: 'Фактическое вознаграждение исполнителя'},
    {name: 'format', title: 'Формат работы', hint: 'Формат работы исполнителя'},
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
  allProperties() {
    return this.properties;
  }
}
