import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SalaryData } from '@general/features/aggregator/types/salary-types';
import {
	AggregatorFilter,
	AggregatorView, FilterParams
} from '@general/features/works/pages/career-gid-works-page/types/aggregator-work.types';
import { transformFilterToQuery } from '@utils/helpers/query.helper';

export interface SalaryAIData {
	description: string,
	salary: string,
}

@Injectable({
	providedIn: 'root'
})
export class AggregatorService {

	private readonly API_URL: string = 'api/Aggregator';

	constructor(private http: HttpClient) {
	}

	getAggregatorWork(id: string): Observable<AggregatorView> {
		return this.http.get<AggregatorView>(this.API_URL + '/' + id);
	}

	showAll(body: AggregatorFilter = null, params?: FilterParams): Observable<Array<AggregatorView>> {
		const httpParams: string = new HttpParams({ fromObject: params as Record<string, string | number> }).toString()
		return this.http.post<Array<AggregatorView>>(this.API_URL +`/showAll?${httpParams}`, body ?? {});
	}

	showAllCount(body: AggregatorFilter = null): Observable<number> {
		return this.http.post<number>(this.API_URL + '/showAllCount' , body ?? {});
	}

	filterSchedules(filter: string): Observable<string[]> {
		return this.http.post<string[]>(this.API_URL + '/FilterSchedules', { filter: filter })
	}

	filterCity(filter: string): Observable<string[]> {
		return this.http.post<string[]>(this.API_URL + '/FilterCity', { filter: filter })
	}

	filterCompany(filter: string): Observable<string[]> {
		return this.http.post<string[]>(this.API_URL + '/FilterCompany', { filter: filter })
	}

}
