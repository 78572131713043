import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppUserView } from '@models/app-user-view';
import { BehaviorSubject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { AppUserEducationAdditionalsDialogComponent } from '@shared/app-user-education-additionals-dialog/app-user-education-additionals-dialog.component';
import { pushFakeHistoryState } from '@utils/functions';
import { NotificationService } from '@profdepo-ui/core';
import { AppUserService } from '@core/services/app-user.service';
import { EducationView } from '@models/education-view';
import { YesNo } from '@models/enums';

@Component({
	selector: 'app-app-user-education-additionals',
	templateUrl: './app-user-education-additionals.component.html',
})
export class AppUserEducationAdditionalsComponent implements OnInit {
	@Input() canUpdate: boolean;

	@Input()
	set currentUserView(value: AppUserView) {
		this._currentUserView.next(value);
	};

	get currentUserView(): AppUserView {
		return this._currentUserView.getValue();
	}

	private _currentUserView = new BehaviorSubject<AppUserView>(null);

	currentAppUserView = new BehaviorSubject<AppUserView>(null);
	educationAdditionalDataSource = new MatTableDataSource<EducationView>();
	@Output() changed = new EventEmitter<any>();

	constructor(
		private dialog: MatDialog,
		private notificationService: NotificationService,
		private userService: AppUserService
	) {
	}

	ngOnInit(): void {
		this._currentUserView
			.pipe(
				filter(x => x !== null)
			)
			.subscribe((appUserView: AppUserView) => {
				this.currentAppUserView.next(appUserView);
				this.educationAdditionalDataSource = new MatTableDataSource<EducationView>(appUserView.educations?.filter(item => item.isCourse === YesNo.yes));
			});

	}

	isEducationsExist(): boolean {
		return this.educationAdditionalDataSource.data?.some(item => item.isCourse === YesNo.yes)
	}
	goUpdateEducationAdditionals(): void {
		pushFakeHistoryState();

		this.dialog.open(AppUserEducationAdditionalsDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				minHeight: '320px',
				panelClass: ['pd-dialog'],
				data: {
					title: 'Повышение квалификации, курсы',
					method: 'update',
					appUserView: this.currentAppUserView.value
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
			)
			.subscribe({
				next: (data) => {
					this.userService.setActiveUser(data);
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}
}
