import {BehaviorSubject, Observable} from "rxjs";
import {NotificationView} from "@models/notification-view";
import {NotificationsRequestService} from "@core/services/notifications-request.service";
import {CollectionViewer} from "@angular/cdk/collections";
import {map, tap} from "rxjs/operators";

export class ReadNotificationDataSource {
  private notificationViewsSubject = new BehaviorSubject<NotificationView[]>([]);
  private countSubject = new BehaviorSubject<number>(0);

  constructor(
    private notificationService: NotificationsRequestService
  ) {}

  data = this.notificationViewsSubject.asObservable();
  count = this.countSubject.asObservable();

  connect(collectionViewer: CollectionViewer): Observable<NotificationView[]> {
    return this.notificationViewsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.notificationViewsSubject.complete();
    this.countSubject.complete();
  }

  load(pageIndex: number, pageSize: number): Observable<NotificationView[]> {
    return this.notificationService.read(pageIndex, pageSize)
      .pipe(
        tap(notificationViews => this.notificationViewsSubject.next(notificationViews))
      );
  }

  loadMore(pageIndex: number, pageSize: number): Observable<NotificationView[]> {
    return this.notificationService.read(pageIndex, pageSize)
      .pipe(
        map(xx => xx.filter(x => !this.notificationViewsSubject.value.some(a => a.id === x.id))),
        tap(notificationViews => {
          this.notificationViewsSubject.next(this.notificationViewsSubject.value.concat(notificationViews))
        })
      );
  }

  loadCount(): Observable<number> {
    return this.notificationService.readCount()
      .pipe(
        tap(count => this.countSubject.next(count))
      );
  }
}
