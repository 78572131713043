import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
	selector: 'pdw-salary-registration-form',
	templateUrl: './salary-registration-form.component.html',
	styleUrls: ['./salary-registration-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalaryRegistrationFormComponent {
	@Input() formGroup!: FormGroup;

	get password(): FormControl {
		return this.formGroup.get('password') as FormControl;
	}

	get email(): FormControl {
		return this.formGroup.get('email') as FormControl;
	}

	get passwordConfirm(): FormControl {
		return this.formGroup.get('passwordConfirm') as FormControl;
	}

}
