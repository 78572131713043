import { Pipe, PipeTransform } from '@angular/core';
import {AbstractEnumPipe} from "@public/pipes/abstract-enum.pipe";

@Pipe({
  name: 'sendEmailTo'
})
export class SendEmailToPipe extends AbstractEnumPipe implements PipeTransform {
  values = [
    'Никому',
    'Владелец проекта',
    'Руководитель проекта',
    'Автор задачи',
    'Исполнитель задачи'
  ];
  transform(value: any, ...args: any[]): any {
    return this.getTransform(value, args);
  }
}
