import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReviewOfUserDialogComponent } from '../../../components/dialogs/review-of-user-dialog/review-of-user-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AppUserView } from '@models/app-user-view';

@Component({
	selector: 'pdw-profdepo-ai-block',
	templateUrl: './profdepo-ai-block.component.html',
	styleUrls: ['./profdepo-ai-block.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfdepoAiBlockComponent {

	@Input() user: AppUserView = null;
	@Input() canOpenModal: boolean = true;
	constructor(private dialog: MatDialog) {
	}
	openAIRateModal(event: Event): void {
		event.stopPropagation();
		event.preventDefault();

		if (this.canOpenModal) {
			this.dialog.open(ReviewOfUserDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: ['pd-dialog', 'no-padding'],
				data: this.user
			})
		}
	}

}
