import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'education'
})
export class EducationPipe extends AbstractModelPipe implements PipeTransform {
  properties = [
    {name: 'level', title: 'Уровень', hint: 'Уровень образования'},
    {name: 'name', title: 'Учебное заведение', hint: 'Учебное заведение'},
    {name: 'sector', title: 'Факультет', hint: 'Факультет'},
    {name: 'speciality', title: 'Специализация', hint: 'Специализация'},
    {name: 'year', title: 'Год окончания', hint: 'Год окончания обучения'},
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
  allProperties() {
    return this.properties;
  }
}
