import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'phone'
})
export class PhonePipe extends AbstractModelPipe implements PipeTransform {
  properties = [
    {name: 'number', title: 'Номер телефона', hint: 'Номер телефона'},
    {name: 'purpose', title: 'Тип телефона', hint: 'Тип телефона'},
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
  allProperties() {
    return this.properties;
  }
}
