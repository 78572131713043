import {Component, Input} from '@angular/core';
import {AppUserType} from "@models/app-user-view";
import {AppUserTypeValuePipe} from "@public/pipes/app-user-type-value.pipe";
import {CompanyView} from "@models/company-view";

@Component({
  selector: 'app-company-link',
  templateUrl: './company-link.component.html',
})
export class CompanyLinkComponent {
  @Input() appUserType: AppUserType;
  @Input() companyView: CompanyView;
  appUserTypeValuePipe = new AppUserTypeValuePipe();
  constructor() { }
}
