import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-rating-stars',
	templateUrl: './rating-stars.component.html',
})
export class RatingStarsComponent implements OnInit {
	@Input() rating: number;
	@Input() stars: number;
	@Input() showRating: boolean = true;
	@Input() showStars: boolean = true;
	allStars: number[] = [1, 2, 3, 4, 5];

	constructor() {
	}

	ngOnInit(): void {
	}

	isFillStar(value: number): boolean {
		if (this.stars > value - 0.5 && this.stars < value) {
			return true;
		}
		return value <= Math.floor(this.stars);
	}

	// isHalfStar(value: number): boolean {
	//   console.log(value)
	//   return this.stars > value - 1 && this.stars < value;
	// }
	isEmptyStar(value: number): boolean {
		if (this.stars > value - 1 && this.stars <= value - 0.5) {
			return true;
		}
		return Math.ceil(this.stars) < value;
	}

	isActive(value: number): boolean {
		return value <= Math.ceil(this.stars);
	}
}
