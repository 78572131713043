import {
	Component,
	Inject,
	Input,
	LOCALE_ID,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { BehaviorSubject, Subject, withLatestFrom } from 'rxjs';
import { WorkService, WorksFilter } from '@core/services/work.service';
import { AnySort } from '@utils/any-sort';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsRequestService } from '@core/services/notifications-request.service';
import { UtilsService } from '@core/services/utils.service';
import { distinctUntilChanged, filter, finalize, takeUntil } from 'rxjs/operators';
import { WorkView } from '@models/work-view';
import { TuiCarouselComponent } from '@taiga-ui/kit';
import { TUI_IS_MOBILE } from '@taiga-ui/cdk';
import { AppUserView } from '@models/app-user-view';
import { AppUserService } from '@core/services/app-user.service';
import { AllWorkDataSource } from '@core/dataSources/all-work-data-source';
import { CompanyView } from '@models/company-view';
import { ManagerWorksDataSource } from '@core/dataSources/manager-works-data-source';
import { ManagerWorksDataSourceShort } from '@core/dataSources/manager-works-data-source-short';
import { AllWorkDataSourceShort } from '@core/dataSources/all-work-data-source-short';

@Component({
	selector: 'pdw-works-card-company-slider',
	templateUrl: './works-card-company-slider.component.html',
	styleUrls: ['./works-card-company-slider.component.scss']
})
export class WorksCardCompanySliderComponent implements OnInit, OnDestroy {
	activeAppUserView = new BehaviorSubject<AppUserView>(null);
	/**
	 * Является ли пользователь заказчиком
	 */
	@Input() canUpdate: boolean = false;

	/**
	 * Источник данных для получения записей удовлетворяющих критериям поиска
	 */
	@Input() dataSource: ManagerWorksDataSource | AllWorkDataSource;

	@Input() dataSourceShort: ManagerWorksDataSourceShort | AllWorkDataSourceShort;

	/**
	 * Количество колонок
	 * @param value количество колонок
	 */
	@Input()
	set columnsCount(value: number) {
		this._columnsCount.next(value);
	};

	get columnsCount(): number {
		return this._columnsCount.getValue();
	}

	private _columnsCount = new BehaviorSubject<number>(null);
	/**
	 * Ширина одной карточки специалиста
	 * Размер должен быть согласован так, чтобы перенос карточек в браузере
	 * при изменении размера окна совпадал с пересчетом количеста колонок
	 */
	@Input() cardWidth: number = 300;

	@Input()
	set filterValues(value: WorksFilter) {
		this._filterValues.next(value);
	};

	get filterValues(): WorksFilter {
		return this._filterValues.getValue();
	}

	private _filterValues = new BehaviorSubject<WorksFilter>(null);

	@Input()
	set sortValues(value: AnySort) {
		this._sortValues.next(value);
	};

	get sortValues(): AnySort {
		return this._sortValues.getValue();
	}

	@Input() loadAll = false;

	@Input()
	set companyView(value: CompanyView) {
		this._companyView.next(value);
	};

	get companyView(): CompanyView {
		return this._companyView.getValue();
	}

	private _companyView = new BehaviorSubject<CompanyView>(null);
	private _sortValues = new BehaviorSubject<AnySort>(null);
	pageIndex = 0;
	pageSize = 6;
	remain = new BehaviorSubject<number>(null);
	unsubscribe: Subject<any> = new Subject<any>();
	loadingData = new BehaviorSubject<boolean>(false);
	loadingNextPage = new BehaviorSubject<boolean>(false);
	@ViewChild('carousel') carousel: TuiCarouselComponent;
	isMobile: boolean;

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private workService: WorkService,
		private notificationService: NotificationsRequestService,
		@Inject(LOCALE_ID) public locale: string,
		public utilsService: UtilsService,
		@Inject(TUI_IS_MOBILE) private readonly _isMobile: boolean,
		private appUserService: AppUserService
	) {
		this.isMobile = _isMobile;
	}

	ngOnInit(): void {
		this.loadingData.next(true);
		this.appUserService.getActiveUserAsObservable()
			.pipe(
				takeUntil(this.unsubscribe),
				filter(x => x !== null)
			)
			.subscribe({
				next: v => this.activeAppUserView.next(v)
			});

		this._companyView
			.pipe(
				takeUntil(this.unsubscribe),
				filter(x => x !== null),
			)
			.subscribe(v => {
				this.pageSize = this.calcPageSize(this.columnsCount);
				this.loadingData.next(true);
				const firstLoad = this.pageSize * (this.pageIndex + 1) > 6 ? this.pageSize * (this.pageIndex + 1) : 6
				this.load(0, firstLoad, this.companyView.id);
			});

		this._columnsCount
			.pipe(
				filter(x => x !== null && x > 0),
				distinctUntilChanged(),
				withLatestFrom(this.dataSource.data),
			)
			.subscribe(data => {
				this.pageSize = this.calcPageSize(data[0]);
				const p = this.calcPageIndex(data[1].length, this.pageSize);
				this.pageIndex = p.pageIndex;
				if (p.reload) {
					this.loadMore(this.pageIndex, this.pageSize, false, null, this.companyView.id);
				}
				this.loadCount();
			});

		this._filterValues
			.pipe(
				filter(x => x !== null && this.columnsCount > 0),
				distinctUntilChanged(),
			)
			.subscribe(data => {
				this.pageSize = this.calcPageSize(this.columnsCount);
				this.loadingData.next(true);
				this.pageIndex = 0;
				this.load(this.pageIndex, this.pageSize, this.companyView.id);
				this.loadCount();
			});

		this._sortValues
			.pipe(
				filter(x => x !== null && this.columnsCount > 0),
				distinctUntilChanged(),
			)
			.subscribe(data => {
				this.pageSize = this.calcPageSize(this.columnsCount);
				this.loadingData.next(true);
				this.load(0, this.pageSize * (this.pageIndex + 1), this.companyView.id);
			});

		this.workService.shouldReload
			.pipe(
				filter(x => x === true),
				takeUntil(this.unsubscribe)
			)
			.subscribe({
				next: v => {
					this.pageSize = this.calcPageSize(this.columnsCount);
					this.loadingData.next(true);
					this.load(0, this.pageSize * (this.pageIndex + 1), this.companyView.id);
					this.loadCount();
				}
			});

	}

	/**
	 * Определяет актуальный номер страницы исходя из количества загруженных данных и размера страницы
	 * @param dataLength количества загруженных данных
	 * @param pageSize размера страницы
	 */
	calcPageIndex(dataLength: number, pageSize: number): any {
		const pageIndex = dataLength / pageSize;
		const pageIndexTrunc = Math.floor(pageIndex);

		/**
		 * Если вычисленный номер страницы не дотягивает до двух,
		 * то значит загрузить только первую страницу
		 */
		if (pageIndex < 2.0) {
			return {
				pageIndex: 0,
				reload: dataLength < pageSize
			};
		}

		/**
		 * В остальных случаях округлять страницу в меньшую сторону
		 */
		return {
			pageIndex: pageIndexTrunc - 1,
			reload: dataLength < pageSize * pageIndexTrunc
		}
	}

	/**
	 * Определяет размер страницы для указанного количества колонок
	 * @param columnsCount количество колонок
	 */
	calcPageSize(columnsCount: number): number {
		if (columnsCount > 2)
			return columnsCount;

		return 6;
	}

	loadCount(): void {
		this.dataSource.loadCount(this.filterValues)
			.pipe(
				takeUntil(this.unsubscribe),
			)
			.subscribe(x => {
				const remain = x - (this.pageIndex + 1) * this.pageSize;
				this.remain.next(remain > this.pageSize ? this.pageSize : remain);
			});
	}

	/**
	 * Загружает указанную страницу данных указанного размера
	 * @param pageIndex номер страницы
	 * @param pageSize размер страницы
	 */
	load(pageIndex: number, pageSize: number, companies: number): void {
		if (this.filterValues.isFreelance === -1) {
			this.filterValues.isFreelance = 0;
		}
		
		this.dataSource.load(
				this.filterValues,
				this.sortValues.name + ',' + this.sortValues.direction,
				pageIndex,
				this.loadAll ? this.getAllCount() : pageSize, false, companies)
			.pipe(
				finalize(() => {
					this.loadingData.next(false);
					this.loadingNextPage.next(false);
					this.workService.shouldReload.next(false);
				}),
				takeUntil(this.unsubscribe),
			)
			.subscribe(x => {
				this.workService.shouldReload.next(false);
				this.loadingData.next(false);
				this.loadingNextPage.next(false);
			});
	}

	/**
	 * Загружает указанную страницу данных указанного размера
	 * @param pageIndex номер страницы
	 * @param pageSize размер страницы
	 * @param shouldSwipe Триггер прокрутки слайдера, после загрузки
	 * @param carousel Карусель
	 */
	loadMore(pageIndex: number, pageSize: number, shouldSwipe = false, carousel: TuiCarouselComponent = null, companies?:number): void {
		this.dataSource.loadMore(
				this.filterValues,
				this.sortValues.name + ',' + this.sortValues.direction,
				pageIndex,
				this.loadAll ? this.getAllCount() : pageSize, false, companies)
			.pipe(
				finalize(() => {
					this.loadingData.next(false);
					this.loadingNextPage.next(false);
				}),
				takeUntil(this.unsubscribe),
			)
			.subscribe(x => {
				this.loadingData.next(false);
				if (shouldSwipe) {
					carousel.next();
				}
				this.loadingNextPage.next(false);
			});
	}

	/**
	 * Загружает следующую страницу данных
	 */
	goNextPage(carousel: TuiCarouselComponent, data: WorkView[]): void {
		if (this.remain.value > 0 && carousel.index >= data.length - this.columnsCount) {
			this.loadingNextPage.next(true);
			this.pageIndex++;
			this.loadMore(this.pageIndex, this.pageSize, true, carousel);
			this.loadCount();
		} else {
			carousel.next();
		}
	}

	isNextButtonDisabled(carousel: TuiCarouselComponent, data: WorkView[]): boolean {		
		if (this.remain.value <= 0 && carousel.index >= data.length - this.columnsCount)
			return true;

		if (this.loadingNextPage.value)
			return true;

		return false
	}

	onStateChange(workView: any): void {
		if (workView == 'deleted') {
			this.workService.shouldReload.next(true);
			return;
		}
		this.dataSource.loadOne(workView.id)
			.pipe(
				takeUntil(this.unsubscribe),
			)
			.subscribe();
	}

	/**
	 * Загружает данные на свайп слайдера
	 * @param carousel
	 * @param data
	 */
	onSwipe(carousel: TuiCarouselComponent, data: WorkView[]): void {
		if (!this.isMobile)
			return;

		if (this.remain.value > 0 && carousel.index >= data.length - this.columnsCount) {
			this.loadingNextPage.next(true);
			this.pageIndex++;
			this.loadMore(this.pageIndex, this.pageSize);
			this.loadCount();
		}
	}

	/**
	 * Отписывает подписки при уничтожении компоненты
	 */
	ngOnDestroy() {
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}

	getAllCount() {
		return this.companyView.worksInExecuting + this.companyView.worksInFinished + this.companyView.worksInSeeking;
	}

	setNextButtonDisabled(columnsCount: number, value: number, index: number) {
		if (value <= columnsCount) {
			return index;
		}
		switch (columnsCount) {
			case 1:
				return Number(((0.5 * value) - 1).toFixed());
			case 2:
				return Number((((11 / 14) * value) - (5 / 7)).toFixed());
			case 3:
				return Number((((9 / 14) * value) - (13 / 7)).toFixed());
			default:
				return Number(((0.5 * value) - 1).toFixed());
		}
	}
}
