<div class="auth-dialog-wrapper">
	<div class="cls-btn-wraper">
		<button
			class='pd-icon-button'
			mat-icon-button
			[mat-dialog-close]='false'
		>
			<mat-icon svgIcon='interface-close'></mat-icon>
		</button>
	</div>
	<div class='pd-dialog-content large-padding' *ngIf="authMode === 0">
		<div
			class='pd-register'
			*ngIf='registrationForm'
		>
			<form
				id='registrationForm'
				[formGroup]='registrationForm'
				(ngSubmit)='onSubmitRegister(registrationForm)'
			>
				<div class='pd-register-form-wrapper'>
					<div class='pd-register-fields-wrapper'>
						<h3 class='pd-login-title'>Регистрация</h3>
						<p class='pd-login-text pd-register-text'>
							У Вас уже есть учетная запись? <a
							class='pd-auth-login-text switch-mode-btn'
							[routerLink]=""
							(click)="onGoLogin()"
						>Войти</a>
						</p>
						<!--Open Auth-->
						<div
							class='pd-oauth-container register'
							[ngClass]="{ 'has-not-shown': afterConfirmationEmail || afterResetPassword }"
						>
							<button
								class='pd-button-oauth register'
								type='button'
								mat-flat-button
								[disabled]='!isProduction'
								(click)='goOAuthLink(provider.name)'
								*ngFor='let provider of openAuthProviders'
							>
								<mat-icon
									class='pd-icon {{ provider.name | lowercase }}'
									svgIcon='{{ provider.icon }}'
								></mat-icon>
								<span>{{ provider.title }}</span>
							</button>
						</div>
						<!--  Email   -->
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
							hideRequiredMarker
						>
							<!--          <mat-icon class="pd-field-prefix-icon" svgIcon="additional-mail" matPrefix></mat-icon>-->
							<mat-label class='pd-auth-new-font'>Email</mat-label>
							<input
								matInput
								formControlName='email'
							/>
							<mat-error
								*ngIf='email?.errors?.required'
								i18n='mat-error@@register-email-required'
							>Обязательное поле
							</mat-error>
							<mat-error
								*ngIf='email?.errors?.pattern'
							>Email введен не корректно!
							</mat-error>
							<mat-error *ngIf='email?.errors?.serverError'>
								{{ email?.errors?.serverError }}
							</mat-error>
						</mat-form-field>
						<!--  Password   -->
						<mat-form-field
							class='pd-input-form-field custom-form-field'
							appearance='fill'
							hideRequiredMarker
						>
							<!--          <mat-icon class="pd-field-prefix-icon" svgIcon="additional-shield-done" matPrefix></mat-icon>-->
							<mat-label class='pd-auth-new-font'>Пароль</mat-label>
							<input
								matInput
								formControlName='password'
								[type]="passwordHide ? 'password' : 'text'"
							/>
							<button
								tabindex='-1'
								mat-icon-button
								matSuffix
								class='pd-icon-button light-gray new-color'
								type='button'
								[attr.aria-label]="authPipe.transform('passwordHide', 'propertyTitle')"
								[attr.aria-pressed]='passwordHide'
								(click)='passwordHide = !passwordHide'
							>
								<mat-icon [svgIcon]="passwordHide ? 'additional-view' : 'additional-view-hide'"></mat-icon>
							</button>
							<mat-error
								*ngIf='password?.errors?.required'
								i18n='mat-error@@register-password-required'
							>Обязательное поле
							</mat-error>
							<mat-error
								*ngIf='password?.errors?.pattern'
								i18n='mat-error@@register-password-pattern'
							>
								Пароль должен быть не менее 8 символов и содержать цифры и буквы большого и малого регистра
							</mat-error>
							<mat-error
								*ngIf='password?.errors?.maxlength'
							>Введите не более 20 символов!
							</mat-error>
							<mat-error *ngIf='password?.errors?.serverError'>
								{{ password?.errors?.serverError }}
							</mat-error>
						</mat-form-field>
						<!--  PasswordConfirm   -->
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
							hideRequiredMarker
						>
							<!--          <mat-icon class="pd-field-prefix-icon" svgIcon="interface-attention-circle" matPrefix></mat-icon>-->
							<mat-label class='pd-auth-new-font'>Повторите пароль</mat-label>
							<input
								matInput
								formControlName='passwordConfirm'
								[type]="passwordConfirmHide ? 'password' : 'text'"
								[errorStateMatcher]='matcher'
							/>
							<button
								tabindex='-1'
								mat-icon-button
								matSuffix
								class='pd-icon-button light-gray new-color'
								type='button'
								[attr.aria-label]="authPipe.transform('passwordConfirmHide', 'propertyTitle')"
								[attr.aria-pressed]='passwordConfirmHide'
								(click)='passwordConfirmHide = !passwordConfirmHide'
							>
								<mat-icon
									[svgIcon]="passwordConfirmHide ? 'additional-view' : 'additional-view-hide'"
								></mat-icon>
							</button>
							<mat-error
								*ngIf='passwordConfirm?.errors?.required'
								i18n='mat-error@@register-passwordConfirm-required'
							>Обязательное поле
							</mat-error>
							<mat-error
								*ngIf="registrationForm?.hasError('notSame') && !passwordConfirm?.errors?.required"
								i18n='mat-error@@register-passwordConfirm-notSame'
							>Указанные пароли не совпадают!
							</mat-error>
							<mat-error *ngIf='passwordConfirm?.errors?.serverError'>
								{{ passwordConfirm?.errors?.serverError }}
							</mat-error>
						</mat-form-field>
						<!--Type-->
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
							hideRequiredMarker
						>
							<pd-select
								class="custom-select"
								formControlName='type'
								[compareWith]='compareEnum'
								required
							>
								<pd-select-option
									class='pd-auth-new-color'
									*ngFor='let item of appUserTypes | appUserType'
									[value]='+item.key'
								>
									{{ item.value }}
								</pd-select-option>
							</pd-select>
							<button
								class='pd-icon-button gray new-color'
								matSuffix
								mat-icon-button
								type='button'
							>
								<mat-icon
									class='pd-icon arrow-down-2'
									svgIcon='arrow-down-2'
								></mat-icon>
							</button>
						</mat-form-field>
						<app-captcha formControlName='captcha'></app-captcha>
					</div>
					<p class='pd-register-terms-label'>
						Продолжая, вы принимаете
						<a
							class='pd-link pd-register-link'
							href='https://profdepo.ru/termsOfUse'
							target='_blank'
						>пользовательское соглашение</a
						>
						и
						<a
							class='pd-link pd-register-link'
							href='https://profdepo.ru/privacyPolicy'
							target='_blank'
						>политику конфиденциальности</a
						>
					</p>
					<button
						class='pd-button new-color'
						form='registrationForm'
						mat-raised-button
						color='primary'
					>
						Зарегистрироваться
					</button>
				</div>
			</form>
		</div>
	</div>
	<div class='pd-dialog-content large-padding' *ngIf="authMode === 1">
		<div
			class='pd-login'
			*ngIf='loginForm'
		>
			<form
				id='loginForm'
				[formGroup]='loginForm'
				(ngSubmit)='onSubmitLogin(loginForm)'
			>
				<div class='pd-login-form-wrapper'>
					<div class='pd-login-fields-wrapper'>
						<h3 class='pd-login-title'>Войти</h3>
						<p class='pd-login-text'>Новый пользователь? <a
							class='pd-auth-login-text switch-mode-btn'
							[routerLink]=""
							(click)="onGoRegister()"
						>Создать учетную запись</a></p>

						<ng-container *ngIf='afterResetPassword'>
							<div class='form-field-reseted-container'>
								Пароль успешно изменен!
							</div>
						</ng-container>
						<ng-container *ngIf='afterForgotPassword'>
							<div class='form-field-reseted-container'>
								Ссылка для подтверждения сброса пароля
								отправлена на вашу почту
							</div>
						</ng-container>

						<ng-container *ngIf='afterConfirmationEmail'>
							<div class='form-field-reseted-container'>
								Ваша почта успешно подтверждена!
							</div>
						</ng-container>
						<!--Email-->
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
							hideRequiredMarker
						>
							<!--          <mat-icon class="pd-field-prefix-icon" svgIcon="additional-user-1" matPrefix></mat-icon>-->
							<mat-label class='pd-auth-new-font'>Email</mat-label>
							<input
								matInput
								formControlName='userName'
							>
							<mat-error
								*ngIf='userName?.errors?.required'
								i18n='mat-error@@login-userName-required'
							>Обязательное поле
							</mat-error>
							<mat-error
								*ngIf='userName?.errors?.pattern'
							>
								Неверный формат почты
							</mat-error>
							<mat-error
								*ngIf='userName?.errors?.serverError'
								i18n='mat-error@@login-userName-serverError'
							>
								{{ userName?.errors?.serverError }}
							</mat-error>
						</mat-form-field>
						<!--Password-->
						<mat-form-field
							class='pd-input-form-field form-field'
							appearance='fill'
							hideRequiredMarker
						>
							<!--          <mat-icon class="pd-field-prefix-icon" svgIcon="additional-shield-done" matPrefix></mat-icon>-->
							<mat-label class='pd-auth-new-font'>Пароль</mat-label>
							<input
								matInput
								formControlName='password'
								[type]="passwordHide ? 'password' : 'text'"
							>
							<button
								mat-icon-button
								matSuffix
								class='pd-icon-button light-gray new-color'
								type='button'
								[attr.aria-label]="authPipe.transform('passwordHide', 'propertyTitle')"
								[attr.aria-pressed]='passwordHide'
								(click)='passwordHide = !passwordHide'
							>
								<mat-icon [svgIcon]="passwordHide ? 'additional-view' : 'additional-view-hide'"></mat-icon>
							</button>
							<mat-error
								i18n='mat-error@@login-password-required'
								*ngIf='passwordLogin?.errors?.required'
							>Обязательное поле
							</mat-error>
							<mat-error
								*ngIf='passwordLogin?.errors?.minlength'
							>Введите не менее 8 символов
							</mat-error>
							<mat-error
								i18n='mat-error@@login-password-serverError'
								*ngIf='passwordLogin?.errors?.serverError'
							>
								{{ passwordLogin?.errors?.serverError }}
							</mat-error>
						</mat-form-field>
						<div class='pd-login-forgot-wrapper'>
							<a
								class='pd-link pd-login-link'
								[href]="'/forgotPassword'"
							>Забыли пароль?</a>
						</div>
					</div>
					<button
						*ngIf='!(loading$ | async); else isLoading'
						class='pd-button new-color'
						form='loginForm'
						mat-raised-button
						color='primary'
					>
						Войти
					</button>
					<p class='pd-login-text additional'>или</p>
					<!--Open Auth-->
					<div
						class='pd-oauth-container'
					>
						<button
							class='pd-button-oauth'
							type='button'
							mat-flat-button
							[disabled]='!isProduction'
							(click)='goOAuthLink(provider.name)'
							*ngFor='let provider of openAuthProviders'
						>
							<mat-icon
								class='pd-icon {{ provider.name | lowercase }}'
								svgIcon='{{ provider.icon }}'
							></mat-icon>
							<span>{{ provider.title }}</span>
						</button>
					</div>
				</div>
			</form>
		</div>

		<ng-template #isLoading>
			<mat-spinner
				class='loading-wrapper'
				strokeWidth='3'
				diameter='50'
			></mat-spinner>
		</ng-template>
	</div>
</div>
