<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="20px">
  <div class="pd-offer">
<!--    <h2>Вы заключаете безопасную сделку</h2>-->
    <p>Обратите внимание. Вы заключаете безопасную сделку, в которой средства заказчика будут заблокированы на номинальном счете до выполнения задачи.</p>
    <p>В случае успешного выполнения задачи, средства заказчика будут переведены исполнителю. В противном случае, они будут возвращены заказчику.</p>
    <p>Не нарушайте сроков выполнения задачи! Своевременно передавайте результат выполнения задачи заказчику!</p>
  </div>
</div>
<div mat-dialog-actions fxLayoutAlign="end" class="pd-dialog-action">
  <button type="button"
          mat-raised-button
          color="primary"
          (click)="onAccept()"
          matTooltip="Согласиться на безопасную сделку">
    Согласен
  </button>
  <button type="button"
          mat-raised-button
          color="accent"
          (click)="onClose()"
          matTooltip="Закрыть">
    Закрыть
  </button>
</div>
