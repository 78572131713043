<ng-container *ngIf='(readDataSource?.data | async).length; else noData'>
	<mat-table
		*ngIf='user$ | async as user'
		matSort
		matSortActive='createtime'
		[dataSource]='dataSource'
		class='pd-notifications-table'
	>
		<!--  Notification Card Column  -->
		<ng-container matColumnDef='card'>
			<mat-cell *matCellDef='let element'>
				<pdw-notification-card [card]='toCard(user, element)'>
				</pdw-notification-card>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef='groupHeader'>
			<mat-cell *matCellDef='let group'>
				<p class='pd-notifications-table-header-date'>
					{{group.value | date: 'LLLL YYYYг.' }}
				</p>
			</mat-cell>
		</ng-container>
		<mat-row
			class='pd-notifications-table-card-row'
			*matRowDef="let row; columns: ['card'];"
		></mat-row>
		<mat-row
			class='pd-notifications-table-header-row'
			*matRowDef="let row; columns: ['groupHeader']; when: groupBy.isGroup"
		></mat-row>
	</mat-table>
</ng-container>
<ng-template #noData>
	<div class='pd-notifications-dialog-no-data'>
		<ng-container *ngIf='!(loading$ | async); else isLoading'>
			<mat-icon
				class='pd-notifications-dialog-icon'
				svgIcon='additional-check-rectangle-big'
			></mat-icon>
			<p class='pd-notifications-dialog-title-empty'>Уведомлений пока нет</p>
			<p class='pd-notifications-dialog-subtitle-empty'>Скоро они появятся здесь</p>
		</ng-container>
		<ng-template #isLoading>
			<mat-spinner
				strokeWidth='3'
				diameter='50'
			></mat-spinner>
		</ng-template>
	</div>
</ng-template>
<ng-container *ngIf='remain | async as remain'>
	<button
		class='pd-button-additional'
		(click)='goNextPage()'
		*ngIf='remain > 0'
	>
		Показать еще
	</button>
</ng-container>
