import { Injectable } from '@angular/core';
import {Observable, tap} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthService} from "@core/services/auth.service";
import {Tokens} from "@requests/tokens";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class OAuthService {
  private oauthUrl = 'api/OAuth';
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
  }

  /**
   * Возвращает ссылку на авторизацию у провайдера OAuth2
   * @param provider наименование провайдера (с заглавной буквы)
   */
  oauthLink(provider: string): Observable<any> {
    const url = `${this.oauthUrl}/${provider}Link`;
    return this.http.get(url, {
      headers: new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8'),
      responseType: 'text'
    });
  }

  /**
   * Выполняет регистрацию и/или вход с учетными данными полученными от провайдера OAuth2
   * @param provider наименование провайдера (с заглавной буквы)
   * @param code код авторизации
   */
  oauthLogin(provider: string, code: string): Observable<any> {
    const url = `${this.oauthUrl}/${provider}Login`;
    return this.http.get<Tokens>(url, { params: { code: code }})
      .pipe(
        tap(x => {
          if (x.hasOwnProperty('error')){
            throw x;
          }
        }),
        tap(tokens => this.authService.doLoginUser(tokens)),
        map(() => true)
      );
  }
}
