<ng-container *ngIf='!(isLoading | async)'>
	<ng-container *ngIf='!(workExecutorMarkView | async) && canAssign'>
		<button
			class='pd-button-assign'
			(click)='goAssign()'
		>
			<mat-icon
				class='pd-icon'
				svgIcon='additional-star'
			></mat-icon>
			Оставьте отзыв
		</button>
	</ng-container>
	<ng-container *ngIf='workMarkView | async as markView'>
		<pdw-work-mark-card [workMarkView]='markView' [isToShowFullMode]="true">
		</pdw-work-mark-card>
	</ng-container>
</ng-container>

