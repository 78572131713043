import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'supportDialog'
})
export class SupportDialogPipe extends AbstractModelPipe implements PipeTransform {

  properties = [
    {name: 'name', title: 'Ваше имя', hint: 'Введите имя'},
    {name: 'email', title: 'Эл. почта для ответа', hint: 'Введите эл. почту'},
    {name: 'file', title: 'Прикрепите файл', hint: 'Прикрепите файл'},
    {name: 'category', title: 'Выебрите категорию', hint: 'Выебрите категорию'},
    {name: 'description', title: 'Текст обращения', hint: 'Введите текст'},
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
  allProperties() {
    return this.properties;
  }

}
