import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'appUserLastConnection'
})
export class AppUserLastConnectionPipe implements PipeTransform {

	transform(v: Date): string | null {
		if (v) {
			if (typeof v === 'string') {
				const newDate = new Date(v);
				return `Был ${this.getDateWithZero(newDate.getDate())}.${this.getDateWithZero(newDate.getMonth() + 1)}.${newDate.getFullYear()} в ${this.getDateWithZero(newDate.getHours())}:${this.getDateWithZero(newDate.getMinutes())} `;
			}
			return `Был ${this.getDateWithZero(v.getDate())}.${this.getDateWithZero(v.getMonth() + 1)}.${v.getFullYear()} в ${this.getDateWithZero(v.getHours())}:${this.getDateWithZero(v.getMinutes())} `;
		}
		return 'Был давно';
	}

	getDateWithZero(date: number): string | number {
		if (date < 10) {
			return '0' + date;
		}
		return date;
	}
}
