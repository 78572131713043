import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, switchMap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppUserType } from '@models/app-user-view';
import { errorTitle } from '@utils/helpers/error-helpers';
import { OAuthService } from '@core/services/oauth.service';
import { AppUserService } from '@core/services/app-user.service';
import { AppUserRoleHintHelper } from '@utils/helpers/app-user-role-hint-helper';
import { DestroyService, NotificationService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-oauth',
	templateUrl: './oauth.component.html',
	providers: [DestroyService]
})
export class OAuthComponent implements OnInit {
	provider: string;
	appUserRoleHintHelper: AppUserRoleHintHelper;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private oAuthService: OAuthService,
		private appUserService: AppUserService,
		private notificationService: NotificationService,
		private destroy$: DestroyService
	) {
	}

	ngOnInit(): void {
		this.provider = this.route.snapshot.data.provider;
		this.route.queryParams
			.pipe(
				switchMap(query => this.oAuthService.oauthLogin(this.provider, query.code)
					.pipe(
						takeUntil(this.destroy$)
					)
				),
				switchMap(() => this.appUserService.getActiveUserView()
					.pipe(
						takeUntil(this.destroy$)
					)
				),
			)
			.subscribe({
				next: (appUserView) => {
					this.appUserRoleHintHelper = new AppUserRoleHintHelper(this.appUserService);
					let commands = [];
					if (appUserView.type == AppUserType.administrator) {
						commands.push('administrator/dashboard');
					} else if (appUserView.type === AppUserType.client) {
						commands.push('/companies');
					} else if (appUserView.type === AppUserType.specialist) {
						commands.push('/companies');
					} else {
						commands.push('/landing');
					}
					this.router.navigate(commands).then(this.appUserRoleHintHelper.initAfterAuth);
				},
				error: (err) => {
					this.router
						.navigate(['/login'])
						.then(() => {
							this.notificationService.showDanger(errorTitle(err));
						});
				}
			});
	}
}
