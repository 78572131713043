import { ChangeDetectionStrategy, Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'pdw-top-switch',
	templateUrl: './top-switch.component.html',
	styleUrls: ['./top-switch.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => TopSwitchComponent),
		multi: true
	}],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopSwitchComponent implements ControlValueAccessor {
	onChange = (_: any) => {
	}
	private _value: boolean = false;
	
	@Input() names: { first: string, second: string }
	@Input() buttonClass: string
	
	@Input()
	set value(val) {
		this._value = val;
		this.onChange(this._value);
	}
	
	switch() {
		this.onChange(!this.value);
		this._value = !this.value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
	}

	writeValue(obj: boolean): void {
		this._value = obj;
	}

	get value() {
		return this._value;
	}
}
