import {Component, HostBinding, OnInit} from '@angular/core';
import {WorkStateValue, WorkView} from "@models/work-view";
import {Subject} from "rxjs";
import {filter} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {pushFakeHistoryState} from "@utils/functions";
import {WorkCardShortDialogComponent} from "@shared/work-card-short-dialog/work-card-short-dialog.component";
import {WorkAbstractComponent} from "@shared/work-abstract-component/work-abstract-component";

@Component({
  selector: 'app-work-card-short',
  templateUrl: './work-card-short.component.html',
  host: {'class': 'pd-work-card-short with-border'}
})
export class WorkCardShortComponent extends WorkAbstractComponent implements OnInit {
  @HostBinding('class') get class() {
    return this.workView.state === WorkStateValue.failedBySpecialist ? 'failed' : '';
  }

  unsubscribe: Subject<any> = new Subject<any>();
  constructor(
    private dialog: MatDialog,
  ) {
    super()
  }

  ngOnInit(): void {
    this.workViewSubject
      .pipe(
        filter(x => x !== null),
      )
      .subscribe((workView: WorkView) => {
        this.currentWorkView.next(workView);
      });
  }

  openDialog(): void{
    pushFakeHistoryState();

    this.dialog.open(WorkCardShortDialogComponent, {
      autoFocus: true,
      disableClose: false,
      width: '581px',
      panelClass: ['pd-dialog', 'pd-dialog-without-footer'],
      data: {
        title: 'Информация о работе',
        workView: this.currentWorkView.value
      }
    });
  }

  getTooltip():string{
    return this.workView.state === WorkStateValue.failedBySpecialist ? 'Не выполнена' : 'Выполнена успешно';
  }

  /**
   * Отписывает подписки при уничтожении компоненты
   */
  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }
}
