import {Injectable} from '@angular/core';
import {AbstractModelService} from "@core/services/abstract-model.service";
import {HttpClient} from "@angular/common/http";
import {NewsPostView} from "@models/news-post-view";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class NewsPostService {

  private newsPostsUrl = 'api/NewsPosts';

  constructor(private http: HttpClient, private modelService: AbstractModelService) {
  }

  /**
   * Возвращает пост
   * @param id идентификатор поста
   */
  getNewsPostView(id: number): Observable<NewsPostView> {
    const url = `${this.newsPostsUrl}/${id}`;
    return this.http.get<NewsPostView>(url, this.modelService.httpOptions)
      .pipe(map(x => NewsPostService.parseDates(x)));
  }

  /**
   * Возвращает список постов
   */
  all(): Observable<NewsPostView[]> {
    return this.http.get<NewsPostView[]>(this.newsPostsUrl)
      .pipe(map(x => x.map(p => NewsPostService.parseDates(p))));
  }

  /**
   * Добавляет новый пост
   * @param newsPostView пост
   */
  add(newsPostView: NewsPostView): Observable<NewsPostView> {
    return this.http.post<NewsPostView>(this.newsPostsUrl, newsPostView, this.modelService.httpOptions)
      .pipe(
        map(x => NewsPostService.parseDates(x))
      );
  }

  /**
   * Изменение существующего поста
   * @param newsPostView пост
   */
  update(newsPostView: NewsPostView): Observable<NewsPostView> {
    const url = `${this.newsPostsUrl}/${newsPostView.id}`;
    return this.http.put<NewsPostView>(url, newsPostView, this.modelService.httpOptions)
      .pipe(
        map(x => NewsPostService.parseDates(x))
      );
  }

  /**
   * Удаление поста
   * @param id идентификатор поста
   */
  delete(id: number): Observable<any> {
    const url = `${this.newsPostsUrl}/${id}`;
    return this.http.delete<any>(url, this.modelService.httpOptions);
  }

  callNewsPostView(method: string, newsPostView: NewsPostView): Observable<NewsPostView> {
    if (method === 'create') {
      return this.add(newsPostView);
    }
    if (method === 'update') {
      return this.update(newsPostView);
    } else {
      throw Error('Calling method is doesn\'t exist');
    }
  }

  /**
   * Преобразование string в Date после десериализации
   * @param newsPostView пост
   */
  public static parseDates(newsPostView: NewsPostView): NewsPostView {
    if (newsPostView) {
      newsPostView.createTime = new Date(newsPostView.createTime);
      newsPostView.modifyTime = new Date(newsPostView.modifyTime);
    }

    return newsPostView;
  }
}
