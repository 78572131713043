import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RusProfileObject, RusProfileResponse} from "../../responses/rus-profile-response";
import {MatTableDataSource} from "@angular/material/table";
import {BehaviorSubject} from "rxjs";
import {SelectionModel} from "@angular/cdk/collections";
export interface RusProfileResponseDialogData {
  title: string;
  message: string;
  rusProfileResponse: RusProfileResponse
}
@Component({
  selector: 'app-rus-profile-response-dialog',
  templateUrl: './rus-profile-response-dialog.component.html',
})
export class RusProfileResponseDialogComponent implements OnInit {
  dataSource: MatTableDataSource<RusProfileObject>;
  initDisplayedColumns = [
    'checked',
    'name',
    'inn',
    'ogrn',
  ];
  displayedColumns = new BehaviorSubject<string[]>(this.initDisplayedColumns);
  selection = new SelectionModel<RusProfileObject>(false, []);
  constructor(
    public dialogRef: MatDialogRef<RusProfileResponseDialogComponent, RusProfileObject | boolean>,
    @Inject(MAT_DIALOG_DATA) public data: RusProfileResponseDialogData
  ) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<RusProfileObject>(this.data.rusProfileResponse.ul);
  }
  onCancel(): void {
    this.dialogRef.close(false);
  }
  onApply(): void {
    this.dialogRef.close(this.selection.selected[0]);
  }
}
