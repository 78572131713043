<div fxLayout="column" fxLayoutGap="20px">
  <div>
    <table mat-table matSort matSortActive="title" matSortDirection="asc" [dataSource]="dataSource"  class="pd-width-full">

      <!-- Title Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'title' | appUser: 'propertyTitle' }}
        </th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="['/', appUserTypeValuePipe.transform(appUserType), 'person', element.id]">
            {{ element.title }}
          </a>
        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'email' | appUser: 'propertyTitle' }}
        </th>
        <td mat-cell *matCellDef="let element">
          <a href="mailto:{{ element.appUser.email }}">
            {{ element.appUser.email }}
          </a>
        </td>
      </ng-container>

      <!-- Phone Number Column -->
      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'phoneNumber' | appUser: 'propertyTitle' }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.appUser.phoneNumber }}
        </td>
      </ng-container>

      <!-- Is Staff Column -->
      <ng-container matColumnDef="isStaff">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'isStaff' | appUser: 'propertyTitle' }}
        </th>
        <td mat-cell *matCellDef="let element">
          <app-yes-no-value [value]="element.isStaff">
          </app-yes-no-value>
        </td>
      </ng-container>

      <!-- Skills Column -->
      <ng-container matColumnDef="skills">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'skills' | appUser: 'propertyTitle' }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let skill of element.skills">
            {{ skill.name }}
          </div>
        </td>
      </ng-container>

      <!-- Rating Column -->
      <ng-container matColumnDef="rating">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'rating' | appUser: 'propertyTitle' }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.rating }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns | async"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns | async;"></tr>
    </table>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
<!--    <div>-->
<!--      <button mat-raised-button-->
<!--              matTooltip="Добавить новое физ. лицо"-->
<!--              color="primary"-->
<!--              [routerLink]="['/', appUserTypeValuePipe.transform(appUserType), 'Person', 'create']">-->
<!--        Добавить...-->
<!--      </button>-->
<!--    </div>-->
    <div class="pd-fill-space"></div>
    <div>
      <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" pageSize="10" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
