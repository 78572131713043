<ng-container *hasAuth='true'>
	<ng-container *ngIf='workView'>
		<button
			class='pd-icon-button pd-icon-button-black'
			mat-icon-button
			[matMenuTriggerFor]='menu'
			(click)='onMoreClick($event, menuTrigger)'
			(onMenuClose)='onCloseMenu(menuTrigger)'
			*ngIf='isTriggerExist()'
			#menuTrigger
		>
			<mat-icon [svgIcon]="'interface-menu-kebab-vertical'"></mat-icon>
		</button>
		<mat-menu
			class='pd-menu'
			#menu='matMenu'
			[xPosition]="'before'"
		>
			<button
				class='pd-menu-item'
				mat-menu-item
				(click)='goExecutingAwait($event)'
				*ngIf='isPackaged'
			>
				<mat-icon svgIcon='additional2-flag-alt'></mat-icon>
				<span>Начать работу</span>
			</button>
			<button
				class='pd-menu-item'
				mat-menu-item
				(click)='goSeeking($event)'
				[disabled]='!canSeeking'
				*ngIf='isDraft'
			>
				<mat-icon svgIcon='interface-check'></mat-icon>
				<span>Опубликовать</span>
			</button>
			<button
				class='pd-menu-item'
				mat-menu-item
				(click)='goDelete($event)'
				*ngIf='canDelete'
			>
				<mat-icon svgIcon='additional-trash-2'></mat-icon>
				<span>Удалить</span>
			</button>
			<button
				class='pd-menu-item'
				mat-menu-item
				(click)='goDraft($event)'
				*ngIf='isSeeking'
			>
				<mat-icon svgIcon='additional2-cancel'></mat-icon>
				<span>Отозвать</span>
			</button>
		</mat-menu>
	</ng-container>
</ng-container>

