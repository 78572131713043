import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppUserView } from '@models/app-user-view';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatTableDataSource } from '@public/components/mat-table-data-source';
import { filter } from 'rxjs/operators';
import { EducationView } from '@models/education-view';
import { errorTitle } from '@utils/helpers/error-helpers';
import { MatDialog } from '@angular/material/dialog';
import { AppUserEducationsDialogComponent } from '@shared/app-user-educations-dialog/app-user-educations-dialog.component';
import { sortByTwoField } from '@utils/helpers/array-helpers';
import { pushFakeHistoryState } from '@utils/functions';
import { NotificationService } from '@profdepo-ui/core';
import { YesNo } from '@models/enums';

@Component({
	selector: 'app-app-user-educations',
	templateUrl: './app-user-educations.component.html',
})
export class AppUserEducationsComponent implements OnInit {
	@Input() canUpdate: boolean;

	@Input()
	set currentUserView(value: AppUserView) {
		this._currentUserView.next(value);
	};

	get currentUserView(): AppUserView {
		return this._currentUserView.getValue();
	}

	private _currentUserView = new BehaviorSubject<AppUserView>(null);

	currentAppUserView = new BehaviorSubject<AppUserView>(null);
	activeAppUserView = new BehaviorSubject<AppUserView>(null);
	both: Observable<AppUserView[]>;
	educationDataSource = new MatTableDataSource<EducationView>();
	@Output() changed = new EventEmitter<any>();

	constructor(
		private dialog: MatDialog,
		private notificationService: NotificationService,
	) {
	}

	ngOnInit(): void {
		this._currentUserView
			.pipe(
				filter(x => x !== null)
			)
			.subscribe((appUserView: AppUserView) => {
				this.currentAppUserView.next(appUserView);
				this.educationDataSource = new MatTableDataSource<EducationView>(appUserView.educations?.filter(item => item.isCourse === YesNo.no).sort(sortByTwoField('level', 'year', true)));
			});
	}

	hiddenRow(row: EducationView) {
		let firstIndex = this.educationDataSource.data.findIndex(item => item.level == row.level);
		let indexOfRow = this.educationDataSource.data.indexOf(row);

		if (firstIndex != indexOfRow) {
			return 'hidden';
		}
		return null;
	}

	isEducationExist(): boolean {
		return this.educationDataSource.data.some(item => item.isCourse === YesNo.no);
	}

	getSectorAndSpeciality(element: EducationView): string {
		let result = '';

		result += element.sector ? element.sector : '';

		if (element.speciality) {
			result += result ? `, ${element.speciality}` : element.speciality;
		}

		return result;
	}

	goUpdateEducations(): void {
		pushFakeHistoryState();

		this.dialog.open(AppUserEducationsDialogComponent, {
				autoFocus: true,
				width: '581px',
				minHeight: '300px',
				panelClass: ['pd-dialog'],
				data: {
					title: 'Образование',
					method: 'update',
					appUserView: this.currentAppUserView.value
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
			)
			.subscribe({
				next: () => {
					this.changed.emit('educations');
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}
}
