import { Pipe, PipeTransform } from '@angular/core';
import {AbstractEnumPipe} from "@public/pipes/abstract-enum.pipe";

@Pipe({
  name: 'skillStateValue'
})
export class SkillStateValuePipe extends AbstractEnumPipe implements PipeTransform {
  values = [
    'Добавлена',
    'Одобрена',
    'Отклонена'
  ];
  transform(value: any, ...args: any[]): any {
    return this.getTransform(value, args);
  }
}
