import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'workMark'
})
export class WorkMarkPipe extends AbstractModelPipe implements PipeTransform {
  properties = [
    {name: 'speedValue', title: 'Скорость ответа', hint: 'Скорость ответа'},
    {name: 'targetryValue', title: 'Постановка задач', hint: 'Постановка задач'},
    {name: 'adequacyValue', title: 'Адекватность', hint: 'Адекватность'},
    {name: 'comment', title: 'Комментарий', hint: 'Напишите отзыв'},
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
  allProperties() {
    return this.properties;
  }
}
