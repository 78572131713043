import { DataSource } from '@core/dataSources/data-source';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { WorkView } from '@models/work-view';
import { CollectionViewer } from '@angular/cdk/collections';
import { tap } from 'rxjs/operators';
import { WorkService, WorksFilter } from '@core/services/work.service';
import { ComplexFilterResult } from '@utils/helpers/complex-filter-helpers';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class AllWorkDataSource implements DataSource<WorkView> {
	private workViewsSubject = new BehaviorSubject<WorkView[]>([]);
	private countSubject = new BehaviorSubject<number>(0);

	constructor(
		private workService: WorkService
	) {
	}

	data = this.workViewsSubject.asObservable();
	count = this.countSubject.asObservable();

	connect(collectionViewer: CollectionViewer): Observable<WorkView[]> {
		return this.workViewsSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.workViewsSubject.complete();
		this.countSubject.complete();
	}

	load(worksFilter: WorksFilter, sortDirection: string, pageIndex: number, pageSize: number, dropOne?: boolean,  companies?: number
		): Observable<WorkView[]> {
		return this.workService.all(worksFilter, sortDirection, pageIndex, pageSize,  companies)
			.pipe(
				tap(workViews => this.workViewsSubject.next(workViews))
			);
	}

	loadMore(worksFilter: WorksFilter, sortDirection: string, pageIndex: number, pageSize: number, dropOne?: boolean, companies?: number): Observable<WorkView[]> {
		return this.workService.all(worksFilter, sortDirection, pageIndex, pageSize, companies)
			.pipe(
				tap(workViews => {
					const tmp = this.workViewsSubject.value;
					const newData = workViews.filter(x => !tmp.some(w => w.id === x.id));

					this.workViewsSubject.next(tmp.concat(newData));
				}),
			);
	}

	loadCount(worksFilter: WorksFilter | ComplexFilterResult): Observable<number> {
		let tmpWorksFilter: WorksFilter;

		if (worksFilter instanceof ComplexFilterResult) {
			tmpWorksFilter = worksFilter.toWorksFilter();
		} else {
			tmpWorksFilter = worksFilter;
		}

		return this.workService.allCount(tmpWorksFilter)
			.pipe(
				tap(count => this.countSubject.next(count))
			);
	}

	loadOne(id: number): Observable<WorkView> {
		const tmp = this.workViewsSubject.value;
		const index = tmp.findIndex(x => x.id === id);
		if (index >= 0) {
			return this.workService.getWorkView(id)
				.pipe(
					tap(x => {
						tmp.splice(index, 1, x);
						this.workViewsSubject.next(tmp);
					})
				);
		}
		return EMPTY;
	}
}
