import { ServerErrorsVsFormControlsMap } from "@utils/constants";

export class ErrorHelpers {
}

export function is400(error: any): boolean {
  return error && error.status && +error.status === 400;
}

export function is500(error: any): boolean {
  return error && error.status && +error.status === 500;
}

export function errorTitle(error: any): string {
  if (is400(error)) {
    return error.error;
  } else if (is500(error)) {
    const lines = error.error.split('\n');
    if (lines.length === 0)
      return error.error;
    let line = lines[0];
    line = line.replace('System.Exception: ','');
    return line;
  }
  return Array.isArray(error.error) ? error.error.join('\n') : error.error;
}

export function errorMap(err: string): string {
	if (Object.keys(ServerErrorsVsFormControlsMap).includes(err)) {
		return ServerErrorsVsFormControlsMap[err];
	}
	return null;
}
