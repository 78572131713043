<div
	class='pd-user-card card-container'
	*ngIf='user'
	[routerLink]='canRedirect ? "/users/" + user.id : ""'
>
	<h1>
		{{user.profession ?? 'Специалист'}}
	</h1>

	<div class='card-content'>
		<div class='card-info-container'>
			<div class='card-row card-username-container closer'>
				<p *ngIf='user.firstname'>
					{{user.firstname}}
				</p>
				<app-rating-one-small [percent]='user.ratingPercent'>
				</app-rating-one-small>
			</div>
			<div class='card-row card-age-container closer'>
				<span>
					{{getAge(user.birthday)}}
				</span>
				<span>
					{{user.city?.name ?? 'Город не указан'}}
				</span>
			</div>

			<span
				class='card-salary'
				[class.card-salary-empty]='!user.preferredReward'
			>
				{{user.preferredReward ? user.preferredReward + " ₽" : 'Не указано'}}
			</span>
		</div>
		<pdw-image-avatar
			class='card-avatar'
			[avatarSrc]='user.id'
			[size]='IMAGE_SIZE.MEDIUM'
			[isDisplayed]='user.hasAvatar === YESNO.yes'
			[isNotRequest]='user.id.includes("/")'
		>
			<div
				class='pd-user-card-avatar'
				[style.background-color]='avatarColor(user)'
			>
				<pdw-user-initial-letters
					[user]='user'
					[canUpdate]='false'
					[size]='IMAGE_SIZE.MEDIUM'
				>
				</pdw-user-initial-letters>
			</div>
		</pdw-image-avatar>
	</div>

	<pdw-profdepo-ai-block [canOpenModal]='canOpenModal' [user]='user'>
	</pdw-profdepo-ai-block>
	<ng-content></ng-content>
</div>
