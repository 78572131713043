import { AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { CompanyView } from '@models/company-view';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { CompanyMarksDataSource } from '@core/dataSources/company-marks-data-source';
import { WorkMarkService } from '@core/services/work-mark.service';
import { calcColumnsCount, onResizeCardsContainer } from '@utils/functions';
import { DestroyService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-company-marks',
	templateUrl: './company-marks.component.html',
	providers: [DestroyService]
})
export class CompanyMarksComponent implements OnInit, AfterViewInit {
	columnsCount: number = 0;
	minCardWidth: number = 392;
	@ViewChild('cardsContainer', { read: ElementRef }) cardsContainer: ElementRef;
	dataSource: CompanyMarksDataSource;

	@Input()
	set companyView(value: CompanyView) {
		this._companyView.next(value);
	};

	get companyView(): CompanyView {
		return this._companyView.getValue();
	}

	private _companyView = new BehaviorSubject<CompanyView>(null);
	currentCompanyView = new BehaviorSubject<CompanyView>(null);

	constructor(
		private workMarkService: WorkMarkService,
		private zone: NgZone,
		private destroy$: DestroyService
	) {
	}

	ngOnInit(): void {
		this._companyView
			.pipe(
				filter(x => x !== null),
			)
			.subscribe((companyView: CompanyView) => {
				this.dataSource = new CompanyMarksDataSource(companyView, this.workMarkService);
			});

		fromEvent(window, 'resize')
			.pipe(
				takeUntil(this.destroy$),
				map(() => this.cardsContainer.nativeElement.offsetWidth),
				distinctUntilChanged()
			).subscribe({
			next: () => {

				this.minCardWidth = onResizeCardsContainer(this.cardsContainer);
				this.columnsCount = calcColumnsCount(this.cardsContainer, this.minCardWidth);
			}
		});
	}

	ngAfterViewInit() {
		this.zone.onStable
			.pipe(
				filter(() => this.cardsContainer !== undefined),
				distinctUntilChanged(),
			)
			.subscribe(() => {
				window.dispatchEvent(new Event('resize'));
			});
	}

}
