<ng-container *ngIf='currentAppUserView | async as currentUser'>
	<!-- CardTemplate -->
	<ng-container *ngIf='inCard; else detailsTemplate'>
		<div
			class='pd-user-card-successful-works-title'
			*ngIf='rating > 0; else noSuccessfulWorksInCard'
		>
			Успешно завершено
			<span class='pd-profile-successful-works-value {{colorClass(rating)}}'>
				{{rating}}%
			</span>
			работ
		</div>
		<ng-template #noSuccessfulWorksInCard>
			<div class='pd-user-card-successful-works-title empty'>
				Нет выполненных работ
			</div>
		</ng-template>
		<pdw-progress-bar
			[matTooltip]='getTooltip(currentUser)'
			[value]='rating'
			[depsColorOnValue]='true'
		></pdw-progress-bar>
	</ng-container>
	<ng-template #detailsTemplate>
		<div class='pd-profile-title-wrapper'>
			<h2 class='pd-profile-title'>
				Успешно завершено
				<span *ngIf='rating > 0 && !(utilsService.mobileQuery980px | async)'>
					:
					<span class='pd-profile-successful-works-value {{colorClass(rating)}}'>
						{{rating}}%
					</span>
					работ
				</span>
			</h2>
			<mat-icon
				class='pd-icon light-gray'
				svgIcon='additional-alert-question-1'
				[matTooltipPosition]="'right'"
				[matTooltip]="'Скоро здесь будет подсказка'"
			>
			</mat-icon>
		</div>
		<div
			class='pd-profile-successful-works-value-wrapper'
			*ngIf='rating > 0 && (utilsService.mobileQuery980px | async)'
		>
			<span class='pd-profile-successful-works-value {{colorClass(rating)}}'>
				{{rating}}%
			</span>
			работ
		</div>
		<ng-container *ngIf='rating > 0; else noSuccessfulWorks'>
			<pdw-progress-bar
				[matTooltip]='getTooltip(currentUser)'
				[value]='rating'
				[depsColorOnValue]='true'
			></pdw-progress-bar>
		</ng-container>
		<ng-template #noSuccessfulWorks>
			<div class='pd-profile-block-no-content-wrapper'>
				<p class='pd-profile-subtitle'>Завершенных работ пока нет</p>
				<p class='pd-profile-text'>Скоро они появятся здесь</p>
			</div>
		</ng-template>
	</ng-template>
</ng-container>
