<div class="pd-chat-context-menu" *ngIf="contextMenuData"
   [ngStyle]="{
   'left.px': contextMenuData?.positionX,
   'top.px': contextMenuData?.positionY
   }">
      <button (click)="startEdit()"
              [disabled]="isMoreThenFiveMinute(contextMenuData.item)">
        <mat-icon svgIcon="additional2-edit">
        </mat-icon>
        Редактировать
      </button>
      <button (click)="startDelete()"
              [disabled]="isMoreThenFiveMinute(contextMenuData.item)">
        <mat-icon svgIcon="additional-trash-1">
        </mat-icon>
        Удалить
      </button>
</div>
