<a
	*ngIf='workRequestView'
	class='pdw-request-card'
	[routerLink]='"/works/" + workRequestView.work.id'
>
	<div class='card-info'>
		<div class='pd-state-snack-card {{stateColorClass(workRequestView)}} pdw-request-card-status'>
			{{ workRequestView.state === 0 && workRequestView.direction === 1 ? 'В ожидании' : workRequestView.state | workRequestState }}
		</div>

		<h2 class='card-title'>
			{{workRequestView.work.name}}
		</h2>

		<span class='card-city'>
			{{workRequestView.work.city?.name ?? 'Не указано'}}
		</span>

		<span
			[class.card-salary-empty]='!workRequestView.work?.amount'
			class='card-salary'
		>
			{{workRequestView.work?.amount ? workRequestView.work?.amount + " &#8381;" : 'Не указано'}}
		</span>

		<div class='card-skill-container'>
			<ng-container *ngFor='let hard of workRequestView.work.hardSkills; index as i'>
				<div
					class='pd-skill-inner card-skill'
					[matTooltip]="'Тип работы'"
					*ngIf='i + 1 <= 3'
				>
					<p class='pd-skill-text'>
						{{ hard.name }}
					</p>
				</div>
			</ng-container>
			<ng-container *ngFor='let sub of workRequestView.work.subCategories; index as i'>
				<div
					class='pd-skill-inner card-skill'
					[matTooltip]="'Тип работы'"
					*ngIf='countOfSkills <= 3 && i + 1 <= 3 - countOfSkills'
				>
					<p class='pd-skill-text'>
						{{ sub.name }}
					</p>
				</div>
			</ng-container>
		</div>
		<span class='card-sub-info'>
			<span class='card-company'>
				{{workRequestView.work.company.name}}
			</span>
			<span
				*ngIf='workRequestView.work.isFreelance === YesNo.yes'
				class='card-sub-info-date'
			>
				/ {{ workRequestView.work.startDate | date:'dd.MM.YYYY'}}
				- {{ workRequestView.work.endDate | date:'dd.MM.YYYY'}}
			</span>
		</span>

		<span
			*ngIf='workRequestView.work.isFreelance === YesNo.yes'
			class='card-sub-info-date-mobile'
		>
			{{ workRequestView.work.startDate | date:'dd.MM.YYYY'}}
			- {{ workRequestView.work.endDate | date:'dd.MM.YYYY'}}
		</span>
	</div>

	<ng-container *ngIf='workRequestView.state === 0 || workRequestView.state === WorkRequestState.CandidatesList'>
		<div class='card-actions'>
			<ng-container *ngIf='workRequestView?.direction === 0; else inviteButtons'>
				<button
					mat-raised-button
					class='pd-button-secondary half-width'
					[disabled]='workRequestView?.work.specialistAssigned === 1'
					(click)='goAccept(workRequestView, $event)'
				>
					Перейти в диалог
				</button>

				<button
					mat-raised-button
					class='pd-button-secondary warn half-width'
					(click)='goDelete(workRequestView, $event)'
				>
					Отменить отклик
				</button>
			</ng-container>
			<ng-template #inviteButtons>
				<button
					mat-raised-button
					class='pd-button-secondary pd-button-sm-font half-width'
					[disabled]='workRequestView?.work.specialistAssigned === 1'
					(click)='goAccept(workRequestView, $event)'
				>
					Перейти в диалог
				</button>
				<button
					mat-raised-button
					class='pd-button-secondary pd-button-sm-font warn half-width'
					(click)='goDecline(workRequestView, $event)'
				>
					Отклонить
				</button>
			</ng-template>
		</div>
	</ng-container>

</a>

