import { Pipe, PipeTransform } from '@angular/core';
import {AbstractExtensionEnumPipe} from "@public/pipes/abstract-extension-enum.pipe";

@Pipe({
  name: 'workExecutorRequestDirection'
})
export class WorkExecutorRequestDirectionPipe extends AbstractExtensionEnumPipe implements PipeTransform {
  values = {
    0: "Отклик",
    1: "Приглашение",
  };
  transform(value: any, ...args: any[]): any {
    return this.getTransform(value, args);
  }
}
