<ng-container *ngLet='both | async as users'>
	<ng-container *ngIf='users[0] as currentAppUser'>
		<div
			class='pd-personal-column'
			*ngIf='isAuth(); else noAuth'
		>
			<div [ngClass]='isSamePerson() && isSpecialist ? "user-info-profile" : "user-info-wrapper"'>
				<div class='pd-personal-top-row'>
					<mat-icon
						class='pd-icon light-gray'
						svgIcon='additional-user-rectangle-1'
					></mat-icon>
					<h1 class='pd-profile-title'>
						{{isAuth() ? (this.currentUserView | userTitleChecker) : 'Специалист'}}
					</h1>
					<ng-container *ngIf='canUpdate'>
						<button
							class='pd-button-secondary small'
							mat-raised-button
							(click)='goUpdatePersonal()'
						>
							<mat-icon
								class='pd-icon'
								svgIcon='additional2-edit'
							></mat-icon>
							<p class='pd-profile-button-text'>Редактировать</p>
						</button>
					</ng-container>
				</div>
				<ng-container *ngIf='isCurrentUserSpecialist(currentAppUser)'>
					<div class='pd-personal-top-row user-wrap'>
						<div class='user-status-container'>
							<mat-icon
								class='pd-icon light-gray'
								svgIcon='interface-attention-circle'
							></mat-icon>

							<ng-container *ngIf='(canUpdateState$ | async); else stateSnack'>
								<button
									class='pd-button-user-state {{stateColorClass(currentAppUser)}}'
									(click)='goChangeState()'
								>
									{{stateText(currentAppUser)}}
								</button>
							</ng-container>

							<ng-template #stateSnack>
								<div class='pd-personal-state {{stateColorClass(currentAppUser)}}'>
									{{stateText(currentAppUser)}}
								</div>
							</ng-template>
							<button
								*ngIf='(currentAppUser?.id === activeUserView?.id)'
								class='pd-button-secondary small'
								mat-raised-button
								(click)='goChangeState()'
							>
								<mat-icon
									class='pd-icon'
									svgIcon='interface-refresh'
								></mat-icon>
								<p class='pd-profile-button-text'>Изменить статус</p>
							</button>
						</div>

						<div
							class='pd-personal-state pd-button-user-state user-inn-status'
							[matTooltip]="'Данный пользователь верифицирован через ФНС'"
							matTooltipClass='user-inn-status-tooltip'
							*ngIf='!isSamePerson() && currentAppUser.inn'
						>
							<mat-icon
								class='pd-icon'
								svgIcon='interface-check'
							></mat-icon>
							<p>
								Специалист верифицирован
							</p>
						</div>
					</div>
				</ng-container>
				<div
					*ngIf='isAuth()'
					class='pd-personal-top-row mobile-top-row'
				>
					<div class='pd-contacts-item'>
						<mat-icon
							class='pd-icon light-gray'
							svgIcon='emojis-smiling-face'
						></mat-icon>
						<p class='pd-profile-text-medium'>
							{{appUserGenderAndAge(currentAppUser)}}
						</p>
					</div>
					<div *ngIf='currentAppUser?.city' class='pd-contacts-item'>
						<mat-icon
							class='pd-icon light-gray'
							svgIcon='additional-location'
						></mat-icon>
						<p
							class='pd-profile-text-medium'
						>
							<ng-container *ngIf='currentAppUser?.isReadyToRelocate !== null; else noCity'>
								{{isReadyToRelocate(currentAppUser, true)}}
							</ng-container>
						</p>

						<ng-template #noCity>
							{{isReadyToRelocate(currentAppUser)}}
						</ng-template>

					</div>
				</div>
				<!-- Contacts -->
				<ng-container *ngIf='canSeeContacts | async'>
					<pdw-app-user-contacts
						*ngIf='isAuth() && canUpdate'
						class='pd-profile-contacts'
						[canUpdate]='canUpdate'
						[currentUserView]='currentAppUser'
					>
					</pdw-app-user-contacts>
				</ng-container>
			</div>

			<ng-container *hasPermission='AppUserType.client'>
				<div
					class='pd-personal-block-btn-wrapper'
					*ngIf='authService.isLoggedIn()'
				>
					<div class='pd-personal-block-only-btn'>
						<button
							*ngIf='!isSamePerson()'
							mat-raised-button
							class='pd-button pd-button-full-width button-container'
							(click)='checkAuthBeforeInviteSpecialist()'
						>
							<mat-icon
								class='pd-icon'
								svgIcon='interface-check'
							></mat-icon>
							Пригласить на работу
						</button>
					</div>
					<div
						class='pd-personal-block-btn-and-info'
						*ngIf='!canUpdate'
					>
						<button
							*ngIf='!isSamePerson() && !isToShowContacts'
							mat-raised-button
							class='pd-button-secondary pd-button-full-width button-container'
							(click)='showContacts()'
						>
							Показать контакты
						</button>
						<div
							class='pd-profile-contacts-info'
							*ngIf='isToShowContacts'
						>
							<pdw-app-user-contacts
								class='pd-profile-contacts'
								[canUpdate]='canUpdate'
								[currentUserView]='currentAppUser'
							>
							</pdw-app-user-contacts>
							<button
								mat-icon-button
								class='pd-icon-button pd-card-bookmark'
								(click)='copy(currentAppUser)'
							>
								<mat-icon
									class='pd-icon'
									svgIcon='additional-copy'
								></mat-icon>
							</button>
						</div>
					</div>
				</div>
			</ng-container>

			<div
				*ngIf='!authService.isLoggedIn()'
				class='pd-personal-block-no-auth-wrapper'
			>
				<div class='pd-personal-block-btn-wrapper'>
					<div class='pd-personal-block-only-btn'>
						<button
							mat-raised-button
							class='pd-button pd-button-full-width button-container'
							(click)='checkAuthBeforeInviteSpecialist()'
						>
							<mat-icon
								class='pd-icon'
								svgIcon='interface-check'
							></mat-icon>
							Пригласить на работу
						</button>
					</div>
					<div
						class='pd-personal-block-btn-and-info'
						*ngIf='!canUpdate'
					>
						<button
							*ngIf='!isToShowContacts'
							mat-raised-button
							class='pd-button-secondary pd-button-full-width button-container'
							(click)='showContacts()'
						>
							Показать контакты
						</button>
						<div
							class='pd-profile-contacts-info'
							*ngIf='isToShowContacts'
						>
							<pdw-app-user-contacts
								class='pd-profile-contacts'
								[canUpdate]='canUpdate'
								[currentUserView]='currentAppUser'
							>
							</pdw-app-user-contacts>
							<button
								mat-icon-button
								class='pd-icon-button pd-card-bookmark'
								(click)='copy(currentAppUser)'
							>
								<mat-icon
									class='pd-icon'
									svgIcon='additional-copy'
								></mat-icon>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ng-template #noAuth>
			<div class='pd-personal-column'>
				<div [ngClass]='isSamePerson() && isSpecialist ? "user-info-profile" : "user-info-wrapper"'>
					<div class='pd-personal-top-row'>
						<mat-icon
							class='pd-icon light-gray'
							svgIcon='additional-user-rectangle-1'
						></mat-icon>
						<h1 class='pd-profile-title'>
							{{ this.currentUserView | userTitleChecker }}
						</h1>
						<ng-container *ngIf='canUpdate'>
							<button
								class='pd-button-secondary small'
								mat-raised-button
								(click)='goUpdatePersonal()'
							>
								<mat-icon
									class='pd-icon'
									svgIcon='additional2-edit'
								></mat-icon>
								<p class='pd-profile-button-text'>Редактировать</p>
							</button>
						</ng-container>
					</div>
					<ng-container *ngIf='isCurrentUserSpecialist(currentAppUser)'>
						<div class='pd-personal-top-row'>
							<mat-icon
								class='pd-icon light-gray'
								svgIcon='interface-attention-circle'
							></mat-icon>

							<ng-container *ngIf='(canUpdateState$ | async); else stateSnack'>
								<button
									class='pd-button-user-state {{stateColorClass(currentAppUser)}}'
									(click)='goChangeState()'
								>
									{{stateText(currentAppUser)}}
								</button>
							</ng-container>
							<ng-template #stateSnack>
								<div class='pd-personal-state {{stateColorClass(currentAppUser)}}'>
									{{stateText(currentAppUser)}}
								</div>
							</ng-template>
							<button
								*ngIf='(currentAppUser?.id === activeUserView?.id)'
								class='pd-button-secondary small'
								mat-raised-button
								(click)='goChangeState()'
							>
								<mat-icon
									class='pd-icon'
									svgIcon='interface-refresh'
								></mat-icon>
								<p class='pd-profile-button-text'>Изменить статус</p>
							</button>
						</div>
					</ng-container>
					<div
						class='pd-personal-top-row'
					>
						<div class='pd-contacts-item'>
							<mat-icon
								class='pd-icon light-gray'
								svgIcon='emojis-smiling-face'
							></mat-icon>
							<p class='pd-profile-text-medium'>
								{{appUserGenderAndAge(currentAppUser)}}
							</p>
						</div>
						<div class='pd-contacts-item'>
							<mat-icon
								class='pd-icon light-gray'
								svgIcon='additional-location'
							></mat-icon>
							<p
								*ngIf='currentAppUser?.city'
								class='pd-profile-text-medium'
							>
								<ng-container *ngIf='currentAppUser?.isReadyToRelocate !== null; else noCity'>
									{{isReadyToRelocate(currentAppUser, true)}}
								</ng-container>
							</p>

							<ng-template #noCity>
								{{isReadyToRelocate(currentAppUser)}}
							</ng-template>

						</div>
					</div>
					<!-- Contacts -->
					<ng-container *ngIf='canSeeContacts | async'>
						<pdw-app-user-contacts
							*ngIf='isAuth() && canUpdate'
							class='pd-profile-contacts'
							[canUpdate]='canUpdate'
							[currentUserView]='currentAppUser'
						>
						</pdw-app-user-contacts>
					</ng-container>
				</div>

				<ng-container *hasPermission='AppUserType.client'>
					<div
						class='pd-personal-block-btn-wrapper'
						*ngIf='authService.isLoggedIn()'
					>
						<div class='pd-personal-block-only-btn'>
							<button
								*ngIf='!isSamePerson()'
								mat-raised-button
								class='pd-button pd-button-full-width button-container'
								(click)='checkAuthBeforeInviteSpecialist()'
							>
								<mat-icon
									class='pd-icon'
									svgIcon='interface-check'
								></mat-icon>
								Пригласить на работу
							</button>
						</div>
						<div
							class='pd-personal-block-btn-and-info'
							*ngIf='!canUpdate'
						>
							<button
								*ngIf='!isSamePerson() && !isToShowContacts'
								mat-raised-button
								class='pd-button-secondary pd-button-full-width button-container'
								(click)='showContacts()'
							>
								Показать контакты
							</button>
							<div
								class='pd-profile-contacts-info'
								*ngIf='isToShowContacts'
							>
								<pdw-app-user-contacts
									class='pd-profile-contacts'
									[canUpdate]='canUpdate'
									[currentUserView]='currentAppUser'
								>
								</pdw-app-user-contacts>
								<button
									mat-icon-button
									class='pd-icon-button pd-card-bookmark'
									(click)='copy(currentAppUser)'
								>
									<mat-icon
										class='pd-icon'
										svgIcon='additional-copy'
									></mat-icon>
								</button>
							</div>
						</div>
					</div>
				</ng-container>

				<div
					*ngIf='!authService.isLoggedIn()'
					class='pd-personal-block-no-auth-wrapper'
				>
					<div class='pd-personal-block-btn-wrapper'>
						<div class='pd-personal-block-only-btn'>
							<button
								mat-raised-button
								class='pd-button pd-button-full-width button-container'
								(click)='checkAuthBeforeInviteSpecialist()'
							>
								<mat-icon
									class='pd-icon'
									svgIcon='interface-check'
								></mat-icon>
								Пригласить на работу
							</button>
						</div>
						<div
							class='pd-personal-block-btn-and-info'
							*ngIf='!canUpdate'
						>
							<button
								*ngIf='!isToShowContacts'
								mat-raised-button
								class='pd-button-secondary pd-button-full-width button-container'
								(click)='showContacts()'
							>
								Показать контакты
							</button>
							<div
								class='pd-profile-contacts-info'
								*ngIf='isToShowContacts'
							>
								<pdw-app-user-contacts
									class='pd-profile-contacts'
									[canUpdate]='canUpdate'
									[currentUserView]='currentAppUser'
								>
								</pdw-app-user-contacts>
								<button
									mat-icon-button
									class='pd-icon-button pd-card-bookmark'
									(click)='copy(currentAppUser)'
								>
									<mat-icon
										class='pd-icon'
										svgIcon='additional-copy'
									></mat-icon>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</ng-container>
</ng-container>
