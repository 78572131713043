import { DataSource } from '@core/dataSources/data-source';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { tap } from 'rxjs/operators';
import { WorkService, WorksFilter } from '@core/services/work.service';
import { ComplexFilterResult } from '@utils/helpers/complex-filter-helpers';
import { Injectable } from '@angular/core';
import { WorkViewShort } from '@models/work-view-short';

@Injectable({
	providedIn: 'root'
})
export class AllWorkDataSourceShort implements DataSource<WorkViewShort> {
	private workViewsSubject = new BehaviorSubject<WorkViewShort[]>([]);
	private countSubject = new BehaviorSubject<number>(0);

	constructor(
		private workService: WorkService
	) {
	}

	data = this.workViewsSubject.asObservable();
	count = this.countSubject.asObservable();

	connect(collectionViewer: CollectionViewer): Observable<WorkViewShort[]> {
		return this.workViewsSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.workViewsSubject.complete();
		this.countSubject.complete();
	}

	load(worksFilter: WorksFilter, sortDirection: string, pageIndex: number, pageSize: number): Observable<WorkViewShort[]> {
		return this.workService.allShort(worksFilter, sortDirection, pageIndex, pageSize)
			.pipe(
				tap(workViews => this.workViewsSubject.next(workViews))
			);
	}

	loadMore(worksFilter: WorksFilter, sortDirection: string, pageIndex: number, pageSize: number): Observable<WorkViewShort[]> {
		return this.workService.allShort(worksFilter, sortDirection, pageIndex, pageSize)
			.pipe(
				tap(workViews => {
					const tmp = this.workViewsSubject.value;
					const newData = workViews.filter(x => !tmp.some(w => w.id === x.id));

					this.workViewsSubject.next(tmp.concat(newData));
				}),
			);
	}

	loadCount(worksFilter: WorksFilter | ComplexFilterResult): Observable<number> {
		let tmpWorksFilter: WorksFilter;

		if (worksFilter instanceof ComplexFilterResult) {
			tmpWorksFilter = worksFilter.toWorksFilter();
		} else {
			tmpWorksFilter = worksFilter;
		}

		return this.workService.allCount(tmpWorksFilter)
			.pipe(
				tap(count => this.countSubject.next(count))
			);
	}
}
