import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { AppUserView } from '@models/app-user-view';
import { BehaviorSubject, switchMap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { filter, takeUntil } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { AppUserAvatarDialogComponent } from '@shared/app-user-avatar-dialog/app-user-avatar-dialog.component';
import { AppUserService } from '@core/services/app-user.service';
import { pushFakeHistoryState } from '@utils/functions';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { TrueLoadingService } from '@core/services/true-loading.service';
import { ImageAvatarSize } from '@shared/components/ui/image-avatar/image-avatar.types';

@Component({
	selector: 'pdw-app-user-avatar',
	templateUrl: './app-user-avatar.component.html',
	styleUrls: ['./app-user-avatar.component.scss'],
	providers: [DestroyService, TrueLoadingService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppUserAvatarComponent implements OnInit {
	@Input() canUpdate: boolean;
	showEditBtn = false

	@Input()
	set currentUserView(value: AppUserView) {
		this._currentUserView.next(value);
	};

	get currentUserView(): AppUserView {
		return this._currentUserView.getValue();
	}

	private _currentUserView = new BehaviorSubject<AppUserView>(null);
	currentAppUserView = new BehaviorSubject<AppUserView>(null);
	isCurrentUserOnline: boolean;
	@Output() changed = new EventEmitter<any>();
	timeForUrl: number = 0;

	constructor(
		private appUserService: AppUserService,
		private dialog: MatDialog,
		private notificationService: NotificationService,
		private destroy$: DestroyService,
		public loading$: TrueLoadingService,
		private cdr: ChangeDetectorRef
	) {
	}

	ngOnInit(): void {
		this._currentUserView
			.pipe(
				filter(x => x !== null)
			)
			.subscribe((appUserView: AppUserView) => {
				this.currentAppUserView.next(appUserView);
				this.isCurrentUserOnline = this.isOnlineUser;
			});
		this.appUserService.timeStampForUrl
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe(x => {
				this.timeForUrl = x;
			});
	}

	avatar(): string {
		return this.currentAppUserView.value.avatar.split('.')[0];
	}

	onLoad() {
		this.loading$.next(false);
	}

	goUpdateAvatar(): void {
		pushFakeHistoryState();

		this.dialog.open(AppUserAvatarDialogComponent, {
				autoFocus: false,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Фотография профиля',
					method: 'update',
					appUserView: this.currentAppUserView.value
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
				takeUntil(this.destroy$),
				switchMap(() => this.appUserService.getActiveUserView())
			)
			.subscribe({
				next: (data) => {
					let now = new Date();
					this.appUserService.timeStampForUrl.next(now.getTime());
					this.loading$.next(false);
					if (data.avatar) {
						const user = {
							avatar: data.avatar,
							cropperPosition: data.cropperPosition, ...this.appUserService.getCurrentActiveUserView()
						}
						this.appUserService.setActiveUser(user);
						this.currentAppUserView.next(user);
					}

					this.cdr.markForCheck();
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	/**
	 * Возвращает true если пользватель был в сети менее 5 минут назад
	 */
	get isOnlineUser(): boolean | null {
		if (this._currentUserView?.value?.id === this.appUserService?.getCurrentActiveUserView()?.id) {
			return true;
		}
		if (this._currentUserView?.value?.lastConnection) {
			const delta = (new Date().getTime() - new Date(this._currentUserView.value.lastConnection).getTime()) / 1000 / 60;
			return delta < 5;
		}
		return null;
	}

	avatarColor(appUserView: AppUserView): string {
		return AppUserService.avatarColor(appUserView);
	}


	setShowEditBtn() {
		this.showEditBtn = true;
	}

	setHideEditBtn() {
		this.showEditBtn = false;
	}

	protected readonly IMAGE_SIZE = ImageAvatarSize;
}
