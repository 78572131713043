<div class='pd-dialog-header'>
	<p class='pd-dialog-header-title'>{{ data.title }}</p>
	<button
		class='pd-icon-button'
		mat-icon-button
		[mat-dialog-close]='false'
	>
		<mat-icon svgIcon='interface-close'></mat-icon>
	</button>
</div>
<tui-scrollbar class='pd-scrollbar pd-dialog-scroll'>
	<div class='pd-dialog-content'>
		<div *ngIf='experiencesForm'>
			<form
				id='experiencesForm'
				[formGroup]='experiencesForm'
				(ngSubmit)='onSubmit(experiencesForm)'
			>
				<div class='pd-dialog-column'>

					<ng-container *ngIf='experiences.length > 0; else noExperiences'>
						<div
							formArrayName='experiences'
							*ngFor='let experienceFormGroup of experiences.controls; let i = index'
						>
							<div [formGroupName]='i'>

								<mat-form-field class='pd-input-invisible'>
									<input
										matInput
										formControlName='id'
										required
									>
								</mat-form-field>

								<div class='pd-dialog-row pd-dialog-subtitle-row'>
									<!--Place-->
									<div class='pd-dialog-subtitle'>
										Место работы {{ i + 1 }}
									</div>
										<pd-checkbox  class="desktop-checkbox"  formControlName='tillNow'>
											{{ 'tillNow' | experience: 'propertyTitle' }}
										</pd-checkbox>
									<div class='pd-dialog-row pd-dialog-checkbox-w-buttons pd-dialog-half-row'>
										<!--Till Now-->

										<div class='pd-dialog-row pd-dialog-icon-buttons'>
											<!--Remove Item-->
											<button
												type='button'
												class='pd-icon-button'
												mat-icon-button
												(click)='goRemoveExperience(i)'
											>
												<mat-icon svgIcon='additional-trash-2'></mat-icon>
											</button>
											<!--Add Item-->
											<button
												type='button'
												(click)='goAddExperience(i + 1)'
												class='pd-icon-button'
												mat-icon-button
											>
												<mat-icon svgIcon='additional-plus'></mat-icon>
											</button>
										</div>
									</div>
								</div>


								<div class='pd-dialog-column'>

									<div class='pd-dialog-row'>
										<!--Start Date -->
										<mat-form-field
											class='pd-input-form-field pd-dialog-half-row'
											appearance='fill'
											hideRequiredMarker
										>
											<mat-label>{{ 'startDate' | experience: 'propertyTitle' }}</mat-label>
											<input
												matInput
												formControlName='startDate'
												[matDatepicker]='startDatePicker'
												placeholder='00.0000'
											>
											<mat-datepicker-toggle
												class='pd-datepicker-toggle'
												matSuffix
												[for]='startDatePicker'
											>
												<mat-icon
													svgIcon='arrow-down-2'
													matDatepickerToggleIcon
												></mat-icon>
											</mat-datepicker-toggle>
											<mat-datepicker
												[panelClass]="'pd-calendar pd-calendar-month-year'"
												#startDatePicker
												[calendarHeaderComponent]='calendarHeader'
												startView='multi-year'
												(monthSelected)='setMonthAndYear(experiencesStartDate(i), $event, startDatePicker)'
											></mat-datepicker>
											<mat-error
												*ngIf='experiencesStartDate(i).errors?.required'
												i18n='mat-error@@app-user-expirience-dialog-experiencesStartDate-required'
											>
												Обязательное поле
											</mat-error>
											<mat-error *ngIf='experiencesStartDate(i).errors?.invalidStartDate && !experiencesStartDate(i).errors?.dateIsEarlier'>
												Дата не может быть больше текущей
											</mat-error>
											<mat-error *ngIf='experiencesStartDate(i).errors?.dateIsEarlier && !experiencesStartDate(i).errors?.invalidStartDate'>
												Дата не должна быть меньше 01.01.1900
											</mat-error>
											<mat-error *ngIf='experiencesStartDate(i).errors?.serverError'>
												{{ experiencesStartDate(i).errors?.serverError }}
											</mat-error>
										</mat-form-field>
											<pd-checkbox class="mobile-checkbox"  formControlName='tillNow'>
												{{ 'tillNow' | experience: 'propertyTitle' }}
											</pd-checkbox>
										<!--End Date -->
										<mat-form-field
											class='pd-input-form-field pd-dialog-half-row'
											appearance='fill'
											hideRequiredMarker
										>
											<mat-label>{{ 'endDate' | experience: 'propertyTitle' }}</mat-label>
											<input
												matInput
												formControlName='endDate'
												[matDatepicker]='endDatePicker'
												placeholder='00.0000'
											>
											<mat-datepicker-toggle
												class='pd-datepicker-toggle'
												matSuffix
												[for]='endDatePicker'
											>
												<mat-icon
													svgIcon='arrow-down-2'
													matDatepickerToggleIcon
												></mat-icon>
											</mat-datepicker-toggle>
											<!--                        (yearSelected)="onYearSelected(experiencesEndDate(i), $event)"-->
											<mat-datepicker
												[panelClass]="'pd-calendar'"
												#endDatePicker
												[calendarHeaderComponent]='calendarHeader'
												startView='multi-year'
												(monthSelected)='setMonthAndYear(experiencesEndDate(i), $event, endDatePicker)'
											></mat-datepicker>
											<mat-error
												*ngIf='experiencesEndDate(i).errors?.required'
												i18n='mat-error@@app-user-expirience-dialog-experiencesEndDate-required'
											>
												Обязательное поле
											</mat-error>
											<mat-error
												*ngIf='experiencesEndDate(i).errors?.invalidEndDate && !experiencesEndDate(i).errors?.dateIsEarlier'
												i18n='mat-error@@app-user-expirience-dialog-experiencesEndDate-invalidEndDate'
											>
												Укажите дату окончания позже даты начала!
											</mat-error>
											<mat-error *ngIf='experiencesEndDate(i).errors?.invalidEndDateRelativeToCurrentDate && !experiencesEndDate(i).errors?.dateIsEarlier'>
												Дата не может быть больше текущей
											</mat-error>
											<mat-error *ngIf='experiencesEndDate(i).errors?.dateIsEarlier && !experiencesEndDate(i).errors?.invalidEndDate && !experiencesEndDate(i).errors?.invalidEndDateRelativeToCurrentDate'>
												Дата не должна быть меньше 01.01.1900
											</mat-error>
											<mat-error *ngIf='experiencesEndDate(i).errors?.serverError'>
												{{ experiencesEndDate(i).errors?.serverError }}
											</mat-error>
										</mat-form-field>
									</div>


									<!-- Company -->
									<mat-form-field
										class='pd-input-form-field'
										appearance='fill'
										hideRequiredMarker
									>
										<mat-label>{{ 'companyName' | experience: 'propertyTitle' }}</mat-label>
										<input
											matInput
											[formControl]='experiencesCompany(i)'
											[matAutocomplete]='companyAuto'
										>
										<mat-autocomplete
											#companyAuto='matAutocomplete'
											(optionSelected)='setSelectedCompanyView(i, $event)'
											[displayWith]='getCompanyViewTitle'
											class='pd-autocomplete'
										>
											<tui-scrollbar class='pd-scrollbar pd-autocomplete-scrollbar'>
												<mat-option
													class='pd-option'
													*ngFor='let item of companyViews[i] | async'
													[value]='item'
												>
													{{item.title}}
												</mat-option>
											</tui-scrollbar>
										</mat-autocomplete>
										<mat-error
											*ngIf='experiencesCompany(i).errors?.required'
											i18n='mat-error@@app-user-expirience-dialog-experiencesCompany-required'
										>Обязательное поле
										</mat-error>
										<mat-error *ngIf='experiencesCompany(i).errors?.maxlength'>Введите максимум 500
											символов!
										</mat-error>
										<mat-error *ngIf='experiencesCompany(i).errors?.onlySpace && !experiencesCompany(i).errors?.required'>
											Проверьте правильность данных
										</mat-error>
										<mat-error *ngIf='experiencesCompany(i).errors?.serverError'>
											{{ experiencesCompany(i).errors?.serverError }}
										</mat-error>
									</mat-form-field>

									<!-- Position -->
									<mat-form-field
										class='pd-input-form-field'
										appearance='fill'
										hideRequiredMarker
									>
										<mat-label>{{ 'position' | experience: 'propertyTitle' }}</mat-label>
										<input
											matInput
											formControlName='position'
											required
										>
										<mat-error
											*ngIf='experiencesPosition(i).errors?.required'
											i18n='mat-error@@app-user-expirience-dialog-experiencesPosition-required'
										>Обязательное поле
										</mat-error>
										<mat-error *ngIf='experiencesPosition(i).errors?.maxlength'>Введите максимум 500
											символов!
										</mat-error>
										<mat-error *ngIf='experiencesPosition(i).errors?.onlySpace && !experiencesPosition(i).errors?.required'>
											Проверьте правильность данных
										</mat-error>
										<mat-error *ngIf='experiencesPosition(i).errors?.serverError'>
											{{ experiencesPosition(i).errors?.serverError }}
										</mat-error>
									</mat-form-field>

									<!-- Description -->
									<mat-form-field
										class='pd-input-form-field pd-textarea-form-field'
										appearance='fill'
										appExtendableTextArea
									>
										<mat-label>{{ 'description' | experience: 'propertyTitle' }}</mat-label>
										<tui-scrollbar class='pd-scrollbar pd-textarea-scrollbar'>
											<div class='pd-textarea-wrapper'>
												<div class='pd-textarea-relative-wrapper'>
													<div
														class='pd-textarea-pseudo-content'
														aria-hidden='true'
													>
														<span class='pd-textarea-pseudo'></span>

													</div>
													<textarea
														class='pd-textarea'
														matInput
														formControlName='description'
														rows='5'
													></textarea>
												</div>
											</div>
										</tui-scrollbar>
										<button
											class='pd-icon-button gray'
											matSuffix
											mat-icon-button
											type='button'
											tabindex='-1'
										>
											<mat-icon
												class='pd-icon'
												svgIcon='additional2-stretch'
											></mat-icon>
										</button>
										<mat-error *ngIf='experiencesDescription(i).errors?.maxlength'>Введите максимум
											1000 символов!
										</mat-error>
										<mat-error *ngIf='experiencesDescription(i).errors?.serverError'>
											{{ experiencesDescription(i).errors?.serverError }}
										</mat-error>
									</mat-form-field>
								</div>
							</div>
						</div>
					</ng-container>
					<ng-template #noExperiences>
						<div class='pd-profile-no-form'>
							<button
								(click)='goAddExperience(0)'
								class='pd-icon-button pd-profile-no-form-button'
								mat-icon-button
							>
								<mat-icon
									class='pd-icon pd-profile-no-form-icon'
									svgIcon='additional-plus-100-100'
								></mat-icon>
								Добавить опыт работы
							</button>
						</div>
					</ng-template>
				</div>
			</form>
		</div>
	</div>
</tui-scrollbar>

<div class='pd-dialog-footer align-end'>
	<div class='pd-dialog-footer-buttons'>
		<button
			mat-raised-button
			class='pd-button-secondary large'
			type='reset'
			[mat-dialog-close]='false'
		>
			Отменить
		</button>
		<button
			form='experiencesForm'
			mat-raised-button
			class='pd-button large'
			type='submit'
			[disabled]='(loading$ | async)'
		>
			<ng-container *ngIf='!(loading$ | async); else isSaving'>Сохранить</ng-container>
			<ng-template #isSaving>
				Сохраняем
			</ng-template>
		</button>
	</div>
</div>
