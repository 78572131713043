import {Component, Inject, OnDestroy} from '@angular/core';
import {AbstractComponentDirective} from "@shared/abstract-component.directive";
import { FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject, Subject} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CompanyView} from "@models/company-view";
import {CompanyService} from "@core/services/company.service";
import {CompanyDetailsDialogData} from "@shared/company-legal-details-dialog/company-legal-details-dialog.component";
import {takeUntil} from "rxjs/operators";
import {errorTitle} from "@utils/helpers/error-helpers";
import {NotificationService} from "@profdepo-ui/core";

@Component({
  selector: 'app-company-description-dialog',
  templateUrl: './company-description-dialog.component.html',
})
export class CompanyDescriptionDialogComponent extends AbstractComponentDirective implements OnDestroy {
  companyViewForm: FormGroup;
  debounceTime = 250;
  saving = new BehaviorSubject<boolean>(false);
  unsubscribe: Subject<any> = new Subject<any>();
  constructor(
    public dialogRef: MatDialogRef<CompanyDetailsDialogData, boolean | CompanyView>,
    @Inject(MAT_DIALOG_DATA) public data: CompanyDetailsDialogData,
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private notificationService: NotificationService
  ) {
    super();
	this.companyViewForm = this.formBuilder.group({
		description: new FormControl(this.data.companyView.description, Validators.maxLength(5000)),
	});
  }

  get description() {
    return this.companyViewForm.get('description');
  }

  onSubmit({value, valid}): void {
    if (valid) {
      this.saving.next(true);
      // copy form values to object
      let companyView = Object.assign({}, this.data.companyView);
      companyView.description = this.description.value;
      // update data
      this.companyService.callCompanyView(this.data.method, companyView)
        .pipe(
          takeUntil(this.unsubscribe)
        )
        .subscribe({
          next:(companyView) => {
            this.saving.next(false);
            this.dialogRef.close(companyView);
          },
          error: (err) => {
            this.saving.next(false);
            this.notificationService.showDanger(errorTitle(err));
          }
        });
    }
  }
  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }

}
