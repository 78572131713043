import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output,
} from '@angular/core';
import { WorkStateEnum, WorkView } from '@models/work-view';
import { WorkRequestService } from '@core/services/work-request.service';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { YesNo } from '@models/enums';
import { WorkService } from '@core/services/work.service';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@core/services/auth.service';
import { AuthDialogComponent } from '@shared/auth-dialog/auth-dialog.component';
import { WorkViewShort } from '@models/work-view-short';
import { EMPTY } from 'rxjs';

export type workCardType = 'specialist' | 'client';

@Component({
	selector: 'pdw-work-card',
	templateUrl: './work-card.component.html',
	host: { 'class': 'pd-work-card' },
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService],
	styleUrls: ['./work-card.component.scss'],

})
export class WorkCardComponent {
	/**
	 * Тип карточки для специалиста/заказчика
	 */
	@Input() cardType: workCardType;
	@Input() work: WorkViewShort | WorkView
	/**
	 * Массив с url без work.Id!!!
	 */
	@Input() commands: any[];
	/**
	 * Для карточки специалиста
	 */
	@Input() border: boolean = false;

	@Input() showFavButton: boolean = true;

	@HostBinding('class') get class() {
		return this.border ? 'with-border' : '';
	}

	readonly WORK_STATE = WorkStateEnum;

	/**
	 * Изменение статуса работы(заказчик), отклик(специалист)
	 */
	@Output() changed = new EventEmitter<any>()

	constructor(
		private workRequestService: WorkRequestService,
		private notificationService: NotificationService,
		private workService: WorkService,
		private destroy$: DestroyService,
		private dialog: MatDialog,
		public authService: AuthService,
	) {

	}

	onChange(event): void {
		this.changed.emit(event);
	}

	/**
	 * Обрабатывает нажатие средней кнопкой мыши на карточке специалиста
	 * @param event
	 */
	onMouseUp(event: MouseEvent): void {
		if (event.button === 1) {
			window.open(this.commands.join('/'), '_blank')
		}
	}

	isShortModel(work: unknown): work is WorkViewShort {
		if (work instanceof WorkViewShort) {
			return true;
		}
		return false;
	}

	onMouseDown(event: MouseEvent): void {
		if (event.button === 1) {
			event.preventDefault();
		}
	}

	canSubscribe(): boolean {
		return this.cardType !== 'client';
	}

	subscribeToWork(event: MouseEvent): void {
		this.work = {...this.work, subscribed: YesNo.yes};
		event.stopPropagation();
		this.workService.subscribe(this.work.id)
			.pipe(
				catchError(() => {
					this.work = {...this.work, subscribed: YesNo.no};
					return EMPTY;
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	goSubscribeWithoutAuth(event: MouseEvent): void {
		event.stopPropagation();
		this.dialog.open(AuthDialogComponent, {
			autoFocus: true,
			disableClose: false,
			width: '581px',
			panelClass: ['auth-dialog'],
		}).afterClosed().pipe(filter(Boolean)).subscribe(() => {
			localStorage.setItem('currentHref', window.location.href);
			this.workService.subscribe(this.work.id)
				.pipe(
					takeUntil(this.destroy$)
				)
				.subscribe({
					next: () => {
						this.work.subscribed = YesNo.yes;
						window.location.reload();
					},
					error: () => {
						window.location.reload();
					}
				});
		});
	}

	unsubscribeFromWork(event: MouseEvent): void {
		event.stopPropagation();
		this.work = {...this.work, subscribed: YesNo.no};
		this.workService.unsubscribe(this.work.id)
			.pipe(
				catchError(() => {
					this.work = {...this.work, subscribed: YesNo.yes};
					return EMPTY;
				}),
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: () => {
					this.work.subscribed = YesNo.no;
				}
			});
	}

	protected readonly YesNo = YesNo;
}
