<ng-container *ngIf='activeUser$ | async as user'>
	<mat-tab-group
		class='pd-tab-group sm files-container'
		animationDuration='0ms'
		*ngIf='haveFiles || (haveResultFiles && isClientOrExecutor(user))'
	>
		<mat-tab *ngIf='haveFiles'>
			<ng-template mat-tab-label>
				{{ 'files' | work:'propertyTitle' }}
			</ng-template>
			<!-- WorkFiles     -->
			<ng-container>
				<app-work-files
					class='pd-files-tab'
					[workView]='workView'
				>
				</app-work-files>
			</ng-container>
		</mat-tab>
		<mat-tab *ngIf='haveResultFiles && isClientOrExecutor(user)'>
			<ng-container>
				<h3>
					{{ 'resultFiles' | work:'propertyTitle' }}
				</h3>
			</ng-container>
			<!-- WorkResultFiles  -->
			<ng-container>
				<app-work-result-files
					class='pd-files-tab'
					[workView]='workView'
				>
				</app-work-result-files>
			</ng-container>
		</mat-tab>
	</mat-tab-group>
</ng-container>
