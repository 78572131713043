<pdw-dialog-header title='Верификация'>
</pdw-dialog-header>

<pdw-dialog-body>
	<form
		class='form'
		[formGroup]='verificationGroup'
	>
		<mat-form-field
			class='pd-input-form-field'
			appearance='fill'
			hideRequiredMarker
		>
			<mat-label>Фамилия</mat-label>
			<input
				matInput
				formControlName='surname'
				type='text'
			>
			<mat-error
				*ngIf='getErrorByControlName("surname").errors?.required'
			>
				Обязательное поле
			</mat-error>
			<mat-error
				*ngIf='getErrorByControlName("surname").errors?.maxlength'
			>
				Количество символов не может быть больше 100
			</mat-error>
		</mat-form-field>
		<mat-form-field
			class='pd-input-form-field'
			appearance='fill'
			hideRequiredMarker
		>
			<mat-label>Имя</mat-label>
			<input
				matInput
				formControlName='name'
				type='text'
			>
			<mat-error
				*ngIf='getErrorByControlName("name").errors?.required'
				i18n='mat-error|Заполнение работы@@work-details-dialog-endDate-invalidEndDate'
			>
				Обязательное поле
			</mat-error>
			<mat-error
				*ngIf='getErrorByControlName("name").errors?.maxlength'
			>
				Количество символов не может быть больше 100
			</mat-error>
		</mat-form-field>
		<mat-form-field
			class='pd-input-form-field'
			appearance='fill'
			hideRequiredMarker
		>
			<mat-label>Отчество</mat-label>
			<input
				matInput
				formControlName='patronymic'
				type='text'
			>
			<mat-error
				*ngIf='getErrorByControlName("patronymic").errors?.maxlength'
			>
				Количество символов не может быть больше 100
			</mat-error>
		</mat-form-field>

		<mat-form-field
			class='pd-input-form-field'
			appearance='fill'
			hideRequiredMarker
		>
			<mat-label>Дата рождения</mat-label>
			<input
				matInput
				formControlName='birthday'
				[maskito]="dateMaskitoOptions"
				[matDatepicker]='datepicker'
				placeholder='00.00.0000'
			>
			<mat-datepicker-toggle
				class='pd-datepicker-toggle'
				matSuffix
				[for]='datepicker'
			>
				<mat-icon
					svgIcon='arrow-down-2'
					matDatepickerToggleIcon
				></mat-icon>
			</mat-datepicker-toggle>
			<mat-datepicker
				[panelClass]="'pd-calendar'"
				#datepicker
				startView='multi-year'
			></mat-datepicker>
			<mat-error
				*ngIf='getErrorByControlName("birthday").errors?.required'
			>
				Обязательное поле
			</mat-error>
			<mat-error
				*ngIf='getErrorByControlName("birthday").errors?.comingOfAge'
			>
				Вам должно быть больше 18 лет
			</mat-error>
		</mat-form-field>

		<mat-form-field
			class='pd-input-form-field'
			appearance='fill'
			hideRequiredMarker
		>
			<mat-label>Серия и номер паспорта</mat-label>
			<input
				matInput
				formControlName='passport'
				type='text'
				[maskito]='maskitoOptions'
				placeholder='0000 000000'
			>
			<mat-error
				*ngIf='getErrorByControlName("passport").errors?.required'
			>
				Обязательное поле
			</mat-error>
			<mat-error
				*ngIf='getErrorByControlName("passport").errors?.pattern'
			>
				Некорректная серия или номер паспорта
			</mat-error>
		</mat-form-field>

	</form>
	<pdw-alert
		class='form-snack'
		alertType='danger'
		[canClose]='false'
		*ngIf='isReturned'
	>
		Информация об ИНН не найдена. Введите корректные данные
	</pdw-alert>
</pdw-dialog-body>

<pdw-dialog-footer>
	<button
		mat-raised-button
		class='pd-button'
		[disabled]='(loading$ | async) || isReturned || !isValid'
		(click)='submit()'
	>
		Далее
	</button>
</pdw-dialog-footer>
