import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'appPlainTextFormat'
})
export class AppPlainTextFormat implements PipeTransform {

  transform(text: any): any {
    if (!text) {
      return;
    }
    return this.linkify(text);
  }

  private linkify(plainText): string {

    const urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;

    // www. sans http:// or https://
    const pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;

    // Email addresses
    const emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;

    const enterPattern = /\n/gim;

    return plainText
      .replace(urlPattern, '<a href="$&" class="pd-link">$&</a>')
      .replace(pseudoUrlPattern, '$1<a href="http://$2" class="pd-link">$2</a>')
      .replace(emailAddressPattern, '<a href="mailto:$&" class="pd-link">$&</a>')
      .replace(enterPattern, '<br>')
  }
}
