<mat-drawer-container hasBackdrop="false" class="pd-mat-drawer-container">
  <mat-drawer #drawer mode="side" position="start" opened class="pd-mat-drawer">
    <div class="pd-mat-drawer-logo" fxLayoutAlign="center">
      <a routerLink="/administrator/dashboard">
        <img src="../../../assets/profdepo-logo.png" alt="Profdepo Logo">
      </a>
    </div>
    <mat-nav-list *ngIf="menuItems | async">
      <app-nav-menu-list-items *ngFor="let item of menuItems | async; let first = first;"
                               [item]="item"
                               [first]="first">
      </app-nav-menu-list-items>
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content class="pd-drawer-content pd-mobile-drawer-content">
    <mat-toolbar class="pd-mat-toolbar">
      <mat-toolbar-row>
        <button mat-icon-button>
          <mat-icon (click)="drawer.toggle()">menu</mat-icon>
        </button>
        <div class="pd-layout-header">
          <!--          <div class="icon">-->
          <!--            <mat-icon>{{ currentIcon | async }}</mat-icon>-->
          <!--          </div>-->
          <div class="container">
            <div class="title">
              {{ currentPrimary | async }}
              <ng-container *ngIf="currentSecondary | async">
                / {{ currentSecondary | async }}
              </ng-container>
            </div>
            <div class="hint">
              {{ currentDescription | async }}
            </div>
          </div>
          <!--          <div class="status">-->
          <!--            <div [style.background-color]="currentStatusColor | async">-->
          <!--              {{ currentStatusTitle | async }}-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div class="pd-fill-space"></div>
        <button mat-flat-button [matMenuTriggerFor]="menu">
          <mat-icon>supervised_user_circle</mat-icon>
          <span *ngIf="currentUser | async">
            {{ (currentUser | async).userName }}
          </span>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item [routerLink]="['profile']">
            <mat-icon>person</mat-icon>
            Профиль
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="onLogout()">
            <mat-icon>exit_to_app</mat-icon>
            Выход
          </button>
        </mat-menu>
      </mat-toolbar-row>
    </mat-toolbar>
    <mat-progress-bar *ngIf="loading | async" mode="indeterminate"></mat-progress-bar>
    <div
      class="pd-content-container"
      [ngClass]="{ 'loading': loading | async }">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
