<pdw-landing>
	<div
		find-job-section
		class='pdw-landing-container pdw-landing-start-container landing-container find-job'
	>
		<div
			class='pdw-landing-start-inner'
		>
			<h1 class='landing-title'>
				<p>
					Наймите
				</p>
				<p class='landing-title-bold'>
					инженера
				</p>
				<p>
					через PROFDEPO.WORK
				</p>
			</h1>
			<div class='landing-undertitle-buttons-container'>
				<a
				mat-raised-button
				class='pd-landing-button pd-button'
				[routerLink]="isLoggedIn ? ['/users']: ['/register'] "
				target="_blank"
				>Ищу
				специалиста</a>
				<img src='assets/customer-landing/first-block-image.svg' />
				<a
				mat-raised-button
				class='pd-landing-button pd-button'
				[routerLink]="isLoggedIn ? ['/jobs/all'] : ['/register']"
				target="_blank"
				>Ищу работу</a>
			</div>
		</div>
		<div class='find-job-register-block'>
			<img src='assets/customer-landing/first-block-image.svg' />
			<div class='register-block'>
				<h1>
					Зарегистрируйтесь
					прямо сейчас!
				</h1>

				<form
					class='register-form'
					[formGroup]='formGroupOne'
				>
					<mat-form-field
						class='pd-input-form-field custom-form-field'
						appearance='fill'
						hideRequiredMarker
					>
						<mat-label>Почта</mat-label>
						<input
							matInput
							formControlName='email'
						/>
						<mat-error
							*ngIf='formGroupOne.email?.errors?.required'
							i18n='mat-error@@register-email-required'
						>Обязательное поле
						</mat-error>
						<mat-error
							*ngIf='formGroupOne.email?.errors?.pattern'
						>Email введен не корректно!
						</mat-error>
					</mat-form-field>
					<mat-form-field class='pd-input-form-field custom-form-field' appearance='fill' hideRequiredMarker>
						<mat-label>Дата рождения</mat-label>
						<input
						matInput
						[matDatepicker]="birthdayPicker"
						[maskito]="maskitoOptions"
						 formControlName='birthday'
						 placeholder='00.00.0000'
						 >
						<mat-datepicker-toggle matSuffix [for]="birthdayPicker">
							<mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon></mat-datepicker-toggle>
						<mat-datepicker #birthdayPicker></mat-datepicker>
						<mat-error *ngIf='formGroupOne.birthday?.errors?.required' i18n='mat-error@@register-birthday-required'>Обязательное
							поле
						</mat-error>
						<mat-error
						*ngIf='formGroupOne.birthday?.errors?.comingOfAge && !formGroupOne.birthday?.errors?.dateIsEarlier && !formGroupOne.birthday?.errors?.matDatepickerParse && !formGroupOne.birthday?.errors?.dateIsLater'
						i18n='mat-error@@app-user-personal-dialog-birthday-comingOfAge'
					>
						Кажется, ошибка: возраст менее 18 лет.
					</mat-error>
					<mat-error
						*ngIf='formGroupOne.birthday?.errors?.dateIsEarlier && !formGroupOne.birthday?.errors?.comingOfAge && !formGroupOne.birthday?.errors?.matDatepickerParse && !formGroupOne.birthday?.errors?.dateIsLater'
					>
						Дата рождения не должна быть меньше 01.01.1900
					</mat-error>
					<mat-error
						*ngIf='formGroupOne.birthday?.errors?.dateIsLater && !formGroupOne.birthday?.errors?.dateIsEarlier && !formGroupOne.birthday?.errors?.matDatepickerParse'
					>
						Дата не может быть больше текущей
					</mat-error>
					<mat-error
						*ngIf='formGroupOne.birthday?.errors?.matDatepickerParse && !formGroupOne.birthday?.errors?.comingOfAge && !formGroupOne.birthday?.errors?.dateIsEarlier && !formGroupOne.birthday?.errors?.dateIsLater'
					>
						Вы ввели не существующую дату
					</mat-error>
					<mat-error *ngIf='formGroupOne.birthday?.errors?.serverError'>
						{{ formGroupOne.birthday?.errors?.serverError }}
					</mat-error>
					</mat-form-field>
					<pdw-password-input
						[displayEyeButton]='true'
						formControlName='password'
					>

						<mat-label>Пароль</mat-label>
						<mat-error
							*ngIf='formGroupOne.password?.errors?.required'
							i18n='mat-error@@register-password-required'
						>Обязательное поле
						</mat-error>
						<mat-error
							*ngIf='formGroupOne.password?.errors?.pattern'
							i18n='mat-error@@register-password-pattern'
						>
							Пароль должен быть не менее 8 символов и содержать цифры и буквы большого и малого
							регистра
						</mat-error>
						<mat-error
							*ngIf='formGroupOne.password?.errors?.maxlength'
						>Введите не более 20 символов!
						</mat-error>
					</pdw-password-input>

					<pdw-password-input
						[displayEyeButton]='true'
						formControlName='passwordConfirm'
					>
						<mat-label>Повторите пароль</mat-label>

						<mat-error
							*ngIf='formGroupOne.passwordConfirm.errors?.required'
							i18n='mat-error@@register-password-required'
						>Обязательное поле
						</mat-error>
						<mat-error
							*ngIf="formGroupOne?.hasError('confirmPassword','passwordConfirm')"
							i18n='mat-error@@register-passwordConfirm-notSame'
						>Указанные пароли не совпадают!
						</mat-error>
					</pdw-password-input>
				</form>

				<span>
					Продолжая, вы принимаете
					<a
						class='pd-link pd-register-link'
						href='https://profdepo.ru/termsOfUse'
						target='_blank'
					>пользовательское соглашение</a
					>
					и
					<a
						class='pd-link pd-register-link'
						href='https://profdepo.ru/privacyPolicy'
						target='_blank'
					>политику конфиденциальности</a
					>
				</span>

				<button
					mat-raised-button
					class='pd-landing-button pd-button register-button'
					[disabled]='(!formGroupOne.valid) || (loading$ | async)'
					(click)='submit(formGroupOne)'
				>
					{{(loading$ | async) ? "Загрузка" : "Зарегистрироваться"}}
				</button>
			</div>
		</div>
	</div>

	<div
		class='scope'
		scope-section
	>
		<h1 class='title'>
			Сервисы для&nbsp;инженерных компаний
		</h1>
		<div class='pdw-landing-scope-container slide-cards-container'>
			<div
				class='pdw-landing-scope-inner pdw-landing-rating slide-card'
			>
				<img
					class='slide-card-image'
					src='assets/landing/woman-with-cat.svg'
					alt='landing-img'
				/>
				<div class='slide-card-info'>
					<p class='slide-card-title'>Фриланс и проектная работа</p>
					<p class='slide-card-subtitle'>Приглашайте проверенных инженеров-фрилансеров и платите за
						результат.</p>
					<a
						[routerLink]="isLoggedIn ? ['/works'] : ['/register']"
						target="_blank"
						mat-raised-button
						class='pd-landing-button pd-button landing-card-button'
					>
						Создать работу
					</a>
				</div>
			</div>
			<div
				class='pdw-landing-scope-inner pdw-landing-money slide-card-outline'
			>
				<div class='slide-card-empty'>
					<h3 class='slide-card-title slide-card-title-margin'>Найм инженеров
						на постоянную работу</h3>
					<p>
						Формируйте штат сотрудников.
					</p>
					<p>
						Здесь вы найдете тех,
					</p>
					<p>
						с кем сработаетесь на годы вперёд.
					</p>

					<a
					   [routerLink]="isLoggedIn ? ['/works'] : ['/register']"
					   [queryParams]="{ isFreelance: false }"
					    target="_blank"
						mat-raised-button
						class='pd-landing-button pd-button landing-card-button'
					>
						Создать вакансию
					</a>
				</div>
				<div class='slide-card-empty-image-wrapper'>
					<img
						class='slide-card-empty-image'
						src='assets/landing/vacancy.svg'
						alt='landing-img'
					/>
				</div>
			</div>
			<div
				class='pdw-landing-scope-inner pdw-landing-rating slide-card'
			>
				<img
					class='slide-card-image'
					src='assets/landing/user-passport.svg'
					alt='landing-img'
				/>
				<div class='slide-card-info'>
					<p class='slide-card-title'>ИИ-помощник в поиске
						и подборе кадров</p>
					<p class='slide-card-subtitle'>Автоматизируйте работу HR-менеджера
						с помощью новейших технологий и алгоритмов</p>

					<a
					    [routerLink]="isLoggedIn ? ['/users']: ['/register'] "
						target="_blank"
						mat-raised-button
						class='pd-landing-button pd-button landing-card-button'
					>
						Подобрать специалиста
					</a>
				</div>
			</div>
		</div>
	</div>

	<div
		class='join'
		join-section
	>
		<div class='join-content-container'>
			<div class='join-img-wrapper'>
				<img
					class='join-content-image'
					src='assets/landing/join.svg'
					alt='landing-img'
				/>
			</div>

			<div class='join-register-block'>
				<h1>
					Зарегистрируйтесь
					и наймите сотрудника
					уже сегодня
				</h1>

				<form
					class='join-form'
					[formGroup]='formGroupTwo'
				>
					<mat-form-field
						class='pd-input-form-field custom-form-field'
						appearance='fill'
						hideRequiredMarker
					>
						<mat-label>Почта</mat-label>
						<input
							matInput
							formControlName='email'
						/>
						<mat-error
							*ngIf='formGroupTwo.email?.errors?.required'
							i18n='mat-error@@register-email-required'
						>Обязательное поле
						</mat-error>
						<mat-error
							*ngIf='formGroupTwo.email?.errors?.pattern'
						>Email введен не корректно!
						</mat-error>
					</mat-form-field>
					<mat-form-field class='pd-input-form-field custom-form-field' appearance='fill' hideRequiredMarker>
						<mat-label>Дата рождения</mat-label>
						<input matInput [matDatepicker]="birthdayPickerTwo" formControlName='birthday' [maskito]="maskitoOptions"
							placeholder='00.00.0000'>
						<mat-datepicker-toggle matSuffix [for]="birthdayPickerTwo">
							<mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon></mat-datepicker-toggle>
						<mat-datepicker #birthdayPickerTwo></mat-datepicker>
						<mat-error *ngIf='formGroupTwo.birthday?.errors?.required' i18n='mat-error@@register-birthday-required'>Обязательное
							поле
						</mat-error>
						<mat-error
							*ngIf='formGroupTwo.birthday?.errors?.comingOfAge && !formGroupTwo.birthday?.errors?.dateIsEarlier && !formGroupTwo.birthday?.errors?.matDatepickerParse && !formGroupTwo.birthday?.errors?.dateIsLater'
							i18n='mat-error@@app-user-personal-dialog-birthday-comingOfAge'>
							Кажется, ошибка: возраст менее 18 лет.
						</mat-error>
						<mat-error
							*ngIf='formGroupTwo.birthday?.errors?.dateIsEarlier && !formGroupTwo.birthday?.errors?.comingOfAge && !formGroupTwo.birthday?.errors?.matDatepickerParse && !formGroupTwo.birthday?.errors?.dateIsLater'>
							Дата рождения не должна быть меньше 01.01.1900
						</mat-error>
						<mat-error
							*ngIf='formGroupTwo.birthday?.errors?.dateIsLater && !formGroupTwo.birthday?.errors?.dateIsEarlier && !formGroupTwo.birthday?.errors?.matDatepickerParse'>
							Дата не может быть больше текущей
						</mat-error>
						<mat-error
							*ngIf='formGroupTwo.birthday?.errors?.matDatepickerParse && !formGroupTwo.birthday?.errors?.comingOfAge && !formGroupTwo.birthday?.errors?.dateIsEarlier && !formGroupTwo.birthday?.errors?.dateIsLater'>
							Вы ввели не существующую дату
						</mat-error>
						<mat-error *ngIf='formGroupTwo.birthday?.errors?.serverError'>
							{{ formGroupTwo.birthday?.errors?.serverError }}
						</mat-error>
					</mat-form-field>
					<pdw-password-input
						[displayEyeButton]='true'
						formControlName='password'
					>

						<mat-label>Пароль</mat-label>
						<mat-error
							*ngIf='formGroupTwo.password?.errors?.required'
							i18n='mat-error@@register-password-required'
						>Обязательное поле
						</mat-error>
						<mat-error
							*ngIf='formGroupTwo.password?.errors?.pattern'
							i18n='mat-error@@register-password-pattern'
						>
							Пароль должен быть не менее 8 символов и содержать цифры и буквы большого и малого
							регистра
						</mat-error>
						<mat-error
							*ngIf='formGroupTwo.password?.errors?.maxlength'
						>Введите не более 20 символов!
						</mat-error>
					</pdw-password-input>

					<pdw-password-input
						[displayEyeButton]='true'
						formControlName='passwordConfirm'
					>
						<mat-label>Повторите пароль</mat-label>

						<mat-error
							*ngIf='formGroupTwo.passwordConfirm.errors?.required'
							i18n='mat-error@@register-password-required'
						>Обязательное поле
						</mat-error>
						<mat-error
							*ngIf="formGroupTwo?.hasError('confirmPassword','passwordConfirm')"
							i18n='mat-error@@register-passwordConfirm-notSame'
						>Указанные пароли не совпадают!
						</mat-error>
					</pdw-password-input>
				</form>

				<span>
					Продолжая, вы принимаете
					<a
						class='pd-link pd-register-link'
						href='https://profdepo.ru/termsOfUse'
						target='_blank'
					>пользовательское соглашение</a
					>
					и
					<a
						class='pd-link pd-register-link'
						href='https://profdepo.ru/privacyPolicy'
						target='_blank'
					>политику конфиденциальности</a
					>
				</span>

				<button
					mat-raised-button
					class='pd-landing-button pd-button register-button'
					[disabled]='(!formGroupTwo.valid) || (loading$ | async)'
					(click)='submit(formGroupTwo)'
				>
					{{(loading$ | async) ? "Загрузка" : "Зарегистрироваться"}}
				</button>
			</div>
		</div>
	</div>
</pdw-landing>
