<div class='pd-dialog-header'>
	<p class='pd-dialog-header-title'>{{ data.title }}</p>
	<button
		class='pd-icon-button'
		mat-icon-button
		[mat-dialog-close]='false'
	>
		<mat-icon svgIcon='interface-close'></mat-icon>
	</button>
</div>
<tui-scrollbar class='pd-scrollbar pd-dialog-scroll'>
	<div class='pd-dialog-content'>
		<div *ngIf='educationsForm'>
			<form
				id='educationsForm'
				[formGroup]='educationsForm'
				(ngSubmit)='onSubmit(educationsForm)'
			>
				<div class='pd-dialog-column'>

					<ng-container *ngIf='educations.length > 0; else noEducations'>
						<div
							formArrayName='educations'
							*ngFor='let educationFormGroup of educations.controls; let i = index'
						>
							<div [formGroupName]='i'>
								<mat-form-field class='pd-input-invisible'>
									<input
										matInput
										formControlName='id'
										required
									>
								</mat-form-field>

								<div class='pd-dialog-row pd-dialog-subtitle-row'>
									<!--University-->
									<div class='pd-dialog-subtitle'>
										Учебное заведение {{ i + 1 }}
									</div>
									<div class='pd-dialog-row pd-dialog-icon-buttons'>
										<!--Remove Item-->
										<button
											type='button'
											class='pd-icon-button'
											mat-icon-button
											(click)='goRemoveEducation(i)'
										>
											<mat-icon svgIcon='additional-trash-2'></mat-icon>
										</button>
										<!--Add Item-->
										<button
											type='button'
											(click)='goAddEducation(i + 1)'
											class='pd-icon-button'
											mat-icon-button
										>
											<mat-icon svgIcon='additional-plus'></mat-icon>
										</button>
									</div>
								</div>
								<div class='pd-dialog-column'>
									<div class='pd-dialog-row'>
										<!--Year-->
										<mat-form-field
											class='pd-input-form-field pd-dialog-half-row'
											appearance='fill'
											hideRequiredMarker
										>
											<mat-label>{{ 'year' | education: 'propertyTitle' }}</mat-label>
											<pd-select
												formControlName='year'
												required
											>
												<pd-select-option
													*ngFor='let item of years'
													[value]='item'
												>
													{{item}}
												</pd-select-option>
											</pd-select>
											<button
												class='pd-icon-button gray'
												matSuffix
												mat-icon-button
												type='button'
											>
												<mat-icon
													class='pd-icon'
													svgIcon='arrow-down-2'
												></mat-icon>
											</button>
											<mat-error
												*ngIf='educationsYear(i).errors?.required'
												i18n='mat-error@@app-user-education-dialog-educationsYear-required'
											>Обязательное поле
											</mat-error>
											<mat-error *ngIf='educationsYear(i).errors?.serverError'>
												{{ educationsYear(i).errors?.serverError }}
											</mat-error>
										</mat-form-field>
										<!--Level-->
										<mat-form-field
											class='pd-input-form-field pd-dialog-half-row'
											appearance='fill'
											hideRequiredMarker
										>
											<mat-label>{{ 'level' | education: 'propertyTitle' }}</mat-label>
											<pd-select
												formControlName='level'
												required
											>
												<pd-select-option
													*ngFor='let item of levels | educationLevel'
													[value]='+item.key'
												>
													{{item.value}}
												</pd-select-option>
											</pd-select>
											<button
												class='pd-icon-button gray'
												matSuffix
												mat-icon-button
												type='button'
											>
												<mat-icon
													class='pd-icon'
													svgIcon='arrow-down-2'
												></mat-icon>
											</button>
											<mat-error
												*ngIf='educationsLevel(i).errors?.required'
												i18n='mat-error@@app-user-education-dialog-educationsLevel-required'
											>Обязательное поле
											</mat-error>
											<mat-error *ngIf='educationsLevel(i).errors?.serverError'>
												{{ educationsLevel(i).errors?.serverError }}
											</mat-error>
										</mat-form-field>
									</div>
									<!--Name-->
									<mat-form-field
										class='pd-input-form-field'
										appearance='fill'
										hideRequiredMarker
									>
										<mat-label>{{ 'name' | education: 'propertyTitle' }}</mat-label>
										<input
											matInput
											formControlName='name'
											required
										>
										<mat-error
											*ngIf='educationsName(i).errors?.required && !educationsName(i).errors?.onlySpace'
											i18n='mat-error@@app-user-education-dialog-educationsName-required'
										>Обязательное поле
										</mat-error>
										<mat-error *ngIf='educationsName(i).errors?.maxlength'>
											Введите максимум 500 символов!
										</mat-error>
										<mat-error *ngIf='educationsName(i).errors?.onlySpace && !educationsName(i).errors?.maxlength &&!educationsName(i).errors?.required'>
											Проверьте правильность данных
										</mat-error>
										<mat-error *ngIf='educationsName(i).errors?.serverError'>
											{{ educationsName(i).errors?.serverError }}
										</mat-error>
									</mat-form-field>
									<!--Sector-->
									<mat-form-field
										class='pd-input-form-field'
										appearance='fill'
									>
										<mat-label>{{ 'sector' | education: 'propertyTitle' }}</mat-label>
										<input
											matInput
											formControlName='sector'
										>
										<mat-error *ngIf='educationsSector(i).errors?.maxlength'>
											Введите максимум 500 символов!
										</mat-error>
										<mat-error *ngIf='educationsSector(i).errors?.onlySpace && !educationsSector(i).errors?.maxlength'>
											Проверьте правильность данных
										</mat-error>
										<mat-error *ngIf='educationsSector(i).errors?.serverError'>
											{{ educationsSector(i).errors?.serverError }}
										</mat-error>
									</mat-form-field>
									<mat-form-field
										class='pd-input-form-field'
										appearance='fill'
									>
										<mat-label>{{ 'speciality' | education: 'propertyTitle' }}</mat-label>
										<input
											matInput
											formControlName='speciality'
										>
										<mat-error *ngIf='educationsSpeciality(i).errors?.maxlength'>
											Введите максимум 500 символов!
										</mat-error>
										<mat-error *ngIf='educationsSpeciality(i).errors?.onlySpace && !educationsSpeciality(i).errors?.maxlength'>
											Проверьте правильность данных
										</mat-error>
										<mat-error *ngIf='educationsSpeciality(i).errors?.serverError'>
											{{ educationsSpeciality(i).errors?.serverError }}
										</mat-error>
									</mat-form-field>
								</div>

							</div>
						</div>
					</ng-container>
					<ng-template #noEducations>
						<div class='pd-profile-no-form'>
							<button
								(click)='goAddEducation(0)'
								class='pd-icon-button pd-profile-no-form-button'
								mat-icon-button
							>
								<mat-icon
									class='pd-icon pd-profile-no-form-icon'
									svgIcon='additional-plus-100-100'
								></mat-icon>
								Добавить образование
							</button>
						</div>
					</ng-template>
				</div>
			</form>
		</div>
	</div>
</tui-scrollbar>
<div class='pd-dialog-footer align-end'>
	<div class='pd-dialog-footer-buttons'>
		<button
			mat-raised-button
			class='pd-button-secondary large'
			type='reset'
			[mat-dialog-close]='false'
		>
			Отменить
		</button>
		<button
			form='educationsForm'
			mat-raised-button
			class='pd-button large'
			type='submit'
			[disabled]='(saving | async)'
		>
			<ng-container *ngIf='!(saving | async); else isSaving'>Сохранить</ng-container>
			<ng-template #isSaving>
				Сохраняем
			</ng-template>
		</button>
	</div>
</div>

