import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'pdw-success-verification-dialog',
  templateUrl: './success-verification-dialog.component.html',
  styleUrls: ['./success-verification-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuccessVerificationDialogComponent {



}
