import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyInformationComponent } from './company-information.component';
import { RouterLinkWithHref } from '@angular/router';
import { RatingOneSmallModule } from '@shared/rating-one-small/rating-one-small.module';
import { AvatarModule } from '@shared/pipes/avatar/avatar.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
	declarations: [CompanyInformationComponent],
	exports: [CompanyInformationComponent],
	imports: [
		CommonModule,
		RouterLinkWithHref,
		RatingOneSmallModule,
		AvatarModule,
		MatProgressSpinnerModule
	]
})
export class CompanyInformationModule {
}
