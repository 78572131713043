<ng-container *ngIf="workView">
  <ng-container *ngIf="(workView.resultFiles && workView.resultFiles.length)">
    <mat-table class="pd-files" [dataSource]="workResultFileDataSource">
      <!-- Info Column -->
      <ng-container matColumnDef="info">
        <mat-cell class="pd-files-cell" *matCellDef="let element">
          <div class="pd-files-inner">
            <span class="pd-files-type">
              {{ element.extension.substring(1) }}
            </span>
            <span class="pd-files-slash">&nbsp;/&nbsp;
            </span>
            <p class="pd-files-name" (click)="goDownload(element)">
              {{ element.name }}
            </p>
            <span class="pd-files-slash">&nbsp;/&nbsp;
            </span>
            <span class="pd-files-size">
                {{ element.size | fileSize }}
            </span>
            <span class="pd-files-slash">&nbsp;/&nbsp;
            </span>
            <span class="pd-files-date">
                {{ element.modifyTime | date }}
            </span>
          </div>
        </mat-cell>
      </ng-container>
      <mat-row class="pd-files-row" *matRowDef="let row; columns: ['info'];"></mat-row>
    </mat-table>
  </ng-container>
</ng-container>
