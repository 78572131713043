<ng-container *ngIf='currentWorkView | async as workView'>
	<ng-container *ngIf='isActiveUserAssigned'>
		<ng-container *ngIf='(canCancel(workView) | async)'>
			<button
				mat-raised-button
				class='pd-button-secondary pd-button-sm-font warn full-width'
				(click)='goCancel(workView)'
			>
				Отклонить
			</button>
		</ng-container>
		<ng-container *ngIf='canExecuting(workView) | async'>
			<button
				mat-raised-button
				class='pd-button-secondary pd-button-secondary-work-state'
				(click)='goExecuting(workView)'
			>
				Начать работу
			</button>
		</ng-container>
		<ng-container *ngIf='canChecking(workView) | async'>
			<button
				mat-raised-button
				class='pd-button-secondary pd-button-secondary-work-state'
				(click)='goChecking(workView)'
			>
				На проверку
			</button>
		</ng-container>
		<ng-container *ngIf='isChecking'>
			<button
				mat-raised-button
				class='pd-button-secondary pd-button-secondary-work-state'
				[disabled]='true'
			>
				Ожидает ответа
			</button>
		</ng-container>
	</ng-container>
	<div
		*ngIf='isInvitedByManagerInVacancy'
		class='buttons-container'
	>
		<button
			mat-raised-button
			class='pd-button-secondary pd-button-sm-font'
			(click)='acceptInvite()'
		>
			Принять приглашение
		</button>

		<button
			class='pd-button-secondary pd-button-sm-font warn'
			mat-raised-button
			(click)='rejectInvite()'
		>
			Отклонить
		</button>
	</div>

	<button
		*ngIf='isAwaitingForManagerInVacancy'
		mat-raised-button
		class='pd-button-secondary pd-button-sm-font warn full-width'
		(click)='rejectInvite()'
	>
		Отказаться от вакансии
	</button>

	<button
		*ngIf='isFirstRequestedByExecutorInVacancy || isFirstRequestByExecutorInFreelance'
		mat-raised-button
		class='pd-button-secondary pd-button-secondary-work-state warn full-width'
		(click)='cancelInviteBySpecialist()'
	>
		Отменить отклик
	</button>

	<div *ngIf='requestedAlready' class='invite-inscription'>
		<mat-icon
			class='pd-icon'
			svgIcon='additional2-info'
		></mat-icon>
		<span class='invite-inscription-text'>
			Вы не можете повторно откликнуться на {{isFreelance ? "работу" : "вакансию"}}
		</span>
	</div>

	<div *ngIf='rejectedBySpecialist' class='invite-inscription'>
		<mat-icon
			class='pd-icon'
			svgIcon='additional2-info'
		></mat-icon>
		<span class='invite-inscription-text'>
			Вы уже отклонили приглашение на эту {{isFreelance ? "работу" : "вакансию"}}
		</span>
	</div>
		<div *ngIf="!(isRequested(workView) | async)"
			class='pd-work-button-wrapper'
			[matTooltip]='tooltipAddRequest(workView) | async'
			[matTooltipDisabled]='!(isRequested(workView) | async)'
			[matTooltipPosition]="'below'"
		>
			<button
				mat-raised-button
				class='pd-button-secondary pd-button-secondary-work-state'
				(click)='goAddRequest(workView)'
				[disabled]='isRequested(workView) | async'
			>
				Откликнуться
			</button>
		</div>
</ng-container>
