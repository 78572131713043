import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'workLabel'
})
export class WorkLabelPipe extends AbstractModelPipe implements PipeTransform {
  properties = [
    {name: 'title', title: 'Наименование', hint: 'Наименование'},
    {name: 'name', title: 'Наименование', hint: 'Наименование работы'},
    {name: 'company', title: 'Компания', hint: 'Компания'},
    {name: 'description', title: 'Расскажите про вакансию', hint: 'Описание работы', secondHint: 'Описание'},
    {name: 'startDate', title: 'Дата начала', hint: 'Фактическая дата начала работы', case: 'дату начала'},
    {name: 'endDate', title: 'Дата окончания', hint: 'Фактическая дата окончания работы', case: 'дату окончания'},
    {name: 'cost', title: 'Уровень дохода в ', hint: 'Уровень дохода'},
    {name: 'hardSkills', title: 'Профессиональные навыки', hint: 'Необходимые профессиональные навыки'},
    {name: 'workTypes', title: 'Формат работы', hint: 'Формат работы'},
    {name: 'workFormat', title: 'График работы', hint: 'График работы'},
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
  allProperties() {
    return this.properties;
  }
}
