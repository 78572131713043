import { Injectable } from '@angular/core';
import { AbstractModelService } from '@core/services/abstract-model.service';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkFormatView } from '@models/work-format-view';

@Injectable({
	providedIn: 'root'
})
export class WorkFormatService {
	private workFormatUrl = 'api/WorkFormat';

	constructor(private http: HttpClient, private modelService: AbstractModelService) {
	}

	public static parseDates(workFormatView: WorkFormatView): WorkFormatView {
		workFormatView.createTime = new Date(workFormatView.createTime);
		workFormatView.modifyTime = new Date(workFormatView.modifyTime);
		return workFormatView;
	}

	all(): Observable<WorkFormatView[]> {
		return this.http.get<WorkFormatView[]>(`${this.workFormatUrl}/All`, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => WorkFormatService.parseDates(x))),
			);
	}

	firstFifty(): Observable<WorkFormatView[]> {
		return this.http.get<WorkFormatView[]>(`${this.workFormatUrl}/FirstFifty`, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => WorkFormatService.parseDates(x))),
			);
	}

	filter(query: string): Observable<WorkFormatView[]> {
		return this.http.get<WorkFormatView[]>(`${this.workFormatUrl}/Filter/${query}`, this.modelService.httpOptions)
			.pipe(
				tap(x => console.log('filter: ', query, x)),
				map(xx => xx.map(x => WorkFormatService.parseDates(x))),
			);
	}


}
