import {Component, Input, OnInit} from '@angular/core';
import {YesNo} from "@models/enums";

@Component({
  selector: 'app-yes-no-value',
  templateUrl: './yes-no-value.component.html',
})
export class YesNoValueComponent {
  @Input() value: YesNo;
  constructor() { }
}
