import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {MenuItem} from "@models/menu-item";
import {MenuService} from "@core/services/menu.service";

@Component({
  selector: 'app-nav-menu-list-items',
  templateUrl: './nav-menu-list-items.component.html',
  styleUrls: ['./nav-menu-list-items.component.scss']
})
export class NavMenuListItemsComponent implements OnInit {
  expanded = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item!: MenuItem;
  @Input() depth: number = 0;
  @Input() first: boolean = false;
  constructor(
    private router: Router,
    private menuService: MenuService
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }
  ngOnInit() {
    this.menuService.currentUrl.subscribe((url: string) => {
      if (this.item.routerLink && url) {
        this.expanded = url.indexOf(`${this.item.routerLink}`) === 0;
        this.ariaExpanded = this.expanded;
      }
    });
  }
  onItemSelected(item: MenuItem, event: Event) {
    if (!item.children || !item.children.length) {
      this.router.navigate(item.routerLink);
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
}
