import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TrueLoadingService extends BehaviorSubject<boolean> implements OnDestroy {

	constructor() {
		super(true);
	}

	ngOnDestroy(): void {
		this.complete();
	}
}
