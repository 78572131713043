import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateToDayAndMonth'
})
export class DateToDayAndMonthPipe implements PipeTransform {

  transform(time: Date): string {
    let now = new Date();
    let el = String(time);
    time = new Date(Date.parse(el));
    if (time){
      return [
        time.getDate(),
        time.getMonth() == 0
          ? 'Января'
          : null,
        time.getMonth() == 1
          ? 'Февраля'
          : null,
        time.getMonth() == 2
          ? 'Марта'
          : null,
        time.getMonth() == 3
          ? 'Апреля'
          : null,
        time.getMonth() == 4
          ? 'Майя'
          : null,
        time.getMonth() == 5
          ? 'Июня'
          : null,
        time.getMonth() == 6
          ? 'Июля'
          : null,
        time.getMonth() == 7
          ? 'Августа'
          : null,
        time.getMonth() == 8
          ? 'Сентября'
          : null,
        time.getMonth() == 9
          ? 'Октября'
          : null,
        time.getMonth() == 10
          ? 'Ноября'
          : null,
        time.getMonth() == 11
          ? 'Декабря'
          : null,
        time.getFullYear() != now.getFullYear()
          ? time.getFullYear()
          : null,
      ].join(' ');
    } else {
      return '';
    }
  }

}
