import { DataSource } from '@core/dataSources/data-source';
import {
	ComplexFilterComplete,
	ComplexFilterField,
	ComplexFilterHelpers,
	ComplexFilterResult,
	ComplexFilterSource
} from '@utils/helpers/complex-filter-helpers';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Directive,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { MaterialCalendarHeaderComponent } from '@shared/material-calendar-header/material-calendar-header.component';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, forkJoin, iif, mergeMap, of, Subject, Subscription, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, startWith, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { YesNo } from '@models/enums';
import { FiltersService } from '@core/services/filters.service';
import { CityModel } from '@models/city-model';


const CHANGE_INPUT_DELAY: number = 350;


export class ComplexFilterDialogData {
	dataSource: DataSource<any>;
	displayedFields: ComplexFilterField[];
	activeFilters: ComplexFilterComplete[];
	anyFilter: string | null;
	placeholder: string;
	counterWordsToDeclension: string[];
}

@Directive()
export class ComplexFilterBase implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	@Input() data: ComplexFilterDialogData;
	calendarHeader = MaterialCalendarHeaderComponent;
	currentFormGroup: FormGroup;
	currentCountFilter = new BehaviorSubject<number>(0);
	activeFilters = new BehaviorSubject<ComplexFilterComplete[]>([]);
	anyFilter = new FormControl();
	sort = new FormControl({ id: 'modifytime', name: 'По новизне', direction: 'desc' });
	sources: ComplexFilterSource[] = [];
	unsubscribe: Subject<any> = new Subject<any>();
	completeInitialization: boolean;
	initialComplexFilterResult: ComplexFilterResult = new ComplexFilterResult(null, null);
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() result = new EventEmitter<ComplexFilterResult>();
	@Output() resetEvent = new EventEmitter<void>();
	@ViewChild('anyFilterInput') anyFilterInput: ElementRef;
	@ViewChild('sortFilterInput') sortFilterInput: ElementRef;
	mainTagFilter = new BehaviorSubject<ComplexFilterComplete>(null);
	mainTagField: ComplexFilterField;
	filtersWithoutMainTag = new BehaviorSubject<ComplexFilterComplete[]>([]);
	private subscriptions: Subscription[] = [];

	protected trueData: ComplexFilterDialogData;
	protected cdr: ChangeDetectorRef = inject(ChangeDetectorRef)
	private filterService = inject(FiltersService);

	constructor() {
	}

	ngOnInit(): void {
		this.cdr.detach();
	}

	initialize(data?: ComplexFilterDialogData): void {
		if (data) {
			this.trueData = data;
		}

		this.completeInitialization = false;
		this.currentFormGroup = new FormGroup({}, {
			validators: [this.compareDates.bind(this), this.compareNumber.bind(this)]
		});

		// Тут мы задаем фильтры
		this.trueData.displayedFields
			.forEach(filter => this.addControl(filter, this.currentFormGroup));

		this.mainTagField = this.trueData.displayedFields.find(x => x.inputType === 'main-tag');

		this.activeFilters
			.pipe(
				filter(x => !!x),
				takeUntil(this.unsubscribe),
			)
			.subscribe((filters: ComplexFilterComplete[]) => {
				this.filtersWithoutMainTag.next(
					filters.filter((x) => x.inputType !== "main-tag" && x?.value !== null)
				);
				this.recount(this.anyFilter.value);
				if (this.completeInitialization) {
					this.result.emit(new ComplexFilterResult(this.anyFilter.value, this.activeFilters.value));
				}

				if (!this.completeInitialization) {
					const formControls = this.currentFormGroup.controls;
					this.initialComplexFilterResult.filters = filters;
					filters.forEach(filter => {
						if (filter.name === 'isFreelance') {
							return;
						}
						if (filter.secondInputValue) {
							formControls[this.secondInputName(filter.name)].setValue(filter.secondInputValue);
							formControls[this.secondInputName(filter.name)].markAsTouched();
						}
						if ((!Array.isArray(filter.value) || filter.inputType === 'multi-toggle' || filter.inputType === 'checkbox-list')
							&& formControls[filter.name]) {
							formControls[filter.name].setValue(filter.value);
							formControls[filter.name].markAsTouched();
						}
					});
				}
				this.cdr.markForCheck();
			});

		this.mainTagFilter
			.pipe(
				takeUntil(this.unsubscribe),
				filter(x => x !== null)
			).subscribe({
			next: () => this.onReset()
		})

		this.anyFilter.valueChanges
			.pipe(
				takeUntil(this.unsubscribe),
				distinctUntilChanged(),
				debounceTime(350),
			)
			.subscribe((x: string) => {
				this.recount(x);
				this.result.emit(new ComplexFilterResult(x, this.activeFilters.value));
			});

		if (this.trueData.activeFilters) {
			this.activeFilters.next(this.trueData.activeFilters);
			this.filtersWithoutMainTag.next(this.trueData.activeFilters.filter(x => x.inputType !== 'main-tag'));
			this.initialComplexFilterResult.filters = [];
			this.trueData.activeFilters.forEach(x => {
				this.initialComplexFilterResult.filters.push(Object.assign({}, x));
				this.cdr.markForCheck();
			});
		}

		if (this.trueData.anyFilter) {
			this.initialComplexFilterResult.any = this.trueData.anyFilter;
			this.cdr.markForCheck();
		}

		this.anyFilter.setValue(this.trueData.anyFilter, { emitEvent: false });
		this.cdr.markForCheck();
	}


	recount(query: string): void {
		const result = new ComplexFilterResult(query, this.activeFilters.value);
		if (!this.trueData.dataSource) return;

		this.trueData.dataSource.loadCount(result)
			.pipe(
				takeUntil(this.unsubscribe)
			)
			.subscribe(x => {
				this.currentCountFilter.next(x);
			}, (err) => {
				console.error(err);
			});
	}


	ngAfterViewInit() {
		this.completeInitialization = true;
	}

	/**
	 * Возвращает подсказку для chip'а в фильтре
	 * @param filter
	 */
	tooltipFilter(filter: ComplexFilterComplete): string {
		const filterField = this.trueData.displayedFields.find(x => x.name === filter.name);

		return ComplexFilterHelpers.tooltipFilter(filter, filterField);
	}

	/**
	 * Возвращает наименование атрибута для chip'а в фильтре
	 * @param filter
	 */
	titleFilter(filter: ComplexFilterComplete): string {
		return ComplexFilterHelpers.titleFilter(this.trueData.displayedFields, filter);
	}

	/**
	 * Возвращает критерий для chip'а в фильтре
	 * @param filter
	 */
	valueFilter(filter: ComplexFilterComplete): string {
		const filterField = this.trueData.displayedFields.find(x => x.name === filter.name);
		if (filter.name === 'isFreelance' && !filterField) {
			return '';
		}
		return ComplexFilterHelpers.valueFilter(filter, filterField);
	}

	/**
	 * Находит критерий поиска по имени атрибута
	 * @param filterName
	 */
	filter(filterName: string): ComplexFilterComplete {
		return this.activeFilters.value?.find(x => x.name === filterName) ?? new ComplexFilterComplete();
	}

	/**
	 * Находит источник значений критериев поиска по имени атрибута
	 * @param filterName
	 */
	filterSource(filterName: string): ComplexFilterSource {
		return this.sources
			.find(x => x.name === filterName);
	}

	/**
	 * Удаляет фильтр по указанному атрибуту
	 * @param filterName фильтр
	 */
	removeFilter(filterName: string): void {
		const tmp = this.activeFilters.value;
		this.currentFormGroup.controls[filterName].setValue(null);
		if (this.currentFormGroup.controls[this.secondInputName(filterName)]) {
			this.currentFormGroup.controls[this.secondInputName(filterName)].setValue(null);
		}
		ComplexFilterHelpers.removeFilter(tmp, filterName);
		this.activeFilters.next(tmp);
	}


	/**
	 * Возвращает имя поля для второго инпута в ряду
	 * @param filterName
	 */
	secondInputName(filterName: string): string {
		return 'second' + filterName;
	}

	/**
	 * Является ли tui-input-number пустым
	 */
	isTuiInputEmpty(filterName: string): boolean {
		const input = this.currentFormGroup.controls[filterName];

		return input?.value === null;
	}

	/**
	 * Блокирует ввод символов для даты
	 */
	onDateInput(event: InputEvent) {
		const regex = /[^\d.\/]/g;
		if (event.inputType === 'deleteContentBackward' ||
			event.inputType === 'deleteByCut' ||
			event.inputType === 'deleteContentForward') {
			return true;
		}

		if (event.data.match(regex)) {
			event.preventDefault();
			return false;
		}
		return true;
	}

	/**
	 * Сравнивает number
	 * @param control
	 */
	compareNumber(control: FormGroup) {
		const displayedNumberFields = this.trueData.displayedFields.filter(f => f.inputType === 'budget' || f.inputType === 'rating');

		displayedNumberFields.forEach(f => {
			const firstInput = control.get(f.name);
			const secondInput = control.get(this.secondInputName(f.name));

			if (!(firstInput && secondInput)) {
				return;
			}

			if (control &&
				firstInput.value &&
				firstInput.valid &&
				secondInput.value &&
				secondInput.valid
			) {
				const firstNumber = firstInput.value;
				const secondNumber = secondInput.value;
				if (firstNumber > secondNumber) {
					firstInput.setErrors({ invalidNumber: true });
					secondInput.setErrors({ invalidNumber: true });
				}
			} else {
				if (firstInput.invalid && firstInput.value && secondInput.value && secondInput.valid) {
					firstInput.updateValueAndValidity();
				} else if (firstInput.valid && firstInput.value && secondInput.value && secondInput.invalid) {
					secondInput.updateValueAndValidity();
				}
			}
		});

		return null;
	}

	/**
	 * Сравнивает две даты
	 */
	compareDates(control: FormGroup) {
		const displayedDateRangeFields = this.trueData?.displayedFields.filter(f => f.inputType === 'dateRange');

		displayedDateRangeFields.forEach(f => {
			const firstInput = control.get(f.name);
			const secondInput = control.get(this.secondInputName(f.name));

			if (!(firstInput && secondInput)) {
				return;
			}

			if (control &&
				firstInput.value &&
				firstInput.valid &&
				secondInput.value &&
				secondInput.valid
			) {
				const firstDate = moment.isMoment(firstInput.value)
					? firstInput.value.toDate()
					: new Date(firstInput.value);
				const secondDate = moment.isMoment(secondInput.value)
					? secondInput.value.toDate()
					: new Date(secondInput.value);
				if (firstDate > secondDate) {
					firstInput.setErrors({ invalidDate: true });
					secondInput.setErrors({ invalidDate: true });
				}
			} else {
				if (firstInput.invalid && firstInput.value && secondInput.value && secondInput.valid) {
					firstInput.updateValueAndValidity();
				} else if (firstInput.valid && firstInput.value && secondInput.value && secondInput.invalid) {
					secondInput.updateValueAndValidity();
				}
			}
		});

		return null;
	}

	/**
	 * Создает input для атрибута поиска, а если надо, создает дополнительные input'ы.
	 * Подписывается на их изменения
	 * @param filterField
	 * @param group
	 */
	addControl(filterField: ComplexFilterField, group: FormGroup) {
		switch (filterField.inputType) {
			case 'autocomplete-chips': {
				group.addControl(filterField.name, new FormControl(null));
				if (filterField.children) {
					filterField.children.forEach(control => {
						this.addControl(control, this.currentFormGroup);
					})
				}
				this.subscriptions.push(group.controls[filterField.name].valueChanges
					.pipe(
						takeUntil(this.unsubscribe),
						filter(value => value !== undefined),
						distinctUntilChanged(),
						debounceTime(CHANGE_INPUT_DELAY),
						switchMap(cityModel => {
							if (Array.isArray(cityModel) && cityModel.length) {
								if (typeof cityModel[0] === 'string') {
									return of(cityModel);
								}
								return forkJoin<CityModel[]>(cityModel.map(city => this.filterService.approveCity(city)));
							}
							if (!cityModel) {
								return of(null)
							}
							return this.filterService.approveCity(cityModel)
						})
					).subscribe(value => {
						const tmp = this.activeFilters.value;

						if (value) {
							if (Array.isArray(value)) {
								if (value.length && typeof value[0] === 'object' && 'id' in value[0]) {
									ComplexFilterHelpers.updateFilter(tmp, filterField, value.map(item => item.id));
								}
								if (typeof value[0] === 'string' || typeof value[0] === 'number') {
									ComplexFilterHelpers.updateFilter(tmp, filterField, value);
								}
							} else {
								if (typeof value[0] === 'object' && 'id' in value) {
									ComplexFilterHelpers.updateFilter(tmp, filterField, value.id);
								}

								if (typeof value === 'string' || typeof value === 'number') {
									ComplexFilterHelpers.updateFilter(tmp, filterField, value);
								}
							}
							this.activeFilters.next(tmp);
							return;
						}

						ComplexFilterHelpers.updateFilter(tmp, filterField, null);
						this.activeFilters.next(tmp);
					})
				);
				return;
			}
			case 'checkbox-list': {
				group.addControl(filterField.name, new FormControl(null));
				this.subscriptions.push(group.controls[filterField.name].valueChanges
					.pipe(
						debounceTime(CHANGE_INPUT_DELAY),
						takeUntil(this.unsubscribe)
					)
					.subscribe((value) => {
						if (this.completeInitialization) {
							const tmp = this.activeFilters.value;
							ComplexFilterHelpers.updateFilter(tmp, filterField, this.prepareFilters(filterField.name, value));
							this.activeFilters.next(tmp);
						}
					})
				);
				break;
			}
			case 'autocomplete': {
				group.addControl(filterField.name, new FormControl(null));
				const filterSource = new ComplexFilterSource();
				filterSource.name = filterField.name;
				filterSource.source = group.controls[filterField.name].valueChanges
					.pipe(
						startWith(''),
						switchMap(query => iif(
							() => typeof query === 'string' && query.length > 1,
							filterField.source(query),
							filterField.firstFiftyItems
								.pipe(
									filter(x => x !== undefined),
								)
						)),
						takeUntil(this.unsubscribe)
					);

				this.sources.push(filterSource);
				break;
			}
			case 'autocomplete-without-icon': {
				group.addControl(filterField.name, new FormControl(null));
				const filterSource = new ComplexFilterSource();
				filterSource.name = filterField.name;
				filterSource.source = group.controls[filterField.name].valueChanges
					.pipe(
						takeUntil(this.unsubscribe),
						startWith(''),
						distinctUntilChanged(),
						mergeMap(query => iif(
							() => typeof query === 'string' && query.length > 1,
							filterField.source(query)
								.pipe(
									takeUntil(this.unsubscribe),
								),
							filterField.firstFiftyItems
								.pipe(
									takeUntil(this.unsubscribe),
									filter(x => x !== undefined),
								)
						)),
					);

				this.sources.push(filterSource);
				break;
			}
			case 'main-tag': {
				group.addControl(filterField.name, new FormControl(filterField.defaultValue));
				this.mainTagFilter.next(ComplexFilterHelpers.getMainTagAsFilterComplete(filterField, filterField.defaultValue));
				this.subscriptions.push(group.controls[filterField.name].valueChanges
					.pipe(
						takeUntil(this.unsubscribe),
						distinctUntilChanged(),
						debounceTime(CHANGE_INPUT_DELAY),
					)
					.subscribe((value) => {
						if (this.completeInitialization) {
							const tmp = this.activeFilters.value;
							ComplexFilterHelpers.updateFilter(tmp, filterField, value);
							this.activeFilters.next(tmp);
							this.mainTagFilter.next(ComplexFilterHelpers.getMainTagAsFilterComplete(filterField, value));
						}
					})
				);
				break;
			}
			default: {
				group.addControl(filterField.name, new FormControl(null));
				if (filterField.inputType === 'text' ||
					filterField.inputType === 'select' ||
					filterField.inputType === 'multi-toggle') {
					this.subscriptions.push(group.controls[filterField.name].valueChanges
						.pipe(
							takeUntil(this.unsubscribe),
							distinctUntilChanged(),
							debounceTime(CHANGE_INPUT_DELAY),
						)
						.subscribe((value) => {
							if (this.completeInitialization) {
								const tmp = this.activeFilters.value;
								ComplexFilterHelpers.updateFilter(tmp, filterField, value);
								this.activeFilters.next(tmp);
							}
						})
					);
				}

				if (filterField.inputType === 'dateRange' ||
					filterField.inputType === 'budget' ||
					filterField.inputType === 'rating' ||
					filterField.inputType === 'number') {
					group.addControl(this.secondInputName(filterField.name), new FormControl(null));
					this.subscriptions.push(group.controls[this.secondInputName(filterField.name)].valueChanges
						.pipe(
							distinctUntilChanged(),
							debounceTime(CHANGE_INPUT_DELAY),
							takeUntil(this.unsubscribe),
						)
						.subscribe({
							next: value => {
								let firstInput = group.controls[filterField.name];
								if (this.completeInitialization) {
									const tmp = this.activeFilters.value;
									ComplexFilterHelpers.updateFilter(tmp, filterField, firstInput.value ?? 0, value ?? 0);
									this.activeFilters.next(tmp);
								}
							}
						}));

					this.subscriptions.push(group.controls[filterField.name].valueChanges
						.pipe(
							distinctUntilChanged(),
							debounceTime(CHANGE_INPUT_DELAY),
							takeUntil(this.unsubscribe),
						)
						.subscribe({
							next: value => {
								let secondInput = group.controls[this.secondInputName(filterField.name)];
								if (this.completeInitialization) {
									const tmp = this.activeFilters.value;
									ComplexFilterHelpers.updateFilter(tmp, filterField, value, secondInput.value);
									this.activeFilters.next(tmp);
								}
							}
						})
					);
				}
			}
		}
	}

	/**
	 * Удаляет один элемент из массива критерия поиска
	 * @param filterName атрибут
	 * @param item элемент
	 */
	removeItem(filterName: string, item: any): void {
		const filter = this.filter(filterName);
		if (filter && filter.value
		) {
			const index = filter.value.findIndex(x => x.id === item.id);
			if (index >= 0) {
				filter.value.splice(index, 1);
				const result = this.activeFilters.value;
				result.forEach(item => {
					if (filter.name === item.name && filter.inputType === item.inputType) {
						return filter;
					}
					return item;
				})
				this.activeFilters.next(result);
			}
		}
	}

	/**
	 * Выбирает и добавляет элемент в массив критерия поиска
	 * @param filterField описание атрибута поиска
	 * @param event
	 */
	selectedItem(filterField: ComplexFilterField, event: MatAutocompleteSelectedEvent): void {
		const tmp = this.activeFilters.value;
		const filter = this.filter(filterField.name);
		const value = filter.value ? filter.value : [];
		value.push(event.option.value);
		ComplexFilterHelpers.updateFilter(tmp, filterField, value);
		this.activeFilters.next(tmp);
		// @ts-ignore
		document.getElementById(filterField.name).value = '';
		this.currentFormGroup.controls[filterField.name].setValue('', { onlySelf: true, emitEvent: true });
	}

	// pushArrayValue(value: any)

	/**
	 * Проверяет наличие элемента в массиве критерия поиска
	 * @param filterName атрибут
	 * @param item элемент
	 */
	disabledItem(filterName: string, item: any): boolean {
		const filter = this.filter(filterName);
		if (filter && filter.value) {
			return filter.value.findIndex(x => x.id === item.id) > -1;
		}
		return false;
	}

	/**
	 * Определяет показывать крестик у контрола, для отчистки
	 */
	showClearControlBtn(filterField: ComplexFilterField): boolean {
		const activeFilter = this.activeFilters.value.find(x => x.name === filterField.name);

		return activeFilter && activeFilter.value !== null;

	}

	/**
	 * Очищает конкретное поле
	 */
	resetFormControl(event: Event, filterField: ComplexFilterField): void {
		event.stopPropagation();

		this.currentFormGroup.controls[filterField.name].setValue(null);
	}

	/**
	 * Сбросывает все значения поиска
	 */
	onReset(onAnyFilterClick: boolean = false) {

		this.anyFilter.setValue(null, { emitEvent: false });
		const tmp: ComplexFilterComplete[] = [];
		if (this.mainTagFilter.value) {
			tmp.push(this.mainTagFilter.value);
		}
		this.activeFilters.next(tmp);
		const controls = this.currentFormGroup.controls;

		for (let key in controls) {
			const index = this.trueData.displayedFields.findIndex(x => x.name === key && x.inputType === 'main-tag');
			if (index < 0) {
				controls[key].setValue(null, { emitEvent: false });
			}
		}

		if (onAnyFilterClick && this.anyFilterInput) {
			this.anyFilterInput.nativeElement.focus();
		}

		this.resetEvent.emit();

		this.cdr.markForCheck();
	}

	/**
	 * Очищает все критерии фильтра
	 */
	clearFilters(emit: boolean = true): void {
		this.anyFilter.setValue(null, { emitEvent: false });
		const tmp: ComplexFilterComplete[] = [];
		if (this.mainTagFilter.value) {
			tmp.push(this.mainTagFilter.value);
		}

		this.activeFilters.next(tmp);
		if (emit) {
			this.resultEmit(this.anyFilter.value);
		}
	}

	/**
	 * Вызывает событие завершение ввода критерия фильтра
	 * @param query критерий поиска по всему
	 */
	resultEmit(query: string): void {
		this.result.emit(new ComplexFilterResult(query, this.activeFilters.value));
	}

	fillForm(filters: ComplexFilterComplete[]) {
		if (!filters) {
			return;
		}
		const formControls = this.currentFormGroup.controls;

		filters.forEach(filter => {
			if (filter.name === 'isFreelance') {
				return;
			}

			if (filter.secondInputValue) {
				formControls[this.secondInputName(filter.name)].setValue(filter.secondInputValue, { emitEvent: false });
				formControls[this.secondInputName(filter.name)].markAsTouched();
			}
			if ((!Array.isArray(filter.value) || filter.inputType === 'multi-toggle' || filter.inputType === 'checkbox-list')
				&& formControls[filter.name]) {
				formControls[filter.name].setValue(filter.value, { emitEvent: false });
				formControls[filter.name].markAsTouched();
			}
		});

		for (let item of this.trueData.displayedFields) {
			if (filters.findIndex(x => x.name === item.name) < 0 && item.inputType !== 'main-tag') {
				this.currentFormGroup.controls[item.name]
					.setValue(null, { emitEvent: false });
				if (this.currentFormGroup.controls.hasOwnProperty(this.secondInputName(item.name))) {
					this.currentFormGroup.controls[this.secondInputName(item.name)]
						.setValue(null, { emitEvent: false });
				}
			}

			if (filters.some(x => x.name === item.name) && item.inputType === 'main-tag') {
				this.currentFormGroup.controls[item.name]
					.setValue(this.mainTagFilter.value.value, { emitEvent: false })
			}
		}
	}

	/**
	 * Отписывает подписки при уничтожении компоненты
	 */
	ngOnDestroy(): void {
		this.cdr.reattach();
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.data.previousValue) {
			this.trueData.displayedFields.forEach(filter => {
				this.currentFormGroup.removeControl(filter.name);
			})
			this.sources = [];
			this.filtersWithoutMainTag.next([]);

			this.subscriptions.forEach(sub => sub.unsubscribe());
			this.subscriptions = [];

			changes.data.currentValue?.displayedFields
				.forEach(filter => this.addControl(filter, this.currentFormGroup));

			this.trueData = changes.data.currentValue;

		}
	}

	prepareFilters(filterName: string, values: Array<any>): Array<any> | null | YesNo {

		switch (filterName) {
			case 'workFormatIds': {
				return values.map(item => {
					switch (item) {
						case 'Готов к переезду': {
							return 0;
						}
						case 'В офисе': {
							return 1;
						}
						case 'Удаленная работа': {
							return 2;
						}
						case 'Гибридный график': {
							return 3;
						}
						default:
							return -1;
					}
				})
			}
			case 'workTypeFilters': {
				return values.map(item => {
					switch (item) {
						case 'Полный день': {
							return 1;
						}
						case 'Неполный день': {
							return 2;
						}
						default: {
							return -1;
						}
					}
				})
			}
			case 'isReadyToRelocate': {
				let result: YesNo;
				values.forEach(item => {
					switch (item) {
						case 'Готов к переезду': {
							result = YesNo.yes;
							break;
						}
						default: {
							result = YesNo.no;
							break;
						}
					}
				})
				return result;
			}
			case 'salarySet': {
				let result: YesNo;
				values.forEach(item => {
					switch (item) {
						case 'Указан доход': {
							result = YesNo.yes;
							break;
						}
						default: {
							result = YesNo.no;
							break;
						}
					}
				})
				return result;
			}
			case 'hasReward': {
				let result;
				values.forEach(item => {
					switch (item) {
						case 'Указан доход': {
							result = true;
							break;
						}
						default: {
							result = false;
							break;
						}
					}
				})
				return result;
			}
			default: {
				return values;
			}
		}
	}

	clearCityId() {
		this.activeFilters.value.filter(item => item.name === 'cityId')[0].value = 0;
	}
}
