import { Injectable } from '@angular/core';
import { AbstractModelService } from '@core/services/abstract-model.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WorkMarkView } from '@models/work-mark-view';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class WorkMarkService {
	private workMarksUrl = 'api/WorkMarks';

	constructor(private http: HttpClient, private modelService: AbstractModelService) {

	}

	/**
	 * Преобразование string в Date после десериализации
	 * @param workMarkView работа
	 */
	public static parseDates(workMarkView: WorkMarkView): WorkMarkView {
		workMarkView.createTime = new Date(workMarkView.createTime);
		workMarkView.modifyTime = new Date(workMarkView.modifyTime);
		return workMarkView;
	}

	/**
	 * Проверяет, может ли пользователь оценивать указанную работу
	 * @param id идентификатор работы
	 */
	canAssign(id: number): Observable<boolean> {
		const url = `${this.workMarksUrl}/CanAssign/${id}`;
		return this.http.get<boolean>(url, this.modelService.httpOptions);
	}

	/**
	 * Возвращает оценку для указанной работы
	 * @param id идентификатор работы
	 */
	one(id: number): Observable<WorkMarkView> {
		const url = `${this.workMarksUrl}/One/${id}`;
		return this.http.get<WorkMarkView>(url, this.modelService.httpOptions)
			.pipe(
				map(x => {
					if (x) {
						return WorkMarkService.parseDates(x);
					}
					return x;
				}),
			);
	}

	/**
	 * Добавление или изменение оценки работы
	 * @param workMarks оценка работы
	 */
	assign(workMarks: WorkMarkView): Observable<WorkMarkView> {
		return this.http.post<WorkMarkView>(this.workMarksUrl, workMarks, this.modelService.httpOptions)
			.pipe(
				tap(x => {
					if (x.hasOwnProperty('error')) {
						throw x;
					}
				})
			);
	}

	/**
	 * Возвращает список оценок компании
	 * @param id идентификтор компании
	 * @param pageIndex
	 * @param pageSize
	 */
	ofCompany(id: number, pageIndex = 0, pageSize = 0): Observable<WorkMarkView[]> {
		const url = `${this.workMarksUrl}/OfCompany/${id}`;
		return this.http.post<WorkMarkView[]>(url, null, {
				params: new HttpParams()
					.set('pageIndex', pageIndex.toString())
					.set('pageSize', pageSize.toString()),
				headers: this.modelService.httpOptions.headers
			})
			.pipe(
				map(xx => xx.map(x => WorkMarkService.parseDates(x)))
			);
	}

	ofCompanyCount(id: number): Observable<number> {
		const url = `${this.workMarksUrl}/OfCompanyCount/${id}`;
		return this.http.post<number>(url, null, this.modelService.httpOptions);
	}
}
