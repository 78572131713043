import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	TemplateRef, ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarModule } from '@shared/pipes/avatar/avatar.module';
import { SharedModule } from '@shared/shared.module';
import { ImageAvatarSize } from '@shared/components/ui/image-avatar/image-avatar.types';

@Component({
	selector: 'pdw-image-avatar',
	templateUrl: './image-avatar.component.html',
	styleUrls: ['./image-avatar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageAvatarComponent {
	@Input() avatarSrc: string = null;
	@Input() size: ImageAvatarSize = ImageAvatarSize.NONE;
	@Input() isDisplayed: boolean = false;
	@Input() isNotRequest: boolean = false;
}
