import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationsRequestService } from '@core/services/notifications-request.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { errorTitle } from '@utils/helpers/error-helpers';
import { WorkExecutorMarkService } from '@core/services/work-executor-mark.service';
import { takeUntil } from 'rxjs/operators';
import { WorkView } from '@models/work-view';
import { AppUserView } from '@models/app-user-view';
import { WorkRequestState } from '@models/work-request-view';
import { pdIconPath } from '@utils/functions';
import { TUI_ICONS_PATH } from '@taiga-ui/core';
import { AppUserService } from '@core/services/app-user.service';
import { WorkExecutorMarkView } from '@models/work-executor-mark-view';
import { DestroyService, NotificationService } from '@profdepo-ui/core';

export interface WorkMarksManagerDialogData {
	title: string;
	workView: WorkView;
}

@Component({
	selector: 'app-work-marks-specialist-manager-dialog',
	templateUrl: './work-marks-manager-dialog.component.html',
	styleUrls: ['./work-marks-manager-dialog.component.scss'],
	providers: [
		{
			provide: TUI_ICONS_PATH,
			useValue: pdIconPath,
		},
		DestroyService
	],
})
export class WorkMarksManagerDialogComponent implements OnInit {
	workExecutorMarkViewForm: FormGroup;
	saving = new BehaviorSubject<boolean>(false);
	isFormSubmitted = false;
	ratingColorClass: (value: number) => string = AppUserService.ratingColorClass;

	constructor(
		public dialogRef: MatDialogRef<WorkMarksManagerDialogData, boolean | WorkExecutorMarkView>,
		@Inject(MAT_DIALOG_DATA) public data: WorkMarksManagerDialogData,
		private formBuilder: FormBuilder,
		private workExecutorMarkService: WorkExecutorMarkService,
		private notificationService: NotificationService,
		private destroy$: DestroyService
	) {
	}

	ngOnInit(): void {
		this.workExecutorMarkService.one(this.data.workView.id)
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: (workExecutorMarkView: WorkExecutorMarkView) => {
					this.workExecutorMarkViewForm = this.formBuilder.group({
						qualityValue: new FormControl(workExecutorMarkView?.qualityValue, {
							validators: [Validators.required]
						}),
						speedValue: new FormControl(workExecutorMarkView?.speedValue, {
							validators: [Validators.required]
						}),
						experienceValue: new FormControl(workExecutorMarkView?.experienceValue, {
							validators: [Validators.required]
						}),
						comment: new FormControl(workExecutorMarkView?.comment),
					});
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	get executor(): AppUserView {
		return this.data.workView.requests.find(x => x?.executor && x?.state === WorkRequestState.accepted).executor;
	}

	get qualityValue(): FormControl {
		return this.workExecutorMarkViewForm.get('qualityValue') as FormControl;
	}

	get speedValue(): FormControl {
		return this.workExecutorMarkViewForm.get('speedValue') as FormControl;
	}

	get experienceValue(): FormControl {
		return this.workExecutorMarkViewForm.get('experienceValue') as FormControl;
	}

	get comment(): FormControl {
		return this.workExecutorMarkViewForm.get('comment') as FormControl;
	}

	get hasRatingErrors(): boolean {
		return (this.qualityValue.invalid || this.speedValue.invalid || this.experienceValue.invalid) && this.isFormSubmitted;
	}

	onSubmit({ value, valid }): void {
		this.isFormSubmitted = true;
		if (valid) {
			this.saving.next(true);
			const workExecutorMarkView = new WorkExecutorMarkView();
			workExecutorMarkView.work = this.data.workView;
			workExecutorMarkView.qualityValue = this.qualityValue.value;
			workExecutorMarkView.speedValue = this.speedValue.value;
			workExecutorMarkView.experienceValue = this.experienceValue.value;
			workExecutorMarkView.comment = this.comment.value;
			this.workExecutorMarkService.assign(workExecutorMarkView)
				.pipe(
					takeUntil(this.destroy$)
				)
				.subscribe({
					next: (workExecutorMarkView: WorkExecutorMarkView) => {
						this.saving.next(false);
						this.dialogRef.close(workExecutorMarkView);
					},
					error: (err) => {
						this.saving.next(false);
						this.notificationService.showDanger(errorTitle(err));
					}
				});
		}
	}

}
