import { Injectable } from '@angular/core';
import { AppUserService } from '@core/services/app-user.service';
import { AppUserType } from '@models/app-user-view';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class RoleService {
	roleChangeEvent$ = new Subject<AppUserType>();

	constructor(private userService: AppUserService) {
	}


	hasPermission(permission: AppUserType | AppUserType[]): boolean {

		if (Array.isArray(permission)) {
			if (permission.includes(AppUserType.none) && !this.currentRole) {
				return true;
			}
			return permission.includes(this.userService.getCurrentActiveUserView()?.type);
		}

		if (permission === AppUserType.none && !this.currentRole) {
			return true;
		}

		return this.currentRole === permission;
	}

	get currentRole() {
		return this.userService.getCurrentActiveUserView()?.type;
	}

	getCurrentRoleAsObservable(): Observable<AppUserType> {
		return this.userService.getActiveUserAsObservable().pipe(map(item => item?.type));
	}
}
