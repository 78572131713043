import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, iif, Observable, Subject, switchMap } from 'rxjs';
import { CompanyView } from '@models/company-view';
import { filter } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { MatDialog } from '@angular/material/dialog';
import { CompanyService } from '@core/services/company.service';
import { pushFakeHistoryState } from '@utils/functions';
import { NotificationService } from '@profdepo-ui/core';
import { AppUserAvatarDialogComponent } from '@shared/app-user-avatar-dialog/app-user-avatar-dialog.component';

@Component({
	selector: 'pdw-company-logo',
	templateUrl: './company-logo.component.html',
	styleUrls: ['./company-logo.component.scss']
})
export class CompanyLogoComponent implements OnInit, OnDestroy {
	loading: boolean;
	@Input() canUpdate: boolean;

	@Input()
	set companyView(value: CompanyView) {
		this._companyView.next(value);
	};

	get companyView(): CompanyView {
		return this._companyView.getValue();
	}

	private _companyView = new BehaviorSubject<CompanyView>(null);
	currentCompanyView = new BehaviorSubject<CompanyView>(null);
	@Output() changed = new EventEmitter<any>();
	unsubscribe: Subject<any> = new Subject<any>();
	timeForLogo: Date = new Date();
	showEditBtn = false

	constructor(
		private companyService: CompanyService,
		private dialog: MatDialog,
		private notificationService: NotificationService,
	) {
	}

	ngOnInit(): void {

		this._companyView
			.pipe(
				filter(x => x !== null),
			)
			.subscribe((companyView: CompanyView) => {
				this.currentCompanyView.next(companyView);
			});
	}

	onLoad() {
		this.loading = false;
	}

	goUpdateLogo(): void {
		pushFakeHistoryState();
		this.dialog.open(AppUserAvatarDialogComponent, {
				autoFocus: false,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Логотип компании',
					method: 'update',
					companyView: this.currentCompanyView.value
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
				switchMap(result => iif(() => result?.method === 'update',
					this.companyService.updateLogo(this._companyView.value.id, result?.imageRequest),
					this.companyService.deleteLogo(this._companyView.value.id)
				)))
			.subscribe({
				next: (data) => {
					this.changed.emit('logo');
					this.timeForLogo = new Date();
					this.loading = true;
					this._companyView.next(data)
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	companyImage(id: number): Observable<any> {
		return this.companyService.original(id)
	}

	ngOnDestroy() {
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}

	setShowEditBtn() {
		this.showEditBtn = true;
	}

	setHideEditBtn() {
		this.showEditBtn = false;
	}
}
