import {Pipe, PipeTransform} from '@angular/core';
import {AppUserWorkState} from "@models/app-user-view";
import {AppUserService} from "@core/services/app-user.service";

@Pipe({
  name: 'appUserWorkState'
})
export class AppUserWorkStatePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (AppUserService.hasAppUserWorkState(value, AppUserWorkState.inWork)) {
      return 'Работает';
    }
    if (AppUserService.hasAppUserWorkState(value, AppUserWorkState.invited) &&
      AppUserService.hasAppUserWorkState(value, AppUserWorkState.requested)){
      return 'Приглашен/Откликнулся'
    }
    if (AppUserService.hasAppUserWorkState(value, AppUserWorkState.invited)) {
      return 'Приглашен';
    }
    if (AppUserService.hasAppUserWorkState(value, AppUserWorkState.requested)) {
      return 'Откликнулся';
    }
    if (AppUserService.hasAppUserWorkState(value, AppUserWorkState.inSearch)) {
      return 'В поиске';
    }
  }
}
