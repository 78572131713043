import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'auth'
})
export class AuthPipe extends AbstractModelPipe implements PipeTransform {
  properties = [
    {name: 'login', title: 'Вход', hint: 'Вход'},
    {name: 'userName', title: 'Имя пользователя или эл. почта', hint: 'Введите имя пользователя или адрес эл. почты, пожалуйста'},
    {name: 'password', title: 'Пароль', hint: 'Введите пароль, пожалуйста'},
    {name: 'passwordConfirm', title: 'Подтверждение пароля', hint: 'Введите пароль еще раз, пожалуйста'},
    {name: 'passwordHide', title: 'Скрыть пароль', hint: 'Скрыть пароль'},
    {name: 'email', title: 'Эл. почта', hint: 'Адрес эл. почтового ящика'},
    {name: 'phoneNumber', title: 'Телефон', hint: 'Номер телефона'},
    {name: 'type', title: 'Тип пользователя', hint: 'Укажите тип пользователя'},
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
}
