<ng-container *ngIf="authService.isLoggedIn(); else noAuthCompanyDetails">
	<ng-container *ngIf='currentCompanyView | async as currentCompanyView'>
		<div class='pd-company'>
			<div class='pd-company-row-gap'>
				<div class='pd-company-brief'>
					<pdw-company-logo
						[companyView]='currentCompanyView'
						[canUpdate]='canUpdate$ | async'
						(changed)='onChanged($event)'
					>
					</pdw-company-logo>
					<div>
						<pdw-company-legal-details
							class='pd-company-legal-details'
							[companyView]='currentCompanyView'
							[canUpdate]='canUpdate$ | async'
							(changed)='onChanged($event)'
						>
						</pdw-company-legal-details>
						<div class='pd-company-subscribe-btn-desktop' *ngIf='canSubscribe(currentCompanyView) | async'>
							<button
								*ngIf='(subscribed$ | async) === 0'
								mat-raised-button
								class='pd-button-secondary button-container'
								(click)='goSubscribe($event, currentCompanyView)'
								>
								<mat-icon
									class='pd-icon'
									svgIcon='additional-bookmark'
								></mat-icon>
								Добавить в избранное
							</button>
							<button
								*ngIf='(subscribed$ | async) === 1'
								mat-raised-button
								class='pd-button button-container'
								(click)='goUnsubscribe($event, currentCompanyView)'
								>
								<mat-icon
									class='pd-icon'
									svgIcon='additional-bookmark'
								></mat-icon>
								Убрать из избранного
							</button>
						</div>
						<div class='pd-company-subscribe-btn' *ngIf='canSubscribe(currentCompanyView) | async'>
							<button
								mat-icon-button
								class='pd-icon-button pd-card-bookmark'
								(click)='goSubscribe($event, currentCompanyView)'
								*ngIf='(subscribed$ | async) === 0'
								[matTooltip]="'Добавить в избранное'"
							>
								<mat-icon
									class='pd-icon'
									svgIcon='additional-bookmark'
								></mat-icon>
							</button>
							<button
								mat-icon-button
								class='pd-icon-button pd-card-bookmark-favorite'
								(click)='goUnsubscribe($event, currentCompanyView)'
								[matTooltip]="'Убрать из избранного'"
								*ngIf='(subscribed$ | async) === 1'
							>
								<mat-icon
									class='pd-icon'
									svgIcon='additional-bookmark'
								></mat-icon>
							</button>
						</div>
					</div>
				</div>
				<div class='pd-company-rating'>
					<app-company-rating [companyView]='currentCompanyView'>
					</app-company-rating>
				</div>
			</div>
			<div
				[class.info-container-column]='moreThan1500Symbols(currentCompanyView)'
				class='info-container'
			>
				<pdw-company-description
					[class.info-full-width]='moreThan1500Symbols(currentCompanyView)'
					class='pd-company-description'
					[companyView]='currentCompanyView'
					[canUpdate]='canUpdate$ | async'
					(changed)='onChanged($event)'
				>
				</pdw-company-description>
				<pdw-company-industries
					class='pd-company-industries'
					[class.info-full-width]='moreThan1500Symbols(currentCompanyView)'
					[companyView]='currentCompanyView'
					[canUpdate]='canUpdate$ | async'
					(changed)='onChanged($event)'
				>
				</pdw-company-industries>
			</div>
			<app-company-works-brief
				class='pd-company-works-brief'
				[canUpdate]='canUpdate$ | async'
				[companyView]='currentCompanyView'
			>
			</app-company-works-brief>
			<pdw-company-marks
				class='pd-company-marks'
				[companyView]='currentCompanyView'
			>
			</pdw-company-marks>
		</div>
	</ng-container>
</ng-container>
<ng-template #noAuthCompanyDetails>
	<ng-container *ngIf='currentCompanyView | async as currentCompanyView'>
		<div class='pd-company'>
			<div class='pd-company-row-gap'>
				<div class='pd-company-brief'>
					<pdw-company-logo
						[companyView]='currentCompanyView'
						[canUpdate]='canUpdate$ | async'
						(changed)='onChanged($event)'
					>
					</pdw-company-logo>
					<div>
						<pdw-company-legal-details
							class='pd-company-legal-details'
							[companyView]='currentCompanyView'
							[canUpdate]='canUpdate$ | async'
							(changed)='onChanged($event)'
						>
						</pdw-company-legal-details>
						<div class='pd-company-subscribe-btn-desktop'>
							<button
								*ngIf='(subscribed$ | async) === 0'
								mat-raised-button
								class='pd-button-secondary button-container'
								(click)='goSubscribeWithoutAuth($event, currentCompanyView)'
								>
								<mat-icon
									class='pd-icon'
									svgIcon='additional-bookmark'
								></mat-icon>
								Добавить в избранное
							</button>
							<button
								*ngIf='(subscribed$ | async) === 1'
								mat-raised-button
								class='pd-button button-container'
								(click)='goUnsubscribeWithoutAuth($event, currentCompanyView)'
								>
								<mat-icon
									class='pd-icon'
									svgIcon='additional-bookmark'
								></mat-icon>
								Убрать из избранного
							</button>
						</div>
						<div class='pd-company-subscribe-btn'>
							<button
								mat-icon-button
								class='pd-icon-button pd-card-bookmark'
								(click)='goSubscribeWithoutAuth($event, currentCompanyView)'
								*ngIf='(subscribed$ | async) === 0'
								[matTooltip]="'Добавить в избранное'"
							>
								<mat-icon
									class='pd-icon'
									svgIcon='additional-bookmark'
								></mat-icon>
							</button>
							<button
								mat-icon-button
								class='pd-icon-button pd-card-bookmark-favorite'
								(click)='goUnsubscribeWithoutAuth($event, currentCompanyView)'
								[matTooltip]="'Убрать из избранного'"
								*ngIf='(subscribed$ | async) === 1'
							>
								<mat-icon
									class='pd-icon'
									svgIcon='additional-bookmark'
								></mat-icon>
							</button>
						</div>
					</div>
				</div>
				<div class='pd-company-rating'>
					<app-company-rating [companyView]='currentCompanyView'>
					</app-company-rating>
				</div>
			</div>
			<div
				[class.info-container-column]='moreThan1500Symbols(currentCompanyView)'
				class='info-container'
			>
				<pdw-company-description
					[class.info-full-width]='moreThan1500Symbols(currentCompanyView)'
					class='pd-company-description'
					[companyView]='currentCompanyView'
					[canUpdate]='canUpdate$ | async'
					(changed)='onChanged($event)'
				>
				</pdw-company-description>
				<pdw-company-industries
					class='pd-company-industries'
					[class.info-full-width]='moreThan1500Symbols(currentCompanyView)'
					[companyView]='currentCompanyView'
					[canUpdate]='canUpdate$ | async'
					(changed)='onChanged($event)'
				>
				</pdw-company-industries>
			</div>
			<app-company-works-brief
				class='pd-company-works-brief'
				[canUpdate]='canUpdate$ | async'
				[companyView]='currentCompanyView'
			>
			</app-company-works-brief>
			<pdw-company-marks
				class='pd-company-marks'
				[companyView]='currentCompanyView'
			>
			</pdw-company-marks>
		</div>
	</ng-container>
</ng-template>
