import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppUserView } from '@models/app-user-view';
import { AppUserService } from '@core/services/app-user.service';
import {ImageAvatarSize} from "@shared/components/ui/image-avatar/image-avatar.types";

@Component({
  selector: 'pdw-user-profile-link',
  templateUrl: './user-profile-link.component.html',
  styleUrls: ['./user-profile-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileLinkComponent {
	@Input() user!: AppUserView;

	avatarColor(user: AppUserView): string {
		return AppUserService.avatarColor(user);
	}

    protected readonly IMAGE_SIZE = ImageAvatarSize;
}
