<form
	class='registration-form'
	[formGroup]='formGroup'
>
	<div class='registration-form-title'>
		Укажите свою почту и придумайте пароль, чтобы получить результат
	</div>
	<mat-form-field
		class='pd-input-form-field custom-form-field'
		appearance='fill'
		hideRequiredMarker
	>
		<mat-icon
			class='pd-field-prefix-icon'
			svgIcon='additional-user-1'
			matPrefix
		></mat-icon>

		<mat-label>Почта</mat-label>
		<input
			matInput
			formControlName='email'
		/>
		<mat-error
			*ngIf='email?.errors?.required'
			i18n='mat-error@@register-email-required'
		>Обязательное поле
		</mat-error>
		<mat-error
			*ngIf='email?.errors?.pattern'
		>Email введен не корректно!
		</mat-error>
	</mat-form-field>
	<pdw-password-input
		[displayEyeButton]='true'
		formControlName='password'
	>
		<mat-icon
			class='pd-field-prefix-icon'
			svgIcon='additional-shield-done'
			matPrefix
		></mat-icon>

		<mat-label>Пароль</mat-label>
		<mat-error
			*ngIf='password?.errors?.required'
			i18n='mat-error@@register-password-required'
		>Обязательное поле
		</mat-error>
		<mat-error
			*ngIf='password?.errors?.pattern'
			i18n='mat-error@@register-password-pattern'
		>
			Пароль должен быть не менее 8 символов и содержать цифры и буквы большого и малого регистра
		</mat-error>
		<mat-error
			*ngIf='password?.errors?.maxlength'
		>Введите не более 20 символов!
		</mat-error>
		<mat-error
			*ngIf="formGroup?.hasError('notSame')"
			i18n='mat-error@@register-passwordConfirm-notSame'
		>Указанные пароли не совпадают!
		</mat-error>
	</pdw-password-input>

	<pdw-password-input
		[displayEyeButton]='true'
		formControlName='passwordConfirm'
	>
		<mat-icon
			class='pd-field-prefix-icon'
			svgIcon='additional-shield-done'
			matPrefix
		></mat-icon>

		<mat-label>Повторите пароль</mat-label>

		<mat-error
			*ngIf='passwordConfirm.errors?.required'
			i18n='mat-error@@register-password-required'
		>Обязательное поле
		</mat-error>
		<mat-error
			*ngIf="formGroup?.hasError('notSame')"
			i18n='mat-error@@register-passwordConfirm-notSame'
		>Указанные пароли не совпадают!
		</mat-error>
	</pdw-password-input>
</form>
