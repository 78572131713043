<div class="pd-dialog-header">
	<p class="pd-dialog-header-title">{{ data.title }}</p>
	<button class="pd-icon-button" mat-icon-button [mat-dialog-close]="false">
		<mat-icon svgIcon="interface-close"></mat-icon>
	</button>
</div>
<tui-scrollbar class="pd-scrollbar pd-dialog-scroll">
	<div class="pd-dialog-content large-padding">
		<div *ngIf="form">
			<form id="personalForm" [formGroup]="form" (ngSubmit)="onSubmit(form)">
				<div class="pd-dialog-column">
					<!--  Lastname   -->
					<mat-form-field class="pd-input-form-field" appearance="fill" hideRequiredMarker>
						<mat-label>{{ lastname | appUser : "propertyTitle" }}</mat-label>
						<input matInput formControlName="lastname" type="text" />
						<mat-error
							*ngIf="lastname?.errors?.required"
							>Обязательное поле</mat-error
						>
						<mat-error
							*ngIf="lastname.errors?.maxlength"
							>Введите максимум 40 символов!</mat-error
						>
						<mat-error
							*ngIf="lastname.errors?.pattern"
							>Недопустимые символы!</mat-error
						>
						<mat-error
							*ngIf="lastname.errors?.whitespace"
							>Введите символы!</mat-error
						>
						<mat-error *ngIf="lastname.errors?.serverError">
							{{ lastname.errors?.serverError }}
						</mat-error>
					</mat-form-field>
					<!--  Firstname   -->
					<mat-form-field class="pd-input-form-field" appearance="fill" hideRequiredMarker>
						<mat-label>{{ firstname | appUser : "propertyTitle" }}</mat-label>
						<input matInput formControlName="firstname" type="text" />
						<mat-error
							*ngIf="firstname?.errors?.required"
							>Обязательное поле</mat-error
						>
						<mat-error
							*ngIf="firstname.errors?.maxlength"
							>Введите максимум 40 символов!</mat-error
						>
						<mat-error
							*ngIf="firstname.errors?.pattern"
							>Недопустимые символы!</mat-error
						>
						<mat-error
							*ngIf="firstname.errors?.whitespace"
							>Введите символы!</mat-error
						>
						<mat-error *ngIf="firstname.errors?.serverError">
							{{ firstname.errors?.serverError }}
						</mat-error>
					</mat-form-field>

					<!--  Middlename   -->
					<mat-form-field class="pd-input-form-field" appearance="fill" hideRequiredMarker>
						<mat-label>{{ middlename | appUser : "propertyTitle" }}</mat-label>
						<input matInput formControlName="middlename" type="text" />
						<mat-error
							*ngIf="middlename.errors?.pattern"
							>Недопустимые символы!</mat-error
						>
						<mat-error
							*ngIf="middlename.errors?.whitespace"
							>Введите символы!</mat-error
						>
						<mat-error *ngIf="middlename.errors?.serverError">
							{{ middlename.errors?.serverError }}
						</mat-error>
					</mat-form-field>
					<!--  Birthday Date   -->
					<mat-form-field class="pd-input-form-field" appearance="fill" hideRequiredMarker>
						<mat-label>{{ birthday | appUser : "propertyTitle" }}</mat-label>
						<input matInput formControlName="birthday" [matDatepicker]="birthdayPicker" />
						<mat-datepicker-toggle class="pd-datepicker-toggle" matSuffix [for]="birthdayPicker">
							<mat-icon svgIcon="arrow-down-2" matDatepickerToggleIcon></mat-icon>
						</mat-datepicker-toggle>
						<mat-datepicker
							[panelClass]="'pd-calendar'"
							#birthdayPicker
							[calendarHeaderComponent]="calendarHeader"
						></mat-datepicker>
						<mat-error
							*ngIf="birthday?.errors?.comingOfAge"
						>
							Кажется, ошибка: возраст менее 18 лет.
						</mat-error>
						<mat-error
							*ngIf="birthday?.errors?.required"
							>Обязательное поле</mat-error
						>
						<mat-error *ngIf="birthday?.errors?.serverError">
							{{ birthday?.errors?.serverError }}
						</mat-error>
					</mat-form-field>

					<!--  Passport Number   -->
					<mat-form-field class="pd-input-form-field" appearance="fill" hideRequiredMarker>
						<mat-label>{{ passportNumber | appUser : "propertyTitle" }}</mat-label>
						<input matInput type="text" formControlName="passportNumber" [maskito]="maskitoOptions" />
						<mat-error
							*ngIf="passportNumber?.errors?.pattern"
							>Некорректное значение</mat-error
						>
						<mat-error
							*ngIf="passportNumber?.errors?.required"
							>Обязательное поле</mat-error
						>
						<mat-error *ngIf="passportNumber?.errors?.serverError">
							{{ passportNumber?.errors?.serverError }}
						</mat-error>
					</mat-form-field>

					<!--  Passport Date   -->
					<mat-form-field class="pd-input-form-field" appearance="fill" hideRequiredMarker>
						<mat-label>{{ passportDay | appUser : "propertyTitle" }}</mat-label>
						<input matInput formControlName="passportDay" [matDatepicker]="passportdayPicker" />
						<mat-datepicker-toggle class="pd-datepicker-toggle" matSuffix [for]="passportdayPicker">
							<mat-icon svgIcon="arrow-down-2" matDatepickerToggleIcon></mat-icon>
						</mat-datepicker-toggle>
						<mat-datepicker
							[panelClass]="'pd-calendar'"
							#passportdayPicker
							[calendarHeaderComponent]="calendarHeader"
						></mat-datepicker>
						<mat-error
							*ngIf="passportDay?.errors?.required"
							>Обязательное поле</mat-error
						>
						<mat-error *ngIf="passportDay?.errors?.serverError">
							{{ passportDay?.errors?.serverError }}
						</mat-error>
					</mat-form-field>
				</div>
			</form>
		</div>
		<ng-container *ngIf="isInnVerificationError">
			<pdw-alert [alertType]="'danger'" [canClose]="false">
				Информация об ИНН не найдена. Введите корректные данные
			</pdw-alert>
		</ng-container>
	</div>
</tui-scrollbar>
<div class="pd-dialog-footer align-end">
	<div class="pd-dialog-footer-buttons" *ngIf="form">
		<button form="personalForm" mat-raised-button class="pd-button large" type="submit" [disabled]="saving | async">
			Далее
		</button>
	</div>
</div>
