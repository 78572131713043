import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'workExecutorRequest'
})
export class WorkExecutorRequestPipe extends AbstractModelPipe implements PipeTransform {
  properties = [
    {name: 'title', title: 'Наименование', hint: 'Наименование'},
    {name: 'workExecutor', title: 'Исполнитель', hint: 'Исполнитель'},
    {name: 'executor', title: 'Специалист', hint: 'Специалист'},
    {name: 'state', title: 'Состояние', hint: 'Наименование работы'},
    {name: 'direction', title: 'Направление', hint: 'Направление заявки'},
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
  allProperties() {
    return this.properties;
  }
}
