import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'address'
})
export class AddressPipe extends AbstractModelPipe implements PipeTransform {
  properties = [
    {name: 'postcode', title: 'Индекс', hint: 'Индекс'},
    {name: 'country', title: 'Страна', hint: 'Страна'},
    {name: 'region', title: 'Регион', hint: 'Регион'},
    {name: 'city', title: 'Город, например, г. Москва', hint: 'Город'},
    {name: 'street', title: 'Полный адрес', hint: 'Полный адрес'},
    {name: 'legal', title: 'Юридический адрес', hint: 'Юридический адрес'},
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
  allProperties() {
    return this.properties;
  }
}
