import {Pipe, PipeTransform} from '@angular/core';
import {AbstractEnumPipe} from "@public/pipes/abstract-enum.pipe";

@Pipe({
  name: 'appUserType'
})
export class AppUserTypePipe extends AbstractEnumPipe implements PipeTransform {
  values = [
    'Администратор',
    'Специалист',
    'Заказчик'
  ];

  transform(value: any, ...args: any[]): any {
    return this.getTransform(value, args);
  }
}
