import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'pdw-dialog-body',
	templateUrl: './dialog-body.component.html',
	styleUrls: ['./dialog-body.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class DialogBodyComponent {

}
