import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { ReadNotificationDataSource } from '@core/dataSources/read-notification-data-source';
import { NotificationsRequestService } from '@core/services/notifications-request.service';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { NotificationCard, NotificationView } from '@models/notification-view';
import { Grouping, MatGroupBy } from '@public/components/mat-group-by';
import { MatTableDataSource } from '@public/components/mat-table-data-source';
import { DatePipe } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { AppUserService } from '@core/services/app-user.service';
import { AppUserView } from '@models/app-user-view';
import { TrueLoadingService } from '@core/services/true-loading.service';
import { DestroyService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-notifications-chronology',
	templateUrl: './notifications-chronology.component.html',
	providers: [TrueLoadingService, DestroyService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsChronologyComponent implements OnInit {
	user$: Observable<AppUserView>;
	groupBy = new MatGroupBy();
	readDataSource = new ReadNotificationDataSource(this.notificationService);
	dataSource: MatTableDataSource<NotificationView>;
	datePipe: DatePipe;
	pageIndex = 0;
	pageSize = 10;
	remain = new BehaviorSubject<number>(null);

	constructor(
		private userService: AppUserService,
		private notificationService: NotificationsRequestService,
		@Inject(LOCALE_ID) public locale: string,
		private destroy$: DestroyService,
		public loading$: TrueLoadingService
	) {
		this.datePipe = new DatePipe(this.locale);
		this.user$ = userService.getActiveUserAsObservable();
	}

	@ViewChild(MatSort, { static: false }) sort: MatSort;

	ngOnInit(): void {
		forkJoin([
			this.readDataSource.load(this.pageIndex, this.pageSize),
			this.readDataSource.loadCount()
		])
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe(data => {
				const remain = data[1] - (this.pageIndex + 1) * this.pageSize;
				this.remain.next(remain > this.pageSize ? this.pageSize : remain);
				this.loading$.next(false);
			});

		this.readDataSource.data
			.pipe(
				filter(x => x.length !== 0),
				distinctUntilChanged(),
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: (data) => {
					this.dataSource = new MatTableDataSource(data);
					this.groupBy.grouping = new Grouping([{
						name: 'createtime',
						sort: 'desc',
					}]);
					this.groupBy.grouping.groupDataAccessor = (group) => {
						return this.datePipe.transform(group.value, 'MM-YYYY');
					}
					this.groupBy.grouping.groupingDataAccessor = (item, column) => {
						return this.datePipe.transform(item[column.name], 'MM-YYYY');
					}
					this.dataSource.groupBy = this.groupBy;
				}
			});
	}

	goNextPage(): void {
		this.pageIndex++;
		this.loading$.next(true);
		forkJoin([
			this.readDataSource.loadMore(this.pageIndex, this.pageSize),
			this.readDataSource.loadCount()
		])
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe(data => {
				const remain = data[1] - (this.pageIndex + 1) * this.pageSize;
				this.remain.next(remain > this.pageSize ? this.pageSize : remain);
				this.loading$.next(false);
			});
	}

	toCard(user: AppUserView, notificationView: NotificationView): NotificationCard {
		return NotificationCard.card(user.type, notificationView);
	}
}
