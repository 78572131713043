<div class='pd-snackbar-container'>
	<p class='pd-snackbar-text'>
		<ng-content></ng-content>
	</p>
	<ng-container *ngIf='canClose'>
		<button
			type='button'
			class='pd-icon-button pd-icon-button-snackbar'
			mat-icon-button
			(click)='close()'
		>
			<mat-icon svgIcon='interface-close-square-angle'></mat-icon>
		</button>
	</ng-container>
</div>
