import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DATE_01011900_MS } from "@utils/constants";
import { dateNotEarlierValidator, dateNotLaterValidator } from "@utils/functions";
import { CustomValidators } from "@utils/validators/custom-validators";

function createFormGroup(regex: string, emailRegex: string): Record<string, FormControl> {
	return {
		email: new FormControl<string>(null, {
			validators: [Validators.required, Validators.pattern(emailRegex)],
		}),
		birthday: new FormControl<string>(null, [
			Validators.required,
			CustomValidators.comingOfAgeValidator(),
			dateNotEarlierValidator(DATE_01011900_MS),
			dateNotLaterValidator(new Date().getTime()),
		]),
		password: new FormControl<string>(null, {
			validators: [Validators.required, Validators.maxLength(20), Validators.pattern(regex)],
		}),
		passwordConfirm: new FormControl<string>(null, {
			validators: [Validators.required],
		}),
	};
}
export class CustomerAndSpecialistRegisterForm extends FormGroup {
	constructor(public regex: string, public emailRegex: string) {
		super(createFormGroup(regex, emailRegex));
		this.addPasswordValidators();
	}
	public get email(): FormControl {
		return this.get("email") as FormControl;
	}
	public get birthday(): FormControl {
		return this.get("birthday") as FormControl;
	}

	public get password(): FormControl {
		return this.get("password") as FormControl;
	}


	public get passwordConfirm(): FormControl {
		return this.get("passwordConfirm") as FormControl;
	}

	private addPasswordValidators(): void {
		this.get("passwordConfirm").addValidators(
			CustomValidators.confirmPassword(
				this.get("password") as FormControl,
				this.get("passwordConfirm") as FormControl
			)
		);
	}
}
