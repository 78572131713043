import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginRequest } from '@requests/login-request';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Tokens } from '@requests/tokens';
import { CompanyService } from '@core/services/company.service';
import { AppUserService } from '@core/services/app-user.service';
import { YandexSmartCaptchaRequest } from '@models/auth-models/YandexSmartCaptchaRequest';
import { PhoneRegistrationRequest } from '@requests/registration-request';
//
@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private readonly AUTH_TOKEN = 'auth_token';
	private readonly REFRESH_TOKEN = 'refresh_token';
	authToken: string | null;
	refreshToken: string | null;
	private authUrl = 'api/Auth';
	private companyService: CompanyService;
	private appUserService: AppUserService;


	constructor(
		private http: HttpClient,
		@Inject(CompanyService) companyService: CompanyService,
		@Inject(AppUserService) appUserService: AppUserService
	) {
		this.companyService = companyService;
		this.appUserService = appUserService;
		this.authToken = this.getAuthToken() ?? null;
		this.refreshToken = this.getRefreshToken() ?? null;
	}

	login(loginRequest: LoginRequest): Observable<boolean> {
		const url = `${this.authUrl}/Login`;
		return this.http.post<Tokens>(url, loginRequest)
			.pipe(
				tap(tokens => this.doLoginUser(tokens)),
				map(() => true)
			);
	}

	loginPhone(loginRequest: PhoneRegistrationRequest): Observable<boolean> {
		const url = `${this.authUrl}/LoginPhone`;
		return this.http.post<Tokens>(url, loginRequest)
			.pipe(
				tap(tokens => this.doLoginUser(tokens)),
				map(() => true)
			);
	}

	logout() {
		const url = `${this.authUrl}/Logout`;
		return this.http.post<any>(url, new Tokens(this.getAuthToken(), this.getRefreshToken()))
			.pipe(
				tap(() => this.doLogoutUser()),
				map(() => true),
				catchError((err) => {
					this.doLogoutUser();
					return throwError(err);
				})
			);
	}

	isLoggedIn() {
		return !!this.appUserService.getCurrentActiveUserView();
	}

	isCaptchaValidated(token: string): Observable<YandexSmartCaptchaRequest> {
		return this.http.get<YandexSmartCaptchaRequest>(`${this.authUrl}/YandexSmartCaptchaCheck`, {
			params: {
				token: token
			}
		})
	}

	updateTokens() {
		const url = `${this.authUrl}/Refresh`;
		return this.http.post<Tokens>(url, new Tokens(this.getAuthToken(), this.getRefreshToken()))
			.pipe(
				tap((tokens: Tokens) => {
					this.storeTokens(tokens);
				}),
				catchError(() => {
					this.doLogoutUser();
					return of(false);
				}));
	}

	getAuthToken(): string | undefined {
		return localStorage.getItem(this.AUTH_TOKEN);
	}

	doLoginUser(tokens: Tokens) {
		this.storeTokens(tokens);
	}

	doLogoutUser() {
		this.appUserService.clearActiveUserView();
		this.companyService.clearOwnerCompanyViews();
		this.clearTokens();
		localStorage.clear();
	}

	private getRefreshToken(): string {
		return localStorage.getItem(this.REFRESH_TOKEN);
	}

	storeTokens(tokens: Tokens) {
		this.authToken = tokens.authToken;
		this.refreshToken = tokens.refreshToken;
		localStorage.setItem(this.AUTH_TOKEN, tokens.authToken);
		localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
	}

	clearTokens(): void {
		this.authToken = null;
		this.refreshToken = null;
	}

}
