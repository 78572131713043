import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'skill'
})
export class SkillPipe extends AbstractModelPipe implements PipeTransform {
  properties = [
    {name: 'title', title: 'Введите качество', hint: 'Наименование'},
    {name: 'hardSkillsTitle', title: 'Профессиональные навыки', hint: 'Введите навык'},
    {name: 'softSkillsTitle', title: 'Личные качества', hint: 'Введите качество'},
    {name: 'section', title: 'Раздел', hint: 'Раздел специализации'},
    {name: 'code', title: 'Обозначение', hint: 'Обозначение специализации'},
    {name: 'name', title: 'Наименование', hint: 'Наименование специализации'},
    {name: 'state', title: 'Статус', hint: 'Статус специализации'},
    {name: 'personsCount', title: 'Кол-во специалистов', hint: 'Количество специалистов'},
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
  allProperties() {
    return this.properties;
  }
}
