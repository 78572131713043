import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, SkipSelf } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { CreateControlInfo } from '../create-job-dialog/create-job-model';
import { CityModel } from '@models/city-model';
import { takeUntil } from 'rxjs/operators';
import { FiltersService } from '@core/services/filters.service';
import { DestroyService } from '@profdepo-ui/core';
import { switchMap } from 'rxjs';
import { isEmptyValue } from '@utils/helpers/filter-helpers';
import { CreateJobDialogComponent } from '../create-job-dialog/create-job-dialog.component';

@Component({
	selector: 'pdw-create-work-form',
	templateUrl: './create-work-form.component.html',
	styleUrls: ['./create-work-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService]
})
export class CreateWorkFormComponent implements OnInit {
	form: FormGroup;
	@Input() data: CreateControlInfo[];
	constructor(private rootFormGroup: FormGroupDirective,
				@SkipSelf() @Inject(CreateJobDialogComponent) public parent: CreateJobDialogComponent,
				private filterService: FiltersService,
				private destroy$: DestroyService) {
		this.form = rootFormGroup.control;
	}

	cityAutocomplete = new FormControl<string>('');
	cities: CityModel[] = [];

	ngOnInit(): void {
		this.cityAutocomplete.setValue(this.form.controls.city.value);
		this.cityAutocomplete.valueChanges
			.pipe(switchMap(query => this.filterService.getCities(query)),
				takeUntil(this.destroy$))
			.subscribe(cities => {
				this.cities = cities;
			});
	}

	getControlByName(name: string): FormControl {
		return this.form.get(name) as FormControl;
	}

	getControlInfo(key: string): CreateControlInfo {
		return this.data.find(item => item.name === key) ?? null;
	}

	onCitySelect(city: CityModel) {
		this.filterService.approveCity(city).pipe(takeUntil(this.destroy$)).subscribe(val => {
			this.getControlByName('city').setValue(val);
		});
	}

	compareOptionById<T extends { id?: string }>(option: T, value: T): boolean {
		return option.id === value.id;
	}

	compareOptionByName<T extends { name?: string }>(option: T, value: T): boolean {
		return option.name === value.name;
	}

	getCityName(city: CityModel | null): string {
		return city ? city.name : '';
	}

	protected readonly isEmptyValue = isEmptyValue;
}
