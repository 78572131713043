import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {WorkRequestChatMessageView} from "@models/work-request-chat-message-view";
import {BehaviorSubject} from "rxjs";
import {pushFakeHistoryState} from "@utils/functions";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogType
} from "@shared/components/dialog/confirmation-dialog/confirmation-dialog.component";
import {filter} from "rxjs/operators";
import {errorTitle} from "@utils/helpers/error-helpers";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NotificationsRequestService} from "@core/services/notifications-request.service";
import {NotificationService} from "@profdepo-ui/core";

class ContextMenuData {
  positionY: number;
  positionX: number;
  item: WorkRequestChatMessageView;
}
@Component({
  selector: 'app-work-requests-chat-messenger-context-menu',
  templateUrl: './work-requests-chat-messenger-context-menu.component.html',
})
export class WorkRequestsChatMessengerContextMenuComponent implements OnInit {
  @Output() edit = new EventEmitter<WorkRequestChatMessageView>(null);
  @Output() delete = new EventEmitter<WorkRequestChatMessageView>(null);
  @Input('contextMenuData')
  set contextMenuData(element: ContextMenuData) {
    this._contextMenuData.next(element);
    this.prevChatMessage = element?.item;
  }
  get contextMenuData(): ContextMenuData {
    return this._contextMenuData.value;
  }
  @ViewChild(MatDialogRef, { static: false}) matDialog: MatDialogRef<any>;
  constructor(
    private dialog: MatDialog,
    private notificationService: NotificationService,
  ) { }

  private _contextMenuData = new BehaviorSubject<ContextMenuData>(null);
  prevChatMessage: WorkRequestChatMessageView;
  ngOnInit(): void {
  }

  @HostListener('document:click')
  clickOut() {
    this._contextMenuData.next(null);
  }
  startEdit(){
    this.edit.emit(this._contextMenuData.value.item);
  }
  startDelete(){

    pushFakeHistoryState();

    this.dialog.open(ConfirmationDialogComponent, {
      autoFocus: true,
      disableClose: false,
      width: '581px',
      panelClass: 'pd-dialog',
      data: {
        title: 'Внимание',
        message: 'Вы действительно хотите удалить комментарий',
        value: this.contextMenuData.item.message,
        questionMark: true,
        type: ConfirmationDialogType.question,
      }
    })
      .afterClosed()
      .pipe(
        filter(result => result == true),
      )
      .subscribe({
        next:(data) => {
          this.delete.emit(this.prevChatMessage);
        },
        error:(err) => {
          this.notificationService.showDanger(errorTitle(err));
        }
      });
  }
  isMoreThenFiveMinute(chatMessage: WorkRequestChatMessageView): boolean {
    let now = new Date();
    let el = String(chatMessage.createTime);
    let createTime = new Date(Date.parse(el));
    return (now.getTime() - createTime.getTime() > 300000)
  }
}
