import { ChangeDetectionStrategy, Component, Input, OnInit, } from '@angular/core';
import { MenuItem, SideMenuService } from '@core/services/side-menu.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppUserService } from '@core/services/app-user.service';
import { filter, takeUntil } from 'rxjs/operators';
import { AppUserType, AppUserView, SpecialistMenuTitles } from '@models/app-user-view';
import { MatSidenav } from '@angular/material/sidenav';
import { CompanyMenuTitles } from '@models/company-view';
import { WorkMenuTitles } from '@models/work-view';
import { WorkRequestTitles } from '@models/work-request-view';
import { AuthService } from '@core/services/auth.service';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { HttpErrorResponse } from '@angular/common/http';
import { errorTitle } from '@utils/helpers/error-helpers';
import { Router } from '@angular/router';
import { RoleService } from '@core/services/role.service';
import { FeatureService } from '@core/services/feature.service';
import { ImageAvatarSize } from '@shared/components/ui/image-avatar/image-avatar.types';

@Component({
	selector: 'pdw-side-menu',
	templateUrl: './side-menu.component.html',
	providers: [DestroyService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

	activeAppUserView$: Observable<AppUserView>;
	currentMenuItems: Array<MenuItem>;

	protected readonly AppUserType = AppUserType;
	protected readonly IMAGE_SIZE = ImageAvatarSize;

	activeWorkRequestCounts = new BehaviorSubject<number | null>(null);

	public sideMenuFixed: boolean;
	timeForUrl: number = 0;
	@Input() mobileMenu: boolean;

	constructor(
		private sideMenuService: SideMenuService,
		private appUserService: AppUserService,
		private matSidenav: MatSidenav,
		private auth: AuthService,
		private destroy$: DestroyService,
		private router: Router,
		private notificationService: NotificationService,
		private roleService: RoleService,
		private featureService: FeatureService
	) {
		this.activeAppUserView$ = this.appUserService.getActiveUserAsObservable().pipe(filter(user => !!user));

		this.currentMenuItems = [
			{
				icon: 'additional2-widget-add',
				linkTo: ['/works'],
				text: WorkMenuTitles.primaryTitleItems,
				permissions: [AppUserType.client],
			},
			{
				icon: 'additional2-widget-add',
				text: WorkMenuTitles.primaryTitleItems,
				linkTo: ['jobs/careergid'],
				permissions: [AppUserType.specialist],
				// children: [
				// 	{
				// 		icon: 'additional2-widget-add',
				// 		linkTo: ['jobs/careergid'],
				// 		text: WorkMenuTitles.allWorks,
				// 		permissions: [AppUserType.specialist],
				// 	},
				// 	{
				// 		icon: 'additional2-widget-add',
				// 		linkTo: ['jobs/my'],
				// 		text: WorkMenuTitles.myWorks,
				// 		permissions: [AppUserType.specialist],
				// 	},
					// {
					// 	icon: 'additional2-widget-add',
					// 	linkTo: ['jobs/careergid'],
					// 	text: WorkMenuTitles.careerGid,
					// 	permissions: [AppUserType.specialist],
					// }
				// ]
			},
			{
				icon: 'additional2-widget-add',
				text: WorkMenuTitles.primaryTitleItems,
				linkTo: ['jobs/careergid'],
				permissions: [AppUserType.none],
				// children: [
				// 	{
				// 		icon: 'additional2-widget-add',
				// 		linkTo: ['jobs/careergid'],
				// 		text: WorkMenuTitles.allWorks,
				// 		permissions: [AppUserType.none],
				// 	},
					// {
					// 	icon: 'additional2-widget-add',
					// 	linkTo: ['jobs/careergid'],
					// 	text: WorkMenuTitles.careerGid,
					// 	permissions: [AppUserType.none],
					// }
				// ]
			},
			{
				icon: 'additional2-chat',
				linkTo: ['/requests'],
				text: WorkRequestTitles.primaryTitleItems,
				permissions: [AppUserType.specialist, AppUserType.client]
			},
			{
				icon: 'additional2-bag',
				linkTo: ['/companies'],
				text: CompanyMenuTitles.primaryTitleItems,
				permissions: [AppUserType.specialist, AppUserType.client]
			},
			{
				icon: 'additional2-user',
				linkTo: ['/users'],
				text: SpecialistMenuTitles.primaryTitleItems,
				permissions: [AppUserType.none, AppUserType.client],
			},
			{
				icon: 'balance-wallet',
				linkTo: ['/proaccount'],
				text: SpecialistMenuTitles.wallet,
				permissions: [AppUserType.specialist, AppUserType.client, AppUserType.none]
			}
		];
	}

	hasPermission(type: AppUserType | AppUserType[]): boolean {
		return this.roleService.hasPermission(type);
	}

	ngOnInit(): void {
		this.sideMenuService.workRequestCounts
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: count => {
					this.activeWorkRequestCounts.next(count);
				}
			});
		this.appUserService.timeStampForUrl
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe(x => {
				this.timeForUrl = x;
			})
	}

	closeMenu(): void {
		this.matSidenav.close();
	}

	/**
	 * Предотвращает всплытие клика
	 * @param event
	 */
	onMoreClick(event: MouseEvent) {
		event.stopPropagation();
	}

	avatarColor(appUserView: AppUserView): string {
		return AppUserService.avatarColor(appUserView);
	}

	showUserName(user: AppUserView): string {
		if (user.firstname && user.lastname) {
			return `${user.firstname} ${user.lastname[0]}.`
		}

		if (user.firstname) {
			return user.firstname
		}

		if (user.lastname) {
			return user.lastname
		}

		return user.type === AppUserType.specialist ? 'Специалист' : 'Заказчик';
	}

	isLoggedIn() {
		return this.auth.isLoggedIn();
	}

	logout() {
		this.auth.logout()
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: () => {
					this.router
						.navigate(['login']);
					this.auth.doLogoutUser();
				},
				error: (err) => {
					if (err instanceof HttpErrorResponse) {
						this.notificationService.showDanger(errorTitle(err));
					}
				}
			});
	}
}
