import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CompanyService } from '@core/services/company.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CompanyView } from '@models/company-view';
import { YesNo } from '@models/enums';
import { AppUserService } from '@core/services/app-user.service';
import { AppUserType } from '@models/app-user-view';

@Component({
	selector: 'pdw-company-card',
	templateUrl: 'company-card.component.html',
	host: { 'class': 'pd-company-card' },
	styleUrls: ['./company-card.component.scss']
})
export class CompanyCardComponent implements OnInit, OnDestroy {
	ownerCompanyViews: CompanyView[] = [];
	@Input() commands: any[];

	/**
	 * Компания
	 * @param value
	 */
	@Input()
	set companyView(value: CompanyView) {
		this._companyView.next(value);
	};

	get companyView(): CompanyView {
		return this._companyView.getValue();
	}

	private _companyView = new BehaviorSubject<CompanyView>(null);
	currentCompanyView = new BehaviorSubject<CompanyView>(null);
	unsubscribe: Subject<any> = new Subject<any>();

	constructor(
		private router: Router,
		private appUserService: AppUserService,
		private companyService: CompanyService,
	) {
	}

	ngOnInit(): void {
		this._companyView
			.pipe(
				filter(x => x !== null),
			)
			.subscribe((companyView: CompanyView) => {
				this.currentCompanyView.next(companyView);
			});
	}

	/**
	 * Обрабатывает нажатие средней кнопкой мыши на карточке компании
	 * @param event
	 */
	onMouseUp(event: MouseEvent): void {
		if (event.button === 1) {
			window.open(this.commands.join('/'), '_blank');
		}
	}

	onMouseDown(event: MouseEvent): void {
		if (event.button === 1) {
			event.preventDefault();
		}
	}

	canSubscribe(companyView: CompanyView): Observable<boolean> {
		return this.appUserService.getActiveUserAsObservable()
			.pipe(
				filter(x => x !== null),
				map(x => !this.ownerCompanyViews?.some(a => a.id === companyView.id) && x.type === AppUserType.specialist)
			);
	}

	goCompanySubscribe(event: MouseEvent, companyView: CompanyView): void {
		event.stopPropagation();
		this.companyService.subscribe(companyView.id)
			.pipe(
				takeUntil(this.unsubscribe)
			)
			.subscribe({
				next: () => {
					companyView.subscribed = YesNo.yes;
				}
			});
	}

	goCompanyUnsubscribe(event: MouseEvent, companyView: CompanyView): void {
		event.stopPropagation();
		this.companyService.unsubscribe(companyView.id)
			.pipe(
				takeUntil(this.unsubscribe)
			)
			.subscribe({
				next: () => {
					companyView.subscribed = YesNo.no;
					this.companyService.shouldReload.next(true);
				}
			});
	}

	logoColor(companyView: CompanyView): string {
		return CompanyService.logoColor(companyView);
	}

	/**
	 * Отписывает подписки при уничтожении компоненты
	 */
	ngOnDestroy() {
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}

	getCityOrRegion(companyView: CompanyView): string | null {
		if (companyView?.legal?.city) {
			return companyView.legal.city
		}

		if (companyView?.legal?.region) {
			return companyView.legal.region
		}
		
		return null;
	}
}
