import { Component, Input } from '@angular/core';
import { AppUserView } from '@models/app-user-view';
import { AppUserService } from '@core/services/app-user.service';
import { ImageAvatarSize } from '@shared/components/ui/image-avatar/image-avatar.types';

@Component({
	selector: 'pdw-user-initial-letters',
	templateUrl: './user-initial-letters.component.html',
	host: { 'class': 'initial-letters-host', '[class.can-update]': 'canUpdate' },
})
export class UserInitialLettersComponent {
	@Input() user: AppUserView;
	@Input() canUpdate: boolean = false;
	@Input() size: ImageAvatarSize = ImageAvatarSize.BIG;

	letters(): string {
		return AppUserService.avatarInitialLetters(this.user);
	}
}
