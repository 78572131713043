import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, SkipSelf } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { CreateControlInfo } from '../create-job-dialog/create-job-model';
import { switchMap } from 'rxjs';
import { CityModel } from '@models/city-model';
import { CreateJobDialogComponent } from '../create-job-dialog/create-job-dialog.component';
import { KeyValuePipe } from '@angular/common';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { takeUntil } from 'rxjs/operators';
import { FiltersService } from '@core/services/filters.service';
import { WorkFileView } from '@models/work-file-view';

@Component({
	selector: 'pdw-create-freelance-form',
	templateUrl: './create-freelance-form.component.html',
	styleUrls: ['./create-freelance-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [KeyValuePipe, DestroyService]
})
export class CreateFreelanceFormComponent implements OnInit {
	@Input() data: CreateControlInfo[];
	@Input() isEditMode = false;
	form: FormGroup;
	cityAutocomplete: FormControl = new FormControl<string>('');
	filesMap: Map<string, File | WorkFileView>;

	constructor(private rootFormGroup: FormGroupDirective,
				private notificationService: NotificationService,
				private destroy$: DestroyService,
				private filterService: FiltersService,
				@SkipSelf() @Inject(CreateJobDialogComponent) public parent: CreateJobDialogComponent,
				private keyValuePipe: KeyValuePipe) {
		this.form = rootFormGroup.control;
		this.filesMap = new Map<string, File | WorkFileView>(this.parent.data.workView.files?.map(file => [file.name, file]) ?? [])
	}

	cities: CityModel[] = [];

	ngOnInit(): void {
		this.cityAutocomplete.valueChanges
			.pipe(switchMap(query => this.filterService.getCities(query)),
				takeUntil(this.destroy$))
			.subscribe(cities => {
				this.cities = cities;
			});

	}


	getControlInfo(key: string): CreateControlInfo {
		return this.data.find(item => item.name === key) ?? null;
	}


	getControlByName(name: string): FormControl {
		return this.form.get(name) as FormControl;
	}

	get files(): AbstractControl {
		return this.getControlByName('files');
	}

	onSelectFiles(event: any) {
		const uploadedFiles: File[] = event.target.files;
		const currentValue = this.files.value;

		uploadedFiles.forEach(file => {
			if (this.filesMap.has(file.name)) {
				this.notificationService.showDanger(`Файл с именем ${file.name} уже существует`);
				return;
			}
			this.filesMap.set(file.name, file);
		});

		if (currentValue) {
			currentValue.forEach(file => {
				this.filesMap.set(file.name, file);
			});
		}
		this.updateFileControl();
	}

	trackByFile(index, item): string {
		return item.name;
	}

	changeFile(name: string, event: Event): void {
		const file = (event.target as HTMLInputElement).files[0];
		if (this.filesMap.has(file.name)) {
			this.notificationService.showDanger('Файл с текущим названием уже добавлен');
			return;
		}
		this.filesMap.delete(name);
		this.filesMap.set(file.name, file);
		this.updateFileControl();
	}

	deleteFile(name: string): void {
		this.filesMap.delete(name);
		this.updateFileControl();
	}

	private updateFileControl(): void {
		const result = this.keyValuePipe.transform(this.filesMap).map(item => item.value);
		this.files.setValue(result);
	}

	onCitySelect(city: CityModel) {
		this.filterService.approveCity(city).pipe(takeUntil(this.destroy$)).subscribe(val => {
			this.getControlByName('city').setValue(val);
		});
	}

	getCityName(city: CityModel): string {
		return city.name;
	}

}
