<div class='pd-dialog-header'>
	<p class='pd-dialog-header-title'>{{ data.title }}</p>
	<button
		class='pd-icon-button'
		mat-icon-button
		[mat-dialog-close]='false'
	>
		<mat-icon svgIcon='interface-close'></mat-icon>
	</button>
</div>
<tui-scrollbar class='pd-scrollbar pd-dialog-scroll'>
	<div class='pd-dialog-content large-padding pd-dialog-content-small-gap'>
		<div class='pd-dialog-mark-header'>
			<h2 class='pd-dialog-mark-title'>
				<mat-icon
					class='pd-icon'
					svgIcon='additional-user-rectangle-1'
				></mat-icon>
				{{ executor.title }}
			</h2>
			<p class='pd-dialog-mark-subtitle'>
				{{ data.workView.name }}
			</p>
		</div>
		<p class='pd-dialog-subtitle'>Оцените качество выполненной работы</p>
		<ng-container *ngIf='workExecutorMarkViewForm'>
			<div class='pd-dialog-mark-ratings-wrapper'>
				<div class='pd-work-rating-wrapper'>
					<tui-rating
						class='pd-work-rating'
						[formControl]='qualityValue'
						[iconFilled]="'additional2-star-filled'"
						[iconNormal]="'additional2-star-filled'"
					></tui-rating>
					<div class='pd-work-rating-text'>
						{{ qualityValue | workExecutorMark:'propertyTitle' }}
						<span class='{{ratingColorClass(qualityValue.value)}}'>
							{{ qualityValue.value }}
						</span>
					</div>
				</div>
				<div class='pd-work-rating-wrapper'>
					<tui-rating
						class='pd-work-rating'
						[formControl]='speedValue'
						[iconFilled]="'additional2-star-filled'"
						[iconNormal]="'additional2-star-filled'"
					></tui-rating>
					<div class='pd-work-rating-text'>
						{{ speedValue | workExecutorMark:'propertyTitle' }}
						<span class='{{ratingColorClass(speedValue.value)}}'>
							{{ speedValue.value }}
						</span>
					</div>
				</div>
				<div class='pd-work-rating-wrapper'>
					<tui-rating
						class='pd-work-rating'
						[formControl]='experienceValue'
						iconFilled="additional2-star-filled"
						iconNormal="additional2-star-filled"
					></tui-rating>
					<div class='pd-work-rating-text'>
						{{ experienceValue | workExecutorMark:'propertyTitle' }}
						<span class='{{ratingColorClass(experienceValue.value)}}'>
							{{ experienceValue.value }}
						</span>
					</div>
				</div>
				<mat-error *ngIf='hasRatingErrors'>Выберите оценку</mat-error>
			</div>
			<form
				id='workExecutorMarkViewForm'
				[formGroup]='workExecutorMarkViewForm'
				(ngSubmit)='onSubmit(workExecutorMarkViewForm)'
			>
				<div class='pd-dialog-column'>
					<!-- Comment -->
					<mat-form-field
						class='pd-input-form-field pd-textarea-form-field'
						appearance='fill'
						appExtendableTextArea
						hideRequiredMarker
					>
						<mat-label>{{ comment | workMark: 'propertyHint' }}</mat-label>
						<tui-scrollbar class='pd-scrollbar pd-textarea-scrollbar'>
							<div class='pd-textarea-wrapper'>
								<div class='pd-textarea-relative-wrapper'>
									<div class='pd-textarea-pseudo-content'>
										<span class='pd-textarea-pseudo'></span>
										&nbsp;
									</div>
									<textarea
										class='pd-textarea'
										matInput
										[formControl]='comment'
										rows='5'
									></textarea>
								</div>
							</div>
						</tui-scrollbar>
						<button
							class='pd-icon-button gray'
							matSuffix
							mat-icon-button
							type='button'
							tabindex='-1'
						>
							<mat-icon
								class='pd-icon'
								svgIcon='additional2-stretch'
							></mat-icon>
						</button>
						<mat-error *ngIf='comment.errors?.serverError'>
							{{ comment.errors?.serverError }}
						</mat-error>
					</mat-form-field>

				</div>
			</form>

		</ng-container>
	</div>
</tui-scrollbar>

<div
	class='pd-dialog-footer align-end'
	*ngIf='workExecutorMarkViewForm'
>
	<div class='pd-dialog-footer-buttons'>
		<button
			mat-raised-button
			class='pd-button-secondary large'
			type='reset'
			[mat-dialog-close]='false'
		>
			Отменить
		</button>
		<button
			form='workExecutorMarkViewForm'
			mat-raised-button
			class='pd-button large'
			type='submit'
			[disabled]='(saving | async)'
		>
			<ng-container *ngIf='!(saving | async); else isSaving'>Сохранить</ng-container>
			<ng-template #isSaving>
				Сохраняем
			</ng-template>
		</button>
	</div>
</div>
