import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from '@core/services/utils.service';
import { WorkMarkView } from '@models/work-mark-view';
import { WorkStateValue } from '@models/work-view';

@Component({
	selector: 'app-work-mark-card-dialog',
	templateUrl: './work-mark-card-dialog.component.html',
})
export class WorkMarkCardDialogComponent {
	WorkState = WorkStateValue;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { title: string, workMarkView: WorkMarkView },
		public utilsService: UtilsService
	) {
	}


	averageMark(): number {
		const mark = this.data.workMarkView;
		return +((mark.speedValue + mark.targetryValue + mark.adequacyValue) / 3).toFixed(1);
	}

	protected readonly WorkStateValue = WorkStateValue;
}
