import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppUserService } from '@core/services/app-user.service';
import { AppUserType, AppUserView } from '@models/app-user-view';
import { NavigationEnd, Router } from '@angular/router';
import { DestroyService } from '@profdepo-ui/core';
import { filter, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { VerificationDialogComponent } from './components/dialog/verification-dialog/verification-dialog.component';
import { RoleService } from '@core/services/role.service';


@Component({
	selector: 'pdw-general',
	templateUrl: './general.component.html',
	host: { 'class': 'pd-expanded-height' },
	styleUrls: ['./general.component.scss'],
	providers: [DestroyService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralComponent {

	private nonDisplayInnRoutes: string[] = ['/users', '/companies'];
	private nonAuthRoutes: string[] = ['/users', '/jobs']
	private isInnUrl: boolean = false;
	private isAuthUrl: boolean = false;
	private currentUrl: string;
	protected readonly AppUserType = AppUserType;

	constructor(private userService: AppUserService,
				private route: Router,
				private destroy$: DestroyService,
				private dialog: MatDialog,
				private roleService: RoleService
	) {
		if (!this.user?.inn) {
			this.route.events
				.pipe(
					filter(event => event instanceof NavigationEnd),
					takeUntil(this.destroy$)
				)
				.subscribe((navigationEnd: NavigationEnd) => {
					this.currentUrl = navigationEnd.url;
					this.isAuthUrl = this.nonAuthRoutes.some(url => navigationEnd.url.includes(url));
					this.isInnUrl = !this.nonDisplayInnRoutes.some(url => navigationEnd.url.includes(url));
				})
		}
	}

	get user(): AppUserView {
		return this.userService.getCurrentActiveUserView();
	}

	get isNonVerificated(): boolean {
		return !this.user?.inn && this.isInnUrl && this.roleService.hasPermission(AppUserType.specialist);
	}

	get isNotAuthorized(): boolean {
		return !this.user && this.isAuthUrl;
	}

	openVerificationDialog(): void {
		this.dialog.open(VerificationDialogComponent, {
			autoFocus: true,
			disableClose: false,
			width: '581px',
			panelClass: ['pd-dialog', 'no-padding'],
			data: this.user
		});
	}
}
