import { ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { AppUserView } from '@models/app-user-view';
import { YesNo } from '@models/enums';
import { AppUserService } from '@core/services/app-user.service';
import { DestroyService } from '@profdepo-ui/core';
import { catchError, takeUntil } from 'rxjs/operators';
import { TrueLoadingService } from '@core/services/true-loading.service';
import { EMPTY } from 'rxjs';
import { ImageAvatarSize } from '@shared/components/ui/image-avatar/image-avatar.types';
import { diffDate } from '@utils/helpers/date-helpers';
import * as moment from 'moment/moment';

@Component({
	selector: 'pdw-card-matched-employee',
	templateUrl: './card-matched-employee.component.html',
	styleUrls: ['./card-matched-employee.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, TrueLoadingService],
})
export class CardMatchedEmployeeComponent {

	protected readonly YESNO = YesNo;
	protected readonly IMAGE_SIZE = ImageAvatarSize;

	@Input()
	set user(user: AppUserView) {
		this.cardUser = user;
	}

	@Input() canOpenModal: boolean = true;
	@Input() canRedirect: boolean = true;

	get user(): AppUserView {
		return this.cardUser;
	}

	private cardUser!: AppUserView;

	constructor(private userService: AppUserService,
				private destroy$: DestroyService,
				@Inject(LOCALE_ID) public locale: string,
				public loading$: TrueLoadingService) {
		this.loading$.next(false);
	}

	avatarColor(user: AppUserView) {
		return AppUserService.avatarColor(user);
	}
	manipulateWithFavPerson(event: Event): void {
		event.preventDefault();
		event.stopPropagation();

		this.loading$.next(true);
		if (this.isUserInFavList) {
			this.removeFromFavList()
			return
		}
		this.addToFavList();
	}

	get isUserInFavList(): boolean {
		return this.cardUser.favorite === this.YESNO.yes;
	}

	private addToFavList(): void {
		this.userService
			.addToFavorites(this.cardUser.id)
			.pipe(
				takeUntil(this.destroy$),
				catchError(() => {
					this.loading$.next(false);
					return EMPTY;
				}))
			.subscribe(() => {
				this.cardUser = { ...this.cardUser, favorite: this.YESNO.yes }
				this.loading$.next(false);
			});
	}

	private removeFromFavList(): void {
		this.userService
			.removeFromFavorites(this.cardUser.id)
			.pipe(
				takeUntil(this.destroy$),
				catchError(() => {
					this.loading$.next(false);
					return EMPTY;
				})
			)
			.subscribe(() => {
				this.cardUser = { ...this.cardUser, favorite: this.YESNO.no };
				this.loading$.next(false);
			});
	}

	 getAge(age: Date | null): string {
		if (!age) return 'Возраст не указан';

		const diff = diffDate(new Date(Date.now()), age);
		return [
			diff.years > 1 ? null : 1,
			moment.duration(diff.years, 'year').locale(this.locale).humanize()
		].join(' ');
	}
}
