import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpacePipe } from './space.pipe';



@NgModule({
    declarations: [
        SpacePipe
    ],
	exports: [
		SpacePipe,
		SpacePipe
	],
    imports: [
        CommonModule
    ]
})
export class SpacePipeModule { }
