<div *ngIf="currentAppUserView | async as currentAppUser">
  <div class="pd-profile-about-top-wrapper"
       [ngClass]="(currentAppUser.hardSkills && currentAppUser.hardSkills.length) ? '' : 'empty'">
    <h3 class="pd-profile-subtitle">
      <mat-icon class="pd-icon light-gray" svgIcon="additional-check-rectangle"></mat-icon>
      {{'hardSkillsTitle' | skill: 'propertyTitle'}}
    </h3>
    <ng-container *ngIf="canUpdate">
      <button *ngIf="currentAppUser.hardSkills && currentAppUser.hardSkills.length; else noHardSkills"
              class="pd-button-secondary small"
              mat-raised-button
              (click)="goUpdateHardSkills()">
        <mat-icon class="pd-icon" svgIcon="additional2-edit"></mat-icon>
        <p class="pd-profile-button-text">Редактировать</p>
      </button>
      <ng-template #noHardSkills>
        <button class="pd-button-secondary small" mat-raised-button (click)="goUpdateHardSkills()">
          <mat-icon class="pd-icon" svgIcon="additional-plus"></mat-icon>
          <p class="pd-profile-button-text">Добавить</p>
        </button>
      </ng-template>
    </ng-container>
  </div>
  <div class="pd-profile-skills-wrapper" *ngIf="currentAppUser.hardSkills && currentAppUser.hardSkills.length">
    <ng-container *ngFor="let hardSkill of currentAppUser.hardSkills">
      <!-- Без возможности оценки -->
      <div class="pd-skill-inner" [ngClass]="{ 'created': hardSkill.state === 0 }">
        <p class="pd-skill-text" [matTooltip]="getTooltip(hardSkill)">
          {{ hardSkill.title }}
        </p>
      </div>
    </ng-container>
  </div>
</div>
