import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	LOCALE_ID,
	OnInit,
	Output
} from '@angular/core';
import { WorkStateValue, WorkView } from '@models/work-view';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { WorkRequestDirection, WorkRequestState, WorkRequestView } from '@models/work-request-view';
import {
	ConfirmationDialogComponent,
	ConfirmationDialogType
} from '@shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { YesNo } from '@models/enums';
import { AppUserType, AppUserView } from '@models/app-user-view';
import { WorkService } from '@core/services/work.service';
import { AppUserService } from '@core/services/app-user.service';
import { WorkRequestService } from '@core/services/work-request.service';
import { MatDialog } from '@angular/material/dialog';
import { UtilsService } from '@core/services/utils.service';
import { WorkAbstractComponent } from '@shared/work-abstract-component/work-abstract-component';
import { WorkResultFilesDialogComponent } from '@shared/work-result-files-dialog/work-result-files-dialog.component';
import { DestroyService, NotificationService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-work-state-change-specialist',
	templateUrl: './work-state-change-specialist.component.html',
	providers: [DestroyService],
	styleUrls: ['./work-state-change-specialist.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkStateChangeSpecialistComponent extends WorkAbstractComponent implements OnInit {
	@Input() isActiveUserAssigned: boolean = false;

	activeAppUserView = new BehaviorSubject<AppUserView>(null);
	@Output() changed = new EventEmitter<any>();

	constructor(
		private workService: WorkService,
		private appUserService: AppUserService,
		private workRequestService: WorkRequestService,
		private notificationService: NotificationService,
		private dialog: MatDialog,
		public utilsService: UtilsService,
		@Inject(LOCALE_ID) public locale: string,
		private destroy$: DestroyService
	) {
		super();
	}

	ngOnInit(): void {
		this.appUserService.getActiveUserView()
			.pipe(
				filter(x => x !== null),
				takeUntil(this.destroy$),
			).subscribe({
			next: appUserView => {
				this.activeAppUserView.next(appUserView);
			}
		});

		this.workViewSubject
			.pipe(
				filter(x => x !== null),
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: workView => {
					this.currentWorkView.next(workView);
				}
			});
	}

	canCancel(workView: WorkView): Observable<boolean> {
		return of(workView.state == WorkStateValue.packaged);
	}

	canExecuting(workView: WorkView): Observable<boolean> {
		return of(workView.state == WorkStateValue.executingAwait && workView.isFreelance === YesNo.yes);
	}

	canChecking(workView: WorkView): Observable<boolean> {
		return of(workView.state == WorkStateValue.executing);
	}

	// canRequest(workView: WorkView): Observable<boolean> {
	// 	return this.appUserService.getActiveUserAsObservable()
	// 		.pipe(
	// 			filter(x => x !== null && workView !== null),
	// 			map(u => {
	// 				return u.id !== workView.manager.id &&
	// 					u.openForWork === YesNo.yes &&
	// 					workView.specialistAssigned === YesNo.no
	// 					&& workView.requests.every(request => request.executor.id !== u.id);
	// 			})
	// 		);
	// }

	tooltipAddRequest(workView: WorkView): Observable<string> {
		return this.appUserService.getActiveUserAsObservable()
			.pipe(
				filter(x => x !== null && workView !== null),
				map(u => {
					const workRequestView = workView.requests && workView.requests.find(x => x.executor.id === u.id);
					if (!workRequestView) {
						return null;
					}

					switch (workRequestView?.state) {
						case WorkRequestState.accepted:
							return workRequestView.direction == WorkRequestDirection.fromManager
								? 'Приглашение принято'
								: 'Запрос принят';
						case WorkRequestState.declined:
							return workRequestView.direction == WorkRequestDirection.fromManager
								? 'Приглашение отклонено'
								: 'Запрос отклонен';
						case WorkRequestState.cancelledBySpecialist:
							return 'Выполнение отклонено специалистом';
						case WorkRequestState.cancelledByClient:
							return 'Выполнение отклонено заказчиком';
						default:
							return workRequestView?.direction == WorkRequestDirection.fromManager
								? 'Вам отправлено приглашение'
								: 'Запрос отправлен';
					}
				})
			);
	}

	isRequested(workView: WorkView): Observable<boolean> {
		return this.appUserService.getActiveUserAsObservable()
			.pipe(
				filter(x => x !== null && workView !== null),
				map(u => {
					return workView.requests && workView.requests.some(x => x.executor.id === u.id);
				})
			);
	}

	goExecuting(workView: WorkView): void {
		this.workService.stateChange(workView.id, workView.isFreelance === YesNo.yes ? WorkStateValue.executing : WorkStateValue.vacancyIsFinished)
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: () => {
					this.changed.emit();
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	goChecking(workView: WorkView): void {
		this.dialog.open(WorkResultFilesDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Загрузка файла с результатом работы',
					workView: workView
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
				switchMap(() => this.workService.stateChange(workView.id, WorkStateValue.checking)
					.pipe(
						takeUntil(this.destroy$)
					)
				)
			)
			.subscribe({
				next: () => {
					this.changed.emit();
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	goCancel(workView: WorkView): void {
		const workRequest = this.workRequestExecutor(workView);
		this.dialog.open(ConfirmationDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Подтверждение',
					message: 'Вы действительно хотите отказаться от выполнения работы',
					value: this.workView.title,
					questionMark: true,
					type: ConfirmationDialogType.question,
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
				switchMap(() => this.workRequestService.cancelBySpecialist(workRequest.id)),
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: () => {
					this.changed.emit();
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	goAddRequest(workView: WorkView): void {
		this.workRequestService.add(workView.id)
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: () => {
					this.changed.emit();
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	acceptInvite(): void {
		this.workRequestService.accept(this.workRequestExecutor(this.workView).id)
			.pipe(
				takeUntil(this.destroy$),
				switchMap(() => this.workService.getWorkView(this.workView.id))
			)
			.subscribe({
					next: (work) => {
						this.updateWorkView(work);
						this.notificationService.showSuccess('Работа успешно принята');
						this.changed.emit();
					},
					error: (err) => {
						this.notificationService.showDanger(errorTitle(err));
					}
				}
			);
	}

	rejectInvite(): void {
		this.dialog.open(ConfirmationDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Внимание',
					message: 'Вы действительно хотите отклонить отклик на ',
					value: this.workView.title,
					questionMark: true,
					type: ConfirmationDialogType.question,
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
				switchMap(() => this.workRequestService.decline(this.workRequestExecutor(this.workView).id)
					.pipe(
						switchMap(() => this.workService.getWorkView(this.workView.id)),
						takeUntil(this.destroy$)
					)
				)
			)
			.subscribe({
				next: (work) => {
					this.updateWorkView(work);
					this.notificationService.showSuccess('Работа отклонена');
					this.changed.emit();
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	rejectInviteBySpecialist(): void {
		this.dialog.open(ConfirmationDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Внимание',
					message: 'Вы действительно хотите отклонить отклик на ',
					value: this.workView.title,
					questionMark: true,
					type: ConfirmationDialogType.question,
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
				switchMap(() => this.workRequestService.cancelBySpecialist(this.workRequestExecutor(this.workView).id)),
				switchMap(() => {
					this.workService.clearWorkViews();
					return this.workService.getWorkView(this.workView.id)
				}),
				takeUntil(this.destroy$)
			).subscribe({
			next: (work) => {
				this.updateWorkView(work);
				this.notificationService.showSuccess('Работа отклонена');
				this.changed.emit();
			},
			error: (err) => {
				this.notificationService.showDanger(errorTitle(err));
			}
		});
	}

	cancelInviteBySpecialist(): void {
		this.dialog.open(ConfirmationDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Внимание',
					message: 'Вы действительно хотите отклонить отклик на ',
					value: this.workView.title,
					questionMark: true,
					type: ConfirmationDialogType.question,
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
				switchMap(() => this.workRequestService.delete(this.workRequestExecutor(this.workView).id)),
				switchMap(() => {
					this.workService.clearWorkViews();
					return this.workService.getWorkView(this.workView.id)
				}),
				takeUntil(this.destroy$)
			).subscribe({
			next: (work) => {
				this.updateWorkView(work);
				this.notificationService.showSuccess('Отклик отменен');
				this.changed.emit();
			},
			error: (err) => {
				this.notificationService.showDanger(errorTitle(err));
			}
		});
	}

	/** Проверяет, пригласил ли заказчик специалиста, при этом assigned пользователя еще нет и специалисту до этого не отказывали (на этой работе)
	 * @returns {boolean}
	 * @param workView - любая работы без пустых реквестов
	 */
	isInvited(workView: WorkView): boolean {
		const workRequest = this.workRequestExecutor(workView);
		if (!workRequest) {
			return false;
		}
		return workRequest.state === WorkRequestState.sent && workRequest.executor.id && workRequest.direction === WorkRequestDirection.fromManager && this.workView.specialistAssigned === YesNo.no && !this.workView.executorId && !this.isDeclined
	}

	/** Возвращает boolean когда заказчик отказал специалсту в вакансии/фриланса
	 * @returns boolean
	 */
	get isDeclined(): boolean {
		return this.workView.requests.some(item => item?.executor.id === this.activeAppUserView.value?.id && (item.state === WorkRequestState.declined || item.state === WorkRequestState.cancelledByClient))
	}

	/** Проверяет, приглашали и отказали ли пользователю
	 * @returns boolean
	 */
	get requestedAlready(): boolean {
		const request = this.workRequestExecutor(this.workView);
		return request && this.workView.state === WorkStateValue.seeking && (request?.state === WorkRequestState.cancelledByClient || request?.state === WorkRequestState.declined) && request.direction === WorkRequestDirection.fromExecutor;
	}

	/** Проверяет, отказался ли от работы сам специалист
	 * @returns boolean
	 */
	get rejectedBySpecialist(): boolean {
		const request = this.workRequestExecutor(this.workView);
		return ((request?.state === WorkRequestState.declined && request.direction === WorkRequestDirection.fromManager)
				|| (request?.state === WorkRequestState.cancelledBySpecialist && request.direction === WorkRequestDirection.fromExecutor))
			&& this.workView.state !== WorkStateValue.abstractFinished;
	}

	get rejectedByClient(): boolean {
		const request = this.workRequestExecutor(this.workView);

		return (request?.state === WorkRequestState.cancelledByClient)
	}


	/** Приглашение от заказчика на вакансию
	 * @returns boolean
	 */
	get isInvitedByManagerInVacancy(): boolean {
		const request = this.workRequestExecutor();
		return request
			&& request.state === WorkRequestState.sent
			&& request.direction === WorkRequestDirection.fromManager;
	}


	/** В ожидании финального выбора заказчика
	 * @returns boolean
	 */
	get isAwaitingForManagerInVacancy(): boolean {
		const request = this.workRequestExecutor();

		return request
			&& request.state === WorkRequestState.CandidatesList
			&& request.direction === WorkRequestDirection.fromManager
			&& !this.isFreelance;
	}

	get isRequestedByExecutorInVacancy(): boolean {
		const request = this.workRequestExecutor();

		return request
			&& request.state === WorkRequestState.CandidatesList
			&& request.direction === WorkRequestDirection.fromExecutor
			&& !this.isFreelance;
	}


	/** Проверяет, существует ли отклик специалиста в вакансии
	 * @returns boolean
	 */
	get isFirstRequestedByExecutorInVacancy(): boolean {
		const request = this.workRequestExecutor();

		return request
			&& request.state === WorkRequestState.CandidatesList
			&& this.activeAppUserView.value.type === AppUserType.specialist
			&& request.direction === WorkRequestDirection.fromExecutor
			&& !this.isFreelance;
	}

	get isFirstRequestByExecutorInFreelance(): boolean {
		const request = this.workRequestExecutor();

		return request
			&& request.state === WorkRequestState.sent
			&& this.workView.state === WorkStateValue.seeking
			&& request.direction === WorkRequestDirection.fromExecutor
			&& this.isFreelance
	}

	/** Находит WorkRequest в workView
	 * @returns boolean
	 */
	workRequestExecutor(workView?: WorkView): WorkRequestView | null {
		return this.workView.requests.find(req => req.executor.id === this.activeAppUserView?.value?.id) ?? null;
	}

}
