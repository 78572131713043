<ng-container *ngIf='currentAppUserView | async as currentAppUser'>
	<div class='pd-profile-title-wrapper'>
		<h2 class='pd-profile-title'>
			{{ 'subCategories' | appUser: 'propertyTitle' }}
		</h2>
		<ng-container *ngIf='canUpdate'>
			<button
				*ngIf='currentAppUser.subCategories && currentAppUser.subCategories.length; else noSubCategories'
				class='pd-button-secondary small'
				mat-raised-button
				(click)='goUpdateSubCategories()'
			>
				<mat-icon
					class='pd-icon'
					svgIcon='additional2-edit'
				></mat-icon>
				<p class='pd-profile-button-text'>Редактировать</p>
			</button>
			<ng-template #noSubCategories>
				<button
					class='pd-button-secondary small'
					mat-raised-button
					(click)='goUpdateSubCategories()'
				>
					<mat-icon
						class='pd-icon'
						svgIcon='additional-plus'
					></mat-icon>
					<p class='pd-profile-button-text'>Добавить</p>
				</button>
			</ng-template>
		</ng-container>
	</div>
	<div
		class='pd-profile-sub-categories-wrapper'
		*ngIf='currentAppUser.subCategories && currentAppUser.subCategories.length; else noSubCategories'
	>
		<ng-container *ngFor='let subCategory of currentAppUser.subCategories'>
			<div class='pd-skill-inner'>
				<p class='pd-skill-text'>
					{{ subCategory.name }}
				</p>
			</div>
		</ng-container>
	</div>
	<ng-template #noSubCategories>
		<div class='pd-profile-block-no-content-wrapper'>
			<p class='pd-profile-subtitle'>Типы работ пока не указаны</p>
			<ng-container *ngIf='canUpdate'>
				<p class='pd-profile-text'>Укажите типы работ, чтобы автоматически подбирать подходящие заказы</p>
			</ng-container>
		</div>
	</ng-template>
</ng-container>

