import { Pipe, PipeTransform } from '@angular/core';
import {AbstractEnumPipe} from "@public/pipes/abstract-enum.pipe";

@Pipe({
  name: 'appUserTypeValue'
})
export class AppUserTypeValuePipe extends AbstractEnumPipe implements PipeTransform {
  values = [
    'administrator',
    'client',
    'specialist'
  ];
  transform(value: any, ...args: any[]): any {
    return this.getTransform(value, args);
  }
}
