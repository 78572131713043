import { Pipe, PipeTransform } from '@angular/core';
import { AbstractModelPipe } from '@public/pipes/abstract-model.pipe';

@Pipe({
	name: 'work'
})
export class WorkPipe extends AbstractModelPipe implements PipeTransform {
	properties = [
		{ name: 'title', title: 'Наименование', hint: 'Наименование' },
		{ name: 'name', title: 'Наименование', hint: 'Наименование работы' },
		{ name: 'company', title: 'Компания', hint: 'Компания' },
		{ name: 'description', title: 'Что нужно сделать', hint: 'Описание работы', secondHint: 'Описание' },
		{ name: 'resultDescription', title: 'Какой будет результат', hint: 'Описание результата работы' },
		{
			name: 'resultCriteria',
			title: 'Критерии оценки результата работы',
			hint: 'Описание критериев оценки результата работы'
		},
		{ name: 'startDate', title: 'Дата начала', hint: 'Фактическая дата начала работы', case: 'дату начала' },
		{ name: 'endDate', title: 'Дата окончания', hint: 'Фактическая дата окончания работы', case: 'дату окончания' },
		{ name: 'executor', title: 'Исполнитель', hint: 'Назначенный исполнитель' },
		{ name: 'categories', title: 'Категории работы', hint: 'Категории работы' },
		{ name: 'subCategories', title: 'Типы работы', hint: 'Типы работы' },
		{ name: 'cost', title: 'Бюджет', hint: 'Бюджет' },
		{ name: 'dates', title: 'Сроки', hint: 'Сроки' },
		{ name: 'hardSkills', title: 'Профессиональные навыки', hint: 'Необходимые профессиональные навыки' },
		{ name: 'files', title: 'Приложенные файлы', hint: 'Приложенные файлы' },
		{ name: 'resultFiles', title: 'Результат работы', hint: 'Результат работы' },
		{ name: 'workTypes', title: 'Формат работы', hint: 'Формат работы' },
		{ name: 'workFormat', title: 'График работы', hint: 'График работы' },
		{ name: 'amount', title: 'Бюджет в ₽', hint: 'Бюджет' },
		{ name: 'amountVacancy', title: 'Зарплата в ₽', hint: 'Зарплата' }
	];

	transform(value: any, type: string): any {
		return this.getTransform(value, type);
	}

	allProperties() {
		return this.properties;
	}
}
