import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUserService } from '@core/services/app-user.service';
import { Subject } from 'rxjs';
import { isEmptyObject } from '@utils/helpers/object-helpers';
import { AuthService } from '@core/services/auth.service';
import { HeaderService } from '@core/services/header.service';
import { DestroyService, NotificationService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-confirm-email',
	templateUrl: './confirm-email.component.html',
	providers: [DestroyService]
})
export class ConfirmEmailComponent implements OnInit {
	backUrl: string;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private appUserService: AppUserService,
		private notificationService: NotificationService,
		private authService: AuthService,
		private headerService: HeaderService,
		private destroy$: DestroyService
	) {
	}

	ngOnInit(): void {
		this.headerService.changeCurrentTitle('Подтверждение эл. почты');
		this.route.queryParams
			.pipe(
				takeUntil(this.destroy$),
				tap(queryParams => {
					if (isEmptyObject(queryParams) || !queryParams.id || !queryParams.code) {
						this.router.navigate(['/market/profile']).then();
					}
				}),
				filter(queryParams => queryParams.id && queryParams.code),
				tap(queryParams => this.backUrl = queryParams.backUrl),
				switchMap(queryParams => this.appUserService.confirmEmail({
							appUserId: queryParams.id,
							code: queryParams.code
						})
						.pipe(
							takeUntil(this.destroy$),
						)
				),
			)
			.subscribe((data) => {
				if (this.authService.isLoggedIn()) {
					this.router.navigate([this.backUrl ? this.backUrl : '/market/profile'])
						.then(() => {
							this.notificationService.showSuccess(data.message);
						});
				} else {
					this.router.navigate(['/login'], { state: { afterConfirmationEmail: true } })
						.then();
				}
			}, (err) => {
				this.router.navigate(['/login'])
					.then(() => this.notificationService.showDanger(err.error));
			});
	}
}
