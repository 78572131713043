import { Pipe, PipeTransform } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

@Pipe({
  name: 'abstractModel'
})
export class AbstractModelPipe {
  public properties = [
    {name: 'Name', title: 'Title', hint: 'Hint'}
  ];
  public getTransform(value: any, type: string): any {
    switch (type) {
      case 'propertyCase':
        const propertyCase = this.getProperty(value);
        if (propertyCase !== null && propertyCase.hasOwnProperty('case')) {
          return propertyCase.case;
        }
        const internalPropertyTitle = this.getProperty(value);
        return internalPropertyTitle !== null && internalPropertyTitle.hasOwnProperty('title')
          ? internalPropertyTitle.title
          : value;
      case 'propertyTitle':
        const propertyTitle = this.getProperty(value);
        return propertyTitle !== null && propertyTitle.hasOwnProperty('title') ? propertyTitle.title : value;
      case 'propertyHint':
        const propertyHint = this.getProperty(value);
        return propertyHint !== null && propertyHint.hasOwnProperty('hint') ? propertyHint.hint : value;
      case 'propertyTitleAlt':
        const propertyTitleAlt = this.getProperty(value);
        return propertyTitleAlt !== null && propertyTitleAlt.hasOwnProperty('titleAlt') ? propertyTitleAlt.titleAlt : value;
      case 'propertySecondHint':
        const propertySecondHint = this.getProperty(value);
        return propertySecondHint !== null && propertySecondHint.hasOwnProperty('secondHint') ? propertySecondHint.secondHint : value;
      default:
        return value;
    }
  }
  private getProperty(value: any): any {
    if (typeof value === 'object' && value instanceof FormControl) {
      const parent = value.parent;
      if (parent instanceof FormGroup) {
        Object.keys(parent.controls).forEach((name) => {
          if (value === parent.controls[name]) {
            value = name;
          }
        });
      }
    }
    if (typeof value === 'string') {
      const t = this.properties.filter((o) => o.name === value);
      return t.length > 0 ? t[0] : null;
    } else {
      return null;
    }
  }
}
