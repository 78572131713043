import { Injectable } from '@angular/core';
import { AbstractModelService } from './abstract-model.service';
import { WorkExecutorMarkView } from '@models/work-executor-mark-view';
import { map } from 'rxjs/operators';
import { Observable, tap } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class WorkExecutorMarkService {
	private workExecutorMarksUrl = 'api/WorkExecutorMarks';

	constructor(private http: HttpClient, private modelService: AbstractModelService) {
	}

	/**
	 * Преобразование string в Date после десериализации
	 * @param workExecutorMarkView общай оценка исполнителя работы
	 */
	public static parseDates(workExecutorMarkView: WorkExecutorMarkView): WorkExecutorMarkView {
		workExecutorMarkView.createTime = new Date(workExecutorMarkView.createTime);
		workExecutorMarkView.modifyTime = new Date(workExecutorMarkView.modifyTime);
		return workExecutorMarkView;
	}

	/**
	 * Проверяет, может ли пользователь оценивать исполнителей указанной работы
	 * @param id идентификатор работы
	 */
	canAssign(id: number): Observable<boolean> {
		const url = `${this.workExecutorMarksUrl}/CanAssign/${id}`;
		return this.http.get<boolean>(url, this.modelService.httpOptions);
	}

	/**
	 * Возвращает список всех общий оценок исполнителей для указанной работы
	 * @param id идентификатор работы
	 */
	one(id: number): Observable<WorkExecutorMarkView> {
		const url = `${this.workExecutorMarksUrl}/One/${id}`;
		return this.http.get<WorkExecutorMarkView>(url, this.modelService.httpOptions)
			.pipe(
				map(x => {
					if (x) {
						return WorkExecutorMarkService.parseDates(x);
					}
					return x;
				}),
			);
	}

	/**
	 * Добавление оценки участника работы
	 * @param workExecutorMarkView оценка участника работы
	 */
	assign(workExecutorMarkView: WorkExecutorMarkView): Observable<WorkExecutorMarkView> {
		return this.http.post<WorkExecutorMarkView>(this.workExecutorMarksUrl, workExecutorMarkView, this.modelService.httpOptions)
			.pipe(
				tap(x => {
					if (x.hasOwnProperty('error')) {
						throw x;
					}
				})
			);
	}

	/**
	 * Возвращает список оценок специалиста
	 * @param id идентификтор специалиста
	 * @param pageIndex
	 * @param pageSize
	 */
	ofUser(id: string, pageIndex = 0, pageSize = 0): Observable<WorkExecutorMarkView[]> {
		const url = `${this.workExecutorMarksUrl}/OfUser/${id}`;
		return this.http.post<WorkExecutorMarkView[]>(url, null, {
				params: new HttpParams()
					.set('pageIndex', pageIndex.toString())
					.set('pageSize', pageSize.toString()),
				headers: this.modelService.httpOptions.headers
			})
			.pipe(
				map(xx => xx.map(x => WorkExecutorMarkService.parseDates(x)))
			);
	}

	ofUserCount(id: string): Observable<number> {
		const url = `${this.workExecutorMarksUrl}/OfUserCount/${id}`;
		return this.http.post<number>(url, null, this.modelService.httpOptions);
	}
}
