import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppUserView } from '@models/app-user-view';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { filter, takeUntil } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { HardSkillView } from '@models/hard-skill-view';
import {
	AppUserHardSkillsDialogComponent
} from '@shared/app-user-hard-skills-dialog/app-user-hard-skills-dialog.component';
import { pushFakeHistoryState } from '@utils/functions';
import { SkillStateValue } from '@models/abstract-skill-view';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { AppUserService } from '@core/services/app-user.service';

@Component({
	selector: 'pdw-user-hard-skills',
	templateUrl: './app-user-hard-skills.component.html',
})
export class AppUserHardSkillsComponent implements OnInit {
	@Input() canUpdate: boolean;

	@Input()
	set currentUserView(value: AppUserView) {
		this._currentUserView.next(value);
	};

	get currentUserView(): AppUserView {
		return this._currentUserView.getValue();
	}

	private _currentUserView = new BehaviorSubject<AppUserView>(null);

	currentAppUserView = new BehaviorSubject<AppUserView>(null);
	@Output() changed = new EventEmitter<any>();

	constructor(
		private dialog: MatDialog,
		private notificationService: NotificationService,
		private userService: AppUserService,
		private destroy$: DestroyService
	) {
	}

	ngOnInit(): void {
		this._currentUserView
			.pipe(
				filter(x => x !== null),
				takeUntil(this.destroy$)
			)
			.subscribe((appUserView: AppUserView) => {
				this.currentAppUserView.next(appUserView);
			});
	}

	getTooltip(hardSkillView: HardSkillView): string {
		if (hardSkillView.state === SkillStateValue.created) {
			return 'Навык на модерации';
		}
		return hardSkillView.tooltip;
	}

	goUpdateHardSkills(): void {
		pushFakeHistoryState();

		this.dialog.open(AppUserHardSkillsDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Профессиональные навыки',
					method: 'update',
					appUserView: this.currentAppUserView.value
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: (data) => {
					this.userService.setActiveUser(data);
					this.currentAppUserView.next(data);
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}
}
