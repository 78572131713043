import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureService } from '@core/services/feature.service';

@Directive({
	selector: '[pdwFeatureFlag]'
})
export class FeatureFlagDirective implements OnInit {

	constructor(private featureService: FeatureService, private tpl: TemplateRef<any>, private vcr: ViewContainerRef) {
	}
	ngOnInit() {
		if (!this.featureService.inCurrentRelease) {
			this.vcr.createEmbeddedView(this.tpl);
		}
	}

}
