import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfdepoAiBlockComponent } from './profdepo-ai-block.component';
import { RatingThemeModule } from '@shared/directives/rating-theme/rating-theme.module';
import { ProgressBarModule } from '@shared/components/ui/progress-bar/progress-bar.module';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
	declarations: [ProfdepoAiBlockComponent],
	exports: [ProfdepoAiBlockComponent],
	imports: [
		CommonModule,
		RatingThemeModule,
		ProgressBarModule,
		MatIconModule,
	]
})
export class ProfdepoAiBlockModule {
}
