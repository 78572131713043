import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WorkRequestView } from '@models/work-request-view';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { DestroyService } from '@profdepo-ui/core';
import { catchError, map, startWith, takeUntil } from 'rxjs/operators';
import { TrueLoadingService } from '@core/services/true-loading.service';
import { WorkRequestService } from '@core/services/work-request.service';

@Component({
	selector: 'pdw-complete-invite-specialist-dialog',
	templateUrl: './complete-invite-specialist-dialog.component.html',
	styleUrls: ['./complete-invite-specialist-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, TrueLoadingService]
})
export class CompleteInviteSpecialistDialogComponent {

	executorControl: FormControl = new FormControl(null);
	searchControl: FormControl = new FormControl<string>(null);
	section: number = null;
	executors$: Observable<WorkRequestView[]>;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { executors: WorkRequestView[], workId: number },
		private destroy$: DestroyService,
		public loading$: TrueLoadingService,
		private workRequestService: WorkRequestService,
		public dialogRef: MatDialogRef<CompleteInviteSpecialistDialogComponent>,
	) {
		this.loading$.next(false);
		this.executors$ = this.searchControl
			.valueChanges.pipe(
				startWith(''),
				map((query: string) => this.data.executors.filter(req => req.executor.titleLong.toLowerCase().includes(query.toLowerCase()))),
				takeUntil(this.destroy$)
			)
	}

	isSearchEmpty(): boolean {
		return this.searchControl.value === null || this.searchControl.value === '';
	}

	get isValueEmpty(): boolean {
		return this.section === null;
	}

	sendRequest(): void {
		this.loading$.next(true);
		this.workRequestService.chooseCandidateToVacancy(this.data.workId, this.section > 0
				? this.section.toString()
				: null)
			.pipe(
				catchError(() => {
					this.loading$.next(false);
					return 'Что-то пошло не так';
				}),
				takeUntil(this.destroy$)
			).subscribe(() => {
			this.loading$.next(false);
			this.dialogRef.close();
		});
	}

	toggleSelected(request: number): void {
		if (!request) {
			this.section = null;
			return;
		}

		this.section = request === this.section ? null : request;
	}

	hasSelected(request: number): boolean {
		return this.section === request;
	}

}
