import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, forkJoin, Subject, switchMap} from "rxjs";
import {AppUserService} from "@core/services/app-user.service";
import {filter, takeUntil} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {errorTitle} from "@utils/helpers/error-helpers";
import {WorkStateValue} from "@models/work-view";
import {WorkExecutorMarkView} from "@models/work-executor-mark-view";
import {WorkExecutorMarkService} from "@core/services/work-executor-mark.service";
import {
  WorkMarksManagerDialogComponent
} from "@shared/work-marks-manager-dialog/work-marks-manager-dialog.component";
import {pushFakeHistoryState} from "@utils/functions";
import {WorkMarkService} from "@core/services/work-mark.service";
import {WorkMarkView} from "@models/work-mark-view";
import {WorkAbstractComponent} from "@shared/work-abstract-component/work-abstract-component";
import {NotificationService} from "@profdepo-ui/core";

@Component({
  selector: 'app-work-marks-manager',
  templateUrl: './work-marks-manager.component.html',
})
export class WorkMarksManagerComponent extends WorkAbstractComponent implements OnInit, OnDestroy {
  @Input('columnsCount')
  set columnsCount(value: number) {
    this._columnsCount.next(value);
  };

  get columnsCount(): number {
    return this._columnsCount.getValue();
  }

  private _columnsCount = new BehaviorSubject<number>(null);

  workExecutorMarkView = new BehaviorSubject<WorkExecutorMarkView>(null);
  workMarkView = new BehaviorSubject<WorkMarkView>(null);
  _canAssign = new BehaviorSubject<boolean>(null);
  isLoading = new BehaviorSubject<boolean>(false);

  @Output() changed = new EventEmitter<any>();
  unsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private appUserService: AppUserService,
    private workExecutorMarkService: WorkExecutorMarkService,
    private workMarkService: WorkMarkService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading.next(true);
    this.workViewSubject
      .pipe(
        filter(x => x !== null),
        switchMap(x => forkJoin([
            this.workExecutorMarkService.canAssign(x.id),
            this.workExecutorMarkService.one(x.id),
            this.workMarkService.one(x.id)
          ])
            .pipe(
              takeUntil(this.unsubscribe)
            )
        )
      )
      .subscribe({
        next: (data) => {
          this.isLoading.next(false);
          this._canAssign.next(data[0]);
          this.workExecutorMarkView.next(data[1]);
          this.workMarkView.next(data[2]);
        }
      });
  }

  getTooltip(): string {
    const canAssign = this._canAssign.getValue();

    if (this.workView.state < WorkStateValue.successed && canAssign) {
      return 'Вы сможете поставить оценку, после завершения работы';
    } else if (this.workView.state < WorkStateValue.successed && !canAssign) {
      return 'Вы не можете поставить оценку'
    }

    return null;
  }

  get canAssign(): boolean {
    return this._canAssign.getValue();
  }

  goAssign(): void {
    pushFakeHistoryState();

    const workExecutorMarkView = new WorkExecutorMarkView();
    workExecutorMarkView.work = this.workView;

    this.dialog.open(WorkMarksManagerDialogComponent, {
      autoFocus: true,
      disableClose: false,
      width: '581px',
      minHeight: '300px',
      panelClass: ['pd-dialog'],
      closeOnNavigation: false,
      data: {
        title: 'Оценка специалиста',
        workView: workExecutorMarkView.work,
      }
    })
      .afterClosed()
      .pipe(
        filter(result => result),
      )
      .subscribe({
        next: (data) => {
          this.changed.emit('create');
        },
        error: (err) => {
          this.notificationService.showDanger(errorTitle(err));
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }
}
