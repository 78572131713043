import { Pipe, PipeTransform } from '@angular/core';
import { AbstractExtensionEnumPipe } from '@public/pipes/abstract-extension-enum.pipe';

@Pipe({
	name: 'educationLevel'
})
export class EducationLevelPipe extends AbstractExtensionEnumPipe implements PipeTransform {
	values = {
		'-1': 'Нет образования',
		0: 'Среднее',
		1: 'Среднее специальное',
		2: 'Неоконченное высшее',
		3: 'Высшее',
		4: 'Бакалавр',
		5: 'Магистр',
		6: 'Кандидат наук',
		7: 'Доктор наук',
	};

	transform(value: any, ...args: any[]): any {
		return this.getTransform(value, args);
	}
}
