import { Directive, ElementRef, Renderer2 } from "@angular/core";

@Directive({
	selector: "[fitContent]",
})
export class FitContentDirective {
	constructor(private element: ElementRef, private renderer: Renderer2) {
		this.renderer.setStyle(this.element.nativeElement, "width", "fit-content");
	}
}
