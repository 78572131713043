import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Router } from '@angular/router';
import { PdIconService } from '@profdepo-ui/core';
import { NavigationService } from '@core/services/navigation.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
	title = 'profdepo-client';

	constructor(
		private router: Router,
		private iconService: PdIconService,
		private swUpdate: SwUpdate,
		private navigationService: NavigationService
	) {
		iconService.registerDefaultIcons();
		this.navigationService.initHistory();
	}

	ngOnInit() {
		window.addEventListener('popstate', () => {
			if (history.state?.fakeState) {
				history.back();
			}
		});

		this.swUpdate.versionUpdates
			.subscribe((event) => {
				if (event.type == 'VERSION_READY') {
					this.swUpdate.activateUpdate()
						.then(() => document.location.reload());
				}
			});

		if (this.swUpdate.isEnabled) {
			this.swUpdate.checkForUpdate()
				.then();
		}
	}
}
