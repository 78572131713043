<ng-container *ngIf='(loadingData | async); else isLoading'>
			<div class='pd-companies'>
				<pdw-company-card
					[commands]="['/companies', element.id]"
					*ngFor='let element of companiesList'
					[companyView]='element'
				>
				</pdw-company-card>
			</div>
			<div *ngIf='remain | async as remain; else noRemain'>
				<button
					class='pd-button-additional pd-button-additional-cards'
					*ngIf='remain > 0; else noRemain'
					[disabled]='loadingNextPage | async'
				>
					{{loadingNextPage.value ? 'Загружаем' : 'Показать еще' }}
				</button>
			</div>
			<ng-template #noRemain>
				<ng-container *ngIf='pageIndex !== 0'>
					<p class='pd-basis-no-remain'>Вы посмотрели все компании</p>
				</ng-container>
			</ng-template>
		</ng-container>
		<ng-template #emptySource>
			<div class='pd-basis-card-empty'>
				<div class='pd-basis-card-empty-inner'>
					<mat-icon
						class='pd-icon'
						[svgIcon]="'additional2-alert-exclamation-2-100-100'"
					></mat-icon>
					<h2 class='pd-basis-card-empty-title'>По вашему запросу ничего не найдено</h2>
					<p class='pd-basis-card-empty-subtitle'>Измените поисковый запрос</p>
				</div>
			</div>
		</ng-template>
<ng-template #isLoading>
	<div class='pd-basis-card-empty'>
		<div class='pd-basis-card-empty-inner'>
			<mat-spinner
				[diameter]='70'
				[strokeWidth]='5'
			></mat-spinner>
		</div>
	</div>
</ng-template>
