import {WorkView} from "@models/work-view";
import {AppUserView} from "@models/app-user-view";
import {AbstractModifiedModelView} from "@models/abstract-modified-model-view";

export class WorkMarkView extends AbstractModifiedModelView{
  public id: number;
  public work: WorkView;
  public assigner: AppUserView;
  public speedValue: number;
  public targetryValue: number;
  public adequacyValue: number;
  public comment: string;
}
