import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, skip, Subject } from 'rxjs';
import { distinctUntilChanged, filter, finalize, takeUntil } from 'rxjs/operators';
import { AppUserService, AppUsersFilter } from '@core/services/app-user.service';
import { MatchedWorkExecutorDataSource } from '@core/dataSources/matched-work-executor-data-source';
import { AnySort } from '@utils/any-sort';
import { WorkAbstractComponent } from '@shared/work-abstract-component/work-abstract-component';
import { AppUserView } from '@models/app-user-view';
import { DestroyService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-work-matched-executor-cards',
	templateUrl: './work-matched-executor-cards.component.html',
	host: { 'class': 'pd-expanded-height' },
	styleUrls: ['./work-matched-executor-cards.component.scss'],
	providers: [DestroyService]
})
export class WorkMatchedExecutorCardsComponent extends WorkAbstractComponent implements OnInit {
	/**
	 * Источник данных для получения записей удовлетворяющих критериям поиска
	 */
	@Input() dataSource: MatchedWorkExecutorDataSource;

	@Input()
	set filterValues(value: AppUsersFilter) {
		this._filterValues.next(value);
	};

	get filterValues(): AppUsersFilter {
		return this._filterValues.getValue();
	}

	private _filterValues = new BehaviorSubject<AppUsersFilter>(null);

	@Input()
	set sortValues(value: AnySort) {
		this._sortValues.next(value);
	};

	get user(): AppUserView {
		return this.appUserService.getCurrentActiveUserView();
	}

	get sortValues(): AnySort {
		return this._sortValues.getValue();
	}

	private _sortValues = new BehaviorSubject<AnySort>(null);
	pageIndex = 0;
	pageSize: number = 50;
	remain = new BehaviorSubject<number>(null);
	loadingData = new BehaviorSubject<boolean>(false);
	loadingNextPage = new BehaviorSubject<boolean>(false);

	constructor(
		private appUserService: AppUserService,
		private destroy$: DestroyService
	) {
		super();
	}

	ngOnInit(): void {
		this.loadingData.next(true);

		this.load(this.pageIndex, this.pageSize);
		this.loadCount();

		this._filterValues
			.pipe(
				skip(1),
				takeUntil(this.destroy$),
				filter(x => x !== null),
				distinctUntilChanged(),
			)
			.subscribe(data => {
				this.pageIndex = 0;
				this.loadingData.next(true);
				this.load(this.pageIndex, this.pageSize);
				this.loadCount();
			});

		this._sortValues
			.pipe(
				skip(1),
				takeUntil(this.destroy$),
				filter(x => x !== null),
				distinctUntilChanged(),
			)
			.subscribe(data => {
				this.loadingData.next(true);
				this.load(0, this.pageSize * (this.pageIndex + 1));
			});

		this.appUserService.shouldReload
			.pipe(
				takeUntil(this.destroy$),
				filter(x => x === true)
			)
			.subscribe({
				next: v => {
					this.loadingData.next(true);
					this.load(this.pageIndex, this.pageSize);
					this.loadCount();
				}
			});
	}

	loadCount(): void {
		this.dataSource.loadCount(this.filterValues)
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe(x => {
				const remain = x - (this.pageIndex + 1) * this.pageSize;
				this.remain.next(remain > this.pageSize ? this.pageSize : remain);
			});
	}

	/**
	 * Загружает указанную страницу данных указанного размера
	 * @param pageIndex номер страницы
	 * @param pageSize размер страницы
	 */
	load(pageIndex: number, pageSize: number): void {
		this.dataSource.load(
				this.filterValues,
				this.sortValues.name + ',' + this.sortValues.direction,
				pageIndex,
				pageSize)
			.pipe(
				finalize(() => {
					this.appUserService.shouldReload.next(false);
					this.loadingData.next(false);
					this.loadingNextPage.next(false);
				}),
				takeUntil(this.destroy$),
			)
			.subscribe(x => {
				this.appUserService.shouldReload.next(false);
				this.loadingData.next(false);
				this.loadingNextPage.next(false);
			});
	}

	/**
	 * Загружает указанную страницу данных указанного размера
	 * @param pageIndex номер страницы
	 * @param pageSize размер страницы
	 */
	loadMore(pageIndex: number, pageSize: number): void {
		this.dataSource.loadMore(
				this.filterValues,
				this.sortValues.name + ',' + this.sortValues.direction,
				pageIndex,
				pageSize)
			.pipe(
				finalize(() => {
					this.appUserService.shouldReload.next(false);
					this.loadingData.next(false);
					this.loadingNextPage.next(false);
				}),
				takeUntil(this.destroy$),
			)
			.subscribe(x => {
				this.appUserService.shouldReload.next(false);
				this.loadingData.next(false);
				this.loadingNextPage.next(false);
			});
	}

	/**
	 * Загружает следующую страницу данных
	 */
	goNextPage(): void {
		this.loadingNextPage.next(true);
		this.pageIndex++;
		this.loadMore(this.pageIndex, this.pageSize);
		this.loadCount();
	}

	onChange(appUserView: any) {
		this.appUserService.shouldReload.next(true);
	}
}
