import { animate, state, style, transition, trigger } from '@angular/animations';

export const progressBarAnimations = [
	trigger('value', [
		state('true',
			style({
				width: '{{value}}%',
			}),
			{ params: { value: 0 } }
		),
		state('false',
			style({
				width: '0px',
			}),
		),
		transition('* <=> *', animate('425ms cubic-bezier(.54,.12,.63,.87)')),
	])
]

