<ng-container [formGroup]='form'>
	<mat-form-field
		class='pd-input-form-field'
		appearance='fill'
		hideRequiredMarker
	>
		<mat-label>{{ getControlByName('name') | work: 'propertyTitle' }}</mat-label>
		<input
			matInput
			formControlName='name'
			type='text'
		>
		<mat-error
			*ngIf='getControlByName("name")?.errors?.required'
			i18n='mat-error|Заполнение работы@@work-details-dialog-name-required'
		>Обязательное поле
		</mat-error>
		<mat-error
			*ngIf='getControlByName("name")?.errors?.minlength'
			i18n='mat-error|Заполнение работы@@work-details-dialog-name-minlength'
		>Введите минимум 3 символа!
		</mat-error>
		<mat-error
			*ngIf='getControlByName("name")?.errors?.maxlength'
			i18n='mat-error|Заполнение работы@@work-details-dialog-name-maxlength'
		>Введите максимум 120 символов!
		</mat-error>
		<mat-error *ngIf='getControlByName("name")?.errors?.serverError'>
			{{ getControlByName("name")?.errors?.serverError }}
		</mat-error>
	</mat-form-field>

	<mat-form-field
		class='pd-input-form-field'
		appearance='fill'
		hideRequiredMarker
		*ngIf='!isEditMode'
	>
		<mat-label>Компания</mat-label>
		<pd-select
			formControlName='company'
		>
			<pd-select-option
				*ngFor='let item of getControlInfo("company").dataAsObservable | async'
				[value]='item'
			>
				{{item.title}}
			</pd-select-option>
		</pd-select>
		<button
			class='pd-icon-button gray'
			matSuffix
			mat-icon-button
			type='button'
		>
			<mat-icon
				class='pd-icon'
				svgIcon='arrow-down-2'
			></mat-icon>
		</button>
		<mat-error *ngIf='form.get("company")?.errors?.required'>Обязательное поле</mat-error>
	</mat-form-field>


	<mat-form-field
		class='pd-input-form-field'
		appearance='fill'
		*ngIf='!isEditMode'
	>
		<mat-label>Город</mat-label>
		<mat-icon
			class='pd-field-prefix-icon'
			svgIcon='additional-location'
			matPrefix
		></mat-icon>
		<input
			matInput
			[formControl]='cityAutocomplete'
			[matAutocomplete]='auto'
		>
		<mat-autocomplete
			class='pd-autocomplete'
			#auto='matAutocomplete'
			[displayWith]='getCityName'
			(optionSelected)='onCitySelect($event.option.value)'

		>
			<tui-scrollbar class='pd-scrollbar pd-autocomplete-scrollbar'>
				<mat-option
					class='pd-option'
					[value]='item'
					*ngFor='let item of cities'
				>
					{{item.name}}
				</mat-option>
			</tui-scrollbar>
		</mat-autocomplete>
	</mat-form-field>


	<mat-form-field
		class='pd-input-form-field'
		appearance='fill'
		hideRequiredMarker
		*ngIf='!isEditMode'
	>
		<mat-label>Выберите тип работы</mat-label>
		<mat-icon
			class='pd-field-prefix-icon'
			svgIcon='additional-dashbord'
			matPrefix
		></mat-icon>
		<pd-select
			formControlName='subCategories'
			[compareWith]='parent.compareSubCategoryView'
			[disabled]='parent.isNotDraftState()'
			multiple
		>
			<pd-optgroup
				*ngFor='let group of getControlInfo("subCategories").dataAsObservable | async | keyvalue'
				[label]='group.value[0]?.category?.name'
			>
				<pd-select-option
					[disabled]='parent.selectOptionDisabled(getControlByName("subCategories"), 5, item)'
					*ngFor='let item of $any(group.value)'
					[value]='item'
				>
					{{ item.name }}
				</pd-select-option>
			</pd-optgroup>
		</pd-select>
		<button
			class='pd-icon-button gray'
			matSuffix
			mat-icon-button
			type='button'
		>
			<mat-icon
				class='pd-icon'
				svgIcon='arrow-down-2'
			></mat-icon>
		</button>
		<mat-error *ngIf='getControlByName("subCategories")?.errors?.serverError'>
			{{ getControlByName("subCategories")?.errors?.serverError }}
		</mat-error>
		<mat-error *ngIf='getControlByName("subCategories")?.errors?.count'>
			Укажите максимум 5 типов!
		</mat-error>
	</mat-form-field>

	<!-- Description -->
	<mat-form-field
		class='pd-input-form-field pd-textarea-form-field'
		appearance='fill'
		appExtendableTextArea
		hideRequiredMarker
	>
		<mat-label>{{ 'description' | work: 'propertyTitle' }}</mat-label>
		<tui-scrollbar class='pd-scrollbar pd-textarea-scrollbar'>
			<div class='pd-textarea-wrapper'>
				<div class='pd-textarea-relative-wrapper'>
					<div class='pd-textarea-pseudo-content'>
						<span class='pd-textarea-pseudo'></span>
						&nbsp;
					</div>
					<textarea
						class='pd-textarea'
						matInput
						formControlName='description'
						rows='5'
						maxlength='10000'
					></textarea>
				</div>
			</div>
		</tui-scrollbar>
		<button
			class='pd-icon-button gray'
			matSuffix
			mat-icon-button
			type='button'
			tabindex='-1'
		>
			<mat-icon
				class='pd-icon'
				svgIcon='additional2-stretch'
			></mat-icon>
		</button>
		<mat-error
			*ngIf='getControlByName("description")?.errors?.required'
			i18n='mat-error|Заполнение работы@@work-details-dialog-description-required'
		>Обязательное поле
		</mat-error>
		<mat-error *ngIf='getControlByName("description").errors?.serverError'>
			{{ getControlByName("description").errors?.serverError }}
		</mat-error>
	</mat-form-field>

	<pdw-chips-select
		*ngIf='!isEditMode'
		[isMultiple]='true'
		formControlName='hardSkills'
		[options]='getControlInfo("hardSkills").emitData'
		label='{{ "hardSkills" | work: "propertyTitle" }}'
	>
		<mat-error
			class='custom-error'
			*ngIf='getControlByName("hardSkills")?.errors?.required && getControlByName("hardSkills").touched'
			i18n='mat-error|Заполнение работы@@work-details-dialog-description-required'
		>Обязательное поле
		</mat-error>
	</pdw-chips-select>

	<div class='pd-dialog-row'>
		<!-- Start Date -->
		<mat-form-field
			class='pd-input-form-field pd-dialog-half-row'
			appearance='fill'
			hideRequiredMarker
		>
			<mat-label>{{ 'startDate' | work: 'propertyTitle' }}</mat-label>
			<input
				matInput
				formControlName='startDate'
				[matDatepicker]='planStartDatePicker'
			>
			<mat-datepicker-toggle
				class='pd-datepicker-toggle'
				matSuffix
				[for]='planStartDatePicker'
			>
				<mat-icon
					svgIcon='arrow-down-2'
					matDatepickerToggleIcon
				></mat-icon>
			</mat-datepicker-toggle>
			<mat-datepicker
				[panelClass]="'pd-calendar'"
				#planStartDatePicker
				startView='multi-year'
			></mat-datepicker>
			<mat-error *ngIf='getControlByName("startDate").errors?.serverError'>
				{{ getControlByName("startDate").errors?.serverError }}
			</mat-error>
			<mat-error
				*ngIf='getControlByName("startDate").errors?.invalidDate'
				i18n='mat-error|Заполнение работы@@work-details-dialog-endDate-invalidEndDate'
			>Укажите дату раньше даты окончания!
			</mat-error>
			<mat-error
				*ngIf='getControlByName("startDate").errors?.required'
				i18n='mat-error|Заполнение работы@@work-details-dialog-startDate-required'
			>
				Обязательное поле
			</mat-error>
		</mat-form-field>
		<!--End Date -->
		<mat-form-field
			class='pd-input-form-field pd-dialog-half-row'
			appearance='fill'
			hideRequiredMarker
		>
			<mat-label>{{ 'endDate' | work: 'propertyTitle' }}</mat-label>
			<input
				matInput
				formControlName='endDate'
				[matDatepicker]='planEndDatePicker'
			>
			<mat-datepicker-toggle
				class='pd-datepicker-toggle'
				matSuffix
				[for]='planEndDatePicker'
			>
				<mat-icon
					svgIcon='arrow-down-2'
					matDatepickerToggleIcon
				></mat-icon>
			</mat-datepicker-toggle>
			<mat-datepicker
				[panelClass]="'pd-calendar'"
				#planEndDatePicker
				startView='multi-year'
			></mat-datepicker>
			<mat-error
				*ngIf='getControlByName("endDate").errors?.invalidDate'
				i18n='mat-error|Заполнение работы@@work-details-dialog-endDate-invalidEndDate'
			>Укажите дату окончания позже даты начала!
			</mat-error>
			<mat-error *ngIf='getControlByName("endDate").errors?.serverError'>
				{{ getControlByName("endDate").errors?.serverError }}
			</mat-error>
			<mat-error
				*ngIf='getControlByName("endDate").errors?.required'
				i18n='mat-error|Заполнение работы@@work-details-dialog-endDate-required'
			>
				Обязательное поле
			</mat-error>
		</mat-form-field>

		<div class='pd-taiga-input-wrapper'>
			<tui-input-number
				class='pd-taiga-input'
				[min]='1'
				[decimal]="'never'"
				[ngClass]="{'pd-taiga-input-empty': parent.isEmptyValue(getControlByName('amount'))}"
				formControlName='amount'
			>
				{{ 'amount' | work: 'propertyTitle' }}
			</tui-input-number>
			<mat-error
				*ngIf='getControlByName("amount").errors?.required'
				i18n='mat-error|Заполнение работы@@work-details-dialog-cost-required'
			>
				Обязательное поле
			</mat-error>
			<mat-error *ngIf='getControlByName("amount").errors?.serverError'>
				{{ getControlByName("amount").errors?.serverError }}
			</mat-error>
		</div>
	</div>

	<form
		id='workFilesForm'
	>
		<div class='pd-dialog-column'>
			<button
				class='pd-button-additional'
				(click)='fileInput.click()'
				type='button'
			>
				<mat-icon
					class='pd-icon black'
					svgIcon='files-attachment'
				></mat-icon>
				Выберите один или несколько файлов
			</button>
			<input
				hidden
				type='file'
				multiple
				(change)='onSelectFiles($event)'
				#fileInput
			/>
			<div
			>
				<div>

					<div class='pd-dialog-column'>
						<div class='pd-dialog-column pd-dialog-files-column'>
							<div
								*ngFor='let file of files.value; trackBy: trackByFile'
								class='pd-dialog-row pd-dialog-files-row'
							>
								<div class='pd-dialog-files-file'>
									<p class='pd-dialog-files-name'>{{file.name}}</p>
									<mat-progress-bar
										[mode]="true ? 'determinate' : 'indeterminate'"
										[value]='100'
										[color]="true ? 'primary' : 'warn'"
									>
									</mat-progress-bar>
								</div>
								<div class='pd-dialog-row pd-dialog-icon-buttons'>
									<!--Change Item-->
									<button
										type='button'
										class='pd-icon-button'
										mat-icon-button
										(click)='singleFileInput.click()'
									>
										<mat-icon svgIcon='additional2-regroup'></mat-icon>
									</button>
									<input
										hidden
										type='file'
										(change)='changeFile(file.name, $event)'
										#singleFileInput
									/>
									<!--Remove Item-->
									<button
										type='button'
										class='pd-icon-button'
										mat-icon-button
										(click)='deleteFile(file.name)'
									>
										<mat-icon svgIcon='additional-trash-2'></mat-icon>
									</button>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</form>
</ng-container>

