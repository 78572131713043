import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output
} from '@angular/core';

export type AlertType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info';

@Component({
	selector: 'pdw-alert',
	templateUrl: './alert.component.html',
	host: { 'class': 'pd-snackbar' },
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent implements OnInit {

	@HostBinding('class') colorClass: string = '';

	@Input() alertType: AlertType = 'info';

	@Input() canClose: boolean = true;

	@Output() closeEmit = new EventEmitter<boolean>();

	close() {
		this.el.nativeElement.parentElement.removeChild(this.el.nativeElement);
		this.closeEmit.emit(true);
	}


	constructor(private el: ElementRef) {
	}


	ngOnInit(): void {
		this.colorClass = this.alertType + '-snackbar';
	}

}
