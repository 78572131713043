import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {WorkExecutorMarkView} from "@models/work-executor-mark-view";
import {BehaviorSubject, Subject} from "rxjs";
import {filter, takeUntil} from "rxjs/operators";
import {pushFakeHistoryState} from "@utils/functions";
import {
  WorkExecutorMarkCardDialogComponent
} from "@shared/work-executor-mark-card-dialog/work-executor-mark-card-dialog.component";
import {CompanyView} from "@models/company-view";
import {YesNo} from "@models/enums";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-work-executor-mark-card',
  templateUrl: './work-executor-mark-card.component.html',
  host: {'class': 'pd-marks-card'}
})
export class WorkExecutorMarkCardComponent implements OnInit, OnDestroy {
  @Input('workExecutorMarkView')
  set workExecutorMarkView(value: WorkExecutorMarkView) {
    this._workExecutorMarkView.next(value);
  };
  @Input() isShortMode = false;

  get workExecutorMarkView(): WorkExecutorMarkView {
    return this._workExecutorMarkView.getValue();
  }

  private _workExecutorMarkView = new BehaviorSubject<WorkExecutorMarkView>(null);
  currentWorkExecutorMarkView = new BehaviorSubject<WorkExecutorMarkView>(null);
  unsubscribe: Subject<any> = new Subject<any>();

  constructor(private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this._workExecutorMarkView
      .pipe(
        takeUntil(this.unsubscribe),
        filter(x => x !== null)
      )
      .subscribe({
        next: v => this.currentWorkExecutorMarkView.next(v)
      });
  }


  openDialog(workExecutorMarkView: WorkExecutorMarkView): void {
    pushFakeHistoryState();

    this.dialog.open(WorkExecutorMarkCardDialogComponent, {
      autoFocus: true,
      disableClose: false,
      width: '581px',
      panelClass: ['pd-dialog', 'pd-dialog-without-footer'],
      data: {
        title: 'Отзыв о работе',
        workExecutorMarkView: workExecutorMarkView
      }
    });
  }

  companyHasLogo(company: CompanyView): boolean {
    return company.hasLogo === YesNo.yes;
  }

  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }
}
