import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, withLatestFrom } from 'rxjs';
import { distinctUntilChanged, filter, finalize, takeUntil } from 'rxjs/operators';
import { CompanyMarksDataSource } from '@core/dataSources/company-marks-data-source';
import { DestroyService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-work-mark-cards',
	templateUrl: './work-mark-cards.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService],
	styleUrls: ['./work-mark-cards.component.scss']
})
export class WorkMarkCardsComponent implements OnInit {

	/**
	 * Источник данных
	 */

	@Input() dataSource: CompanyMarksDataSource;

	/**
	 * Количество колонок
	 * @param value количество колонок
	 */
	@Input()
	set columnsCount(value: number) {
		this._columnsCount.next(value);
	};

	get columnsCount(): number {
		return this._columnsCount.getValue();
	}

	private _columnsCount = new BehaviorSubject<number>(null);
	/**
	 * Ширина одной карточки специалиста
	 * Размер должен быть согласован так, чтобы перенос карточек в браузере
	 * при изменении размера окна совпадал с пересчетом количеста колонок
	 */
	@Input() cardWidth: number = 391;
	pageIndex = 0;
	pageSize = 5;
	remain = new BehaviorSubject<number>(null);
	loadingData = new BehaviorSubject<boolean>(false);
	loadingNextPage = new BehaviorSubject<boolean>(false);

	constructor(private destroy$: DestroyService) {
	}

	ngOnInit(): void {
		this.showFirstReview();
	}

	/**
	 * Определяет актуальный номер страницы исходя из количества загруженных данных и размера страницы
	 * @param dataLength количества загруженных данных
	 * @param pageSize размера страницы
	 */
	calcPageIndex(dataLength: number, pageSize: number): any {
		const pageIndex = dataLength / pageSize;
		const pageIndexTrunc = Math.floor(pageIndex);

		/**
		 * Если вычисленный номер страницы не дотягивает до двух,
		 * то значит загрузить только первую страницу
		 */
		if (pageIndex < 2.0) {
			return {
				pageIndex: 0,
				reload: dataLength < pageSize
			};
		}

		/**
		 * В остальных случаях округлять страницу в меньшую сторону
		 */
		return {
			pageIndex: pageIndexTrunc - 1,
			reload: dataLength < pageSize * pageIndexTrunc
		}
	}

	/**
	 * Определяет размер страницы для указанного количества колонок
	 * @param columnsCount количество колонок
	 */
	calcPageSize(columnsCount: number): number {
		if (columnsCount > 2)
			return columnsCount;

		switch (columnsCount) {
			case 1:
				return 3;
			case 2:
				return 4;
			default:
				return 3;
		}
	}

	loadCount(): void {
		this.dataSource.loadCount()
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe(x => {
				const remain = x - (this.pageIndex + 1) * this.pageSize;
				this.remain.next(remain > this.pageSize ? this.pageSize : remain);
			});
	}

	/**
	 * Загружает указанную страницу данных указанного размера
	 * @param pageIndex номер страницы
	 * @param pageSize размер страницы
	 */
	load(pageIndex: number, pageSize: number): void {
		this.dataSource.load(pageIndex, pageSize)
			.pipe(
				finalize(() => {
					this.loadingData.next(false);
					this.loadingNextPage.next(false);
				}),
				takeUntil(this.destroy$),
			)
			.subscribe(() => {
				this.loadingData.next(false);
				this.loadingNextPage.next(false);
			});
	}

	/**
	 * Загружает указанную страницу данных указанного размера
	 * @param pageIndex номер страницы
	 * @param pageSize размер страницы
	 */
	loadMore(pageIndex: number, pageSize: number): void {
		this.dataSource.loadMore(pageIndex, pageSize)
			.pipe(
				finalize(() => {
					this.loadingData.next(false);
					this.loadingNextPage.next(false);
				}),
				takeUntil(this.destroy$),
			)
			.subscribe(() => {
				this.loadingData.next(false);
				this.loadingNextPage.next(false);
			});
	}

	/**
	 * Загружает следующую страницу данных
	 */
	goNextPage(): void {
		this.loadingNextPage.next(true);
		this.pageIndex++;
		this.loadMore(this.pageIndex, this.pageSize);
		this.loadCount();
	}

	showFirstReview(): void {
		this.pageIndex = 0;
		this.load(this.pageIndex, this.pageSize);
		this.loadCount();
		this._columnsCount
			.pipe(
				filter(x => x !== null && x > 0),
				distinctUntilChanged(),
				withLatestFrom(this.dataSource.data),
			)
			.subscribe(data => {
				this.pageSize = this.calcPageSize(data[0]);

				const p = this.calcPageIndex(data[1].length, this.pageSize);
				if (p.reload) {
					this.loadMore(this.pageIndex, this.pageSize);
				}
				this.loadCount();
			});
	}


}
