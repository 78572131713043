import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '../../public/components/mat-table-data-source';
import {WorkView} from "@models/work-view";
import {BehaviorSubject, Subject} from "rxjs";
import {WorkService} from "@core/services/work.service";
import {takeUntil} from "rxjs/operators";
import {errorTitle} from "@utils/helpers/error-helpers";
import {NotificationService} from "@profdepo-ui/core";

@Component({
  selector: 'app-works-table',
  templateUrl: './works-table.component.html',
})
export class WorksTableComponent implements OnInit, OnDestroy {
  @Input() otherLink: any[];
  dataSource: MatTableDataSource<WorkView>;
  initDisplayedColumns = [
    'title',
    'company',
  ];
  displayedColumns = new BehaviorSubject<string[]>(this.initDisplayedColumns);
  unsubscribe: Subject<any> = new Subject<any>();
  constructor(
    private workService: WorkService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.workService.moderate()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe({
        next: (workViews: WorkView[]) => {
          this.dataSource = new MatTableDataSource<WorkView>(workViews);
        },
        error: (err) => {
          this.notificationService.showDanger(errorTitle(err));
        }
      });
  }
  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }
}
