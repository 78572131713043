import {Component, Input} from '@angular/core';
import {AppUserType, AppUserView} from "@models/app-user-view";
import {AppUserTypeValuePipe} from "@public/pipes/app-user-type-value.pipe";

@Component({
  selector: 'app-app-user-link',
  templateUrl: './app-user-link.component.html',
})
export class AppUserLinkComponent {
  @Input() appUserType: AppUserType;
  @Input() appUserView: AppUserView;
  appUserTypeValuePipe = new AppUserTypeValuePipe();
  constructor() { }
}
