import { Directive } from "@angular/core";
import { AbstractControl, FormArray, FormGroup } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { toLowerCaseFirstLetter } from "@utils/helpers/string-helpers";

@Directive({
	selector: "[appAbstractComponent]",
})
export class AbstractComponentDirective {
	constructor() {}

	filloutRelationships<T>(model: T, form: FormGroup) {
		Object.keys(form.getRawValue()).forEach((key) => {
			// console.warn('key 1: ', key);
			const formGroup = form.get(key) as FormGroup;
			// console.warn('formGroup: ', key, formGroup, model);
			if (formGroup.controls) {
				model[key] = this.filloutRelationships(model[key], formGroup);
			} else {
				// console.warn('key 2: ', key, formGroup, form.get(key), form.value[key], model);//, model[key]);
				model[key] = form.get(key).disabled ? model[key] : form.value[key];
			}
		});
		return model;
	}

	private getFormControl(form: FormGroup, prop: string): AbstractControl {
		const subProps = prop.split(".");
		let control = form;
		// console.warn('getFormControl subProps: ', subProps);
		subProps.forEach((v, i, a) => {
			const m = v.match(/(\w+)\[(\d+)\]|(\w+)/);
			// console.warn('getFormControl m: ', m);
			control =
				m[1] && m[2]
					? (control.get(toLowerCaseFirstLetter(m[1])) as FormArray).controls[m[2]]
					: control.get(toLowerCaseFirstLetter(m[3]));
		});
		return control;
	}

	public filloutFormError(form: FormGroup, err: HttpErrorResponse, ignore: string[] = []) {
		const e = err.error.errors ? err.error.errors : err.error;
		Object.keys(e)
			.filter((prop) => !ignore.some((x) => prop.startsWith(x)))
			.forEach((prop) => {
				const formControl = this.getFormControl(form, prop);
				if (formControl) {
					// activate the error message
					formControl.setErrors({
						serverError: e[prop],
					});
				}
			});
	}

	public filloutFormErrorByPropName(form: FormGroup, err: HttpErrorResponse, propName: string) {
		const formControl = this.getFormControl(form, propName);
		if (formControl) {
			formControl.setErrors({
				serverEmailError: true,
			});
		}
	}
}
