import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


export interface BreadCrumb {
	label: string,
	path?: string,
	queryParams?: { from: RootFrom };
}

/**
 * common: Пришли из публичного общего роута
 * details: Пришли из публичного определенного роута
 * private: Пришли из приватного определенного роута
 */
export type RootFrom = 'common' | 'details' | 'private';

@Injectable({
	providedIn: 'root'
})
export class BreadCrumbsService {
	private currentBreadCrumbsSource = new BehaviorSubject<BreadCrumb[]>([]);

	// public shouldTakeWorkTreeFromRoot = new BehaviorSubject<string>('Работы');

	constructor() {
	}

	currentBreadCrumbs = this.currentBreadCrumbsSource.asObservable();

	public clearCurrentBreadCrumbs(): void {
		this.currentBreadCrumbsSource.next(null);
	}

	public setCurrentBreadCrumbs(breadCrumbs: BreadCrumb[]): void {
		this.currentBreadCrumbsSource.next(breadCrumbs);
	}

	public addSpecialistToCurrentBreadCrumbs(breadCrumb: BreadCrumb): void {
		let currentBreadCrumbs = this.currentBreadCrumbsSource.getValue();

		if (currentBreadCrumbs && currentBreadCrumbs.length) {
			const index = currentBreadCrumbs.findIndex(x => x.path === breadCrumb.path);
			if (index < 0) {
				this.currentBreadCrumbsSource.next(currentBreadCrumbs.concat(breadCrumb));
				return;
			}

			if (index > 0) {
				this.currentBreadCrumbsSource.next(currentBreadCrumbs.slice(0, index + 1));
				return;
			}
		}

		currentBreadCrumbs = [
			{ label: 'Специалисты', path: '/users' }
		]

		this.currentBreadCrumbsSource.next(currentBreadCrumbs.concat(breadCrumb));
		return;
	}

	public addClientWorkToCurrentBreadCrumbs(breadCrumbs: BreadCrumb[]): void {
		let currentBreadCrumbs = this.currentBreadCrumbsSource.getValue();

		if (currentBreadCrumbs && currentBreadCrumbs.length) {
			const index = currentBreadCrumbs.findIndex(x => x.path === breadCrumbs[0].path);
			if (index < 0) {
				this.currentBreadCrumbsSource.next(currentBreadCrumbs.concat(breadCrumbs));
				return;
			}

			if (index > 0) {
				this.currentBreadCrumbsSource.next(currentBreadCrumbs.slice(0, index).concat(breadCrumbs));
				return;
			}
		}

		currentBreadCrumbs = [
			{ label: 'Работы', path: '/works' }
		]

		this.currentBreadCrumbsSource.next(currentBreadCrumbs.concat(breadCrumbs));
		return;
	}
}
