import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {AbstractSkillView} from "@models/abstract-skill-view";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Location} from "@angular/common";
import {filter, takeUntil} from "rxjs/operators";
import {AbstractComponentDirective} from "@shared/abstract-component.directive";
import {Router} from "@angular/router";
import {AppUserTypeValuePipe} from "@public/pipes/app-user-type-value.pipe";
import {errorTitle, is400} from "@utils/helpers/error-helpers";
import {AbstractSkillService} from "@core/services/abstract-skill.service";
import {NotificationService} from "@profdepo-ui/core";

@Component({
  selector: 'app-skill-form',
  templateUrl: './skill-form.component.html',
})
export class SkillFormComponent extends AbstractComponentDirective implements OnInit, OnDestroy {
  @Input() viewLink: any[];
  @Input() method: string;
  @Input('skillView')
  set skillView(value: AbstractSkillView) {
    this._skillView.next(value);
  };
  get skillView(): AbstractSkillView {
    return this._skillView.getValue();
  }
  private _skillView = new BehaviorSubject<AbstractSkillView>(null);
  @Input() skillService: AbstractSkillService;
  skillViewForm: FormGroup;
  sections = new BehaviorSubject<string[]>([]);
  saving = new BehaviorSubject<boolean>(false);
  appUserTypeValuePipe = new AppUserTypeValuePipe();
  unsubscribe: Subject<any> = new Subject<any>();
  constructor(
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService
  ) {
    super();
  }

  ngOnInit(): void {
    this._skillView
      .pipe(
        filter(data => data !== null)
      )
      .subscribe(skillView => {
        this.skillViewForm = this.formBuilder.group({
          section: new FormControl(skillView.section, {
            validators: [
              Validators.required,
            ],
          }),
          code: new FormControl(skillView.code),
          name: new FormControl(skillView.name, {
            validators: [
              Validators.required,
            ],
          }),
        });
      });

    this.skillService.sections()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(sections => {
        this.sections.next(sections);
      });
  }
  get section(): FormControl { return this.skillViewForm.get('section') as FormControl; }
  get code(): FormControl { return this.skillViewForm.get('code') as FormControl; }
  get name(): FormControl { return this.skillViewForm.get('name') as FormControl; }
  goBack(): void {
    this.location.back();
  }
  onSubmit({value, valid}): void {
    if (valid) {
      this.saving.next(true);
      let skillView = Object.assign({}, this.skillView);
      skillView =
        this.filloutRelationships<AbstractSkillView>(
          skillView,
          this.skillViewForm,
        );
      this.skillService.callSkillView(this.method, skillView)
        .pipe(
          takeUntil(this.unsubscribe),
        )
        .subscribe({
          next:(skillView) => {
            this.saving.next(false);
            this.router.navigate(this.viewLink.concat([skillView.id]));
          },
          error:(err) => {
            this.saving.next(false);
            if (is400(err)) {
              this.filloutFormError(this.skillViewForm, err);
            } else {
              this.notificationService.showDanger(errorTitle(err));
            }
          }
        });
    }
  }
  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }
}
