import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WorkView } from '@models/work-view';
import { BehaviorSubject, Subject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WorkMarkService } from '@core/services/work-mark.service';
import { takeUntil } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { AppUserService } from '@core/services/app-user.service';
import { TUI_ICONS_PATH } from '@taiga-ui/core';
import { pdIconPath } from '@utils/functions';
import { WorkMarkView } from '@models/work-mark-view';
import { DestroyService, NotificationService } from '@profdepo-ui/core';

export interface WorkMarkSpecialistDialogData {
	title: string;
	workView: WorkView;
}

@Component({
	selector: 'app-work-marks-specialist-dialog',
	templateUrl: './work-marks-specialist-dialog.component.html',
	styleUrls: ['./work-marks-specialist-dialog.scss'],
	providers: [
		{
			provide: TUI_ICONS_PATH,
			useValue: pdIconPath,
		},
		DestroyService
	],
})
export class WorkMarksSpecialistDialogComponent implements OnInit {
	workMarkViewForm: FormGroup;
	saving = new BehaviorSubject<boolean>(false);
	isFormSubmitted = false;
	ratingColorClass: (value: number) => string = AppUserService.ratingColorClass;

	constructor(
		public dialogRef: MatDialogRef<WorkMarkSpecialistDialogData, boolean | WorkMarkView>,
		@Inject(MAT_DIALOG_DATA) public data: WorkMarkSpecialistDialogData,
		private formBuilder: FormBuilder,
		private workMarkService: WorkMarkService,
		private notificationService: NotificationService,
		private destroy$: DestroyService
	) {
	}

	ngOnInit(): void {
		this.workMarkService.one(this.data.workView.id)
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: (workMarkView: WorkMarkView) => {
					this.workMarkViewForm = this.formBuilder.group({
						speedValue: new FormControl(workMarkView?.speedValue, {
							validators: [Validators.required]
						}),
						targetryValue: new FormControl(workMarkView?.targetryValue, {
							validators: [Validators.required]
						}),
						adequacyValue: new FormControl(workMarkView?.adequacyValue, {
							validators: [Validators.required]
						}),
						comment: new FormControl(workMarkView?.comment),
					});
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	get speedValue(): FormControl {
		return this.workMarkViewForm.get('speedValue') as FormControl;
	}

	get targetryValue(): FormControl {
		return this.workMarkViewForm.get('targetryValue') as FormControl;
	}

	get adequacyValue(): FormControl {
		return this.workMarkViewForm.get('adequacyValue') as FormControl;
	}

	get comment(): FormControl {
		return this.workMarkViewForm.get('comment') as FormControl;
	}

	get hasRatingErrors(): boolean {
		return (this.targetryValue.invalid || this.speedValue.invalid || this.adequacyValue.invalid) && this.isFormSubmitted;
	}

	onSubmit({ value, valid }): void {
		this.isFormSubmitted = true;
		if (valid) {
			this.saving.next(true);
			const workMarkView = new WorkMarkView();
			workMarkView.work = this.data.workView;
			workMarkView.speedValue = this.speedValue.value;
			workMarkView.targetryValue = this.targetryValue.value;
			workMarkView.adequacyValue = this.adequacyValue.value;
			workMarkView.comment = this.comment.value;
			this.workMarkService.assign(workMarkView)
				.pipe(
					takeUntil(this.destroy$)
				)
				.subscribe({
					next: (workMarks) => {
						this.saving.next(false);
						this.dialogRef.close(workMarks);
					},
					error: (err) => {
						this.saving.next(false);
						this.notificationService.showDanger(errorTitle(err));
					}
				});
		} else {
			this.workMarkViewForm.updateValueAndValidity();
		}
	}
}
