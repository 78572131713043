import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyView } from '@models/company-view';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import {
	CompanyDescriptionDialogComponent
} from '@shared/company-description-dialog/company-description-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { pushFakeHistoryState } from '@utils/functions';
import { DestroyService, NotificationService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-company-description',
	templateUrl: './company-description.component.html',
	styleUrls: ['./company-description.component.scss'],
	providers: [DestroyService]
})
export class CompanyDescriptionComponent implements OnInit {
	@Input() canUpdate: boolean;

	@Input()
	set companyView(value: CompanyView) {
		this._companyView.next(value);
	};

	get companyView(): CompanyView {
		return this._companyView.getValue();
	}

	private _companyView = new BehaviorSubject<CompanyView>(null);
	currentCompanyView = new BehaviorSubject<CompanyView>(null);
	@Output() changed = new EventEmitter<any>();

	hasClosedSnackBar: boolean;

	constructor(
		private dialog: MatDialog,
		private notificationService: NotificationService,
		private destroy$: DestroyService
	) {
	}

	ngOnInit(): void {
		this._companyView
			.pipe(
				filter(x => x !== null),
				takeUntil(this.destroy$),
			)
			.subscribe({
				next: (companyView: CompanyView) => {
					this.currentCompanyView.next(companyView);
					this.hasClosedSnackBar = !!companyView.description;
				}
			});
	}

	goUpdateDescription(event: Event): void {
		event.stopPropagation();
		pushFakeHistoryState();

		this.dialog.open(CompanyDescriptionDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				minHeight: '320px',
				panelClass: ['pd-dialog'],
				data: {
					title: 'О компании',
					method: 'update',
					companyView: this.currentCompanyView.value
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: () => {
					this.changed.emit('company description');
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	onCloseSnack(model: boolean): void {
		this.hasClosedSnackBar = model;
	}


	/**
	 * Проверка на пустой блок для владельца компании
	 * @param company
	 */
	isEmptyBlock(company: CompanyView): boolean {
		return (!company.description &&
			this.hasClosedSnackBar &&
			this.canUpdate);
	}

	moreThan1500Symbols(company: CompanyView): boolean {
		return company.description?.length > 1500 ?? false;
	}

}
