import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppUserType, AppUserView} from "@models/app-user-view";
import {AbstractSkillView} from "../../models/abstract-skill-view";
import {BehaviorSubject, Subject} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {AppUserTypeValuePipe} from "@public/pipes/app-user-type-value.pipe";
import {filter, switchMap, takeUntil} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {AppUserService} from "@core/services/app-user.service";

@Component({
  selector: 'app-skill-app-users',
  templateUrl: './skill-app-users.component.html',
})
export class SkillAppUsersComponent implements OnDestroy {
  @Input() appUserType: AppUserType;
  @Input('data')
  set data(value: AbstractSkillView) {
    // set the latest value for _data BehaviorSubject
    this._data.next(value);
  };
  get data(): AbstractSkillView {
    // get the latest value from _data BehaviorSubject
    return this._data.getValue();
  }
  private _data = new BehaviorSubject<AbstractSkillView>(null);
  dataSource: MatTableDataSource<AppUserView>;
  initDisplayedColumns = [
    'title',
    'email',
    'phoneNumber',
    'isIndividualEntrepreneur',
    'isStaff',
    'skills',
    'rating',
  ];
  displayedColumns = new BehaviorSubject<string[]>(this.initDisplayedColumns);
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  appUserTypeValuePipe = new AppUserTypeValuePipe();
  unsubscribe: Subject<any> = new Subject<any>();
  constructor(
    private appUserService: AppUserService
  ) {
    this._data
      .pipe(
        filter(x => x !== null && this.appUserType !== undefined),
        switchMap(skillView => this.appUserService.inSkill(skillView.id)
          .pipe(
            takeUntil(this.unsubscribe)
          )
        )
      )
      .subscribe(personViews => {
        this.dataSource = new MatTableDataSource(personViews);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
  }
  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }
}
