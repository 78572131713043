import { AppUserView } from '@models/app-user-view';
import { YesNo } from '@models/enums';

export enum EducationLevel {
	none = '-1',
	secondary = 0,
	secondarySpecial,
	higherUnfinished,
	higher,
	bachelor,
	master,
	candidate,
	doctor,
}

export class EducationView {
	public id: number;
	public appUser: AppUserView;
	public level: EducationLevel;
	public name: string;
	public sector: string;
	public speciality: string;
	public year: number;
	public createAppUser: AppUserView;
	public modifyAppUser: AppUserView;
	public createTime: Date;
	public modifyTime: Date;
	public isCourse: YesNo
}
