import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NotificationCard, NotificationType } from '@models/notification-view';
import { NotificationsRequestService } from '@core/services/notifications-request.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { errorTitle } from '@utils/helpers/error-helpers';
import { AppUserService } from '@core/services/app-user.service';
import { WorkRequestService } from '@core/services/work-request.service';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { TrueLoadingService } from '@core/services/true-loading.service';

@Component({
	selector: 'pdw-notification-card',
	templateUrl: './notification-card.component.html',
	host: {
		'class': 'pd-notification-card'
	},
	providers: [DestroyService]
})
export class NotificationCardComponent {
	@Input() card: NotificationCard;
	@Output() read = new EventEmitter<NotificationCard>();

	constructor(
		private notificationRequestService: NotificationsRequestService,
		private notificationService: NotificationService,
		private appUserService: AppUserService,
		private workRequestService: WorkRequestService,
		private destroy$: DestroyService
	) {
	}

	isWorkRequest(notificationCard: NotificationCard): boolean {
		return notificationCard.type == NotificationType.workRequest;
	}

	isRequest(notificationCard: NotificationCard): boolean {
		return notificationCard.type == NotificationType.appUserFollowerRequest ||
			notificationCard.type == NotificationType.workRequest;
	}

	goMakeRead(notificationCard: NotificationCard): void {
		console.log('notification Card: ', notificationCard);
		this.notificationRequestService.makeAsRead(notificationCard.id)
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe({
				next: () => {
					if (notificationCard.type == NotificationType.chatMessage && notificationCard?.object?.id)
						this.notificationService.notificationChatMessageRead.emit(notificationCard?.object?.id)
					this.read.emit(notificationCard);
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	goAcceptWorkRequest(notificationCard: NotificationCard): void {
		this.workRequestService.accept(notificationCard.object.id)
			.pipe(
				takeUntil(this.destroy$),
				switchMap(() => this.notificationRequestService.makeAsRead(notificationCard.id)
					.pipe(
						takeUntil(this.destroy$)
					)
				)
			)
			.subscribe({
				next: () => {
					this.read.emit(notificationCard);
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	goDeclineWorkRequest(notificationCard: NotificationCard): void {
		this.workRequestService.decline(notificationCard.object.id)
			.pipe(
				takeUntil(this.destroy$),
				switchMap(() => this.notificationRequestService.makeAsRead(notificationCard.id)
					.pipe(
						takeUntil(this.destroy$)
					)
				)
			)
			.subscribe({
				next: () => {
					this.read.emit(notificationCard);
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	/**
	 * Возвращает имя и фамилию пользователя в формате Иванов И.
	 * @param lastname
	 * @param firstname
	 * @param title
	 */
	editTitleCreator(lastname: string, firstname: string, title: string): string {
		if (!lastname || !firstname) {
			return title;
		}
		return lastname + ' ' + firstname.slice(0, 1).toUpperCase() + '.';
	}
}
