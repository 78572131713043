<form
	class='exp'
	[formGroup]='formGroup'
>
	<div class='exp-row'>
		<p class='exp-title'>
			Место работы {{index + 1}}
		</p>

		<div class='exp-delete-container'>
			<pd-checkbox
				class='desktop-checkbox'
				formControlName='tillNow'
			>
				{{ 'tillNow' | experience: 'propertyTitle' }}
			</pd-checkbox>
			<button
				(click)='deleteCurrentGroup()'
				*ngIf='index > 0'
			>
				<mat-icon svgIcon='additional-trash-2'></mat-icon>
			</button>
		</div>
	</div>
	<div class='pd-dialog-column'>

		<div class='pd-dialog-row exp-date-container'>
			<mat-form-field
				class='pd-input-form-field pd-dialog-half-row'
				appearance='fill'
				hideRequiredMarker
			>
				<mat-label>{{ 'startDate' | experience: 'propertyTitle' }}</mat-label>
				<input
					matInput
					formControlName='startDate'
					[matDatepicker]='startDatePicker'
					placeholder='00.0000'
				>
				<mat-datepicker-toggle
					class='pd-datepicker-toggle'
					matSuffix
					[for]='startDatePicker'
				>
					<mat-icon
						svgIcon='arrow-down-2'
						matDatepickerToggleIcon
					></mat-icon>
				</mat-datepicker-toggle>
				<mat-datepicker
					[panelClass]="'pd-calendar pd-calendar-month-year'"
					#startDatePicker
					[calendarHeaderComponent]='calendarHeader'
					startView='multi-year'
					(monthSelected)='setMonthAndYear(startDateControl, $event, startDatePicker)'
				></mat-datepicker>
				<mat-error
					*ngIf='startDateControl.errors?.required'
					i18n='mat-error@@register-password-required'
				>Обязательное поле
				</mat-error>
				<mat-error
					*ngIf='startDateControl.errors?.dateError'
					i18n='mat-error@@register-password-required'
				>Укажите дату начала раньше даты конца!
				</mat-error>
				<mat-error *ngIf='!startDateControl.errors?.dateError && startDateControl.errors?.dateIsLater'>
					Дата не может быть больше текущей
				</mat-error>
				<mat-error *ngIf='startDateControl.errors?.dateIsEarlier && !startDateControl.errors?.dateError'>
					Дата не должна быть меньше 01.01.1900
				</mat-error>

			</mat-form-field>

			<mat-form-field
				class='pd-input-form-field pd-dialog-half-row'
				appearance='fill'
				hideRequiredMarker
			>
				<mat-label>{{ 'endDate' | experience: 'propertyTitle' }}</mat-label>
				<input
					matInput
					formControlName='endDate'
					[matDatepicker]='endDatePicker'
					placeholder='00.0000'
					class='date-end-picker'
					[disabled]='tillNowControl.value'
				>
				<mat-datepicker-toggle
					class='pd-datepicker-toggle'
					matSuffix
					[for]='endDatePicker'
				>
					<mat-icon
						svgIcon='arrow-down-2'
						matDatepickerToggleIcon
					></mat-icon>
				</mat-datepicker-toggle>
				<mat-datepicker
					[panelClass]="'pd-calendar pd-calendar-month-year'"
					#endDatePicker
					[calendarHeaderComponent]='calendarHeader'
					startView='multi-year'
					(monthSelected)='setMonthAndYear(endDateControl, $event, endDatePicker)'
				></mat-datepicker>
				<mat-error
					*ngIf='endDateControl.errors?.required'
					i18n='mat-error@@register-password-required'
				>Обязательное поле
				</mat-error>
				<mat-error
					*ngIf='endDateControl.errors?.dateError'
					i18n='mat-error@@register-password-required'
				>Укажите дату окончания позже даты начала!
				</mat-error>
				<mat-error *ngIf='!endDateControl.errors?.dateError && endDateControl.errors?.dateIsLater'>
					Дата не может быть больше текущей
				</mat-error>
				<mat-error *ngIf='endDateControl.errors?.dateIsEarlier && !endDateControl.errors?.invalidEndDate && !endDateControl.errors?.invalidEndDateRelativeToCurrentDate'>
					Дата не должна быть меньше 01.01.1900
				</mat-error>
			</mat-form-field>
		</div>

		<mat-form-field
			class='pd-input-form-field'
			appearance='fill'
			hideRequiredMarker
		>
			<mat-label>{{ 'company' | experience: 'propertyTitle' }}</mat-label>
			<input
				matInput
				formControlName='companyName'
				maxlength='150'
			>
			<mat-error
				*ngIf='company.errors?.required'
				i18n='mat-error@@register-password-required'
			>Обязательное поле
			</mat-error>
		</mat-form-field>

		<mat-form-field
			class='pd-input-form-field'
			appearance='fill'
			hideRequiredMarker
		>
			<mat-label>{{ 'position' | experience: 'propertyTitle' }}</mat-label>
			<input
				matInput
				formControlName='position'
				maxlength='150'
			>

			<mat-error
				*ngIf='position.errors?.required'
				i18n='mat-error@@register-password-required'
			>Обязательное поле
			</mat-error>
		</mat-form-field>

		<mat-form-field
			class='pd-input-form-field pd-textarea-form-field'
			appearance='fill'
			appExtendableTextArea
		>
			<mat-label>{{ 'description' | experience: 'propertyTitle' }}</mat-label>
			<tui-scrollbar class='pd-scrollbar pd-textarea-scrollbar'>
				<div class='pd-textarea-wrapper'>
					<div class='pd-textarea-relative-wrapper'>
						<div
							class='pd-textarea-pseudo-content'
							aria-hidden='true'
						>
							<span class='pd-textarea-pseudo'></span>
						</div>
						<textarea
							class='pd-textarea exp-description'
							matInput
							formControlName='description'
							maxlength='1000'
						></textarea>
					</div>
				</div>
			</tui-scrollbar>
			<button
				class='pd-icon-button gray'
				matSuffix
				mat-icon-button
				type='button'
				tabindex='-1'
			>
				<mat-icon
					class='pd-icon'
					svgIcon='additional2-stretch'
				></mat-icon>
			</button>
			<mat-error *ngIf='description?.errors?.maxlength'>
				Введите максимум 1000 символов!
			</mat-error>
		</mat-form-field>
	</div>
</form>
