<div
	class='pd-expanded-height'
	#cardsContainer
>
	<pdw-work-executor-mark-cards
		[dataSource]='dataSource'
		[columnsCount]='columnsCount'
		[cardWidth]='minCardWidth'
	>
	</pdw-work-executor-mark-cards>
</div>

