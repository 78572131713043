<div class='pd-dialog-header'>
	<p class='pd-dialog-header-title'>{{ data.title }}</p>
	<button
		class='pd-icon-button'
		mat-icon-button
		[mat-dialog-close]='false'
	>
		<mat-icon svgIcon='interface-close'></mat-icon>
	</button>
</div>
<tui-scrollbar class='pd-scrollbar pd-dialog-scroll'>
	<div class='pd-dialog-content large-padding'>
		<div class='pd-dialog-column'>
			<input
				hidden=''
				id='file'
				type='file'
				accept='image/png, image/svg, image/jpeg, image/jpg, image/webp'
				(change)='fileChangeEvent($event)'
				#fileInput
			/>
			<label
				*ngIf='!hasAvatar; else twoButtons'
				for='file'
				class='pd-button-additional'
			>
				<mat-icon
					class='pd-icon black'
					svgIcon='additional-plus'
				></mat-icon>
				Выберите файл
			</label>

			<ng-template #twoButtons>
				<div class='pd-dialog-row'>
					<button
						class='pd-button-additional pd-dialog-half-row-mobile'
						[disabled]='(saving$ | async) || (isLoadingFile | async)'
						(click)='fileInput.click()'
					>
						<mat-icon
							class='pd-icon black'
							svgIcon='additional2-regroup'
						></mat-icon>
						Заменить
					</button>
					<button
						class='pd-button-additional pd-dialog-half-row-mobile'
						[disabled]='(saving$ | async) || (deleting$ | async) || (isLoadingFile | async)'
						(click)='onDelete()'
					>
						<mat-icon
							class='pd-icon black'
							svgIcon='additional-trash-2'
						></mat-icon>
						<ng-container *ngIf='!(deleting$ | async); else isDeleting'>Удалить</ng-container>
						<ng-template #isDeleting>
							Удаление...
						</ng-template>
					</button>
				</div>
			</ng-template>

			<p class='avatar-description'>
				Файл должен быть не менее 150px х 150рх, не более
				20 МБ. Формат JPG, JPEG, PNG, WEBP, SVG
			</p>

			<div
				#cropperWrapper
				class='pd-image-cropper-wrapper'
				[ngClass]="{ 'has-no-photo': (isLoadingFile | async) || !hasAvatar }"
				dragDrop
				(files)='fileDropped($event)'
				(dragleaveEvt)='onDragleave()'
				(dragoverEvt)='onDragover()'
				(dropEvt)='onDrop()'
			>
				<image-cropper
					[ngClass]="{ 'hidden': (isLoadingFile | async) || !hasAvatar }"
					[imageFile]='imageFile'
					[imageChangedEvent]='imageChangedEvent'
					[maintainAspectRatio]='true'
					[aspectRatio]='1'
					[resizeToWidth]='256'
					format='png'
					(imageCropped)='onImageCropped($event)'
					(imageLoaded)='onImageLoaded($event)'
					(cropperReady)='onCropperReady($event)'
					(loadImageFailed)='onLoadImageFailed()'
					class='pd-image-cropper'
					style='height: var(--image-cropper-height)'
				>
				</image-cropper>
				<ng-container *ngIf='isLoadingFile | async'>
					<mat-spinner
						[diameter]='24'
						[strokeWidth]='2'
					></mat-spinner>
					<p class='pd-dialog-no-avatar-text'>Загружаем</p>
				</ng-container>
				<ng-container *ngIf='!hasAvatar'>
					<mat-icon
						class='pd-icon pd-no-avatar-icon'
						svgIcon='additional2-user-100-100'
					></mat-icon>
					<p class='pd-dialog-no-avatar-text'>После загрузки здесь появится
						ваша фотография</p>
				</ng-container>
			</div>
		</div>
	</div>
</tui-scrollbar>
<div class='pd-dialog-footer align-end'>
	<div class='pd-dialog-footer-buttons'>
		<button
			mat-raised-button
			class='pd-button-secondary large'
			type='reset'
			[mat-dialog-close]='false'
		>
			Отменить
		</button>
		<button
			mat-raised-button
			class='pd-button large'
			type='submit'
			[disabled]='!(saving$ | async) && !(hasImage$ | async)'
			(click)='onSave()'
		>
			<ng-container *ngIf='!(saving$ | async); else isSaving'>Сохранить</ng-container>
			<ng-template #isSaving>
				Сохраняем
			</ng-template>
		</button>
	</div>
</div>
