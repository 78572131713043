<div class='pd-dialog-header'>
	<p class='pd-dialog-header-title'>{{data.title}}</p>
	<button
		class='pd-icon-button'
		mat-icon-button
		[mat-dialog-close]='false'
	>
		<mat-icon svgIcon='interface-close'></mat-icon>
	</button>
</div>
<tui-scrollbar class='pd-scrollbar pd-dialog-scroll'>
	<div class='pd-dialog-content large-padding'>
		<div *ngIf='changePassForm'>
			<form
				id='changePassForm'
				[formGroup]='changePassForm'
				(ngSubmit)='onSubmit(changePassForm)'
			>
				<div class='pd-dialog-column'>
					<!--  Password   -->
					<mat-form-field
						class='pd-input-form-field mb-3'
						appearance='fill'
						hideRequiredMarker
					>
						<mat-icon
							class='pd-field-prefix-icon'
							svgIcon='additional-shield-done'
							matPrefix
						></mat-icon>
						<mat-label>Пароль</mat-label>
						<input
							matInput
							formControlName='password'
							[type]="passwordHide ? 'password' : 'text'"
						>
						<button
							mat-icon-button
							matSuffix
							class='pd-icon-button light-gray'
							type='button'
							[attr.aria-label]="authPipe.transform('passwordHide', 'propertyTitle')"
							[attr.aria-pressed]='passwordHide'
							(click)='passwordHide = !passwordHide'
						>
							<mat-icon [svgIcon]="passwordHide ? 'additional-view' : 'additional-view-hide'"></mat-icon>
						</button>
						<mat-error
							*ngIf='password?.errors?.required'
							i18n='mat-error@@app-user-reset-any-password-dialog-password-required'
						>Обязательное поле
						</mat-error>
						<mat-error
							*ngIf='password?.errors?.pattern'
						>
							Пароль должен содержать буквы большого и малого регистра, цифры и быть больше 8 символов и
							меньше 20 символов
						</mat-error>
						<mat-error *ngIf='password?.errors?.serverError'>
							{{ password?.errors?.serverError }}
						</mat-error>
					</mat-form-field>
					<!--  PasswordConfirm   -->
					<mat-form-field
						class='pd-input-form-field'
						appearance='fill'
						hideRequiredMarker
					>
						<mat-icon
							class='pd-field-prefix-icon'
							svgIcon='interface-attention-circle'
							matPrefix
						></mat-icon>
						<mat-label>Подтвердите пароль</mat-label>
						<input
							matInput
							formControlName='passwordConfirm'
							[type]="passwordConfirmHide ? 'password' : 'text'"
							[errorStateMatcher]='matcher'
						>
						<button
							mat-icon-button
							matSuffix
							class='pd-icon-button light-gray'
							type='button'
							[attr.aria-label]="authPipe.transform('passwordHide', 'propertyTitle')"
							[attr.aria-pressed]='passwordConfirmHide'
							(click)='passwordConfirmHide = !passwordConfirmHide'
						>
							<mat-icon [svgIcon]="passwordConfirmHide ? 'additional-view' : 'additional-view-hide'"></mat-icon>
						</button>
						<mat-error
							*ngIf='passwordConfirm?.errors?.required'
							i18n='mat-error@@app-user-reset-any-password-dialog-passwordConfirm-required'
						>Обязательное поле
						</mat-error>
						<mat-error
							*ngIf="changePassForm?.hasError('notSame') && !passwordConfirm?.errors?.required"
							i18n='mat-error@@app-user-reset-any-password-dialog-passwordConfirm-notSame'
						>Указаные пароли не совпадают
						</mat-error>
						<mat-error *ngIf='passwordConfirm?.errors?.serverError'>
							{{ passwordConfirm?.errors?.serverError }}
						</mat-error>
					</mat-form-field>
				</div>
			</form>
		</div>
	</div>
</tui-scrollbar>
<div class='pd-dialog-footer align-end'>
	<div class='pd-dialog-footer-buttons'>
		<button
			mat-raised-button
			class='pd-button-secondary large'
			type='reset'
			[mat-dialog-close]='false'
		>
			Отменить
		</button>
		<button
			form='changePassForm'
			mat-raised-button
			class='pd-button large'
			type='submit'
			[disabled]='(saving | async)'
		>
			<ng-container *ngIf='!(saving | async); else isSaving'>Сохранить</ng-container>
			<ng-template #isSaving>
				Сохраняем
			</ng-template>
		</button>
	</div>
</div>
