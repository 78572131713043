import { ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoginRequest } from "@requests/login-request";
import { HttpErrorResponse } from "@angular/common/http";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { filter, switchMap, takeUntil } from "rxjs/operators";
import { AuthService } from "@core/services/auth.service";
import { AppUserService } from "@core/services/app-user.service";
import { AppUserType } from "@models/app-user-view";
import { errorTitle } from "@utils/helpers/error-helpers";
import { AuthPipe } from "@public/pipes/auth.pipe";
import { HeaderService } from "@core/services/header.service";
import { OAuthService } from "@core/services/oauth.service";
import { environment } from "@env/environment";
import { MatDialog } from "@angular/material/dialog";
import { AppUserRoleHintHelper } from "@utils/helpers/app-user-role-hint-helper";
import { DestroyService, NotificationService } from "@profdepo-ui/core";
import { CaptchaComponent } from "@shared/captcha/captcha.component";
import { emailRegEx } from "@utils/constants";
import { TrueLoadingService } from "@core/services/true-loading.service";
import { LoadingService } from "@core/services/loading.service";
import { RoleService } from "@core/services/role.service";
import { LocalStorageUtilsService } from "@core/services/local-storage-utils.service";

@Component({
	selector: "pdw-login",
	templateUrl: "./login.component.html",
	providers: [DestroyService, LoadingService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ["./login.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
	openAuthProviders = [
		{ name: "Yandex", title: "Продолжить с Яндекс", icon: "social-yandex" },
		{ name: "Vk", title: "Продолжить с ВКонтакте", icon: "social-vk" },
		// { name: 'Google', title: 'Продолжить с Google', icon: 'social-google' }
	];
	authPipe = new AuthPipe();
	passwordHide = true;
	loginForm: FormGroup;
	afterResetPassword: boolean = false;
	afterConfirmationEmail: boolean = false;
	appUserRoleHintHelper: AppUserRoleHintHelper;
	id: string = null;
	afterForgotPassword: boolean = false;

	@ViewChild("captcha") captchaComponent!: CaptchaComponent;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private appUserService: AppUserService,
		private location: Location,
		private router: Router,
		private notificationService: NotificationService,
		private headerService: HeaderService,
		private oAuthService: OAuthService,
		private dialog: MatDialog,
		private destroy$: DestroyService,
		public loading$: TrueLoadingService,
		private localStorageUtils: LocalStorageUtilsService,
		public roleService: RoleService
	) {
		this.loginForm = this.formBuilder.group({
			userName: ["", [Validators.required, Validators.minLength(4), Validators.pattern(emailRegEx)]],
			password: ["", [Validators.required, Validators.minLength(8)]],
		});

		loading$.next(false);
	}

	ngOnInit(): void {
		this.localStorageUtils.setVisitedUrl();
		this.id = localStorage.getItem("redirectTo");
		if (history.state.afterResetPassword) {
			this.afterResetPassword = history.state.afterResetPassword;
		}
		if (history.state.afterConfirmationEmail) {
			this.afterConfirmationEmail = history.state.afterConfirmationEmail;
		}

		this.headerService.changeCurrentTitle("Вход");

		if (history.state.afterForgotPassword) {
			this.afterForgotPassword = history.state.afterForgotPassword;
		}
	}

	get userName() {
		return this.loginForm.get("userName");
	}

	get password() {
		return this.loginForm.get("password");
	}

	get captchaField() {
		return this.loginForm.get("captcha");
	}

	goBack(): void {
		this.location.back();
	}

	onSubmit(): void {
		this.loading$.next(true);
		this.authService
			.login(this.loginForm.value as LoginRequest)
			.pipe(
				switchMap(() =>
					this.appUserService.getActiveUserView().pipe(
						filter((x) => x !== null),
						takeUntil(this.destroy$)
					)
				)
			)
			.subscribe({
				next: (appUserView) => {
					this.loading$.next(false);
					this.appUserRoleHintHelper = new AppUserRoleHintHelper(this.appUserService);
					let commands = [];

					switch (appUserView.type) {
						case AppUserType.administrator: {
							commands.push("administrator/dashboard");
							break;
						}
						case AppUserType.client: {
							if (
								this.localStorageUtils.getVisitedUrl() !== null ||
								this.localStorageUtils.getVisitedUrl() !== undefined
							) {
								commands.push(this.localStorageUtils.getVisitedUrl());
							} else if (this.id) {
								commands.push(`/users/${this.id}`);
							} else {
								commands.push("/profile");
							}
							break;
						}

						case AppUserType.specialist: {
							if (
								this.localStorageUtils.getVisitedUrl() !== null ||
								this.localStorageUtils.getVisitedUrl() !== undefined
							) {
								commands.push(this.localStorageUtils.getVisitedUrl());
							} else commands.push("/profile");
							break;
						}
						default: {
							commands.push("/landing");
						}
					}
					localStorage.removeItem("redirectTo");

					localStorage.removeItem("beforeAuth");

					this.router.navigate(commands).then(this.appUserRoleHintHelper.initAfterAuth);
				},
				error: (err) => {
					this.loading$.next(false);
					if (err instanceof HttpErrorResponse) {
						if (err.status === 400) {
							this.password.setErrors({ serverError: err.error });
						} else {
							this.notificationService.showDanger(errorTitle(err));
						}
					}
				},
			});
	}

	get isProduction(): boolean {
		return environment.production;
	}

	goOAuthLink(provider: string): void {
		console.warn("goGoogle");
		this.oAuthService
			.oauthLink(provider)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (data: string) => {
					console.warn("data: ", data);
					window.location.href = data;
				},
				error: (err) => {
					console.warn("err: ", err);
				},
			});
	}
}
