import { isMoment } from 'moment';
import { PipeTransform } from '@angular/core';
import { AppUsersFilter } from '@core/services/app-user.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CompaniesFilter } from '@core/services/company.service';
import { WorkRequestsFilter } from '@core/services/work-request.service';
import { WorksFilter } from '@core/services/work.service';
import { DatePipe } from '@angular/common';
import { removeTimeZone } from '@utils/helpers/date-helpers';
import { YesNo } from '@models/enums';
import { ValidatorFn } from '@angular/forms';
import { CustomValidatorFn } from '@utils/validators/custom-validators';
import { SpacePipe } from '@shared/pipes/space-pipe/space.pipe';

export type ComplexFilterInputType =
	'text'
	| 'rating'
	| 'select'
	| 'dateRange'
	| 'autocomplete'
	| 'autocomplete-without-icon'
	| 'budget'
	| 'multi-toggle'
	| 'main-tag'
	| 'number'
	| 'checkbox-list'
	| 'checkbox'
	| 'autocomplete-chips'
	| 'switch'
	| 'hard-skills'
	| null;

const TIME_FORMAT = 'dd.MM.YYYY';

/**
 * Элемент комплексного фильтра
 */
export class ComplexFilterField {
	/**
	 * Атрибут искомой сущности (title, lastname, rating и т.п.)
	 */
	name: string;
	/**
	 * Pipe сущности для доступа к наименованиям и подсказкам атрибута
	 */
	pipe: PipeTransform;
	/**
	 * Тип поиска: text, number, date, select
	 */
	inputType: ComplexFilterInputType;
	/**
	 * Значение строки поиска по-умолчанию
	 */
	defaultValue?: any;
	/**
	 * Функция возвращающая значения критериев поиска по query
	 */
	source: (query: string) => Observable<any> | null;
	/**
	 * Массив начальных значений для autocomplete;
	 */
	firstFiftyItems?: BehaviorSubject<any[]>;
	/**
	 * Иконка для элемента поиска
	 */
	icon?: string;
	/**
	 * Настройки select
	 */
	selectOptions?: {
		selectValues?: any[],
		valuesPipe?: PipeTransform,
		isMultiple?: boolean,
		errors?: ValidatorFn | ValidatorFn[] | CustomValidatorFn | CustomValidatorFn[]
	}
	checkboxLabel?: string;
	/**
	 * Список вложенных элементов фильтра
	 */
	children?: ComplexFilterField[];

	idOption?: string;

	labelOption?: string

	halfInput?: boolean;

	isToShowLabel?: boolean;
}

/**
 *  Источник значений для элемента комплексного фильтра
 */
export class ComplexFilterSource {
	/**
	 * Атрибут искомой сущности (title, lastname, rating и т.п.)
	 */
	name: string;
	/**
	 * Источник значений критерия поиска
	 */
	source: Observable<any>;
}

/**
 * Результат элемента комплексного фильтра
 */
export class ComplexFilterComplete {
	/**
	 * Атрибут
	 */
	name: string;
	/**
	 * Критерий поиска (значение или массив значений)
	 */
	value: any;

	/**
	 * Второй критерий поиска
	 */
	secondInputValue: any;
	/**
	 * Тип инпута повторяет свойство inputType в displayedField
	 */
	inputType: ComplexFilterInputType;
}

/**
 * Результат комплексного фильтра
 */
export class ComplexFilterResult {
	/**
	 * Произвольная строка поиска (поиск по всему подряд)
	 */
	any: string | null;
	/**
	 * Список значений фильтров для соотв. атрибутов
	 */
	filters: ComplexFilterComplete[];

	mainTagFilter?: ComplexFilterComplete;

	constructor(any: string, filters: ComplexFilterComplete[], mainTagFilter = null) {
		this.any = any;
		this.filters = filters;
		this.mainTagFilter = mainTagFilter;
	}

	public toAppUsersFilter(isFreelance?: YesNo): AppUsersFilter {
		const result = new AppUsersFilter();
		if (isFreelance !== undefined) {
			result.isFreelance = isFreelance;
		}
		result.any = this.any;
		this.filtersToResult<AppUsersFilter>(result);

		return result;
	}

	public toCompaniesFilter(): CompaniesFilter {
		const result = new CompaniesFilter();
		result.any = this.any;
		this.filtersToResult<CompaniesFilter>(result);

		return result;
	}

	public toWorkRequestsFilter(): WorkRequestsFilter {
		const result = new WorkRequestsFilter();
		result.any = this.any;
		this.filtersToResult<WorkRequestsFilter>(result);

		return result;
	}

	public toWorksFilter(isFreelance?: boolean): WorksFilter {
		const result = new WorksFilter();
		if (isFreelance !== undefined) {
			result.isFreelance = isFreelance;
		}
		result.any = this.any;
		this.filtersToResult<WorksFilter>(result);

		return result;
	}

	private filtersToResult<T>(result: T) {
		this.filters.forEach((v) => {
			if (v.secondInputValue) {
				result[v.name] = v.value + '|' + v.secondInputValue;
			} else {
				if (Array.isArray(v.value)) {
					result[v.name] = v.value
						.map(x => x.id ? x.id : x);
				} else {
					result[v.name] = v.value;
				}
			}
		});
	}
}

export class ComplexFilterHelpers {
	/**
	 * Обновляет фильтр для указанного фильтра
	 * @param filters список фильтров
	 * @param filterField фильтр
	 * @param value критерий поиска
	 * @param secondInputValue второй критерий поиска
	 */
	static updateFilter(filters: ComplexFilterComplete[], filterField: ComplexFilterField, value: any, secondInputValue: any = null) {
		const index = filters
			.findIndex(filter => filter.name === filterField.name);

		if (index >= 0) {
			filters[index].value = ComplexFilterHelpers.setValue(value);
			filters[index].secondInputValue = ComplexFilterHelpers.setValue(secondInputValue);
		} else {
			filters.push({
				name: filterField.name,
				value: ComplexFilterHelpers.setValue(value),
				secondInputValue: ComplexFilterHelpers.setValue(secondInputValue),
				inputType: filterField.inputType
			});
		}
		return filters;
	}

	static getMainTagAsFilterComplete(filterField: ComplexFilterField, value: any): ComplexFilterComplete {
		return {
			name: filterField.name,
			secondInputValue: null,
			value: value,
			inputType: filterField.inputType
		}
	}

	/**
	 * Удаляет фильтр для указанного имени атрибута
	 * @param filters список фильтров
	 * @param filterName имя атрибута
	 */
	static removeFilter(filters: ComplexFilterComplete[], filterName: string): void {
		const index = filters
			.findIndex(filter => filter.name === filterName);

		if (index >= 0) {
			filters.splice(index, 1);
		}
	}

	static setValue(value: any): any {
		if (isMoment(value)) {
			return value.isValid() ? removeTimeZone(value.toDate()).toISOString() : null;
		} else {
			return value;
		}
	}

	static titleFilter(filterFields: ComplexFilterField[], filter: ComplexFilterComplete): string {
		const filterField = filterFields.find(x => x.name === filter.name);
		return filterField?.pipe.transform(filterField.name, 'propertyTitle');
	}

	static valueFilter(filter: ComplexFilterComplete, filterField: ComplexFilterField): string {
		if (filterField && filterField.inputType === 'select') {
			return filterField.selectOptions.valuesPipe.transform(filter.value);
		}

		return this.valueConcat(filter, filterField);
	}

	private static valueConcat(filter: ComplexFilterComplete, filterField: ComplexFilterField): string {
		if (!filterField) {
			return '';
		}
		if (Array.isArray(filter.value) && (filterField?.inputType === 'multi-toggle' || filterField?.inputType === 'checkbox-list')) {
			return filter.value
				.map(x => filterField.selectOptions.valuesPipe ? filterField.selectOptions.valuesPipe.transform(x) : x)
				.join(', ');
		}

		if (Array.isArray(filter.value)) {
			return filter.value
				.map(x => x.title)
				.join(', ');
		}
		if (filterField?.inputType === 'select' || filterField?.inputType === 'main-tag') {
			return filterField.selectOptions.valuesPipe.transform(filter.value);
		}

		if (filterField.inputType === 'dateRange') {
			const datePipe = new DatePipe('ru-RU');

			if (filter.value && filter.secondInputValue)
				return 'C ' + datePipe.transform(filter.value, TIME_FORMAT) + ' по ' + datePipe.transform(filter.secondInputValue, TIME_FORMAT);
			if (filter.value && !filter.secondInputValue) {
				return 'C ' + datePipe.transform(filter.value, TIME_FORMAT);
			}
			if (!filter.value && filter.secondInputValue) {
				return 'По ' + datePipe.transform(filter.secondInputValue, TIME_FORMAT);
			}
		}

		if (filterField.inputType === 'budget') {
			const pipe = new SpacePipe();

			if (filter.value && filter.secondInputValue)
				return 'От ' + pipe.transform(filter.value) + ' до ' + pipe.transform(filter.secondInputValue);
			if (filter.value && !filter.secondInputValue) {
				return 'От ' + pipe.transform(filter.value);
			}
			if (!filter.value && filter.secondInputValue) {
				return 'До ' + pipe.transform(filter.secondInputValue);
			}
		}

		if (filterField.inputType === 'rating') {
			if (filter.value && filter.secondInputValue)
				return 'От ' + filter.value + ' до ' + filter.secondInputValue;
			if (filter.value && !filter.secondInputValue) {
				return 'От ' + filter.value;
			}
			if (!filter.value && filter.secondInputValue) {
				return 'До ' + filter.secondInputValue;
			}
		}


		return filter.value;
	}

	static tooltipFilter(filter: ComplexFilterComplete, filterField: ComplexFilterField): string {
		return this.valueConcat(filter, filterField)?.length > 8
			? this.valueConcat(filter, filterField)
			: null;
	}

	static isEqualComplexFiltersResult(firstFilter: ComplexFilterResult, secondFilter: ComplexFilterResult): boolean {
		if (firstFilter.any !== secondFilter.any)
			return false;

		if (firstFilter.filters.length !== secondFilter.filters.length)
			return false;

		for (let i = 0; i < firstFilter.filters.length; i++) {
			const fActiveFilter = firstFilter.filters[i];
			const sActiveFilter = secondFilter.filters[i];

			if (fActiveFilter.name !== sActiveFilter.name ||
				fActiveFilter.value !== sActiveFilter.value ||
				fActiveFilter.secondInputValue !== sActiveFilter.secondInputValue ||
				fActiveFilter.inputType !== sActiveFilter.inputType)
				return false;
		}
		return true;
	}
}
