import { Pipe, PipeTransform } from '@angular/core';
import { AbstractModelPipe } from '@public/pipes/abstract-model.pipe';

@Pipe({
	name: 'appUser'
})
export class AppUserPipe extends AbstractModelPipe implements PipeTransform {
	properties = [
		{ name: 'userName', title: 'Имя пользователя', hint: 'Имя пользователя' },
		{ name: 'email', title: 'Эл. почта', hint: 'Адрес эл. почтового ящика' },
		{ name: 'phoneNumber', title: 'Телефон', hint: 'Номер телефона' },
		{ name: 'type', title: 'Тип пользователя', hint: 'Тип пользователя' },
		{ name: 'avatar', title: 'Фото', hint: 'Фото специалиста' },
		{ name: 'title', title: 'ФИО', hint: 'Фамилия, имя и отчество' },
		{ name: 'titleLong', title: 'ФИО', hint: 'Фамилия, имя и отчество' },
		{ name: 'lastname', title: 'Фамилия', hint: 'Фамилия' },
		{ name: 'firstname', title: 'Имя', hint: 'Имя' },
		{ name: 'middlename', title: 'Отчество', hint: 'Отчество' },
		{ name: 'birthday', title: 'Дата рождения', hint: 'Дата рождения' },
		{ name: 'inn', title: 'ИНН', hint: 'Индивидуальный номер налогоплательщика' },
		{ name: 'snils', title: 'СНИЛС', hint: 'Страховой номер индивидуального лицевого счёта' },
		{ name: 'isIndividualEntrepreneur', title: 'ИП', hint: 'Индивидуальный предприниматель' },
		{ name: 'isStaff', title: 'Сотрудник', hint: 'Штатный сотрудник' },
		{
			name: 'ogrnip',
			title: 'ОГРНИП',
			hint: 'Основной государственный регистрационный номер индивидуального предпринимателя'
		},
		{ name: 'incognito', title: 'Инкогнито', hint: 'Скрыть персональные данные' },
		{ name: 'subCategories', title: 'Типы работ', hint: 'Подходящие типы работ' },
		{ name: 'hardSkills', title: 'Профессиональные качества', hint: 'Профессиональные качества' },
		{ name: 'softSkills', title: 'Личные качества', hint: 'Личные качества' },
		{ name: 'rating', title: 'Рейтинг', hint: 'Рейтинг' },
		{ name: 'companyPersonType', title: 'Вид деятельности', hint: 'Вид трудовой деятельности' },
		{ name: 'gender', title: 'Пол', hint: 'Пол' },
		{ name: 'action', title: 'Действие', hint: 'Действие' },
		{ name: 'oneUsePassword', title: 'Пароль', hint: 'Пароль пользователя' },
		{ name: 'roles', title: 'Роли', hint: 'Роли пользователя' },
		{ name: 'relation', title: 'Отношения', hint: 'Отношения со специалистом' },
		{ name: 'speciality', title: 'Должность', hint: 'Должность, например, инженер-технолог' },
		{ name: 'passportDay', title: 'Дата выдачи паспорта', hint: 'Дата выдачи паспорта' },
		{ name: 'passportNumber', title: 'Серия и номер паспорта', hint: 'Серия и номер паспорта' },
		{ name: 'account', title: 'Расчетный счет', hint: 'Расчетный счет' },
		{ name: 'bik', title: 'БИК банка', hint: 'БИК банка' },
		{ name: 'accountName', title: 'Название счета', hint: 'Название счета' },
		{ name: 'city', title: 'Город', hint: 'Город' },
		{ name: 'profession', title: 'Профессия', hint: 'Желаемая профессия' },
		{ name: 'preferredReward', title: 'Доход', hint: 'Желаемая зарплата' },
	];

	transform(value: any, type: string): any {
		return this.getTransform(value, type);
	}

	allProperties() {
		return this.properties;
	}
}
