<div class='pd-dialog-header'>
	<p class='pd-dialog-header-title'>{{ data.title }}</p>
	<button
		class='pd-icon-button'
		mat-icon-button
		[mat-dialog-close]='false'
	>
		<mat-icon svgIcon='interface-close'></mat-icon>
	</button>
</div>
<tui-scrollbar class='pd-scrollbar pd-dialog-scroll'>
	<div class='pd-dialog-content'>
		<div *ngIf='educationAdditionalsForm'>
			<form
				id='educationAdditionalsForm'
				[formGroup]='educationAdditionalsForm'
				(ngSubmit)='onSubmit(educationAdditionalsForm)'
			>
				<div class='pd-dialog-column'>


					<ng-container *ngIf='educationAdditionals.length > 0; else noEducationsAdditionals'>
						<div
							formArrayName='educationAdditionals'
							*ngFor='let educationAdditionalFormGroup of educationAdditionals.controls; let i = index'
						>
							<div [formGroupName]='i'>
								<mat-form-field class='pd-input-invisible'>
									<input
										matInput
										formControlName='id'
										required
									>
								</mat-form-field>
								<div class='pd-dialog-row pd-dialog-subtitle-row'>
									<!--University-->
									<div class='pd-dialog-subtitle'>
										Дополнительное образование {{ i + 1 }}
									</div>

									<div class='pd-dialog-row pd-dialog-icon-buttons'>
										<!--Remove Item-->
										<button
											type='button'
											class='pd-icon-button'
											mat-icon-button
											(click)='goRemoveEducationAdditional(i)'
										>
											<mat-icon svgIcon='additional-trash-2'></mat-icon>
										</button>
										<!--Add Item-->
										<button
											type='button'
											(click)='goAddEducationAdditional(i + 1)'
											class='pd-icon-button'
											mat-icon-button
										>
											<mat-icon svgIcon='additional-plus'></mat-icon>
										</button>
									</div>
								</div>
								<div class='pd-dialog-column'>
									<div class='pd-dialog-row'>

										<!--Year-->
										<mat-form-field
											class='pd-input-form-field pd-dialog-half-row'
											appearance='fill'
											hideRequiredMarker
										>
											<mat-label>{{ 'year' | educationAdditional: 'propertyTitle' }}</mat-label>
											<pd-select
												formControlName='year'
												required
											>
												<pd-select-option
													*ngFor='let item of years'
													[value]='item'
												>
													{{item}}
												</pd-select-option>
											</pd-select>
											<button
												class='pd-icon-button gray'
												matSuffix
												mat-icon-button
												type='button'
											>
												<mat-icon
													class='pd-icon'
													svgIcon='arrow-down-2'
												></mat-icon>
											</button>
											<mat-error
												*ngIf='educationAdditionalsYear(i).errors?.required'
												i18n='mat-error@@app-user-education-additionals-dialog-educationAdditionalsYear-required'
											>Обязательное поле
											</mat-error>
											<mat-error *ngIf='educationAdditionalsYear(i).errors?.serverError'>
												{{ educationAdditionalsYear(i).errors?.serverError }}
											</mat-error>
										</mat-form-field>
										<!-- Name -->
										<mat-form-field
											class='pd-input-form-field pd-dialog-half-row'
											appearance='fill'
											hideRequiredMarker
										>
											<mat-label>{{ 'name' | educationAdditional: 'propertyTitle' }}</mat-label>
											<input
												matInput
												formControlName='name'
												required
											>
											<mat-error
												*ngIf='educationAdditionalsName(i).errors?.required'
												i18n='mat-error@@app-user-education-additionals-dialog-educationAdditionalsName-required'
											>Обязательное поле
											</mat-error>
											<mat-error *ngIf='educationAdditionalsName(i).errors?.onlySpace && !educationAdditionalsName(i).errors?.required'>
												Проверьте правильность данных
											</mat-error>
											<mat-error *ngIf='educationAdditionalsName(i).errors?.maxlength'>Введите
												максимум 500 символов!
											</mat-error>
											<mat-error *ngIf='educationAdditionalsName(i).errors?.serverError'>
												{{ educationAdditionalsName(i).errors?.serverError }}
											</mat-error>
										</mat-form-field>
									</div>

									<!-- Sector -->
									<mat-form-field
										class='pd-input-form-field'
										appearance='fill'
										hideRequiredMarker
									>
										<mat-label>{{ 'sector' | educationAdditional: 'propertyTitle' }}</mat-label>
										<input
											matInput
											formControlName='sector'
										>
										<mat-error *ngIf='educationAdditionalsSector(i).errors?.onlySpace && !educationAdditionalsSector(i).errors?.required'>
											Проверьте правильность данных
										</mat-error>
										<mat-error *ngIf='educationAdditionalsSector(i).errors?.maxlength'>Введите
											максимум 500 символов!
										</mat-error>
										<mat-error *ngIf='educationAdditionalsSector(i).errors?.required'>Обязательное
											поле
										</mat-error>
										<mat-error *ngIf='educationAdditionalsSector(i).errors?.serverError'>
											{{ educationAdditionalsSector(i).errors?.serverError }}
										</mat-error>
									</mat-form-field>
								</div>
							</div>
						</div>
					</ng-container>
					<ng-template #noEducationsAdditionals>
						<div class='pd-profile-no-form'>
							<button
								(click)='goAddEducationAdditional(0)'
								class='pd-icon-button pd-profile-no-form-button'
								mat-icon-button
							>
								<mat-icon
									class='pd-icon pd-profile-no-form-icon'
									svgIcon='additional-plus-100-100'
								></mat-icon>
								Добавить образование
							</button>
						</div>
					</ng-template>
				</div>
			</form>
		</div>
	</div>
</tui-scrollbar>
<div class='pd-dialog-footer align-end'>
	<div class='pd-dialog-footer-buttons'>
		<button
			mat-raised-button
			class='pd-button-secondary large'
			type='reset'
			[mat-dialog-close]='false'
		>
			Отменить
		</button>
		<button
			form='educationAdditionalsForm'
			mat-raised-button
			class='pd-button large'
			type='submit'
			[disabled]='(saving | async)'
		>
			<ng-container *ngIf='!(saving | async); else isSaving'>Сохранить</ng-container>
			<ng-template #isSaving>
				Сохраняем
			</ng-template>
		</button>
	</div>
</div>
