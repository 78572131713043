<ng-container *ngIf='!(loadingData | async); else isLoading'>
	<ng-container *ngIf='{ data: dataSource.data | async, count: dataSource.count | async } as both'>
		<ng-container *ngIf='both.data.length > 0; else emptySource'>
			<div class='pd-works'>
				<ng-container *ngFor='let element of both.data | slice: 0 : (pageIndex + 1) * pageSize'>
					<pdw-work-card
						[commands]="['/works', element.id]"
						[cardType]="'specialist'"
						[work]='element'
						(changed)='onRequest($event)'
					>
					</pdw-work-card>
				</ng-container>
			</div>
			<div *ngIf='remain | async as remain;'>
				<button
					class='pd-button-additional pd-button-additional-cards'
					(click)='goNextPage()'
					*ngIf='remain > 0; else noRemain'
					[disabled]='loadingNextPage | async'
				>
					{{loadingNextPage.value ? 'Загружаем' : 'Показать еще' }}
				</button>
			</div>
			<ng-template #noRemain>
				<ng-container *ngIf='pageIndex !== 0'>
					<p class='pd-basis-no-remain'>Вы посмотрели все работы</p>
				</ng-container>
			</ng-template>
		</ng-container>
		<ng-template #emptySource>
			<div class='pd-basis-card-empty'>
				<div class='pd-basis-card-empty-inner'>
					<ng-container *ngIf='hasFilter(filterValues); else noFilterValues'>
						<div class='pd-basis-card-empty-inner'>
							<mat-icon
								class='pd-icon'
								[svgIcon]="'additional2-alert-exclamation-2-100-100'"
							></mat-icon>
							<h2 class='pd-basis-card-empty-title'>По вашему запросу ничего не найдено</h2>
							<p class='pd-basis-card-empty-subtitle'>Измените поисковый запрос</p>
						</div>
					</ng-container>
					<ng-template #noFilterValues>
						<mat-icon
							class='pd-icon'
							[svgIcon]="'additional2-alert-exclamation-2-100-100'"
						></mat-icon>
						<h2 class='pd-basis-card-empty-title'>Нет работ</h2>
					</ng-template>
				</div>
			</div>
		</ng-template>
	</ng-container>
</ng-container>
<ng-template #isLoading>
	<div class='pd-basis-card-empty'>
		<div class='pd-basis-card-empty-inner'>
			<mat-spinner
				[diameter]='70'
				[strokeWidth]='5'
			></mat-spinner>
		</div>
	</div>
</ng-template>
