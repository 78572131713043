<ng-container *ngIf='currentCompanyView | async as companyView'>
	<div
		[routerLink]='commands'
		(mousedown)='onMouseDown($event)'
		(mouseup)='onMouseUp($event)'
		class='pd-company-card-inner'
		[queryParams]="{subscribed: companyView.subscribed}"
	>
		<!--Header-->
		<div class='pd-company-card-header'>
			<!--Logo-->
			<div
				class='pd-company-card-logo card-image'
				[style]="'background: linear-gradient(200.87deg, ' + logoColor(companyView) + ' 20.98%, rgba(219, 189, 207, 0.1) 91.37%)'"
			>
				<ng-container *ngIf='companyView.hasLogo === 1;'>
					<img
						class='pd-company-card-logo-img'
						[src]="companyView | avatar: 'api/Companies/Logo' | async"
						alt='Logo Image'
					/>
				</ng-container>
			</div>
			<!--Name-->
			<div class='pd-company-card-wrapper-title'>
				<div class='pd-company-card-title card-title'>
					<p
						class='pd-company-card-title-text'
						[ngClass]="{'short': canSubscribe(companyView) | async }"
					>{{ companyView.title }}</p>
					<!--Right side-->
					<ng-container *ngIf='canSubscribe(companyView) | async'>
						<button
							mat-icon-button
							class='pd-icon-button pd-card-bookmark'
							(click)='goCompanySubscribe($event, companyView)'
							[matTooltip]="'Добавить в избранное'"
							*ngIf='companyView.subscribed == 0'
						>
							<mat-icon
								class='pd-icon'
								svgIcon='additional-bookmark'
							></mat-icon>
						</button>
						<button
							mat-icon-button
							class='pd-icon-button pd-card-bookmark-favorite'
							(click)='goCompanyUnsubscribe($event, companyView)'
							[matTooltip]="'Убрать из избранного'"
							*ngIf='companyView.subscribed == 1'
						>
							<mat-icon
								class='pd-icon'
								svgIcon='additional-bookmark'
							></mat-icon>
						</button>
					</ng-container>
				</div>
				<!--Rating-->
				<div
					class='pd-company-card-sub-title'
					[ngClass]="{'empty-region' : !getCityOrRegion(companyView)}"
				>
					<p class='pd-company-card-region'>{{getCityOrRegion(companyView)}}</p>
					<app-rating-one-small [percent]='companyView.ratingPercent'>
					</app-rating-one-small>
				</div>
			</div>
		</div>
		<div
			class='card-categories-container'
			*ngIf='companyView?.industries?.length; else noIndustries'
		>
			<span
				*ngFor='let industry of companyView.industries'
				class='card-categories'
			>
				{{ industry.name }}
			</span>
		</div>
		<ng-template #noIndustries>
			<p class='pd-company-card-industries-empty'>Сферы деятельности не указаны</p>
		</ng-template>
	</div>
</ng-container>
