import { ChangeDetectionStrategy, Component, forwardRef, Inject, Injector, OnInit } from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	FormControlDirective,
	FormControlName,
	FormGroupDirective, NG_VALUE_ACCESSOR,
	NgControl,
	NgModel
} from '@angular/forms';
import { DestroyService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-slide',
	templateUrl: './slide.component.html',
	styleUrls: ['./slide.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		DestroyService,
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => SlideComponent),
		}
	]

})
export class SlideComponent implements OnInit, ControlValueAccessor {

	public control: FormControl;

	constructor(@Inject(Injector) private injector: Injector) {
	}

	ngOnInit(): void {
		this.setControl();
	}

	private setControl(): void {
		const injectedControl = this.injector.get(NgControl);

		switch (injectedControl.constructor) {
			case NgModel: {
				const { control } = injectedControl as NgModel;
				this.control = control;
				break;
			}
			case FormControlName: {
				this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
				break;
			}
			default: {
				this.control = (injectedControl as FormControlDirective).form as FormControl;
				break;
			}
		}
	}

	registerOnChange(fn: any): void {
	}

	registerOnTouched(fn: any): void {
	}

	writeValue(obj: any): void {
	}

}
