import { Component, OnInit } from '@angular/core';
import { WorkRequestState, WorkRequestView } from '@models/work-request-view';
import { AppUserView } from '@models/app-user-view';
import { BehaviorSubject, Subject } from 'rxjs';
import { WorkRequestService } from '@core/services/work-request.service';
import { AppUserService } from '@core/services/app-user.service';
import { ChatService } from '@core/services/chat.service';
import { takeUntil } from 'rxjs/operators';
import { UtilsService } from '@core/services/utils.service';
import { WorkRequestChatMessageView } from '@models/work-request-chat-message-view';
import { WorkAbstractComponent } from '@shared/work-abstract-component/work-abstract-component';
import { DestroyService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-work-details-chat-specialist',
	templateUrl: './work-details-chat-specialist.component.html',
	providers: [DestroyService]
})
export class WorkDetailsChatSpecialistComponent extends WorkAbstractComponent implements OnInit {
	unsubscribe: Subject<any> = new Subject<any>();
	currentUser: AppUserView;
	connectionEstablished: boolean = false;
	timeForLogo: Date = new Date();
	managerUser = new BehaviorSubject<AppUserView>(null);
	isConnecting = new BehaviorSubject<boolean>(false);
	isPanelDisabled: boolean = false;
	selectedWorkRequest: WorkRequestView = null;
	lastSelectedWorkRequest: WorkRequestView = null;
	initExpanded = false;

	constructor(private workRequestService: WorkRequestService,
				private appUserService: AppUserService,
				private destroy$: DestroyService,
				private chatService: ChatService,
				public utilsService: UtilsService) {
		super();
	}

	ngOnInit(): void {
		this.chatService.connectionEstablished.pipe(
			takeUntil(this.destroy$)
		).subscribe((established) => {
			if (established) this.connectionEstablished = true
		});

		this.workRequestService.my().pipe(
			takeUntil(this.destroy$),
			// filter(() => this.panel !== undefined)
		).subscribe(requests => {
			this.lastSelectedWorkRequest = requests.find((view) => view.work.id == this.workView.id);
			if (!this.utilsService.mobileQuery980px.getValue()) {
				this.selectedWorkRequest = this.lastSelectedWorkRequest;
				this.initExpanded = true;
			}
		});

		this.appUserService.getActiveUserView().pipe(
			takeUntil(this.destroy$)
		).subscribe(user => {
			this.currentUser = user;
		});

		this.appUserService.getAppUserView(this.workView.manager.id)
			.pipe(
				takeUntil(this.destroy$)
			).subscribe({
			next: v => this.managerUser.next(v)
		});
	}

	isUserOnline(appUserView: AppUserView): boolean | null {
		if (appUserView?.lastConnection) {
			appUserView.lastConnection = new Date(appUserView.lastConnection);
			const delta = (new Date().getTime() - appUserView.lastConnection.getTime()) / 1000 / 60;
			return delta < .3;
		}
		return null;
	}

	isConnectingChange(event: boolean): void {
		setTimeout(() => {
			this.isConnecting.next(event);
		}, 0);
	}

	onPanelCollapse(): void {
		this.selectedWorkRequest = null;
		this.isPanelDisabled = false;
	}

	onPanelClose(): void {
		this.isPanelDisabled = true;
	}

	onLastChatMessageReceived(w: WorkRequestChatMessageView): void {
		this.managerUser.next(w.workRequest.work.manager);
	}

	isRejected(): boolean {
		return this.lastSelectedWorkRequest?.state === WorkRequestState.cancelledBySpecialist || this.lastSelectedWorkRequest?.state === WorkRequestState.cancelledByClient
	}
}
