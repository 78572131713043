import { SortDirection } from '@angular/material/sort';
import { SortParams } from '../general/models/enums/sort-params';

export class AnySort {
	name: SortParams | string;
	direction: SortDirection;

	constructor(name: string, direction: SortDirection) {
		this.name = name;
		this.direction = direction;
	}
}
