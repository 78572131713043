import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollPaginationDirective } from './scroll-pagination.directive';


@NgModule({
	declarations: [
		ScrollPaginationDirective
	],
	exports: [
		ScrollPaginationDirective
	],
	imports: [
		CommonModule
	]
})
export class ScrollPaginationModule {
}
