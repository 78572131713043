<div class="pd-dialog-header">
  <p class="pd-dialog-header-title">{{ data.title }}</p>
  <button class="pd-icon-button" mat-icon-button [mat-dialog-close]="false">
    <mat-icon svgIcon="interface-close"></mat-icon>
  </button>
</div>
<tui-scrollbar class="pd-scrollbar pd-dialog-scroll">
  <div class="pd-dialog-content large-padding">
    <div *ngIf="companyViewForm">
      <form id="descriptionForm" [formGroup]="companyViewForm" (ngSubmit)="onSubmit(companyViewForm)">
        <div class="pd-dialog-column">
          <!-- Description -->
          <mat-form-field class="pd-input-form-field pd-textarea-form-field" appearance="fill"
                          appExtendableTextArea>
            <mat-label>{{ 'description' | company: 'propertyTitle' }}</mat-label>
            <tui-scrollbar class="pd-scrollbar pd-textarea-scrollbar">
              <div class="pd-textarea-wrapper">
                <div class="pd-textarea-relative-wrapper">
                  <div class="pd-textarea-pseudo-content" aria-hidden="true">
                    <span class="pd-textarea-pseudo"></span>
                    &nbsp;
                  </div>
                  <textarea
                    class="pd-textarea"
                    matInput
                    formControlName="description"
                    rows="12"
                  ></textarea>
                </div>
              </div>
            </tui-scrollbar>
            <button
              class="pd-icon-button gray"
              matSuffix
              mat-icon-button
              type="button"
              tabindex="-1"
            >
              <mat-icon class="pd-icon" svgIcon="additional2-stretch"></mat-icon>
            </button>
            <mat-error *ngIf="description.errors?.maxlength">
			  Введите максимум 5000 символов!
            </mat-error>
            <mat-error *ngIf="description.errors?.serverError">
              {{ description.errors?.serverError }}
            </mat-error>
          </mat-form-field>

        </div>
      </form>
    </div>
  </div>
</tui-scrollbar>
<div class="pd-dialog-footer align-end">
  <div class="pd-dialog-footer-buttons">
    <button mat-raised-button class="pd-button-secondary large" type="reset" [mat-dialog-close]="false">
      Отменить
    </button>
    <button form="descriptionForm" mat-raised-button class="pd-button large" type="submit" [disabled]="(saving | async) || companyViewForm.invalid">
      <ng-container *ngIf="!(saving | async); else isSaving">Сохранить</ng-container>
      <ng-template #isSaving>
        Сохраняем
      </ng-template>
    </button>
  </div>
</div>
