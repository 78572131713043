import {
	ChangeDetectionStrategy,
	Component,
	forwardRef,
	Inject,
	Injector,
	Input,
	OnInit,
	TemplateRef
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	FormControlDirective,
	FormControlName,
	FormGroupDirective, NG_VALUE_ACCESSOR,
	NgControl, NgModel
} from '@angular/forms';
import { tap } from 'rxjs';
import { DestroyService } from '@profdepo-ui/core';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'pdw-password-input',
	templateUrl: './password-input.component.html',
	styleUrls: ['./password-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PasswordInputComponent),
			multi: true,
		},
	]
})
export class PasswordInputComponent<T> implements OnInit, ControlValueAccessor {
	@Input() displayEyeButton: boolean = false;
	control!: FormControl<unknown>;
	type: 'text' | 'password' = 'password';

	constructor(@Inject(Injector) private injector: Injector,
				private destroy$: DestroyService) {
	}

	public ngOnInit(): void {
		this.setComponentControl();
	}

	private setComponentControl(): void {
		const injectedControl = this.injector.get(NgControl);

		switch (injectedControl.constructor) {
			case NgModel: {
				const { control, update } = injectedControl as NgModel;

				this.control = control;

				this.control.valueChanges
					.pipe(
						tap((value: T) => update.emit(value)),
						takeUntil(this.destroy$),
					)
					.subscribe();
				break;
			}
			case FormControlName: {
				this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
				break;
			}
			default: {
				this.control = (injectedControl as FormControlDirective).form as FormControl;
				break;
			}
		}
	}

	changeType() {
		this.type = this.type === 'password' ? 'text' : 'password';
	}

	registerOnChange(fn: any): void {
	}

	registerOnTouched(fn: any): void {
	}

	writeValue(obj: any): void {
	}
}
