import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {SupportService} from "@core/services/support.service";
import {supportCategories, SupportRequest} from "@requests/support-request";
import {NotificationService} from "@profdepo-ui/core";

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
})
export class SupportDialogComponent implements OnInit, OnDestroy {
  supportForm: FormGroup;
  saving = new BehaviorSubject<boolean>(false);
  unsubscribe: Subject<any> = new Subject<any>();

  categoryItems = supportCategories;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
    private supportService: SupportService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.supportForm = this.formBuilder.group({
      name: new FormControl(
        '',
        [
          Validators.required, Validators.maxLength(50),
          Validators.minLength(3)
        ]
      ),
      email: new FormControl(
        '',
        [Validators.required, Validators.email]
      ),
      category: new FormControl(supportCategories.Offer),
      description: new FormControl(
        '',
        [Validators.required]
      ),
    });
  }

  get name(): FormControl { return this.supportForm.get('name') as FormControl; }
  get email(): FormControl { return this.supportForm.get('email') as FormControl; }
  get category(): FormControl { return this.supportForm.get('category') as FormControl; }
  get description(): FormControl { return this.supportForm.get('description') as FormControl; }


  compareEnum(t1: any, t2: any): boolean {
    return +t1 === t2;
  }

  onSubmit({value, valid}){
    if(valid){
      this.saving.next(true);


      this.supportService.anonymousRequest(value as SupportRequest)
        .pipe(
          takeUntil(this.unsubscribe),
        )
        .subscribe((data) => {
            this.saving.next(false);
            this.dialogRef.close(data);
            this.notificationService.showSuccess(data.message);

          },
          (err) => {
            this.saving.next(false);
            this.notificationService.showDanger(err.error);
          }
        );
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }

}
