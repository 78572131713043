import {Component, Input, OnInit} from '@angular/core';
import {NewsPostView} from "@models/news-post-view";

@Component({
  selector: 'app-landing-news-post',
  templateUrl: './landing-news-post.component.html',
  styleUrls: ['./landing-news-post.component.scss']
})
export class LandingNewsPostComponent implements OnInit {
  @Input() newsPostView: NewsPostView;
  constructor() { }

  ngOnInit(): void {
  }

}
