import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { FormEventData } from '@public/pages/salary-register-page/salary-register-page.types';
import { EducationLevel } from '@models/education-view';

@Component({
	selector: 'pdw-salary-register-education-form',
	templateUrl: './salary-register-education-form.component.html',
	styleUrls: ['./salary-register-education-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalaryRegisterEducationFormComponent  {

	@Input() index: number;
	@Input() parentForm: FormGroup | FormArray;
	@Input() formGroup!: FormGroup;
	@Output() deleteFormGroup: EventEmitter<FormEventData> = new EventEmitter<FormEventData>();
	EDUCATION_LEVEL = EducationLevel;

	deleteCurrentGroup(): void {
		this.deleteFormGroup.next({ parentForm: this.parentForm, index: this.index });
	}

	get years(): Array<number> {
		const now = new Date().getUTCFullYear();
		return Array(now - (now - 60)).fill('').map((v, idx) => now - idx) as Array<number>;
	}

	get yearControl(): FormControl {
		return this.formGroup.get('year') as FormControl;
	}

	get level(): FormControl {
		return this.formGroup.get('level') as FormControl;
	}

	get name(): FormControl {
		return this.formGroup.get('name') as FormControl;
	}

	get speciality(): FormControl {
		return this.formGroup.get('speciality') as FormControl
	}

	get sector(): FormControl {
		return this.formGroup.get('sector') as FormControl;
	}

}
