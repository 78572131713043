<ng-container *ngIf='currentWorkRequestView | async as WorkRequestView'>
	<div
		[routerLink]="['/users', WorkRequestView.executor.id]"
		(mousedown)='onMouseDown($event)'
		(mouseup)='onMouseUp(WorkRequestView, $event)'
		class='pd-request-card-inner'
	>
		<!--Header-->
		<div class='card-header'>
			<p class='pd-request-card-title-text title'>{{WorkRequestView.work.name}}</p>
		</div>
		<div class='pd-request-card-content-wrapper'>
			<!--Content-->
			<div class='pd-request-card-content'>
				<div class='pd-request-card-user-content'>
					<!--Avatar-->
					<div
						class='pd-request-card-avatar'
						[style.background-color]='avatarColor(WorkRequestView.executor)'
					>
						<ng-container *ngIf='WorkRequestView.executor.hasAvatar === 1; else noAvatar'>
							<img
								class='pd-request-card-avatar-img'
								[src]='WorkRequestView.executor | avatar | async'
								alt='Avatar Image'
							/>
						</ng-container>
						<ng-template #noAvatar>
							<pdw-user-initial-letters
								[user]='WorkRequestView.executor'
								[canUpdate]='false'
								[size]="IMAGE_SIZE.MEDIUM"
							>
							</pdw-user-initial-letters>
						</ng-template>
						<div
							class='pd-profile-online-status pd-profile-online-status-into'
							[ngClass]="{'online' : isUserOnline(WorkRequestView.executor)}"
							[matTooltip]="isUserOnline(WorkRequestView.executor) ? 'В сети' : WorkRequestView.executor.lastConnection | appUserLastConnection"
						>
						</div>
					</div>
					<!--Right side-->
					<div class='pd-request-card-title-name'>
						<!--Title & Menu-->
						<div class='pd-request-card-title-text card-specialist-name'>
							{{WorkRequestView.executor | userTitleChecker}}
						</div>
						<!--Rating-->
						<div class='pd-request-card-rating-row'>
							<app-rating-one-small [percent]='WorkRequestView.executor.ratingPercent'>
							</app-rating-one-small>
							<p class='pd-request-card-request-state {{stateColorClass(WorkRequestView)}}'>
								{{ WorkRequestView.state == 0 && WorkRequestView.direction == 0 ? 'В ожидании' : WorkRequestView.state | workRequestState }}
							</p>
						</div>
					</div>
				</div>
				<p class='card-profession'>
					{{WorkRequestView.executor.profession}}
				</p>
				<!-- Actions-->
				<ng-container *ngIf='WorkRequestView.state === 0 || WorkRequestView.state === WorkRequestState.CandidatesList'>
					<div class='pd-request-card-action-buttons' *ngIf='WorkRequestView.work.isFreelance === YesNo.no; else freelanceActions'>
						<ng-container *ngIf='WorkRequestView?.direction === 1; else inviteButtonsVacancy'>
							<button
								mat-raised-button
								class='pd-button-secondary half-width'
								[disabled]='WorkRequestView?.work.specialistAssigned === 1'
								(click)='goAccept($event, WorkRequestView)'
							>
								Перейти в диалог
							</button>

							<button
								mat-raised-button
								class='pd-button-secondary warn half-width'
								(click)='goDelete($event, WorkRequestView)'
							>
								Отменить приглашение
							</button>
						</ng-container>
						<ng-template #inviteButtonsVacancy>
							<button
								mat-raised-button
								class='pd-button-secondary pd-button-sm-font half-width'
								[disabled]='WorkRequestView?.work.specialistAssigned === 1'
								(click)='goAccept($event, WorkRequestView)'
							>
								Перейти в диалог
							</button>
							<button
								mat-raised-button
								class='pd-button-secondary pd-button-sm-font warn half-width'
								(click)='goDecline($event, WorkRequestView)'
							>
								Отклонить отклик
							</button>
						</ng-template>
					</div>
				</ng-container>
				<ng-template #freelanceActions>
					<div class='pd-request-card-action-buttons'>
						<ng-container *ngIf='WorkRequestView?.direction === 1; else inviteButtons'>
							<button
								mat-raised-button
								class='pd-button-secondary warn'
								(click)='goDelete($event, WorkRequestView)'
							>
								Отменить приглашение
							</button>
						</ng-container>
						<ng-template #inviteButtons>
							<button
								mat-raised-button
								class='pd-button-secondary pd-button-sm-font half-width'
								[disabled]='WorkRequestView?.work.specialistAssigned === 1'
								(click)='acceptFreelance($event, WorkRequestView)'
							>
								Принять отклик
							</button>
							<button
								mat-raised-button
								class='pd-button-secondary pd-button-sm-font warn half-width'
								(click)='goDecline($event, WorkRequestView)'
							>
								Отклонить отклик
							</button>
						</ng-template>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
</ng-container>
