<header
	class='pd-header'
	#toolbar
>
	<div class='pd-header-title'>
		<ng-container *ngIf='mobileMenu; else burgerMenu'>
			<pdw-bread-crumbs
				class='pd-header-bread-crumbs'
				[breadCrumbs]='breadCrumbs | async'
				[mobileMenu]='true'
			></pdw-bread-crumbs>
		</ng-container>
		<ng-template #burgerMenu>
			<pdw-bread-crumbs
				class='pd-header-bread-crumbs'
				[breadCrumbs]='breadCrumbs | async'
			></pdw-bread-crumbs>
		</ng-template>
	</div>
	<div class='pd-header-icons'>
		<button
			*ngIf='user$ | async as user'
			class='pd-icon-button'
			mat-icon-button
			#notificationButton
			[disableRipple]='true'
			(click)='goNotifications($event)'
		>
			<ng-container *ngIf='currentNotificationCounts | async as data; else noCount'>
				<mat-icon
					class='pd-header-icon'
					svgIcon='general-notification'
					[matBadge]='data'
					matBadgeColor='primary'
				>
				</mat-icon>
			</ng-container>
			<ng-template #noCount>
				<mat-icon
					class='pd-header-icon'
					svgIcon='general-notification'
				></mat-icon>
			</ng-template>
		</button>
		<ng-container *ngIf='mobileMenu'>
			<button
				mat-icon-button
				class='pd-icon-button pd-header-icon'
				(click)='sideMenuRef.toggle()'
			>
				<mat-icon svgIcon='interface-menu-burger-horizontal'></mat-icon>
			</button>
		</ng-container>
	</div>
</header>

