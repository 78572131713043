import { Component, Inject, OnInit } from '@angular/core';
import { CompanyView } from '@models/company-view';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from '@core/services/company.service';
import { BehaviorSubject } from 'rxjs';
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidatorFn,
	Validators
} from '@angular/forms';
import { AbstractComponentDirective } from '@shared/abstract-component.directive';
import {
	debounceTime, distinctUntilChanged,
	filter,
	takeUntil,
} from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { CompanyEmailView } from '@models/company-email-view';
import { EmailPurpose } from '@models/abstract-email-view';
import { CompanyFromFnsApi } from '@utils/api-company-data';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { CompanyDataFromFnsApi, CompanyItemFromFnsApi, Filials } from '@models/company-from-fns-view';
import { EMAIL_REGEX } from '@utils/constants';
import { SupportDialogComponent } from '@public/support-dialog/support-dialog.component';
import { MaskitoOptions } from '@maskito/core';

export enum InnError {
	notFound = 'notFound',
	registered = 'registered'
}

export enum KppError {
	databaseKppMatch = 'notFound',
}

export interface CompanyDetailsDialogData {
	title: string;
	message: string;
	method: string;
	companyView: CompanyView;
}

@Component({
	selector: 'pdw-company-legal-details-dialog',
	templateUrl: './company-legal-details-dialog.component.html',
	styleUrls: ['./company-legal-details-dialog.component.scss'],
	providers: [DestroyService],
})
export class CompanyLegalDetailsDialogComponent extends AbstractComponentDirective implements OnInit {
	companyViewForm: FormGroup;
	legalFormGroup: FormGroup;

	innRegistered = false;
	innNotFound = false;
	kppMatch = false;

	companiesFromBase: CompanyView[] = [];
	companiesFromApi: CompanyItemFromFnsApi[] = [];

	saving = new BehaviorSubject<boolean>(false);
	isCheckingInn = new BehaviorSubject<boolean>(false);

	regexOnlyDigits = /^[0-9]+$/;
	httpRegexp = new RegExp('(([\\p{L}\\p{N}-]+)\\.)+[\\p{L}\\p{N}-]{2,}(:\\d+)?(\\\/[-\\p{L}\\p{N}_\\.~+%]*)*(\\?[\\p{L}\\p{N}&=]*)?(#[\\p{L}\\p{N}_]*)?$', 'gmu');


	maskitoOptions: MaskitoOptions = {
		mask: this.regexOnlyDigits,
	};
	constructor(
		public dialogRef: MatDialogRef<CompanyDetailsDialogData, boolean | CompanyView>,
		@Inject(MAT_DIALOG_DATA) public data: CompanyDetailsDialogData,
		private formBuilder: FormBuilder,
		private companyService: CompanyService,
		private notificationService: NotificationService,
		private destroy$: DestroyService,
		private dialog: MatDialog,
		@Inject(EMAIL_REGEX) private emailRegex: string
	) {
		super();
		this.legalFormGroup = this.formBuilder.group({
			postcode: new FormControl(this.data.companyView.legal.postcode, {
				validators: [Validators.maxLength(10)]
			}),
			country: new FormControl(this.data.companyView.legal.country),
			region: new FormControl(this.data.companyView.legal.region),
			street: new FormControl(this.data.companyView.legal.street),
			city: new FormControl(this.data.companyView.legal.city),
		});

		this.companyViewForm = this.formBuilder.group({
			name: new FormControl(this.data.companyView.name, {
				validators: [
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(100)
				],
			}),
			fullName: new FormControl(this.data.companyView.fullName, {
				validators: [
					Validators.required,
					Validators.minLength(6),
					Validators.maxLength(150),
				],
			}),
			url: new FormControl(this.data.companyView.url, {
				validators: [Validators.pattern(this.httpRegexp), Validators.maxLength(150)],
			}),
			email: new FormControl(this.data.companyView.emails[0]?.address, {
				validators: [Validators.pattern(this.emailRegex), Validators.maxLength(150)],
			}),
			inn: new FormControl(this.data.companyView.inn, {
				validators: [
					Validators.required,
					Validators.minLength(10),
					Validators.maxLength(12),
					Validators.pattern(this.regexOnlyDigits),
					(control): ValidatorFn => {
						if (this.innRegistered) {
							return <ValidatorFn><unknown>{ registered: true };
						}
						return null;
					},
					(control): ValidatorFn => {
						if (this.innNotFound) {
							return <ValidatorFn><unknown>{ notFound: true };
						}
						return null;
					}
				],
			}),
			kpp: new FormControl(this.data.companyView.kpp, {
				validators: [
					Validators.required,
					Validators.minLength(9),
					Validators.maxLength(9),
					Validators.pattern(this.regexOnlyDigits),
					(control): ValidatorFn => {
						if (this.kppMatch) {
							return <ValidatorFn><unknown>{ databaseKppMatch: true };
						}
						return null;
					}
				],
			}),
			ogrn: new FormControl(this.data.companyView.ogrn, {
				validators: [
					Validators.required,
					Validators.minLength(13),
					Validators.maxLength(15),
					Validators.pattern(this.regexOnlyDigits)
				],
			}),
			legalFormGroup: this.legalFormGroup,
		});
	}

	ngOnInit(): void {

		this.inn.valueChanges.pipe(
			takeUntil(this.destroy$),
			debounceTime(500),
			distinctUntilChanged(),
		).subscribe(value => {
			//очищает ошибки, кастомной валидации (проверки наличия инн в базе и по апи)
			this.clearInnErrors();
			if (this.inn.valid) {
				this.checkCompanyByInn();
			}
		})

		this.kpp.valueChanges.pipe(
			takeUntil(this.destroy$),
			debounceTime(500),
			distinctUntilChanged(),
		).subscribe(value => {
			//очищает ошибки, кастомной валидации (проверки наличия инн в базе и по апи)
			this.clearKppErrors();
			if (this.kpp.valid) {
				this.checkCompanyByKpp(value);
			}
		})

		this.isCheckingInn.pipe(
			takeUntil(this.destroy$),
			distinctUntilChanged(),
		).subscribe(value => {
			this.setMainFieldsBlock(!value);
			this.setControlBlock(this.kpp, !value);
			this.setControlBlock(this.inn, !value);
			this.setControlBlock(this.fullName, !value);
			this.setControlBlock(this.name, !value);
		});

		if (this.isEditMode) {
			this.name.disable();
			this.fullName.disable();
			this.inn.disable();
			this.kpp.disable();
			this.ogrn.disable();
			this.street.disable();
		}
	}

	get name() {
		return this.companyViewForm.get('name');
	}

	get fullName() {
		return this.companyViewForm.get('fullName');
	}

	get email() {
		return this.companyViewForm.get('email');
	}

	get url() {
		return this.companyViewForm.get('url');
	}

	get inn() {
		return this.companyViewForm.get('inn');
	}

	get kpp() {
		return this.companyViewForm.get('kpp');
	}

	get ogrn() {
		return this.companyViewForm.get('ogrn');
	}

	get postcode() {
		return this.legalFormGroup.get('postcode');
	}

	get country() {
		return this.legalFormGroup.get('country');
	}

	get region() {
		return this.legalFormGroup.get('region');
	}

	get street() {
		return this.legalFormGroup.get('street');
	}

	get city() {
		return this.legalFormGroup.get('city');
	}

	get isEditMode(): boolean {
		return this.data.method === 'update';
	}

	//здесь получаем данные от сервера и апи
	checkCompanyByInn(): void {
		//очищаем поля, и, ожидаем результата запроса
		this.isCheckingInn.next(true);
		this.setCompanyData(null);

		let inn = this.inn.value;
		//получаем данные компании от апи
		this.companyService.getCompanyDataByInnFromFns(inn)
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe((companyFromFnsApi: CompanyFromFnsApi) => {
				let companiesFromApi = companyFromFnsApi.items;
				companyFromFnsApi.items.forEach(item => {
					if (item.юл.филиалы) {
						item.юл.филиалы.forEach((filial: Filials) => {
							if (filial.наименование && filial.кпп) {
								const filialData: CompanyDataFromFnsApi = {
									...item.юл,
									наимПолнЮЛ: filial.наименование,
									кпп: filial.кпп ? filial.кпп : item.юл.кпп
								}
								const filialItem: CompanyItemFromFnsApi = {
									юл: filialData
								}
								companiesFromApi.push(filialItem)
							}
						})
					}
				})

				this.companiesFromApi = companyFromFnsApi.items;
				const firstCompanyFromApi = this.companiesFromApi?.[0];
				if (firstCompanyFromApi) {
					this.setCompanyData(firstCompanyFromApi);
					//если это ИП
					if (!firstCompanyFromApi.юл.кпп) {
						if (this.inn == inn) {
							this.setInnError(InnError.registered);
						}
					}
				} else {
					this.setInnError(InnError.notFound);
				}
				this.isCheckingInn.next(false);
			})
	}

	checkCompanyByKpp(kpp: string): void {
		let kppMachCompany = this.companiesFromBase.find(x => x.kpp == kpp);
		if (kppMachCompany && kpp !== this.data.companyView.kpp) {
			this.setKppError(KppError.databaseKppMatch);
		}
	}

	setCompanyByKpp(kpp: string): void {
		let apiMachCompany = this.companiesFromApi.find((x: CompanyItemFromFnsApi) => Number(x.юл.кпп) === Number(kpp));
		if (apiMachCompany)
			this.setCompanyKppAndName(apiMachCompany);
	}

	private setInnError(error: InnError): void {
		if (error == InnError.registered)
			this.innRegistered = true;
		if (error == InnError.notFound)
			this.innNotFound = true;
		this.inn.updateValueAndValidity();
	}

	private setKppError(error: KppError): void {
		if (error == KppError.databaseKppMatch)
			this.kppMatch = true;
		this.kpp.updateValueAndValidity();
	}

	private clearInnErrors(): void {
		this.innRegistered = false;
		this.innNotFound = false;
		this.inn.updateValueAndValidity();
	}

	private clearKppErrors(): void {
		this.kppMatch = false;
		this.kpp.updateValueAndValidity();
	}

	private setMainFieldsBlock(condition: boolean) {
		this.setControlBlock(this.ogrn, condition);
		this.setControlBlock(this.postcode, condition);
		this.setControlBlock(this.country, condition);
		this.setControlBlock(this.region, condition);
		this.setControlBlock(this.street, condition);
		this.setControlBlock(this.city, condition);
	}

	private setControlBlock(control: AbstractControl, enabled: boolean) {
		if (enabled && control.disabled) control.enable();
		if (!enabled && control.enabled) control.disable();
	}

	private setCompanyData(companyData?: CompanyItemFromFnsApi): void {
		this.setCompanyKppAndName(companyData);
		this.ogrn.setValue(companyData?.юл.огрн ? companyData?.юл.огрн : '');
		this.setCompanyAddress(companyData);
	}

	private setCompanyKppAndName(companyData?: CompanyItemFromFnsApi): void {
		const kppData = companyData?.юл.кпп;
		if (kppData) {
			this.kpp.setValue(kppData ? kppData : '');
			this.name.setValue(companyData?.юл.наимСокрЮЛ ? companyData?.юл.наимСокрЮЛ : '');
			this.fullName.setValue(companyData?.юл.наимПолнЮЛ ? companyData?.юл.наимПолнЮЛ : '');
			this.ogrn.setValue(companyData?.юл.огрн ? companyData?.юл.огрн : '');
			this.street.setValue(companyData?.юл.адрес.formattedAddress ? companyData?.юл.адрес.formattedAddress : '');
			this.city.setValue(companyData?.юл.адрес.city ? companyData?.юл.адрес.city : '');
		}
	}

	private setCompanyAddress(companyData?: CompanyItemFromFnsApi): void {
		this.postcode.setValue(companyData?.юл.адрес.индекс ?? '');
		this.street.setValue(companyData?.юл.адрес.formattedAddress ?? '');
		this.city.setValue(companyData?.юл.адрес.city ?? '');
	}

	chooseBranch($event): void {
		//если вводим филиал вручную
		if ($event.option.id == 0) {
			this.kpp.setValue('');
			this.fullName.setValue('');
			this.name.setValue('');
		} else
			this.setCompanyByKpp($event.option.id);
	}

	onSubmit({ value, valid }): void {
		if (valid) {
			this.saving.next(true);
			// copy form values to object
			let companyView = Object.assign({}, this.data.companyView);
			companyView.name = this.name.value;
			companyView.fullName = this.fullName.value;
			companyView.kpp = this.kpp.value;
			companyView.inn = this.inn.value;
			companyView.legal = this.legalFormGroup.value;
			companyView.ogrn = this.ogrn.value;
			companyView.url = this.url.value;

			if (this.email.value) {
				const companyEmailView = new CompanyEmailView();
				companyEmailView.id = 0;
				companyEmailView.address = this.email.value;
				companyEmailView.purpose = EmailPurpose.other;

				companyView.emails = [companyEmailView];
			} else {
				companyView.emails = [];
			}
			// update data
			this.companyService.callCompanyView(this.data.method, companyView)
				.pipe(
					takeUntil(this.destroy$)
				)
				.subscribe({
					next: (companyView) => {
						this.saving.next(false);
						this.dialogRef.close(companyView);
					},
					error: (err) => {
						this.saving.next(false);
						this.notificationService.showDanger(errorTitle(err));
					}
				});
		}
	}

	getCompanyName(item: CompanyItemFromFnsApi) {
		return item.юл.наимПолнЮЛ ? item.юл.наимПолнЮЛ : item.юл.наимСокрЮЛ;
	}

	getCurrentInn() {
		return this.inn.value;
	}

	getCompanyKpp(item: CompanyItemFromFnsApi) {
		return item.юл.кпп;
	}

	getNoteText() {
		return this.isEditMode ? 'Вы не можете самостоятельно менять данные компании, уже указанные Вами. Для изменения данных, пожалуйста, обратитесь в ' : 'Начните отсюда. Автозаполнение реквизитов по ИНН';
	}

	getBtnText() {
		return this.isEditMode ? 'Сохранить' : 'Далее';
	}

	goSupport() {
		this.dialog
			.open(SupportDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: "581px",
				minHeight: "320px",
				panelClass: ["pd-dialog"],
				data: {
					title: "Поддержка",
				},
			})
			.afterClosed()
			.pipe(takeUntil(this.destroy$), filter((result) => result))
			.subscribe({
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				},
			});
	}
}
