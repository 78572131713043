import {Component, Input, OnInit} from '@angular/core';
import {AbstractSkillView} from "../../models/abstract-skill-view";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-skills-details',
  templateUrl: './skills-details.component.html',
})
export class SkillsDetailsComponent implements OnInit {
  @Input() maxCount: number = undefined;
  @Input('skillViews')
  set data(value: AbstractSkillView[]) {
    this._data.next(value);
  };
  get data(): AbstractSkillView[] {
    return this._data.getValue();
  }
  private _data = new BehaviorSubject<AbstractSkillView[]>([]);
  @Input() markedSkillViews: AbstractSkillView[];
  showAll = new BehaviorSubject<boolean>(false);
  constructor() { }

  ngOnInit(): void {
  }
  isMarked(skillView: AbstractSkillView): boolean {
    return this.markedSkillViews && this.markedSkillViews.some(x => x.id === skillView.id);
  }
  goShow(): void {
    this.showAll.next(true);
  }
  goHide(): void {
    this.showAll.next(false);
  }
}
