import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AbstractModelService} from "@core/services/abstract-model.service";
import {HttpClient} from "@angular/common/http";
import {WorkRequestChatMessageView} from "@models/work-request-chat-message-view";
import {ChatMessageCounts} from "@models/chat-message-counts";

@Injectable({
  providedIn: 'root'
})
export class ChatMessageService {
  private chatMessagesUrl = 'api/WorkRequestChatMessages';
  public lastClientAddedMessage = new EventEmitter<WorkRequestChatMessageView>();
  constructor(private http: HttpClient, private modelService: AbstractModelService) {
  }

  /**
   * Преобразование string в Date после десериализации
   * @param workRequestChatMessageView
   */
  public static parseDates(workRequestChatMessageView : WorkRequestChatMessageView): WorkRequestChatMessageView {
    if (workRequestChatMessageView) {
      workRequestChatMessageView.createTime = new Date(workRequestChatMessageView.createTime);
    }
    return workRequestChatMessageView;
  }
  /**
   *
   * Отклики
   *
   */

  /**
   * Отправляет сообщение чата для отклика
   * @param id номер запроса
   * @param workRequestChatMessageView
   */
  add(id: number, workRequestChatMessageView : WorkRequestChatMessageView): Observable<WorkRequestChatMessageView> {
    const url = `${this.chatMessagesUrl}/Add/${id}`;
    return this.http.post<WorkRequestChatMessageView>(url, workRequestChatMessageView, this.modelService.httpOptions)
      .pipe(
        map(x => ChatMessageService.parseDates(x) as WorkRequestChatMessageView),
      );
  }

  /**
   * Загружает все сообщения для указанной отклика
   * @param id идентификатор работы
   */
  all(id: number): Observable<WorkRequestChatMessageView[]> {
    const url = `${this.chatMessagesUrl}/All/${id}`;
    return this.http.get<WorkRequestChatMessageView[]>(url, this.modelService.httpOptions)
      .pipe(
        map(xx => xx.map(x => ChatMessageService.parseDates(x) as WorkRequestChatMessageView)),
      );
  }

  /**
   *
   * Сообщения
   *
   */

  /**
   * Возвращает количество непрочитанные сообщения пользователя
   */
  count(): Observable<ChatMessageCounts> {
    const url = `${this.chatMessagesUrl}/Counts`;
    return this.http.get<ChatMessageCounts>(url, this.modelService.httpOptions);
  }
  /**
   * Редактирует сообщение
   * @param id идентификатор сообщения
   * @param newMessage текст нового сообщения
   */
  edit(id: number, newMessage: string): Observable<any> {
    const url = `${this.chatMessagesUrl}/Edit/${id}/${newMessage}`;
    return this.http.get<any>(url, this.modelService.httpOptions);
  }
  /**
   * Удаляет сообщение
   * @param id идентификатор сообщения
   */
  delete(id: number): Observable<any> {
    const url = `${this.chatMessagesUrl}/Delete/${id}`;
    return this.http.get<any>(url, this.modelService.httpOptions);
  }
}
