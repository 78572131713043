<a
	class='pdw-landing-news-post'
	[href]='newsPostView.url'
>
	<p class='pdw-landing-news-post-title'>
		{{newsPostView.createTime | date : 'dd MMM YYYY'}}
		<span>{{newsPostView.title}}</span>
	</p>
	<p class='pdw-landing-news-post-description'>{{newsPostView.description}}</p>
</a>
