import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {HeaderService} from "@core/services/header.service";
import {BehaviorSubject} from "rxjs";
import {MenuItem} from "@models/menu-item";
import {AdministratorHomeMenu} from "@models/administrator-home-menu";
import {SkillMenuTitles, AbstractSkillView} from "@models/abstract-skill-view";
import {AppUserService} from "@core/services/app-user.service";
import {DashboardMenuTitles} from "@models/dashboard";
import {HardSkillMenuTitles} from "@models/hard-skill-view";
import {SoftSkillMenuTitles} from "@models/soft-skill-view";
import {WorkMenuTitles, WorkView} from "@models/work-view";
export enum MenuContentType {
  home,
  person,
  skill,
  project,
  task,
  work
}
@Injectable({
  providedIn: 'root'
})
export class AdministratorMenuService {
  private appUsersUrl = 'api/AppUsers';
  private menuItemsSource = new BehaviorSubject<MenuItem[]>([]);
  private appUserService: AppUserService;
  private headerService: HeaderService;

  constructor(
    private http: HttpClient,
    @Inject(AppUserService) appUserService: AppUserService,
    @Inject(HeaderService) headerService: HeaderService,
  ) {
    this.appUserService = appUserService;
    this.headerService = headerService;
  }

  currentMenuItems = this.menuItemsSource.asObservable();

  changeMenuContentType(menuContentType: MenuContentType, model: any = null, method: string) {
    switch (menuContentType) {
      case MenuContentType.home:
        this.menuItemsSource.next(this.getHomeMenuItems(null, method));
        break;
      case MenuContentType.skill:
        this.menuItemsSource.next(this.getSkillMenuItems(null, model, method));
        break;
      case MenuContentType.work:
        this.menuItemsSource.next(this.getWorkMenuItems(null, model, method));
        break;
    }
  }

  // <editor-fold desc="Home menu">
  private getHomeMenuItems(homeMenu: AdministratorHomeMenu, method: string) {
    return [
      this.getDashboardMenuItem(homeMenu, method),
      this.getHardSkillsMenuItem(homeMenu, method),
      this.getSoftSkillsMenuItem(homeMenu, method),
      this.getWorksMenuItem(homeMenu, method),
      this.getNewsPostsMenuItem()
    ];
  }

  private getDashboardMenuItem(homeMenu: AdministratorHomeMenu, method: string) {
    if (method === 'dashboard') {
      this.headerService.changeCurrentTitle(DashboardMenuTitles.primaryTitleItems);
    }
    return new MenuItem(
      DashboardMenuTitles.primaryTitleItems,
      DashboardMenuTitles.descriptionItems,
      ['/administrator/dashboard'],
      null,
      DashboardMenuTitles.icon
    );
  }

  private getHardSkillsMenuItem(homeMenu: AdministratorHomeMenu, method: string) {
    if (method === 'hardSkills') {
      this.headerService.changeCurrentTitle(HardSkillMenuTitles.primaryTitleItems);
    }
    return new MenuItem(
      HardSkillMenuTitles.primaryTitleItems,
      HardSkillMenuTitles.descriptionItems,
      ['hardSkills'],
      homeMenu && homeMenu.skillCount > 0 ? homeMenu.skillCount.toString() : null,
      HardSkillMenuTitles.icon
    );
  }

  private getSoftSkillsMenuItem(homeMenu: AdministratorHomeMenu, method: string) {
    if (method === 'softSkills') {
      this.headerService.changeCurrentTitle(SoftSkillMenuTitles.primaryTitleItems);
    }
    return new MenuItem(
      SoftSkillMenuTitles.primaryTitleItems,
      SoftSkillMenuTitles.descriptionItems,
      ['softSkills'],
      homeMenu && homeMenu.skillCount > 0 ? homeMenu.skillCount.toString() : null,
      SoftSkillMenuTitles.icon
    );
  }

  private getWorksMenuItem(homeMenu: AdministratorHomeMenu, method: string) {
    if (method === 'works') {
      this.headerService.changeCurrentTitle(WorkMenuTitles.primaryTitleItems);
    }
    return new MenuItem(
      WorkMenuTitles.primaryTitleItems,
      WorkMenuTitles.descriptionItems,
      ['works'],
      null,
      WorkMenuTitles.icon
    );
  }

  private getNewsPostsMenuItem() {
    return new MenuItem(
      'Новостной блог',
      'Все посты',
      ['newsPosts'],
      null,
      'newspaper'
    )
  }

  // </editor-fold>
  // <editor-fold desc="Skill menu">
  private getSkillMenuItems(skillMenu: any, skillView: AbstractSkillView, method: string) {
    if (method === 'create') {
      return [
        this.getSkillsBackHomeMenuItem(skillView),
        this.getSkillMenuItem(skillView, method),
      ];
    } else {
      return [
        this.getSkillsBackHomeMenuItem(skillView),
        this.getSkillMenuItem(skillView, method),
        // this.getSkillPersonsMenuItem(skillMenu, skillView, method),
      ];
    }
  }

  private getSkillsBackHomeMenuItem(skillView: AbstractSkillView) {
    return new MenuItem(
      SkillMenuTitles.primaryTitleItems,
      SkillMenuTitles.descriptionItems,
      [skillView.kind + 'Skills'],
      null,
      'keyboard_arrow_left'
    );
  }

  private getSkillMenuItem(skillView: AbstractSkillView, method: string) {
    switch (method) {
      case 'create':
        this.headerService.changeCurrentTitle(SkillMenuTitles.primaryTitleCreate);
        return new MenuItem(
          SkillMenuTitles.primaryTitleCreate,
          SkillMenuTitles.descriptionCreate,
          [skillView.kind + 'Skill/create'],
          null,
          SkillMenuTitles.icon
        );
      case 'update':
        this.headerService.changeCurrentTitle(skillView.title);
        return new MenuItem(
          skillView.title,
          SkillMenuTitles.descriptionItem,
          [skillView.kind + 'Skill/update', skillView.id],
          null,
          SkillMenuTitles.icon
        );
      default:
        this.headerService.changeCurrentTitle(skillView.title);
        return new MenuItem(
          skillView.title,
          SkillMenuTitles.descriptionItem,
          [skillView.kind + 'Skill', skillView.id],
          null,
          SkillMenuTitles.icon
        );
    }
  }
  // </editor-fold>
  // <editor-fold desc="Work menu">
  private getWorkMenuItems(workMenu: any, workView: WorkView, method: string) {
    if (method === 'create') {
      return [
        this.getWorksBackHomeMenuItem(workView),
        this.getWorkMenuItem(workView, method),
      ];
    } else {
      return [
        this.getWorksBackHomeMenuItem(workView),
        this.getWorkMenuItem(workView, method),
        // this.getSkillPersonsMenuItem(workMenu, workView, method),
      ];
    }
  }

  private getWorksBackHomeMenuItem(workView: WorkView) {
    return new MenuItem(
      WorkMenuTitles.primaryTitleItems,
      WorkMenuTitles.descriptionItems,
      ['works'],
      null,
      'keyboard_arrow_left'
    );
  }

  private getWorkMenuItem(workView: WorkView, method: string) {
    switch (method) {
      case 'create':
        this.headerService.changeCurrentTitle(WorkMenuTitles.primaryTitleCreate);
        return new MenuItem(
          WorkMenuTitles.primaryTitleCreate,
          WorkMenuTitles.descriptionCreate,
          ['work/create'],
          null,
          WorkMenuTitles.icon
        );
      case 'update':
        this.headerService.changeCurrentTitle(workView.title);
        return new MenuItem(
          workView.title,
          WorkMenuTitles.descriptionItem,
          ['work/update', workView.id],
          null,
          WorkMenuTitles.icon
        );
      default:
        this.headerService.changeCurrentTitle(workView.title);
        return new MenuItem(
          workView.title,
          WorkMenuTitles.descriptionItem,
          ['work', workView.id],
          null,
          WorkMenuTitles.icon
        );
    }
  }
  // </editor-fold>
}
