import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'workHistory'
})
export class WorkHistoryPipe extends AbstractModelPipe implements PipeTransform {
  properties = [
    {name: 'attribute', title: 'Атрибут', hint: 'Атрибут'},
    {name: 'value', title: 'Значение', hint: 'Значение'},
    {name: 'state', title: 'Статус', hint: 'Статус изменения'},
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
  allProperties() {
    return this.properties;
  }
}
