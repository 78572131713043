<div
	*ngIf='hasValue()'
	class='pd-state-snack-card {{stateColorClass()}}'
	[ngSwitch]='type'
>
	<ng-container *ngSwitchCase="'certain'">
		{{value | workStateValue}}
	</ng-container>
	<ng-container *ngSwitchCase="'abstractFinished'">
		{{toNormalizedOutputValue(toAbstractFinish(value)) | workStateValue}}
	</ng-container>
	<ng-container *ngSwitchCase="'abstractFinishedExcludeSuccessed'">
		{{toAbstractFinishExcludeSuccessed(value) | workStateValue}}
	</ng-container>
	<ng-container *ngSwitchCase="'exclusiveForSpecialist'">
		{{toExclusiveForSpecialist(value) | workStateValue}}
	</ng-container>
</div>
