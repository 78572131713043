import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppUserDialogData, AppUserView } from '@models/app-user-view';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { personNamesRegEx } from '@utils/constants';
import { WhitespaceValidator } from '@utils/helpers/validator-helpers';
import { forkJoin, tap } from 'rxjs';
import { WorkTypeView } from '@models/work-type-view';
import { WorkFormatView } from '@models/work-format-view';
import { WorkFormatService } from '@core/services/work-format.service';
import { WorkTypeService } from '@core/services/work-type.service';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { TrueLoadingService } from '@core/services/true-loading.service';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { AppUserService } from '@core/services/app-user.service';
import { filloutFormError, filloutRelationships } from '@utils/form-helper';
import { HttpErrorResponse } from '@angular/common/http';
import { errorTitle } from '@utils/helpers/error-helpers';

@Component({
	selector: 'pdw-specialization-modal',
	templateUrl: './specialization-modal.component.html',
	styleUrls: ['./specialization-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, {
		provide: 'saving',
		useClass: TrueLoadingService
	}, {
		provide: 'loading',
		useClass: TrueLoadingService
	}]
})
export class SpecializationModalComponent implements OnInit {

	formGroup: FormGroup;
	workTypesArray: Array<WorkTypeView>;
	workFormatsArray: Array<WorkFormatView>;

	constructor(@Inject(MAT_DIALOG_DATA) public data: AppUserDialogData,
				public dialogRef: MatDialogRef<AppUserDialogData, boolean | AppUserView>,
				private fb: FormBuilder,
				private workFormatService: WorkFormatService,
				private workTypeService: WorkTypeService,
				private destroy$: DestroyService,
				private userService: AppUserService,
				private notificationService: NotificationService,
				@Inject('saving') public saving$: TrueLoadingService,
				@Inject('loading') public loading$: TrueLoadingService) {

		this.formGroup = fb.group({
			profession: [this.data.appUserView.profession,
				[WhitespaceValidator, Validators.maxLength(100)]],
			workFormats: [this.data.appUserView.workFormats],
			workTypes: [this.data.appUserView.workTypes],
			preferredReward: [this.data.appUserView?.preferredReward ?? null, [Validators.pattern('^[0-9]*$')]],
		});
		this.saving$.next(false);
		this.loading$.next(true);
	}

	ngOnInit(): void {
		forkJoin([this.workFormatService.all().pipe(filter(arr => Array.isArray(arr))),
			this.workTypeService.all().pipe(filter(arr => Array.isArray(arr)))])
			.pipe(takeUntil(this.destroy$),
				finalize(() => this.loading$.next(false)))
			.subscribe(result => {
				this.workFormatsArray = result[0];
				this.workTypesArray = result[1];
			});
	}

	get profession(): AbstractControl {
		return this.formGroup.get('profession');
	}

	get workFormats(): AbstractControl {
		return this.formGroup.get('workFormats');
	}

	get workTypes(): AbstractControl {
		return this.formGroup.get('workTypes');
	}

	get preferredReward(): AbstractControl {
		return this.formGroup.get('preferredReward');
	}

	onSubmit(): void {
		if (this.formGroup.valid) {
			const user = filloutRelationships(this.data.appUserView, this.formGroup);
			this.userService.updateAppUserView(user)
				.pipe(
					tap(() => this.saving$.next(true)),
					takeUntil(this.destroy$)
				)
				.subscribe((value) => {
						this.saving$.next(false);
						this.dialogRef.close(value);
					}, (err) => {
						this.saving$.next(false);
						if (err instanceof HttpErrorResponse) {
							if (err.status === 400) {
								filloutFormError(this.formGroup, err);
							} else {
								this.notificationService.showDanger(errorTitle(err));
							}
						}
					}
				);
		} else {
			this.notificationService.showDanger('Что-то пошло не так');
		}
	}

}
