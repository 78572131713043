import { Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, skip, Subject, } from 'rxjs';
import { distinctUntilChanged, filter, finalize, takeUntil } from 'rxjs/operators';
import { AnySort } from '@utils/any-sort';
import { WorkRequestService } from '@core/services/work-request.service';
import { AllWorkDataSource } from '@core/dataSources/all-work-data-source';
import { NotificationsRequestService } from '@core/services/notifications-request.service';
import { AppUserService } from '@core/services/app-user.service';
import { WorkService, WorksFilter } from '@core/services/work.service';
import { WorkView } from '@models/work-view';
import { AppUserView } from '@models/app-user-view';

@Component({
	selector: 'app-works-specialist-card',
	templateUrl: './works-specialist-card.component.html',
	host: { 'class': 'pd-expanded-height' },
})
export class WorksSpecialistCardComponent implements OnInit, OnDestroy {
	activeAppUserView = new BehaviorSubject<AppUserView>(null);

	/**
	 * Источник данных для получения записей удовлетворяющих критериям поиска
	 */
	@Input() dataSource: AllWorkDataSource;

	/**
	 * Фильтр
	 * @param value
	 */
	@Input()
	set filterValues(value: WorksFilter) {
		this._filterValues.next(value);
	};

	get filterValues(): WorksFilter {
		return this._filterValues.getValue();
	}

	private _filterValues = new BehaviorSubject<WorksFilter>(null);

	/**
	 * Сортировка
	 * @param value
	 */
	@Input('sortValues')
	set sortValues(value: AnySort) {
		this._sortValues.next(value);
	};

	get sortValues(): AnySort {
		return this._sortValues.getValue();
	}

	private _sortValues = new BehaviorSubject<AnySort>(null);
	pageIndex = 0;
	pageSize = 6;
	remain = new BehaviorSubject<number>(null);
	loadingData = new BehaviorSubject<boolean>(false);
	loadingNextPage = new BehaviorSubject<boolean>(false);
	unsubscribe: Subject<any> = new Subject<any>();

	constructor(
		private router: Router,
		private appUserService: AppUserService,
		private workRequestService: WorkRequestService,
		private notificationService: NotificationsRequestService,
		private workService: WorkService,
		@Inject(LOCALE_ID) public locale: string,
	) {
	}

	ngOnInit(): void {
		this.loadingData.next(true);

		this.appUserService.getActiveUserAsObservable()
			.pipe(
				takeUntil(this.unsubscribe),
				filter(x => x !== null)
			)
			.subscribe({
				next: v => this.activeAppUserView.next(v)
			});

		this.load(this.pageIndex, this.pageSize);
		this.loadCount();

		this._filterValues
			.pipe(
				skip(1),
				takeUntil(this.unsubscribe),
				filter(x => x !== null),
				distinctUntilChanged(),
			)
			.subscribe(data => {
				this.loadingData.next(true);
				this.pageIndex = 0;
				this.load(this.pageIndex, this.pageSize);
				this.loadCount();
			});

		this._sortValues
			.pipe(
				skip(1),
				takeUntil(this.unsubscribe),
				filter(x => x !== null),
				distinctUntilChanged(),
			)
			.subscribe(data => {
				this.loadingData.next(true);
				this.load(0, this.pageSize * (this.pageIndex + 1));
			});

		this.workService.shouldReload
			.pipe(
				takeUntil(this.unsubscribe),
				filter(x => x === true)
			)
			.subscribe({
				next: v => {
					this.loadingData.next(true);
					this.load(0, this.pageSize * (this.pageIndex + 1));
					this.loadCount();
				}
			});
	}

	loadCount(): void {
		this.dataSource.loadCount(this.filterValues)
			.pipe(
				takeUntil(this.unsubscribe),
			)
			.subscribe(x => {
				const remain = x - (this.pageIndex + 1) * this.pageSize;
				this.remain.next(remain > this.pageSize ? this.pageSize : remain);
			});
	}

	/**
	 * Загружает указанную страницу данных указанного размера
	 * @param pageIndex номер страницы
	 * @param pageSize размер страницы
	 */
	load(pageIndex: number, pageSize: number): void {
		this.dataSource.load(
				this.filterValues,
				this.sortValues.name + ',' + this.sortValues.direction,
				pageIndex,
				pageSize)
			.pipe(
				finalize(() => {
					this.loadingData.next(false);
					this.loadingNextPage.next(false);
					this.workService.shouldReload.next(false);
				}),
				takeUntil(this.unsubscribe),
			)
			.subscribe(x => {
				this.workService.shouldReload.next(false);
				this.loadingData.next(false);
				this.loadingNextPage.next(false);
			});
	}

	/**
	 * Загружает указанную страницу данных указанного размера
	 * @param pageIndex номер страницы
	 * @param pageSize размер страницы
	 */
	loadMore(pageIndex: number, pageSize: number): void {
		this.dataSource.loadMore(
				this.filterValues,
				this.sortValues.name + ',' + this.sortValues.direction,
				pageIndex,
				pageSize)
			.pipe(
				finalize(() => {
					this.loadingData.next(false);
					this.loadingNextPage.next(false);
				}),
				takeUntil(this.unsubscribe),
			)
			.subscribe(x => {
				this.loadingData.next(false);
				this.loadingNextPage.next(false);
			});
	}

	/**
	 * Загружает следующую страницу данных
	 */
	goNextPage(): void {
		this.loadingNextPage.next(true);
		this.pageIndex++;
		this.loadMore(this.pageIndex, this.pageSize);
		this.loadCount();
	}


	onRequest(event): void {
		this.reloadWork(event);
	}

	reloadWork(workView: WorkView): void {
		this.dataSource.loadOne(workView.id)
			.pipe(
				takeUntil(this.unsubscribe),
			)
			.subscribe();
	}

	/**
	 * Проверяет есть ли значения в фильтре
	 * @param worksFilter
	 */
	hasFilter(worksFilter: WorksFilter): boolean {
		return WorkService.hasFilter(worksFilter);
	}

	/**
	 * Отписывает подписки при уничтожении компоненты
	 */
	ngOnDestroy() {
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}
}
