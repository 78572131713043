import { ChangeDetectorRef, Component, Inject, Input, LOCALE_ID, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppUserType, AppUserView, WorkFormatView } from '@models/app-user-view';
import { BehaviorSubject, combineLatestWith, Observable, of } from 'rxjs';
import { AppUserService } from '@core/services/app-user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { catchError, filter, finalize, switchMap, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { errorTitle } from '@utils/helpers/error-helpers';
import { pushFakeHistoryState } from '@utils/functions';
import { AppUserResetAnyPasswordDialogComponent } from '@shared/app-user-reset-any-password-dialog/app-user-reset-any-password-dialog.component';
import { AppUserSupportDialogComponent } from '@shared/app-user-support-dialog/app-user-support-dialog.component';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { SpecializationModalComponent } from '../../general/pages/general-profile/components/specialization-modal/specialization-modal.component';
import { RoleService } from '@core/services/role.service';
import { AuthDialogComponent } from '@shared/auth-dialog/auth-dialog.component';
import { YesNo } from '@models/enums';
import { AuthService } from '@core/services/auth.service';
import { WorkTypeView } from '@models/work-type-view';
import { ChangeRoleConfirmDialogComponent } from '@general/components/dialog/change-role-confirm-dialog/change-role-confirm-dialog.component';

@Component({
	selector: "pdw-user-details",
	templateUrl: "./app-user-details.component.html",
	host: { class: "pd-expanded-height pd-profile" },
	styleUrls: ["./app-user-details.component.scss"],
})
export class AppUserDetailsComponent implements OnInit {
	@Input()
	set appUserView(value: AppUserView) {
		this._appUserView.next(value);
	}

	get appUserView(): AppUserView {
		return this._appUserView.getValue();
	}

	private _appUserView = new BehaviorSubject<AppUserView>(null);

	activeAppUserView$: Observable<AppUserView>;

	activeUser: AppUserView = null;

	currentAppUserView$ = new BehaviorSubject<AppUserView>(null);
	both: Observable<AppUserView[]>;
	isEmptyUserFields: boolean = false;
	isChangingType$ = new BehaviorSubject<boolean>(false);

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private location: Location,
		private userService: AppUserService,
		private dialog: MatDialog,
		private notificationService: NotificationService,
		private destroy$: DestroyService,
		@Inject(LOCALE_ID) public locale: string,
		private cdr: ChangeDetectorRef,
		private roleService: RoleService,
		public authService: AuthService
	) {
		moment.locale(this.locale);
		this.activeAppUserView$ = userService.getActiveUserAsObservable();
	}

	ngOnInit(): void {
		this.activeAppUserView$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			this.activeUser = value;
		});

		this._appUserView
			.pipe(
				filter((x) => x !== null),
				combineLatestWith(this.userService.getActiveUserAsObservable()),
				takeUntil(this.destroy$)
			)
			.subscribe((data: AppUserView[]) => {
				if (data[0].id === this.activeUser?.id) {
					this.currentAppUserView$.next(this.activeUser);
				} else {
					this.currentAppUserView$.next(data[0]);
				}
				this.isEmptyUserFields = this.isEmptyUserAboutFields();
			});

		this.both = this.currentAppUserView$.pipe(
			takeUntil(this.destroy$),
			combineLatestWith(this.activeAppUserView$),
			filter((x) => x[0] !== null && x[1] !== null)
		);

		this.roleService.roleChangeEvent$
			.pipe(
				takeUntil(this.destroy$),
				switchMap((appUserType: AppUserType) => this.userService.changeType(appUserType)),
				finalize(() => this.isChangingType$.next(false)),
				catchError((err) => {
					this.notificationService.showDanger(errorTitle(err));
					this.isChangingType$.next(false);
					return of(err);
				})
			)
			.subscribe(() => {
				this.onChanged("User Type Change");
			});
	}

	openSpecializationDialog(): void {
		this.dialog
			.open(SpecializationModalComponent, {
				autoFocus: true,
				disableClose: false,
				width: "581px",
				panelClass: "pd-dialog",
				data: {
					title: "Специалиация",
					appUserView: { ...this.currentAppUserView$.value },
				},
			})
			.afterClosed()
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.reloadActiveUser();
			});
	}

	canUpdate(current: AppUserView | null, active: AppUserView | null): boolean {
		if (!current || !active) return false;

		return current.id === active.id;
	}

	isCurrentUserSpecialist(currentUser: AppUserView | null): boolean {
		if (!currentUser) return false;

		return currentUser.type == AppUserType.specialist;
	}

	canSeeContacts(current: AppUserView, active: AppUserView): boolean {
		return current.id === active.id || active.type === AppUserType.administrator;
	}

	reloadAppUser(): void {
		this.userService.clearAppUserView(this.currentAppUserView$.value.id);
		this.userService
			.getAppUserView(this.currentAppUserView$.value.id)
			.pipe(takeUntil(this.destroy$))
			.subscribe((appUserView) => {
				this.currentAppUserView$.next(appUserView);
			});
	}

	reloadActiveUser(): void {
		this.userService
			.getActiveUserView()
			.pipe(takeUntil(this.destroy$))
			.subscribe((appUserView) => {
				this.cdr.markForCheck();
				this.currentAppUserView$.next(appUserView);
			});
	}

	transformArray(array: WorkTypeView[] | WorkFormatView[]): WorkTypeView[] | WorkFormatView[] {
		return array.map((item, index) => {
			if (index === 0) {
				return item;
			}
			return { ...item, name: item.name.toLowerCase() };
		});
	}

	onChanged(event): void {
		if (this.activeUser?.id === this.currentAppUserView$.value.id) {
			this.reloadActiveUser();
		} else {
			this.reloadAppUser();
		}

		this.cdr.markForCheck();
	}

	goChangePass(): void {
		pushFakeHistoryState();

		this.dialog
			.open(AppUserResetAnyPasswordDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: "581px",
				panelClass: "pd-dialog",
				data: {
					title: "Изменить пароль",
					appUserView: this.activeUser,
				},
			})
			.afterClosed()
			.pipe(
				takeUntil(this.destroy$),
				filter((result) => result),
				catchError((err) => {
					this.notificationService.showDanger(errorTitle(err));
					return of(err);
				})
			)
			.subscribe();
	}

	goSupport(): void {
		pushFakeHistoryState();

		this.dialog
			.open(AppUserSupportDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: "581px",
				panelClass: "pd-dialog",
				data: {
					title: "Поддержка",
					appUserView: this.activeUser,
				},
			})
			.afterClosed()
			.pipe(
				takeUntil(this.destroy$),
				filter((result) => result)
			)
			.subscribe({
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				},
			});
	}

	/**
	 * Открытие модалки в котором клик на кнопку стать "специалистом/заказчиком"
	 */

	confirmationOfRoleChange() {
		const dialogRef = this.dialog.open(ChangeRoleConfirmDialogComponent, {
			data: {
				activeUser: this.activeUser,
			},
			width: "581px",
			panelClass: ["pd-dialog", "no-padding"],
		});

		dialogRef.afterClosed().subscribe(() => {
			this.onChanged("User Type Change");
		})
	}

	/**
	 * Возвращает название кнопки смены типа пользователя
	 * @param type
	 */
	changeTypeBtnTitle(type: AppUserType): string {
		return type === AppUserType.specialist ? "заказчиком" : "специалистом";
	}

	isEmptyUserAboutFields(): boolean {
		const user = this.currentAppUserView$.value;
		return !(
			user?.educations?.length ||
			user?.educationAdditionals?.length ||
			user?.hardSkills?.length ||
			user?.softSkills?.length ||
			user?.experiences?.length
		);
	}

	checkAuthBeforeAddToFavorites(event: Event, appUserView: AppUserView) {
		event.stopPropagation();
		if (this.authService.isLoggedIn()) {
			this.addToFavorites(event, appUserView);
		} else {
			this.dialog
				.open(AuthDialogComponent, {
					autoFocus: true,
					disableClose: false,
					width: "581px",
					panelClass: ["auth-dialog"],
				})
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe(() => {
					this.addToFavorites(event, appUserView);
					localStorage.setItem("currentHref", window.location.href);
					window.location.reload();
				});
		}
	}

	addToFavorites(event: Event, appUserView: AppUserView): void {
		event.stopPropagation();
		this.userService
			.addToFavorites(appUserView.id)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					this.currentAppUserView$.next({ ...this.currentAppUserView$.value, favorite: YesNo.yes });
				},
			});
	}

	checkAuthBeforeRemoveFromFavorites(event: Event, appUserView: AppUserView) {
		event.stopPropagation();
		if (this.authService.isLoggedIn()) {
			this.removeFromFavorites(event, appUserView);
		} else {
			this.dialog
				.open(AuthDialogComponent, {
					autoFocus: true,
					disableClose: false,
					width: "581px",
					panelClass: ["auth-dialog"],
				})
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe(() => {
					localStorage.setItem("currentHref", window.location.href);
					this.removeFromFavorites(event, appUserView);
				});
		}
	}

	removeFromFavorites(event: Event, appUserView: AppUserView): void {
		event.stopPropagation();
		this.userService
			.removeFromFavorites(appUserView.id)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					this.userService.shouldReload.next(true);
					this.currentAppUserView$.next({ ...this.currentAppUserView$.value, favorite: YesNo.no });
				},
			});
	}
}
