import { SelectionModel } from "@angular/cdk/collections";
import { NgIfContext } from "@angular/common";
import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	EventEmitter,
	inject,
	Output,
	ViewChild,
	ElementRef,
	TemplateRef,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { MatDialog } from "@angular/material/dialog";
import { CompanyService } from "@core/services/company.service";
import { IndustryService } from "@core/services/industry.service";
import { UtilsService } from "@core/services/utils.service";
import { CompanySpecTag, CompanyView } from "@models/company-view";
import { IndustryView } from "@models/industry-view";
import { DestroyService } from "@profdepo-ui/core";
import { BehaviorSubject, debounceTime, map, Observable, switchMap, takeUntil, tap } from "rxjs";

export interface CompaniesFilterForm {
	search: FormControl<string>;
	rating: FormControl<string>;
	industries: FormControl<number[]>;
}

@Component({
	selector: "pdw-company-filter",
	templateUrl: "./company-filter.component.html",
	styleUrls: ["./company-filter.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyFilterComponent implements OnInit {
	@Output() companiesList = new EventEmitter();

	@ViewChild("search") search!: TemplateRef<HTMLElement>;
	@ViewChild("searchFilter") searchFilter!: TemplateRef<HTMLElement>;

	private readonly companyService = inject(CompanyService);
	private readonly industryService = inject(IndustryService);
	private readonly destroy$ = inject(DestroyService);
	private readonly matDialog = inject(MatDialog);

	public utilsService = inject(UtilsService);
	public industries$: Observable<IndustryView[]> = this.industryService.all();
	public selectedIndustriesList = new SelectionModel<IndustryView>(true);
	public companiesCount$ = new BehaviorSubject<number>(0);
	public isDialogOpen$ = new BehaviorSubject<boolean>(false);
	public isMobile$ = this.utilsService.mobileQuery980px;

	public form: FormGroup = new FormGroup<CompaniesFilterForm>({
		search: new FormControl<string>(null),
		rating: new FormControl<string>(null),
		industries: new FormControl(null),
	});

	constructor() {}

	ngOnInit(): void {
		this.loadCount();
		this.companyService
			.all(this.companyFilters(CompanySpecTag.all),
				"name,asc",
				0,
				30
			)
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe((result) => {
				this.companyService.companies.next(result);
			});

		this.form.valueChanges
			.pipe(
				debounceTime(200),
				map((values) => this.companyFilters(CompanySpecTag.all)),
				switchMap((filters) => {
					return this.companyService.all(filters, "name,asc", 0, 30);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe((result: CompanyView[]) => {
				this.companyService.companies.next(result);
				this.companiesCount$.next(result.length);
			});

		this.industriesControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			if (value && typeof value === "string") {
				this.industries$ = this.industryService.all().pipe(
					map((industry) => {
						return industry.filter((item) => item.title.toLowerCase().includes(value.toLowerCase()));
					})
				);
			}
		});
	}

	loadCount(): void {
		this.companyService
			.allCount(this.companyFilters(CompanySpecTag.all))
			.pipe(
				tap((count) => (this.companiesCount$.next(count))),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	get searchControl() {
		return this.form.get("search") as FormControl;
	}

	get industriesControl() {
		return this.form.get("industries") as FormControl;
	}

	selectedItem(item: MatAutocompleteSelectedEvent) {
		this.industriesControl.setValue(null);
		this.selectedIndustriesList.select(item.option.value);
	}

	getDisabled(item: IndustryView): boolean {
		return this.selectedIndustriesList.isSelected(item);
	}

	companyFilters(specTag: CompanySpecTag) {
		return {
			any: this.searchControl.value,
			specTag: specTag,
			ratingMoreThen: this.form.value.rating ? [4.5] : null,
			industriesIds: this.selectedIndustriesList.selected.map((industry) => industry.id),
		};
	}

	onRemove(item: IndustryView): void {
		this.selectedIndustriesList.deselect(item);
		this.companyService
			.all(this.companyFilters(CompanySpecTag.all), "name,asc", 0, 30)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result: CompanyView[]) => {
				this.companyService.companies.next(result);
				this.companiesCount$.next(result.length);
			});
	}
	onToggleChange($event: MatButtonToggleChange) {
		if ($event.value === "all") {
			this.companyService
				.all(this.companyFilters(CompanySpecTag.all), "name,asc", 0, 30)
				.pipe(takeUntil(this.destroy$))
				.subscribe((result: CompanyView[]) => {
					this.companyService.companies.next(result);
					this.companiesCount$.next(result.length);
				});
		} else if ($event.value === "favorites") {
			this.companyService
				.all(this.companyFilters(CompanySpecTag.favorites), "name,asc", 0, 30)
				.pipe(takeUntil(this.destroy$))
				.subscribe((result: CompanyView[]) => {
					this.companyService.companies.next(result);
					this.companiesCount$.next(result.length);
				});
		}
	}

	openFilterDialog() {
		const dialogRef = this.matDialog.open(this.searchFilter, {
			height: "100%",
			width: "100%",
			panelClass: ["pd-complex-filter-dialog"],
		});

		dialogRef
			.afterOpened()
			.pipe(
				tap(() => this.isDialogOpen$.next(true)),
				takeUntil(this.destroy$)
			)
			.subscribe();

		dialogRef
			.afterClosed()
			.pipe(
				tap(() => this.isDialogOpen$.next(false)),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	onReset() {
		this.form.reset({
			search: null,
			rating: "",
			industries: null,
		});
		this.selectedIndustriesList.clear();
		this.companyService
			.all(
				{
					any: null,
					specTag: CompanySpecTag.all,
					ratingMoreThen: null,
					industriesIds: null,
				},
				"name",
				0,
				30
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe((result: CompanyView[]) => {
				this.companyService.companies.next(result);
				this.companiesCount$.next(result.length);
			});
	}
}
