import { NoSortAndFilterDataSource } from '@core/dataSources/no-sort-and-filter-data-source';
import { WorkMarkView } from '@models/work-mark-view';
import { BehaviorSubject, Observable } from 'rxjs';
import { CompanyView } from '@models/company-view';
import { WorkMarkService } from '@core/services/work-mark.service';
import { CollectionViewer } from '@angular/cdk/collections';
import { tap } from 'rxjs/operators';

export class CompanyMarksDataSource implements NoSortAndFilterDataSource<WorkMarkView> {
	private workMarkViewsSubject = new BehaviorSubject<WorkMarkView[]>([]);
	private countSubject = new BehaviorSubject<number>(0);

	constructor(
		private companyView: CompanyView,
		private workMarkService: WorkMarkService
	) {
	}

	data = this.workMarkViewsSubject.asObservable();
	count = this.countSubject.asObservable();

	connect(collectionViewer: CollectionViewer): Observable<WorkMarkView[]> {
		return this.workMarkViewsSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer) {
		this.workMarkViewsSubject.complete();
		this.countSubject.complete();
	}

	load(pageIndex: number, pageSize: number): Observable<WorkMarkView[]> {
		return this.workMarkService.ofCompany(this.companyView.id, pageIndex, pageSize)
			.pipe(
				tap(workMarkViews => this.workMarkViewsSubject.next(workMarkViews))
			);
	}

	loadMore(pageIndex: number, pageSize: number): Observable<WorkMarkView[]> {
		return this.workMarkService.ofCompany(this.companyView.id, pageIndex, pageSize)
			.pipe(
				tap(workMarkViews => {
					const tmp = this.workMarkViewsSubject.value;
					const newData = workMarkViews.filter(x => !tmp.some(w => w.id === x.id));

					this.workMarkViewsSubject.next(tmp.concat(newData));
				})
			);
	}

	loadCount(): Observable<number> {
		return this.workMarkService.ofCompanyCount(this.companyView.id)
			.pipe(
				tap(count => this.countSubject.next(count))
			);
	}
}
