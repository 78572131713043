import { EventEmitter, inject, Injectable } from "@angular/core";
import { AbstractModelService } from "@core/services/abstract-model.service";
import { BehaviorSubject, Observable, of, switchMap } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppUserTag, AppUserType, AppUserView, AppUserWorkState } from "@models/app-user-view";
import { catchError, map, shareReplay, tap } from "rxjs/operators";
import { PhoneRegistrationRequest, RegistrationRequest } from "@requests/registration-request";
import { ConfirmEmailRequest } from "@requests/confirm-email-request";
import { ResetAnyPasswordRequest } from "@requests/reset-any-password-request";
import { ExperienceService } from "@core/services/experience.service";
import { ImageRequest } from "@requests/image-request";
import { ResetPasswordRequest } from "@requests/reset-password-request";
import { ForgotPasswordRequest } from "@requests/forgot-password-request";
import { removeTimeZone } from "@utils/helpers/date-helpers";
import { YesNo } from "@models/enums";
import { stringToHslColor } from "@utils/helpers/string-helpers";
import { HardSkillService } from "@core/services/hard.skill.service";
import { SoftSkillService } from "@core/services/soft-skill.service";
import { AdminStatisticsResponse } from "../../responses/admin-statistics-response";
import { GetAppUserInnFromFioResponse } from "../../responses/app-user-inn-responses";
import { AppUserInnStateView } from "@models/app-user-inn-state-view";
import { AppUserRequisitesRequest } from "@requests/app-user-requisites-request";
import { FiltersService } from "@core/services/filters.service";
import { AppUserRequisitesResponse } from "@requests/app-user-requisites-response";
import { VerificationData } from "../../general/models/verification-data";
import { SalaryData } from "@general/features/aggregator/types/salary-types";
import { SalaryAIData } from "@core/services/aggregator.service";
import { TwoFactorRegistrationRequest } from "@public/pages/salary-register-page/salary-register-page.types";
import { Tokens } from "@requests/tokens";
import {
	CareerPath,
	CitiesData,
	CourseEn,
	TopFiveCities,
	UploadPdfSalaryResponce,
} from "@general/features/career-consultant/types/career-consultant-interface";

export class AppUsersFilter {
	any: string = null;
	subCategoriesIds: number[] | null = null;
	tag: AppUserTag = AppUserTag.all;
	ratingMoreThen: number[] = null;
	salarySet: number[] = null;
	workFormatIds: number[] = null;
	workTypeFilters: number[] = null;
	cityId: number = 0;
	isFreelance: YesNo = YesNo.none;
	categoryIds: number[] = null;
	rewardFrom: number | null = null;
	rewardTo: number | null = null;
	hardSkillsIds: number[] = null;
	isReadyToRelocate: YesNo = -1;
	profession: string = "";
}

@Injectable({
	providedIn: "root",
})
export class AppUserService {
	private appUsersUrl: string = "api/AppUsers";
	private appUserViewCache: { id: string; observable: Observable<AppUserView> }[] = [];
	private activeUser$ = new BehaviorSubject<AppUserView>(null);
	public timeStampForUrl = new BehaviorSubject<number>(null);
	private clearCacheEmitter = new EventEmitter<any>();
	private filtersService = inject(FiltersService);
	/**
	 * Флаг, после создания работы нужно перезагрузить карточки
	 */
	shouldReload = new BehaviorSubject<boolean>(false);

	constructor(private http: HttpClient, private modelService: AbstractModelService) {
		this.getActiveUserView();
	}

	/**
	 * Возвращает активного пользователя. Если необходимо, подгружает данные с сервера.
	 * НЕ ИСПОЛЬЗОВАТЬ В forkJoin!
	 */
	getActiveUserView(): Observable<AppUserView> {
		const url = `${this.appUsersUrl}/GetActiveUserView`;

		if (!localStorage.getItem("user")) {
			return this.http.get<AppUserView>(url, this.modelService.httpOptions).pipe(
				switchMap((user) => this.getAppUserView(user.id)),

				tap((appUser) => {
					this.activeUser$.next(appUser);
					localStorage.setItem("user", JSON.stringify(appUser));
				})
			);
		} else {
			this.activeUser$.next(this.getUserFromLocalStorageIfExist());
			const id = this.activeUser$.value.id;
			return this.http.get<AppUserView>(`${this.appUsersUrl}/${id}`, this.modelService.httpOptions).pipe(
				tap((appUser) => {
					this.activeUser$.next(appUser);
					localStorage.setItem("user", JSON.stringify(appUser));
				})
			);
		}
	}

	getUserFromLocalStorageIfExist(): AppUserView | null {
		if (localStorage.getItem("user")) {
			return JSON.parse(localStorage.getItem("user")) as AppUserView;
		}
		return null;
	}

	getCurrentActiveUserView(): AppUserView {
		return this.activeUser$.value;
	}

	getActiveUserAsObservable(): Observable<AppUserView> {
		return this.activeUser$.asObservable();
	}

	setActiveUser(user: AppUserView): void {
		localStorage.setItem("user", JSON.stringify(user));
		this.activeUser$.next(user);
	}

	clearActiveUserView(): void {
		this.activeUser$.next(null);
		localStorage.clear();
	}

	/**
	 * Преобразование string в Date после десериализации
	 * @param appUserView физ. лицо
	 */
	public static parseDates(appUserView: AppUserView): AppUserView {
		if (appUserView.birthday) {
			appUserView.birthday = new Date(appUserView.birthday);
		}
		appUserView.createTime = new Date(appUserView.createTime);
		appUserView.modifyTime = new Date(appUserView.modifyTime);
		if (appUserView.hardSkills) {
			appUserView.hardSkills = appUserView.hardSkills.map((x) => HardSkillService.parseDates(x));
		}
		if (appUserView.softSkills) {
			appUserView.softSkills = appUserView.softSkills.map((x) => SoftSkillService.parseDates(x));
		}
		if (appUserView.experiences) {
			appUserView.experiences = appUserView.experiences.map((x) => ExperienceService.parseDates(x));
		}
		if (appUserView.lastConnection) {
			appUserView.lastConnection = new Date(appUserView.lastConnection);
		}
		return appUserView;
	}

	/**
	 * Загружает, кэширует и возвращает физжает, кэширует и возвращает физ. лицо.
	 * @param id идентификатор физ. лица для загрузки данных с сервера или получения его из массива кэша
	 */
	getAppUserView(id: string): Observable<AppUserView> {
		if (id && typeof id === "string") {
			if (!this.appUserViewCache || !this.appUserViewCache.find((x) => x.id === id)) {
				const url = `${this.appUsersUrl}/${id}`;
				this.appUserViewCache.push({
					id,
					observable: this.http.get<AppUserView>(url, this.modelService.httpOptions).pipe(
						map((x) => AppUserService.parseDates(x)),
						shareReplay(1)
					),
				});
			}
			return this.appUserViewCache.find((x) => x.id === id).observable;
		} else {
			console.warn("Invalid type of id argument in getAppUserView", id);
			return of(null);
		}
	}

	salaryAI(data: SalaryData): Observable<SalaryAIData> {
		return this.http.post<SalaryAIData>(this.appUsersUrl + "/SalaryAI", data);
	}

	twoFactorRegistration(data: TwoFactorRegistrationRequest): Observable<Tokens> {
		return this.http.post<Tokens>(this.appUsersUrl + "/TwoFactorRegistration", data);
	}

	/**
	 * Очищает кэш с указанным физ. лицом
	 * @param id идентификатор физ. лица для очистки записи в кэше
	 */ clearAppUserView(id: string): void {
		if (id && typeof id === "string") {
			if (this.appUserViewCache) {
				const index = this.appUserViewCache.findIndex((x) => x.id === id);
				if (index >= 0) {
					this.appUserViewCache.splice(index, 1);
				}
			}
		} else {
			console.warn("Invalid type of id argument in clearAppUserView", id);
		}
	}

	/**
	 * Очищает кэш
	 */
	clearAppUserViews(): void {
		this.clearCacheEmitter.emit("clearCache");
		this.appUserViewCache = [];
	}

	/**
	 * Добавление нового пользователя
	 * @param appUserView новый пользователь
	 */
	addAppUserView(appUserView: AppUserView): Observable<AppUserView> {
		return this.http
			.post<AppUserView>(this.appUsersUrl, appUserView, this.modelService.httpOptions)
			.pipe(map((x) => AppUserService.parseDates(x)));
	}

	/**
	 * Изменение существующего пользователя
	 * @param appUserView пользователь
	 */
	updateAppUserView(appUserView: AppUserView): Observable<AppUserView> {
		// remove timezone from birthday
		appUserView.birthday = removeTimeZone(appUserView.birthday);
		// remove timezone from experience
		appUserView.experiences?.map((x) => {
			x.startDate = removeTimeZone(x.startDate);
			x.endDate = removeTimeZone(x.endDate);
		});

		// make url and execute put request
		const url = `${this.appUsersUrl}/${appUserView.id}`;
		return this.http
			.put<AppUserView>(url, appUserView, this.modelService.httpOptions)
			.pipe(tap((res) => this.setActiveUser(res)));
	}

	validateView(appUserView: AppUserView): Observable<AppUserView> {
		const url = `${this.appUsersUrl}/ValidateView`;
		return this.http.post<AppUserView>(url, appUserView, this.modelService.httpOptions);
	}

	registration(registrationRequest: RegistrationRequest): Observable<AppUserView> {
		const url = `${this.appUsersUrl}/Registration`;
		return this.http.post<AppUserView>(url, registrationRequest, this.modelService.httpOptions);
	}

	forgotPassword(forgotPasswordRequest: ForgotPasswordRequest): Observable<any> {
		const url = `${this.appUsersUrl}/ForgotPassword`;
		return this.http.post<any>(url, forgotPasswordRequest);
	}

	resetPassword(id: string, resetPasswordRequest: ResetPasswordRequest): Observable<ResetPasswordRequest> {
		const url = `${this.appUsersUrl}/ResetPassword/${id}`;
		return this.http.post<ResetPasswordRequest>(url, resetPasswordRequest, this.modelService.httpOptions);
	}

	/**
	 * Отправляет токен подтверждения email
	 * @param confirmEmailRequest
	 */
	confirmEmail(confirmEmailRequest: ConfirmEmailRequest): Observable<any> {
		const url = `${this.appUsersUrl}/ConfirmEmail`;
		return this.http.post<any>(url, confirmEmailRequest);
	}

	/**
	 * Отправляет письмо подтверждения email на эл. почтовый яшик активного пользователя
	 * @param backUrl
	 * @param reason причина подтверждения (0 - обычное подтверждение, 1 - изменение email)
	 */
	emailConfirmation(backUrl: string, reason: number): Observable<any> {
		const url = `${this.appUsersUrl}/EmailConfirmation`;
		return this.http.get<any>(url, {
			params: { backUrl: backUrl, reason: reason },
			headers: this.modelService.httpOptions.headers,
		});
	}

	private prepareFilter(appUsersFilter: AppUsersFilter): AppUsersFilter {
		Object.keys(appUsersFilter).forEach((key) => {
			if ("rewardFrom" === key && appUsersFilter[key]?.toString().includes("|")) {
				const data = appUsersFilter[key].toString().split("|");
				appUsersFilter[key] = Number(data[0]);
				appUsersFilter["rewardTo"] = Number(data[1]);
			}

			if ("isFreelance" === key) {
				appUsersFilter[key] = this.filtersService.isFreelance$.value ? YesNo.yes : YesNo.no;
				return;
			}

			if (
				![
					"tag",
					"ratingMoreThen",
					"subCategoriesIds",
					"cityIds",
					"isFreelance",
					"isReadyToRelocate",
					"workFormatIds",
					"workTypeFilters",
					"hardSkillsIds",
					"categoryIds",
					"rewardFrom",
					"rewardTo",
					"salarySet",
					"specTag",
					"planSalary",
					"userHardSkills",
					"hasReward",
				].some((x) => x === key) &&
				appUsersFilter[key]
			) {
				appUsersFilter[key] = "%" + appUsersFilter[key] + "%";
			}
		});
		return appUsersFilter;
	}

	private dataSource(
		url: string,
		filter: AppUsersFilter = null,
		sort: string = null,
		pageIndex = 0,
		pageSize = 0
	): Observable<AppUserView[]> {
		return this.http
			.post<AppUserView[]>(url, this.prepareFilter(filter), {
				params: new HttpParams()
					.set("sort", sort || "")
					.set("pageIndex", pageIndex.toString())
					.set("pageSize", pageSize.toString()),
				headers: this.modelService.httpOptions.headers,
			})
			.pipe(map((xx) => xx.map((x) => AppUserService.parseDates(x))));
	}

	private dataSourceCount(url: string, filter: AppUsersFilter = null): Observable<number> {
		return this.http.post<number>(url, this.prepareFilter(filter), this.modelService.httpOptions);
	}

	/**
	 * Возвращает список всех специалистов, соответствующих критериям фильтра
	 * @param filter
	 * @param sort
	 * @param pageIndex
	 * @param pageSize
	 */
	all(filter: AppUsersFilter, sort = null, pageIndex = 0, pageSize = 0): Observable<AppUserView[]> {
		const url = `${this.appUsersUrl}/All`;
		return this.dataSource(url, filter, sort, pageIndex, pageSize);
	}

	allCount(filter: AppUsersFilter): Observable<number> {
		const url = `${this.appUsersUrl}/AllCount`;
		return this.dataSourceCount(url, filter);
	}

	inSkill(id: number): Observable<AppUserView[]> {
		const url = `${this.appUsersUrl}/InSkill/${id}`;
		return this.http
			.get<AppUserView[]>(url, this.modelService.httpOptions)
			.pipe(map((xx) => xx.map((x) => AppUserService.parseDates(x))));
	}

	original(id: string): Observable<any> {
		const url = `${this.appUsersUrl}/Original/${id}`;
		return this.http.get(url, {
			headers: this.modelService.downloadImageHttpHeaders,
			observe: "response",
			responseType: "blob",
		});
	}

	avatar(id: string): Observable<any> {
		const url = `${this.appUsersUrl}/Avatar/${id}`;
		return this.http.get(url, {
			headers: this.modelService.downloadImageHttpHeaders,
			observe: "response",
			responseType: "blob",
		});
	}

	updateAvatar(id: string, request: ImageRequest): Observable<AppUserView> {
		const url = `${this.appUsersUrl}/UpdateAvatar/${id}`;
		return this.http.post<AppUserView>(url, request, this.modelService.httpOptions);
	}

	deleteAvatar(id: string): Observable<AppUserView> {
		const url = `${this.appUsersUrl}/DeleteAvatar/${id}`;
		return this.http.get<AppUserView>(url, this.modelService.httpOptions);
	}

	hasCompanies(): Observable<boolean> {
		const url = `${this.appUsersUrl}/HasCompanies`;
		return this.http.get<boolean>(url, this.modelService.httpOptions);
	}

	/**
	 * Может ли авторизованный пользователь видеть контакты
	 * @param id пользователя, чей профиль открыт
	 */
	canSeeContacts(id: string): Observable<boolean> {
		const url = `${this.appUsersUrl}/CanSeeContacts/${id}`;
		return this.http.get<boolean>(url, this.modelService.httpOptions);
	}

	/**
	 * Возвращает список всех подходящих специалистов для указанной работы,
	 * удовлетворяющих критериям фильтра
	 * @param id Идентификатор работы
	 * @param filter
	 * @param sort
	 * @param pageIndex
	 * @param pageSize
	 */

	matchedWork(
		id: number,
		filter: AppUsersFilter,
		sort = null,
		pageIndex = 0,
		pageSize = 0
	): Observable<AppUserView[]> {
		const url = `${this.appUsersUrl}/MatchedWorkNum/${id}`;
		return this.dataSource(url, filter, sort, pageIndex, pageSize);
	}

	/**
	 * Возвращает количество всех подходящих специалистов для указанной работы,
	 * удовлетворяющих критериям фильтра
	 * @param id Идентификатор работы
	 * @param filter Фильтр
	 */
	matchedWorkCount(id: number, filter: AppUsersFilter): Observable<number> {
		const url = `${this.appUsersUrl}/MatchedWorkCount/${id}`;
		return this.dataSourceCount(url, filter);
	}

	/**
	 * Возвращает список TOP-5 специалистов подходящих для указанной работы
	 * @param id Идентификатор работы
	 */
	topMatchedWork(id: number): Observable<AppUserView[]> {
		const url = `${this.appUsersUrl}/TopMatchedWork/${id}`;
		return this.http
			.get<AppUserView[]>(url, this.modelService.httpOptions)
			.pipe(map((xx) => xx.map((x) => AppUserService.parseDates(x))));
	}

	disableRoleHintDialog(): Observable<any> {
		const url = `${this.appUsersUrl}/DisableRoleHintDialog`;
		return this.http.get<any>(url, this.modelService.httpOptions);
	}

	getAppUserInnFromFio(passNumber: number): Observable<string | null> {
		const url = `${this.appUsersUrl}/GetAppUserInnFromFio/${passNumber}`;
		return this.http.get<GetAppUserInnFromFioResponse>(url, this.modelService.httpOptions).pipe(
			map((value: GetAppUserInnFromFioResponse) =>
				value?.items?.length && value?.items[0].ИНН ? value?.items[0].ИНН : null
			),
			catchError(() => of(null))
		);
	}

	getCheckAppUserInn(): Observable<AppUserInnStateView> {
		const url = `${this.appUsersUrl}/CheckAppUserInn`;
		return this.http.get<AppUserInnStateView>(url, this.modelService.httpOptions).pipe(catchError(() => of(null)));
	}

	postAppUserRequisites(appUserRequisitesRequest: AppUserRequisitesRequest): Observable<AppUserRequisitesResponse> {
		const url = `${this.appUsersUrl}/AppUserRequisites`;
		return this.http.post<AppUserRequisitesResponse>(url, appUserRequisitesRequest, this.modelService.httpOptions);
	}

	changeType(type: AppUserType): Observable<AppUserType> {
		const url = `${this.appUsersUrl}/ChangeType/${type}`;
		return this.http.get<AppUserType>(url, this.modelService.httpOptions);
	}

	updateUserWithInn(data: VerificationData): Observable<AppUserView> {
		return this.http.post<AppUserView>(`${this.appUsersUrl}/UpdateUserWithInn`, data);
	}

	/**
	 * Добавляет специалиста в избранные
	 * @param id Идентификатор специалиста
	 */
	addToFavorites(id: string): Observable<any> {
		const url = `${this.appUsersUrl}/AddToFavorites/${id}`;
		return this.http.get<any>(url, this.modelService.httpOptions);
	}

	removeFromFavorites(id: string): Observable<any> {
		const url = `${this.appUsersUrl}/RemoveFromFavorites/${id}`;
		return this.http.get<any>(url, this.modelService.httpOptions);
	}

	adminStatistics(): Observable<AdminStatisticsResponse> {
		const url = `${this.appUsersUrl}/AdminStatistics`;
		return this.http.get<AdminStatisticsResponse>(url, this.modelService.httpOptions);
	}

	static ratingColorClass(value: number): string {
		let color: string = "rating-0";
		if (value > 0 && value < 3) {
			color = "rating-1";
		} else if (value >= 3 && value < 4) {
			color = "rating-2";
		} else if (value >= 4) {
			color = "rating-3";
		}
		return color;
	}

	static avatarInitialLetters(appUserView: AppUserView): string {
		let letters = [];
		if (appUserView.incognito === YesNo.yes) {
			letters.push(appUserView.userName[0].toUpperCase());
		} else {
			if (
				appUserView.lastname &&
				appUserView.lastname.length &&
				appUserView.firstname &&
				appUserView.firstname.length
			) {
				letters.push(appUserView.lastname[0].toUpperCase());
				letters.push(appUserView.firstname[0].toUpperCase());
			} else {
				letters.push(appUserView.userName[0].toUpperCase());
			}
		}
		return letters.join("");
	}

	static hasAppUserWorkState(value: number, flag: AppUserWorkState): boolean {
		return (value & flag) == flag;
	}

	static WorkStateColorClass(value: number): string {
		if (AppUserService.hasAppUserWorkState(value, AppUserWorkState.inWork)) {
			return "executing";
		}
		if (
			AppUserService.hasAppUserWorkState(value, AppUserWorkState.invited) ||
			AppUserService.hasAppUserWorkState(value, AppUserWorkState.requested)
		) {
			return "sent";
		}
		if (AppUserService.hasAppUserWorkState(value, AppUserWorkState.inSearch)) {
			return "inSearch";
		}

		return "sent";
	}

	/**
	 * Возвращает цвет заднего фона аватара в зависимости от наименования пользователя
	 * @param appUserView
	 */
	static avatarColor(appUserView: AppUserView): string {
		return stringToHslColor(appUserView.title, 30, 80);
	}

	setNewPassword(id: string, resetPasswordRequest: ResetAnyPasswordRequest): Observable<ResetPasswordRequest> {
		const url = `${this.appUsersUrl}/ResetPassword/${id}`;
		return this.http.post<ResetPasswordRequest>(url, resetPasswordRequest, this.modelService.httpOptions);
	}

	setNewPasswordForAuthorizedPerson(password: string) {
		return this.http.post(
			`${this.appUsersUrl}/ResetUserPassword`,
			{
				password: password,
			},
			{
				responseType: "text",
			}
		);
	}

	phoneNumberRegistration(registrationRequest: PhoneRegistrationRequest) {
		const url = `${this.appUsersUrl}/PhoneRegistration/`;
		return this.http.post(url, registrationRequest, this.modelService.httpOptions);
	}

	uploadPdfSalary(body: FormData): Observable<UploadPdfSalaryResponce> {
		const url = `${this.appUsersUrl}/uploadPdfSalary`;
		return this.http.post<UploadPdfSalaryResponce>(url, body);
	}
	uploadPdfTop5(file: FormData): Observable<TopFiveCities> {
		const url = `${this.appUsersUrl}/uploadPdfTop5`;
		return this.http.post<TopFiveCities>(url, file);
	}

	uploadPdfCourses(file: FormData): Observable<CourseEn> {
		const url = `${this.appUsersUrl}/uploadPdfCourses`;
		return this.http.post<CourseEn>(url, file);
	}
	uploadPdfCarrier(file: FormData): Observable<CareerPath> {
		const url = `${this.appUsersUrl}/uploadPdfCarrier`;
		return this.http.post<CareerPath>(url, file);
	}
}
