import { Pipe, PipeTransform } from '@angular/core';
import { number2string } from '@utils/helpers/string-helpers';

@Pipe({
	name: 'numberToWordDeclension'
})
export class NumberToWordDeclensionPipe implements PipeTransform {

	/**
	 * Сколнение существительного с числительным
	 * @param value
	 * @param wordsArray массив форм [для 0 или 5..., для прочих, для 2...4]
	 * @param returnNumber true добавляет в начало строки число(value)
	 */
	transform(value: number, wordsArray: string[], returnNumber: boolean = false): string {
		if (returnNumber) {
			return value + ' ' + number2string(value, wordsArray);
		}
		return number2string(value, wordsArray);
	}

}
