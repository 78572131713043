<div *ngIf='currentAppUserView | async as currentAppUser'>
	<div
		class='pd-profile-about-top-wrapper'
		[ngClass]="(currentAppUser.experiences && currentAppUser.experiences.length) ? '' : 'empty'"
	>
		<h3 class='pd-profile-subtitle'>
			<mat-icon
				class='pd-icon light-gray'
				svgIcon='additional-check-rectangle'
			></mat-icon>
			Опыт работы {{ experienceTotalDelta(currentAppUser.experiences) }}
		</h3>
		<ng-container *ngIf='canUpdate'>
			<button
				*ngIf='currentAppUser.experiences && currentAppUser.experiences.length; else noExperiences'
				class='pd-button-secondary small'
				mat-raised-button
				(click)='goUpdateExperiences()'
			>
				<mat-icon
					class='pd-icon'
					svgIcon='additional2-edit'
				></mat-icon>
				<p class='pd-profile-button-text'>Редактировать</p>
			</button>
			<ng-template #noExperiences>
				<button
					class='pd-button-secondary small'
					mat-raised-button
					(click)='goUpdateExperiences()'
				>
					<mat-icon
						class='pd-icon'
						svgIcon='additional-plus'
					></mat-icon>
					<p class='pd-profile-button-text'>Добавить</p>
				</button>
			</ng-template>
		</ng-container>
	</div>
	<ng-container *ngIf='currentAppUser.experiences && currentAppUser.experiences.length'>
		<mat-table
			class='pd-profile-table pd-profile-table-experience'
			[dataSource]='experienceDataSource'
		>

			<!-- Period Column -->
			<ng-container matColumnDef='period'>
				<mat-cell
					class='pd-profile-table-period pd-profile-table-pointed-cell top-point'
					*matCellDef='let element'
				>
					<div class="start-end-dates">
						<div>
							{{ formatDate(element.startDate) | titlecase }} —&nbsp;
						</div>
						<div>
							<ng-container *ngIf='element.tillNow === YesNo.yes; else endDate'>
								по настоящее время
							</ng-container>
							<ng-template #endDate>
								{{ formatDate(element.endDate) }}
							</ng-template>
						</div>
					</div>
					<div class='pd-profile-table-experience-delta'>
						{{ experienceDelta(element) }}
					</div>
				</mat-cell>
			</ng-container>

			<!-- Description Column -->
			<ng-container matColumnDef='description'>
				<mat-cell
					class='pd-profile-inner-column'
					*matCellDef='let element'
				>
					<h2 class='pd-profile-table-experience-title'>
						<ng-container *ngIf='element.company; else noCompany'>
							{{ element.company.title }}
						</ng-container>
						<ng-template #noCompany>
							{{ element.companyName }}
						</ng-template>
					</h2>
					<h3 class='pd-profile-text-medium pd-profile-table-description-name'>
						{{ element.position }}
					</h3>
					<div
						class='pd-profile-text pd-profile-experiences-description gray'
						[innerHTML]='element.description | appPlainTextFormat'
					></div>
				</mat-cell>
			</ng-container>

			<mat-row
				class='pd-profile-table-period-row'
				*matRowDef="let row; columns: ['period', 'description'];"
			></mat-row>
		</mat-table>
	</ng-container>
</div>

