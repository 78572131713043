<ng-container *ngIf='data.workView as workView'>
	<div class='pd-dialog-header'>
		<p class='pd-dialog-header-title'>{{ data.title }}</p>
		<button
			class='pd-icon-button'
			mat-icon-button
			[mat-dialog-close]='false'
		>
			<mat-icon svgIcon='interface-close'></mat-icon>
		</button>
	</div>
	<tui-scrollbar class='pd-scrollbar pd-dialog-scroll'>
		<div class='pd-dialog-content pd-dialog-content-small-gap'>
			<p class='pd-dialog-subtitle'>
				{{'title' | work:'propertyTitle'}}
			</p>
			<p class='pd-dialog-font-15m'>
				{{workView.title}}
			</p>
			<pdw-work-state
				[value]='workView.state'
				[type]='WORK_STATE.ABSTRACT_FINISHED'
				[isActiveUserAssigned]='true'
				[style]="'width: fit-content'"
			>
			</pdw-work-state>
			<p class='pd-dialog-subtitle'>
				{{'description' | work:'propertySecondHint'}}
			</p>
			<p
				class='pd-dialog-font-14r'
				[innerHTML]='workView.description | appPlainTextFormat'
			></p>
			<p class='pd-dialog-subtitle'>
				{{'cost' | work:'propertyTitle'}}
			</p>
			<pdw-work-cost
				[work]='workView'
				[plateView]='false'
			>
			</pdw-work-cost>
			<p class='pd-dialog-subtitle'>
				{{'dates' | work:'propertyTitle'}}
			</p>
			<p class='pd-dialog-font-15r'>
				{{workView.startDate | date:'dd.MM.YYYY'}}
				- {{workView.endDate | date:'dd.MM.YYYY'}}
			</p>
		</div>
	</tui-scrollbar>
</ng-container>


