import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ManagerWorkRequestDataSource } from '@core/dataSources/manager-work-request-data-source';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { WorkRequestService } from '@core/services/work-request.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { WorkRequestState, WorkRequestView } from '@models/work-request-view';
import { AppUserView } from '@models/app-user-view';
import { errorTitle } from '@utils/helpers/error-helpers';
import { pushFakeHistoryState } from '@utils/functions';
import {
	ConfirmationDialogComponent,
	ConfirmationDialogType
} from '@shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SingleWorkRequestDataSource } from '@core/dataSources/single-work-request-data-source';
import { UtilsService } from '@core/services/utils.service';
import { AppUserService } from '@core/services/app-user.service';
import { WorkService } from '@core/services/work.service';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { YesNo } from '@models/enums';
import {ImageAvatarSize} from "@shared/components/ui/image-avatar/image-avatar.types";

@Component({
	selector: 'pdw-work-requests-manager-card',
	templateUrl: './work-requests-manager-card.component.html',
	host: { 'class': 'pd-request-card' },
	providers: [DestroyService],
	styleUrls: ['./work-requests-manager-card.component.scss']
})
export class WorkRequestsManagerCardComponent implements OnInit {
	@Input() dataSource: ManagerWorkRequestDataSource | SingleWorkRequestDataSource;
	@Output() changed = new EventEmitter<any>();

	@Input()
	set WorkRequestView(value: WorkRequestView) {
		this._WorkRequestView.next(value);
	};

	get WorkRequestView(): WorkRequestView {
		return this._WorkRequestView.getValue();
	}

	private _WorkRequestView = new BehaviorSubject<WorkRequestView>(null);
	currentWorkRequestView = new BehaviorSubject<WorkRequestView>(null);

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private workService: WorkService,
		private workRequestService: WorkRequestService,
		private notificationService: NotificationService,
		private utilsService: UtilsService,
		private destroy$: DestroyService
	) {
	}

	ngOnInit(): void {
		this._WorkRequestView
			.pipe(
				filter(x => x !== null),
				takeUntil(this.destroy$),
			)
			.subscribe((WorkRequestView: WorkRequestView) => {
				this.currentWorkRequestView.next(WorkRequestView);
			});

	}

	acceptFreelance(event: MouseEvent, request: WorkRequestView): void {
		event.stopPropagation();
		this.workRequestService.accept(request.id)
			.pipe(
				tap(() => this.workService.needReloadId = request.work.id),
				takeUntil(this.destroy$),
			)
			.subscribe({
					next: () => {
						this.changed.emit('change');
						this.utilsService.changeWorkRequestCount.emit('Accept request');
					},
					error: (err) => {
						this.notificationService.showDanger(errorTitle(err));
					}
				}
			);
	}

	/**
	 * Обрабатывает нажатие средней кнопкой мыши на карточке отклика
	 * @param workRequestView
	 * @param event
	 */
	onMouseUp(workRequestView: WorkRequestView, event: MouseEvent): void {
		if (event.button === 1) {
			this.router.navigate([])
				.then(() => {
					window.open('/users/' + workRequestView.executor.id, '_blank');
				});
		}
	}

	onMouseDown(event: MouseEvent): void {
		if (event.button === 1) {
			event.preventDefault();
		}
	}

	/**
	 * Обрабаывает нажатие левой кнопкой мыши на меню карточки (блокирует переход)
	 * @param event
	 * @param menuTriggerButton
	 */
	onMoreClick(event: MouseEvent, menuTriggerButton: MatButton): void {
		menuTriggerButton._elementRef.nativeElement.closest('.pd-request-card-inner').focus();
		event.stopPropagation();
	}

	isUserOnline(appUserView: AppUserView): boolean | null {
		if (appUserView.lastConnection) {
			const delta = (new Date().getTime() - appUserView.lastConnection.getTime()) / 1000 / 60;
			return delta < 5;
		}
		return null;
	}

	goAccept(event: MouseEvent, workRequestView: WorkRequestView): void {
		event.stopPropagation();
		this.router.navigate([`/works/${workRequestView.work.id}/details`]);
	}

	goDecline(event: MouseEvent, workExecutorRequestView: WorkRequestView): void {
		event.stopPropagation();

		this.dialog.open(ConfirmationDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Внимание',
					message: 'Вы действительно хотите отклонить отклик ' + workExecutorRequestView.executor.title + ' на',
					value: workExecutorRequestView.work.title,
					questionMark: true,
					type: ConfirmationDialogType.question,
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
				switchMap(() => this.workRequestService.decline(workExecutorRequestView.id)
					.pipe(
						takeUntil(this.destroy$)
					)
				)
			)
			.subscribe({
					next: () => {
						this.changed.emit('change');
						this.utilsService.changeWorkRequestCount.emit('Decline request');
					},
					error: (err) => {
						this.notificationService.showDanger(errorTitle(err));
					}
				}
			);
	}

	goDelete(event: MouseEvent, workExecutorRequestView: WorkRequestView): void {
		event.stopPropagation();
		pushFakeHistoryState();
		this.dialog.open(ConfirmationDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Внимание',
					message: 'Вы действительно хотите отменить приглашение для ' + workExecutorRequestView.executor.title + ' на',
					value: workExecutorRequestView.work.title,
					questionMark: true,
					type: ConfirmationDialogType.question,
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
				switchMap(() => this.workRequestService.delete(workExecutorRequestView.id)
					.pipe(
						takeUntil(this.destroy$)
					)
				)
			)
			.subscribe({
				next: () => {
					this.dataSource.remove(workExecutorRequestView);
					this.utilsService.changeWorkRequestCount.emit('Delete request');
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	stateColorClass(workRequestView: WorkRequestView): string {
		return WorkRequestService.stateColorClass(workRequestView.state, workRequestView.direction);
	}

	avatarColor(appUserView: AppUserView): string {
		return AppUserService.avatarColor(appUserView);
	}

	protected readonly YesNo = YesNo;
	protected readonly WorkRequestState = WorkRequestState;
    protected readonly IMAGE_SIZE = ImageAvatarSize;
}
