import { Observable } from 'rxjs';
import { CompanyView } from '@models/company-view';
import { CityModel } from '@models/city-model';
import { SubCategoryView } from '@models/sub-category-view';
import { HardSkillView } from '@models/hard-skill-view';
import { WorkFormatI, WorkTypeI, WorkView } from '@models/work-view';
import { AppUserView } from '@models/app-user-view';
import { CreateFreelanceJobMethods } from '@models/enums';

export interface CreateControlInfo {
	name: string,
	dataAsObservable?: Observable<any>,
	emitData?: (params: any) => Observable<any>
}

export interface BaseJob {
	name: string,
	company: CompanyView,
	city: CityModel,
	subCategories: Array<SubCategoryView>,
	hardSkills: Array<HardSkillView>,
	description: string,
	amount: number,
	isFreelance: boolean,
}

export interface FreelanceData extends BaseJob {
	startDate: Date,
	endDate: Date
}

export interface FreelanceEditData {
	name: string,
	description: string,
	amount: number,
	startDate: Date,
	endDate: Date
}

export interface WorkData extends BaseJob {
	workType: WorkTypeI,
	workFormat: WorkFormatI
}

export class Work implements WorkData {
	amount: number = null;
	city: CityModel = null;
	company: CompanyView = null;
	description: string = null;
	hardSkills: Array<HardSkillView> = null;
	isFreelance: boolean = null;
	name: string = null;
	subCategories: Array<SubCategoryView> = null;
	workFormat: WorkFormatI = null;
	workType: WorkTypeI = null;
}

export class Freelance implements FreelanceData {
	amount: number = null;
	city: CityModel = null;
	company: CompanyView = null;
	description: string = null;
	endDate: Date = null;
	hardSkills: Array<HardSkillView> = null;
	isFreelance: boolean = null;
	name: string = null;
	startDate: Date = null;
	subCategories: Array<SubCategoryView> = null;
}

export class FreelanceEdit implements FreelanceEditData {
	name: string = null;
	description: string = null;
	amount: number = null;
	startDate: Date = null;
	endDate: Date = null;
}

export interface WorkDetailsDialogData {
	title: string;
	message: string;
	method: CreateFreelanceJobMethods;
	workView: WorkView;
	withCompany: boolean;
	appUserView: AppUserView;
	isFreelance?: boolean;
}
