import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { AppUserView } from '@models/app-user-view';
import { BehaviorSubject, combineLatestWith, Observable, switchMap } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { MatDialog } from '@angular/material/dialog';
import { AppUserService } from '@core/services/app-user.service';
import { ConfirmationDialogType, DestroyService, NotificationService } from '@profdepo-ui/core';
import { OnCloseValue } from '@shared/app-user-inn-step-three-confirmation/app-user-inn-step-three-confirmation.component';
import { AppUserInnStateView, InnStateValue } from '@models/app-user-inn-state-view';
import { AppUserInnDialogService } from '@core/services/app-user-inn-dialog.service';

@Component({
	selector: 'pdw-user-inn-block',
	templateUrl: './app-user-inn-block.component.html',
	host: { class: 'pd-personal' },
	providers: [DestroyService],
})
export class AppUserInnBlockComponent implements OnInit {
	currentDate = new Date();
	appUserInnState: AppUserInnStateView = null;

	@Input() canUpdate: boolean;

	@Input()
	set activeUserView(value: AppUserView) {
		this._activeUserView.next(value);
	}

	get activeUserView(): AppUserView {
		return this._activeUserView.getValue();
	}

	private _activeUserView = new BehaviorSubject<AppUserView>(null);

	@Input()
	set currentUserView(value: AppUserView) {
		this._currentUserView$.next(value);
	}

	get currentUserView(): AppUserView {
		return this._currentUserView$.getValue();
	}

	private _currentUserView$ = new BehaviorSubject<AppUserView>(null);
	currentAppUserView$ = new BehaviorSubject<AppUserView>(null);
	activeAppUserView$ = new BehaviorSubject<AppUserView>(null);
	canSeeContacts$ = new BehaviorSubject<boolean>(false);
	both$: Observable<AppUserView[]>;
	@Output() changed = new EventEmitter<any>();

	constructor(
		private dialog: MatDialog,
		private notificationService: NotificationService,
		private appUserService: AppUserService,
		@Inject(LOCALE_ID) public locale: string,
		private destroy$: DestroyService,
		private appUserInnDialogService: AppUserInnDialogService,
	) {
		this.both$ = this.currentAppUserView$.pipe(combineLatestWith(this.activeAppUserView$));
	}

	ngOnInit(): void {
		this._currentUserView$
			.pipe(
				combineLatestWith(this._activeUserView),
				filter((x) => x[0] !== null || x[1] !== null)
			)
			.subscribe((data: AppUserView[]) => {
				this.currentAppUserView$.next(data[0]);
				this.activeAppUserView$.next(data[1]);
				if (!data[0] || !data[1]) return;

				if (this.currentAppUserView$.value.id === this.activeAppUserView$.value.id) {
					this.canSeeContacts$.next(true);
				}
			});

		this.both$
			.pipe(
				filter((x) => x[0] !== null && x[1] !== null),
				filter((x) => x[0].id !== x[1].id),
				switchMap((data) => this.appUserService.canSeeContacts(data[0].id).pipe(takeUntil(this.destroy$)))
			)
			.subscribe({
				next: (v) => this.canSeeContacts$.next(v),
				error: (err) => this.notificationService.showDanger(errorTitle(err)),
			});

		//TODO: вернуть вызов метода после отладки
		// this.appUserService
		// 	.getCheckAppUserInn()
		// 	.pipe(takeUntil(this.destroy$))
		// 	.subscribe((res) => {
		// 		this.appUserInnState = res;
		// 	});
	}

	appUserInn(appUser: AppUserView): string {
		return appUser.inn ? appUser.inn : 'отсутствует';
	}

	goInnVerification(): void {
		this.appUserInnDialogService.showInnVerificationStepOneDialog(this.currentAppUserView$, this.destroy$);
	}

	statusColorClass(appUserInnState: AppUserInnStateView): string {
		return appUserInnState && appUserInnState.value === InnStateValue.selfImpolyeed ? 'confirmed' : 'unconfirmed';
	}

	statusText(appUserInnState: AppUserInnStateView): string {
		return appUserInnState && appUserInnState.value === InnStateValue.selfImpolyeed ? 'Подтвержден' : 'Отсутствует';
	}

	statusDateText(appUserInnState: AppUserInnStateView): string {
		return appUserInnState && appUserInnState.value === InnStateValue.selfImpolyeed
			? 'Статус подтвержден на'
			: 'Статус не подтвержден на';
	}

	getStatusDate(appUserInnState: AppUserInnStateView): Date {
		return appUserInnState && appUserInnState.value === InnStateValue.selfImpolyeed
			? new Date(appUserInnState.createTime)
			: this.currentDate;
	}

	goCheckStatus(): void {
		this.appUserService
			.getCheckAppUserInn()
			.pipe(takeUntil(this.destroy$))
			.subscribe((res) => {
				this.appUserInnState = res;
				this.appUserInnDialogService
					.showInnVerificationStepThreeDialog(
						this.statusDateText(res),
						this.getStatusDate(res),
						ConfirmationDialogType.question,
						!!res
					)
					.afterClosed()
					.pipe(takeUntil(this.destroy$))
					.subscribe({
						next: (res) => {
							if (res === OnCloseValue.goNext) {
								this.appUserInnDialogService.showRequisitesStepFourDialog(
									this.currentAppUserView$.value
								);
							} else if (res === OnCloseValue.update) {
								this.goCheckStatus();
							}
						},
						error: (err) => {
							this.notificationService.showDanger(errorTitle(err));
						},
					});
			});
	}
}
