<div class='pd-dialog-header'>
	<p class='pd-dialog-header-title'>{{ data.title }}</p>
	<button
		class='pd-icon-button'
		mat-icon-button
		[mat-dialog-close]='false'
	>
		<mat-icon svgIcon='interface-close'></mat-icon>
	</button>
</div>
<tui-scrollbar class='pd-scrollbar pd-dialog-scroll'>
	<form [formGroup]='form' class='pd-invite-dialog-wrapper'>
		<div class='pd-invite-dialog-search'>
			<mat-form-field
				class='pd-input-form-field'
				appearance='fill'
				hideRequiredMarker
			>
				<mat-icon
					matPrefix
					class='pd-field-prefix-icon gray'
					svgIcon='general-search'
				></mat-icon>
				<mat-label>Поиск работ</mat-label>
				<input
					type='text'
					matInput
					class='pd-chip-input'
					formControlName='searchControl'
				>
				<button
					class='pd-icon-button with-line-height gray'
					mat-icon-button
					matSuffix
					matTooltip='Очистить фильтр'
					*ngIf='titleFilter.value'
					(click)='titleFilter.setValue(null)'
				>
					<mat-icon
						class='pd-icon'
						svgIcon='additional-close'
					></mat-icon>
				</button>
			</mat-form-field>

			<div class='pdw-switch-wrapper'>
				<pdw-top-switch
					class='pdw-switch'
					[names]='displayedFields'
					formControlName='switchControl'
				></pdw-top-switch>
			</div>
		</div>
		<div class='pd-dialog-content large-padding without-padding-top'>
			<ng-container *ngIf='!(isDataLoading | async); else loading'>
				<ng-container *ngIf="filterWorks(dataSource.data | async) as data">
					<ng-container *ngIf='data.length; else noWorkExecutors'>
						<mat-table
							class='pd-table pd-invite-dialog-table'
							[dataSource]='data'
						>

							<!--  Title Column  -->
							<ng-container matColumnDef='title'>
								<mat-cell
									*matCellDef='let element'
									class='pd-invite-dialog-table-title'
								>
									<ng-container *ngIf='canInvite(element); else disabledText'>
										<a
											class='pd-link pd-link-plain'
											[routerLink]="['/works', element.id]"
											target="_blank"
										>
											{{ element.title }}
										</a>
									</ng-container>
									<ng-template #disabledText>
										<div class="disabled-text-container disabled-link" (mouseover)="onShowSubTitle(element)" (mouseleave)="onHideSubTitle(element)" (click)="onRouterNavigate(element.id)">
											<p class='pd-invite-dialog-table-disabled-text pd-disabled-title'>
												{{ element.title }}
											</p>
											<p class='pd-invite-dialog-table-disabled-text pd-disabled-subtitle'>
												{{ getDisabledSubTitle(element) }}
											</p>
										</div>
									</ng-template>
								</mat-cell>
							</ng-container>

							<!-- Checked Column -->
							<ng-container matColumnDef='checked'>
								<mat-cell
									class='pd-invite-dialog-table-checkbox'
									*matCellDef='let element'
								>
									<pd-rounded-checkbox
										[matTooltip]='roundedCheckboxTooltip(element)'
										(click)='$event.stopPropagation()'
										(change)='$event ? toggleSelected(element) : null'
										[checked]='hasSelected(element)'
										[disabled]='!canInvite(element)'
									>
									</pd-rounded-checkbox>
								</mat-cell>
							</ng-container>

							<mat-row
								*matRowDef="let row; columns: ['title','checked'];"
								class='pd-invite-dialog-table-row'
							></mat-row>
						</mat-table>
						<ng-container *ngIf="remain | async as remain">
							<button
								class='pd-button-additional'
								(click)='goNextPage()'
								*ngIf='remain > 0'
							>
								Показать еще
							</button>
						</ng-container>
					</ng-container>
					<ng-template #noWorkExecutors>
						<ng-container *ngIf='filter.any; else noExecutorsAtAll'>
							<div class='pd-invite-dialog-no-data'>
								<mat-icon
									class='pd-dialog-confirmation-icon'
									[svgIcon]="'additional-alert-rectangle-100-100'"
								></mat-icon>
								<p class='pd-invite-dialog-no-data-text'>По вашему запросу ничего не найдено</p>
							</div>
						</ng-container>
						<ng-template #noExecutorsAtAll>
							<div class='pd-invite-dialog-no-data'>
								<mat-icon
									class='pd-dialog-confirmation-icon'
									[svgIcon]="'additional-alert-rectangle-100-100'"
								></mat-icon>
								<p class='pd-invite-dialog-no-data-text'>У вас нет опубликованных работ</p>
							</div>
						</ng-template>
					</ng-template>
				</ng-container>
			</ng-container>
			<ng-template #loading>
				<div class='pd-invite-dialog-no-data'>
					<mat-spinner
						[diameter]='24'
						[strokeWidth]='2'
					></mat-spinner>
				</div>
			</ng-template>
		</div>
	</form>
</tui-scrollbar>
<div class='pd-dialog-footer align-end'>
	<div class='pd-dialog-footer-buttons'>
		<button
			mat-raised-button
			class='pd-button-secondary large'
			type='reset'
			[mat-dialog-close]='false'
		>
			Отменить
		</button>
		<button
			mat-raised-button
			class='pd-button large'
			type='submit'
			(click)='goSave()'
			[disabled]='selection.isEmpty()'
		>
			<ng-container *ngIf='!(saving | async); else isSaving'>Пригласить</ng-container>
			<ng-template #isSaving>
				Приглашаем
			</ng-template>
		</button>
	</div>
</div>
