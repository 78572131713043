<ng-container *ngIf='currentWorkView | async'>
	<div class='pd-work-states-change-wrapper'>
		<button
			mat-raised-button
			class='pd-button-secondary pd-button-secondary-work-state'
			(click)='_goSeeking()'
			*ngIf='isDraft'
		>
			Опубликовать
		</button>
		<button
			mat-raised-button
			class='pd-button-secondary pd-button-secondary-work-state'
			[disabled]='true'
			*ngIf='isExecutingAwait || isExecuting'
		>
			Ожидает ответа
		</button>
		<button
			mat-raised-button
			class='pd-button-secondary pd-button-secondary-work-state half-width'
			(click)='_goExecutingAwait()'
			*ngIf='isPackaged'
		>
			Начать работу
		</button>
		<button
			mat-raised-button
			class='pd-button-secondary pd-button-secondary-work-state half-width'
			(click)='_goExecuting()'
			*ngIf='isChecking'
		>
			Вернуть в работу
		</button>
		<button
			mat-raised-button
			class='pd-button mat-raised-button half-width pd-button-secondary-work-state'
			(click)='_goSuccessed()'
			*ngIf='isChecking'
		>
			Завершить
		</button>
		<button
			mat-raised-button
			class='pd-button-secondary warn pd-button-secondary-work-state'
			(click)='_goDraft()'
			*ngIf='isSeeking && !isReadyToInvite'
		>
			Отозвать
		</button>
		<button
			mat-raised-button
			class='pd-button-secondary warn pd-button-secondary-work-state half-width'
			(click)='_goCancelByManager()'
			*ngIf='isPackaged'
		>
			Отказаться
		</button>

		<div
			*ngIf='isReadyToInvite'
			class='buttons-container'
		>
			<button
				mat-raised-button
				class='pd-button-secondary warn pd-button-secondary-work-state'
				(click)='_goDraft()'
				*ngIf='isSeeking'
			>
				Отозвать
			</button>

			<button
				mat-raised-button
				class='pd-button-secondary pd-button-secondary-work-state'
				(click)='inviteSpecialistToVacancy()'
				*ngIf='isReadyToInvite'
			>
				Завершить поиск
			</button>

		</div>

	</div>
</ng-container>
