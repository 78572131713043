import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppUserView } from '@models/app-user-view';
import { AppUserService } from '@core/services/app-user.service';
import {ImageAvatarSize} from "@shared/components/ui/image-avatar/image-avatar.types";

@Component({
	selector: 'pdw-review-of-user-dialog',
	templateUrl: './review-of-user-dialog.component.html',
	styleUrls: ['./review-of-user-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewOfUserDialogComponent {

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: AppUserView
	) {
	}

	avatarColor(appUserView: AppUserView): string {
		return AppUserService.avatarColor(appUserView);
	}

    protected readonly IMAGE_SIZE = ImageAvatarSize;
}
