import {CompanyView} from "@models/company-view";
import {YesNo} from "@models/enums";
import {AppUserView} from "@models/app-user-view";
export const CompanyStateMenuTitles = {
  icon: 'layers',
  primaryTitleItems: 'Статусы',
  descriptionItems: 'Все статусы',
  descriptionItem: 'Статус',
  primaryTitleSettingsItems: 'Статусы организиции',
  primaryTitleCreate: 'Создать статус',
  primaryTitleUpdate: 'Изменить статус',
}
export enum CompanyStateValue
{
  none = -1,
  draft,
  active,
  cancel
}
export class CompanyStateView {
  public id: number;
  public company: CompanyView;
  public isLast: YesNo;
  public value: CompanyStateValue;
  // public createPerson: PersonView;
  public createAppUser: AppUserView;
  public createTime: Date;

  static fromValue(value: CompanyStateValue): CompanyStateView {
    const result = new CompanyStateView();
    result.value = value;
    return result;
  }
}
