import { WorkStateValue } from '@models/work-view';
import { CityModel } from '@models/city-model';
import { YesNo } from '@models/enums';

export class WorkViewShort {
	id: number
	title: string
	companyId: number
	companyName: string
	executorId: any
	name: string
	amount: number
	description: string
	categoryNames: CategoryShortData[]
	subCategoryNames: SubCategoryShortData[]
	state: number
	subscribed: YesNo
	city: CityModel
	isFreelance: YesNo
}

export interface CategoryShortData {
	id: string,
	name: string
}

export interface SubCategoryShortData extends CategoryShortData{
	title: string
}
