<form class="pd-recovery-form" id="recoveryForm" [formGroup]="recoveryForm" (ngSubmit)="onSubmit(recoveryForm)">
	<div class="pd-form-hint forgot-password">
		<span class="pd-form-hint-compressed-text">Введите адрес электронной почты,</span> указанной при регистрации
	</div>
	<!--Email-->
	<mat-form-field class="pd-input-form-field" appearance="fill" hideRequiredMarker>
		<!--    <mat-icon class="pd-field-prefix-icon" svgIcon="additional-mail" matPrefix></mat-icon>-->
		<mat-label class="pd-auth-new-font">Email</mat-label>
		<input matInput formControlName="email" />
		<mat-error *ngIf="email?.errors?.required" i18n="mat-error@@forgot-password-email-required"
			>Обязательное поле</mat-error
		>
		<mat-error *ngIf="email?.errors?.serverError">
			{{ email?.errors?.serverError }}
		</mat-error>
		<mat-error *ngIf="email?.errors?.serverEmailError">Пользователь с таким адресом не найден!</mat-error>
	</mat-form-field>
	<button
		class="pd-button new-color"
		form="recoveryForm"
		type="submit"
		mat-raised-button
		color="primary"
		[disabled]="saving | async"
	>
		Далее
	</button>
</form>
