import {WorkView} from "@models/work-view";
import {AppUserView} from "@models/app-user-view";
import {AbstractModifiedModelView} from "@models/abstract-modified-model-view";

export class WorkExecutorMarkView extends AbstractModifiedModelView {
  public id: number;
  public work: WorkView;
  public executor: AppUserView;
  public qualityValue: number | null;
  public speedValue: number | null;
  public experienceValue: number | null;
  public comment: string;
  public modifyTime: Date;
}
