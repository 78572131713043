import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UnreadNotificationDataSource } from '@core/dataSources/unread-notification-data-source';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { NotificationsRequestService } from '@core/services/notifications-request.service';
import { takeUntil } from 'rxjs/operators';
import { NotificationCard, NotificationView } from '@models/notification-view';
import { AppUserView } from '@models/app-user-view';
import { AppUserService } from '@core/services/app-user.service';
import { DestroyService } from '@profdepo-ui/core';
import { TrueLoadingService } from '@core/services/true-loading.service';

@Component({
	selector: 'pdw-notifications-table',
	templateUrl: './notifications-table.component.html',
	providers: [DestroyService, TrueLoadingService],
	changeDetection: ChangeDetectionStrategy.OnPush

})
export class NotificationsTableComponent implements OnInit {
	user$: Observable<AppUserView>;
	unreadDataSource = new UnreadNotificationDataSource(this.notificationService);
	pageIndex = 0;
	pageSize = 10;
	remain = new BehaviorSubject<number>(null);

	constructor(
		private userService: AppUserService,
		private notificationService: NotificationsRequestService,
		private destroy$: DestroyService,
		public loading$: TrueLoadingService
	) {
		this.user$ = userService.getActiveUserAsObservable();
	}

	ngOnInit(): void {

		forkJoin([
			this.unreadDataSource.load(this.pageIndex, this.pageSize),
			this.unreadDataSource.loadCount()
		])
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe(data => {
				const remain = data[1] - (this.pageIndex + 1) * this.pageSize;
				this.remain.next(remain > this.pageSize ? this.pageSize : remain);
				this.loading$.next(false);
			});

	}

	goNextPage(): void {
		this.pageIndex++;
		this.loading$.next(true);
		forkJoin([
			this.unreadDataSource.loadMore(this.pageIndex, this.pageSize),
			this.unreadDataSource.loadCount()
		])
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe(data => {
				const remain = data[1] - (this.pageIndex + 1) * this.pageSize;
				this.remain.next(remain > this.pageSize ? this.pageSize : remain);
				this.loading$.next(false);
			});
	}

	toCard(user: AppUserView, notificationView: NotificationView): NotificationCard {
		return NotificationCard.card(user.type, notificationView);
	}

	onRead(notificationCard: NotificationCard): void {
		forkJoin([
			this.unreadDataSource.load(0, (this.pageIndex + 1) * this.pageSize),
			this.unreadDataSource.loadCount()
		])
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe(data => {
				const remain = data[1] - (this.pageIndex + 1) * this.pageSize;
				this.remain.next(remain > this.pageSize ? this.pageSize : remain);
				this.loading$.next(false);
			});
	}

}
