<div class="dialog-container">

    <div class="card">
        <div class="img">
            <img src="assets/dialog/link-transition-dialog/man-with-card.png" alt="Картинка">
        </div>
        <h1 class="title">Переход по внешней ссылке</h1>
        <div class="description-wrapper">
            <p class="text">Вы собираетесь перейти по ссылке:</p>
            <p class="bold-text">{{url}}</p>
            <p class="text">Сайт, расположенный по ссылке, не имеет отношения к <span
                    class="bold-text">Profdepo.work.</span> <br>
                При любых сомнениях мы настоятельно рекомендуем не переходить по данной ссылке.</p>
                <div class="logo-mobile">
                    <p class="text-logo">Profdepo<span class="blue-text">.work</span></p>
                </div>
        </div>
        <div class="btn-wrapper">
            <button mat-dialog-close="false" mat-raised-button class='cancel-button'>Отмена</button>
            <a mat-raised-button class='go-link-button' [href]="url" target="_blank">Все равно перейти</a>
        </div>

    </div>
    <div class="logo">
        <p class="text-logo">Profdepo<span class="blue-text">.work</span></p>
    </div>
</div>