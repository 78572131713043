import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreateFreelanceJobMethods, YesNo } from '@models/enums';
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators
} from '@angular/forms';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { TrueLoadingService } from '@core/services/true-loading.service';
import { catchError, defaultIfEmpty, filter, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { CompanyService } from '@core/services/company.service';
import {
	CreateControlInfo,
	Freelance,
	FreelanceData,
	FreelanceEdit,
	FreelanceEditData,
	Work,
	WorkData,
	WorkDetailsDialogData
} from './create-job-model';
import { forkJoin, Observable, throwError } from 'rxjs';
import { FiltersService } from '@core/services/filters.service';
import { WorkView } from '@models/work-view';
import { SubCategoryView } from '@models/sub-category-view';
import { SubCategoryService } from '@core/services/sub-category.service';
import { CategoryView } from '@models/category-view';
import { CategoryService } from '@core/services/category.service';
import { HardSkillService } from '@core/services/hard.skill.service';
import { AbstractSkillView } from '@models/abstract-skill-view';
import { isEmptyValue } from '@utils/helpers/filter-helpers';
import { WorkFormatService } from '@core/services/work-format.service';
import { WorkTypeService } from '@core/services/work-type.service';
import { WorkService } from '@core/services/work.service';
import { FileService } from '@core/services/file.service';
import { WorkFileView } from '@models/work-file-view';
import { filloutRelationships } from '@utils/form-helper';


@Component({
	selector: 'pdw-create-job-dialog',
	templateUrl: './create-job-dialog.component.html',
	styleUrls: ['./create-job-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [DestroyService, TrueLoadingService]
})
export class CreateJobDialogComponent implements OnInit {

	isFreelance: FormControl<boolean> = new FormControl<boolean>(false);
	protected readonly YesNo = YesNo;
	createForm: FormGroup;
	formData: CreateControlInfo[] = [];
	emptyFreelance: FreelanceData;
	freelanceEditData: FreelanceEditData;
	emptyWork: WorkData;
	private initValues: WorkView = null;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: WorkDetailsDialogData,
		private fb: FormBuilder,
		public loading$: TrueLoadingService,
		private destroy$: DestroyService,
		private companyService: CompanyService,
		private filterService: FiltersService,
		private subCategoriesService: SubCategoryService,
		private categoryService: CategoryService,
		private hardSkills: HardSkillService,
		private workFormatService: WorkFormatService,
		private workTypeService: WorkTypeService,
		private workService: WorkService,
		public dialogRef: MatDialogRef<CreateJobDialogComponent>,
		private notificationService: NotificationService,
		private fileService: FileService
	) {
		this.createForm = fb.group({
			name: [this.fillControl('name'), [
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(120)
			]],
			company: [this.fillControl('company'), [Validators.required]],
			city: [this.fillControl('city')],
			subCategories: [this.fillControl('subCategories'), [this.categoryValidator(5)]],
			description: [this.fillControl('description'), [Validators.required]],
			hardSkills: [this.fillControl('hardSkills'), [this.uniqueRequired.bind(this), Validators.required]],
			startDate: [this.fillControl('startDate'), [Validators.required]],
			endDate: [this.fillControl('endDate'), [Validators.required]],
			amount: [this.fillControl('amount')],
			files: [this.fillControl('files')],
			workType: [this.fillControl('workType'), [Validators.required]],
			workFormat: [this.fillControl('workFormat'), [Validators.required]],
		}, { validators: [this.dateValidator, this.requiredValidatorWrapper.bind(this)] });

		this.emptyFreelance = new Freelance();
		this.freelanceEditData = new FreelanceEdit();
		this.emptyWork = new Work();
		this.loading$.next(false);
	}


	ngOnInit(): void {
		this.formData.push({ name: 'company', dataAsObservable: this.companyService.ofOwner() })
		this.formData.push({
			name: 'subCategories',
			dataAsObservable: this.subCategoriesService.all()
				.pipe(map(subCategories => this.transformSubCategories(subCategories)))
		});

		this.formData.push({
			name: 'hardSkills',
			emitData: this.getHardSkills.bind(this)
		});

		this.formData.push({
			name: 'workType',
			dataAsObservable: this.workTypeService.all().pipe(filter(arr => Array.isArray(arr)))
		})

		this.formData.push({
			name: 'workFormat',
			dataAsObservable: this.workFormatService.all().pipe(filter(arr => Array.isArray(arr)))
		})

		this.initValues = this.createForm.value as WorkView;
		this.isFreelance
			.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.createForm.reset();
			});

	}

	formHasChanged(): boolean {
		return Object.keys(this.initValues).some((key: keyof WorkView) => {
			return this.initValues[key] !== this.createForm.value[key]
		})
	}

	requiredValidatorWrapper(group: AbstractControl): ValidationErrors | null {
		const amountControl = group.get('amount');

		if (!amountControl.value && this.isFreelanceDialog) {
			amountControl.setErrors({ required: 'Empty' });
			return { required: 'Empty' }
		}
		return null
	}

	dateValidator(group: AbstractControl): ValidationErrors | null {
		const fromCtrl = group.get('startDate');
		const toCtrl = group.get('endDate');
		if (!toCtrl.value || !fromCtrl.value) {
			return { invalidDate: 'error message' };
		}
		fromCtrl.setErrors(new Date(fromCtrl.value) > new Date(toCtrl.value) ? { invalidDate: true } : null);
		toCtrl.setErrors(new Date(fromCtrl.value) > new Date(toCtrl.value) ? { invalidDate: true } : null);
		return new Date(fromCtrl.value) > new Date(toCtrl.value) ? { invalidDate: 'error message' } : null;
	}

	getHardSkills(query: string): Observable<AbstractSkillView[]> {
		return this.hardSkills.all().pipe(shareReplay(1), map(hardSkills => hardSkills.filter(hard => hard.name.includes(query))));
	}

	uniqueRequired(control: AbstractControl) {
		return control.value &&
		typeof control.value === 'object' &&
		control.value.some(x => x.id == control.value.id)
			? { uniqueRequired: true }
			: null;
	}


	fillControl(key: string): any {
		if (key === 'files') {
			return this.data.workView.files as unknown as File[];
		}
		return this.data.workView[key] ?? null;
	}

	categoryValidator(maxCount: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			return (control.value &&
				control.value.length > maxCount)
				? { count: true }
				: null;
		}
	}

	isEmptyValue(control: FormControl): boolean {
		return isEmptyValue(control);
	}

	get isFreelanceDialog(): boolean {
		return false
	}

	get isFreelanceEditDialog(): boolean {
		return this.isFreelanceEditMode;
	}


	transformSubCategories(subCategories: Array<SubCategoryView>): Map<number, SubCategoryView[]> | null {
		const mapCategories = new Map<number, SubCategoryView[]>();
		subCategories.forEach(subCateg => {
			const key = subCateg.category.id;
			if (mapCategories.has(key)) {
				mapCategories.set(subCateg.category.id, [...mapCategories.get(key), subCateg])
			} else {
				mapCategories.set(subCateg.category.id, [subCateg])
			}
		});

		return mapCategories;
	}

	get isCreatingStage(): boolean {
		return this.data.method === CreateFreelanceJobMethods.create;
	}

	isNotDraftState(): boolean {
		return this.data.method === CreateFreelanceJobMethods.update;
	}

	compareSubCategoryView(x1: SubCategoryView, x2: SubCategoryView): boolean {
		return x1 && x2 ? x1.id === x2.id : false;
	}

	selectOptionDisabled(control: AbstractControl, maxCount: number, option: SubCategoryView | CategoryView): boolean {
		const isOptionSelected = control.value?.some(x => x.id === option.id);
		return control.value?.length >= maxCount && !isOptionSelected;
	}

	get modifiedTitle(): string {
		if (this.isNotDraftState()) {
			return this.isFreelanceDialog ? 'Редактирование работы' : 'Редактирование вакансии'
		}
		return this.isFreelanceDialog ? 'Создание работы' : 'Создание вакансии'
	}

	get isFreelanceEditMode(): boolean {
		return this.isFreelanceDialog && (this.data.method === CreateFreelanceJobMethods.update || this.data.method === CreateFreelanceJobMethods.accept);
	}

	rejectWork(): void {
		this.dialogRef.close(YesNo.no);
	}

	submitWork(): void {
		this.loading$.next(true);
		if (this.isAcceptMode()) {
			this.dialogRef.close(YesNo.yes);
			return;
		}

		const formData = this.createForm.value;
		const result = new WorkView();


		if (this.data.workView) {
			Object.keys(this.data.workView).forEach(key => {
				result[key] = this.data.workView[key];
			});
		}

		let files: File[] = [];

		Object.keys(formData).forEach(key => {
			if (key === 'files') {
				files = (formData[key] as File[])?.filter(file => !this.data.workView.files.find(res => res.name === file.name));
			}

			if (key === 'amount' && !formData['amount']) {
				result['amount'] = 0;
				return;
			}
			result[key] = formData[key];
		});

		if (!result.subCategories) {
			result.subCategories = [];
		}

		result.manager = this.data.appUserView;
		result.isFreelance = this.isFreelanceDialog ? YesNo.yes : YesNo.no;

		if (result.isFreelance === YesNo.yes) {
			forkJoin(
				files?.map(file => this.fileService.upload(file).pipe(map(result => {
						return { tmpName: result, file: file }
					}
				))) ?? []
			)
				.pipe(
					defaultIfEmpty([]),
					catchError((err) => {
						this.notificationService.showDanger(err);
						this.loading$.next(false);
						return throwError(err);
					}),
					switchMap((files) => {
						if (files.length) {
							const newFiles = files.map(result => {
								const file = new WorkFileView();
								file.id = 0;
								file.work = null;
								file.name = result.file.name;
								file.sourceFilename = result.file.name;
								file.temporaryFilename = result.tmpName;
								return file
							});

							result.files = [...formData.files.filter(file => file.id), ...newFiles];
						} else {
							result.files = formData.files;
						}

						if (this.data.method === CreateFreelanceJobMethods.update) {
							return this.workService.freelanceUpdate(this.data.workView.id, result)
						}
						return this.workService.freelanceCreate(result);
					}),
					takeUntil(this.destroy$)
				)
				.subscribe(res => {
					this.loading$.next(false);
					this.notificationService.showSuccess(this.data.method === CreateFreelanceJobMethods.update ? 'Фриланс успешно обновлен' : 'Фриланс успешно создан');
					this.dialogRef.close(res);
				});
			return;
		}

		if (this.data.method === CreateFreelanceJobMethods.update) {
			this.workService.vacancyUpdate(this.data.workView.id, result).pipe(
					catchError((err) => {
						this.notificationService.showDanger(err);
						this.loading$.next(false);
						return throwError(err);
					}),
					takeUntil(this.destroy$))
				.subscribe(val => {
					this.notificationService.showSuccess('Работа успешно обновлена');
					this.loading$.next(false);
					this.dialogRef.close(val);
				});
		} else {
			this.workService.vacancyCreate(result)
				.pipe(
					catchError((err) => {
						this.notificationService.showDanger(err);
						this.loading$.next(false);
						return throwError(err);
					}),
					takeUntil(this.destroy$)
				)
				.subscribe(val => {
					this.dialogRef.close(val);
					this.notificationService.showSuccess('Работа успешно создана');
				})
		}

	}


	getFileByName(name: string): WorkFileView {
		return this.data.workView.files.find(file => file.name === name) ?? null;
	}

	get isValidForm(): boolean {
		if (this.isFreelanceEditDialog) {
			return Object.keys(this.freelanceEditData).every(item => {
				return this.createForm.get(item)?.valid
			});
		}

		if (this.isFreelanceDialog) {
			return Object.keys(this.emptyFreelance).every(item => {
				if (item === 'isFreelance') {
					return this.isFreelance.valid
				}
				return this.createForm.get(item)?.valid
			});
		}

		return Object.keys(this.emptyWork).every(item => {
			if (item === 'isFreelance') {
				return this.isFreelance.valid
			}
			return this.createForm.get(item)?.valid
		})
	}

	isAcceptMode() {
		return this.data.method === CreateFreelanceJobMethods.accept;
	}
}
