import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { EnvironmentService } from '@core/services/environment.service';

@Directive({
	selector: '[pdwDevEnv]'
})
export class EnvDevelopmentDirective implements OnInit {

	constructor(private envService: EnvironmentService, private tpl: TemplateRef<any>, private vcr: ViewContainerRef) {
	}

	ngOnInit(): void {
		this.vcr.clear();
		if (!this.envService.getValue('production') && !this.envService.getValue('test')) {
			this.vcr.createEmbeddedView(this.tpl);
		} else {
			this.vcr.clear();
		}
	}

}
