import { AppUserType, AppUserView } from '@models/app-user-view';

export const checkEmptyUserInfo = (user: AppUserView, isToReturnRole = true) => {
	let result = '';

	if (user.lastname) {
		result = user.lastname;
	}
	if (user.firstname) {
		return result + ' ' + user.firstname
	}
	if (user.userName) {
		return user.userName
	}
	if (isToReturnRole) {
		return isCurrentUserRole(user, AppUserType.specialist) ? 'Специалист' : 'Заказчик'
	}
	return result;
}

export const isCurrentUserRole = (currentUser: AppUserView, type: AppUserType): boolean => {
	return currentUser.type === type
}
