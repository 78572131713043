import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppUserService } from '@core/services/app-user.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { RegistrationRequest } from '@requests/registration-request';
import { AppUserType } from '@models/app-user-view';
import { removeNone, removeZero } from '@utils/helpers/filter-helpers';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthPipe } from '@public/pipes/auth.pipe';
import { errorTitle } from '@utils/helpers/error-helpers';
import { Router } from '@angular/router';
import { ConfirmPassMatcher } from '@utils/errors/confirm-pass-matcher';
import { checkPasswords } from '@utils/check-passwords';
import { HeaderService } from '@core/services/header.service';
import { OAuthService } from '@core/services/oauth.service';
import { environment } from '@env/environment';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { DATE_01011900_MS, dateMask, emailRegEx, PASSWORD_REGEX } from '@utils/constants';
import { filloutFormError } from '@utils/form-helper';
import { MaskitoOptions } from '@maskito/core';
import { dateNotEarlierValidator, dateNotLaterValidator } from '@utils/functions';
import { CustomValidators } from '@utils/validators/custom-validators';
import * as moment from 'moment';
import { LocalStorageUtilsService } from '@core/services/local-storage-utils.service';

@Component({
	selector: "pdw-register",
	templateUrl: "./register.component.html",
	styleUrls: ["./register.component.scss"],
	providers: [DestroyService],
	encapsulation: ViewEncapsulation.None,
})
export class RegisterComponent implements OnInit {
	openAuthProviders = [
		{ name: "Yandex", title: "Яндекс", icon: "social-yandex" },
		{ name: "Vk", title: "ВКонтакте", icon: "social-vk" },
		// { name: 'Google', title: 'Google', icon: 'social-google' },
	];
	authPipe = new AuthPipe();
	passwordHide = true;
	passwordConfirmHide = true;
	registrationForm: FormGroup;
	appUserTypes = AppUserType;
	checkPasswords = checkPasswords;

	matcher = new ConfirmPassMatcher();
	afterConfirmationEmail: boolean = false;
	afterResetPassword: boolean = false;
	maskitoOptions: MaskitoOptions = {
		mask: dateMask,
	};

	constructor(
		private formBuilder: FormBuilder,
		private appUserService: AppUserService,
		private notificationService: NotificationService,
		private router: Router,
		private headerService: HeaderService,
		private oAuthService: OAuthService,
		private destroy$: DestroyService,
		private localStorageUtils: LocalStorageUtilsService,
		@Inject(PASSWORD_REGEX) regex: string
	) {
		this.registrationForm = new FormGroup(
			{
				email: new FormControl<string>("", [Validators.required, Validators.pattern(emailRegEx)]),
				password: new FormControl<string>("", [
					Validators.required,
					Validators.maxLength(20),
					Validators.pattern(regex),
				]),
				birthday: new FormControl<string>("", [
					Validators.required,
					CustomValidators.comingOfAgeValidator(),
					dateNotEarlierValidator(DATE_01011900_MS),
					dateNotLaterValidator(new Date().getTime()),
				]),
				passwordConfirm: new FormControl<string>("", [Validators.required, Validators.minLength(8)]),
				type: new FormControl<number>(AppUserType.specialist, [Validators.required]),
				captcha: new FormControl<string>("", [Validators.required]),
			},
			{ validators: this.checkPasswords }
		);
	}

	ngOnInit(): void {
		this.localStorageUtils.setVisitedUrl();
		if (history.state.afterResetPassword) {
			this.afterResetPassword = history.state.afterResetPassword;
		}
		if (history.state.afterConfirmationEmail) {
			this.afterConfirmationEmail = history.state.afterConfirmationEmail;
		}
		removeNone(this.appUserTypes);
		removeZero(this.appUserTypes, "administrator");

		this.headerService.changeCurrentTitle("Регистрация");
	}

	compareEnum(t1: any, t2: any): boolean {
		return +t1 === t2;
	}

	get email(): FormControl {
		return this.registrationForm.get("email") as FormControl;
	}

	get password(): FormControl {
		return this.registrationForm.get("password") as FormControl;
	}

	get passwordConfirm(): FormControl {
		return this.registrationForm.get("passwordConfirm") as FormControl;
	}

	get type(): FormControl {
		return this.registrationForm.get("type") as FormControl;
	}

	get birthday(): FormControl {
		return this.registrationForm.get("birthday") as FormControl;
	}

	onSubmit(form): void {
		const birthday = moment(form.get('birthday').value).format('YYYY-MM-DD');
		const updatedFormValue = { ...form.value, birthday: birthday };
		if (form.valid) {
			this.appUserService
				.registration({
					...updatedFormValue,
				} as RegistrationRequest)
				.pipe(
					finalize(() => {
						console.warn("registration finish");
					})
				)
				.subscribe({
					next: () => {
						this.notificationService.showSuccess("Пользователь успешно зарегистрирован");
						this.router.navigate(["/sentConfirmation"], {
							state: { afterRegistration: true },
						});
					},
					error: (err) => {
						if (err instanceof HttpErrorResponse) {
							if (err.status === 400) {
								filloutFormError(this.registrationForm, err);
							} else {
								this.notificationService.showDanger(errorTitle(err));
							}
						}
					},
				});
		}
	}

	get isProduction(): boolean {
		return environment.production;
	}

	goOAuthLink(provider: string): void {
		this.oAuthService
			.oauthLink(provider)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (data: string) => {
					window.location.href = data;
				},
				error: (err) => {
					console.warn("err: ", err);
				},
			});
	}
}