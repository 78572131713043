export class Tokens {
  authToken: string;
  refreshToken: string;
  expiration: number;
  constructor(authToken: string, refreshToken: string) {
    this.authToken = authToken;
    this.refreshToken = refreshToken;
    this.expiration = 0;
  }
}
