import { Component, Input, OnInit } from '@angular/core';
import { AppUserView } from '@models/app-user-view';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UtilsService } from '@core/services/utils.service';
import { hundredRatingColorClass } from '@utils/functions';

@Component({
	selector: 'app-app-user-successful-works-rate',
	templateUrl: './app-user-successful-works-rate.component.html',
})
export class AppUserSuccessfulWorksRateComponent implements OnInit {
	@Input() inCard: boolean = false;

	@Input()
	set currentUserView(value: AppUserView) {
		this._currentUserView.next(value);
	};

	get currentUserView(): AppUserView {
		return this._currentUserView.getValue();
	}

	private _currentUserView = new BehaviorSubject<AppUserView>(null);
	currentAppUserView = new BehaviorSubject<AppUserView>(null);

	rating: number = 0;

	constructor(
		public utilsService: UtilsService
	) {
	}

	ngOnInit(): void {
		this._currentUserView
			.pipe(
				filter(x => x !== null),
			)
			.subscribe((data: AppUserView) => {
				this.currentAppUserView.next(data);
				if (data.workCountAll > 0) {
					this.rating = Math.round((data.workCountSuccessed / data.workCountAll) * 100);
				}
			});
	}

	colorClass(value: number): string {
		return hundredRatingColorClass(value);
	}

	getTooltip(currentUser: AppUserView): string {
		let textWork = 'работ';
		if ((currentUser.workCountAll > 20 && currentUser.workCountAll % 10 === 1) ||
			currentUser.workCountAll == 1) {
			textWork = 'работы';
		}

		return `Выполнено ${currentUser.workCountSuccessed} из ${currentUser.workCountAll} ${textWork}`;
	}
}
