import { Pipe, PipeTransform } from '@angular/core';
import {AbstractExtensionEnumPipe} from "@public/pipes/abstract-extension-enum.pipe";

@Pipe({
  name: 'companyStateValue'
})
export class CompanyStateValuePipe extends AbstractExtensionEnumPipe implements PipeTransform {
  values = {
    0: "Создана",
    1: "Действует",
    2: "Ликвидирована",
  };
  transform(value: any, ...args: any[]): any {
    return this.getTransform(value, args);
  }
}
