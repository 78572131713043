import {Injectable} from '@angular/core';
import {AbstractModelService} from "@core/services/abstract-model.service";
import {HttpClient} from "@angular/common/http";
import {CategoryView} from "@models/category-view";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private categoriesUrl = 'api/Categories';
  constructor(private http: HttpClient, private modelService: AbstractModelService) {
  }

  /**
   * Преобразование string в Date после десериализации
   * @param categoryView категория
   */
  public static parseDates(categoryView: CategoryView): CategoryView {
    categoryView.createTime = new Date(categoryView.createTime);
    categoryView.modifyTime = new Date(categoryView.modifyTime);
    return categoryView;
  }

  all(): Observable<CategoryView[]> {
    const url = `${this.categoriesUrl}/All`;
    return this.http.get<CategoryView[]>(url, this.modelService.httpOptions)
      .pipe(
        map(xx => xx.map(x => CategoryService.parseDates(x))),
      );
  }
}
