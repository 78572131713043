import {AbstractControl, FormControl, FormGroup, ValidationErrors} from "@angular/forms";

/**
 * Валидатор значения типа object: должно быть object
 * @param control
 */
export function objectRequired(control: AbstractControl) {
  return control.value === '' || typeof control.value === 'object'
    ? null
    : { objectRequired: true };
}

/**
 * Валидатор числового значения: больше 0 (не отрицательное и больше 0)
 * @param control
 */
export function positiveRequired(control: AbstractControl) {
  if (Number(control.value) <= 0) {
    return {positiveRequired: true};
  } else {
    return null;
  }
}

export function integerRequired(control: AbstractControl) {
  return Number.isInteger(control.value)
    ? null
    : {integerRequired: true};
}

/**
 * Валидатор времени в формате HH:mm
 * @param control
 */
export function hhmmRequired(control: AbstractControl) {
  if (control.value && control.value.length) {
    const values = control.value.toString().split(':', 2);
    if (values.length < 2)
      return {hhmmRequired: true};
    const hours = Number(values[0]);
    const minutes = Number(values[1]);
    if (hours < 0 || hours > 24 || minutes < 0 || minutes > 59 || Number.isNaN(hours) || Number.isNaN(minutes))
      return {hhmmRequired: true};
    return null;
  } else {
    return {hhmmRequired: true};
  }
}

export function dateLessThan(from: string, to: string) {
  return (group: FormGroup): ValidationErrors => {
    let f = group.controls[from];
    let t = group.controls[to];
    if (f.value && t.value && f.value > t.value) {
      console.warn('ERROR!');
      return {dateLessThan: true};
    }
    return null;
  }
}


/**
 * Валидатор подтверждения пароля
 * @param password наименование поля с паролем
 * @param passwordConfirm наименование поля с подтверждением пароля
 */
export function passwordConfirm(password: string, passwordConfirm: string) {
  return (group: FormGroup): ValidationErrors => {
    let p = group.controls[password];
    let c = group.controls[passwordConfirm];
    if (p.value && c.value && p.value != c.value) {
      console.warn('ERROR!');
      return {passwordConfirm: true};
    }
    return null;
  }
}

/**
 * Валидатор для проверки на пустые инпуты, заполненными пробелами
 * @param control
 */
export function WhitespaceValidator(control: FormControl): ValidationErrors | null {
  if (!control.value){
    return null
  }

  const isWhitespace = control.value.trim().length === 0;
  return isWhitespace ? { 'whitespace': true } : null;
}
