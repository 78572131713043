import {Component, Input, OnInit} from '@angular/core';
import {CompanyView} from "@models/company-view";
import {BehaviorSubject} from "rxjs";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-company-rating',
  templateUrl: './company-rating.component.html',
})
export class CompanyRatingComponent implements OnInit {
  @Input('companyView')
  set companyView(value: CompanyView) {
    this._companyView.next(value);
  };
  get companyView(): CompanyView {
    return this._companyView.getValue();
  }
  private _companyView = new BehaviorSubject<CompanyView>(null);
  currentCompanyView = new BehaviorSubject<CompanyView>(null);
  constructor() { }

  ngOnInit(): void {
    this._companyView
      .pipe(
        filter(x => x !== null),
      )
      .subscribe((companyView: CompanyView) => {
        this.currentCompanyView.next(companyView);
      });
  }
}
