export interface UploadPdfSalaryResponceData {
	loading: boolean;
	data: UploadPdfSalaryResponce | null;
}

export interface TopFiveCitiesData {
	loading: boolean;
	data: TopFiveCities | null;
}

export interface CourseEnData {
	loading: boolean;
	data: CourseEn | null;
}

export interface CareerPathData {
	loading: boolean;
	data: CareerPath | null;
}
export interface UploadPdfSalaryResponce {
	description: string;
	salary: string;
}

export interface CitiesData {
	city: string;
	slaryFork: string;
	puls: { description: string }[];
	minus: { description: string }[];
}

export interface TopFiveCities {
	city: CitiesData[];
}

export interface Course {
	courseName: string;
	courseCompany: string;
	link: string;
	duration: string;
	cost: string;
}
export interface CourseEn {
	coursesEn: Course[];
}

export interface CareerPath {
	careerPathEn: CareerPathNameEn[];
}

export interface CareerPathNameEn {
	careerPathNameEn: string | null;
	careerStage: CareerStage[];
}
export interface CareerStage {
	stageDuration: string;
	position: string;
	companyDescripton: string;
	averageIncome: string;
	skillForDevelopment: Skill[];
	valuebleProduct: string;
}

export interface Skill {
	description: string;
}
