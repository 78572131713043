import { DataSource } from '@core/dataSources/data-source';
import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { tap } from 'rxjs/operators';
import { WorkService, WorksFilter } from '@core/services/work.service';
import { WorkView } from '@models/work-view';

export class AvailableWorkDataSource implements DataSource<WorkView> {
	private workExecutorViewsSubject = new BehaviorSubject<WorkView[]>([]);
	private countSubject = new BehaviorSubject<number>(0);

	constructor(
		private workService: WorkService
	) {
	}

	data = this.workExecutorViewsSubject.asObservable();
	count = this.countSubject.asObservable();

	connect(collectionViewer: CollectionViewer): Observable<WorkView[]> {
		return this.workExecutorViewsSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.workExecutorViewsSubject.complete();
		this.countSubject.complete();
	}

	load(worksFilter: WorksFilter, sortDirection: string, pageIndex: number, pageSize: number, isGlobal: boolean = true): Observable<WorkView[]> {
		if (isGlobal) {
			return this.workService.available(worksFilter, sortDirection, pageIndex, pageSize)
				.pipe(
					tap(workViews => this.workExecutorViewsSubject.next(workViews))
				);
		} else {
			return this.workService.localManager(worksFilter, sortDirection, pageIndex, pageSize)
				.pipe(
					tap(workViews => this.workExecutorViewsSubject.next(workViews))
				);
		}
	}

	loadMore(worksFilter: WorksFilter, sortDirection: string, pageIndex: number, pageSize: number): Observable<WorkView[]> {
		return this.workService.available(worksFilter, sortDirection, pageIndex, pageSize)
			.pipe(
				tap(workViews => {
					const tmp = this.workExecutorViewsSubject.value;
					const newData = workViews.filter(x => !tmp.some(w => w.id === x.id));

					this.workExecutorViewsSubject.next(tmp.concat(newData));
				})
			);
	}

	loadCount(worksFilter: WorksFilter): Observable<number> {
		return this.workService.availableCount(worksFilter)
			.pipe(
				tap(count => {
					this.countSubject.next(count)
				})
			);
	}
}
