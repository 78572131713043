import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'email'
})
export class EmailPipe extends AbstractModelPipe implements PipeTransform {
  properties = [
    {name: 'address', title: 'Эл. почта', hint: 'Адрес эл. почты'},
    {name: 'purpose', title: 'Назначение', hint: 'Назначение адреса эл. почты'},
    {name: 'mail', title: 'Email', hint: 'Email'},
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
  allProperties() {
    return this.properties;
  }
}
