import { Injectable } from '@angular/core';
import { AbstractModelService } from '@core/services/abstract-model.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class FileService {
	private filesUrl = 'api/Files';

	constructor(private http: HttpClient, private modelService: AbstractModelService) {
	}

	upload(file: File): Observable<string> {
		let formData = new FormData();
		formData.append('file', file, file.name);
		const url = `${this.filesUrl}/Upload`;
		return this.http.post<string>(url, formData, this.modelService.uploadHttpHeaders);
	}

	deleteTmpFile(fileName: string): Observable<void> {
		return this.http.delete<void>(`${this.filesUrl}/DeleteTmpFile/${fileName}`);
	}
}
