<ng-container *ngIf='currentAppUserView | async as currentUser'>
	<div class='pd-contacts-row'>
		<div class='pd-contacts-item'>
			<mat-icon
				class='pd-icon light-gray'
				svgIcon='additional-phone-call'
			></mat-icon>
			<p class='pd-profile-phone' [class.pd-phone-text]="!currentUser.phoneNumber">
				{{ currentUser.phoneNumber ? (currentUser.phoneNumber | tuiFormatPhone) : 'Не указан' }}
			</p>
		</div>
		<div class='pd-contacts-item'>
			<ng-container *ngIf='!currentUser.emailConfirmed; else confirmedEmailIcon'>
				<ng-container *ngIf='canUpdate; else notConfirmedEmailIcon'>
					<button
						mat-icon-button
						class='pd-icon-button light-gray'
						(click)='goEmailConfirmation()'
						[matTooltip]="'Подтвердите адрес'"
					>
						<mat-icon
							class='pd-icon'
							svgIcon='additional-mail-new-red'
						></mat-icon>
					</button>
				</ng-container>
				<ng-template #notConfirmedEmailIcon>
					<mat-icon
						class='pd-icon light-gray'
						svgIcon='additional-mail-new-red'
						[matTooltip]="'Почта не подтверждена'"
					></mat-icon>
				</ng-template>
			</ng-container>
			<ng-template #confirmedEmailIcon>
				<mat-icon
					class='pd-icon light-gray'
					svgIcon='additional-mail-check-green'
					[matTooltip]="'Почта подтверждена'"
				></mat-icon>
			</ng-template>
			<p class='pd-profile-email'>
				{{ currentUser.email ? currentUser.email : 'Почта' }}
			</p>
		</div>
	</div>
</ng-container>
