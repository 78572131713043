<ng-container *ngIf='currentAppUserView | async as appUserView'>
	<div
		[routerLink]="['/users', appUserView.id]"
		(mousedown)='onMouseDown($event)'
		(mouseup)='onMouseUp(appUserView, $event)'
		class='pd-user-card-inner'
	>
		<!--Header-->
		<div class="user-card">
			<div class="top-row">
				<div
					class='pd-user-card-title-text'
					[ngClass]="{'short': isActiveUserClient}"
				>
					<p class='pd-user-card-title-name'>{{ appUserView.profession ? appUserView.profession : 'Специалист'}}</p>
				</div>
				<div
					class='pd-user-card-title-buttons'
					*ngIf='isActiveUserClient; else noAuthActionBtn'
				>
					<button
						mat-icon-button
						class='pd-icon-button pd-card-bookmark'
						(click)='checkAuthBeforeAddToFavorites($event, appUserView)'
						*ngIf='appUserView.favorite !== YESNO.yes'
						[matTooltip]="'Добавить в избранное'"
					>
						<mat-icon
							class='pd-icon'
							svgIcon='additional-bookmark'
						></mat-icon>
					</button>
					<button
						mat-icon-button
						class='pd-icon-button pd-card-bookmark-favorite'
						(click)='checkAuthBeforeRemoveFromFavorites($event, appUserView)'
						[matTooltip]="'Убрать из избранного'"
						*ngIf='appUserView.favorite === YESNO.yes'
					>
						<mat-icon
							class='pd-icon'
							svgIcon='additional-bookmark'
						></mat-icon>
					</button>
					<button
						*ngIf="isShowInviteWorkBtn"
						mat-icon-button
						class='pd-icon-button'
						(click)='checkAuthBeforGoInviteWork($event, appUserView)'
						[matTooltip]="'Пригласить на работу'"
					>
						<mat-icon
							class='pd-icon'
							svgIcon='additional2-user-add'
						></mat-icon>
					</button>
				</div>
				<ng-template #noAuthActionBtn>
					<div class='pd-user-card-title-buttons'>
						<button
							mat-icon-button
							class='pd-icon-button pd-card-bookmark'
							(click)='checkAuthBeforeAddToFavorites($event, appUserView)'
							*ngIf='appUserView.favorite !== YESNO.yes'
							[matTooltip]="'Добавить в избранное'"
						>
							<mat-icon
								class='pd-icon'
								svgIcon='additional-bookmark'
							></mat-icon>
						</button>
						<button
							mat-icon-button
							class='pd-icon-button pd-card-bookmark-favorite'
							(click)='checkAuthBeforeRemoveFromFavorites($event, appUserView)'
							[matTooltip]="'Убрать из избранного'"
							*ngIf='appUserView.favorite === YESNO.yes'
						>
							<mat-icon
								class='pd-icon'
								svgIcon='additional-bookmark'
							></mat-icon>
						</button>
						<button
							*ngIf="isActiveUserClient && isShowInviteWorkBtn"
							mat-icon-button
							class='pd-icon-button'
							(click)='checkAuthBeforGoInviteWork($event, appUserView)'
							[matTooltip]="'Пригласить на работу'"
						>
							<mat-icon
								class='pd-icon'
								svgIcon='additional2-user-add'
							></mat-icon>
						</button>
					</div>
				</ng-template>
			</div>
			<div class="content-row">
				<div>
					<div class="user-rating-title">
						<div class="user-title" *ngIf="getUserTitle(appUserView)">
							{{getUserTitle(appUserView)}}
						</div>
						<div>
							<app-rating-one-small [percent]='appUserView.ratingPercent'>
							</app-rating-one-small>
						</div>
					</div>
					<p class="content-row__age">
						{{ appUserAge(appUserView?.birthday ?? null) }}&nbsp;&nbsp;&nbsp;<span
							class="content-row__city">{{appUserView?.city ? appUserView?.city.name : ''}}</span>
					</p>
					<p class="content-row__salary" *ngIf="getSalary(appUserView)">
						{{ getSalary(appUserView) + ' ₽' }}
					</p>
					<p class="content-row__no-salary" *ngIf="!getSalary(appUserView)">
						Не указан
					</p>
				</div>
				<div>
					<div
						class='pd-user-card-avatar'
						[style.background-color]='avatarColor(appUserView)'
					>
						<ng-container *ngIf='appUserView.hasAvatar === YESNO.yes; else noAvatar'>
							<img
								class='pd-user-card-avatar-img'
								[src]='appUserView | avatar | async'
								alt='Avatar Image'
							/>
						</ng-container>
						<ng-template #noAvatar>
							<pdw-user-initial-letters
								[user]='appUserView'
								[canUpdate]='false'
								[size]="IMAGE_SIZE.MEDIUM"
							>
							</pdw-user-initial-letters>
						</ng-template>
						<div
							class='pd-profile-online-status'
							[ngClass]="{'online' : isUserOnline(appUserView)}"
							[matTooltip]="isUserOnline(appUserView) ? 'В сети' : appUserView.lastConnection | appUserLastConnection"
						></div>
					</div>
				</div>
			</div>
		</div>
		<!--Content-->
		<div class='pd-user-card-content'>
			<ng-container *ngIf='appUserView.subCategories && appUserView.subCategories.length;'>
				<div class='pd-user-card-categories'>
					<ng-container *ngFor='let subCategory of appUserView.subCategories'>
						<div class='pd-skill-inner'>
							<p class='pd-skill-text'>
								{{ subCategory.name }}
							</p>
						</div>
					</ng-container>
				</div>
			</ng-container>
			<ng-content></ng-content>
		</div>
	</div>
</ng-container>



