import { EventEmitter, Injectable } from '@angular/core';
import { AbstractModelService } from '@core/services/abstract-model.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CompanySpecTag, CompanyView } from '@models/company-view';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { RusProfileResponse } from '../../responses/rus-profile-response';
import { CompanyStateValue, CompanyStateView } from '@models/company-state-view';
import { ImageRequest } from '@requests/image-request';
import { CompanyFromApi, CompanyFromFnsApi } from '@utils/api-company-data';
import { stringToHslColor } from '@utils/helpers/string-helpers';

export class CompaniesFilter {
	any: string = null;
	specTag?: CompanySpecTag = CompanySpecTag.all;
	ratingMoreThen?: number[] = null;
	industriesIds?: number[] | null = null;
	city?: string[] = null;
}

@Injectable({
	providedIn: 'root'
})

export class CompanyService {
	private companiesUrl = 'api/Companies';
	private ownerCompanyViewsSource = new BehaviorSubject<CompanyView[]>(null);
	private companyViewCache: { id: number, observable: Observable<CompanyView> }[] = [];
	private currentCompanyViewSource = new BehaviorSubject<CompanyView>(null);
	private clearCacheEmitter = new EventEmitter<any>();

	public companies = new BehaviorSubject<CompanyView[]>([]);

	/**
	 * Флаг для перезагрузки карточек
	 */
	shouldReload = new BehaviorSubject<boolean>(false);

	constructor(private http: HttpClient, private modelService: AbstractModelService) {
	}

	/**
	 * Возвращает активное юр. лицо
	 */
	currentCompanyView = this.currentCompanyViewSource.asObservable();

	/**
	 * Делает активным указанное юр. лицо
	 * @param id идентификатор юр. лица
	 */
	changeCurrentCompanyView(id: number): Observable<CompanyView> {
		if (id && typeof id === 'number') {
			return this.getCompanyView(id)
				.pipe(
					tap(companyView => this.currentCompanyViewSource.next(companyView))
				);
		} else {
			console.warn('Invalid type of id argument in changeCurrentCompany', id);
			return of(null);
		}
	}

	ofOwner(): Observable<CompanyView[]> {
		const url = `${this.companiesUrl}/OfOwner`;
		return this.http.get<CompanyView[]>(url, this.modelService.httpOptions)
			.pipe(
				tap(xx => xx.map(x => CompanyService.parseDates(x)))
			);
	}

	/**
	 * Возвращает список организаций, владельцем или менеджером которых является активный пользователь
	 * НЕ ИСПОЛЬЗОВАТЬ В forkJoin!
	 */
	ofOwnerFull(): Observable<CompanyView[]> {
		if (this.ownerCompanyViewsSource.value === null) {
			// console.warn('getCurrentUserView load from SERVER!');
			const url = `${this.companiesUrl}/OfOwnerFull`;
			return this.http.get<CompanyView[]>(url, this.modelService.httpOptions)
				.pipe(
					tap(xx => xx.map(x => CompanyService.parseDates(x))),
					tap(companyViews => this.ownerCompanyViewsSource.next(companyViews))
				);
		} else {
			// console.warn('getCurrentUserView load from CLIENT!');
			return this.ownerCompanyViewsSource.asObservable();
		}
	}

	/**
	 * Преобразование string в Date после десериализации
	 * @param companyView юр. лицо
	 */
	public static parseDates(companyView: CompanyView): CompanyView {
		if (companyView) {
			companyView.createTime = new Date(companyView.createTime);
			companyView.modifyTime = new Date(companyView.modifyTime);
		}
		return companyView;
	}

	/**
	 * Преобразование string в Date после десериализации
	 * @param companyStateView состояние организации
	 */
	public static parseStateDates(companyStateView: CompanyStateView): CompanyStateView {
		companyStateView.createTime = new Date(companyStateView.createTime);
		return companyStateView;
	}

	/**
	 * Загружает, кэширует и возвращает юр. лицо.
	 * @param id идентификатор юр. лица для загрузки данных с сервера или получения его из массива кэша
	 */
	getCompanyView(id: number): Observable<CompanyView> {
		if (id && typeof id === 'number') {
			if (!this.companyViewCache || !this.companyViewCache.find(x => x.id === id)) {
				const url = `${this.companiesUrl}/${id}`;
				this.companyViewCache.push({
					id,
					observable: this.http.get<CompanyView>(url, this.modelService.httpOptions)
						.pipe(
							takeUntil(this.clearCacheEmitter),
							map(x => CompanyService.parseDates(x)),
							shareReplay(1)
						)
				});
			}
			return this.companyViewCache.find(x => x.id === id).observable;
		} else {
			console.warn('Invalid type of id argument in getCompanyView', id);
			return of(null);
		}
	}

	/**
	 * Очищает кэш с указанным юр. лицом
	 * @param id идентификатор юр. лица для очистки записи в кэше
	 */
	clearCompanyView(id: number): void {
		if (id && typeof id === 'number') {
			if (this.companyViewCache) {
				const index = this.companyViewCache.findIndex(x => x.id === id);
				if (index >= 0) {
					this.companyViewCache.splice(index, 1);
				}
			}
		} else {
			console.warn('Invalid type of id argument in clearCompanyView', id);
		}
	}

	/**
	 * Очищает кэш
	 */
	clearCompanyViews(): void {
		this.clearCacheEmitter.emit('clear Cache');
		this.companyViewCache = [];
	}

	/**
	 * Очищает кэш
	 */
	clearOwnerCompanyViews(): void {
		this.ownerCompanyViewsSource.next(null);
	}

	/**
	 * Добавление нового юр. лица
	 * @param companyView юр. лицо
	 */
	addCompanyView(companyView: CompanyView): Observable<CompanyView> {
		return this.http.post<CompanyView>(this.companiesUrl, companyView, this.modelService.httpOptions)
			.pipe(
				map(x => CompanyService.parseDates(x)),
			);
	}

	/**
	 * Изменение существующего юр. лица
	 * @param companyView юр. лицо
	 */
	updateCompanyView(companyView: CompanyView): Observable<CompanyView> {
		const url = `${this.companiesUrl}/${companyView.id}`;
		return this.http.put<CompanyView>(url, companyView, this.modelService.httpOptions)
			.pipe(
				map(x => CompanyService.parseDates(x)),
				tap(x => {
					this.clearCompanyView(x.id);
				})
			);
	}

	// @ts-ignore
	callCompanyView(method: string, companyView: CompanyView): Observable<CompanyView> {
		if (method === 'create' || method === 'replace') {
			return this.addCompanyView(companyView);
		}
		if (method === 'update') {
			return this.updateCompanyView(companyView);
		}
	}

	/**
	 * Полиучение данных компании по инн
	 * @param inn инн компании
	 */
	getCompanyDataFromApiByInn(inn: string): Observable<CompanyFromApi[]> {
		var url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party';
		var token = '183bb2ae015cd6d0be2eeedcac630cb22fb542fa';

		var options = {
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Token ' + token
			}
		}
		return this.http.post<any>(url, { query: inn }, options).pipe(
			map(x => x?.suggestions),
		);
	}

	/**
	 * Полиучение данных компании по инн
	 * @param inn инн компании
	 */
	getCompanyDataByInnFromFns(inn: string): Observable<CompanyFromFnsApi> {
		return this.http.get<CompanyFromFnsApi>(`${this.companiesUrl}/GetCompanyDataByInnFromFns/${inn}`);
	}

	validateView(companyView: CompanyView): Observable<CompanyView> {
		const url = `${this.companiesUrl}/ValidateView`;
		return this.http.post<CompanyView>(url, companyView, this.modelService.httpOptions);
	}

	states(id: number): Observable<CompanyStateView[]> {
		const url = `${this.companiesUrl}/States/${id}`;
		return this.http.get<CompanyStateView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => CompanyService.parseStateDates(x)))
			);
	}

	find(query: string): Observable<CompanyView[]> {
		if (query === null || query.length === 0)
			return of([]);
		const url = `${this.companiesUrl}/Find/${query}`;
		return this.http.get<CompanyView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => CompanyService.parseDates(x))),
			);
	}

	findByInn(inn: string): Observable<CompanyView[]> {
		if (inn == null || inn.length === 0)
			return of(null);
		const url = `${this.companiesUrl}/FindByInn/${inn}`;
		return this.http.get<CompanyView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => CompanyService.parseDates(x))),
			);
	}

	/**
	 * Поиск организации, в которой есть работы, где пользователь является исполнителем
	 * @param query Строка поиска
	 */
	findOfMyWorks(query: string): Observable<CompanyView[]> {
		if (query === null || query.length === 0)
			return of([]);
		const url = `${this.companiesUrl}/FindOfMyWorks/${query}`;
		return this.http.get<CompanyView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => CompanyService.parseDates(x))),
			);
	}

	/**
	 * Первые 50 организаций
	 */
	firstFiftyCompanies(): Observable<CompanyView[]> {
		const url = `${this.companiesUrl}/FirstFifty`;
		return this.http.get<CompanyView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => CompanyService.parseDates(x))),
			);
	}

	/**
	 * Первые 50 организаций, в которых есть работы, где пользователь является исполнителем
	 */
	firstOfMyWorks(): Observable<CompanyView[]> {
		const url = `${this.companiesUrl}/FirstOfMyWorks`;
		return this.http.get<CompanyView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => CompanyService.parseDates(x))),
			);
	}

	/**
	 * Поиск организации, в которой есть работы, подходящие пользователю
	 * @param query Строка поиска
	 */
	findOfMatchedWorks(query: string): Observable<CompanyView[]> {
		if (query === null || query.length === 0)
			return of([]);
		const url = `${this.companiesUrl}/FindOfMatchedWorks/${query}`;
		return this.http.get<CompanyView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => CompanyService.parseDates(x))),
			);
	}

	/**
	 * Первые 50 организаций, в которых есть работы, подходящие пользователю
	 */
	firstOfMatchedWorks(): Observable<CompanyView[]> {
		const url = `${this.companiesUrl}/FirstOfMatchedWorks`;
		return this.http.get<CompanyView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => CompanyService.parseDates(x))),
			);
	}

	query(query: string): Observable<RusProfileResponse> {
		const url = `${this.companiesUrl}/Query/${query}`;
		return this.http.get<RusProfileResponse>(url, this.modelService.httpOptions);
	}

	import(query: string): Observable<CompanyView[]> {
		if (query === null || query.length === 0)
			return of([]);
		const url = `${this.companiesUrl}/Import/${query}`;
		return this.http.get<CompanyView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => CompanyService.parseDates(x))),
			);
	}

	attach(id: number, tid: number): Observable<any> {
		const url = `${this.companiesUrl}/Attach/${id}/${tid}`;
		return this.http.get<any>(url, this.modelService.httpOptions);
	}

	private prepareFilter(companiesFilter: CompaniesFilter): CompaniesFilter {
		Object.keys(companiesFilter).forEach(key => {
			if (!['specTag', 'ratingMoreThen', 'industriesIds'].some(x => x === key) && companiesFilter[key]) {
				companiesFilter[key] = '%' + companiesFilter[key] + '%';
			}
		});
		return companiesFilter;
	}

	/**
	 * Возвращает список всех специалистов, соответствующих критериям фильтра
	 * @param companiesFilter
	 * @param sort
	 * @param pageIndex
	 * @param pageSize
	 */
	all(companiesFilter: CompaniesFilter,
		sort = null,
		pageIndex = 0,
		pageSize = 0): Observable<CompanyView[]> {
		const url = `${this.companiesUrl}/All`;
		return this.http.post<CompanyView[]>(url, this.prepareFilter(companiesFilter), {
				params: new HttpParams()
					.set('sort', sort)
					.set('pageIndex', pageIndex.toString())
					.set('pageSize', pageSize.toString()),
				headers: this.modelService.httpOptions.headers
			})
			.pipe(
				map(xx => xx.map(x => CompanyService.parseDates(x)))
			);
	}

	allCount(companiesFilter: CompaniesFilter): Observable<number> {
		const url = `${this.companiesUrl}/AllCount`;
		return this.http.post<number>(url, this.prepareFilter(companiesFilter), this.modelService.httpOptions);
	}

	original(id: number): Observable<any> {
		const url = `${this.companiesUrl}/Original/${id}`;
		return this.http.get(url, {
			headers: this.modelService.downloadImageHttpHeaders,
			observe: 'response',
			responseType: 'blob'
		});
	}

	updateLogo(id: number, request: ImageRequest): Observable<CompanyView> {
		const url = `${this.companiesUrl}/UpdateLogo/${id}`;
		return this.http.post<CompanyView>(url, request, this.modelService.httpOptions);
	}

	deleteLogo(id: number): Observable<CompanyView> {
		const url = `${this.companiesUrl}/DeleteLogo/${id}`;
		return this.http.get<CompanyView>(url, this.modelService.httpOptions);
	}

	logo(id: number | string): Observable<Blob> {
		return this.http.get<Blob>(`${this.companiesUrl}/Logo/${id}`, {
			headers: this.modelService.downloadImageHttpHeaders,
			observe: 'response',
			// @ts-ignore
			responseType: "arraybuffer"
		}).pipe(map(buffer => new Blob([buffer])))
	}

	subscribe(id: number): Observable<any> {
		const url = `${this.companiesUrl}/Subscribe/${id}`;
		return this.http.get<any>(url, this.modelService.httpOptions);
	}

	unsubscribe(id: number): Observable<any> {
		const url = `${this.companiesUrl}/Unsubscribe/${id}`;
		return this.http.get<any>(url, this.modelService.httpOptions);
	}

	canUpdate(id: number): Observable<boolean> {
		const url = `${this.companiesUrl}/CanUpdate/${id}`;
		return this.http.get<boolean>(url, this.modelService.httpOptions);
	}

	static logoColor(companyView: CompanyView): string {
		return stringToHslColor(companyView.name, 30, 80);
	}

	public static stateColor(companyStateView: CompanyStateView): string {
		if (companyStateView == null) {
			return null;
		}
		switch (companyStateView.value) {
			case CompanyStateValue.draft:
				return 'coral';
			case CompanyStateValue.active:
				return 'mediumseagreen';
			case CompanyStateValue.cancel:
				return 'lightgray';
			default:
				return null;
		}
	}
}
