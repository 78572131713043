import { Component, HostListener, Input, OnInit } from "@angular/core";
import { BehaviorSubject, takeUntil } from "rxjs";
import { CompanyService } from "@core/services/company.service";
import { DestroyService } from "@profdepo-ui/core";
import { CompanyView } from "@models/company-view";

@Component({
	selector: "pdw-companies-card",
	templateUrl: "./companies-card.component.html",
	host: { class: "pd-expanded-height" },
	providers: [DestroyService],
})
export class CompaniesCardComponent implements OnInit {
	@Input() companiesList: CompanyView[];
	pageIndex = 0;
	pageSize = 30;
	remain = new BehaviorSubject<number>(null);
	loadingData = new BehaviorSubject<boolean>(false);
	loadingNextPage = new BehaviorSubject<boolean>(false);
	@HostListener("window:scroll", [])
	onScroll(): void {
		let scrollHeight = Math.max(
			document.body.scrollHeight,
			document.documentElement.scrollHeight,
			document.body.offsetHeight,
			document.documentElement.offsetHeight,
			document.body.clientHeight,
			document.documentElement.clientHeight
		);
		if (window.scrollY >= scrollHeight - document.body.offsetHeight) {
			this.goNextPage();
		}
	}

	constructor(
		private companyService: CompanyService,
		private destroy$: DestroyService
	) {}

	ngOnInit(): void {
		this.loadingData.next(true);
	}


	public goNextPage(): void {
		if (this.loadingNextPage.value) {
			return;
		}

		this.loadingNextPage.next(true);
		this.pageIndex++;
		this.companyService
			.all(
				{
					any: null,
					specTag: 1,
					ratingMoreThen: null,
					industriesIds: null,
				},
				"name,asc",
				this.pageIndex,
				30
			).pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (data) => {
					this.companiesList.push(...data);
					this.loadingNextPage.next(false);
					this.remain.next(data.length);
				},
				error: (error) => {
					console.error(error);
					this.loadingNextPage.next(false);
				},
			});
	}
}
