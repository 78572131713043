import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'pdw-steps',
	templateUrl: './steps.component.html',
	styleUrls: ['./steps.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepsComponent {
	@Input() stepCount: number = 0;
	@Input() currentStep: number = 0;
	protected readonly Array = Array;

}
