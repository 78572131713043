import {
	Component,
	Inject,
	OnInit,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WorkView } from '@models/work-view';
import { AbstractComponentDirective } from '@shared/abstract-component.directive';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AppUserService } from '@core/services/app-user.service';
import { HeaderService } from '@core/services/header.service';
import { OAuthService } from '@core/services/oauth.service';
import { environment } from '@env/environment';
import { AppUserType } from '@models/app-user-view';
import { AuthPipe } from '@public/pipes/auth.pipe';
import { RegistrationRequest } from '@requests/registration-request';
import { checkPasswords } from '@utils/check-passwords';
import { PASSWORD_REGEX, emailRegEx } from '@utils/constants';
import { ConfirmPassMatcher } from '@utils/errors/confirm-pass-matcher';
import { filloutFormError } from '@utils/form-helper';
import { errorTitle } from '@utils/helpers/error-helpers';
import { removeNone, removeZero } from '@utils/helpers/filter-helpers';
import { filter, finalize, switchMap, takeUntil } from 'rxjs';
import { TrueLoadingService } from '@core/services/true-loading.service';
import { Location } from '@angular/common';
import { AuthService } from '@core/services/auth.service';
import { LoginRequest } from '@requests/login-request';
import { AppUserRoleHintHelper } from '@utils/helpers/app-user-role-hint-helper';
import { RoleService } from '@core/services/role.service';
import { LoadingService } from '@core/services/loading.service';
import { pushFakeHistoryState } from '@utils/functions';

enum AuthMode {
	Register,
	Login,
}

@Component({
	selector: 'pdw-auth-dialog',
	templateUrl: './auth-dialog.component.html',
	styleUrls: ['./auth-dialog.component.scss'],
	providers: [DestroyService, LoadingService],
})
export class AuthDialogComponent extends AbstractComponentDirective implements OnInit {
	authMode = AuthMode.Register;
	openAuthProviders = [
		{ name: 'Yandex', title: 'Яндекс', icon: 'social-yandex' },
		{ name: 'Vk', title: 'ВКонтакте', icon: 'social-vk' },
		// { name: 'Google', title: 'Google', icon: 'social-google' },
	];
	authPipe = new AuthPipe();
	passwordHide = true;
	passwordConfirmHide = true;
	registrationForm: FormGroup;
	appUserTypes = AppUserType;
	checkPasswords = checkPasswords;

	matcher = new ConfirmPassMatcher();
	afterConfirmationEmail: boolean = false;
	afterResetPassword: boolean = false;

	loginForm: FormGroup;
	id: string = null;
	afterForgotPassword: boolean = false;
	appUserRoleHintHelper: AppUserRoleHintHelper;

	constructor(
		private formBuilder: FormBuilder,
		private appUserService: AppUserService,
		private notificationService: NotificationService,
		private router: Router,
		private headerService: HeaderService,
		private oAuthService: OAuthService,
		private destroy$: DestroyService,
		@Inject(PASSWORD_REGEX) regex: string,
		public dialogRef: MatDialogRef<any, boolean | WorkView>,
		public loading$: TrueLoadingService,
		private location: Location,
		private authService: AuthService,
		public roleService: RoleService
	) {
		super();
		this.registrationForm = this.formBuilder.group(
			{
				email: ['', [
					Validators.required,
					Validators.pattern(emailRegEx),
				]],
				password: ['', [
					Validators.required,
					Validators.maxLength(20),
					Validators.pattern(regex)
				]],
				passwordConfirm: ['', [
					Validators.required,
					Validators.minLength(8),
				]],
				type: [AppUserType.specialist, [Validators.required]],
				captcha: ['', [Validators.required]],
			},
			{
				validators: [this.checkPasswords],
			}
		);

		this.loginForm = this.formBuilder.group({
			userName: ['', [
				Validators.required,
				Validators.minLength(4),
				Validators.pattern(emailRegEx)
			]],
			password: ['', [
				Validators.required,
				Validators.minLength(8),
			]],
		});

		loading$.next(false);
	}

	ngOnInit(): void {

		if (history.state.afterResetPassword) {
			this.afterResetPassword = history.state.afterResetPassword;
		}
		if (history.state.afterConfirmationEmail) {
			this.afterConfirmationEmail = history.state.afterConfirmationEmail;
		}
		removeNone(this.appUserTypes);
		removeZero(this.appUserTypes, 'administrator');

		this.headerService.changeCurrentTitle('Регистрация');

		this.id = localStorage.getItem('redirectTo');
		if (history.state.afterResetPassword) {
			this.afterResetPassword = history.state.afterResetPassword;
		}
		if (history.state.afterConfirmationEmail) {
			this.afterConfirmationEmail = history.state.afterConfirmationEmail;
		}

		this.headerService.changeCurrentTitle('Вход');

		if (history.state.afterForgotPassword) {
			this.afterForgotPassword = history.state.afterForgotPassword;
		}
	}

	compareEnum(t1: any, t2: any): boolean {
		return +t1 === t2;
	}

	get email(): AbstractControl {
		return this.registrationForm.get('email');
	}

	get password(): AbstractControl {
		return this.registrationForm.get('password');
	}

	get passwordConfirm(): AbstractControl {
		return this.registrationForm.get('passwordConfirm');
	}

	get type(): AbstractControl {
		return this.registrationForm.get('type');
	}

	get userName() {
		return this.loginForm.get('userName');
	}

	get passwordLogin() {
		return this.loginForm.get('password');
	}

	get captchaField() {
		return this.loginForm.get('captcha');
	}

	onSubmitRegister({ value, valid }): void {
		if (valid) {
			this.appUserService
				.registration(this.registrationForm.value as RegistrationRequest)
				.subscribe({
					next: () => {
						this.notificationService.showSuccess('Пользователь успешно зарегистрирован');
						this.authMode = AuthMode.Login;
					},
					error: (err) => {
						if (err instanceof HttpErrorResponse) {
							if (err.status === 400) {
								filloutFormError(this.registrationForm, err);
							} else {
								this.notificationService.showDanger(errorTitle(err));
							}
						}
					},
				});
		}
	}

	goBack(): void {
		this.location.back();
	}

	onSubmitLogin({ value, valid }): void {
		if (valid) {
			this.loading$.next(true);
			this.authService.login(this.loginForm.value as LoginRequest).pipe(
				switchMap(() => this.appUserService.getActiveUserView()
					.pipe(
						filter(x => x !== null),
						takeUntil(this.destroy$)
					))
			).subscribe({
				next: (appUserView) => {
					this.loading$.next(false);
					this.appUserRoleHintHelper = new AppUserRoleHintHelper(this.appUserService);

					switch (appUserView.type) {
						case AppUserType.administrator: {
							this.dialogRef.close(true);
							break;
						}
						case AppUserType.client: {
							this.dialogRef.close(true);
							break;
						}

						case AppUserType.specialist: {
							this.dialogRef.close(true);
							break;
						}
						default: {
							this.dialogRef.close(true);						}
					}
					localStorage.removeItem('redirectTo');
					this.appUserRoleHintHelper.initAfterAuth()
				},
				error: (err) => {
					this.loading$.next(false);
					if (err instanceof HttpErrorResponse) {
						if (err.status === 400) {
							filloutFormError(this.loginForm, err);
						} else {
							this.notificationService.showDanger(errorTitle(err));
						}
					}
				}
			});
		}
	}

	get isProduction(): boolean {
		return environment.production;
	}

	goOAuthLink(provider: string): void {
		this.oAuthService
			.oauthLink(provider)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (data: string) => {
					window.location.href = data;
				},
				error: (err) => {
					console.warn('err: ', err);
				},
			});
	}

	onGoLogin() {
		this.authMode = AuthMode.Login;
	}

	onGoRegister() {
		this.authMode = AuthMode.Register;
	}
}
