<ng-container *ngIf='data.workMarkView as workMarkView'>
	<div class='pd-dialog-header'>
		<p class='pd-dialog-header-title'>{{ data.title }}</p>
		<button
			class='pd-icon-button'
			mat-icon-button
			[mat-dialog-close]='false'
		>
			<mat-icon svgIcon='interface-close'></mat-icon>
		</button>
	</div>
	<tui-scrollbar class='pd-scrollbar pd-dialog-scroll'>
		<div class='pd-dialog-content pd-dialog-content-small-gap'>
			<p class='pd-dialog-subtitle'>
				{{'work' | workExecutorMark:'propertyTitle'}}
			</p>
			<pdw-work-state
				[value]='workMarkView.work.state === WorkState.successed ? WorkState.abstractFinished : workMarkView.work.state'
				[style]="'width: fit-content'"
			>
			</pdw-work-state>
			<p
				class='pd-dialog-font-14r'
				[innerHTML]='data.workMarkView.work.title | appPlainTextFormat'
			></p>
			<p class='pd-dialog-subtitle'>
				{{'mark' | workExecutorMark:'propertyTitle'}}
				<app-rating-one-small
					*ngIf='utilsService.mobileQuery980px | async'
					[useInitValue]='true'
					[percent]='averageMark()'
				>
				</app-rating-one-small>
			</p>
			<div class='pd-dialog-mark-rating'>
				<div class='pd-profile-rating-row'>
					<app-rating-stars
						[showRating]='false'
						[stars]='workMarkView.speedValue'
						[rating]='workMarkView.speedValue'
					>
					</app-rating-stars>
					<p class='pd-profile-text'>
						{{ 'speedValue' | workMark:'propertyTitle' }}
					</p>
				</div>
				<div class='pd-profile-rating-row'>
					<app-rating-stars
						[showRating]='false'
						[stars]='workMarkView.targetryValue'
						[rating]='workMarkView.targetryValue'
					>
					</app-rating-stars>
					<p class='pd-profile-text'>
						{{ 'targetryValue' | workMark:'propertyTitle' }}
					</p>
				</div>
				<div class='pd-profile-rating-row'>
					<app-rating-stars
						[showRating]='false'
						[stars]='workMarkView.adequacyValue'
						[rating]='workMarkView.adequacyValue'
					>
					</app-rating-stars>
					<p class='pd-profile-text'>
						{{ 'adequacyValue' | workMark:'propertyTitle' }}
					</p>
				</div>
				<app-rating-one-big
					*ngIf='!(utilsService.mobileQuery980px | async)'
					class='sm'
					[rating]='averageMark()'
				>
				</app-rating-one-big>
			</div>
			<p class='pd-dialog-subtitle'>
				Отзыв от {{workMarkView.modifyTime | date:'dd.MM.YYYY'}}
			</p>
			<p class='pd-dialog-font-15r'>
				{{workMarkView.comment ? workMarkView.comment : 'Комментарий не указан'}}
			</p>
		</div>
	</tui-scrollbar>
</ng-container>

