import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { WorkMarkView } from '@models/work-mark-view';
import { AppUserView } from '@models/app-user-view';
import { YesNo } from '@models/enums';
import { pushFakeHistoryState } from '@utils/functions';
import { WorkMarkCardDialogComponent } from '@shared/work-mark-card-dialog/work-mark-card-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DestroyService } from '@profdepo-ui/core';
import { LightModeWorkMarkView } from '@models/light-mode-work-mark-view';
import { ShortWorkMarkCardDialogComponent } from '@shared/short-work-mark-card-dialog/short-work-mark-card-dialog.component';

@Component({
	selector: 'pdw-work-mark-card',
	templateUrl: './work-mark-card.component.html',
	host: { 'class': 'pd-marks-card' },
	styleUrls: ['./work-mark-card.component.scss'],
	providers: [DestroyService]
})
export class WorkMarkCardComponent implements OnInit {
	@Input() isToShowFullMode = false;
	@Input()
	set workMarkView(value: WorkMarkView) {
		this._workMarkView.next(value);
	};

	get workMarkView(): WorkMarkView {
		return this._workMarkView.getValue();
	}

	private _workMarkView = new BehaviorSubject<WorkMarkView>(null);
	currentWorkMarkView = new BehaviorSubject<WorkMarkView>(null);
	currentLighTModeWorkMarkView = new BehaviorSubject<LightModeWorkMarkView>(null);

	constructor(private dialog: MatDialog, private destroy$: DestroyService) {
	}

	ngOnInit(): void {
		this._workMarkView
			.pipe(
				takeUntil(this.destroy$),
				filter(x => x !== null)
			)
			.subscribe({
				next: v => {
					this.currentWorkMarkView.next(v);
					if (!this.isToShowFullMode) {
						this.currentLighTModeWorkMarkView.next(v as unknown as LightModeWorkMarkView);
					}
				}
			});
	}

	userHasAvatar(appUser: AppUserView): boolean {
		return appUser.hasAvatar === YesNo.yes;
	}

	openDialog(workMarkView: WorkMarkView): void {
		pushFakeHistoryState();

		this.dialog.open(WorkMarkCardDialogComponent, {
			autoFocus: true,
			disableClose: false,
			width: '581px',
			panelClass: ['pd-dialog', 'pd-dialog-without-footer'],
			data: {
				title: 'Отзыв о работе',
				workMarkView: workMarkView
			}
		});
	}

	openLightModeDialog(workMarkView: LightModeWorkMarkView): void {
		pushFakeHistoryState();
		this.dialog.open(ShortWorkMarkCardDialogComponent, {
			autoFocus: true,
			disableClose: false,
			width: '581px',
			panelClass: ['pd-dialog', 'pd-dialog-without-footer'],
			data: {
				title: 'Отзыв о работе',
				workMarkView: workMarkView
			}
		});
	}
}
