<ng-container *ngIf='loading$ | async'>
	<ng-container *ngIf='((count | async) == 0 && (unread | async) == 0); else hasData'>
		<div class='pd-notifications-dialog-header-empty'>
			<button
				class='pd-icon-button'
				mat-icon-button
				[mat-dialog-close]='false'
			>
				<mat-icon svgIcon='interface-close'></mat-icon>
			</button>
		</div>
		<div class='pd-dialog-content without-padding-top pd-dialog-content-center'>
			<div class='pd-notifications-dialog-no-data without-padding'>
				<mat-icon
					class='pd-notifications-dialog-icon'
					svgIcon='additional-check-rectangle-big'
				></mat-icon>
				<p class='pd-notifications-dialog-title-empty'>Уведомлений пока нет</p>
				<p class='pd-notifications-dialog-subtitle-empty'>Скоро они появятся здесь</p>
			</div>
		</div>
	</ng-container>
	<ng-template #hasData>
		<div class='pd-dialog-header'>
			<p class='pd-dialog-header-title'>Уведомления</p>
			<button
				class='pd-icon-button'
				mat-icon-button
				[mat-dialog-close]='false'
			>
				<mat-icon svgIcon='interface-close'></mat-icon>
			</button>
		</div>
		<div class='pd-notifications-dialog-toolbar'>
			<mat-button-toggle-group
				class='pd-button-toggle-group'
				(change)='onChange($event)'
				value='{{notificationState}}'
			>
				<mat-button-toggle
					class='pd-button-toggle'
					value='0'
				>
					<mat-icon
						class='pd-button-toggle-icon'
						svgIcon='additional2-fire'
					></mat-icon>
					Актуальные
				</mat-button-toggle>
				<mat-button-toggle
					class='pd-button-toggle'
					value='1'
				>
					<mat-icon
						class='pd-button-toggle-icon'
						svgIcon='additional2-menu'
					></mat-icon>
					Хронология
				</mat-button-toggle>
			</mat-button-toggle-group>
		</div>
		<tui-scrollbar class='pd-scrollbar pd-dialog-scroll'>
			<div
				class='pd-dialog-content without-padding-top'
				[ngSwitch]='notificationState'
			>
				<ng-container *ngSwitchCase='0'>
					<pdw-notifications-table>
					</pdw-notifications-table>
				</ng-container>

				<ng-container *ngSwitchCase='1'>
					<pdw-notifications-chronology>
					</pdw-notifications-chronology>
				</ng-container>
			</div>
		</tui-scrollbar>
	</ng-template>
</ng-container>
