import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-chip, [app-chip]',
	templateUrl: './chip.component.html',
	styleUrls: ['./chip.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipComponent {
	@Input() chipData!: string | number;
	@Output() deleteChip = new EventEmitter();

	delete() {
		this.deleteChip.emit();
	}

}
