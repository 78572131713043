import { Pipe, PipeTransform } from '@angular/core';
import {AbstractExtensionEnumPipe} from "@public/pipes/abstract-extension-enum.pipe";

@Pipe({
  name: 'workHistoryState'
})
export class WorkHistoryStatePipe extends AbstractExtensionEnumPipe implements PipeTransform {
  values = {
    0: "Черновик",
    1: "Пропущено",
    2: "Принято",
    3: "Отклонено"
  };
  transform(value: any, ...args: any[]): any {
    return this.getTransform(value, args);
  }
}
