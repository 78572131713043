import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, } from '@angular/core';
import { AppUserService } from '@core/services/app-user.service';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppUserView } from '@models/app-user-view';
import { errorTitle } from '@utils/helpers/error-helpers';
import { Router } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { WorksSelectDialogComponent } from '@shared/works-select-dialog/works-select-dialog.component';
import { pushFakeHistoryState } from '@utils/functions';
import { YesNo } from '@models/enums';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { AuthService } from '@core/services/auth.service';
import { AuthDialogComponent } from '@shared/auth-dialog/auth-dialog.component';
import { diffDate } from '@utils/helpers/date-helpers';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { checkEmptyUserInfo } from '@utils/helpers/user-helper';
import { ImageAvatarSize } from '@shared/components/ui/image-avatar/image-avatar.types';

@Component({
	selector: 'pdw-user-card-redesign',
	templateUrl: './app-user-card-redesign.component.html',
	styleUrls: ['./app-user-card-redesign.component.scss'],
	host: { 'class': 'pd-user-card' },
	providers: [DestroyService]
})
export class AppUserCardRedesignComponent implements OnInit {
	currentAppUserView = new BehaviorSubject<AppUserView>(null);
	protected readonly YESNO = YesNo;
	@Input() isShowInviteWorkBtn = false;

	@Input() isActiveUserClient: boolean;

	@Input()
	set AppUserView(value: AppUserView) {
		this._AppUserView.next(value);
	};

	get AppUserView(): AppUserView {
		return this._AppUserView.getValue();
	}

	private _AppUserView = new BehaviorSubject<AppUserView>(null);
	@Output() changeEmitter = new EventEmitter<any>();
	workStateColorClass: (value: number) => {};

	constructor(
		private router: Router,
		private appUserService: AppUserService,
		private notificationService: NotificationService,
		private dialog: MatDialog,
		private destroy$: DestroyService,
		private authService: AuthService,
		@Inject(LOCALE_ID) public locale: string,
	) {
	}

	ngOnInit(): void {
		this._AppUserView
			.pipe(
				filter(x => x !== null),
				takeUntil(this.destroy$)
			)
			.subscribe((AppUserView: AppUserView) => {
				this.currentAppUserView.next(AppUserView);
			});

		this.workStateColorClass = AppUserService.WorkStateColorClass;
	}

	isAuth(): boolean {
		return this.authService.isLoggedIn();
	}

	checkAuthBeforGoInviteWork(event: Event, appUserView: AppUserView) {
		event.stopPropagation();
		if (this.authService.isLoggedIn()) {
			this.goInviteWork(event, appUserView);
		} else {
			this.dialog.open(AuthDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: ['auth-dialog'],
			}).afterClosed().pipe(filter(Boolean)).subscribe(() => {
				localStorage.setItem('currentHref', window.location.href);
				this.goInviteWork(event, appUserView)
			});
		}
	}

	goInviteWork(event: Event, appUserView: AppUserView): void {
		event.stopPropagation();
		pushFakeHistoryState();
		this.dialog.open(WorksSelectDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				closeOnNavigation: false,
				data: {
					title: 'Приглашение на работу',
					appUserView: appUserView
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
			)
			.subscribe({
				next: () => {
					this.notificationService.showSuccess('Приглашение на работу для ' + appUserView.title + ' отправлено!');
					this.changeEmitter.emit(this.AppUserView);
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	/**
	 * Обрабатывает нажатие средней кнопкой мыши на карточке специалиста
	 * @param appUserView
	 * @param event
	 */
	onMouseUp(appUserView: AppUserView, event: MouseEvent): void {
		if (event.button === 1) {
			this.router.navigate([])
				.then(() => {
					window.open('/users/' + appUserView.id, '_blank');
				});
		}
	}

	onMouseDown(event: MouseEvent): void {
		if (event.button === 1) {
			event.preventDefault();
		}
	}

	/**
	 * Обрабаывает нажатие левой кнопкой мыши на меню карточки (блокирует переход)
	 * @param event
	 * @param menuTriggerButton
	 */
	onMoreClick(event: MouseEvent, menuTriggerButton: MatButton): void {
		menuTriggerButton._elementRef.nativeElement.closest('.pd-user-card').classList.add('focused');
		event.stopPropagation();
	}

	/**
	 * Сбрасывает класс focused у карточки, после закрытия меню
	 * @param menuTriggerButton
	 */
	onCloseMenu(menuTriggerButton: MatButton): void {
		menuTriggerButton._elementRef.nativeElement.closest('.pd-user-card').classList.remove('focused');
	}

	isUserOnline(appUserView: AppUserView): boolean | null {
		if (appUserView.lastConnection) {
			const delta = (new Date().getTime() - appUserView.lastConnection.getTime()) / 1000 / 60;
			return delta < 5;
		}
		return null;
	}

	checkAuthBeforeAddToFavorites(event: Event, appUserView: AppUserView) {
		event.stopPropagation();
		if (this.authService.isLoggedIn()) {
			this.addToFavorites(event, appUserView);
		} else {
			this.dialog.open(AuthDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: ['auth-dialog'],
			}).afterClosed().pipe(filter(Boolean)).subscribe(() => {
				this.addToFavorites(event, appUserView);
				localStorage.setItem('currentHref', window.location.href);
				window.location.reload();
			});
		}
	}

	addToFavorites(event: Event, appUserView: AppUserView): void {
		event.stopPropagation();
		this.appUserService.addToFavorites(appUserView.id)
			.pipe(
				takeUntil(this.destroy$)
			).subscribe({
			next: () => {
				this.AppUserView.favorite = YesNo.yes;
			}
		});
	}

	checkAuthBeforeRemoveFromFavorites(event: Event, appUserView: AppUserView) {
		event.stopPropagation();
		if (this.authService.isLoggedIn()) {
			this.removeFromFavorites(event, appUserView);
		} else {
			this.dialog.open(AuthDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: ['auth-dialog'],
			}).afterClosed().pipe(filter(Boolean)).subscribe(() => {
				localStorage.setItem('currentHref', window.location.href);
				this.removeFromFavorites(event, appUserView)
			});
		}
	}

	removeFromFavorites(event: Event, appUserView: AppUserView): void {
		event.stopPropagation();
		this.appUserService.removeFromFavorites(appUserView.id)
			.pipe(
				takeUntil(this.destroy$)
			).subscribe({
			next: () => {
				this.appUserService.shouldReload.next(true);
				this.AppUserView.favorite = YesNo.no;
			}
		})
	}

	avatarColor(appUserView: AppUserView): string {
		return AppUserService.avatarColor(appUserView);
	}

	appUserAge(birthday: any): string {
		if (!birthday) {
			return 'возраст не указан';
		}
		const diff = diffDate(new Date(Date.now()), birthday);
		const datePipe = new DatePipe(this.locale);
		return [
			diff.years > 1 ? null : 1,
			moment.duration(diff.years, 'year').locale(this.locale).humanize()
		].join(' ');
	}

	getSalary(appUserView) {
		return appUserView.preferredReward;
	}

	getUserTitle(appUserView: AppUserView) {
		return checkEmptyUserInfo(appUserView, false);
	}

	protected readonly IMAGE_SIZE = ImageAvatarSize;
}
