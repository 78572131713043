<button
	(click)='openAIRateModal($event)'
	class='card-ai-container'
>
	<div class='card-ai-title'>
		<mat-icon svgIcon='logo-profdepo-ai'></mat-icon>
		<p>
			Умный подбор от ProfdepoAI
		</p>
	</div>

	<div
		*ngIf='user?.specialistProfit !== undefined'
		class='card-ai-rate'
	>
		<p>
			Специалист подходит на <span
			pdwRatingTheme
			[percentage]='user.specialistProfit'
		>{{user.specialistProfit}}%</span>
		</p>
		<pdw-progress-bar
			[depsColorOnValue]='true'
			[value]='user.specialistProfit'
		>
		</pdw-progress-bar>
	</div>
</button>
