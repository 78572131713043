<div class='pd-side-menu-header'>
	<a
		class='pd-side-menu-header-link'
		[routerLink]="['/']"
	>Profdepo<span>.work</span></a>
</div>
<tui-scrollbar class='pd-scrollbar pd-side-menu-scroll'>
	<div class='pd-side-menu-mobile-header'>
		<a class='pd-side-menu-mobile-header-link' [routerLink]="['/']">Profdepo<span>.work</span></a>
		<button
			class='pd-icon-button'
			mat-icon-button
			(click)='closeMenu()'
		>
			<mat-icon svgIcon='interface-close'></mat-icon>
		</button>
	</div>
	<div
		class='pd-side-menu'
		[ngClass]="{'pd-side-menu-fixed': sideMenuFixed && !mobileMenu, 'pd-side-menu-mobile': mobileMenu}"
	>
		<mat-nav-list [ngClass]="{'pd-side-menu-list': !mobileMenu, 'pd-side-menu-header': !mobileMenu}">
			<a
				mat-list-item
				*ngIf='isLoggedIn()'
				routerLinkActive='pd-side-menu-profile-active'
				[routerLink]="['/profile']"
				class='pd-side-menu-profile'
			>
				<ng-container *ngIf='(activeAppUserView$ | async) as user'>
					<div
						class='pd-side-menu-profile-img-wrapper'
						[style.background-color]='avatarColor(user)'
					>
						<ng-container *ngIf='user?.hasAvatar; else noAvatar'>
							<img
								class='pd-side-menu-profile-img'
								[src]='user | avatar | async'
								alt='Avatar Image'
							/>
						</ng-container>
						<ng-template #noAvatar>
							<pdw-user-initial-letters
								[user]='user'
								[canUpdate]='false'
								[size]='IMAGE_SIZE.SMALL'
							>
							</pdw-user-initial-letters>
						</ng-template>
					</div>
					<div class='pd-side-menu-profile-wrapper'>
						<span class='pd-side-menu-link-text'>
							{{ showUserName(user)}}
						</span>
						<span class='pd-side-menu-link-text pd-side-menu-link-text-accent'>
							{{ user.type | appUserType }}
						</span>
					</div>
				</ng-container>
			</a>

			<ng-container *ngFor='let item of currentMenuItems'>
				<ng-template #accordionLink>

					<pdw-menu-accordion-link
						class='menu-accordion'
						[menuItem]='item'
						*ngIf='!item.linkTo && item.children && hasPermission(item.permissions)'
					>
					</pdw-menu-accordion-link>
				</ng-template>
				<ng-container *ngIf='item.linkTo && !item.children; else accordionLink'>
					<a
						class='pd-side-menu-item'
						mat-list-item
						routerLinkActive='pd-side-menu-link-active'
						#rla='routerLinkActive'
						*ngIf='hasPermission(item.permissions ?? AppUserType.none)'
						[routerLink]='item.linkTo'
					>
						<ng-container *ngIf="item.icon === 'additional2-chat'; else notRequests">
							<mat-icon
								class='pd-icon'
								[svgIcon]='item.icon'
								[color]="rla.isActive ? 'primary' : null "
							>
							</mat-icon>
							<span class='pd-side-menu-link-text'>
								{{item.text}}
								<span
									class='pd-badge pd-badge-active'
									*ngIf='activeWorkRequestCounts | async as counts'
								>
									{{counts}}
								</span>
							</span>
						</ng-container>

						<ng-template #notRequests>
							<mat-icon
								class='pd-icon'
								[svgIcon]='item.icon'
								[color]="rla.isActive ? 'primary' : null "
							>
							</mat-icon>
							<span
								class='pd-side-menu-link-text'
							>
								{{item.text}}
							</span>
						</ng-template>
					</a>
				</ng-container>
			</ng-container>
			<ul class='aggregator-list' *ngIf="hasPermission([AppUserType.specialist, AppUserType.none])">
				<a
					routerLinkActive='aggregator-list-item-active'
					routerLink='/careerConsultant'
				>
					<mat-icon
						class='pd-icon'
						svgIcon='alt-stat'
					>
					</mat-icon>
					<span>
						Карьерный консультант
					</span>
				</a>
			</ul>
		</mat-nav-list>
		<mat-nav-list class='pd-side-menu-list pd-side-menu-footer'>
			<button
				*ngIf='isLoggedIn()'
				class='pd-side-menu-item'
				mat-list-item
				routerLinkActive='pd-side-menu-link-active'
				#rla='routerLinkActive'
				(click)='logout()'
			>
				<mat-icon
					[svgIcon]="'additional2-sign-out-circle'"
					class='pd-icon'
					[color]="rla.isActive ? 'primary' : null "
				></mat-icon>
				<span class='pd-side-menu-link-text'>Выход</span>
			</button>
		</mat-nav-list>
	</div>
</tui-scrollbar>

