<mat-sidenav-container class='pd-container'>
	<mat-sidenav
		#sideMenu
		[mode]="mobileQuery980px ? 'over' : 'side'"
		[opened]='!mobileQuery980px'
	>
		<pdw-side-menu [mobileMenu]='mobileQuery980px'></pdw-side-menu>
	</mat-sidenav>
	<mat-sidenav-content
		[ngClass]="{'pd-mobile-drawer-content': mobileQuery980px}"
	>
		<div class='pd-expanded-height'>
			<pdw-market-header
				class='app-header'
				[ngClass]="{'app-header-mobile-content': mobileQuery980px,
          'hidden': (mobileQuery980px && sideMenu.opened) || !(hasScrolledMainPage$ | async) , 'scrolled': isVisibleHeader$ | async}"
				[mobileMenu]='mobileQuery980px'
				[sideMenuRef]='sideMenu'
			></pdw-market-header>
			<router-outlet></router-outlet>
		</div>
	</mat-sidenav-content>
</mat-sidenav-container>

