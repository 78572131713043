<ng-container *ngIf="currentCompanyView | async as currentCompany">
  <div class="pd-profile-rating-content" [ngClass]="{'no-rating': !(currentCompany.ratingPercent > 0)}">
    <div class="pd-profile-inner-column">
      <ng-container *ngIf="currentCompany.ratingPercent > 0 else noRating">
        <h2 class="pd-profile-rating-title pd-profile-title">Рейтинг
          <app-rating-one-small class="pd-profile-rating-one-small"
                                [percent]="currentCompany.ratingPercent">
          </app-rating-one-small>
        </h2>
        <div class="pd-profile-rating-row">
          <app-rating-stars
            [showRating]="false"
            [stars]="currentCompany.speedRatingPercent / 100 * 5"
            [rating]="currentCompany.speedRatingPercent">
          </app-rating-stars>
          <p class="pd-profile-text">
            {{ 'speedValue' | workMark:'propertyTitle' }}
          </p>
        </div>
        <div class="pd-profile-rating-row">
          <app-rating-stars
            [showRating]="false"
            [stars]="currentCompany.targetryRatingPercent / 100 * 5"
            [rating]="currentCompany.targetryRatingPercent">
          </app-rating-stars>
          <p class="pd-profile-text">
            {{ 'targetryValue' | workMark:'propertyTitle' }}
          </p>
        </div>
        <div class="pd-profile-rating-row">
          <app-rating-stars
            [showRating]="false"
            [stars]="currentCompany.adequacyRatingPercent / 100 * 5"
            [rating]="currentCompany.adequacyRatingPercent">
          </app-rating-stars>
          <p class="pd-profile-text">
            {{ 'adequacyValue' | workMark:'propertyTitle' }}
          </p>
        </div>
      </ng-container>
      <ng-template #noRating>
        <h2 class="pd-profile-rating-title pd-profile-title">Рейтинг
          <app-rating-one-small class="pd-profile-rating-one-small"
                                [percent]="currentCompany.ratingPercent">
          </app-rating-one-small>
        </h2>
        <h2 class="pd-profile-subtitle pd-profile-no-rating-title">Рейтинга пока нет</h2>
        <p class="pd-profile-text">Скоро он появится здесь</p>
      </ng-template>
    </div>
    <app-rating-one-big class="pd-profile-rating-one-big" [rating]="currentCompany.ratingPercent / 100 * 5">
    </app-rating-one-big>
  </div>
</ng-container>
