<ng-template #search>
    <div [ngClass]="{'wrapper': (isMobile$ | async) && !(isDialogOpen$ | async)}">
        <mat-form-field #formField hideRequiredMarker class='pd-input-form-field' appearance='fill'
            [ngClass]="{'pd-input-form-field-without-border': (isMobile$ | async) && !(isDialogOpen$ | async) }">
            <mat-label>Что ищем?</mat-label>
            <mat-icon class='pd-field-prefix-icon' svgIcon='general-search' matPrefix></mat-icon>
            <input type="text" matInput maxlength="100" [formControl]="searchControl">
            <div class='pd-input-suffix-two-btn' matSuffix>
                <button *ngIf="(isMobile$ | async) && !(isDialogOpen$ | async)"
                    class='pd-icon-button with-line-height gray' mat-icon-button matTooltip='Фильтр'
                    (click)='openFilterDialog()'>
                    <mat-icon class='pd-icon' svgIcon='additional2-filter-alt'></mat-icon>
                </button>
            </div>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #searchFilter>
    <div *ngIf="(isMobile$ | async)" class='mobile-header'>
        Фильтр

        <button class='pd-icon-button' mat-icon-button [mat-dialog-close]='false' type='submit'>
            <mat-icon svgIcon='interface-close'></mat-icon>
        </button>
    </div>
    <form [class.filter-form]="isMobile$ | async" class="pd-complex-filter-form" [formGroup]="form">
        <button [class.reset-btn]="!(isMobile$ | async)" form='form' mat-raised-button class='pd-button-secondary'
            type='button' (click)="onReset()">
            Сбросить
        </button>
        <ng-container *ngTemplateOutlet="search">

        </ng-container>
        <mat-button-toggle-group class='pd-button-toggle-group-divided' (change)="onToggleChange($event)">
            <mat-button-toggle class='pd-button-toggle' value="favorites">Избранное</mat-button-toggle>
            <mat-button-toggle class='pd-button-toggle' value="all" [checked]="true">Все</mat-button-toggle>
        </mat-button-toggle-group>
        <p class='pd-complex-filter-field-title'>
            Сферы деятельности
        </p>

        <mat-form-field class='pd-input-form-field' appearance='fill'>
            <mat-label>Введите сферу деятельности</mat-label>
            <mat-icon class='pd-field-prefix-icon' svgIcon="additional-dashbord" matPrefix></mat-icon>
            <input type="text" matInput [matAutocomplete]='auto' formControlName="industries">

            <mat-autocomplete class='pd-autocomplete' #auto='matAutocomplete' (optionSelected)='selectedItem($event)'>
                <tui-scrollbar class='pd-scrollbar pd-autocomplete-scrollbar'>
                    <mat-option class='pd-option' *ngFor='let item of (industries$ | async)' [value]='item'
                        [disabled]="getDisabled(item)">
                        {{ item.title }}
                    </mat-option>
                </tui-scrollbar>
            </mat-autocomplete>
        </mat-form-field>

        <mat-chip-list aria-label='Skills selection' class='pd-chip-list-wrap pd-chip-list-not-mat'>
            <mat-chip class='pd-chip' *ngFor='let item of selectedIndustriesList.selected' [removable]='true'
                (removed)="onRemove(item)">
                <span class='pd-chip-label'>{{ item.title }}</span>
                <button class='pd-chip-remove-btn' matChipRemove>
                    <mat-icon svgIcon='interface-close-small'></mat-icon>
                </button>
            </mat-chip>
        </mat-chip-list>

        <pd-checkbox formControlName="rating">{{'Рейтинг не менее 4.5'}}</pd-checkbox>
        <p class='pd-complex-filter-field-title'>
            <mat-icon class='pd-icon' svgIcon='interface-check'></mat-icon>
            Подходит {{companiesCount$ | async}} Компаний
        </p>
    </form>
</ng-template>

<ng-container *ngIf="(isMobile$ | async); else desktop">
    <ng-container *ngTemplateOutlet="search"></ng-container>
</ng-container>

<ng-template #desktop>
    <ng-container>
        <ng-container *ngTemplateOutlet="searchFilter"></ng-container>
    </ng-container>
</ng-template>