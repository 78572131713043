import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { WorkView } from '@models/work-view';
import { AppUserService } from '@core/services/app-user.service';

@Directive({
	selector: '[pdwCompanyChecker]'
})
export class CompanyCheckerDirective {

	constructor(private template: TemplateRef<any>, private vcr: ViewContainerRef, private userService: AppUserService) {
	}

	@Input('pdwCompanyChecker') set isOwnerOfCompany(work: WorkView) {
		if (this.userService.getCurrentActiveUserView()?.id === work?.manager?.id) {
			this.vcr.clear();
			this.vcr.createEmbeddedView(this.template);
		} else {
			this.vcr.clear();
		}
	}

}
