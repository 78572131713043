import {Directive, ElementRef, HostBinding, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appAnimationScrollbar]'
})
export class AnimationScrollbarDirective {

  @Input('class-animation') animation: string;

  //За сколько пикселей от ожидаемой компоненты начать воспроизводить анимацию
  @Input() activateFrom: number = 100;

  private isScrolledToElem: boolean = false;
  private isExecuted: boolean = false;
  private domElement: HTMLElement;

  constructor(private elementRef: ElementRef) {
    this.domElement = elementRef.nativeElement as HTMLElement;
  }

  @HostBinding('class') get getAnimationClass() {
    if (this.isScrolledToElem && !this.isExecuted) {
      this.isExecuted = true;
      return this.animation;
    }
    if (!this.isExecuted) {
      return '';
    }
    return this.animation;

  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const componentPosition = this.domElement.offsetTop;
    const scrollPosition = window.pageYOffset;
    this.isScrolledToElem = scrollPosition >= componentPosition - this.activateFrom;
  }

}
