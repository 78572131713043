import { AbstractControl } from '@angular/forms';

export function removeNone<T>(value: T): T {
	delete value['-1'];
	delete value['none'];
	return value;
}

export function removeZero<T>(value: T, name: string): T {
	delete value['0'];
	delete value[name];
	return value;
}

/**
 * Фильтр числовых значений по указанному критерию
 * @param value числовое значение
 * @param filter критерий, который может содержать условия вида >=, <=, >, <, =
 */
export function filterNumber(value: number, filter: string): boolean {
	if (filter === null || filter === undefined || filter === '')
		return true;
	filter = filter.replace(',', '.');
	if (filter.startsWith('>=')) {
		return value >= +filter.substr(2);
	} else if (filter.startsWith('<=')) {
		return value <= +filter.substr(2);
	} else if (filter.startsWith('>')) {
		return value > +filter.substr(1);
	} else if (filter.startsWith('<')) {
		return value < +filter.substr(1);
	} else if (filter.startsWith('=')) {
		return value == +filter.substr(1);
	}
	return value.toString().indexOf(filter) !== -1;
}

/**
 * Фильтр значений по значению идентификатора
 * @param value
 * @param filter
 */
export function filterId(value: number | null, filter: number | null): boolean {
	if (filter === null || filter === undefined)
		return true;
	return value === filter;
}

export function filterContainsId(value: number | null, filter: number[] | null): boolean {
	if (filter === null || filter === undefined)
		return true;
	return filter.some(x => x === value);
}

// export function filterIdInSequence(value: number[], filter: number | null): boolean {
//   if (filter === null || filter === undefined)
//     return true;
//   return value.some(x => x === filter);
// }

// export function filterPersonTitle(value: PersonView | null, filter: string): boolean {
//   if (filter === null || filter === undefined || filter === "")
//     return true;
//   if (value === null)
//     return true;
//   return value.title.toLowerCase().indexOf(filter) !== -1;
// }

// export function filterAppUserTitle(value: AppUserView | null, filter: string): boolean {
//   if (filter === null || filter === undefined || filter === "")
//     return true;
//   if (value === null)
//     return true;
//   return value.title.toLowerCase().indexOf(filter) !== -1;
// }

export function filterString(value: string | null, filter: string | null): boolean {
	if (filter === null || filter === undefined || filter === '')
		return true;
	if (value === null)
		return false;
	return value.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
}

/**
 * Фильтр значений по выбранному из списка
 * @param value
 * @param filter
 */
export function filterEnum<T>(value: T, filter: number | null): boolean {
	console.warn('filterEnum: ', value, filter);
	if (filter === null || filter === undefined || filter < 0)
		return true;
	return +value === +filter;
}

/**
 * Проверка значения выпадающего списка пустое или нет
 * @param control
 */
export function isEmptyValue(control: AbstractControl) {
	if (!control) return false;

	return control.value === null || control.value === '' || (Object.prototype.toString.call(control.value) === '[object Array]' && control.value.length === 0);
}

