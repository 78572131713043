<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content fxLayout="column">
  <div class="pd-dialog-message">
    {{data.message}}
  </div>
  <div [style.max-height.px]="300">
    <table mat-table [dataSource]="dataSource" class="pd-width-full">
      <!-- Checked Column -->
      <ng-container matColumnDef="checked">
        <th mat-header-cell *matHeaderCellDef>

        </th>
        <td mat-cell class="header xxs" *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell
            matTooltip="{{ 'title' | company: 'propertyHint' }}"
            *matHeaderCellDef>
          {{ 'title' | company: 'propertyTitle' }}
        </th>
        <td mat-cell *matCellDef="let element">
          <a [href]="'https://www.rusprofile.ru' + element.url" target="_blank">
            {{ element.name }}
          </a>
        </td>
      </ng-container>

      <!-- Inn Column -->
      <ng-container matColumnDef="inn">
        <th mat-header-cell
            matTooltip="{{ 'inn' | company: 'propertyHint' }}"
            *matHeaderCellDef>
          {{ 'inn' | company: 'propertyTitle' }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.inn }}
        </td>
      </ng-container>

      <!-- Ogrn Column -->
      <ng-container matColumnDef="ogrn">
        <th mat-header-cell
            matTooltip="{{ 'ogrn' | company: 'propertyHint' }}"
            *matHeaderCellDef>
          {{ 'ogrn' | company: 'propertyTitle' }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.ogrn }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns | async"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns | async;"></tr>
    </table>
  </div>
</div>
<div mat-dialog-actions fxLayoutAlign="end" class="pd-dialog-action">
  <button type="button"
          mat-raised-button
          color="primary"
          [disabled]="!selection.hasValue()"
          (click)="onApply()"
          matTooltip="Применить выбранную организацию">
    Применить
  </button>
  <button type="button"
          mat-raised-button
          (click)="onCancel()"
          matTooltip="Отмена">
    Отменить
  </button>
</div>
