import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject, Subject} from "rxjs";
import {AppUserSupportRequest, supportCategories} from "@requests/support-request";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SupportService} from "@core/services/support.service";
import {takeUntil} from "rxjs/operators";
import {AppUserView} from "@models/app-user-view";
import {NotificationService} from "@profdepo-ui/core";

export interface SupportUserDialogData {
  title: string;
  appUserView: AppUserView;
}

@Component({
  selector: 'app-app-user-support-dialog',
  templateUrl: './app-user-support-dialog.component.html',
})
export class AppUserSupportDialogComponent implements OnInit {

  supportForm: FormGroup;
  saving = new BehaviorSubject<boolean>(false);
  unsubscribe: Subject<any> = new Subject<any>();

  categoryItems = supportCategories;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SupportUserDialogData,
    private dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
    private supportService: SupportService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.supportForm = this.formBuilder.group({
      category: new FormControl(supportCategories.Offer),
      description: new FormControl(
        '',
        [Validators.required]
      ),
    });
  }

  get category(): FormControl { return this.supportForm.get('category') as FormControl; }
  get description(): FormControl { return this.supportForm.get('description') as FormControl; }

  compareEnum(t1: any, t2: any): boolean {
    return +t1 === t2;
  }

  onSubmit({value, valid}){
    if(valid){
      this.saving.next(true);


      this.supportService.userRequest(this.data.appUserView.id ,value as AppUserSupportRequest)
        .pipe(
          takeUntil(this.unsubscribe),
        )
        .subscribe((data) => {
            this.saving.next(false);
            this.dialogRef.close(data);
            this.notificationService.showSuccess(data.message);

          },
          (err) => {
            this.saving.next(false);
            this.notificationService.showDanger(err.error);
          }
        );
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }
}
