import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isMoment } from 'moment';
import { diffDate } from '@utils/helpers/date-helpers';

export type CustomValidatorFn = (firstControl: AbstractControl, secondControl: AbstractControl) => ValidationErrors | null;

export class CustomValidators {

	static confirmPassword(passwordControl: FormControl, repeatControl: FormControl): ValidatorFn {
		return (): ValidationErrors | null => {
			if (passwordControl.value === repeatControl.value) {
				return null
			}
			return {confirmPassword: true}
		}
	}
	static costValidator(firstControl: AbstractControl, secondControl: AbstractControl): ValidationErrors | null {
		if (typeof firstControl.value === 'number' && typeof secondControl.value === 'number') {
			if (firstControl.value > secondControl.value) {
				return { error: 'Неверный ввод' }
			}
		}
		return null;
	}

	/**
	 * Валидатор для проверки возраста
	 * @param years - Количество лет (18 лет по дефолту)
	 * @beta
	 */
	static comingOfAgeValidator(years: number = 18): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value) {
				return null;
			}

			const value: Date = isMoment(control.value) ? control.value.toDate() : control.value;
			const diff = diffDate(new Date(Date.now()), value);

			return diff.years >= years ? null : { comingOfAge: true };
		}
	}

	/**
	 * Валидатор, который не дает ставить дату раньше, чем заданная граница
	 * @param value - Крайняя левая граница времени
	 * @beta
	 */
	static dateNotEarlierValidator(value: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value === null) {
				return null;
			}
			return new Date(control.value) < new Date(value) ? { dateIsEarlier: true } : null;
		};
	}

	/**
	 * Валидатор, который не дает ставить дату позже, чем заданная граница
	 * @param value - Крайняя правая граница времени (По дефолту стоит Date.now())
	 * @beta
	 */
	static dateNotLaterValidator(value: number = Date.now()): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value === null) {
				return null;
			}
			const isLater = new Date(control.value) > new Date(value);
			return isLater ? { dateIsLater: true } : null;
		};
	}
}
