import { ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, skip, Subject } from 'rxjs';
import { distinctUntilChanged, filter, finalize, takeUntil } from 'rxjs/operators';
import { AnySort } from '@utils/any-sort';
import { ManagerWorksDataSource } from '@core/dataSources/manager-works-data-source';
import { pushFakeHistoryState } from '@utils/functions';
import { errorTitle } from '@utils/helpers/error-helpers';
import { UtilsService } from '@core/services/utils.service';
import { WorkService, WorksFilter } from '@core/services/work.service';
import { WorkMenuTitles, WorkView } from '@models/work-view';
import { AppUserView } from '@models/app-user-view';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { CreateJobDialogComponent } from '@shared/components/dialog/create-job-dialog/create-job-dialog.component';
import { CompanyView } from '@models/company-view';
import { CreateFreelanceJobMethods } from '@models/enums';
import { PaginationData } from '@shared/directives/scroll-pagination/scroll-pagination.types';
import { TrueLoadingService } from '@core/services/true-loading.service';

@Component({
	selector: 'pdw-works-card',
	templateUrl: './works-card.component.html',
	host: { 'class': 'pd-expanded-height' },
	styleUrls: ['./works-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService,
		{
			provide: 'update',
			useClass: TrueLoadingService
		}, {
			provide: 'loading',
			useClass: TrueLoadingService
		}
	],
})
export class WorksCardComponent implements OnInit {
	/**
	 * Источник данных для получения записей удовлетворяющих критериям поиска
	 */
	@Input() dataSource: ManagerWorksDataSource;
	@Input() currentAppUserView: AppUserView | null;
	@Input() companiesOfActiveUser: CompanyView[] = [];
	@Input() freelanceValue = true;

	/**
	 * Фильтр
	 * @param value
	 */
	@Input()
	set filterValues(value: WorksFilter) {
		this._filterValues.next(value);
	};

	get filterValues(): WorksFilter {
		return this._filterValues.getValue();
	}

	private _filterValues = new BehaviorSubject<WorksFilter>(null);

	/**
	 * Сортировка
	 * @param value
	 */
	@Input()
	set sortValues(value: AnySort) {
		this._sortValues.next(value);
	};

	get sortValues(): AnySort {
		return this._sortValues.getValue();
	}

	private _sortValues = new BehaviorSubject<AnySort>(null);
	pageIndex = 0;
	pageSize = 6;
	remain = new BehaviorSubject<number>(null);

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private workService: WorkService,
		private notificationService: NotificationService,
		public utilsService: UtilsService,
		private destroy$: DestroyService,
		@Inject(LOCALE_ID) public locale: string,
		@Inject('update') public update$: TrueLoadingService,
		@Inject('loading') public loading$: TrueLoadingService
	) {

	}

	ngOnInit(): void {
		this.loading$.next(true);
		this.dataSource.hasNoCompanies.pipe(takeUntil(this.destroy$)).subscribe();
		this.load(this.pageIndex, this.pageSize);
		this.loadCount();

		this._filterValues
			.pipe(
				skip(1),
				takeUntil(this.destroy$),
				filter(x => x !== null),
				distinctUntilChanged(),
			)
			.subscribe(() => {
				this.loading$.next(true);
				this.pageIndex = 0;
				this.load(this.pageIndex, this.pageSize);
				this.loadCount();
			});

		this._sortValues
			.pipe(
				skip(1),
				takeUntil(this.destroy$),
				filter(x => x !== null),
				distinctUntilChanged(),
			)
			.subscribe(data => {
				this.update$.next(true);
				this.load(0, this.pageSize * (this.pageIndex + 1));
			});

		this.workService.shouldReload
			.pipe(
				takeUntil(this.destroy$),
				filter(x => x === true)
			)
			.subscribe({
				next: v => {
					this.loading$.next(true);
					this.load(this.pageIndex, this.pageSize);
					this.loadCount();
				}
			});
	}

	loadCount(): void {
		this.dataSource.loadCount(this.filterValues)
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe(x => {
				const remain = x - (this.pageIndex + 1) * this.pageSize;
				this.remain.next(remain > this.pageSize ? this.pageSize : remain);

				this.workService.currentCompanyHasWorks.next(!!x);
			});
	}

	/**
	 * Загружает указанную страницу данных указанного размера
	 * @param pageIndex номер страницы
	 * @param pageSize размер страницы
	 */
	load(pageIndex: number, pageSize: number): void {
		this.dataSource.load(
				this.filterValues,
				this.sortValues.name + ',' + this.sortValues.direction,
				pageIndex,
				pageSize)
			.pipe(
				finalize(() => {
					this.loading$.next(false);
					this.update$.next(false);
					this.workService.shouldReload.next(false);
				}),
				takeUntil(this.destroy$),
			)
			.subscribe(x => {
				this.workService.shouldReload.next(false);
				this.loading$.next(false);
				this.update$.next(false);
			});
	}

	/**
	 * Загружает указанную страницу данных указанного размера
	 * @param pageIndex номер страницы
	 * @param pageSize размер страницы
	 */
	loadMore(pageIndex: number, pageSize: number): void {
		this.dataSource.loadMore(
				this.filterValues,
				this.sortValues.name + ',' + this.sortValues.direction,
				pageIndex,
				pageSize)
			.pipe(
				finalize(() => {
					this.loading$.next(false);
					this.update$.next(false);
				}),
				takeUntil(this.destroy$),
			)
			.subscribe(x => {
				this.loading$.next(false);
				this.update$.next(false);
			});
	}

	loadByObject(params: PaginationData): void {
		this.pageSize = params.pageSize;
		this.load(params.pageIndex, params.pageSize)
	}

	goNextPageByData(data: PaginationData): void {
		this.pageSize = data.pageSize;
		if (!this.update$.value) {
			this.update$.next(true);
			this.pageIndex++;
			this.loadMore(data.pageIndex, data.pageSize);
			this.loadCount();
		}
	}


	/**
	 * Проверяет есть ли значения в фильтре
	 * @param worksFilter
	 */
	hasFilter(worksFilter: WorksFilter): boolean {
		return WorkService.hasFilter(worksFilter);
	}

	onStateChange(workView: any): void {
		if (workView == 'deleted') {
			this.workService.shouldReload.next(true);
			return;
		}
		this.dataSource.loadOne(workView.id)
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe();
	}

	goAdd(): void {
		pushFakeHistoryState();

		const workView = new WorkView();
		workView.startDate = new Date();
		workView.files = [];
		let endDate = new Date();
		endDate = new Date(endDate.setMonth(endDate.getMonth() + 1))
		workView.endDate = endDate;
		this.dialog.open(CreateJobDialogComponent, {
				autoFocus: false,
				disableClose: false,
				width: '581px',
				minHeight: '320px',
				panelClass: ['pd-dialog'],
				data: {
					title: WorkMenuTitles.primaryTitleCreate,
					method: CreateFreelanceJobMethods.create,
					workView: workView,
					appUserView: this.currentAppUserView
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
			)
			.subscribe({
				next: (data) => {
					this.workService.shouldReload.next(true);
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}
}
