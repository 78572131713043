import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CityModel } from '@models/city-model';

@Injectable({
	providedIn: 'root'
})
export class FiltersService {
	private apiUrl = 'api/';
	isFreelance$ = new BehaviorSubject<boolean>(true);

	private http = inject(HttpClient)

	getCities(query: string = ''): Observable<CityModel[]> {
		return this.http.get<CityModel[]>(this.apiUrl + 'Cities', {
			params: {
				city: query,
			}
		})
	}

	approveCity(query: CityModel): Observable<CityModel> {
		return this.http.post<CityModel>(this.apiUrl + 'Cities/Approve', query);
	}
}
