<div class='accordion-wrapper'>
	<mat-accordion>
		<mat-expansion-panel
			class='custom-panel'
			[expanded]='expanded || isActiveRoute()'
			[hideToggle]='hideToggle'
		>
			<mat-expansion-panel-header>
				<mat-panel-title class='pd-side-menu-item'>
					<mat-icon
						class='pd-icon'
						[svgIcon]='menuItem.icon'
						[class.expanded]='isActiveRoute()'
					>
					</mat-icon>
					<span
						[class.expanded]='isActiveRoute()'
						class='pd-side-menu-item-text'
					>
						{{menuItem.text}}
					</span>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<div class='expansion-panel-content'>
				<ng-container *ngIf='menuItem?.children?.length'>
					<a
						routerLinkActive='expanded'
						[routerLinkActiveOptions]='{"exact": false}'
						[routerLink]='sublink.linkTo'
						[queryParams]="{isFreelance: 1}"
						*ngFor='let sublink of menuItem?.children'
					>{{sublink.text}}</a>
				</ng-container>
			</div>

		</mat-expansion-panel>

	</mat-accordion>
</div>
