import {FormControl, FormGroupDirective, NgForm} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material/core";


//Для matFormControl stateMatcher при сравнении паролей
export class ConfirmPassMatcher implements ErrorStateMatcher{
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    if (form.submitted){
      return control.parent.errors && control.parent.errors['notSame'];
    }
    return control.parent.errors && control.parent.errors['notSame'] && control.touched;
  }
}
