import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ErrorService {
	getClientMessage(error: Error): string {
		// if (!navigator.onLine) {
		//   return 'No Internet Connection';
		// }
		// return error.message ? error.message : error.toString();
		return error.message ?
			error.message :
			error.toString();
	}

	getClientStack(error: Error): string {
		return error.stack;
	}

	getServerMessage(error: HttpErrorResponse): string {
		// return error.message;
		if (navigator.onLine) {
			return error.error ? error.error : error.message;
		} else {
			return 'No Internet Connection';
		}
		// return navigator.onLine ?
		//     error.message :
		//     'No Internet Connection';
	}

	getServerStack(error: HttpErrorResponse): string {
		// handle stack trace
		return 'stack';
	}
}
