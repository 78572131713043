<ng-container *ngIf="currentWorkExecutorMarkView | async as mark">
	<div class="pd-marks-card-inner" (mouseup)="openDialog(mark)">
		<!--Header-->
		<div class="pd-marks-card-header">
			<!--Avatar-->
			<div class="pd-marks-card-avatar" *ngIf="!isShortMode">
				<ng-container *ngIf="companyHasLogo(mark.work.company); else noAvatar">
					<img
						class="pd-marks-card-avatar-img"
						[src]="'/Logos/Originals/' + mark.work.company.id + mark.work.company.originalFilenameExtension"
						alt="Avatar Image"
					/>
				</ng-container>
				<ng-template #noAvatar>
					<mat-icon class="pd-marks-card-no-avatar-icon" [svgIcon]="'additional2-img-box'"></mat-icon>
				</ng-template>
			</div>
			<!--Right side-->
			<div class="pd-marks-card-title">
				<p class="pd-marks-card-title-text" [class.fit-height]="isShortMode">{{ mark.work.name }}</p>
				<div class="pd-marks-card-title-company-row" *ngIf="!isShortMode">
					<mat-icon class="pd-icon" svgIcon="additional-bag-2-check"></mat-icon>
					<p class="pd-marks-card-title-text-company">{{ mark.work.company.name }}</p>
				</div>
			</div>
		</div>
		<mat-divider class="pd-divider-line-1"></mat-divider>
		<div class="pd-marks-card-rating">
			<div class="pd-marks-card-rating-item">
				<p class="pd-marks-card-rating-text">{{ "qualityValue" | workExecutorMark : "propertyTitle" }}:</p>
				<app-rating-one-small [useInitValue]="true" [fractionDigits]="0" [percent]="mark.qualityValue">
				</app-rating-one-small>
			</div>
			<div class="pd-marks-card-rating-item">
				<p class="pd-marks-card-rating-text">{{ "speedValue" | workExecutorMark : "propertyTitle" }}:</p>
				<app-rating-one-small [useInitValue]="true" [fractionDigits]="0" [percent]="mark.speedValue">
				</app-rating-one-small>
			</div>
			<div class="pd-marks-card-rating-item experience">
				<p class="pd-marks-card-rating-text">{{ "experienceValue" | workExecutorMark : "propertyTitle" }}:</p>
				<app-rating-one-small [useInitValue]="true" [fractionDigits]="0" [percent]="mark.experienceValue">
				</app-rating-one-small>
			</div>
		</div>
		<mat-divider class="pd-divider-line-1"></mat-divider>
		<div class="pd-marks-card-body">
			<div class="pd-marks-card-modify-time">
				<mat-icon class="pd-icon" svgIcon="additional-check"></mat-icon>
				<p>
					{{ mark.modifyTime | date : "dd.MM.YYYY" }}
				</p>
			</div>
			<p class="pd-marks-card-comment" [ngClass]="{ empty: !mark.comment }">
				{{ mark.comment ? mark.comment : "" }}
			</p>
		</div>
	</div>
</ng-container>
