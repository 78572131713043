<pdw-dialog-header title='Характеристика специалиста'></pdw-dialog-header>

<pdw-dialog-body>
	<main>
		<div class='body-user-container'>
			<div
				class='avatar-container'
				[style.background-color]='avatarColor(data)'
			>
				<img
					*ngIf='data | avatar | async as avatar; else letters'
					class='avatar'
					[src]='avatar'
					alt='avatar'
				>
				<ng-template #letters>
					<pdw-user-initial-letters
						[user]='data'
						[canUpdate]='false'
						[size]='IMAGE_SIZE.SMALL'
					>
					</pdw-user-initial-letters>
				</ng-template>
			</div>

			<div class='body-user-info'>
				<h3>
					{{data.profession ?? 'Специалист'}}
				</h3>

				<span class='body-user-name-container'>
					{{data.firstname}}

					<span>
						<app-rating-one-small [percent]='data.ratingPercent'>
						</app-rating-one-small>
					</span>
				</span>
			</div>
		</div>
		<div class='rating'>
			<p>
				Специалист подходит на <span
				pdwRatingTheme
				[percentage]='data.specialistProfit'
			>{{data.specialistProfit}}%</span>
			</p>
			<pdw-progress-bar
				[depsColorOnValue]='true'
				[value]='data.specialistProfit'
			>
			</pdw-progress-bar>
		</div>

		<div class='body-description'>
			<h4>
				Положительные черты характера и сильные стороны
			</h4>

			<span>
				{{data.positiveQuality}}
			</span>
		</div>
	</main>

	<footer>
		<mat-icon svgIcon='logo-profdepo-ai'></mat-icon>
		<p>
			Умный подбор от ProfdepoAI
		</p>
	</footer>
</pdw-dialog-body>
