import {Component, Input, OnInit} from '@angular/core';
import {AppUserView} from "@models/app-user-view";
import {BehaviorSubject, count} from "rxjs";
import {filter} from "rxjs/operators";
import {CategoryView} from "@models/category-view";
import {CategoryMarkView} from "@models/category-mark-view";

@Component({
  selector: 'app-app-user-categories-rate',
  templateUrl: './app-user-categories-rate.component.html',
  host: {'class': 'pd-categories-rate'}
})
export class AppUserCategoriesRateComponent implements OnInit {

  @Input('currentUserView')
  set currentUserView(value: AppUserView) {
    this._currentUserView.next(value);
  };

  get currentUserView(): AppUserView {
    return this._currentUserView.getValue();
  }

  private _currentUserView = new BehaviorSubject<AppUserView>(null);
  currentAppUserView = new BehaviorSubject<AppUserView>(null);

  constructor() {
  }

  ngOnInit(): void {
    this._currentUserView
      .pipe(
        filter(x => x !== null),
      )
      .subscribe((data: AppUserView) => {
        this.currentAppUserView.next(data);
      });
  }

  getTooltip(category: CategoryMarkView): string {
    let textWork = 'работ';
    if ((category.workCountAll > 20 && category.workCountAll % 10 === 1) || category.workCountAll == 1){
      textWork = 'работы';
    }

    return `Успешно завершенно ${category.workCountSuccess} из ${category.workCountAll} ${textWork}`
  }

}
