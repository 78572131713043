import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'workExecutorMark'
})
export class WorkExecutorMarkPipe extends AbstractModelPipe implements PipeTransform {
  properties = [
    {name: 'qualityValue', title: 'Качество', hint: 'Качество'},
    {name: 'speedValue', title: 'Сроки', hint: 'Сроки'},
    {name: 'experienceValue', title: 'Опыт', hint: 'Опыт'},
    {name: 'executor', title: 'Специалист', hint: 'Специалист'},
    {name: 'work', title: 'Работа', hint: 'Работа'},
    {name: 'mark', title: 'Общая оценка', hint: 'Общая оценка'},
    {name: 'company', title: 'Компания', hint: 'Компания'},
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
  allProperties() {
    return this.properties;
  }
}
