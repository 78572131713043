<div class="pd-chat-messenger-wrapper" [ngClass]="{'disabled' : disabled}">
  <form id="messageForm"
        [formGroup]="messageForm"
        (ngSubmit)="onSubmit(messageForm)"
        (keyup.enter)="onSubmit(messageForm)"
        (keydown.enter)="onKeydown($event)"
        class="pd-chat-messenger-form" >
        <textarea matInput
                  cdkTextareaAutosize
                  formControlName="message"
                  placeholder="Написать сообщение"
                  class="pd-chat-textarea mat-form-field"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="5"
                  #messageRef>
        </textarea>
  </form>
  <button class="pd-icon-button-large pd-icon-button light-gray"
          mat-icon-button
          [disabled]="disabled"
          form="messageForm"
          type="submit">
    <mat-icon svgIcon="additional2-send"
              class="pd-icon pd-chat-send-icon">
    </mat-icon>
  </button>
</div>
