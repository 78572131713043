import {NgModule, Injectable} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BehaviorSubject} from 'rxjs';
import {SortDirection} from "@angular/material/sort";
import {MatTableDataSource} from "./mat-table-data-source";

@Injectable({
  providedIn: 'root'
})

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})

export class MatGroupBy {

  public groupingChange: BehaviorSubject<Grouping>;

  constructor() {
    this.groupingChange = new BehaviorSubject<Grouping>(this.grouping);
  }

  public get grouping(): Grouping {
    return this._grouping;
  }

  public set grouping(grouping: Grouping) {
    this._grouping = grouping;
    this.groupingChange.next(this.grouping);
  }

  private _grouping: Grouping;
  private groupCache = new GroupCache<Group>();

  public isGroup(index, item): boolean {
    return item.level;
  }

  public toggleExpanded(row) {
    row.expanded = !row.expanded;
    this.groupingChange.next(this.grouping);
  }

  public groupData<T>(data: T[], dataSource: MatTableDataSource<T>): (T | Group)[] {
    let rootGroup = this.getRootGroup();
    if (!rootGroup) {
      rootGroup = new Group();
      this.groupCache.add({}, rootGroup);
    }
    const sortedData = this.grouping.doSort<T>(data);
    return this.getSublevel<T>(sortedData, 0, rootGroup);
  }

  private getSublevel<T>(data: T[], level: number, parent: Group): (T | Group)[] {
    // Recursive function, stop when there are no more levels.
    if (level >= this.grouping.columns.length) {
      return data;
    }

    const currentColumn = this.grouping.columns[level];

    const groups = this.uniqueBy(
      data.map(
        row => {
          const key = {};
          for (let i = 0; i <= level; i++) {
            key[this.grouping.columns[i].name] = this.grouping.groupingDataAccessor(row, this.grouping.columns[i]);
          }

          let result = this.groupCache.retrieve(key);
          if (!result) {
            result = new Group();
            result.level = level + 1;
            result.parent = parent;
            result.name = currentColumn.name;
            result.value = row[currentColumn.name];
            result.key = key;
            this.groupCache.add(key, result);
          }
          return result;
        }
      ),
      JSON.stringify);
    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data
        .filter(row => this.grouping.groupDataAccessor(group) === this.grouping.groupingDataAccessor(row, currentColumn));
      subGroups = subGroups.concat([group]);
      if (group.expanded) {
        subGroups = subGroups.concat(
          this.getSublevel<T>(rowsInGroup, level + 1, group)
        );
      }
    });
    return subGroups;
  }

  private uniqueBy(a, key) {
    const seen = {};
    return a.filter(function (item) {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  private getRootGroup(): (Group | null) {
    return this.groupCache.retrieve({});
  }
}

export class GroupCache<T> {

  private cache = {};

  add(key: any, item: T) {
    const keyString = JSON.stringify(key);
    this.cache[keyString] = item;
  }

  retrieve(key: any): T {
    const keyString = JSON.stringify(key);
    return <T>this.cache[keyString];
  }
}

export class Grouping {
  readonly columns: GroupingColumn[];

  constructor(columns: GroupingColumn[]) {
    this.columns = columns;
  }

  doSort<T>(data: T[]) {
    return data.sort(this.compareGroupedColumns.bind(this));
  }

  compareGroupedColumns<T>(a: T, b: T): number {
    for (let columnIndex = 0; columnIndex < this.columns.length; columnIndex++) {
      // Don't use columns.foreach(column => {...});
      // it prevents the return value being passed out of the function.
      const column = this.columns[columnIndex];
      if (column.sort === '')
        continue;
      let av = this.sortDataAccessor(a, column);
      let bv = this.sortDataAccessor(b, column);
      if (av > bv) {
        return column.sort === 'asc' ? +1 : -1;
      }
      if (av < bv) {
        return column.sort === 'asc' ? -1 : +1;
      }
    }
    return 0;
  }

  sortDataAccessor: (<T>(x: T, column: GroupingColumn) => string | number) =
    <T>(x: T, column: GroupingColumn): string | number => {
      return x[column.name];
    }

  groupDataAccessor: ((x: Group) => any) =
    (x: Group): any => {
      return x.value;
    }

  groupingDataAccessor: (<T>(x: T, column: GroupingColumn) => any) =
    <T>(x: T, column: GroupingColumn): any => {
      return x[column.name];
    }
}

export class GroupingColumn {
  name: string;
  sort: SortDirection;
}

export class Group {
  level = 0;
  name: string;
  value: any;
  parent: Group;
  expanded = true;
  key: object;
}
