import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from '@shared/components/ui/progress-bar/progress-bar.component';


@NgModule({
	declarations: [ProgressBarComponent],
	exports: [ProgressBarComponent],
	imports: [
		CommonModule
	]
})
export class ProgressBarModule {
}
