import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppUserTypePipe } from '@public/pipes/app-user-type.pipe';
import { AbstractComponentDirective } from './abstract-component.directive';
import { CompanyPipe } from '@public/pipes/company.pipe';
import { AbstractModelPipe } from '@public/pipes/abstract-model.pipe';
import { BankAccountFormComponent } from './bank-account-form/bank-account-form.component';
import { AbstractHistoryPipe } from '@public/pipes/abstract-history.pipe';
import { LowerCaseFirstLetterPipe } from '@public/pipes/lower-case-first-letter.pipe';
import { SkillPipe } from '@public/pipes/skill.pipe';
import { SkillStateValuePipe } from '@public/pipes/skill-state-value.pipe';
import { SkillsTableComponent } from './skills-table/skills-table.component';
import { SkillDetailsComponent } from './skill-details/skill-details.component';
import { SkillFormComponent } from './skill-form/skill-form.component';
import { AppUserTypeValuePipe } from '@public/pipes/app-user-type-value.pipe';
import { SkillStatesComponent } from './skill-states/skill-states.component';
import { SkillStatePipe } from '@public/pipes/skill-state.pipe';
import { SkillsListComponent } from './skills-list/skills-list.component';
import { AppUserDetailsComponent } from './app-user-details/app-user-details.component';
import { AppUserPipe } from '@public/pipes/app-user.pipe';
import { AppUserLinkComponent } from './app-user-link/app-user-link.component';
import { SkillAppUsersComponent } from './skill-app-users/skill-app-users.component';
import { AppUserNameComponent } from './app-user-name/app-user-name.component';
import { AddressPipe } from '@public/pipes/address.pipe';
import { YesNoValueComponent } from './yes-no-value/yes-no-value.component';
import { ConfirmationDialogComponent } from './components/dialog/confirmation-dialog/confirmation-dialog.component';
import { CompanyLinkComponent } from './company-link/company-link.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SendEmailToPipe } from '@public/pipes/send-email-to.pipe';
import { StateColorPipe } from '@public/pipes/state-color.pipe';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { CustomIconComponent } from './custom-icon/custom-icon.component';
import { BoolValueComponent } from './bool-value/bool-value.component';
import { AppUserResetAnyPasswordDialogComponent } from './app-user-reset-any-password-dialog/app-user-reset-any-password-dialog.component';
import { EmailDetailsComponent } from './email-details/email-details.component';
import { EmailPurposePipe } from '@public/pipes/email-purpose.pipe';
import { PhonePurposePipe } from '@public/pipes/phone-purpose.pipe';
import { PhoneDetailsComponent } from './phone-details/phone-details.component';
import { EmailPipe } from '@public/pipes/email.pipe';
import { PhonePipe } from '@public/pipes/phone.pipe';
import { RusProfileResponseDialogComponent } from './rus-profile-response-dialog/rus-profile-response-dialog.component';
import { CompanyStateValuePipe } from '@public/pipes/company-state-value.pipe';
import { SafeDealDialogComponent } from './safe-deal-dialog/safe-deal-dialog.component';
import { SkillsDetailsComponent } from './skills-details/skills-details.component';
import { MiddleClickRouterLinkDirective } from '@shared/directives/middle-click-router-link.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuthPipe } from '@public/pipes/auth.pipe';
import { YesNoPipe } from '@public/pipes/yes-no.pipe';
import { MatTreeModule } from '@angular/material/tree';
import { RatingStarsComponent } from './rating-stars/rating-stars.component';
import { NotificationsDialogComponent } from '@shared/notifications-dialog/notifications-dialog.component';
import { EducationLevelPipe } from '@public/pipes/education-level.pipe';
import { NotificationsTableComponent } from '@shared/notifications-table/notifications-table.component';
import { NotificationsChronologyComponent } from './notifications-chronology/notifications-chronology.component';
import { NotificationCardComponent } from './notification-card/notification-card.component';
import { TuiFormatPhonePipeModule, TuiRootModule, TuiScrollbarModule, TuiSvgModule } from '@taiga-ui/core';
import { ComplexFilterDialogComponent } from '@shared/complex-filter-dialog/complex-filter-dialog.component';
import { MaterialCalendarHeaderComponent } from './material-calendar-header/material-calendar-header.component';
import { AppUserAvatarDialogComponent } from './app-user-avatar-dialog/app-user-avatar-dialog.component';
import { UserPersonalDialogComponent } from '@shared/user-personal-dialog/user-personal-dialog.component';
import { AppUserGenderPipe } from '@public/pipes/app-user-gender.pipe';
import { AppUserHardSkillsDialogComponent } from './app-user-hard-skills-dialog/app-user-hard-skills-dialog.component';
import { AppUserPersonalComponent } from './app-user-personal/app-user-personal.component';
import { AppUserHardSkillsComponent } from './app-user-hard-skills/app-user-hard-skills.component';
import { AppUserSoftSkillsComponent } from './app-user-soft-skills/app-user-soft-skills.component';
import { UserSoftSkillsDialogComponent } from './app-user-soft-skills-dialog/user-soft-skills-dialog.component';
import { AppUserExperiencesComponent } from './app-user-experiences/app-user-experiences.component';
import { AppUserExperiencesDialogComponent } from './app-user-experiences-dialog/app-user-experiences-dialog.component';
import { ExperiencePipe } from '@public/pipes/experience.pipe';
import { AppUserEducationsComponent } from './app-user-educations/app-user-educations.component';
import { AppUserEducationsDialogComponent } from './app-user-educations-dialog/app-user-educations-dialog.component';
import { EducationPipe } from '@public/pipes/education.pipe';
import { AppUserEducationAdditionalsComponent } from './app-user-education-additionals/app-user-education-additionals.component';
import { AppUserEducationAdditionalsDialogComponent } from './app-user-education-additionals-dialog/app-user-education-additionals-dialog.component';
import { EducationAdditionalPipe } from '@public/pipes/education-additional.pipe';
import { AppUserAvatarComponent } from './app-user-avatar/app-user-avatar.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AppUserRatingComponent } from './app-user-rating/app-user-rating.component';
import { RatingBigOneComponent } from './rating-one-big/rating-big-one.component';
import { AppUserContactsComponent } from './app-user-contacts/app-user-contacts.component';
import { ComplexFilterComponent } from './complex-filter/complex-filter.component';
import { AppUserDialogGenderPipe } from '@public/pipes/app-user-dialog-gender.pipe';
import { ExtendableTextAreaDirective } from '@shared/directives/extendable-text-area.directive';
import { HorizontalScrollableDirective } from '@shared/directives/horizontal-scrollable.directive';
import { AppPlainTextFormat } from '@public/pipes/app-plain-text-format';
import { AppUserContactsPipe } from '@public/pipes/app-user-contacts.pipe';
import { AlertComponent } from './alert/alert.component';
import { AppUserLastConnectionPipe } from '@public/pipes/app-user-last-conection.pipe';
import { CompaniesCardComponent } from './companies-card/companies-card.component';
import { CompanyLogoComponent } from './company-logo/company-logo.component';
import { CompanyLegalDetailsDialogComponent } from './company-legal-details-dialog/company-legal-details-dialog.component';
import { AppUserFilterDialogPipe } from '@public/pipes/app-user-filter-dialog.pipe';
import {
	TuiCarouselModule,
	TuiInputNumberModule,
	TuiInputPhoneModule,
	TuiPaginationModule,
	TuiRatingModule,
} from '@taiga-ui/kit';
import { TuiItemModule } from '@taiga-ui/cdk';
import { SupportDialogPipe } from '@public/pipes/support-dialog.pipe';
import { SupportCategoriesPipe } from '@public/pipes/support-categories.pipe';
import { WorkStateValuePipe } from '@public/pipes/work-state-value.pipe';
import { WorkPipe } from '@public/pipes/work.pipe';
import { WorkExecutorPipe } from '@public/pipes/work-executor.pipe';
import { WorksSpecialistCardComponent } from './works-specialist-card/works-specialist-card.component';
import { CompanyFilterDialogPipe } from '@public/pipes/company-filter-dialog.pipe';
import { CutThousandPipe } from '@public/pipes/cut-thousand';
import { SimpleAnyFilterComponent } from './simple-any-filter/simple-any-filter.component';
import { NumberToWordDeclensionPipe } from '@public/pipes/number-to-word-declension.pipe';
import { CompanyLegalDetailsComponent } from './company-legal-details/company-legal-details.component';
import { CompanyWorksBriefComponent } from './company-works-brief/company-works-brief.component';
import { CompanyWebsitePipe } from '@public/pipes/company-website.pipe';
import { CompanyDescriptionComponent } from './company-description/company-description.component';
import { WorkStatesComponent } from './work-states/work-states.component';
import { WorkStateComponent } from './work-state/work-state.component';
import { WorkExecutorRequestPipe } from '@public/pipes/work-executor-request.pipe';
import { WorkExecutorRequestDirectionPipe } from '@public/pipes/work-executor-request-direction.pipe';
import { CompanyDescriptionDialogComponent } from './company-description-dialog/company-description-dialog.component';
import { WorkMatchedExecutorCardsComponent } from './work-matched-executor-cards/work-matched-executor-cards.component';
import { WorksSelectDialogComponent } from './works-select-dialog/works-select-dialog.component';
import { WorkStatesMenuComponent } from './work-states-menu/work-states-menu.component';
import { WorkFilesComponent } from './work-files/work-files.component';
import { WorkFilePipe } from '@public/pipes/work-file.pipe';
import { NgxFilesizeModule } from 'ngx-filesize';
import { ContentTypeToExtensionPipe } from '@public/pipes/content-type-to-extension.pipe';
import { WorkCostComponent } from './work-cost/work-cost.component';
import { FileSizePipe } from '@public/pipes/file-size.pipe';
import { CompanyIndustriesComponent } from './company-industries/company-industries.component';
import { CompanyIndustriesDialogComponent } from './company-industries-dialog/company-industries-dialog.component';
import { WorkRequestsInWorkComponent } from '@shared/work-requests-in-work/work-requests-in-work.component';
import { WorkRequestsChatMessengerComponent } from '@shared/work-requests-chat-messenger/work-requests-chat-messenger.component';
import { BreadCrumbsComponent } from '@shared/bread-crumbs/bread-crumbs.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { WorkMarksSpecialistDialogComponent } from './work-marks-specialist-dialog/work-marks-specialist-dialog.component';
import { WorkMarksSpecialistComponent } from './work-marks-specialist/work-marks-specialist.component';
import { WorkMarkPipe } from '@public/pipes/work-mark.pipe';
import { ChatMessagePipe } from '@public/pipes/chat-message.pipe';
import { WorkRequestsInWorkItemComponent } from '@shared/work-requests-in-work-item/work-requests-in-work-item.component';
import { WorkMarksManagerComponent } from './work-marks-manager/work-marks-manager.component';
import { WorkMarksManagerDialogComponent } from './work-marks-manager-dialog/work-marks-manager-dialog.component';
import { DateToDayAndMonthPipe } from '@public/pipes/date-to-day-and-month.pipe';
import { AppUserSupportDialogComponent } from './app-user-support-dialog/app-user-support-dialog.component';
import { WorkRequestsManagerCardsComponent } from './work-requests-manager-cards/work-requests-manager-cards.component';
import { WorkRequestsSpecialistCardsComponent } from './work-requests-specialist-cards/work-requests-specialist-cards.component';
import { WorkRequestsChatMessengerFormComponent } from './work-requests-chat-messenger-form/work-requests-chat-messenger-form.component';
import { WorkDetailsManagerComponent } from './work-details-manager/work-details-manager.component';
import { WorkDetailsSpecialistComponent } from './work-details-specialist/work-details-specialist.component';
import { WorkRequestsChatMessengerCountdownComponent } from './work-requests-chat-messenger-countdown/work-requests-chat-messenger-countdown.component';
import { WorkRequestsChatMessengerContextMenuComponent } from './work-requests-chat-messenger-context-menu/work-requests-chat-messenger-context-menu.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { AppUserSuccessfulWorksRateComponent } from './app-user-successful-works-rate/app-user-successful-works-rate.component';
import { WorkFilterDialogPipe } from '@public/pipes/work-filter-dialog.pipe';
import { WorkExecutorMarkPipe } from '@public/pipes/work-executor-mark.pipe';
import { AppUserMarksComponent } from './app-user-marks/app-user-marks.component';
import { WorkExecutorMarkCardsComponent } from './work-executor-mark-cards/work-executor-mark-cards.component';
import { CompanyRatingComponent } from './company-rating/company-rating.component';
import { CompanyMarksComponent } from './company-marks/company-marks.component';
import { WorkMarkCardsComponent } from './work-mark-cards/work-mark-cards.component';
import { WorksCardCompanySliderComponent } from './works-card-company-slider/works-card-company-slider.component';
import { AppUserCategoriesRateComponent } from './app-user-categories-rate/app-user-categories-rate.component';
import { WorkCardShortComponent } from './work-card-short/work-card-short.component';
import { AppUserCategoryWorksComponent } from './app-user-category-works/app-user-category-works.component';
import { WorksCategoryCardSliderComponent } from './works-category-card-slider/works-category-card-slider.component';
import { AppUserSubCategoriesComponent } from './app-user-sub-categories/app-user-sub-categories.component';
import { AppUserSubCategoriesDialogComponent } from './app-user-sub-categories-dialog/app-user-sub-categories-dialog.component';
import { WorkCardComponent } from './work-card/work-card.component';
import { ComplexFilterDesktopComponent } from './complex-filter-desktop/complex-filter-desktop.component';
import { WorkHistoryComponent } from './work-history/work-history.component';
import { WorkHistoryPipe } from '@public/pipes/work-history.pipe';
import { WorkHistoryStatePipe } from '@public/pipes/work-history-state.pipe';
import { WorkSpecTagPipe } from '@public/pipes/work-spec-tag.pipe';
import { WorkCardShortDialogComponent } from './work-card-short-dialog/work-card-short-dialog.component';
import { WorkExecutorMarkCardDialogComponent } from './work-executor-mark-card-dialog/work-executor-mark-card-dialog.component';
import { WorkMarkCardDialogComponent } from './work-mark-card-dialog/work-mark-card-dialog.component';
import { CompanyCardComponent } from '@shared/company-card/company-card.component';
import { WorkRequestsManagerCardComponent } from '@shared/work-requests-manager-card/work-requests-manager-card.component';
import { WorkRequestsSpecialistCardComponent } from '@shared/components/cards/work-requests-specialist-card/work-requests-specialist-card.component';
import { WorkDetailsChatManagerComponent } from './work-details-chat-manager/work-details-chat-manager.component';
import { WorkDetailsChatSpecialistComponent } from './work-details-chat-specialist/work-details-chat-specialist.component';
import { WorkRequestStatePipe } from '@public/pipes/work-executor-request-state.pipe';
import { WorksTableComponent } from '@shared/works-table/works-table.component';
import { WorkStateChangeManagerComponent } from './work-state-change-manager/work-state-change-manager.component';
import { WorkStateChangeSpecialistComponent } from './work-state-change-specialist/work-state-change-specialist.component';
import { WorkExecutorMarkCardComponent } from './work-executor-mark-card/work-executor-mark-card.component';
import { WorkMarkCardComponent } from './work-mark-card/work-mark-card.component';
import { WorkResultFilesDialogComponent } from './work-result-files-dialog/work-result-files-dialog.component';
import { WorkResultFilesComponent } from './work-result-files/work-result-files.component';
import { WorkAllFilesComponent } from './work-all-files/work-all-files.component';
import { AppUserWorkStatePipe } from '@public/pipes/app-user-work-state.pipe';
import { AppUserWorkStateComponent } from './app-user-work-state/app-user-work-state.component';
import { AppUserTagPipe } from '@public/pipes/app-user-tag.pipe';
import { CheckboxListComponent } from './checkbox-list/checkbox-list.component';
import { CompanySpecTagPipe } from '@public/pipes/company-spec-tag.pipe';
import { PdSelectOptionModule } from '@profdepo-ui/core/components/select-option';
import { PdSelectModule } from '@profdepo-ui/core/components/select';
import { PdCheckboxModule, PdSnackbarModule } from '@profdepo-ui/core';
import { AppSwitchComponent } from './app-switch/app-switch.component';
import { UserTitleCheckerPipe } from '@public/pipes/user-title-checker.pipe';
import { HasPermissionDirective } from '@shared/directives/has-permission.directive';
import { HasNotPermissionDirective } from '@shared/directives/has-not-permission.directive';
import { HasAuthDirective } from '@shared/directives/has-auth.directive';
import { LetDirective } from '@shared/directives/let.directive';
import { CaptchaComponent } from './captcha/captcha.component';
import { ChipComponent } from '@shared/components/chip/chip.component';
import { ChipsListComponent } from '@shared/components/chips-list/chips-list.component';
import { ChipsSelectComponent } from '@shared/components/chips-select/chips-select.component';
import { WorkLabelPipe } from '@public/pipes/work-label.pipe';
import { AppUserInnBlockComponent } from './app-user-inn-block/app-user-inn-block.component';
import { AppUserInnDialogComponent } from './app-user-inn-dialog/app-user-inn-dialog.component';
import { AppUserInnStepTwoConfirmationDialogComponent } from './app-user-inn-step-two-confirmation/app-user-inn-step-two-confirmation.component';
import { AppUserInnStepThreeConfirmationDialogComponent } from './app-user-inn-step-three-confirmation/app-user-inn-step-three-confirmation.component';
import { AppUserInnNoteConfirmationDialogComponent } from './app-user-inn-note-confirmation/app-user-inn-note-confirmation.component';
import { AppUserRequisitesDialogComponent } from './app-user-requisites-dialog/app-user-requisites-dialog.component';
import { AppUserRequisitesNoteConfirmationDialogComponent } from './app-user-requisites-note-confirmation/app-user-requisites-note-confirmation.component';
import { MaskitoModule } from '@maskito/angular';
import { FitContentDirective } from '@shared/directives/fit-content.directive';
import { DialogHeaderComponent } from '@shared/components/dialog/dialog-header/dialog-header.component';
import { DialogBodyComponent } from './components/dialog/dialog-body/dialog-body.component';
import { DialogFooterComponent } from './components/dialog/dialog-footer/dialog-footer.component';
import { CompanyAddCardComponent } from './company-add-card/company-add-card.component';
import { WorksCardComponent } from '@shared/works-card/works-card.component';
import { DragDropDirective } from '@shared/directives/drag-drop.directive';
import { RoundedCheckboxComponent } from './works-select-dialog/components/rounded-checkbox/rounded-checkbox.component';
import { ConvertArrayToStringPipe } from '@public/pipes/convert-array-to-string.pipe';
import { WorkCardSliderComponent } from './work-card-slider/work-card-slider.component';
import { CompanyCheckerDirective } from '@shared/directives/company-checker.directive';
import { AuthDialogComponent } from './auth-dialog/auth-dialog.component';
import { ShortWorkMarkCardDialogComponent } from './short-work-mark-card-dialog/short-work-mark-card-dialog.component';
import { SlideComponent } from './components/ui/slide/slide.component';
import { TopSwitchComponent } from './works-select-dialog/components/top-switch/top-switch.component';
import { CreateFreelanceFormComponent } from '@shared/components/dialog/create-freelance-form/create-freelance-form.component';
import { CreateWorkFormComponent } from '@shared/components/dialog/create-work-form/create-work-form.component';
import { CreateJobDialogComponent } from '@shared/components/dialog/create-job-dialog/create-job-dialog.component';
import { AppUserCardRedesignComponent } from './app-user-card-redesign/app-user-card-redesign.component';
import { CompanyInformationModule } from '../general/pages/general-work/components/ui/company-information/company-information.module';
import { RatingOneSmallModule } from '@shared/rating-one-small/rating-one-small.module';
import { AvatarModule } from '@shared/pipes/avatar/avatar.module';
import { UsersFilterDialogComponent } from './users-filter-dialog/users-filter-dialog.component';
import { CompaniesFilterDialogComponent } from './companies-filter-dialog/companies-filter-dialog.component';
import { UniversalWorkFilterDialogComponent } from './universal-work-filter-dialog/universal-work-filter-dialog.component';
import { RatingThemeModule } from '@shared/directives/rating-theme/rating-theme.module';
import { ImageAvatarModule } from '@shared/components/ui/image-avatar/image-avatar.module';
import { ProfdepoAiBlockModule } from '../general/pages/general-work/general-work-matched/components/profdepo-ai-block/profdepo-ai-block.module';
import { ProgressBarModule } from '@shared/components/ui/progress-bar/progress-bar.module';
import { CardMatchedEmployeeModule } from '../general/pages/general-work/general-work-matched/components/card-matched-employee/card-matched-employee.module';
import { UserInitialLettersModule } from '@shared/components/ui/user-initial-letters/user-initial-letters.module';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { SpacePipeModule } from '@shared/pipes/space-pipe/space-pipe.module';
import { ScrollPaginationModule } from '@shared/directives/scroll-pagination/scroll-pagination.module';
import { EnvFlagModule } from '@shared/directives/env-flag/env-flag.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CompanyFilterComponent } from './company-filter/company-filter.component';

const materialModules = [
	MatBadgeModule,
	MatChipsModule,
	MatToolbarModule,
	MatIconModule,
	MatButtonModule,
	MatCardModule,
	MatListModule,
	MatRadioModule,
	MatFormFieldModule,
	MatInputModule,
	MatSelectModule,
	PdSelectModule,
	PdSelectOptionModule,
	// MatTableDataSource,
	MatTableModule,
	MatSortModule,
	MatPaginatorModule,
	// MatHeaderRowDef,
	// MatHeaderCellDef,
	// MatColumnDef,
	// MatSort,
	FlexLayoutModule,
	MatSidenavModule,
	// MatSnackBar,
	// MatTab,
	// MatTab
	MatTabsModule,
	MatSnackBarModule,
	MatGridListModule,
	MatDialogModule,
	MatProgressBarModule,
	MatCheckboxModule,
	MatDatepickerModule,
	// MatNativeDateModule,
	MatSlideToggleModule,
	MatStepperModule,
	MatProgressSpinnerModule,
	MatExpansionModule,
	MatMenuModule,
	MatTooltipModule,
	// MatButtonToggle,
	MatButtonToggleModule,
	// MatButtonToggleGroup,
	MatAutocompleteModule,
	MatRippleModule,
	MatBottomSheetModule,
	MatTreeModule,
	CdkAccordionModule,
	ClipboardModule
];

@NgModule({
	declarations: [
		AbstractComponentDirective,
		HorizontalScrollableDirective,
		AppUserContactsPipe,
		AppUserTypePipe,
		AppUserGenderPipe,
		AppUserDialogGenderPipe,
		AbstractModelPipe,
		CompanyPipe,
		AbstractHistoryPipe,
		LowerCaseFirstLetterPipe,
		SkillPipe,
		SkillStateValuePipe,
		AppUserTypeValuePipe,
		SkillStatePipe,
		AppUserPipe,
		AddressPipe,
		SendEmailToPipe,
		StateColorPipe,
		EmailPurposePipe,
		PhonePurposePipe,
		EmailPipe,
		PhonePipe,
		CompanyStateValuePipe,
		AuthPipe,
		YesNoPipe,
		EducationLevelPipe,
		ExperiencePipe,
		EducationPipe,
		EducationAdditionalPipe,
		AppPlainTextFormat,
		AppUserLastConnectionPipe,
		AppUserFilterDialogPipe,
		SupportDialogPipe,
		SupportCategoriesPipe,
		CompanyFilterDialogPipe,
		CutThousandPipe,
		NumberToWordDeclensionPipe,
		WorkStateValuePipe,
		WorkPipe,
		WorkLabelPipe,
		WorkExecutorPipe,
		WorkSpecTagPipe,
		CompanyWebsitePipe,
		WorkExecutorRequestPipe,
		WorkRequestStatePipe,
		WorkExecutorRequestDirectionPipe,
		WorkFilePipe,
		ContentTypeToExtensionPipe,
		FileSizePipe,
		WorkMarkPipe,
		ChatMessagePipe,
		DateToDayAndMonthPipe,
		WorkFilterDialogPipe,
		WorkExecutorMarkPipe,
		WorkHistoryPipe,
		WorkHistoryStatePipe,
		AppUserWorkStatePipe,
		AppUserTagPipe,
		CompanySpecTagPipe,
		BankAccountFormComponent,
		SkillsTableComponent,
		SkillDetailsComponent,
		SkillFormComponent,
		WorksCardComponent,
		SkillStatesComponent,
		SkillsListComponent,
		AppUserDetailsComponent,
		AppUserLinkComponent,
		SkillAppUsersComponent,
		AppUserNameComponent,
		YesNoValueComponent,
		ConfirmationDialogComponent,
		CompanyLinkComponent,
		FileUploadComponent,
		CustomIconComponent,
		BoolValueComponent,
		AppUserResetAnyPasswordDialogComponent,
		EmailDetailsComponent,
		PhoneDetailsComponent,
		RusProfileResponseDialogComponent,
		NotificationsDialogComponent,
		NotificationsTableComponent,
		SafeDealDialogComponent,
		SkillsDetailsComponent,
		MiddleClickRouterLinkDirective,
		RatingStarsComponent,
		NotificationsDialogComponent,
		NotificationsDialogComponent,
		NotificationsTableComponent,
		NotificationsChronologyComponent,
		NotificationCardComponent,
		AppUserAvatarDialogComponent,
		UserPersonalDialogComponent,
		AppUserHardSkillsDialogComponent,
		AppUserPersonalComponent,
		AppUserHardSkillsComponent,
		AppUserSoftSkillsComponent,
		UserSoftSkillsDialogComponent,
		ComplexFilterDialogComponent,
		MaterialCalendarHeaderComponent,
		AppUserExperiencesComponent,
		AppUserExperiencesDialogComponent,
		AppUserEducationsComponent,
		AppUserEducationsDialogComponent,
		AppUserEducationAdditionalsComponent,
		AppUserEducationAdditionalsDialogComponent,
		AppUserAvatarComponent,
		AppUserRatingComponent,
		RatingBigOneComponent,
		AppUserContactsComponent,
		ComplexFilterComponent,
		ExtendableTextAreaDirective,
		AlertComponent,
		CompaniesCardComponent,
		CompanyLogoComponent,
		CompanyLegalDetailsDialogComponent,
		SimpleAnyFilterComponent,
		WorksSpecialistCardComponent,
		CompanyLegalDetailsComponent,
		CompanyWorksBriefComponent,
		CompanyDescriptionComponent,
		WorkStatesComponent,
		WorkStateComponent,
		WorkRequestsInWorkComponent,
		WorkMatchedExecutorCardsComponent,
		CompanyDescriptionDialogComponent,
		WorksSelectDialogComponent,
		WorkFilesComponent,
		WorkStatesMenuComponent,
		WorkRequestsInWorkComponent,
		WorkRequestsInWorkItemComponent,
		WorkRequestsChatMessengerComponent,
		WorkCostComponent,
		BreadCrumbsComponent,
		CompanyIndustriesComponent,
		CompanyIndustriesDialogComponent,
		CompanyDetailsComponent,
		WorkMarksSpecialistDialogComponent,
		WorkMarksSpecialistComponent,
		WorkMarksManagerComponent,
		WorkMarksManagerDialogComponent,
		AppUserSupportDialogComponent,
		WorkRequestsManagerCardsComponent,
		WorkRequestsSpecialistCardsComponent,
		WorkCardComponent,
		WorkRequestsChatMessengerFormComponent,
		WorkDetailsManagerComponent,
		WorkDetailsSpecialistComponent,
		WorkRequestsChatMessengerCountdownComponent,
		WorkRequestsChatMessengerContextMenuComponent,
		AppUserSuccessfulWorksRateComponent,
		AppUserMarksComponent,
		WorkExecutorMarkCardsComponent,
		CompanyRatingComponent,
		CompanyMarksComponent,
		WorkMarkCardsComponent,
		WorksCardCompanySliderComponent,
		WorkCardComponent,
		AppUserCategoriesRateComponent,
		WorkCardShortComponent,
		AppUserCategoryWorksComponent,
		WorksCategoryCardSliderComponent,
		AppUserSubCategoriesComponent,
		AppUserSubCategoriesDialogComponent,
		WorkCardComponent,
		ComplexFilterDesktopComponent,
		WorkCardShortDialogComponent,
		WorkExecutorMarkCardDialogComponent,
		WorkMarkCardDialogComponent,
		CompanyCardComponent,
		CompanyAddCardComponent,
		WorkRequestsManagerCardComponent,
		WorkRequestsSpecialistCardComponent,
		WorkHistoryComponent,
		WorkDetailsChatManagerComponent,
		WorkDetailsChatSpecialistComponent,
		WorksTableComponent,
		WorkStateChangeManagerComponent,
		WorkStateChangeSpecialistComponent,
		WorkExecutorMarkCardComponent,
		WorkMarkCardComponent,
		WorkResultFilesDialogComponent,
		WorkResultFilesComponent,
		WorkAllFilesComponent,
		AppUserWorkStateComponent,
		CheckboxListComponent,
		AppSwitchComponent,
		UserTitleCheckerPipe,
		HasPermissionDirective,
		HasNotPermissionDirective,
		HasAuthDirective,
		LetDirective,
		CaptchaComponent,
		ChipComponent,
		ChipsListComponent,
		ChipsSelectComponent,
		AppUserInnBlockComponent,
		AppUserInnDialogComponent,
		AppUserInnStepTwoConfirmationDialogComponent,
		AppUserInnStepThreeConfirmationDialogComponent,
		AppUserInnNoteConfirmationDialogComponent,
		AppUserRequisitesDialogComponent,
		AppUserRequisitesNoteConfirmationDialogComponent,
		FitContentDirective,
		DialogHeaderComponent,
		DialogBodyComponent,
		DialogFooterComponent,
		DragDropDirective,
		RoundedCheckboxComponent,
		WorkCardSliderComponent,
		ConvertArrayToStringPipe,
		CompanyCheckerDirective,
		AuthDialogComponent,
		ShortWorkMarkCardDialogComponent,
		SlideComponent,
		CreateFreelanceFormComponent,
		CreateWorkFormComponent,
		CreateJobDialogComponent,
		TopSwitchComponent,
		AppUserCardRedesignComponent,
		UsersFilterDialogComponent,
		CompaniesFilterDialogComponent,
		UniversalWorkFilterDialogComponent,
        CompanyFilterComponent,
	],
	imports: [
		CommonModule,
		RatingOneSmallModule,
		AvatarModule,
		ReactiveFormsModule,
		RouterModule,
		...materialModules,
		NgxMatDatetimePickerModule,
		NgxMatTimepickerModule,
		NgxMaterialTimepickerModule,
		FormsModule,
		DragDropModule,
		TuiRootModule,
		TuiScrollbarModule,
		TuiCarouselModule,
		TuiPaginationModule,
		TuiItemModule,
		TuiRatingModule,
		TuiSvgModule,
		PdCheckboxModule,
		ImageCropperModule,
		NgxFilesizeModule,
		TuiInputNumberModule,
		TuiInputPhoneModule,
		TuiFormatPhonePipeModule,
		PdSnackbarModule,
		MaskitoModule,
		NgOptimizedImage,
		CompanyInformationModule,
		RatingThemeModule,
		ImageAvatarModule,
		ProfdepoAiBlockModule,
		ProgressBarModule,
		UserInitialLettersModule,
		CardMatchedEmployeeModule,
		SpacePipeModule,
		ScrollPaginationModule,
		EnvFlagModule
	],
	exports: [
		...materialModules,
		ReactiveFormsModule,
		TuiRootModule,
		TuiScrollbarModule,
		TuiCarouselModule,
		TuiPaginationModule,
		TuiItemModule,
		TuiSvgModule,
		TuiRatingModule,
		TuiInputNumberModule,
		HorizontalScrollableDirective,
		AppUserContactsPipe,
		AppUserTypePipe,
		AppUserGenderPipe,
		AppUserDialogGenderPipe,
		AbstractModelPipe,
		CompanyPipe,
		AbstractHistoryPipe,
		LowerCaseFirstLetterPipe,
		SkillPipe,
		SkillStateValuePipe,
		AppUserTypeValuePipe,
		SkillStatePipe,
		AppUserPipe,
		AddressPipe,
		SendEmailToPipe,
		StateColorPipe,
		EmailPurposePipe,
		PhonePurposePipe,
		EmailPipe,
		PhonePipe,
		CompanyStateValuePipe,
		AuthPipe,
		YesNoPipe,
		EducationLevelPipe,
		ExperiencePipe,
		EducationPipe,
		EducationAdditionalPipe,
		AppPlainTextFormat,
		AppUserLastConnectionPipe,
		AppUserFilterDialogPipe,
		SupportDialogPipe,
		SupportCategoriesPipe,
		CompanyFilterDialogPipe,
		CutThousandPipe,
		NumberToWordDeclensionPipe,
		DateToDayAndMonthPipe,
		WorkStateValuePipe,
		WorkPipe,
		WorkLabelPipe,
		WorkExecutorPipe,
		WorkSpecTagPipe,
		WorkExecutorRequestPipe,
		WorkRequestStatePipe,
		WorkExecutorRequestDirectionPipe,
		CompanyWebsitePipe,
		WorkFilePipe,
		ContentTypeToExtensionPipe,
		FileSizePipe,
		WorkMarkPipe,
		ChatMessagePipe,
		WorkFilterDialogPipe,
		WorkExecutorMarkPipe,
		WorkHistoryPipe,
		WorkHistoryStatePipe,
		AppUserWorkStatePipe,
		CompanySpecTagPipe,
		SkillsTableComponent,
		SkillDetailsComponent,
		SkillFormComponent,
		SkillStatesComponent,
		AppUserDetailsComponent,
		SkillAppUsersComponent,
		AppUserNameComponent,
		FileUploadComponent,
		SkillsDetailsComponent,
		MiddleClickRouterLinkDirective,
		RatingStarsComponent,
		NotificationsDialogComponent,
		NotificationsDialogComponent,
		NotificationsTableComponent,
		ComplexFilterDialogComponent,
		PdCheckboxModule,
		ExtendableTextAreaDirective,
		ComplexFilterComponent,
		PdSnackbarModule,
		AlertComponent,
		CompaniesCardComponent,
		CompanyLogoComponent,
		WorksSpecialistCardComponent,
		SimpleAnyFilterComponent,
		CompanyLegalDetailsComponent,
		CompanyWorksBriefComponent,
		CompanyDescriptionComponent,
		WorkStatesComponent,
		WorkStateComponent,
		WorkRequestsInWorkComponent,
		CompanyDescriptionDialogComponent,
		WorkStatesMenuComponent,
		WorkFilesComponent,
		WorkCostComponent,
		BreadCrumbsComponent,
		CompanyIndustriesComponent,
		CompanyDetailsComponent,
		WorkMatchedExecutorCardsComponent,
		AppUserSupportDialogComponent,
		WorkRequestsManagerCardsComponent,
		WorkRequestsSpecialistCardsComponent,
		WorkCardComponent,
		WorkDetailsManagerComponent,
		WorkDetailsSpecialistComponent,
		AppUserSuccessfulWorksRateComponent,
		WorkExecutorMarkCardsComponent,
		CompanyMarksComponent,
		WorkMarkCardsComponent,
		AppUserCategoriesRateComponent,
		WorkCardShortComponent,
		AppUserCategoryWorksComponent,
		WorksCategoryCardSliderComponent,
		WorkCardComponent,
		ComplexFilterDesktopComponent,
		CustomIconComponent,
		WorkCardShortDialogComponent,
		WorkExecutorMarkCardDialogComponent,
		WorkMarkCardDialogComponent,
		CompanyCardComponent,
		CompanyAddCardComponent,
		WorkRequestsManagerCardComponent,
		WorkRequestsSpecialistCardComponent,
		WorksTableComponent,
		WorkStateChangeManagerComponent,
		WorkStateChangeSpecialistComponent,
		WorkExecutorMarkCardComponent,
		WorkMarkCardComponent,
		AppUserWorkStateComponent,
		CheckboxListComponent,
		AppSwitchComponent,
		HasPermissionDirective,
		CaptchaComponent,
		AppUserInnStepTwoConfirmationDialogComponent,
		AppUserInnStepThreeConfirmationDialogComponent,
		AppUserInnNoteConfirmationDialogComponent,
		AppUserRequisitesDialogComponent,
		AppUserRequisitesNoteConfirmationDialogComponent,
		FitContentDirective,
		DialogHeaderComponent,
		DialogBodyComponent,
		DialogFooterComponent,
		WorksCardComponent,
		DragDropDirective,
		RoundedCheckboxComponent,
		ConvertArrayToStringPipe,
		WorkCardSliderComponent,
		CompanyCheckerDirective,
		AuthDialogComponent,
		ShortWorkMarkCardDialogComponent,
		SlideComponent,
		LetDirective,
		ChipsSelectComponent,
		CreateFreelanceFormComponent,
		CreateWorkFormComponent,
		CreateJobDialogComponent,
		TopSwitchComponent,
		UserTitleCheckerPipe,
		UsersFilterDialogComponent,
		CompaniesFilterDialogComponent,
		UniversalWorkFilterDialogComponent,
		AppUserCardRedesignComponent,
		HasNotPermissionDirective,
		HasAuthDirective,
		CompanyFilterComponent
	],
	providers: [
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
	]
})
export class SharedModule {
}
