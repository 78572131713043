import { AfterViewInit, Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { BreadCrumb } from '@core/services/bread-crumbs.service';
import { Router } from '@angular/router';
import { DestroyService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-bread-crumbs',
	templateUrl: './bread-crumbs.component.html',
	styleUrls: ['./bread-crumbs.component.scss'],
	host: { 'class': 'pd-bread-crumbs' },
	providers: [DestroyService]
})
export class BreadCrumbsComponent implements AfterViewInit {

	hiddenLinks: any[] = [];
	@Input() breadCrumbs: BreadCrumb[];
	@ViewChild('links', { read: ElementRef, static: true }) links: ElementRef;

	@Input() mobileMenu: boolean = false;

	constructor(
		private zone: NgZone,
		private router: Router,
		private destroy$: DestroyService
	) {
	}

	ngAfterViewInit() {
		this.zone.onStable
			.pipe(
				filter(() => this.links !== undefined),
				map(() => this.links.nativeElement.children),
				distinctUntilChanged(),
				takeUntil(this.destroy$)
			)
			.subscribe((nodes) => {
				const arrayOfLinks: any[] = [];
				for (let item of nodes) {
					if (item.nodeName === 'A') {
						arrayOfLinks.push(item)
					}
				}
				this.hiddenLinks = arrayOfLinks.filter(i => i.scrollWidth > 300);
			});
	}

	setToolTip(item: any): string {
		if (this.hiddenLinks.includes(item)) {
			return item.text;
		}
		return null;
	}
}
