<div class="pdw-job-search-wrapper">

    <header class="pdw-public-header job-search-header">
        <div class='pdw-public-header-container jobs-header-container'>
            <ul class='header-list-first pdw-job-header-list'>
                <li>
                    <a class='pdw-public-header-logo'>Profdepo<span>.work</span></a>
                </li>
            </ul>
        </div>
    </header>
    <div class="pdw-job-search-container">
        <div class="pdw-job-entry">
            <div class="pdw-job-entry-left">
                <h1 class="pdw-job-entry-title">
                    Найдём работу инженеру за 10&nbsp;дней и бесплатно!
                </h1>
                <p class="pdw-job-entry-subtitle">
                    У нас более 240 тыс. вакансий по России и СНГ. <br>Всю рутину поиска работы берём на себя.
                    <br>Подбираем
                    работу
                    за ~10 дней.
                    <br>
                </p>
            </div>

            <div class="pdw-job-entry-rigth">
                <form class="pdw-job-form" [formGroup]="jobSearchForm">
                    <h2 class="pdw-job-form-title">Мы напишем Вам <br> в WhatsApp или Telegram</h2>
                    <mat-form-field class='pd-input-form-field' appearance='fill' hideRequiredMarker>
                        <mat-label>Как к вам обращаться</mat-label>
                        <input matInput type='text' formControlName="firstName">
                        <mat-error *ngIf="jobSearchFormFirstNameFormControl.errors?.required">Обязательное
                            поле</mat-error>
                        <mat-error
                            *ngIf="jobSearchFormFirstNameFormControl.errors?.minlength && !jobSearchFormFirstNameFormControl.errors?.whitespace">Недостаточное
                            количество
                            символов</mat-error>
                        <mat-error *ngIf="jobSearchFormFirstNameFormControl.errors?.maxlength">Введите максимум 40
                            символов!</mat-error>
                        <mat-error *ngIf='jobSearchFormFirstNameFormControl.errors?.whitespace'>Введите
                            символы!</mat-error>
                        <mat-error *ngIf="jobSearchFormFirstNameFormControl.errors?.pattern">Недопустимые символы!
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class='pd-input-form-field' appearance='fill' hideRequiredMarker>
                        <mat-label>+7 (999) 999-99-99</mat-label>
                        <input matInput autocomplete='tel' formControlName="phoneNumber" [maskito]="maskitoOptions">
                        <mat-error *ngIf="jobSearchFormPhoneNumberControl.errors?.required">Обязательное
                            поле</mat-error>
                        <mat-error *ngIf='jobSearchFormPhoneNumberControl.errors?.minlength'>Недостаточное количество
                            символов</mat-error>
                        <mat-error *ngIf='jobSearchFormPhoneNumberControl.errors?.serverError'>
                            {{ jobSearchFormPhoneNumberControl.errors?.serverError }}
                        </mat-error>
                    </mat-form-field>
                    <button class="pd-button pdw-job-form-button"
                        [class.has-error]="jobSearchFormPhoneNumberControl.errors?.serverError"
                        [disabled]="!jobSearchForm.valid" (click)="onSubmit(jobSearchForm)">Отправить</button>
                    <p class="pdw-job-form-policy">
                        Нажимая на кнопку, вы соглашаетесь
                        <a href='https://profdepo.ru/privacyPolicy' target="_blank" class="pdw-job-form-policy-link">
                            c нашей политикой конфиденциальности
                        </a>
                    </p>
                </form>
            </div>
        </div>


        <div class="pdw-job-benefits">
            <h2 class="pdw-job-benefits-title">Мы подбираем работу
                только для инженеров</h2>
            <p class="pdw-job-benefits-subtitle">Работаем в инженерной сфере более 25 лет, хорошо понимаем рынок,
                выручили более 12,000 специалистов инженерной сферы</p>

        </div>
        <ul class="pdw-job-benefits-list">
            <li class="pdw-job-benefits-list-item">
                <h3 class="pdw-job-benefits-list-item-title">Для вас это бесплатно</h3>
                <p class="pdw-job-benefits-list-item-subtitle">Мы зарабатываем на работодателе, с вас мы не возьмем
                    ни рубля за поиск и подбор вакансий</p>
            </li>
            <li class="pdw-job-benefits-list-item">
                <h3 class="pdw-job-benefits-list-item-title">Мы не отвлекаем звонками</h3>
                <p class="pdw-job-benefits-list-item-subtitle">Весь процесс происходит через Whatsapp, мы не будем
                    раздражать вас звонками</p>
            </li>
            <li class="pdw-job-benefits-list-item">
                <h3 class="pdw-job-benefits-list-item-title">Нашли работу 12 тыс.
                    специалистам</h3>
                <p class="pdw-job-benefits-list-item-subtitle">100 инженеров в день находят работу вместе с нами</p>
            </li>
            <li class="pdw-job-benefits-list-item">
                <h3 class="pdw-job-benefits-list-item-title">У нас есть эксклюзивные
                    вакансии</h3>
                <p class="pdw-job-benefits-list-item-subtitle">Наша активная база предприятий: 130 тысяч компаний.
                </p>
            </li>
        </ul>


        <div class="pdw-job-about">
            <div class="pdw-job-about-left">
                <img src="assets/job-search-layout/men.png" alt="Кандидат">
            </div>
            <div class="pdw-job-about-right">
                <h3 class="pdw-job-about-title">Работаем только с инженерными вакансиями:</h3>
                <ul class="pdw-job-about-list">
                    <li class="pdw-job-about-list-item"><img src="assets/job-search-layout/mark.svg" alt="Галочка">
                        Проектировщики, сметчики</li>
                    <li class="pdw-job-about-list-item"> <img src="assets/job-search-layout/mark.svg"
                            alt="Галочка">Специалисты по охране труда</li>
                    <li class="pdw-job-about-list-item"> <img src="assets/job-search-layout/mark.svg"
                            alt="Галочка">Геологи и геодезисты</li>
                    <li class="pdw-job-about-list-item"> <img src="assets/job-search-layout/mark.svg"
                            alt="Галочка">Инженеры-конструкторы</li>
                    <li class="pdw-job-about-list-item"> <img src="assets/job-search-layout/mark.svg"
                            alt="Галочка">Инженеры КИПиА</li>
                    <li class="pdw-job-about-list-item"> <img src="assets/job-search-layout/mark.svg" alt="Галочка">ГИПы
                    </li>
                    <li class="pdw-job-about-list-item"> <img src="assets/job-search-layout/mark.svg"
                            alt="Галочка">Инженеры-технологи</li>
                    <li class="pdw-job-about-list-item"> <img src="assets/job-search-layout/mark.svg"
                            alt="Галочка">Инженеры-энергетики</li>
                    <li class="pdw-job-about-list-item"> <img src="assets/job-search-layout/mark.svg"
                            alt="Галочка">Инженеры по эксплуатации</li>
                    <li class="pdw-job-about-list-item"> <img src="assets/job-search-layout/mark.svg"
                            alt="Галочка">Инженеры-механики</li>
                    <li class="pdw-job-about-list-item"> <img src="assets/job-search-layout/mark.svg"
                            alt="Галочка">Инженеры ПТО</li>
                    <li class="pdw-job-about-list-item"><img src="assets/job-search-layout/mark.svg"
                            alt="Галочка">Гидрологи</li>
                </ul>
            </div>
        </div>

        <div class="pdw-find-job">
            <h2 class="pdw-find-job-title">Как мы найдем Вам работу <br> за 10 дней?</h2>

            <ul class="pdw-find-job-list">
                <li class="pdw-find-job-list-item">
                    <p class="pdw-find-job-list-item-number">1</p>
                    <h3 class="pdw-find-job-list-item-title">Обсуждаем Ваш
                        идеальный вариант</h3>
                    <p class="pdw-find-job-list-item-subtitle">Оставьте заявку, и мы обсудим все условия: профессия,
                        город, компания, уровень зарплаты, вахта
                        удалёнка или в офисе, переезд и прочие условия</p>
                </li>
                <li class="pdw-find-job-list-item">
                    <p class="pdw-find-job-list-item-number">2</p>
                    <h3 class="pdw-find-job-list-item-title">Запускаем поиск
                        подходящих вакансий</h3>
                    <p class="pdw-find-job-list-item-subtitle">У нас огромная база: 240 тысяч вакансий и 130 тысяч
                        предприятий</p>
                </li>
                <li class="pdw-find-job-list-item">
                    <p class="pdw-find-job-list-item-number">3</p>
                    <h3 class="pdw-find-job-list-item-title">Улучшим Ваше резюме</h3>
                    <p class="pdw-find-job-list-item-subtitle">Мы не врём в резюме! Только оформляем Ваше резюме в
                        наилучший визуальный вид, который оценит будущий работодатель</p>
                </li>
                <li class="pdw-find-job-list-item">
                    <p class="pdw-find-job-list-item-number">4</p>
                    <h3 class="pdw-find-job-list-item-title">Отправим ваше резюме
                        на согласование
                        с работодателями</h3>
                    <p class="pdw-find-job-list-item-subtitle">Обычно на каждого кандидата мы находим от 50 подходящих
                        компаний и позиций</p>
                </li>
                <li class="pdw-find-job-list-item">
                    <p class="pdw-find-job-list-item-number">5</p>
                    <h3 class="pdw-find-job-list-item-title">Сообщаем вам о ответных
                        предложениях о работе</h3>
                    <p class="pdw-find-job-list-item-subtitle">Спустя всего 10 дней!</p>
                </li>
                <li class="pdw-find-job-list-item">
                    <p class="pdw-find-job-list-item-number">6</p>
                    <h3 class="pdw-find-job-list-item-title">Поздравляем,<br> Вы нашли желанную
                        работу!</h3>
                </li>
            </ul>
        </div>

        <div class="pdw-job-registration">
            <div class="pdw-job-registration-left">
                <h2 class="pdw-job-registration-title">
                    Мы возьмём <br>
                    всю рутину <br>
                    по поиску работы
                    на себя</h2>
                <img src="assets/job-search-layout/form.png" alt="Форма">
            </div>

            <div class="pdw-job-registration-right">
                <form class="pdw-job-registration-form" [formGroup]="jobRegistrationForm">
                    <h2 class="pdw-job-registration-form-title">Заполните анкету <br>
                        и занимайтесь своими делами</h2>
                    <label>
                        <p class='pd-complex-filter-field-title'>Как к вам обращаться?</p>
                        <mat-form-field class='pd-input-form-field' appearance='fill' hideRequiredMarker>
                            <mat-label>Введите ваше имя</mat-label>
                            <input matInput type='text' formControlName="firstName">
                            <mat-error *ngIf="jobRegistrationFormFirstNameControl.errors?.required">Обязательное
                                поле</mat-error>
                            <mat-error *ngIf="jobRegistrationFormFirstNameControl.errors?.minlength">Недостаточное
                                количество
                                символов</mat-error>
                            <mat-error *ngIf="jobRegistrationFormFirstNameControl.errors?.maxlength">Введите максимум 40
                                символов!</mat-error>
                            <mat-error *ngIf='jobRegistrationFormFirstNameControl.errors?.whitespace'>Введите
                                символы!</mat-error>
                            <mat-error *ngIf="jobRegistrationFormFirstNameControl.errors?.pattern">Недопустимые символы!
                            </mat-error>
                        </mat-form-field>
                    </label>
                    <label>
                        <p class='pd-complex-filter-field-title'>Ваша профессия?</p>
                        <mat-form-field class='pd-input-form-field' appearance='fill' hideRequiredMarker>
                            <mat-label>Введите вашу профессию</mat-label>
                            <input matInput type='text' formControlName="profession">
                            <mat-error *ngIf="professionFormControl.errors?.required">Обязательное поле</mat-error>
                            <mat-error *ngIf='professionFormControl.errors?.whitespace'>Введите символы!</mat-error>
                            <mat-error *ngIf="professionFormControl.errors?.pattern">Недопустимые символы! </mat-error>
                        </mat-form-field>
                    </label>
                    <label>
                        <p class='pd-complex-filter-field-title'>Город, где вы хотите найти работу?</p>
                        <mat-form-field class='pd-input-form-field' appearance='fill' hideRequiredMarker>
                            <mat-label>Введите название города</mat-label>
                            <input matInput type='text' formControlName="city" [matAutocomplete]="cities">
                            <mat-autocomplete #cities="matAutocomplete">
                                <mat-option *ngFor="let city of (cities$ | async)" [value]="city">
                                    {{city}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="citiesFormControl.errors?.required">Обязательное поле</mat-error>
                        </mat-form-field>
                    </label>

                    <label>
                        <input type="file" id="file-upload" (change)="onChangeFile($event)" formControlName="file"
                            accept=".pdf, .doc, .docs, .rtf, .xls">

                        <p class='pd-complex-filter-field-title button-label'>Загрузите ваше резюме. Принимаем PDF,
                            WORD,
                            RTF,
                            EXCEL
                        </p>
                        <p *ngIf="file" class="pd-complex-filter-field-title">{{file?.name}}</p>
                        <label for="file-upload" class="file-upload-button">
                            Загрузить
                        </label>
                    </label>
                    <label>
                        <p class='pd-complex-filter-field-title'> Ваш номер телефона (напишем в WhatsApp, Telegram или
                            Viber)?</p>
                        <mat-form-field class='pd-input-form-field' appearance='fill' hideRequiredMarker>
                            <mat-label>+7 (999) 999-99-99</mat-label>
                            <input matInput type='text' formControlName="phoneNumber" [maskito]="maskitoOptions">
                            <mat-error *ngIf="jobRegistrationFormPhoneNumberControl.errors?.required">Обязательное
                                поле</mat-error>
                            <mat-error *ngIf='jobRegistrationFormPhoneNumberControl.errors?.minlength'>Недостаточное
                                количество символов</mat-error>
                            <mat-error *ngIf='jobRegistrationFormPhoneNumberControl.errors?.serverError'>
                                {{ jobRegistrationFormPhoneNumberControl.errors?.serverError }}
                            </mat-error>
                        </mat-form-field>
                    </label>
                    <button class="pdw-job-form-button"
                        [class.has-error]="jobSearchFormPhoneNumberControl.errors?.serverError"
                        (click)="onSubmitTwo(jobRegistrationForm)"
                        [disabled]="!jobRegistrationForm.valid">Отправить</button>
                    <p class="pdw-job-form-policy">
                        Нажимая на кнопку, вы соглашаетесь
                        <a href='https://profdepo.ru/privacyPolicy' target="_blank" class="pdw-job-form-policy-link">
                            c нашей политикой конфиденциальности
                        </a>
                    </p>
                </form>
            </div>
        </div>
    </div>
    <footer
        class="pdw-public-footer pdw-job-footer {{method === 'logout' ? 'pdw-public-footer-visibility' : ''}} footer-container">
        <div class='pdw-public-footer-container pdw-public-container footer'>
            <div class='pdw-public-footer-wrapper'>
                <div class='pdw-public-footer-row footer-first-row'>
                    <a class='pdw-public-footer-title'>Profdepo<span>.work</span></a>
                    <div class='pdw-public-footer-social-link'>
                        <p class='pdw-public-footer-text pdw-public-footer-text-life'>Мы в соцсетях</p>
                        <div class='pdw-public-footer-social-link-icons'>
                            <a mat-icon-button class='pd-icon-button pdw-public-footer-social-link'
                                href='https://t.me/profdepo_ru'>
                                <mat-icon class='pdw-icon' [svgIcon]="'additional2-tg'"></mat-icon>
                            </a>
                            <a mat-icon-button class='pd-icon-button pdw-public-footer-social-link'
                                href='https://vk.com/profdepo_ru'>
                                <mat-icon class='pdw-icon' [svgIcon]="'additional2-vk'"></mat-icon>
                            </a>
                        </div>
                    </div>
                    <div class='pdw-public-footer-feedback'>
                        <p class='pdw-public-footer-text pdw-public-footer-text-link'>Есть вопросы<br>или предложения?
                        </p>
                        <a class='pdw-public-w-icon-link' (click)='goSupport()'>
                            <mat-icon svgIcon='math-sign-plus'>
                            </mat-icon>
                            <span>
                                Напишите нам
                            </span>
                        </a>
                    </div>
                </div>
                <div class='pdw-public-footer-border'></div>
                <div class='pdw-public-footer-documents'>
                    <a class='pdw-public-footer-link' href='https://profdepo.ru/privacyPolicy'>Политика
                        конфиденциальности</a>
                    <a class='pdw-public-footer-link' href='https://profdepo.ru/termsOfUse'>Пользовательское
                        соглашение</a>
                    <a class='pdw-public-footer-link' href='https://profdepo.ru/transactionRules'>Правила заключения
                        сделок</a>
                    <a class='pdw-public-footer-link' href='https://profdepo.ru/infoServiceRules'>Правила оказания
                        услуг</a>
                </div>
            </div>
        </div>
    </footer>
</div>