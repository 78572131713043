import { Component } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AppUserService } from "@core/services/app-user.service";
import { ForgotPasswordRequest } from "@requests/forgot-password-request";
import { errorTitle } from "@utils/helpers/error-helpers";
import { finalize, takeUntil } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { DestroyService, NotificationService } from "@profdepo-ui/core";
import { filloutFormError, filloutFormErrorByPropName } from "@utils/form-helper";

@Component({
	selector: "app-forgot-password",
	templateUrl: "./forgot-password.component.html",
})
export class ForgotPasswordComponent {
	recoveryForm: FormGroup;
	saving = new BehaviorSubject<boolean>(false);

	constructor(
		private _formBuilder: FormBuilder,
		private _router: Router,
		private _appUserService: AppUserService,
		private _notificationService: NotificationService,
		private destroy$: DestroyService
	) {
		this.recoveryForm = this._formBuilder.group({
			email: new FormControl("", [Validators.required, Validators.email]),
		});
	}

	get email(): AbstractControl {
		return this.recoveryForm.get("email");
	}

	onSubmit({ value, valid }): void {
		if (valid) {
			this.saving.next(true);
			this._appUserService
				.forgotPassword(value as ForgotPasswordRequest)
				.pipe(
					takeUntil(this.destroy$),
					finalize(() => {
						console.warn("forgotPassword final");
						this.saving.next(false);
					})
				)
				.subscribe({
					next: (data) => {
						console.warn("forgotPassword success", data);
						this._router.navigate(["/sentConfirmation"], { state: { afterForgotPassword: true } });
					},
					error: (err) => {
						if (err instanceof HttpErrorResponse) {
							switch (err.status) {
								case 400:
									filloutFormError(this.recoveryForm, err);
									break;
								case 404:
									filloutFormErrorByPropName(this.recoveryForm, "email");
									break;
								default:
									this._notificationService.showDanger(errorTitle(err));
									break;
							}
						}
					},
				});
		}
	}
}
