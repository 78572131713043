import { Pipe, PipeTransform } from '@angular/core';
import { AppUserView } from '@models/app-user-view';
import { checkEmptyUserInfo } from '@utils/helpers/user-helper';

@Pipe({
	name: 'userTitleChecker'
})
export class UserTitleCheckerPipe implements PipeTransform {
	transform(user: AppUserView): string {
		return checkEmptyUserInfo(user);
	}

}
