import { ChangeDetectionStrategy, Component, EventEmitter, Inject, LOCALE_ID, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { WorkHistoryService } from '@core/services/work-history.service';
import { errorTitle } from '@utils/helpers/error-helpers';
import { WorkHistoryState, WorkHistoryView } from '@models/work-history-view';
import { MatTableDataSource } from '@angular/material/table';
import { AppUserGender, AppUserView } from '@models/app-user-view';
import { YesNo } from '@models/enums';
import { WorkRequestState } from '@models/work-request-view';
import { AppUserService } from '@core/services/app-user.service';
import { WorkPipe } from '@public/pipes/work.pipe';
import { DatePipe } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { WorkAbstractComponent } from '@shared/work-abstract-component/work-abstract-component';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { SpacePipe } from '@shared/pipes/space-pipe/space.pipe';

type HandledWorkHistories = { history: WorkHistoryView[], totalDraft: number }

@Component({
	selector: 'pdw-work-history',
	templateUrl: './work-history.component.html',
	styleUrls: ['./work-history.component.scss'],
	host: { 'class': 'pd-work-history' },
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService]
})
export class WorkHistoryComponent extends WorkAbstractComponent implements OnInit {
	dataSource: MatTableDataSource<WorkHistoryView>;
	displayedColumns = [
		'info',
		'action' 
	];
	@Output() changed = new EventEmitter<any>();
	@Output() edit = new EventEmitter<WorkHistoryView>();
	hasData: boolean = false;
	activeHistoriesCount: number = 0;
	expandedElement: WorkHistoryView;
	isLoading = new BehaviorSubject<boolean>(false);

	constructor(
		private appUserService: AppUserService,
		private workHistoryService: WorkHistoryService,
		private notificationService: NotificationService,
		private destroy$: DestroyService,
		@Inject(LOCALE_ID) public locale: string,
	) {
		super();
	}

	ngOnInit(): void {
		this.isLoading.next(true);
		this.workViewSubject
			.pipe(
				filter(x => x !== null),
				switchMap(workView => this.workHistoryService.work(workView.id)
					.pipe(
						takeUntil(this.destroy$)
					)
				)
			)
			.subscribe({
				next: (workHistories: WorkHistoryView[]) => {
					const { history, totalDraft } = this.handleHistory(workHistories)

					this.hasData = !!history.length;
					this.activeHistoriesCount = totalDraft;
					this.dataSource = new MatTableDataSource<WorkHistoryView>(history);
					this.isLoading.next(false);
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
					this.isLoading.next(false);
				}
			});
	}

	verb(appUserView: AppUserView, attribute: string): string {
		let tmp = appUserView.gender === AppUserGender.Female ? 'изменила' : 'изменил';
		if (['description', 'resultDescription'].some(x => x == attribute)) {
			tmp = tmp + ',';
		}
		return tmp;
	}

	private handleHistory(workHistories: WorkHistoryView[]) {
		return workHistories.reduce<HandledWorkHistories>((acc, historyItem) => {
			if (historyItem.state == WorkHistoryState.Draft) acc.totalDraft += 1;
			if ([WorkHistoryState.Auto, WorkHistoryState.Skip].includes(historyItem.state)) return acc;
			acc.history.push(historyItem)
			return acc
		}, { history: [], totalDraft: 0 })
	}

	canChangeState(workHistoryView: WorkHistoryView): Observable<boolean> {
		return this.appUserService.getActiveUserAsObservable()
			.pipe(
				filter(x => x !== null && this.workView !== null),
				map(appUserView => {
					if (workHistoryView.state !== WorkHistoryState.Draft) {
						return false;
					}

					if (this.workView.specialistAssigned === YesNo.no) {
						return false;
					}

					const acceptedWorkRequest = this.workView.requests
						.find(x => x.state === WorkRequestState.accepted);

					if (acceptedWorkRequest == null) {
						return false;
					}

					let fromManager = workHistoryView.createAppUser.id === this.workView.manager.id;
					let fromExecutor = workHistoryView.createAppUser.id == acceptedWorkRequest.executor.id;

					if (fromManager && appUserView.id !== acceptedWorkRequest.executor.id)
						return false;

					return !(fromExecutor && appUserView.id !== workHistoryView.work.manager.id);
				})
			);
	}

	goAccept(workHistoryView: WorkHistoryView): void {
		this.workHistoryService.accept(workHistoryView.id)
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: (workHistoryView: WorkHistoryView) => {
					this.changed.emit(workHistoryView);
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	goReject(workHistoryView: WorkHistoryView): void {
		this.workHistoryService.reject(workHistoryView.id)
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: (workHistoryView: WorkHistoryView) => {
					this.changed.emit(workHistoryView);
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	getWorkHistoryStateTitle(workHistoryView: WorkHistoryView): string {
		switch (workHistoryView.state) {
			case WorkHistoryState.Draft:
				return 'Внесены изменения'
			case WorkHistoryState.Reject:
				return 'Изменения отклонены'
			default:
				return 'Изменения приняты';
		}
		
	}

	isDraftHistory(workHistoryView: WorkHistoryView): boolean {
		return workHistoryView.state === WorkHistoryState.Draft;
	}

	isRejectHistory(workHistoryView: WorkHistoryView): boolean {
		return workHistoryView.state === WorkHistoryState.Reject
	}

	creatorName(workHistoryView: WorkHistoryView): string {
		if (workHistoryView.createAppUser.id === this.workView.manager.id) {
			return this.workView.company.name;
		}

		return workHistoryView.createAppUser.title;
	}

	isDescriptionRow(index, workHistoryView: WorkHistoryView): boolean {
		return workHistoryView.attribute === 'description' || workHistoryView.attribute === 'resultDescription';
	}

	getValueString(workHistoryView: WorkHistoryView): string {
		let result: string = '';
		let tmp: any;
		const workPipe = new WorkPipe();
		const datePipe = new DatePipe(this.locale);
		const costPipe = new SpacePipe();

		switch (workHistoryView.attribute) {
			case 'name':
				tmp = workHistoryView.value.stringValue;
				switch (workHistoryView.state) {
					case WorkHistoryState.Skip:
					case WorkHistoryState.Reject:
						result += `Отклонено ${workPipe.transform(workHistoryView.attribute, 'propertyTitle').toLowerCase()}: ${tmp}`;
						break;
					case WorkHistoryState.Accept:
						result += `Принято ${workPipe.transform(workHistoryView.attribute, 'propertyTitle').toLowerCase()}: ${tmp}`;
						break;
					case WorkHistoryState.Draft:
						result += `Изменить ${workPipe.transform(workHistoryView.attribute, 'propertyCase').toLowerCase()} на: ${tmp}`;
						break;
				}
				break;
			case 'description':
			case 'resultDescription':
				switch (workHistoryView.state) {
					case WorkHistoryState.Skip:
					case WorkHistoryState.Reject:
						result += 'Отклонено, '
						break;
					case WorkHistoryState.Accept:
						result += 'Принято, '
						break;
					case WorkHistoryState.Draft:
						result += 'Изменить, '
						break;
				}
				result += workPipe.transform(workHistoryView.attribute, 'propertyTitle').toLowerCase() + '...';
				break;
			case 'startDate':
			case 'endDate':
				tmp = datePipe.transform(workHistoryView.value.dateTimeValue, 'shortDate');
				switch (workHistoryView.state) {
					case WorkHistoryState.Skip:
					case WorkHistoryState.Reject:
						result += `Отклонена ${workPipe.transform(workHistoryView.attribute, 'propertyTitle').toLowerCase()}: ${tmp}`;
						break;
					case WorkHistoryState.Accept:
						result += `Принята ${workPipe.transform(workHistoryView.attribute, 'propertyTitle').toLowerCase()}: ${tmp}`;
						break;
					case WorkHistoryState.Draft:
						result += `Изменить ${workPipe.transform(workHistoryView.attribute, 'propertyCase').toLowerCase()} на: ${tmp}`;
						break;
				}
				break;
			case 'cost':
				tmp = costPipe.transform(workHistoryView.value.doubleValue);
				switch (workHistoryView.state) {
					case WorkHistoryState.Skip:
					case WorkHistoryState.Reject:
						result += `Отклонен ${workPipe.transform(workHistoryView.attribute, 'propertyTitle').toLowerCase()}: ${tmp}\xa0₽`;
						break;
					case WorkHistoryState.Accept:
						result += `Принят ${workPipe.transform(workHistoryView.attribute, 'propertyTitle').toLowerCase()}: ${tmp}\xa0₽`;
						break;
					case WorkHistoryState.Draft:
						result += `Изменить ${workPipe.transform(workHistoryView.attribute, 'propertyTitle').toLowerCase()} на: ${tmp}\xa0₽`;
						break;
				}
				break;
		}

		return result;
	}

	onEdit(workHistoryView) {
		this.edit.next(workHistoryView);
	}
}
