import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	forwardRef,
	Input,
	OnDestroy,
	ViewChild,
} from '@angular/core';
import { environment } from '@env/environment';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface CaptchaOptions {
	params: {
		sitekey: string;
		callback?: (token: string) => void;
		hl?: 'ru' | 'en' | 'be' | 'kk' | 'tt' | 'uk' | 'uz' | 'tr';
		test?: boolean;
		webview?: boolean;
		invisible?: boolean;
		shieldPosition?: `top-left` | `center-left` | `bottom-left` | `top-right` | `center-right` | `bottom-right`;
		hideShield?: boolean;
	}
}

@Component({
	selector: 'app-captcha',
	templateUrl: './captcha.component.html',
	styleUrls: ['./captcha.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CaptchaComponent),
			multi: true
		}
	]
})
export class CaptchaComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {

	@Input() value: string = '';
	@Input() options: CaptchaOptions;
	widgetId: any;

	@ViewChild('captcha') captchaElement!: ElementRef;


	onChange: any = () => {
	};
	onTouched: any = () => {
	};

	private readonly captchaKey: string;
	private readonly isTestedVersion: boolean;

	constructor() {
		this.captchaKey = environment.yandexCaptcha;
		this.isTestedVersion = environment.testCaptcha;
	}

	get key() {
		return this.captchaKey;
	}

	get isTested() {
		return this.isTestedVersion;
	}

	public reset() {
		if (window['smartCaptcha']) {
			window['smartCaptcha'].reset(this.widgetId);
		}
	}

	private onLoad() {
		try {
			if (window['smartCaptcha']) {
				this.widgetId = window['smartCaptcha'].render(this.captchaElement.nativeElement as HTMLElement, {
					sitekey: this.key,
					hl: 'ru',
					test: this.isTested,
					callback: (token: string) => {
						this.value = token;
						this.onChange(this.value);
						this.onTouched();
					},
					...this.options
				});
			}
		} catch (e) {
			console.warn('captcha error');
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	writeValue(value: any): void {
		this.value = value;
	}

	getValue(): string {
		return this.value;
	}

	ngOnDestroy(): void {
		window['smartCaptcha'].destroy(this.widgetId);
	}

	ngAfterViewInit(): void {
		this.onLoad();
	}

}
