<div fxLayout="column" fxLayoutGap="20px" *ngIf="dataSource">
  <div>
    <mat-table matSort matSortActive="title" matSortDirection="desc" [dataSource]="dataSource" class="pd-width-full">

      <!-- Title Column -->
      <ng-container matColumnDef="title">
        <mat-header-cell mat-sort-header matTooltip="{{ 'title' | skill: 'propertyHint' }}" *matHeaderCellDef>
          {{ 'title' | skill: 'propertyTitle' }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a [routerLink]="otherLink.concat([element.id])">
            {{ element.title }}
          </a>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
          Итого:
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="title.filter">
        <mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter sm" floatLabel="never">
            <mat-label>Поиск</mat-label>
            <input matInput [formControl]="titleFilter">
          </mat-form-field>
        </mat-header-cell>
      </ng-container>

      <!-- Code Column -->
      <ng-container matColumnDef="code">
        <mat-header-cell mat-sort-header matTooltip="{{ 'code' | skill: 'propertyHint' }}" *matHeaderCellDef>
          {{ 'code' | skill: 'propertyTitle' }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.code }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="code.filter">
        <mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter sm" floatLabel="never">
            <mat-label>Поиск</mat-label>
            <input matInput [formControl]="codeFilter">
            <button class="pd-icon-button"
                    type="button"
                    mat-icon-button
                    matSuffix
                    matTooltip="Очистить значение"
                    aria-label="Clear"
                    (click)="codeFilter.setValue(null)"
                    *ngIf="!isEmptyValue(codeFilter)">
              <mat-icon class="pd-icon" svgIcon="additional-close"></mat-icon>
            </button>
          </mat-form-field>
        </mat-header-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell mat-sort-header matTooltip="{{ 'name' | skill: 'propertyHint' }}" *matHeaderCellDef>
          {{ 'name' | skill: 'propertyTitle' }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a [routerLink]="otherLink.concat([element.id])">
            {{ element.name }}
          </a>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="name.filter">
        <mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter sm" floatLabel="never">
            <mat-label>Поиск</mat-label>
            <input matInput [formControl]="nameFilter">
            <button class="pd-icon-button"
                    type="button"
                    mat-icon-button
                    matSuffix
                    matTooltip="Очистить значение"
                    aria-label="Clear"
                    (click)="nameFilter.setValue(null)"
                    *ngIf="!isEmptyValue(nameFilter)">
              <mat-icon class="pd-icon" svgIcon="additional-close"></mat-icon>
            </button>
          </mat-form-field>
        </mat-header-cell>
      </ng-container>

      <!-- PersonsCount Column -->
      <ng-container matColumnDef="personsCount">
        <mat-header-cell mat-sort-header matTooltip="{{ 'personsCount' | skill: 'propertyHint' }}" *matHeaderCellDef>
          {{ 'personsCount' | skill: 'propertyTitle' }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.personsCount">
            {{ element.personsCount }}
          </ng-container>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
          <span *ngIf="dataSource">
            {{ personsCountSum() | number }}
          </span>
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="personsCount.filter">
        <mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter sm" floatLabel="never">
            <mat-label>Поиск</mat-label>
            <input matInput [formControl]="personsCountFilter">
          </mat-form-field>
        </mat-header-cell>
      </ng-container>

      <!-- State Column -->
      <ng-container matColumnDef="state">
        <mat-header-cell mat-sort-header matTooltip="{{ 'state' | skill: 'propertyHint' }}" *matHeaderCellDef>
          {{ 'state' | skill: 'propertyTitle' }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container [ngSwitch]="element.state">
            <div *ngSwitchCase="0">
              <mat-icon [color]="skillStateColor(element.state)">error_outline</mat-icon>
            </div>
            <div *ngSwitchCase="1">
              <mat-icon [color]="skillStateColor(element.state)">check_circle_outline</mat-icon>
            </div>
            <div *ngSwitchCase="2">
              <mat-icon [color]="skillStateColor(element.state)">highlight_off</mat-icon>
            </div>
            <div *ngSwitchDefault>
              {{ element.state | skillStateValue }}
            </div>
          </ng-container>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="state.filter">
        <mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter sm" floatLabel="never">
            <mat-label>Поиск</mat-label>
            <input matInput [formControl]="stateFilter">
          </mat-form-field>
        </mat-header-cell>
      </ng-container>

      <!-- Group header -->
      <ng-container matColumnDef="groupHeader">
        <mat-cell colspan="999" class="pd-table-cell-group" *matCellDef="let group">
          <div *ngIf="group.value">
            <h3>{{ group.value }}</h3>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns | async"></mat-header-row>
      <mat-header-row *matHeaderRowDef="filteredColumns | async"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns | async;"></mat-row>
      <mat-row *matRowDef="let row; columns: ['groupHeader']; when: groupBy.isGroup"></mat-row>
      <mat-footer-row *matFooterRowDef="displayedColumns | async"></mat-footer-row>
    </mat-table>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <button mat-raised-button
            matTooltip="Добавить новую специализацию"
            color="primary"
            [routerLink]="otherLink.concat(['create'])">
      Добавить...
    </button>
<!--    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>-->
  </div>
</div>
