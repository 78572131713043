import {Component, Input, OnInit} from '@angular/core';
import {AppUserView} from "@models/app-user-view";

@Component({
  selector: 'app-app-user-name',
  templateUrl: './app-user-name.component.html',
})
export class AppUserNameComponent {
  @Input() appUserView: AppUserView;
  constructor() { }
}
