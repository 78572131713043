import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root'
})
export class FeatureService {

	get inCurrentRelease(): boolean {
		return environment.inCurrentRelease;
	}
}
