import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { WorkRequestState, WorkRequestView } from '@models/work-request-view';
import { ChatService } from '@core/services/chat.service';
import { ChatMessageService } from '@core/services/chat-message.service';
import { WorkRequestService, WorkRequestsFilter } from '@core/services/work-request.service';
import { NotificationsRequestService } from '@core/services/notifications-request.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AppUserView } from '@models/app-user-view';
import { NoticeService } from '@core/services/notice.service';
import { WorkView } from '@models/work-view';
import { NotificationService } from '@profdepo-ui/core';

@Component({
	selector: 'app-work-requests-in-work',
	templateUrl: './work-requests-in-work.component.html',
})
export class WorkRequestsInWorkComponent implements OnInit, OnDestroy {
	@Output() selectWorkRequestChange = new EventEmitter<WorkRequestView>();
	@Output() isLoading = new EventEmitter<boolean>();
	@Input() selectWorkRequest: WorkRequestView;
	@Output() newMessagesCountEvent = new EventEmitter<number>();
	@Input() titleFilter: FormControl;
	@Input() user: AppUserView;
	@Input() work: WorkView;

	isLoad: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	unsubscribe: Subject<any> = new Subject<any>();
	dataSource: MatTableDataSource<WorkRequestView>;
	currentWorkRequest: WorkRequestView;

	constructor(
		private chatService: ChatService,
		private chatMessageService: ChatMessageService,
		private workRequestService: WorkRequestService,
		private notificationService: NotificationService,
		private notificationRequestService: NotificationsRequestService,
		private noticeService: NoticeService
	) {
	}

	ngOnInit(): void {
		this.isLoading.emit(this.isLoad.value);
		this.dataSource = new MatTableDataSource<WorkRequestView>();
		this.dataSource.filterPredicate = (data, filter) => {
			return data.work.name.trim().toLowerCase().indexOf(filter) != -1;
		};

		this.workRequestsLoad(() => {
			var accepted = this.dataSource.data.filter(x => x.state == WorkRequestState.accepted);
			if (accepted.length > 0)
				this.selectingWorkRequest(accepted[0]);
		});

		//прилетел новый реквест
		this.noticeService.workRequestCountsChanged
			.pipe(
				filter(x => x != undefined),
				takeUntil(this.unsubscribe)
			)
			.subscribe({
				next: (data) => {
					this.workRequestsLoad();
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});

		//получено новое сообщение, вывести диалог вверх
		this.chatService.workRequestLastMessageUpdated
			.pipe(
				takeUntil(this.unsubscribe),
				map(x => WorkRequestService.parseDates(x))
			)
			.subscribe({
				next: (data: WorkRequestView) => {
					let i = this.dataSource.data.findIndex(x => x.id == data.id);
					//если отклик, по которому пришел ответ есть в списке диалогов
					if (i != -1) {
						this.dataSource.data[i] = Object.assign({}, data);
						this.sortRequestsByLastChatMessageCreateTime();
					}
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});

		this.chatMessageService.lastClientAddedMessage
			.pipe(
				takeUntil(this.unsubscribe),
				filter(x => x !== null)
			)
			.subscribe({
				next: workRequestChatMessageView => {
					let i = this.dataSource.data.findIndex(x => x.id == workRequestChatMessageView.workRequest.id);
					//если отклик, по которому пришел ответ есть в списке диалогов
					if (i != -1) {
						this.dataSource.data[i].lastWorkRequestChatMessage = workRequestChatMessageView;
						this.sortRequestsByLastChatMessageCreateTime();
					}
				}
			})

		//по прочтению уведомления, чисто для клиента уменьшаем количество сообщений по воркреквесту на 1
		this.notificationRequestService.notificationChatMessageRead
			.pipe(
				takeUntil(this.unsubscribe),
			)
			.subscribe(
				(workRequestViewId: number) => {
					let i = this.dataSource.data.findIndex(x => x.id == workRequestViewId);
					//если отклик, чье уведомление прочитали, есть в списке диалогов
					if (i != -1) {
						if (this.dataSource.data[i].newMessagesCount > 0) {
							this.dataSource.data[i].newMessagesCount -= 1;
							this.newMessagesCountEvent.emit(this.calcMessagesCountSum());
							this.sortRequestsByLastChatMessageCreateTime();
						}
					}
				}
			)
	}

	workRequestsLoad(afterFunc?: Function) {
		this.workRequestService.inWork(this.work.id, new WorkRequestsFilter(), null, 0, 1000)
			.pipe(
				takeUntil(this.unsubscribe),
			)
			.subscribe({
				next: (data: WorkRequestView[]) => {
					this.setWorkRequestsData(data);
					if (afterFunc)
						afterFunc();
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	setWorkRequestsData(data: WorkRequestView[]) {
		if (data.length != 0) {
			this.isLoad.next(true);
		}
		this.dataSource.data = data.filter(item => !(item.state === WorkRequestState.declined || item.state === WorkRequestState.cancelledByClient || item.state === WorkRequestState.cancelledBySpecialist));
		// this.newMessagesCountEvent.emit(this.calcMessagesCountSum());
		this.sortRequestsByLastChatMessageCreateTime();
	}

	sortRequestsByLastChatMessageCreateTime() {
		this.dataSource.data = this.dataSource.data.sort((a: WorkRequestView, b: WorkRequestView) => {
			if (a == b)
				return 0;
			if (a.state == WorkRequestState.accepted || a.lastWorkRequestChatMessage?.createTime > b.lastWorkRequestChatMessage?.createTime
				|| !a) return -1;
			if (b.state == WorkRequestState.accepted || a.lastWorkRequestChatMessage?.createTime < b.lastWorkRequestChatMessage?.createTime
				|| !b)
				return 1;
			return 0;
		});
	}

	selectingWorkRequest(data: WorkRequestView) {
		//передает родителю выбранный реквест, который дальше отправляется в work-request-chat-messenger.component, где
		//вызывается this.chatMessageService.all, который на сервере отмечает прочитанными все текущие непрочитанные сообщения
		this.selectWorkRequestChange.emit(data);
		//далее, чисто для фронта зануляем колчество непрочитанных сообщений у выбранного реквеста
		let i = this.dataSource.data.findIndex(x => x.id == data.id);
		this.dataSource.data[i].newMessagesCount = 0;
		this.currentWorkRequest = this.dataSource.data[i];
		this.newMessagesCountEvent.emit(this.calcMessagesCountSum());
	}

	calcMessagesCountSum() {
		return this.dataSource.data.length > 0 ?
			this.dataSource.data
				.map(request => request.newMessagesCount)
				.reduce((prev, curr) => prev + curr) : 0;
	}

	ngOnDestroy() {
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}
}
