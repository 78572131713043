import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SideMenuService } from '@core/services/side-menu.service';
import { BehaviorSubject, forkJoin, mergeWith, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { NotificationsDialogComponent } from '@shared/notifications-dialog/notifications-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Location } from '@angular/common';
import { NotificationCounts } from '@models/notification-counts';
import { NoticeService } from '@core/services/notice.service';
import { NotificationsRequestService } from '@core/services/notifications-request.service';
import { AppUserService } from '@core/services/app-user.service';
import { AppUserType, AppUserView } from '@models/app-user-view';
import { NavigationEnd, Router } from '@angular/router';
import { pushFakeHistoryState } from '@utils/functions';
import { BreadCrumb, BreadCrumbsService } from '@core/services/bread-crumbs.service';
import { WorkRequestService } from '@core/services/work-request.service';
import { UtilsService } from '@core/services/utils.service';
import { DestroyService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-market-header',
	templateUrl: './market-header.component.html',
	providers: [DestroyService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./market-header.component.scss']
})
export class MarketHeaderComponent implements OnInit, OnDestroy {
	user$: Observable<AppUserView>;
	currentNotificationCounts = new BehaviorSubject<number>(0);
	breadCrumbs = new BehaviorSubject<BreadCrumb[]>([]);
	count = 0;

	@Input() mobileMenu: boolean;
	@Input() sideMenuRef: MatSidenav;

	@ViewChild('toolbar', { read: ElementRef }) toolbar: ElementRef;
	@ViewChild('notificationButton', { read: ElementRef }) notificationButton: ElementRef;

	constructor(
		private appUserService: AppUserService,
		private sideMenuService: SideMenuService,
		private dialog: MatDialog,
		private location: Location,
		private noticeService: NoticeService,
		private notificationService: NotificationsRequestService,
		private router: Router,
		private breadCrumbsService: BreadCrumbsService,
		private workRequestService: WorkRequestService,
		private utilsService: UtilsService,
		private destroy$: DestroyService
	) {
		this.user$ = this.appUserService.getActiveUserAsObservable();
	}

	private get user(): AppUserView {
		return this.appUserService.getCurrentActiveUserView();
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.breadCrumbsService.currentBreadCrumbs
				.pipe(
					takeUntil(this.destroy$)
				)
				.subscribe(breadCrumbs => {
					this.breadCrumbs.next(breadCrumbs);
				});
		}, 0);

		this.noticeService.notificationCountsChanged
			.pipe(
				takeUntil(this.destroy$),
				filter(x => x !== undefined)
			)
			.subscribe((x: NotificationCounts) => {
				this.currentNotificationCounts.next(x.newNotifications);
			});

		this.noticeService.workRequestCountsChanged
			.pipe(
				takeUntil(this.destroy$),
				filter(x => x !== undefined),
				switchMap(counts => this.appUserService.getActiveUserView()
					.pipe(
						filter(x => x !== null),
						takeUntil(this.destroy$),
						map(x => x.type === AppUserType.client
							? counts.forManager
							: counts.forExecutor
						)
					)
				)
			)
			.subscribe({
				next: (count: number) => {
					this.sideMenuService.setWorkRequestCounts(count);
				}
			});

		this.noticeService.connectionEstablished
			.pipe(
				takeUntil(this.destroy$),
				filter(x => x),
				distinctUntilChanged(),
				mergeWith(this.utilsService.changeWorkRequestCount),
				switchMap(() => forkJoin([
						this.notificationService.count(),
						this.workRequestService.counts(),
						this.user$
					])
						.pipe(
							takeUntil(this.destroy$),
							map(data => {
								return {
									notificationCounts: data[0].newNotifications,
									workRequestCounts: data[2].type === AppUserType.client
										? data[1].forManager
										: data[1].forExecutor
								}
							})
						)
				),
			)
			.subscribe((data) => {
				this.currentNotificationCounts.next(data.notificationCounts);
				this.sideMenuService.setWorkRequestCounts(data.workRequestCounts);
			});

		this.router.events
			.pipe(
				takeUntil(this.destroy$),
				filter(event => event instanceof NavigationEnd)
			)
			.subscribe({
				next: () => {
					this.dialog.closeAll();
				}
			});
	}

	goNotifications(event: MouseEvent): void {
		pushFakeHistoryState();

		const notificationButtonRect = this.notificationButton.nativeElement.getBoundingClientRect();

		this.dialog.open(NotificationsDialogComponent, {
			position: {
				top: this.toolbar.nativeElement.offsetHeight + 'px',
				left: notificationButtonRect.x + notificationButtonRect.width - 581 + 'px'
			},
			width: '581px',
			minHeight: '280px',
			panelClass: ['pd-dialog', 'pd-dialog-without-footer', 'pd-notifications-dialog'],
			data: {
				appUserView: this.user
			}
		});
	}

	goBack() {
		this.location.back();
	}

	ngOnDestroy(): void {
		this.noticeService.endConnection().then();
	}
}
