import {Component, Input} from '@angular/core';
import {CompanyPhoneView} from "@models/company-phone-view";

@Component({
  selector: 'app-phone-details',
  templateUrl: './phone-details.component.html',
})
export class PhoneDetailsComponent {
  @Input() phoneView: CompanyPhoneView;
  constructor() { }
}
