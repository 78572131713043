export class StringHelpers {
}

export function toLowerCaseFirstLetter(value: string): string {
	return value.charAt(0).toLowerCase() + value.slice(1);
}

export function toUpperCaseFirstLetter(value: string): string {
	return value.charAt(0).toUpperCase() + value.slice(1);
}

export function hours2string(value: number): string {
	return number2string(value, ['часов', 'час', 'часа']);
}

/**
 * Возвращает множественную форму числа
 * @param value число
 * @param words массив форм [для 0 или 5..., для прочих, для 2...4]
 */
export function number2string(value: number, words: string[]): string {
	const lastTwoDigits = Math.abs(value) % 100;
	const lastDigit = Math.abs(value) % 10;
	if (lastDigit == 0 || lastDigit >= 5 || (lastTwoDigits > 10 && lastTwoDigits < 20)) {
		return words[0];
	}
	if (lastDigit >= 2 && lastDigit <= 4) {
		return words[2];
	}
	return words[1];
}

// export function uuidv4() {
//   return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
//     (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
//   );
// }

export function stringToHslColor(str, s, l) {
	if (!str) {
		return ''
	}
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}

	let h = hash % 360;
	return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

export function getBooleanPropertyFromString(value: string): boolean {
	return value != null && `${value.toLowerCase()}` !== 'false';
}
