<ng-container *ngIf='utilsService.mobileQuery980px | async; else desktop'>
	<div class='pd-complex-filter-mobile-header'>
		<mat-form-field
			class='pd-input-form-field pd-input-form-field-without-border'
			appearance='fill'
			hideRequiredMarker
			#formField
		>
			<mat-chip-list
				class='pd-chip-list'
				#chipList
			>
				<mat-icon
					class='pd-field-prefix-icon gray'
					svgIcon='general-search'
				></mat-icon>
				<mat-chip
					class='pd-chip pd-chip-no-list'
					*ngIf='(filtersWithoutMainTag | async)?.length'
					[removable]='true'
					[disableRipple]='true'
				>
					<span class='filter-value'>
						{{(filtersWithoutMainTag | async).length | numberToWordDeclension: ['фильтров', 'фильтр', 'фильтра'] : true}}
					</span>
					<button
						class='pd-chip-remove-btn'
						matChipRemove
						(click)='clearFilters()'
					>
						<mat-icon svgIcon='interface-close-small'></mat-icon>
					</button>
				</mat-chip>
				<input
					type='text'
					matInput
					class='pd-chip-input'
					[placeholder]="(filtersWithoutMainTag | async)?.length ? '+ ' + data.placeholder : data.placeholder"
					[formControl]='anyFilter'
					[matChipInputFor]='chipList'
				>
				<div
					class='pd-input-suffix-two-btn'
					matSuffix
				>
					<button
						class='pd-icon-button with-line-height gray'
						mat-icon-button
						matTooltip='Фильтр'
						(click)='goFilter()'
					>
						<mat-icon
							class='pd-icon'
							svgIcon='additional2-filter-alt'
						></mat-icon>
					</button>
				</div>
			</mat-chip-list>
		</mat-form-field>
		<ng-container *ngIf='mainTagField && !mainTagField'>
			<form [formGroup]='currentFormGroup'>
				<mat-button-toggle-group
					class='pd-button-toggle-group-divided'
					[formControlName]='mainTagField.name'
				>
					<ng-container *ngFor='let item of mainTagField.selectOptions.selectValues'>
						<mat-button-toggle
							class='pd-button-toggle-rounded'
							[value]='item'
						>
							{{mainTagField.selectOptions.valuesPipe.transform(item)}}
						</mat-button-toggle>
					</ng-container>
				</mat-button-toggle-group>
			</form>
		</ng-container>
		<ng-container>
			<pd-select
				[formControl]="sortControl"
				class='works-select'
			>
				<pd-select-option
					*ngFor='let item of sortOptions'
					[value]='item'
					class='pd-select-option sort'
				>
					{{item.name}}
				</pd-select-option>
			</pd-select>
		</ng-container>
	</div>
</ng-container>
<ng-template #desktop>

	<form
		id='filterForm'
		[formGroup]='currentFormGroup'
		(reset)='onReset()'
		class='pd-complex-filter-form'
	>

		<!--  Any   -->
		<mat-form-field
			class='pd-input-form-field'
			appearance='fill'
			hideRequiredMarker
		>
			<mat-icon
				class='pd-field-prefix-icon'
				svgIcon='general-search'
				matPrefix
			></mat-icon>
			<mat-label>{{data.placeholder}}</mat-label>
			<input
				matInput
				type='text'
				[formControl]='anyFilter'
				maxlength="100"
			>
		</mat-form-field>
		<ng-container
			*ngFor='let filterField of data.displayedFields'
			[ngSwitch]='filterField.inputType'
		>
			<ng-container *ngSwitchCase="'select'">
				<p class='pd-complex-filter-field-title'>
					{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
				</p>
				<mat-form-field
					class='pd-input-form-field'
					appearance='fill'
				>
					<mat-icon
						class='pd-field-prefix-icon'
						svgIcon='{{filterField.icon}}'
						matPrefix
					></mat-icon>
					<mat-label>
						{{ filterField.pipe.transform(filterField.name, 'propertyHint') }}
					</mat-label>
					<pd-select
						[formControlName]='filterField.name'
					>
						<pd-select-option
							*ngFor='let item of filterField.selectOptions.selectValues'
							[value]='item'
						>
							{{filterField.selectOptions.valuesPipe.transform(item)}}
						</pd-select-option>
					</pd-select>
					<button
						matSuffix
						class='pd-icon-button'
						mat-icon-button
						[disableRipple]='true'
						type='button'
						*ngIf='showClearControlBtn(filterField)'
						(click)='resetFormControl($event ,filterField)'
					>
						<mat-icon svgIcon='interface-close'></mat-icon>
					</button>
				</mat-form-field>
			</ng-container>
			<ng-container *ngSwitchCase="'text'">
				<p class='pd-complex-filter-field-title'>
					{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
				</p>
				<mat-form-field
					class='pd-input-form-field'
					appearance='fill'
				>
					<mat-icon
						class='pd-field-prefix-icon'
						svgIcon='{{filterField.icon}}'
						matPrefix
					></mat-icon>
					<mat-label>
						{{ filterField.pipe.transform(filterField.name, 'propertyHint') }}
					</mat-label>
					<input
						matInput
						type='search'
						[formControlName]='filterField.name'
					>
				</mat-form-field>
			</ng-container>

			<ng-container *ngSwitchCase="'budget'">
				<p class='pd-complex-filter-field-title'>
					{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
				</p>

				<div
					class='budget-wrapper'
					*ngIf='filterField.halfInput; else defaultBudget'
				>
					<div class='pd-taiga-input-wrapper'>
						<tui-input-number
							class='pd-taiga-input pd-taiga-input-w-prefix'
							[min]='0'
							[decimal]="'never'"
							[ngClass]="{'pd-taiga-input-empty': isTuiInputEmpty(filterField.name)}"
							[formControlName]='filterField.name'
						>{{ filterField.pipe.transform(filterField.name, 'propertyHint') }}</tui-input-number>
						<mat-icon
							*ngIf='filterField.icon'
							class='pd-icon pd-taiga-input-prefix-icon'
							svgIcon='{{filterField.icon}}'
						></mat-icon>
					</div>
					<div *ngIf='filterField?.selectOptions?.isMultiple' class='pd-taiga-input-wrapper'>
						<tui-input-number
							class='pd-taiga-input pd-taiga-input-w-prefix'
							[min]='0'
							[decimal]="'never'"
							[ngClass]="{'pd-taiga-input-empty': isTuiInputEmpty(secondInputName(filterField.name))}"
							[formControlName]='secondInputName(filterField.name)'
						>{{ filterField.pipe.transform(filterField.name, 'propertySecondHint') }}</tui-input-number>
						<mat-icon
							*ngIf='filterField.icon'
							class='pd-icon pd-taiga-input-prefix-icon'
							svgIcon='{{filterField.icon}}'
						></mat-icon>
					</div>
				</div>

				<ng-template #defaultBudget>

					<div class='pd-taiga-input-wrapper half-width'>
						<tui-input-number
							class='pd-taiga-input pd-taiga-input-w-prefix'
							[min]='0'
							[decimal]="'never'"
							[ngClass]="{'pd-taiga-input-empty': isTuiInputEmpty(filterField.name), 'non-icon': !filterField?.icon}"
							[formControlName]='filterField.name'
						>{{ filterField.pipe.transform(filterField.name, 'propertyHint') }}</tui-input-number>
						<mat-icon
							*ngIf='filterField.icon'
							class='pd-icon pd-taiga-input-prefix-icon'
							svgIcon='{{filterField.icon}}'
						></mat-icon>
					</div>
					<div *ngIf='filterField?.selectOptions?.isMultiple' class='pd-taiga-input-wrapper half-width'>
						<tui-input-number
							class='pd-taiga-input pd-taiga-input-w-prefix'
							[min]='0'
							[decimal]="'never'"
							[ngClass]="{'pd-taiga-input-empty': isTuiInputEmpty(secondInputName(filterField.name))}"
							[formControlName]='secondInputName(filterField.name)'
						>{{ filterField.pipe.transform(filterField.name, 'propertySecondHint') }}</tui-input-number>
						<mat-icon
							*ngIf='filterField.icon'
							class='pd-icon pd-taiga-input-prefix-icon'
							svgIcon='{{filterField.icon}}'
						></mat-icon>
					</div>
				</ng-template>
			</ng-container>

			<ng-container *ngSwitchCase="'rating'">
				<p class='pd-complex-filter-field-title'>
					{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
				</p>
				<div class='pd-taiga-input-wrapper'>
					<tui-input-number
						class='pd-taiga-input pd-taiga-input-w-prefix'
						[min]='0'
						[precision]='1'
						[max]='5'
						[ngClass]="{'pd-taiga-input-empty': isTuiInputEmpty(filterField.name)}"
						[formControlName]='filterField.name'
					>{{ filterField.pipe.transform(filterField.name, 'propertyHint') }}</tui-input-number>
					<mat-icon
						class='pd-icon pd-taiga-input-prefix-icon'
						svgIcon='{{filterField.icon}}'
					></mat-icon>
				</div>
				<div class='pd-taiga-input-wrapper '>
					<tui-input-number
						class='pd-taiga-input pd-taiga-input-w-prefix'
						[min]='0'
						[precision]='1'
						[max]='5'
						[ngClass]="{'pd-taiga-input-empty': isTuiInputEmpty(secondInputName(filterField.name))}"
						[formControlName]='secondInputName(filterField.name)'
					>{{ filterField.pipe.transform(filterField.name, 'propertySecondHint') }}</tui-input-number>
					<mat-icon
						class='pd-icon pd-taiga-input-prefix-icon'
						svgIcon='{{filterField.icon}}'
					></mat-icon>
				</div>
			</ng-container>
			<ng-container *ngSwitchCase="'autocomplete'">
				<p class='pd-complex-filter-field-title'>
					{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
				</p>
				<mat-form-field
					class='pd-input-form-field'
					appearance='fill'
				>
					<mat-label>{{ filterField.pipe.transform(filterField.name, 'propertyHint') }}</mat-label>
					<mat-icon
						class='pd-field-prefix-icon'
						svgIcon='{{filterField.icon}}'
						matPrefix
					></mat-icon>
					<input
						matInput
						[id]='filterField.name'
						[formControlName]='filterField.name'
						[matAutocomplete]='auto'
						maxlength="100"
					>
					<mat-autocomplete
						class='pd-autocomplete'
						#auto='matAutocomplete'
						(optionSelected)='selectedItem(filterField, $event)'
					>
						<tui-scrollbar class='pd-scrollbar pd-autocomplete-scrollbar'>
							<mat-option
								class='pd-option'
								*ngFor='let item of filterSource(filterField.name).source | async'
								[disabled]='disabledItem(filterField.name, item)'
								[value]='item'
							>
								{{ item.titleLong ? item.titleLong : item.title }}
							</mat-option>
						</tui-scrollbar>
					</mat-autocomplete>
				</mat-form-field>
				<ng-container *ngIf='filter(filterField.name) as filter'>
					<mat-chip-list
						aria-label='Skills selection'
						class='pd-chip-list-wrap pd-chip-list-not-mat'
					>
						<mat-chip
							class='pd-chip'
							*ngFor='let item of filter.value'
							[removable]='true'
							(removed)='removeItem(filterField.name, item)'
						>
							<span class='pd-chip-label'>{{ item.title }}</span>
							<button
								class='pd-chip-remove-btn'
								matChipRemove
							>
								<mat-icon svgIcon='interface-close-small'></mat-icon>
							</button>
						</mat-chip>
					</mat-chip-list>
				</ng-container>
			</ng-container>

			<ng-container *ngSwitchCase="'dateRange'">
				<p class='pd-complex-filter-field-title'>
					{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
				</p>
				<mat-form-field
					class='pd-input-form-field'
					appearance='fill'
					hideRequiredMarker
				>
					<mat-icon
						class='pd-field-prefix-icon'
						svgIcon='{{filterField.icon}}'
						matPrefix
					></mat-icon>
					<mat-label>
						{{ filterField.pipe.transform(filterField.name, 'propertyHint') }}
					</mat-label>
					<input
						matInput
						[formControlName]='filterField.name'
						(beforeinput)='onDateInput($event)'
						[matDatepicker]='datePicker'
					>
					<mat-datepicker-toggle
						class='pd-datepicker-toggle'
						matSuffix
						[for]='datePicker'
					>
						<mat-icon
							svgIcon='arrow-down-2'
							matDatepickerToggleIcon
						></mat-icon>
					</mat-datepicker-toggle>
					<mat-datepicker
						[panelClass]="'pd-calendar'"
						#datePicker
						[calendarHeaderComponent]='calendarHeader'
					></mat-datepicker>
				</mat-form-field>
				<mat-form-field
					class='pd-input-form-field'
					appearance='fill'
					hideRequiredMarker
				>
					<mat-icon
						class='pd-field-prefix-icon'
						svgIcon='{{filterField.icon}}'
						matPrefix
					></mat-icon>
					<mat-label>
						{{ filterField.pipe.transform(filterField.name, 'propertySecondHint') }}
					</mat-label>
					<input
						matInput
						[formControlName]='secondInputName(filterField.name)'
						[matDatepicker]='secondDatePicker'
					>
					<mat-datepicker-toggle
						class='pd-datepicker-toggle'
						matSuffix
						[for]='secondDatePicker'
					>
						<mat-icon
							svgIcon='arrow-down-2'
							matDatepickerToggleIcon
						></mat-icon>
					</mat-datepicker-toggle>
					<mat-datepicker
						[panelClass]="'pd-calendar'"
						#secondDatePicker
						[calendarHeaderComponent]='calendarHeader'
					></mat-datepicker>
				</mat-form-field>
			</ng-container>

			<ng-container *ngSwitchCase="'multi-toggle'">
				<p class='pd-complex-filter-field-title'>
					{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
				</p>
				<mat-button-toggle-group
					class='pd-button-toggle-group-divided'
					[formControlName]='filterField.name'
					multiple='true'
				>
					<ng-container *ngFor='let item of filterField.selectOptions.selectValues'>
						<mat-button-toggle
							class='pd-button-toggle'
							[value]='item'
						>
							{{filterField.selectOptions.valuesPipe.transform(item)}}
						</mat-button-toggle>
					</ng-container>
				</mat-button-toggle-group>
			</ng-container>
			<ng-container *ngSwitchCase="'main-tag'">
				<p class='pd-complex-filter-field-title' *ngIf="filterField.isToShowLabel">
					{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
				</p>
				<mat-button-toggle-group
					class='pd-button-toggle-group-divided'
					[formControlName]='filterField.name'
				>
					<ng-container *ngFor='let item of filterField.selectOptions.selectValues'>
						<mat-button-toggle
							class='pd-button-toggle'
							[value]='item'
						>
							{{filterField.selectOptions.valuesPipe.transform(item)}}
						</mat-button-toggle>
					</ng-container>
				</mat-button-toggle-group>
			</ng-container>
			<ng-container *ngSwitchCase="'number'">
				<p class='pd-complex-filter-field-title'>
					{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
				</p>
				<mat-form-field
					class='pd-input-form-field'
					appearance='fill'
				>
					<mat-icon
						class='pd-field-prefix-icon'
						svgIcon='{{filterField.icon}}'
						matPrefix
					></mat-icon>
					<mat-label>
						{{ filterField.pipe.transform(filterField.name, 'propertyHint') }}
					</mat-label>
					<input
						matInput
						oninput='this.value = Math.abs(this.value)'
						type='number'
						min='0'
						[formControlName]='filterField.name'
					>
				</mat-form-field>
				<mat-form-field
					class='pd-input-form-field'
					appearance='fill'
				>
					<mat-icon
						class='pd-field-prefix-icon'
						svgIcon='{{filterField.icon}}'
						matPrefix
					></mat-icon>
					<mat-label>
						{{ filterField.pipe.transform(filterField.name, 'propertySecondHint') }}
					</mat-label>
					<input
						matInput
						min='0'
						type='number'
						[formControlName]='secondInputName(filterField.name)'
					>
				</mat-form-field>
			</ng-container>
			<ng-container *ngSwitchCase="'checkbox-list'">
				<pdw-checkbox-list
					[label]='filterField.checkboxLabel'
					[formControlName]='filterField.name'
					[options]='filterField.selectOptions.selectValues'
					[name]='checkboxListDisplayedName(filterField.selectOptions.selectValues)'
				></pdw-checkbox-list>
			</ng-container>
			<ng-container *ngSwitchCase="'autocomplete-chips'">
				<p class='pd-complex-filter-field-title'>
					{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
				</p>
				<pdw-chips-select
					[icon]='filterField.icon'
					[isMultiple]='filterField?.selectOptions?.isMultiple ?? false'
					[optionLabel]='filterField.labelOption'
					[optionKey]='filterField.labelOption'
					[formControlName]='filterField.name'
					[options]='filterField.source'
					[label]='filterField.pipe.transform(filterField.name, "propertyHint")'
				>
					<ng-container *ngIf='filterField.children as children'>
						<ng-container *ngFor='let child of children'>
							<pdw-checkbox-list
								class='checkbox-relocate'
								*ngIf='child.inputType === "checkbox-list"'
								[label]='child.checkboxLabel'
								[formControlName]='child.name'
								[options]='child.selectOptions.selectValues'
							></pdw-checkbox-list>
						</ng-container>
					</ng-container>
				</pdw-chips-select>
			</ng-container>
		</ng-container>
		<p class='pd-complex-filter-field-title'>
			<mat-icon
				class='pd-icon'
				svgIcon='interface-check'
			></mat-icon>
			Подходит {{(currentCountFilter | async) | numberToWordDeclension: data.counterWordsToDeclension : true}}
		</p>
		<button
			form='filterForm'
			mat-raised-button
			class='pd-button-secondary'
			type='button'
			(click)='onReset()'
		>
			Сбросить
		</button>
	</form>
</ng-template>
