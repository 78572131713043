<div class='pd-dialog-header'>
	<p class='pd-dialog-header-title'>{{ data.title }}</p>
	<button
		class='pd-icon-button'
		mat-icon-button
		[mat-dialog-close]='false'
	>
		<mat-icon svgIcon='interface-close'></mat-icon>
	</button>
</div>
<tui-scrollbar class='pd-scrollbar pd-dialog-scroll'>
	<div class='pd-dialog-content large-padding'>
		<div *ngIf='softSkillForm'>
			<input
				class='pd-input-invisible'
				#invisibleInput
				tabindex='-1'
			>
			<form
				id='softSkillForm'
				[formGroup]='softSkillForm'
			>
				<div class='pd-dialog-column'>

					<!--  SoftSkill   -->
					<mat-form-field
						class='pd-input-form-field'
						appearance='fill'
						hideRequiredMarker
					>
						<mat-label>{{ 'softSkillsTitle' | skill: 'propertyHint' }}</mat-label>
						<input
							matInput
							#skillInput
							[formControl]='softSkill'
							[matAutocomplete]='skillAuto'
							(keyup.enter)='onEnter()'
						>
						<mat-autocomplete
							#skillAuto='matAutocomplete'
							(optionSelected)='setSelectedSkillView($event)'
							[displayWith]='getSkillViewTitle'
							class='pd-autocomplete'
						>
							<tui-scrollbar class='pd-scrollbar pd-autocomplete-scrollbar'>
								<mat-option
									class='pd-option'
									*ngFor='let item of softSkillViews$ | async'
									[disabled]='disabledItem(item)'
									[value]='item'
								>
									{{item.titleLong}}
								</mat-option>
							</tui-scrollbar>
						</mat-autocomplete>
						<button
							class='pd-icon-button'
							type='button'
							mat-icon-button
							matSuffix
							matTooltip="Добавить новое качество '{{ softSkill.value }}'"
							*ngIf='softSkill.valid && !isEmptyValue(softSkill)'
							(click)='goAdd($event)'
						>
							<mat-icon
								class='pd-icon'
								svgIcon='additional-plus'
							></mat-icon>
						</button>

						<mat-error
							*ngIf='softSkill.errors?.maxSoftSkills && !softSkill.errors?.objectRequired && !softSkill.errors?.onlySpace && !softSkill.errors?.maxlength'
							i18n='mat-error@@app-user-soft-skills-dialog-softSkill-maxSoftSkills'
						>
							Максимальное количество качеств 30!
						</mat-error>
						<mat-error
							*ngIf='softSkill.errors?.uniqueRequired && !softSkill.errors?.maxSoftSkills && !softSkill.errors?.onlySpace && !softSkill.errors?.maxlength'
							i18n='mat-error@@app-user-soft-skills-dialog-softSkill-uniqueRequired'
						>
							Качество уже присутствует в списке!
						</mat-error>
						<mat-error *ngIf='softSkill.errors?.onlySpace && !softSkill.errors?.maxSoftSkills && !softSkill.errors?.uniqueRequired && !softSkill.errors?.maxlength'>
							Проверьте правильность данных
						</mat-error>
						<mat-error *ngIf='softSkill.errors?.maxlength && !softSkill.errors?.maxSoftSkills && !softSkill.errors?.objectRequired && !softSkill.errors?.onlySpace'>
							Введите максимум 50 символов!
						</mat-error>
						<mat-error *ngIf='softSkill.errors?.serverError'>
							{{ softSkill.errors?.serverError }}
						</mat-error>
					</mat-form-field>
					<ng-container *ngIf='softSkills$.value.length'>
						<mat-chip-list class='pd-chip-list-not-mat pd-chip-list-wrap'>
							<mat-chip
								class='pd-chip'
								[ngClass]="{ 'created': softSkill.state === 0 }"
								*ngFor='let softSkill of softSkills$ | async'
								[matTooltip]='getTooltip(softSkill)'
								(removed)='goRemove(softSkill)'
							>
								<span class='pd-chip-label'>{{softSkill.title}}</span>
								<button
									class='pd-chip-remove-btn'
									matChipRemove
								>
									<mat-icon svgIcon='interface-close-small'></mat-icon>
								</button>
							</mat-chip>
						</mat-chip-list>
					</ng-container>
				</div>
			</form>
		</div>
	</div>
</tui-scrollbar>
<div class='pd-dialog-footer align-end'>
	<div class='pd-dialog-footer-buttons'>
		<button
			mat-raised-button
			class='pd-button-secondary large'
			type='reset'
			[mat-dialog-close]='false'
		>
			Отменить
		</button>
		<button
			mat-raised-button
			class='pd-button large'
			type='submit'
			(click)='goSave()'
		>
			<ng-container *ngIf='!(saving$ | async); else isSaving'>Сохранить</ng-container>
			<ng-template #isSaving>
				Сохраняем
			</ng-template>
		</button>
	</div>
</div>
