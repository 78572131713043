import { CompanyView } from '@models/company-view';
import { AppUserView } from '@models/app-user-view';
import { HardSkillView } from '@models/hard-skill-view';
import { WorkFileView } from '@models/work-file-view';
import { YesNo } from '@models/enums';
import { WorkRequestView } from '@models/work-request-view';
import { CategoryView } from '@models/category-view';
import { SubCategoryView } from '@models/sub-category-view';
import { TimedView } from '@models/timed-view';
import { WorkStateView } from '@models/work-state-view';
import { WorkResultFileView } from '@models/work-result-file-view';
import { CityModel } from '@models/city-model';

export const WorkMenuTitles = {
	icon: 'work',
	primaryTitleItems: 'Вакансии',
	descriptionItems: 'Все работы',
	primaryTitleCreate: 'Создание работы',
	descriptionCreate: 'Создание новой работы',
	primaryTitleItem: 'Работа',
	descriptionItem: 'Описание работы',
	moderateTitleItems: 'Модерация',
	myWorks: 'Мои работы',
	allWorks: 'Все работы',
	careerGid: 'Агрегатор вакансий'
};

export interface WorkFormatI {
	id: number,
	name: string,
}

export enum WorkSpecTag {
	none = -1,
	my,
	matched,
	subscribed,
	all,
}


export enum WorkStateValue {
	none = -1,
	draft,
	seeking,
	packaged,
	executingAwait,
	executing,
	checking,
	successed,
	moderateFail,
	failedBySpecialist,
	failedByClient,
	vacancyIsFinished = 98,
	abstractFinished = 99
}

export interface WorkTypeI {
	createAppUser?: AppUserView,
	createTime?: Date | null,
	modifyAppUser?: AppUserView,
	modifyTime?: Date | null,
	id: number,
	name?: string,
}

export enum WorkStateEnum {
	CERTAIN = 'certain',
	ABSTRACT_FINISHED = 'abstractFinished',
	EXCLUSIVE_FOR_SPECIALIST = 'exclusiveForSpecialist',
	ABSTRACT_FINISHED_EXCLUDE_SUCCEED = 'abstractFinishedExcludeSuccessed'
}

export class WorkView {
	public id: number;
	public title: string;
	public company: CompanyView;
	public manager: AppUserView;
	public name: string;
	public description: string;
	public resultDescription: string;
	public executorId: string | null
	// public planStartDate: Date | null;
	// public planEndDate: Date | null;
	public startDate: Date | null;
	public endDate: Date | null;
	// public planCost: number | null;
	public amount: number | null;
	public files: WorkFileView[];
	public resultFiles: WorkResultFileView[];
	public state: WorkStateValue;
	public states: WorkStateView[];
	public hardSkills: HardSkillView[];
	public categories: CategoryView[];
	public subCategories: SubCategoryView[];
	public subscribed: YesNo;
	public requests: WorkRequestView[];
	public specialistAssigned: YesNo;
	public hasActiveRequests: YesNo;
	public matchedCount: number;
	public requestsCount: number;
	public timed: TimedView;
	public createAppUser: AppUserView;
	public modifyAppUser: AppUserView;
	public createTime: Date;
	public modifyTime: Date;
	public workType?: WorkTypeI;
	public workFormat?: WorkFormatI;
	public isFreelance?: YesNo;
	public city?: CityModel
	public companyName?: string
	public categoryNames?: string[]
}
