<div class='pd-dialog-header'>
	<p class='pd-dialog-header-title'>{{ data.title }}</p>
	<button
		class='pd-icon-button'
		mat-icon-button
		[mat-dialog-close]='false'
	>
		<mat-icon svgIcon='interface-close'></mat-icon>
	</button>
</div>
<tui-scrollbar class='pd-scrollbar pd-dialog-scroll'>
	<div class='pd-dialog-content large-padding'>
		<div *ngIf='supportForm'>
			<form
				id='supportForm'
				[formGroup]='supportForm'
				(ngSubmit)='onSubmit(supportForm)'
			>
				<div class='pd-dialog-column'>

					<div class='pd-dialog-row'>
						<!--  Name   -->
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
							hideRequiredMarker
						>
							<mat-label>{{ name | supportDialog: 'propertyTitle' }}</mat-label>
							<input
								matInput
								formControlName='name'
								type='text'
							>
							<mat-error
								*ngIf='name.errors?.required'
								i18n='mat-error@@support-dialog-name-required'
							>Обязательное поле
							</mat-error>
							<mat-error
								*ngIf='name?.errors?.maxlength'
								i18n='mat-error@@support-dialog-name-maxlength'
							>Введите максимум 50 символов!
							</mat-error>
							<mat-error
								*ngIf='name?.errors?.minlength'
								i18n='mat-error@@support-dialog-name-minlength'
							>Введите минимум 3 символа!
							</mat-error>
							<mat-error *ngIf='name.errors?.serverError'>
								{{ name.errors?.serverError }}
							</mat-error>
						</mat-form-field>
						<!--  Email   -->
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
							hideRequiredMarker
						>
							<mat-label>{{ email | supportDialog: 'propertyTitle' }}</mat-label>
							<input
								maxlength='64'
								matInput
								formControlName='email'
								type='email'
							>
							<mat-error
								*ngIf='email.errors?.required'
								i18n='mat-error@@support-dialog-email-required'
							>Обязательное поле
							</mat-error>
							<mat-error
								*ngIf='email.errors?.email'
								i18n='mat-error@@support-dialog-email-email'
							>Эл. почта введена не корректно!
							</mat-error>
							<mat-error *ngIf='email.errors?.serverError'>
								{{ email.errors?.serverError }}
							</mat-error>
						</mat-form-field>
						<!--  Category-->
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
							hideRequiredMarker
						>
							<mat-icon
								class='pd-field-prefix-icon'
								svgIcon='interface-compare'
								matPrefix
							></mat-icon>
							<mat-label>{{ category | supportDialog: 'propertyTitle' }}</mat-label>
							<pd-select
								formControlName='category'
								[compareWith]='compareEnum'
								required
							>
								<pd-select-option
									*ngFor='let item of categoryItems | supportCategories'
									[value]='+item.key'
								>
									{{item.value}}
								</pd-select-option>
							</pd-select>
							<button
								class='pd-icon-button gray'
								matSuffix
								mat-icon-button
								type='button'
							>
								<mat-icon
									class='pd-icon'
									svgIcon='arrow-down-2'
								></mat-icon>
							</button>
						</mat-form-field>
						<!-- Description -->
						<mat-form-field
							class='pd-input-form-field pd-textarea-form-field'
							appearance='fill'
							appExtendableTextArea
							hideRequiredMarker
						>
							<mat-label>{{ 'description' | supportDialog: 'propertyTitle' }}</mat-label>
							<tui-scrollbar class='pd-scrollbar pd-textarea-scrollbar'>
								<div class='pd-textarea-wrapper'>
									<div class='pd-textarea-relative-wrapper'>
										<div
											class='pd-textarea-pseudo-content'
											aria-hidden='true'
										>
											<span class='pd-textarea-pseudo'></span>
											&nbsp;
										</div>
										<textarea
											class='pd-textarea'
											matInput
											formControlName='description'
											rows='5'
										></textarea>
									</div>
								</div>
							</tui-scrollbar>
							<button
								class='pd-icon-button gray'
								matSuffix
								mat-icon-button
								type='button'
								tabindex='-1'
							>
								<mat-icon
									class='pd-icon'
									svgIcon='additional2-stretch'
								></mat-icon>
							</button>
							<mat-error
								*ngIf='description.errors?.required'
								i18n='mat-error@@support-dialog-description-required'
							>Обязательное поле
							</mat-error>
							<mat-error *ngIf='description.errors?.serverError'>
								{{ description.errors?.serverError }}
							</mat-error>
						</mat-form-field>
					</div>
				</div>
			</form>
		</div>
	</div>
</tui-scrollbar>
<div class='pd-dialog-footer align-end'>
	<div class='pd-dialog-footer-buttons'>
		<button
			mat-raised-button
			class='pd-button-secondary large'
			type='reset'
			[mat-dialog-close]='false'
		>
			Отменить
		</button>
		<button
			form='supportForm'
			mat-raised-button
			class='pd-button large'
			type='submit'
			[disabled]='(saving | async) || supportForm.invalid'
		>
			<ng-container *ngIf='!(saving | async); else isSaving'>Отправить</ng-container>
			<ng-template #isSaving>
				Отправляем
			</ng-template>
		</button>
	</div>
</div>
