<main class='not-found-page-wrapper'>
	<div class='not-found-page'>
		<div class='title-wrapper'>
			<a
				class='return-link'
				[routerLink]='route'
			>
				<mat-icon svgIcon='arrow-left-2'></mat-icon>
				<p>
					Назад
				</p>
			</a>

			<a
				class='return-link-main'
				routerLink='/'
			>
				На главную
			</a>
		</div>
		<img class='not-found-image' src='assets/404/img.png' />
	</div>
</main>


