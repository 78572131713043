<ng-container *ngIf='currentAppUserView | async as currentUser'>
	<ng-container *ngIf='currentUser.categoryMarks.length > 0; else noRating'>
		<mat-expansion-panel
			*ngFor='let category of currentUser.categoryMarks'
			class='pd-expansion-panel'
			[hideToggle]='true'
		>
			<mat-expansion-panel-header>
				<div class='pd-categories-rate-header'>
					<div class='pd-categories-rate-title-wrapper'>
						<button
							class='pd-icon-button'
							mat-icon-button
						>
							<mat-icon
								class='pd-icon pd-profile-block-toggle-icon'
								svgIcon='additional-chevron-down'
							>
							</mat-icon>
						</button>
						<p class='pd-categories-rate-title'>
							{{category.category.name}} - {{category.workCountSuccessPercent}}%
						</p>
					</div>
					<pdw-progress-bar
						[matTooltip]='getTooltip(category)'
						[value]='category.workCountSuccessPercent'
						[color]="'main-blue'"
					>
					</pdw-progress-bar>
				</div>
			</mat-expansion-panel-header>
			<ng-template matExpansionPanelContent>
				<app-app-user-category-works
					[currentUserView]='currentUser'
					[categoryMarkView]='category'
				>
				</app-app-user-category-works>
			</ng-template>
		</mat-expansion-panel>
	</ng-container>
	<ng-template #noRating>
		<div class='pd-profile-block-no-content-wrapper'>
			<p class='pd-profile-subtitle'>Рейтинга пока нет</p>
			<p class='pd-profile-text'>Скоро он появится здесь</p>
		</div>
	</ng-template>
</ng-container>
