import {Pipe, PipeTransform} from '@angular/core';
import {AbstractExtensionEnumPipe} from "@public/pipes/abstract-extension-enum.pipe";

@Pipe({
  name: 'workSpecTag'
})
export class WorkSpecTagPipe extends AbstractExtensionEnumPipe implements PipeTransform {
  values = {
    0: "Мои",
    1: "Подходящие",
    2: "Избранное",
    3: "Все",
  };

  transform(value: any, ...args: any[]): any {
    return this.getTransform(value, args);
  }
}
