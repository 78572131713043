<div
	*ngIf='currentAppUserView | async as currentAppUser'
	class='pd-profile-avatar'
>
	<div
		class='pd-profile-avatar-wrapper'
		[style.background-color]='avatarColor(currentAppUser)'
		(mouseover)="setShowEditBtn()"
		(mouseleave)="setHideEditBtn()"
	>
		<ng-container *ngIf='currentAppUser.hasAvatar; else noAvatar'>
			<mat-spinner
				*ngIf='loading$ | async'
				[diameter]='35'
				[strokeWidth]='2'
			></mat-spinner>
			<div class="wrapper" *ngIf='currentAppUser.hasAvatar && showEditBtn'>
				<button
					*ngIf='canUpdate'
					class='pd-profile-edit-btn'
					mat-raised-button
					(click)='goUpdateAvatar()'
				>
					<mat-icon
						class='pd-icon'
						svgIcon='additional-camera'
					></mat-icon>
					<br>
					Сменить фото профиля
				</button>
			</div>
			<img
				*ngIf='currentUserView | avatar | async as avatar'
				class='pd-profile-avatar-image'
				[ngStyle]="{visibility: (loading$ | async) ? 'hidden' : ''}"
				(load)='onLoad()'
				[src]='avatar'
				alt='Avatar Image'
			/>
		</ng-container>
		<ng-template #noAvatar>
			<pdw-user-initial-letters
				[user]='currentAppUser'
				[canUpdate]='canUpdate'
				[size]="IMAGE_SIZE.BIG"
			>
			</pdw-user-initial-letters>
		</ng-template>
		<ng-container *ngIf='canUpdate'>
			<ng-container *ngIf='!currentAppUser.hasAvatar'>
				<button
					class='pd-button-secondary small pd-profile-avatar-btn'
					mat-raised-button
					(click)='goUpdateAvatar()'
				>
					<mat-icon
						class='pd-icon'
						svgIcon='additional-plus'
					></mat-icon>
					Добавить фото
				</button>
			</ng-container>
		</ng-container>
		<div
			class='pd-profile-online-status pd-profile-online-status-user'
			[ngClass]="{'online' : isCurrentUserOnline}"
			[matTooltip]="isCurrentUserOnline ? 'В сети' : currentAppUser.lastConnection | appUserLastConnection"
		></div>
	</div>
</div>
