<ng-container *ngIf='!(loadingData | async); else isLoading'>
	<ng-container *ngIf='workView'>
		<ng-container
			*ngIf='{
      data: dataSource.data | async,
      count: dataSource.count | async
    } as both'
		>
			<ng-container *ngIf='both.count && both.data.length; else emptySource'>
				<div class='pd-users pd-work-matched-users cards-container'>
					<pdw-card-matched-employee
						*ngFor='let element of both.data | slice: 0 : (pageIndex + 1) * pageSize'
						[user]='element'
					>

					</pdw-card-matched-employee>

				</div>
				<div *ngIf='remain | async as remain; else noRemain'>
					<button
						class='pd-button-additional pd-button-additional-cards'
						(click)='goNextPage()'
						*ngIf='remain > 0; else noRemain'
						[disabled]='loadingNextPage | async'
					>
						{{loadingNextPage.value ? 'Загружаем' : 'Показать еще' }}
					</button>
				</div>
				<ng-template #noRemain>
					<ng-container *ngIf='pageIndex !== 0'>
						<p class='pd-basis-no-remain'>Вы посмотрели всех специалистов</p>
					</ng-container>
				</ng-template>
			</ng-container>
			<ng-template #emptySource>
				<div class='pd-basis-card-empty'>
					<div class='pd-basis-card-empty-inner'>
						<mat-icon
							class='pd-icon'
							[svgIcon]="'additional2-alert-exclamation-2-100-100'"
						></mat-icon>
						<h2 class='pd-basis-card-empty-title'>Подходящих специалистов не найдено</h2>
					</div>
				</div>
			</ng-template>

		</ng-container>
	</ng-container>
</ng-container>
<ng-template #isLoading>
	<div class='pd-basis-card-empty'>
		<div class='pd-basis-card-empty-inner'>
			<mat-spinner
				[diameter]='70'
				[strokeWidth]='5'
			></mat-spinner>
		</div>
	</div>
</ng-template>
