import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppUserType } from '@models/app-user-view';
import { RoleService } from '@core/services/role.service';
import { AuthService } from '@core/services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class RolePermissionGuard implements CanActivate {

	private readonly USER_TYPE = AppUserType;

	constructor(private router: Router,
				private roleService: RoleService,
				private auth: AuthService) {
	}

	canActivate(
		route: ActivatedRouteSnapshot, //AppUserType[] - массив с ролями, которые имеют доступ
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const { permissions } = route.data;
		if (!this.auth.isLoggedIn() && permissions.includes(this.USER_TYPE.none)) return true;

		if (permissions?.some((r: AppUserType) => this.roleService.hasPermission(r))) {
			return true;
		} else {
			return this.router.createUrlTree(['/landing']);
		}
	}

}
