import { Component, Input, OnChanges, OnInit, Optional, Self, SimpleChanges } from '@angular/core';
import {
	ControlValueAccessor,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	NgControl
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-checkbox-list',
	templateUrl: './checkbox-list.component.html',
	styleUrls: ['./checkbox-list.component.scss'],
	providers: [DestroyService]
})
export class CheckboxListComponent implements OnInit, ControlValueAccessor, OnChanges {

	@Input() options: any[] = [];
	@Input() label: string;
	@Input() name?: string;
	@Input() optionKey: string | null = null;

	result: any[] = [];
	onChange = (_ => {
	});
	onTouched = (() => {
	});
	disabled = false;

	formGroup: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private destroy$: DestroyService,
		@Optional() @Self() private ngControl: NgControl
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = this;
		}
	}

	ngOnInit(): void {
		if (this.options) {
			this.updateForm();
		}
	}

	updateForm(): void {
		let mergedOptions: boolean[] = []
		if (this.ngControl?.value && this.optionKey) {
			mergedOptions = this.options?.map(item => {
				return this.ngControl?.value.find(filledValue => filledValue[this.optionKey] === item[this.optionKey]);
			})
		}

		if (mergedOptions?.length) {
			this.formGroup = this.formBuilder.group({
				values: this.formBuilder?.array(mergedOptions?.map((x) => new FormControl(x)) ?? [])
			});
		} else {
			this.formGroup = this.formBuilder.group({
				values: this.formBuilder?.array(this.options?.map((x) => new FormControl(x[this.optionKey])) ?? [])
			});
		}


		this.valuesArray?.valueChanges
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: values => {
					values.forEach((v, i) => {
						let resultIndex = 0;
						const optionValue = this.options.at(i);
						if (this.optionKey) {
							resultIndex = this.result.findIndex(r => {
								return r[this.optionKey] === optionValue[this.optionKey]
							});
						} else {
							resultIndex = this.result.findIndex(r => {
								return r === optionValue
							});
						}

						if (v && resultIndex < 0) {
							this.result.push(optionValue);
						} else if (!v && resultIndex >= 0) {
							this.result.splice(resultIndex, 1);
						}
					});

					this.onChange(this.result.length ? this.result : null);
				}
			});
	}

	get valuesArray(): FormArray {
		return this.formGroup?.get('values') as FormArray;
	}

	writeValue(obj: any[]) {
		if (!Array.isArray(obj)) {
			this.formGroup?.reset([], { emitEvent: false });
			this.result = [];
			return;
		}

		this.valuesArray?.controls?.forEach((control, index) => {
			const optionValue = this.options.at(index);

			if (obj.includes(optionValue)) {
				control.setValue(true, { emitEvent: false });
			} else {
				control.setValue(false, { emitEvent: false });
			}
		});


		this.result = obj;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	displayedName<T extends {name: string} | string>(option: T): string  {
		if (typeof option === 'object' && option.name) {
			return this.label + ' ' + option.name;
		  }
			return this.label + ' ' + option;

	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.options.previousValue) {
			this.updateForm();
		}
	}

}
