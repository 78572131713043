<div class="pd-sequence">
  <div class="item" *ngFor="let skill of data | slice:0:maxCount; let i = index">
    {{ skill.name }}
    <div class="mark" *ngIf="isMarked(skill)">
      <mat-icon color="primary">checked</mat-icon>
    </div>
    <ng-container *ngIf="i === maxCount - 1 && data.length > maxCount && !(showAll | async)">
      <button mat-button color="primary" matTooltip="Показать все" (click)="goShow()">
        <mat-icon>keyboard_double_arrow_right</mat-icon>
      </button>
    </ng-container>
  </div>
  <ng-container *ngIf="showAll | async">
    <div class="item" *ngFor="let skill of data | slice:maxCount; let i = index">
      {{ skill.name }}
      <ng-container *ngIf="i === data.length - maxCount - 1 && data.length > maxCount && (showAll | async)">
        <button mat-button color="primary" matTooltip="Скрыть последние" (click)="goHide()">
          <mat-icon>keyboard_double_arrow_left</mat-icon>
        </button>
      </ng-container>
    </div>
  </ng-container>
</div>
