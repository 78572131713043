<div class='pdw-spec-layout'>
	<div class='pdw-spec-layout-container'>
		<section class='pdw-spec-start'>
			<h1 class='pdw-spec-title'>
				Инженер, найди&nbsp;<span class='title-bold'>работу</span>
				на&nbsp;<span class='title-bold'>Profdepo.work</span>
			</h1>
			<h2 class='pdw-spec-subtitle'>
				Более
				<span
					class='subtitle-bold'
					*ngIf='allCount$ | async as count'
				>{{round(count) | numberToWordDeclension: ['вакансий', 'вакансий', 'вакансии'] : true}}</span>
				для инженеров
			</h2>
			<form [formGroup]="searchFormgroup" class='pdw-spec-form'>
				<label class='label'>
					<mat-form-field
						class='pd-input-form-field spec-form-input'
						appearance='fill'
						hideRequiredMarker
					>
						<mat-label>Профессия, должность</mat-label>
						<input
							matInput
							type='text'
						    formControlName="proffession"
						>
					</mat-form-field>
				</label>
				<label class='label'>
					<mat-form-field
						class='pd-input-form-field spec-form-input'
						appearance='fill'
						hideRequiredMarker
					>
						<mat-label>Город</mat-label>

						<input
							matInput
							type='text'
						 formControlName="cities"
						 [matAutocomplete]="cities"
						>
						<mat-autocomplete #cities="matAutocomplete">
							<mat-option *ngFor="let city of (cities$ | async)" [value]="city">
								{{city}}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</label>
				<button class='search-button' (click)="openlink()">Поиск</button>
			</form>
				<div *ngIf="(loading$ | async)"  class="search-wrapper">
					<div class="text-wrapper">
						<p class="search-text">По Вашему запросу найдено вакансий: {{count$ | async}}</p>
					</div>
					<button class="response-button" (click)="goToAgregator()">Откликнуться</button>
				</div>
		</section>

		<section class='advice'>
			<div class='advice-image'>
				<img
					src='assets/spec-layout/hands.png'
					alt='hands'
				>
			</div>
			<div class='advice-wrapper advice-wrapper-mobile'>
				<h2 class='advice-title'>
					Зарегистрируйтесь, -
					и&nbsp;используйте то, что поможет
					вам зарабатывать больше:
				</h2>
				<div class='cards-wrapper'>
					<mat-card class='salary-card'>
						<mat-card-content>
							<h3 class='salary-card-title'>
								Зарплатный консультант
							</h3>
							<p class='salary-card-text'>
								Заполните анкету, укажите город и желаемую профессию -
								наш ИИ-алгоритм подскажет, сколько стоит ваш опыт и экспертиза
							</p>
							<div class='btns-wrapper'>
								<a
									target='_blank'
									[routerLink]='isAuthorized() ? "/salary" : "/register"'
									class='salary-card-btn try-btn'
								>Попробовать</a>
							</div>
						</mat-card-content>
					</mat-card>
					<mat-card class='salary-card'>
						<mat-card-content>
							<h3 class='salary-card-title'>
								Агрегатор инженерных вакансий
							</h3>
							<p class='salary-card-text'>
								Получайте желаемые актуальные предложения по работе первыми -
								и из одного источника. Мы найдём то, что вам интересно, пришлём
								в телеграм, на почту, или в личный кабинет Profdepo
							</p>
							<div class='btns-wrapper'>
								<a
									target='_blank'
									[routerLink]='isAuthorized() ? "/jobs/careergid" : "/register"'
									class='salary-card-btn try-btn'
								>Попробовать</a>
							</div>
						</mat-card-content>
					</mat-card>
				</div>
			</div>
		</section>
		<section class='who-us'>
			<div class='image-wrapper'>
				<img
					src='assets/spec-layout/who-us-image.png'
					alt='who-us'
				>
			</div>
			<div class='who-us-description'>
				<h2 class='who-us-title'>
					Кто мы?
				</h2>
				<div class='who-us-text-wrapper'>
					<p class='who-us-text'>
						PROFDEPO - HR-сервис для инженеров<br>
						и инженерных компаний.
					</p>
					<p class='who-us-text'>
						Мы помогаем инженерам и проектным организациям зарабатывать больше<br> и проще -
						с помощью собственных AI <br>HR-инструментов.
					</p>
				</div>

				<a
					target='_blank'
					routerLink='/register'
					class='who-us-btn'
				>
					Зарегистрируйтесь в PROFDEPO
				</a>
				<div class='social'>
					<h3 class='social-title'>
						Мы в соцсетях
					</h3>
					<ul class='social-links-list'>
						<li class='social-links-list-item'>
							<a
								mat-icon-button
								class='pd-icon-button icon-link'
								href='https://t.me/profdepo_ru'
								target='_blank'
							>
								<mat-icon
									class='pdw-icon'
									[svgIcon]="'additional2-tg'"
								></mat-icon>
							</a>
						</li>
						<li class='social-links-list-item'>
							<a
								mat-icon-button
								class='pd-icon-button icon-link'
								href='https://vk.com/profdepo_ru'
								target='_blank'
							>
								<mat-icon
									class='pdw-icon'
									[svgIcon]="'additional2-vk'"
								></mat-icon>
							</a>
						</li>
						<li class='social-links-list-item text-link'>
							<a
								mat-icon-button
								class='pd-icon-button'
								target='_blank'
								href='https://profdepo.ru/'
							>
								Profdepo.ru
							</a>
						</li>
					</ul>
				</div>
			</div>
		</section>

		<section class='register-section'>
			<div class='advice-wrapper'>
				<div class='register-image'>
					<img src='assets/spec-layout/specialist.svg' />
				</div>

				<div class='register-block'>
					<div class='register-wrapper'>
						<h1>
							Зарегистрируйтесь
							и найдите работу
							уже сегодня!
						</h1>

						<form
							class='register-form'
							[formGroup]='formGroup'
						>
							<mat-form-field
								class='pd-input-form-field custom-form-field'
								appearance='fill'
								hideRequiredMarker
							>
								<mat-label>Почта</mat-label>
								<input
									matInput
									formControlName='email'
								/>
								<mat-error
									*ngIf='formGroup.email?.errors?.required'
									i18n='mat-error@@register-email-required'
								>Обязательное поле
								</mat-error>
								<mat-error
									*ngIf='formGroup.email?.errors?.pattern'
								>Email введен не корректно!
								</mat-error>
							</mat-form-field>
							<mat-form-field class='pd-input-form-field custom-form-field' appearance='fill' hideRequiredMarker>
								<mat-label>Дата рождения</mat-label>
								<input matInput [matDatepicker]="birthdayPicker" formControlName='birthday' [maskito]="maskitoOptions"
								placeholder='00.00.0000'>
								<mat-datepicker-toggle matSuffix [for]="birthdayPicker">
									<mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon></mat-datepicker-toggle>
								<mat-datepicker #birthdayPicker></mat-datepicker>
								<mat-error *ngIf='formGroup.birthday?.errors?.required' i18n='mat-error@@register-birthday-required'>Обязательное
									поле
								</mat-error>
								<mat-error
								*ngIf='formGroup.birthday?.errors?.comingOfAge && !formGroup.birthday?.errors?.dateIsEarlier && !formGroup.birthday?.errors?.matDatepickerParse && !formGroup.birthday?.errors?.dateIsLater'
								i18n='mat-error@@app-user-personal-dialog-birthday-comingOfAge'>
								Кажется, ошибка: возраст менее 18 лет.
							</mat-error>
							<mat-error
								*ngIf='formGroup.birthday?.errors?.dateIsEarlier && !formGroup.birthday?.errors?.comingOfAge && !formGroup.birthday?.errors?.matDatepickerParse && !formGroup.birthday?.errors?.dateIsLater'>
								Дата рождения не должна быть меньше 01.01.1900
							</mat-error>
							<mat-error
								*ngIf='formGroup.birthday?.errors?.dateIsLater && !formGroup.birthday?.errors?.dateIsEarlier && !formGroup.birthday?.errors?.matDatepickerParse'>
								Дата не может быть больше текущей
							</mat-error>
							<mat-error
								*ngIf='formGroup.birthday?.errors?.matDatepickerParse && !formGroup.birthday?.errors?.comingOfAge && !formGroup.birthday?.errors?.dateIsEarlier && !formGroup.birthday?.errors?.dateIsLater'>
								Вы ввели не существующую дату
							</mat-error>
							<mat-error *ngIf='formGroup.birthday?.errors?.serverError'>
								{{ formGroup.birthday?.errors?.serverError }}
							</mat-error>
							</mat-form-field>
							<pdw-password-input
								[displayEyeButton]='true'
								formControlName='password'
							>

								<mat-label>Пароль</mat-label>
								<mat-error
									*ngIf='formGroup.password?.errors?.required'
									i18n='mat-error@@register-password-required'
								>Обязательное поле
								</mat-error>
								<mat-error
									*ngIf='formGroup.password?.errors?.pattern'
									i18n='mat-error@@register-password-pattern'
								>
									Пароль должен быть не менее 8 символов и содержать цифры и буквы большого и малого
									регистра
								</mat-error>
								<mat-error
									*ngIf='formGroup.password?.errors?.maxlength'
								>Введите не более 20 символов!
								</mat-error>
							</pdw-password-input>

							<pdw-password-input
								[displayEyeButton]='true'
								formControlName='passwordConfirm'
							>
								<mat-label>Повторите пароль</mat-label>

								<mat-error
									*ngIf='formGroup.passwordConfirm.errors?.required'
									i18n='mat-error@@register-password-required'
								>Обязательное поле
								</mat-error>
								<mat-error *ngIf="formGroup?.hasError('confirmPassword','passwordConfirm')"
									i18n='mat-error@@register-passwordConfirm-notSame'>Указанные пароли не совпадают!
								</mat-error>
							</pdw-password-input>
						</form>

						<span>
							Продолжая, вы принимаете
							<a
								class='pd-link pd-register-link'
								href='https://profdepo.ru/termsOfUse'
								target='_blank'
							>пользовательское соглашение</a
							>
							и
							<a
								class='pd-link pd-register-link'
								href='https://profdepo.ru/privacyPolicy'
								target='_blank'
							>политику конфиденциальности</a
							>
						</span>

						<button
							mat-raised-button
							class='pd-landing-button pd-button register-button'
							[disabled]='(!formGroup.valid) || (loading$ | async)'
							(click)='submit(formGroup)'
						>
							{{(loading$ | async) ? "Загрузка" : "Зарегистрироваться"}}
						</button>
					</div>
				</div>
			</div>
		</section>

		<section class='news-section landing-container'>
			<div
				class='news-block'
			>
				<h1 class='news-title'>Новости</h1>
				<div>
					<p>Статьи из индустрии инжиниринга, строительства и IT.</p>
					<p>А также обновления и новости сервиса.</p>
				</div>
				<a
					mat-raised-button
					class='pd-landing-button pd-button news'
					href='https://t.me/profdepo_ru'
				>Больше новостей на
					Profdepo.ru</a>
			</div>

			<app-landing-news-posts

			></app-landing-news-posts>

		</section>
	</div>
</div>
