<div class='pd-dialog-header'>
	<p class='pd-dialog-header-title'>{{ data.title }}</p>
	<button
		class='pd-icon-button'
		mat-icon-button
		[mat-dialog-close]='false'
	>
		<mat-icon svgIcon='interface-close'></mat-icon>
	</button>
</div>
<tui-scrollbar class='pd-scrollbar pd-dialog-scroll'>
	<div class='pd-dialog-content'>
		<div class='pd-dialog-confirmation'>
			<ng-container [ngSwitch]='data.type'>
				<ng-container *ngSwitchCase='1'>
					<mat-icon
						*ngIf='data.type'
						class='pd-dialog-confirmation-icon'
						[svgIcon]="'additional2-alert-question-2-100-100'"
					></mat-icon>
				</ng-container>
				<ng-container *ngSwitchCase='2'>
					<mat-icon
						*ngIf='data.type'
						class='pd-dialog-confirmation-icon'
						[svgIcon]="'additional-alert-rectangle-100-100'"
					></mat-icon>
				</ng-container>
			</ng-container>

			<p class='pd-dialog-confirmation-message'>
				{{data.message}}<span *ngIf='!data.value && data.questionMark'>?</span>
			</p>
			<p
				*ngIf='data.value'
				class='pd-dialog-confirmation-value'
			>
				{{'&#8220;' + data.value + '&#8221;'}}<span *ngIf='data.questionMark'>?</span>
			</p>
		</div>
	</div>
</tui-scrollbar>
<div class='pd-dialog-footer align-end'>
	<div class='pd-dialog-footer-buttons'>
		<button
			mat-raised-button
			class='pd-button-secondary large'
			(click)='onNo()'
		>
			Нет
		</button>
		<button
			mat-raised-button
			class='pd-button large'
			(click)='onYes()'
		>
			Да
		</button>
	</div>
</div>
