import { Pipe, PipeTransform } from '@angular/core';
import {AbstractExtensionEnumPipe} from "@public/pipes/abstract-extension-enum.pipe";

@Pipe({
  name: 'appUserTag'
})
export class AppUserTagPipe extends AbstractExtensionEnumPipe implements PipeTransform {
  values = {
    0: "В поиске",
    1: "Откликнулся",
    2: "Приглашен",
    3: "Работает",
    4: "Избранное",
    5: "Все",
  };

  transform(value: any, ...args: any[]): any {
    return this.getTransform(value, args);
  }
}
