<div *ngIf='currentAppUserView | async as currentAppUser'>
	<div
		class='pd-profile-about-top-wrapper'
		[ngClass]="(isEducationExist() && currentAppUser?.educations?.length) ? '' : 'empty'"
	>
		<h3 class='pd-profile-subtitle'>
			<mat-icon
				class='pd-icon light-gray'
				svgIcon='additional-check-rectangle'
			></mat-icon>
			Образование
		</h3>
		<ng-container *ngIf='canUpdate'>
			<button
				*ngIf='isEducationExist() && currentAppUser?.educations?.length; else noEducations'
				class='pd-button-secondary small'
				mat-raised-button
				(click)='goUpdateEducations()'
			>
				<mat-icon
					class='pd-icon'
					svgIcon='additional2-edit'
				></mat-icon>
				<p class='pd-profile-button-text'>Редактировать</p>
			</button>
			<ng-template #noEducations>
				<button
					class='pd-button-secondary small'
					mat-raised-button
					(click)='goUpdateEducations()'
				>
					<mat-icon
						class='pd-icon'
						svgIcon='additional-plus'
					></mat-icon>
					<p class='pd-profile-button-text'>Добавить</p>
				</button>
			</ng-template>
		</ng-container>
	</div>
	<ng-container *ngIf='isEducationExist() && currentAppUser?.educations?.length;'>
		<mat-table
			class='pd-profile-table'
			[dataSource]='educationDataSource'
			multiTemplateDataRows
		>
			<!-- Level Column -->
			<ng-container matColumnDef='level'>
				<mat-cell *matCellDef='let element'>
					<h2
						class='pd-profile-table-row-title gray'
					>{{ element.level | educationLevel }} {{ element.level <= 3 ? 'образование' : '' }}</h2>
				</mat-cell>
			</ng-container>
			<!-- Period Column -->
			<ng-container matColumnDef='period'>
				<mat-cell
					class='pd-profile-table-period pd-profile-table-pointed-cell'
					*matCellDef='let element'
				>
					<p class='pd-profile-text'>{{ element.year }}</p>
				</mat-cell>
			</ng-container>
			<!-- Description Column -->
			<ng-container matColumnDef='description'>
				<mat-cell *matCellDef='let element'>
					<div class='pd-profile-inner-column'>
						<h3 class='pd-profile-text-medium pd-profile-table-description-name'>{{ element.name }}</h3>
						<p class='pd-profile-text gray'>{{getSectorAndSpeciality(element)}}</p>
					</div>
				</mat-cell>
			</ng-container>
			<mat-row
				class='pd-profile-table-level-row'
				*matRowDef="let row; columns: ['level']"
				[ngClass]='hiddenRow(row)'
			></mat-row>
			<mat-row
				class='pd-profile-table-period-row'
				*matRowDef="let row; columns: ['period', 'description']"
			></mat-row>
		</mat-table>
	</ng-container>
</div>
