<ng-container *ngIf="skillView">
  <div fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="column">
      <mat-table [dataSource]="dataSource">
        <!--  Title  -->
        <ng-container matColumnDef="title">
          <mat-cell *matCellDef="let element">
            {{ element.title }}
          </mat-cell>
        </ng-container>

        <!--  Value  -->
        <ng-container matColumnDef="value">
          <mat-cell *matCellDef="let element">
            {{ element.value }}
          </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: ['title','value'];"></mat-row>
      </mat-table>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <button mat-raised-button
              color="primary"
              [routerLink]="updateLink.concat([skillView.id])">
        Изменить...
      </button>
    </div>
  </div>
</ng-container>
