import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AppUserView } from "@models/app-user-view";
import { filter, map, takeUntil } from "rxjs/operators";
import { AppUserService } from "@core/services/app-user.service";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { errorTitle } from "@utils/helpers/error-helpers";
import { SupportDialogComponent } from "@public/support-dialog/support-dialog.component";
import { pushFakeHistoryState } from "@utils/functions";
import { UtilsService } from "@core/services/utils.service";
import { DestroyService, NotificationService } from "@profdepo-ui/core";

@Component({
	selector: "pdw-public-layout",
	templateUrl: "./public-layout.component.html",
	providers: [DestroyService],
	styleUrls: ["./public-layout.component.scss"],
})
export class PublicLayoutComponent implements OnInit, AfterViewInit {
	method: string;
	currentUser$: Observable<AppUserView>;
	mediaQuery980px = new BehaviorSubject<boolean>(false);

	@ViewChild("header") header: ElementRef;
	@ViewChild("headerContainer") headerContainer: ElementRef;
	@ViewChild("menuMobile") menuMobile: ElementRef;
	isMenuOpen = false;
	public isSpecPath$: Observable<boolean> = this.route.url.pipe(map((item) => item[0]?.path === "spec"));

	constructor(
		private route: ActivatedRoute,
		private appUserService: AppUserService,
		private dialog: MatDialog,
		private notificationService: NotificationService,
		private utilsService: UtilsService,
		private destroy$: DestroyService
	) {}

	ngOnInit(): void {
		this.method = this.route.snapshot.data.method;
		this.currentUser$ = this.appUserService.getActiveUserAsObservable();
	}

	ngAfterViewInit() {
		this.utilsService.mobileQuery980px.pipe(takeUntil(this.destroy$)).subscribe({
			next: (v) => {
				this.mediaQuery980px.next(v);
				this.menuMobile.nativeElement.classList.remove("active");
			},
		});
	}

	goSupport() {
		pushFakeHistoryState();
		this.dialog
			.open(SupportDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: "581px",
				minHeight: "320px",
				panelClass: ["pd-dialog"],
				data: {
					title: "Поддержка",
				},
			})
			.afterClosed()
			.pipe(filter((result) => result))
			.subscribe({
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				},
			});
	}

	menuOpen(): void {
		this.menuMobile.nativeElement.classList.add("active");
	}

	menuClose(): void {
		if (this.isMenuOpen == false) {
			this.menuMobile.nativeElement.classList.remove("active");
		} else {
			this.isMenuOpen = false;
		}
	}
}
