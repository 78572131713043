import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageUtilsService {

  constructor() { }
  
  public setVisitedUrlForce(value: string) {
		localStorage.setItem("beforeAuth", value);
  }

	public setVisitedUrl() {
		let restrictedList = ['register', 'login'];
		let url = localStorage.getItem('beforeAuth');
		let previousUrl = localStorage.getItem('previousUrl');
		if (url !== null) {
			let parcedUrl = url.replace('/', '');
			let parcedPreviousUrl = previousUrl.replace('/', '');
			if (restrictedList.includes(parcedUrl) || restrictedList.includes(parcedPreviousUrl)) {
				console.log(true);
				return;
			}
		}
		localStorage.setItem("beforeAuth", previousUrl);
	}

	public getVisitedUrl() {
		let lastUrl = localStorage.getItem('beforeAuth').toString();
		return lastUrl;
	}
}
