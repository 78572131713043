import {WorkView} from "@models/work-view";
import {ComplexValue} from "@models/complex-value";
import {AppUserView} from "@models/app-user-view";

export enum WorkHistoryState
{
  Draft,
  Skip,
  Accept,
  Reject,
  Auto
}
export class WorkHistoryView {
  public id: number;
  public work: WorkView;
  public attribute: string;
  public value: ComplexValue;
  public state: WorkHistoryState;
  public createAppUser: AppUserView;
  public modifyAppUser: AppUserView;
  public createTime: Date;
  public modifyTime: Date;
}
