import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { STEP, TwoFactorRegistrationRequest } from '@public/pages/salary-register-page/salary-register-page.types';
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors,
	Validators
} from '@angular/forms';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { catchError, finalize, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { dateNotEarlierValidator, dateNotLaterValidator, onlySpaceValidator } from '@utils/functions';
import { YesNo } from '@models/enums';
import { EducationLevel, EducationView } from '@models/education-view';
import { EMPTY, Observable, of } from 'rxjs';
import { AbstractSkillView } from '@models/abstract-skill-view';
import { HardSkillService } from '@core/services/hard.skill.service';
import { EMAIL_REGEX, PASSWORD_REGEX } from '@utils/constants';
import { checkPasswords } from '@utils/check-passwords';
import { AppUserService } from '@core/services/app-user.service';
import { ExperienceView } from '@models/experience-view';
import { HardSkillView } from '@models/hard-skill-view';
import { AppUserType, } from '@models/app-user-view';
import { removeEmpty } from '@utils/helpers/object-helpers';
import { TrueLoadingService } from '@core/services/true-loading.service';
import { AuthService } from '@core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SalaryData } from '@general/features/aggregator/types/salary-types';
import { SalaryAIData } from '@core/services/aggregator.service';
import { CustomValidators } from '@utils/validators/custom-validators';
import { HttpErrorResponse } from '@angular/common/http';
import { QueryParams } from '@utils/helpers/query.helper';

@Component({
	selector: 'pdw-salary-register-page',
	templateUrl: './salary-register-page.component.html',
	styleUrls: ['./salary-register-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, TrueLoadingService]
})
export class SalaryRegisterPageComponent implements OnInit {
	STEP_PAGE: STEP = STEP.EDUCATION;
	protected readonly STEP = STEP;
	PasswordsValidator = checkPasswords;
	formGroup: FormGroup;

	constructor(
		private fb: FormBuilder,
		private destroy$: DestroyService,
		private hardSkills: HardSkillService,
		@Inject(PASSWORD_REGEX) private readonly regex: string,
		@Inject(EMAIL_REGEX) private readonly emailRegex: string,
		private userService: AppUserService,
		private notificationService: NotificationService,
		public loading$: TrueLoadingService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private authService: AuthService
	) {
		this.loading$.next(false);
		this.formGroup = fb.group({
			education: fb.array<FormGroup>([]),
			experience: fb.array<FormGroup>([]),
			hardSkills: new FormControl(null, { validators: [Validators.required] }),
			registration: fb.group({
				email: new FormControl<string>(null, {
					validators: [
						Validators.required,
						Validators.pattern(emailRegex)
					]
				}),
				password: new FormControl<string>(null, {
					validators: [
						Validators.required,
						Validators.maxLength(20),
						Validators.pattern(regex)
					]
				}),
				passwordConfirm: new FormControl<string>(null, {
					validators: [
						Validators.required,
					]
				}),
			}, {
				validators: [this.PasswordsValidator]
			})
		});

		this.registrationGroup.get('passwordConfirm')
			.addValidators(CustomValidators.confirmPassword(
				this.registrationGroup.get('password') as FormControl,
				this.registrationGroup.get('passwordConfirm') as FormControl)
			);

	}

	get currentQuery(): QueryParams {
		return this.activatedRoute.snapshot.queryParams;
	}

	ngOnInit(): void {
		this.addFormGroup(this.educationForm, this.newEducationGroupInstance);
		this.addFormGroup(this.experienceFormArray, this.newExperienceGroupInstance);
	}

	get isCurrentFormValid(): boolean {
		switch (this.STEP_PAGE) {
			case STEP.PASSWORD:
				return this.registrationGroup.valid;
			case STEP.HARD_SKILLS:
				return this.hardSkillsControl.valid
			case STEP.EXP:
				return this.experienceFormArray.valid;
			case STEP.EDUCATION:
				return this.educationForm.valid;
		}
	}

	get educationForm(): FormArray<FormGroup> {
		return this.formGroup.get('education') as FormArray<FormGroup>;
	}

	get experienceFormArray(): FormArray<FormGroup> {
		return this.formGroup.get('experience') as FormArray<FormGroup>;
	}

	get hardSkillsControl(): FormControl {
		return this.formGroup.get('hardSkills') as FormControl;
	}

	get registrationGroup(): FormGroup {
		return this.formGroup.get('registration') as FormGroup;
	}

	getHardSkills(query: string): Observable<AbstractSkillView[]> {
		return this.hardSkills.all()
			.pipe(shareReplay(1), map(hardSkills => hardSkills.filter(hard => hard.name.includes(query))));
	}

	get label(): string {
		switch (this.STEP_PAGE) {
			case STEP.EDUCATION: {
				return 'Образование';
			}
			case STEP.EXP: {
				return 'Опыт работы';
			}
			case STEP.HARD_SKILLS: {
				return 'Профессиональные навыки';
			}
			case STEP.PASSWORD: {
				return 'Регистрация';
			}
		}
	}

	get pageTitle(): string {
		switch (this.STEP_PAGE) {
			case STEP.EDUCATION: {
				return 'Расскажите о своем образовании';
			}
			case STEP.EXP: {
				return 'Расскажите о своем опыте работы';
			}
			case STEP.HARD_SKILLS: {
				return 'Расскажите о своих профессиональных навыках ';
			}
			case STEP.PASSWORD: {
				return 'Последний штрих';
			}
		}
	}

	redirectBack(): void {
		this.router.navigateByUrl('/salary', {state: this.currentQuery});
	}

	submitRegistration(): void {
		this.loading$.next(true);
		const educations: Array<EducationView> = this.educationForm.value
			.filter(edu => Object.keys(removeEmpty(edu)).length > 1).map(edu => {
				return { ...edu, id: 0 }
			}) as Array<EducationView>;

		const experiences: Array<Omit<ExperienceView, 'tillNow'>> = this.experienceFormArray.value
			.filter(exp => Object.keys(removeEmpty(exp)).length > 1).map((exp: ExperienceView) => {
				const { tillNow, ...result } = exp;
				return { ...result, id: 0 }
			}) as Array<Omit<ExperienceView, 'tillNow'>>;

		const hardSkills: Array<HardSkillView> = (this.hardSkillsControl.value ?? [])

		const result: TwoFactorRegistrationRequest = {
			email: this.registrationGroup.get('email').value,
			password: this.registrationGroup.get('password').value,
			repeatPassword: this.registrationGroup.get('passwordConfirm').value,
			lastUrl: '',
			userName: '',
			type: AppUserType.specialist,
			educations: educations.length ? educations : null,
			experiences: experiences.length ? experiences : null,
			hardSkills: hardSkills.length ? hardSkills : null
		}
		this.userService.twoFactorRegistration(result)
			.pipe(
				switchMap(tokens => {
					this.authService.storeTokens(tokens);
					return this.userService.getActiveUserView();
				}),
				switchMap(res => {
					this.userService.setActiveUser(res);
					if (this.activatedRoute.snapshot.queryParams.city && this.activatedRoute.snapshot.queryParams.profession) {
						return this.userService.salaryAI({
							profession: this.activatedRoute.snapshot.queryParams.profession,
							city: this.activatedRoute.snapshot.queryParams.city
						} as SalaryData)
					}
					return EMPTY;
				}),
				finalize(() => {
					this.loading$.next(false);
				}),
				catchError((error) => {
					if (error instanceof HttpErrorResponse) {
						this.notificationService.showDanger(typeof error.error === 'object' && 'message' in error.error ? error.error.message : error.error);
					} else {
						this.notificationService.showDanger(error);
					}
					return EMPTY;
				}),
				takeUntil(this.destroy$),
			).subscribe((data: SalaryAIData) => {
			if (data) {
				this.router.navigateByUrl('/result', { state: data });
				return;
			}
			this.router.navigateByUrl('/salary');
		});
	}

	setNextStep(skipStep: boolean = false): void {
		switch (this.STEP_PAGE) {
			case STEP.EDUCATION: {
				this.STEP_PAGE = STEP.EXP;
				if (skipStep) {
					this.educationForm.controls.length = 1;
					this.clearForm(this.educationForm)
				}
				break
			}
			case STEP.EXP: {
				this.STEP_PAGE = STEP.HARD_SKILLS;
				if (skipStep) {
					this.experienceFormArray.controls.length = 1;
					this.clearForm(this.experienceFormArray)
				}
				break
			}
			case STEP.HARD_SKILLS: {
				this.STEP_PAGE = STEP.PASSWORD;
				if (skipStep) {
					this.clearForm(this.hardSkillsControl)
				}
				break
			}
			case STEP.PASSWORD: {
				this.STEP_PAGE = STEP.EDUCATION;
				break
			}
		}
	}

	checkCurrentStep(step: STEP): boolean {
		return this.STEP_PAGE === step;
	}

	setStep(step: STEP): void {
		this.STEP_PAGE = step;
	}

	addFormGroup(formArray: FormArray<FormGroup>, formGroup: FormGroup) {
		formArray.push(formGroup);
	}

	removeOneFormGroup<T extends FormGroup<Record<string, FormControl>>>(data: {
		parentForm: FormArray<T> | FormGroup,
		index: number
	}): void {

		if (data.parentForm instanceof FormArray) {
			data.parentForm.removeAt(data.index);
			return;
		}
		data.parentForm.removeControl(data.index.toString());
	}

	clearForm(form: AbstractControl) {
		form.reset();
	}

	get newEducationGroupInstance(): FormGroup {
		return new FormGroup({
			level: new FormControl<EducationLevel>(null, {
				validators: [Validators.required],
			}),
			name: new FormControl<string>(null, {
				validators: [Validators.required, Validators.maxLength(150), onlySpaceValidator()],
			}),
			sector: new FormControl<string>(null, [Validators.maxLength(150), onlySpaceValidator()]),
			speciality: new FormControl<string>(null, [Validators.maxLength(150), onlySpaceValidator()]),
			year: new FormControl<number>(null, {
				validators: [Validators.required],
			}),
			isCourse: new FormControl<YesNo>(YesNo.no)
		})
	}

	get newExperienceGroupInstance(): FormGroup {
		return new FormGroup({
			startDate: new FormControl(null, {
				validators: [Validators.required, dateNotEarlierValidator(-2209003353000), dateNotLaterValidator(new Date().getTime())],
			}),
			endDate: new FormControl(null, {
				validators: [Validators.required, dateNotEarlierValidator(-2209003353000), dateNotLaterValidator(new Date().getTime())],
			}),
			tillNow: new FormControl<boolean>(null),
			companyName: new FormControl(null, {
				validators: [Validators.required, Validators.maxLength(500), onlySpaceValidator()],
			}),
			position: new FormControl(null, {
				validators: [Validators.required, Validators.maxLength(500), onlySpaceValidator()],
			}),
			description: new FormControl(null, {
				validators: [Validators.maxLength(1000)],
			}),
		}, {
			validators: [this.groupValidator]
		});
	}

	groupValidator(group: AbstractControl): ValidationErrors | null {
		const fromCtrl = group.get('startDate');
		const toCtrl = group.get('endDate');
		const tillNow = group.get('tillNow');
		if (fromCtrl.value && toCtrl.value) {
			if (fromCtrl.value > toCtrl.value) {
				fromCtrl.setErrors({ ...fromCtrl.errors, dateError: 'error' });
				toCtrl.setErrors({ ...toCtrl.errors, dateError: 'error' });
				return { dateError: 'error' }
			} else {
				if (fromCtrl.errors && Object.keys(fromCtrl.errors).length > 0) {
					const {dateError, ...errors} = fromCtrl.errors;
					fromCtrl.setErrors(Object.keys(errors).length > 0 ? errors : null);
				} else {
					fromCtrl.setErrors(null);
				}

				if (toCtrl.errors && Object.keys(toCtrl.errors).length) {
					const {dateError, ...errors} = toCtrl.errors;
					toCtrl.setErrors(Object.keys(errors).length > 0 ? errors : null);
				} else {
					toCtrl.setErrors(null);
				}
				return null;
			}
		}

		if (fromCtrl.value && !toCtrl.value && tillNow.value) {
			return null;
		}

		return fromCtrl.value > toCtrl.value ? { dateError: true } : null;
	}
}
