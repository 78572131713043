import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AppUserType, AppUserView } from '@models/app-user-view';
import { diffDate } from '@utils/helpers/date-helpers';
import * as moment from 'moment';
import { BehaviorSubject, combineLatestWith, Observable, Subject, switchMap } from 'rxjs';
import { UserPersonalDialogComponent } from '@shared/user-personal-dialog/user-personal-dialog.component';
import { filter, takeUntil } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { MatDialog } from '@angular/material/dialog';
import { pushFakeHistoryState } from '@utils/functions';
import { YesNo } from '@models/enums';
import { AppUserService } from '@core/services/app-user.service';
import { AppUserGenderPipe } from '@public/pipes/app-user-gender.pipe';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { isCurrentUserRole } from '@utils/helpers/user-helper';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { WorksSelectDialogComponent } from '@shared/works-select-dialog/works-select-dialog.component';
import { AuthService } from '@core/services/auth.service';
import { RoleService } from '@core/services/role.service';
import { AuthDialogComponent } from '@shared/auth-dialog/auth-dialog.component';
import {Clipboard} from '@angular/cdk/clipboard';
@Component({
	selector: 'pdw-app-user-personal',
	templateUrl: './app-user-personal.component.html',
	host: { 'class': 'pd-personal' },
	providers: [DestroyService],
	styleUrls: ['./app-user-personal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AppUserPersonalComponent implements OnInit {
	@Input() canUpdate: boolean;
	isToShowContacts = false;

	@Input()
	set activeUserView(value: AppUserView) {
		this._activeUserView.next(value);
	};

	get activeUserView(): AppUserView {
		return this._activeUserView.getValue();
	}

	private _activeUserView = new BehaviorSubject<AppUserView>(null);

	@Input()
	set currentUserView(value: AppUserView) {
		this._currentUserView.next(value);
	};

	get currentUserView(): AppUserView {
		return this._currentUserView.getValue();
	}

	private _currentUserView = new BehaviorSubject<AppUserView>(null);
	currentAppUserView = new BehaviorSubject<AppUserView>(null);
	activeAppUserView = new BehaviorSubject<AppUserView>(null);
	canSeeContacts = new BehaviorSubject<boolean>(false);
	canUpdateState$ = new Subject<boolean>();
	both: Observable<AppUserView[]>;
	@Output() changed = new EventEmitter<any>();

	constructor(
		private dialog: MatDialog,
		private notificationService: NotificationService,
		private appUserService: AppUserService,
		@Inject(LOCALE_ID) public locale: string,
		private destroy$: DestroyService,
		private router: Router,
		public authService: AuthService,
		private roleService: RoleService,
		private clipboard: Clipboard
	) {
		this.both = this.currentAppUserView
			.pipe(
				combineLatestWith(this.activeAppUserView),
			);
	}

	ngOnInit(): void {
		this.canUpdateState$.next(this.canUpdate);

		const isToShowContacts = localStorage.getItem('isToShowContacts');

		if (isToShowContacts) {
			this.isToShowContacts = true;
		}

		setTimeout(() => {
			localStorage.removeItem('isToShowContacts');
		})

		this._currentUserView
			.pipe(
				combineLatestWith(this._activeUserView),
				filter(x => x[0] !== null || x[1] !== null),
			)
			.subscribe((data: AppUserView[]) => {
				this.currentAppUserView.next(data[0]);
				this.activeAppUserView.next(data[1]);
				if (!data[0] || !data[1]) return;

				if (this.currentAppUserView.value.id === this.activeAppUserView.value.id) {
					this.canSeeContacts.next(true);
					this.canUpdateState$.next(false);
				}

				const inviteSpecialist = localStorage.getItem('inviteSpecialist');

				if (inviteSpecialist) {
					this.inviteSpecialist();
				}

				setTimeout(() => {
					localStorage.removeItem('inviteSpecialist');
				})
			});

		this.both
			.pipe(
				filter(x => x[0] !== null && x[1] !== null),
				filter(x => x[0].id !== x[1].id),
				switchMap(data => this.appUserService.canSeeContacts(data[0].id)
					.pipe(
						takeUntil(this.destroy$)
					)
				)
			)
			.subscribe({
				next: v => this.canSeeContacts.next(v),
				error: err => this.notificationService.showDanger(errorTitle(err))
			})
	}

	isAuth(): boolean {
		return this.authService.isLoggedIn();
	}

	appUserAge(birthday: Date): string {
		const diff = diffDate(new Date(Date.now()), birthday);
		const datePipe = new DatePipe(this.locale);
		return [
			diff.years > 1 ? null : 1,
			moment.duration(diff.years, 'year').locale(this.locale).humanize()
		].join(' ') + ', ' + datePipe.transform(birthday, 'longDate');
	}

	appUserGenderAndAge(appUser: AppUserView): string {
		const genderPipe = new AppUserGenderPipe();

		if (appUser.birthday && appUser.gender < 0) {
			return 'Пол не указан, ' +
				this.appUserAge(appUser.birthday);
		}

		if (appUser.birthday && appUser.gender >= 0) {
			return genderPipe.transform(appUser.gender) + ', ' +
				this.appUserAge(appUser.birthday);
		}

		if (!appUser.birthday && appUser.gender >= 0) {
			return genderPipe.transform(appUser.gender);
		}

		return 'Пол и дата рождения не указаны';
	}

	goEmailConfirmation(): void {
		this.appUserService.emailConfirmation(this.router.url.trim(), 1)
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: (x) => {
					this.notificationService.showPrimary(x.message);
				},
				error: (err) => {
					this.notificationService.showDanger(err);
				}
			});
	}

	goUpdatePersonal(): void {
		pushFakeHistoryState();

		this.dialog.open(UserPersonalDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				minHeight: '320px',
				panelClass: ['pd-dialog'],
				data: {
					title: 'Специалист',
					method: 'update',
					appUserView: this.currentAppUserView.value,
					parentUnsubscribe: this.destroy$
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
			)
			.subscribe({
				next: (data) => {
					if (data.email !== this.currentAppUserView.value.email) {
						this.goEmailConfirmation();
					}
					this.appUserService.setActiveUser(data)
					this.onChange('personal');
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	checkAuthBeforeInviteSpecialist() {
		if (this.authService.isLoggedIn()) {
			this.inviteSpecialist();
		} else {
			this.dialog.open(AuthDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: ['auth-dialog'],
			}).afterClosed().pipe(filter(Boolean)).subscribe(() => {
				localStorage.setItem('inviteSpecialist', 'true');
				localStorage.setItem('currentHref', window.location.href);
				window.location.reload();
			});
		}
	}

	inviteSpecialist() {
		this.dialog.open(WorksSelectDialogComponent, {
			autoFocus: true,
			disableClose: false,
			width: '581px',
			minHeight: '320px',
			panelClass: ['pd-dialog'],
			data: {
				title: 'Приглашение на работу',
				appUserView: this.currentAppUserView.value,
				parentUnsubscribe: this.destroy$
			}
		}).afterClosed()
			.pipe(
				filter(result => result),
			)
			.subscribe({
				next: () => {
					this.notificationService.showSuccess('Приглашение на работу для ' + `${this.currentAppUserView.value.firstname + ' ' + this.currentAppUserView.value.lastname}` + ' отправлено!');
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	/**
	 * Изменение openForWork
	 */
	goChangeState(): void {
		const tmpAppUserView = Object.assign({}, this.currentAppUserView.value);
		tmpAppUserView.openForWork = tmpAppUserView.openForWork === YesNo.no ? YesNo.yes : YesNo.no;
		this.appUserService.updateAppUserView(tmpAppUserView)
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: () => {
					this.notificationService.showSuccess('Статус успешно изменён');
					this.onChange('update openForWork');
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	onChange(value: any): void {
		this.changed.emit(value);
	}

	/**
	 * Цвет снэка открыт для работы
	 * @param appUser
	 */
	stateColorClass(appUser: AppUserView): string {
		return appUser.openForWork === YesNo.yes ? 'open' : 'closed'
	}

	/**
	 * Текст снэка открыт для работы
	 * @param appUser
	 */
	stateText(appUser: AppUserView): string {
		let openForWorkText = 'Открыт прием заявок';

		if (appUser.type === AppUserType.specialist) {
			openForWorkText = 'Открыт для работы';
		}

		return appUser.openForWork === YesNo.yes ? openForWorkText : 'Не ищу работу';
	}

	isReadyToRelocate(user: AppUserView, withCity?: boolean): string {
		if (withCity) {
			return `${user.city.name}, ${user.isReadyToRelocate === YesNo.yes ? ' готов к переезду' : ' не готов к переезду'}`;
		}
		return user.isReadyToRelocate === YesNo.yes ? 'Готов к переезду' : 'Не готов к переезду';
	}

	isCurrentUserSpecialist(currentUser: AppUserView): boolean {
		return isCurrentUserRole(currentUser, AppUserType.specialist);
	}

	isSamePerson(): boolean {
		return this.currentAppUserView?.value?.id === this.activeAppUserView?.value?.id
	}

	setUserStorage() {
		localStorage.setItem('redirectTo', this.currentAppUserView.value.id);
		this.notificationService.showPrimary('Для продолжения необходимо авторизоваться');

	}

	get isSpecialist(): boolean {
		return this.roleService.hasPermission(AppUserType.specialist)
	}

	protected readonly AppUserType = AppUserType;

	showContacts() {
		if (this.authService.isLoggedIn()) {
			this.isToShowContacts = true;
		} else {
			this.dialog.open(AuthDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: ['auth-dialog'],
			}).afterClosed().pipe(filter(Boolean)).subscribe(() => {
				localStorage.setItem('isToShowContacts', 'true');
				localStorage.setItem('currentHref', window.location.href);
				window.location.reload();
			});
		}
	}

	copy(currentAppUser: AppUserView): void {
		this.clipboard.copy(`${currentAppUser.email ?? ''}`)
	}
}
