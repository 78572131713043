import {Component, Input, OnInit} from '@angular/core';
import {AbstractSkillView} from "@models/abstract-skill-view";
import {BehaviorSubject} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {SkillPipe} from "@public/pipes/skill.pipe";
import {filter} from "rxjs/operators";

export class AttributeValue {
  public name: string;
  public title: string;
  public value: string;
  constructor(name: string, title: string, value: any) {
    this.name = name;
    this.title = title;
    this.value = value;
  }
}
@Component({
  selector: 'app-skill-details',
  templateUrl: './skill-details.component.html',
})
export class SkillDetailsComponent implements OnInit {
  @Input() updateLink: any[];
  @Input('skillView')
  set skillView(value: AbstractSkillView) {
    this._skillView.next(value);
  };
  get skillView(): AbstractSkillView {
    return this._skillView.getValue();
  }
  private _skillView = new BehaviorSubject<AbstractSkillView>(null);
  displayedProperties = [
    'section',
    'code',
    'name',
  ];
  dataSource: MatTableDataSource<AttributeValue>;
  skillPipe = new SkillPipe();
  constructor() { }
  ngOnInit() {
    this._skillView
      .pipe(
        filter(x => x !== null)
      )
      .subscribe(skillView => {
        const attributes = [];
        this.displayedProperties.forEach(x => {
          attributes.push(new AttributeValue(x, this.skillPipe.transform(x, 'propertyTitle'), skillView[x]))
        });
        this.dataSource = new MatTableDataSource<AttributeValue>(attributes);
      });
  }
}
