<mat-form-field
	class='pd-input-form-field pd-input-form-field-without-border'
	appearance='fill'
	hideRequiredMarker
>
	<mat-icon
		matPrefix
		class='pd-field-prefix-icon gray'
		svgIcon='general-search'
	></mat-icon>
	<mat-label>{{placeholder}}</mat-label>
	<input
		type='text'
		matInput
		class='pd-chip-input'
		[formControl]='anyFilter'
		maxlength="100"
	>
	<button
		class='pd-icon-button with-line-height gray'
		mat-icon-button
		matSuffix
		matTooltip='Очистить фильтр'
		*ngIf='anyFilter.value'
		(click)='clearFilter()'
	>
		<mat-icon
			class='pd-icon'
			svgIcon='additional-close'
		></mat-icon>
	</button>
</mat-form-field>
