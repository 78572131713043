import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyView } from '@models/company-view';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CompanyLegalDetailsDialogComponent } from '@shared/company-legal-details-dialog/company-legal-details-dialog.component';
import { errorTitle } from '@utils/helpers/error-helpers';
import { MatDialog } from '@angular/material/dialog';
import { pushFakeHistoryState } from '@utils/functions';
import { AddressView } from '@models/address-view';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { LinkTransitionConfirmDialogComponent } from '@general/components/dialog/link-transition-confirm-dialog/link-transition-confirm-dialog.component';

@Component({
	selector: 'pdw-company-legal-details',
	templateUrl: './company-legal-details.component.html',
	providers: [DestroyService],
	styleUrls: ['./company-legal-details.component.scss']
})
export class CompanyLegalDetailsComponent implements OnInit {

	@Input()
	canUpdate: boolean = false;

	@Input()
	set companyView(value: CompanyView) {
		this._companyView.next(value);
	};

	get companyView(): CompanyView {
		return this._companyView.getValue();
	}

	private _companyView = new BehaviorSubject<CompanyView>(null);
	currentCompanyView = new BehaviorSubject<CompanyView>(null);
	@Output() changed = new EventEmitter<any>();

	constructor(private dialog: MatDialog,
				private notificationService: NotificationService,
				private destroy$: DestroyService) {
	}

	ngOnInit(): void {
		this._companyView
			.pipe(
				filter(x => x !== null),
				takeUntil(this.destroy$),
			)
			.subscribe({
				next: (companyView: CompanyView) => {
					this.currentCompanyView.next(companyView);
				}
			});
	}

	goUpdateLegalDetails(): void {
		pushFakeHistoryState();

		this.dialog.open(CompanyLegalDetailsDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				minHeight: '320px',
				panelClass: ['pd-dialog'],
				data: {
					title: 'Редактирование компании',
					method: 'update',
					companyView: this.currentCompanyView.value
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
			)
			.subscribe({
				next: () => {
					this.changed.emit('company legal details');
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}


	openConfirmModal() {
		const dialogRef = this.dialog.open(LinkTransitionConfirmDialogComponent, {
			data: {
				companyViewUrl: this.companyView.url
			}
		})
	}

	/**
	 * Проверяет пустой ли адрес
	 * @param address
	 */
	isEmptyAddress(address: AddressView): boolean {
		return !(address.city || address.country || address.street || address.postcode || address.region);
	}

	/**
	 * Преобразует адрес в строку
	 * @param address
	 */
	addressToString(address: AddressView): string {
		let result: string = '';
		let itemsInResult: number = 0;

		const orderedKeys: string[] = [
			'country',
			'postcode',
			'region',
			'city',
			'street'
		];

		for (let i = 0; i < orderedKeys.length; i++) {
			if (!address[orderedKeys[i]]) {
				continue;
			}
			if (!itemsInResult) {
				result = address[orderedKeys[i]];
				itemsInResult++;
			} else {
				result += ', ' + address[orderedKeys[i]];
			}
		}

		return result;
	}

}
