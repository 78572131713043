import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	LOCALE_ID,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { catchError, filter, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { pushFakeHistoryState } from '@utils/functions';
import { UtilsService } from '@core/services/utils.service';
import { WorkService } from '@core/services/work.service';
import { AppUserService } from '@core/services/app-user.service';
import { WorkRequestService } from '@core/services/work-request.service';
import { CreateFreelanceJobMethods, YesNo } from '@models/enums';
import { AppUserType, AppUserView } from '@models/app-user-view';
import { WorkMenuTitles, WorkStateEnum, WorkStateValue, WorkView } from '@models/work-view';
import { WorkRequestState, WorkRequestView } from '@models/work-request-view';
import { MatDialog } from '@angular/material/dialog';
import { WorkStateView } from '@models/work-state-view';
import { ChatService } from '@core/services/chat.service';
import { NoticeService } from '@core/services/notice.service';
import { Router } from '@angular/router';
import { WorkAbstractComponent } from '@shared/work-abstract-component/work-abstract-component';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { AuthService } from '@core/services/auth.service';
import { AuthDialogComponent } from '@shared/auth-dialog/auth-dialog.component';
import {
	ConfirmationDialogComponent,
	ConfirmationDialogType
} from '@shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { CreateJobDialogComponent } from '@shared/components/dialog/create-job-dialog/create-job-dialog.component';
import { WorkHistoryView } from '@models/work-history-view';
import { WorkHistoryService } from '@core/services/work-history.service';
import { CompanyView } from '@models/company-view';
import { CompanyService } from '@core/services/company.service';
import { WorkMarkService } from '@core/services/work-mark.service';

@Component({
	selector: 'pdw-work-details-specialist',
	templateUrl: './work-details-specialist.component.html',
	host: { 'class': 'pd-expanded-height' },
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService],
	styleUrls: ['./work-details-specialist.component.scss']
})
export class WorkDetailsSpecialistComponent extends WorkAbstractComponent implements OnInit, OnDestroy {
	activeAppUserView = new BehaviorSubject<AppUserView>(null);
	lastState: WorkStateView;
	isReloading = new BehaviorSubject<boolean>(false);
	workViewId: number;
	specialistExecutorRequest: WorkRequestView = null;
	countOfMarks: number = 0;
	readonly WORK_STATE = WorkStateEnum;

	constructor(
		private workService: WorkService,
		private appUserService: AppUserService,
		private workRequestService: WorkRequestService,
		private notificationService: NotificationService,
		private dialog: MatDialog,
		public utilsService: UtilsService,
		@Inject(LOCALE_ID) public locale: string,
		private chatService: ChatService,
		private noticeService: NoticeService,
		private router: Router,
		public authService: AuthService,
		private destroy$: DestroyService,
		private cdr: ChangeDetectorRef,
		private workHistoryService: WorkHistoryService,
		private workMarksService: WorkMarkService,
		private companyService: CompanyService
	) {
		super();
	}

	ngOnInit(): void {
		this.chatService.reConnection();

		if (!this.authService.isLoggedIn()) {
			this.activeAppUserView.next(null);
		} else {
			this.appUserService.getActiveUserView()
				.pipe(
					filter(x => x !== null),
					takeUntil(this.destroy$),
				).subscribe({
				next: appUserView => {
					this.activeAppUserView.next(appUserView);
				}
			});
		}


		this.workViewSubject
			.pipe(
				filter(x => x !== null),
				switchMap((workView) => {
					this.currentWorkView.next(workView);
					this.groupWorkCategories(this.groupedCategoriesWithSubCategories, workView);
					this.workViewId = workView.id;
					if (this.isFinished) {
						this.lastState = workView.states.find(x => x.isLast === YesNo.yes);
					}

					if (this.activeAppUserView.value && this.activeAppUserView.value.type !== AppUserType.client) {
						this.specialistExecutorRequest = workView.requests.find(request => request.executor.id === this.activeAppUserView.value?.id) ?? null;
					}
					return this.companyService.getCompanyView(workView.company.id);
				}),
				switchMap((company) => {
					this.currentWorkView.next({ ...this.currentWorkView.value, company: company })
					return this.workMarksService.ofCompanyCount(company.id);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe((count) => {
				this.countOfMarks = count;
			});
	}

	reloadWork(): void {
		if (!this.workView) {
			return;
		}
		this.isReloading.next(true);
		this.workService.clearWorkView(this.workViewId);
		this.workService.changeCurrentWorkView(this.workViewId)
			.pipe(
				takeUntil(this.destroy$),
				tap(() => this.isReloading.next(true)),
				finalize(() => this.isReloading.next(false))
			)
			.subscribe({
				next: workView => {
					this.workView = workView;
				},
				error: err => {
					if (err.status === 404) {
						console.warn(err)
						this.router.navigate(['/specialist/works'])
							.then(() => this.notificationService.showDanger(err.error));
					}
				}
			});
	}


	isExecutor(): boolean {
		if (!this.workView.requests) {
			return false;
		}
		return this.workView?.requests?.some(item => item.executor?.id === this.activeAppUserView.value?.id) || this.workView?.createAppUser?.id === this.activeAppUserView.value?.id
	}

	get isInvited(): boolean {
		if (!this.specialistExecutorRequest) {
			return false;
		}
		return this.specialistExecutorRequest.executor.id === this.activeAppUserView?.value.id && this.workView.specialistAssigned === YesNo.no && !this.workView.executorId && !this.isDeclined
	}

	get isDeclined(): boolean {
		if (!this.workView.requests) {
			return false;
		}
		return this.workView.requests.some(item => item?.executor.id === this.activeAppUserView.value?.id && item.state === WorkRequestState.declined)
	}

	isFirstTimeVisited(): boolean {
		return this.specialistExecutorRequest.executor === null && this.workView.specialistAssigned === YesNo.no && !this.workView.executorId && !this.isDeclined
	}

	acceptInvite(): void {
		this.workRequestService.accept(this.specialistExecutorRequest.id)
			.pipe(
				takeUntil(this.destroy$),
				switchMap(() => this.workService.getWorkView(this.workViewId))
			)
			.subscribe({
					next: (work) => {
						this.updateWorkView(work);
						this.notificationService.showSuccess('Работа успешно принята');
						this.cdr.markForCheck();
					},
					error: (err) => {
						this.notificationService.showDanger(errorTitle(err));
					}
				}
			);
	}

	refreshWork(): Observable<WorkView> {
		this.workService.clearWorkView(this.workViewId);
		return this.workService.changeCurrentWorkView(this.workViewId)
			.pipe(
				takeUntil(this.destroy$)
			);
	}

	getHistoryWork(workHistoryView: WorkHistoryView): Observable<WorkView> {
		return this.workService.getHistoryWorkView(workHistoryView.id)
	}

	onChanged(event): void {
		if (!this.isReloading.value) {
			this.reloadWork();
		}
	}

	get canUpdate(): boolean {
		return this.workView.state == WorkStateValue.packaged;
	}

	isActiveUserAssigned(workView: WorkView): Observable<boolean> {
		return this.appUserService.getActiveUserAsObservable()
			.pipe(
				filter(x => x !== null && workView !== null),
				map(u => {
					return workView.specialistAssigned == YesNo.yes &&
						workView.requests &&
						workView.requests.some(x => x.executor.id === u.id && x.state === WorkRequestState.accepted);
				})
			);
	}

	goSubscribe(event: MouseEvent): void {
		event.stopPropagation();
		this.workService.subscribe(this.workView.id)
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: () => this.reloadWork()
			});
	}

	goSubscribeWithoutAuth(event: MouseEvent): void {
		event.stopPropagation();
		this.dialog.open(AuthDialogComponent, {
			autoFocus: true,
			disableClose: false,
			width: '581px',
			panelClass: ['auth-dialog'],
		}).afterClosed().pipe(filter(Boolean)).subscribe(() => {
			localStorage.setItem('currentHref', window.location.href);
			this.goSubscribe(event);
			window.location.reload();
		});
	}


	isRequested(workView: WorkView): Observable<boolean> {
		return this.appUserService.getActiveUserAsObservable()
			.pipe(
				filter(x => x !== null && workView !== null),
				map(u => {
					return workView.requests && workView.requests.some(x => x.executor.id === u.id && x.state !== WorkRequestState.declined);
				})
			);
	}

	ngOnDestroy() {
		this.chatService.endConnection().then();
	}

	onAddRequest() {
		this.dialog.open(AuthDialogComponent, {
			autoFocus: true,
			disableClose: false,
			width: '581px',
			panelClass: ['auth-dialog'],
		}).afterClosed().pipe(filter(Boolean)).subscribe(() => {
			localStorage.setItem('currentHref', window.location.href);
			this.workRequestService.add(this.workViewId)
				.pipe(
					takeUntil(this.destroy$)
				)
				.subscribe({
					next: () => {
						window.location.reload();
					},
					error: (err) => {
						this.notificationService.showDanger(errorTitle(err));
						window.location.reload();
					}
				});
		});
	}

	onEdit(event) {
		pushFakeHistoryState();

		this.getHistoryWork(event)
			.pipe(
				switchMap(workView => this.dialog.open(CreateJobDialogComponent, {
						autoFocus: true,
						disableClose: false,
						width: '581px',
						minHeight: '320px',
						panelClass: ['pd-dialog'],
						data: {
							title: WorkMenuTitles.primaryTitleItem,
							method: CreateFreelanceJobMethods.accept,
							workView: workView,
							appUserView: this.activeAppUserView.getValue()
						}
					})
					.afterClosed()),
				catchError((err) => {
					this.notificationService.showDanger(errorTitle(err));
					return of(null)
				}),
				takeUntil(this.destroy$),
			)
			.subscribe((result) => {
					switch (result) {
						case YesNo.yes:
							this.goAccept(event);
							break;
						case YesNo.no:
							this.goReject(event);
							break;
					}
				},
			);
	}

	goAccept(workHistoryView: WorkHistoryView): void {
		this.workHistoryService.accept(workHistoryView.id)
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe(() => {
				this.reloadWork();
			});
	}

	goReject(workHistoryView: WorkHistoryView): void {
		this.workHistoryService.reject(workHistoryView.id)
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe(() => {
				this.reloadWork();
			});
	}
}
