import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { checkPasswords } from '@utils/check-passwords';
import { AuthPipe } from '@public/pipes/auth.pipe';
import { ConfirmPassMatcher } from '@utils/errors/confirm-pass-matcher';
import { filter, finalize, takeUntil, tap } from 'rxjs/operators';
import { AppUserService } from '@core/services/app-user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { errorTitle } from '@utils/helpers/error-helpers';
import { AbstractComponentDirective } from '@shared/abstract-component.directive';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { TrueLoadingService } from '@core/services/true-loading.service';
import { PASSWORD_REGEX } from '@utils/constants';
import { isEmptyObject } from '@utils/helpers/object-helpers';

@Component({
	selector: 'pdw-reset-password',
	templateUrl: './reset-password.component.html',
	providers: [DestroyService, TrueLoadingService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./reset-password.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent extends AbstractComponentDirective implements OnInit {

	authPipe = new AuthPipe();
	checkPasswords = checkPasswords;
	recoveryForm: FormGroup;
	matcher = new ConfirmPassMatcher();
	private regex: string;
	paramsId: string;
	paramsToken: string;

	passwordHide = true;
	passwordConfirmHide = true;

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private appUserService: AppUserService,
		private notificationService: NotificationService,
		private destroy$: DestroyService,
		public loading$: TrueLoadingService,
		@Inject(PASSWORD_REGEX) passwordRegex: string
	) {
		super();
		this.loading$.next(false);
		this.regex = passwordRegex;
	}

	ngOnInit(): void {
		this.route.queryParams
			.pipe(
				takeUntil(this.destroy$),
				tap(queryParams => {
					if (isEmptyObject(queryParams) || !queryParams.id || !queryParams.token) {
						this.router.navigate(['/forgotPassword'])
							.then(() => this.notificationService.showDanger('Что-то пошло не так! Пройдите процедуру восстановления пароля еще раз!'));
					}
				}),
				filter(queryParams => queryParams.id && queryParams.token),
			)
			.subscribe({
				next: (queryParams) => {
					this.paramsId = queryParams.id;
					this.paramsToken = queryParams.token;
				}
			});

		this.recoveryForm = this.formBuilder.group({
			password: new FormControl('', [
				Validators.required,
				Validators.pattern(this.regex),
			]),
			passwordConfirm: new FormControl('', [
				Validators.required,
				Validators.minLength(6),
			]),
		}, {
			validators: [
				this.checkPasswords,
			]
		});
	}

	get password(): AbstractControl {
		return this.recoveryForm.get('password');
	}

	get passwordConfirm(): AbstractControl {
		return this.recoveryForm.get('passwordConfirm');
	}

	onSubmit({ value, valid }): void {
		if (valid && this.paramsId && this.paramsToken) {
			this.loading$.next(true);
			this.appUserService.resetPassword(this.paramsId, { password: value.password, token: this.paramsToken })
				.pipe(
					finalize(() => {
						console.warn('resetPassword final');
						this.loading$.next(false);
					}),
					takeUntil(this.destroy$)
				)
				.subscribe({
					next: (data) => {
						console.warn('resetPassword success', data);
						this.router.navigate(['/login'], { state: { afterResetPassword: true } });
					},
					error: (err) => {
						if (err instanceof HttpErrorResponse) {
							if (err.status === 400) {
								this.filloutFormError(this.recoveryForm, err);
							} else {
								this.notificationService.showDanger(errorTitle(err))
							}
						}
					}
				});
		}
	}

}
