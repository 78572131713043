import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Subject} from "rxjs";
export class OfferDialogData {
  title: string;
}
@Component({
  selector: 'app-safe-deal-dialog',
  templateUrl: './safe-deal-dialog.component.html',
})
export class SafeDealDialogComponent implements OnInit, OnDestroy {
  unsubscribe: Subject<any> = new Subject<any>();
  constructor(
    public dialogRef: MatDialogRef<SafeDealDialogComponent | boolean>,
    @Inject(MAT_DIALOG_DATA) public data: OfferDialogData,
  ) { }

  ngOnInit(): void {
  }
  onAccept(): void {
    this.dialogRef.close(true);
  }
  onClose(): void {
    this.dialogRef.close(false);
  }
  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }
}
