import { Injectable } from '@angular/core';
import { AbstractModelService } from '@core/services/abstract-model.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkRequestDirection, WorkRequestState, WorkRequestView } from '@models/work-request-view';
import { WorkRequestCounts } from '@models/work-request-counts';
import { AppUserService } from '@core/services/app-user.service';
import { WorkService } from '@core/services/work.service';
import { YesNo } from '@models/enums';

export class WorkRequestsFilter {
	workId: number | null = null;
	any: string = null;
	isFreelance: YesNo | string | number = YesNo.no;
	direction: WorkRequestDirection = WorkRequestDirection.None;
}

@Injectable({
	providedIn: 'root'
})
export class WorkRequestService {
	private workExecutorRequestsUrl = 'api/WorkRequests';

	constructor(private http: HttpClient, private modelService: AbstractModelService) {
	}

	/**
	 * Преобразование string в Date после десериализации
	 * @param workExecutorRequestView запрос
	 */
	public static parseDates(workExecutorRequestView: WorkRequestView): WorkRequestView {
		if (workExecutorRequestView.executor) {
			workExecutorRequestView.executor = AppUserService.parseDates(workExecutorRequestView.executor);
		}
		if (workExecutorRequestView.work) {
			workExecutorRequestView.work = WorkService.parseDates(workExecutorRequestView.work);
		}
		if (workExecutorRequestView.work && workExecutorRequestView.work.manager) {
			workExecutorRequestView.work.manager = AppUserService.parseDates(workExecutorRequestView.work.manager);
		}
		workExecutorRequestView.createTime = new Date(workExecutorRequestView.createTime);
		workExecutorRequestView.modifyTime = new Date(workExecutorRequestView.modifyTime);
		return workExecutorRequestView;
	}

	add(id: number): Observable<any> {
		const url = `${this.workExecutorRequestsUrl}/Add/${id}`;
		return this.http.get<any>(url, this.modelService.httpOptions);
	}

	/**
	 * Добавляет приглашение от менеджера на исполнение работы
	 * @param id идентификатор исполнителя работы
	 * @param appUserId идентификатор пользователя, которому будет отправлено приглашение
	 */
	invite(id: number, appUserId: string): Observable<any> {
		const url = `${this.workExecutorRequestsUrl}/Invite/${id}/${appUserId}`;
		return this.http.get<any>(url, this.modelService.httpOptions);
	}

	chooseCandidateToVacancy(workId: number, userId: string | null): Observable<void> {
		return this.http.get<void>(`${this.workExecutorRequestsUrl}/ChooseCandidateToVacancy/${workId}/${userId}`);
	}

	accept(id: number): Observable<any> {
		const url = `${this.workExecutorRequestsUrl}/Accept/${id}`;
		return this.http.get<any>(url, this.modelService.httpOptions);
	}

	decline(id: number): Observable<any> {
		const url = `${this.workExecutorRequestsUrl}/Decline/${id}`;
		return this.http.get<any>(url, this.modelService.httpOptions);
	}

	cancelBySpecialist(id: number): Observable<any> {
		const url = `${this.workExecutorRequestsUrl}/CancelBySpecialist/${id}`;
		return this.http.get<any>(url, this.modelService.httpOptions);
	}

	cancelByClient(id: number): Observable<any> {
		const url = `${this.workExecutorRequestsUrl}/CancelByClient/${id}`;
		return this.http.get<any>(url, this.modelService.httpOptions);
	}

	delete(id: number): Observable<any> {
		const url = `${this.workExecutorRequestsUrl}/${id}`;
		return this.http.delete<any>(url, this.modelService.httpOptions);
	}

	private prepareFilter(workRequestsFilter: WorkRequestsFilter): WorkRequestsFilter {
		Object.keys(workRequestsFilter).forEach(key => {
			if (['isFreelance', 'direction'].some(item => item === key)) {
				return workRequestsFilter[key];
			}
			if (!['workId'].some(x => x === key) && workRequestsFilter[key]) {
				workRequestsFilter[key] = '%' + workRequestsFilter[key] + '%';
			}
		});
		return workRequestsFilter;
	}

	private dataSource(url: string,
					   filter: WorkRequestsFilter = null,
					   sort: string = null,
					   pageIndex = 0,
					   pageSize = 0): Observable<WorkRequestView[]> {
		return this.http.post<WorkRequestView[]>(url, this.prepareFilter(filter), {
				params: new HttpParams()
					.set('sort', sort)
					.set('pageIndex', pageIndex.toString())
					.set('pageSize', pageSize.toString()),
				headers: this.modelService.httpOptions.headers
			})
			.pipe(
				map(xx => xx.map(x => WorkRequestService.parseDates(x))),
			);
	}

	private dataSourceCount(url: string, filter: WorkRequestsFilter = null): Observable<number> {
		return this.http.post<number>(url, this.prepareFilter(filter), this.modelService.httpOptions);
	}

	/**
	 * Возвращает список откликов для конкретной работы
	 * @param id Идентификатор работы
	 * @param filter Фильтр
	 * @param sort Сортировка
	 * @param pageIndex
	 * @param pageSize
	 */
	inWork(id: number,
		   filter: WorkRequestsFilter,
		   sort: string = null,
		   pageIndex = 0,
		   pageSize = 0): Observable<WorkRequestView[]> {
		const url = `${this.workExecutorRequestsUrl}/InWork/${id}`;
		return this.dataSource(url, filter, sort, pageIndex, pageSize);
	}

	inWorkCount(id: number, filter: WorkRequestsFilter): Observable<number> {
		const url = `${this.workExecutorRequestsUrl}/InWorkCount/${id}`;
		return this.dataSourceCount(url, filter);
	}

	my(): Observable<WorkRequestView[]> {
		const url = `${this.workExecutorRequestsUrl}/My`;
		return this.http.get<WorkRequestView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => WorkRequestService.parseDates(x))),
			);
	}

	manager(filter: WorkRequestsFilter,
			sort: string = null,
			pageIndex = 0,
			pageSize = 0): Observable<WorkRequestView[]> {
		const url = `${this.workExecutorRequestsUrl}/manager`;
		return this.dataSource(url, filter, sort, pageIndex, pageSize);
	}

	managerCount(filter: WorkRequestsFilter): Observable<number> {
		const url = `${this.workExecutorRequestsUrl}/ManagerCount`;
		return this.dataSourceCount(url, filter);
	}

	/**
	 * Возвращает количество откликов со статусом Sent, для конкретной работы
	 * @param id Идентификатор работы
	 */
	inWorkSentCounts(id: number): Observable<number> {
		const url = `${this.workExecutorRequestsUrl}/inWorkSentCounts/${id}`;
		return this.http.get<number>(url, this.modelService.httpOptions);
	}

	counts(): Observable<WorkRequestCounts> {
		const url = `${this.workExecutorRequestsUrl}/Counts`;
		return this.http.get<WorkRequestCounts>(url, this.modelService.httpOptions);
	}

	acceptFromChat(workRequestChatMessageId: number): Observable<any> {
		const url = `${this.workExecutorRequestsUrl}/AcceptFromChat/${workRequestChatMessageId}`;
		return this.http.get<any>(url, this.modelService.httpOptions);
	}

	/**
	 * Возвращает список всех откликов, как специалиста, и соответствующих критериям фильтра
	 * @param filter
	 * @param sort
	 * @param pageIndex
	 * @param pageSize
	 */
	specialist(filter: WorkRequestsFilter,
			   sort = null,
			   pageIndex = 0,
			   pageSize = 0): Observable<WorkRequestView[]> {
		const url = `${this.workExecutorRequestsUrl}/Specialist`;
		return this.dataSource(url, filter, sort, pageIndex, pageSize);
	}

	specialistCount(filter: WorkRequestsFilter): Observable<number> {
		const url = `${this.workExecutorRequestsUrl}/SpecialistCount`;
		return this.dataSourceCount(url, filter);
	}

	/**
	 * Классы стилей для состояний приглашений
	 * @param state
	 * @param direction
	 */
	public static stateColorClass(state: WorkRequestState, direction: WorkRequestDirection): string | null {
		switch (state) {
			case WorkRequestState.accepted:
				return 'accepted';
			case WorkRequestState.CandidatesList:
				return 'in-progress';
			case WorkRequestState.declined:
				return 'declined';
			case WorkRequestState.sent:
				return direction === WorkRequestDirection.fromExecutor ? 'sent-warn' : 'sent-primary';
			default:
				return 'declined';
		}
	}
}
