import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {AppUserSupportRequest, SupportRequest} from "@requests/support-request";
import {AbstractModelService} from "@core/services/abstract-model.service";

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  private supportUrl = 'api/Support';

  constructor(private http: HttpClient,
              private modelService: AbstractModelService) {

  }

  /**
   * Запрос в поддержку от незарегистрированного пользователя
   * @param supportRequest
   */
  anonymousRequest(supportRequest: SupportRequest): Observable<any> {
    const url = `${this.supportUrl}/AnonymousRequest`;
    return this.http.post<any>(url, supportRequest, this.modelService.anonymusSupportHttpHeaders);
  }
  /**
   * Запрос в поддержку от авторизованного пользователя
   * @param id
   * @param supportRequest
   */
  userRequest(id: string, supportRequest: AppUserSupportRequest): Observable<any> {
    const url = `${this.supportUrl}/UserRequest/${id}`;
    return this.http.post<any>(url, supportRequest, this.modelService.httpOptions);
  }
}
