import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	LOCALE_ID,
	OnDestroy,
	OnInit,
	ViewContainerRef
} from '@angular/core';
import { WorkMenuTitles, WorkStateEnum, WorkStateValue, WorkView } from '@models/work-view';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { WorkService } from '@core/services/work.service';
import { AppUserService } from '@core/services/app-user.service';
import { WorkRequestService } from '@core/services/work-request.service';
import { MatDialog } from '@angular/material/dialog';
import { catchError, filter, finalize, takeUntil } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { pushFakeHistoryState } from '@utils/functions';
import { UtilsService } from '@core/services/utils.service';
import { HardSkillView } from '@models/hard-skill-view';
import { SkillStateValue } from '@models/abstract-skill-view';
import {
	ConfirmationDialogComponent,
	ConfirmationDialogType
} from '@shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateFreelanceJobMethods, YesNo } from '@models/enums';
import { AppUserType, AppUserView } from '@models/app-user-view';
import { WorkStateView } from '@models/work-state-view';
import { ChatService } from '@core/services/chat.service';
import { NoticeService } from '@core/services/notice.service';
import { WorkAbstractComponent } from '@shared/work-abstract-component/work-abstract-component';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { CreateJobDialogComponent } from '@shared/components/dialog/create-job-dialog/create-job-dialog.component';
import { WorkRequestState } from '@models/work-request-view';
import { WorkHistoryView } from '@models/work-history-view';
import { WorkHistoryService } from '@core/services/work-history.service';
import { AuthService } from '@core/services/auth.service';
import { CompanyView } from '@models/company-view';
import { CompanyService } from '@core/services/company.service';
import { WorkMarkService } from '@core/services/work-mark.service';

@Component({
	selector: 'pdw-work-details-manager',
	templateUrl: './work-details-manager.component.html',
	host: { 'class': 'pd-expanded-height' },
	providers: [DestroyService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./work-details-manager.component.scss']
})
export class WorkDetailsManagerComponent extends WorkAbstractComponent implements OnInit, OnDestroy {
	activeAppUserView = new BehaviorSubject<AppUserView>(null);
	lastState: WorkStateView;
	workViewId: number;
	isReloading = new BehaviorSubject<boolean>(false);
	currentAppUserView$: Observable<AppUserView>;
	countOfMarks: number = 0;
	readonly WORK_STATE = WorkStateEnum;

	constructor(
		private workService: WorkService,
		private appUserService: AppUserService,
		private workRequestService: WorkRequestService,
		private notificationService: NotificationService,
		@Inject(LOCALE_ID) public locale: string,
		private dialog: MatDialog,
		public utilsService: UtilsService,
		private activeRoute: ActivatedRoute,
		private router: Router,
		private chatService: ChatService,
		private noticeService: NoticeService,
		private viewContainerRef: ViewContainerRef,
		private workHistoryService: WorkHistoryService,
		public authService: AuthService,
		private companyService: CompanyService,
		private workMarksService: WorkMarkService,
		private destroy$: DestroyService) {
		super();
	}

	ngOnInit(): void {
		this.chatService.reConnection();

		this.currentAppUserView$ = this.appUserService.getActiveUserAsObservable();

		this.workViewSubject
			.pipe(
				filter(x => x !== null),
				switchMap((workView) => {
					this.currentWorkView.next(workView);
					this.groupWorkCategories(this.groupedCategoriesWithSubCategories, workView);
					this.workViewId = workView.id;
					if (this.isFinished) {
						this.lastState = workView.states.find(x => x.isLast === YesNo.yes);
					}

					return this.companyService.getCompanyView(workView.company.id)
				}),
				switchMap((company) => {
					this.currentWorkView.next({ ...this.currentWorkView.value, company: company })
					return this.workMarksService.ofCompanyCount(company.id);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe((count) => {
				this.countOfMarks = count;
			});

		this.noticeService.noticeWorkReload
			.pipe(
				takeUntil(this.destroy$),
				filter(x => this.workView !== null && this.workView.id === x),
				filter(() => !this.isReloading.value)
			)
			.subscribe({
				next: () => {
					this.reloadWork();
				}
			});

		this.noticeService.workRequestCountsChanged
			.pipe(
				takeUntil(this.destroy$),
				filter(() => !this.isReloading.value)
			)
			.subscribe({
				next: () => {
					this.reloadWork();
				}
			});

		if (!this.authService.isLoggedIn()) {
			this.activeAppUserView.next(null);
		} else {
			this.appUserService.getActiveUserView()
				.pipe(
					filter(x => x !== null),
					takeUntil(this.destroy$),
				)
				.subscribe((appUserView) => this.activeAppUserView.next(appUserView));
		}
	}


	isOwner(work: WorkView, user: AppUserView): boolean {
		return this.workView?.manager.id === user?.id;
	}

	logoColor(company: CompanyView) {
		return CompanyService.logoColor(company);
	}

	reloadWork(): void {
		if (!this.workView) {
			return;
		}
		this.isReloading.next(true);
		this.workService.clearWorkView(this.workViewId);
		this.workService.changeCurrentWorkView(this.workViewId)
			.pipe(
				takeUntil(this.destroy$),
				finalize(() => this.isReloading.next(false))
			)
			.subscribe(workView => {
				this.workView = workView;
			});
	}

	refreshWork(): Observable<WorkView> {
		this.workService.clearWorkView(this.workViewId);
		return this.workService.changeCurrentWorkView(this.workViewId)
			.pipe(
				takeUntil(this.destroy$)
			);
	}

	onChanged(event): void {
		if (!this.isReloading.value) {
			this.reloadWork();
		}
	}

	get isExecuting(): boolean {
		return this.workView.state >= WorkStateValue.executing;
	}

	isExistsActiveRequests(): boolean {
		return this.workView.requests?.some(request => request.state === WorkRequestState.sent || request.state === WorkRequestState.accepted || request.state === WorkRequestState.CandidatesList);
	}


	goUpdateDetails(user: AppUserView): void {
		pushFakeHistoryState();
		this.dialog.open(CreateJobDialogComponent, {
			viewContainerRef: this.viewContainerRef,
			autoFocus: false,
			disableClose: false,
			width: '581px',
			minHeight: '320px',
			panelClass: ['pd-dialog'],
			data: {
				title: WorkMenuTitles.primaryTitleItem,
				method: CreateFreelanceJobMethods.update,
				workView: this.workView,
				appUserView: user
			}
		}).afterClosed()
			.pipe(
				filter(res => res),
				takeUntil(this.destroy$))
			.subscribe(res => {
				this.workView = res;
			})
	}


	goDelete(): void {
		pushFakeHistoryState();

		this.dialog.open(ConfirmationDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Подтверждение',
					message: 'Вы действительно хотите удалить работу',
					value: this.workView.title,
					questionMark: true,
					type: ConfirmationDialogType.question,
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
				switchMap(() => this.workService.delete(this.workView.id)
					.pipe(
						takeUntil(this.destroy$)
					)
				)
			)
			.subscribe({
				next: () => {
					this.router.navigate(['works']).then();
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	getHardSkillTooltip(hardSkillView: HardSkillView): string {
		if (hardSkillView.state === SkillStateValue.created) {
			return 'Навык на модерации';
		}

		return hardSkillView.tooltip;
	}

	hasUnapprovedSkills(workView: WorkView): boolean {
		return workView.hardSkills.some(hardSkill => hardSkill.state === SkillStateValue.created);
	}

	get clientTypeOfUser(): AppUserType {
		return AppUserType.client;
	}

	ngOnDestroy() {
		this.chatService.endConnection().then();
	}

	getHistoryWork(workHistoryView: WorkHistoryView): Observable<WorkView> {
		return this.workService.getHistoryWorkView(workHistoryView.id)
	}

	onEdit(event) {
		pushFakeHistoryState();

		this.getHistoryWork(event)
			.pipe(
				switchMap(workView => this.dialog.open(CreateJobDialogComponent, {
						autoFocus: true,
						disableClose: false,
						width: '581px',
						minHeight: '320px',
						panelClass: ['pd-dialog'],
						data: {
							title: WorkMenuTitles.primaryTitleItem,
							method: CreateFreelanceJobMethods.accept,
							workView: workView,
							appUserView: this.activeAppUserView.getValue()
						}
					})
					.afterClosed()),
				catchError((err) => {
					this.notificationService.showDanger(errorTitle(err));
					return of(null)
				}),
				takeUntil(this.destroy$),
			)
			.subscribe((result) => {
					switch (result) {
						case YesNo.yes:
							this.goAccept(event);
							break;
						case YesNo.no:
							this.goReject(event);
							break;
					}
				},
			);
	}

	goAccept(workHistoryView: WorkHistoryView): void {
		this.workHistoryService.accept(workHistoryView.id)
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe(() => {
				this.reloadWork();
			});
	}

	goReject(workHistoryView: WorkHistoryView): void {
		this.workHistoryService.reject(workHistoryView.id)
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe(() => {
				this.reloadWork();
			});
	}

	protected readonly YesNo = YesNo;
}
