import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingSource = new BehaviorSubject<boolean>(true);
  private shouldReloadSource = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  currentLoading = this.loadingSource.asObservable();
  currentShouldReload = this.shouldReloadSource.asObservable();

  changeShouldReload(value: boolean): void {
    this.shouldReloadSource.next(value);
  }

  changeLoading(value: boolean): void {
    this.loadingSource.next(value);
  }
}
