import { Pipe, PipeTransform } from '@angular/core';
import { AbstractModelPipe } from '@public/pipes/abstract-model.pipe';

@Pipe({
	name: 'appUserContacts'
})
export class AppUserContactsPipe extends AbstractModelPipe implements PipeTransform {
	properties = [
		{ name: 'email', title: 'Адрес эл. почты', hint: 'Адрес эл. почтового ящика' },
		{ name: 'phoneNumber', title: 'Номер телефона', hint: 'Номер телефона' },
		{ name: 'code', title: 'Код', hint: 'Код' },
	];

	transform(value: any, type: string): any {
		return this.getTransform(value, type);
	}

	allProperties() {
		return this.properties;
	}

}
