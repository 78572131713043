import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WorkRequestState, WorkRequestView } from '@models/work-request-view';
import { AppUserService } from '@core/services/app-user.service';
import { Subject } from 'rxjs';
import { AppUserView } from '@models/app-user-view';
import { ImageAvatarSize } from '@shared/components/ui/image-avatar/image-avatar.types';

@Component({
	selector: 'pdw-work-requests-in-work-item',
	templateUrl: './work-requests-in-work-item.component.html',
	host: { 'class': 'pd-chat-requests-item' }
})
export class WorkRequestsInWorkItemComponent implements OnInit, OnDestroy {
	@Input() workRequestView: WorkRequestView;
	@Input() user: AppUserView;
	@Input() currentWorkRequest: WorkRequestView;
	appUserView: AppUserView;
	isReceived: boolean;
	unsubscribe: Subject<any> = new Subject<any>();

	ngOnInit(): void {
		this.appUserView = this.user.id === this.workRequestView.work.manager.id
			? this.workRequestView.executor
			: this.workRequestView.work.manager;
	}

	isUserOnline(appUserView: AppUserView): boolean | null {
		if (appUserView.lastConnection) {
			const delta = (new Date().getTime() - appUserView.lastConnection.getTime()) / 1000 / 60;
			return delta < 5;
		}
		return null;
	}

	messageCount(): number {
		return this.workRequestView.newMessagesCount;
	}

	isAcceptedUser(): boolean {
		return this.workRequestView.state == WorkRequestState.accepted;
	}

	avatarColor(appUserView: AppUserView): string {
		return AppUserService.avatarColor(appUserView);
	}

	get isCancelled(): boolean {
		return this.workRequestView.state === WorkRequestState.declined || this.workRequestView.state === WorkRequestState.cancelledByClient || this.workRequestView.state === WorkRequestState.cancelledBySpecialist
	}

	ngOnDestroy() {
		this.unsubscribe.next(undefined);
	}

	protected readonly IMAGE_SIZE = ImageAvatarSize;
}

