import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppUserType } from '@models/app-user-view';
import { RoleService } from '@core/services/role.service';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[hasNotPermission]'
})
export class HasNotPermissionDirective {

	@Input('hasNotPermission') set visibleTo(roles: AppUserType | Array<AppUserType>) {
		const rolesArray = typeof roles === 'object' ? roles : [roles];
		if (this.checkVisibility(rolesArray)) {
			this.viewContainerRef.clear();
		} else {
			this.viewContainerRef.createEmbeddedView(this.templateRef);
		}
	}

	constructor(
		protected templateRef: TemplateRef<any>,
		protected viewContainerRef: ViewContainerRef,
		protected roleService: RoleService,
	) {
	}

	private checkVisibility(permissions: AppUserType[] = []): boolean {
		if (permissions.length === 0) {
			return true;
		}

		if (permissions.includes(AppUserType.none)) {
			return true;
		}

		return permissions.map(permission => this.roleService.hasPermission(permission)).some(Boolean);
	}

}
