<mat-expansion-panel
	class='pd-expansion-panel pd-work-chat-panel'
	[hideToggle]='true'
	[expanded]='initExpanded'
	#panel
	[disabled]='(isConnecting | async) || isPanelDisabled'
	(opened)='selectedWorkRequest = lastSelectedWorkRequest'
	(afterCollapse)='onPanelCollapse()'
	(closed)='onPanelClose()'
	*ngIf='!isRejected()'
>
	<mat-expansion-panel-header>
		<div class='pd-chat-panel-title'>
			<div class='pd-chat-panel-title-inner'>
				<div class='pd-chat-panel-title-img-wrapper'>
					<ng-container *ngIf='workView.company.hasLogo;'>
						<img class='pd-chat-panel-title-img' [src]="workView.company| avatar: 'api/Companies/Logo' | async"
							[alt]="workView.company.name + ' logo'">
					</ng-container>
					<ng-container *ngIf='managerUser | async as manager'>
						<div
							class='pd-profile-online-status pd-profile-online-status-into small'
							[ngClass]="{'online' : isUserOnline(manager)}"
							[matTooltip]="isUserOnline(manager) ? 'В сети' : manager.lastConnection | appUserLastConnection"
						>
						</div>
					</ng-container>
				</div>
				<p class='pd-chat-panel-title-text'>
					{{workView.company.title}}
				</p>
			</div>
		</div>

		<button
			class='pd-icon-button'
			mat-icon-button
		>
			<mat-icon
				class='pd-icon pd-profile-block-toggle-icon'
				svgIcon='additional-chevron-down'
			>
			</mat-icon>
		</button>
	</mat-expansion-panel-header>
	<ng-template matExpansionPanelContent>
		<div class='pd-chat selected'>
			<app-work-requests-chat-messenger
				class='pd-chat-messenger'
				(lastChatMessageReceived)='onLastChatMessageReceived($event)'
				[selectedWorkRequest]='selectedWorkRequest'
				[isSingleMessengerComponent]='true'
				[user]='currentUser'
			>
			</app-work-requests-chat-messenger>
		</div>
	</ng-template>
</mat-expansion-panel>

