<a
	*ngIf="currentWorkView | async as workView"
	class="pd-work-card-inner"
	(click)="goToLink($event)"
>
	<div class='pd-work-card-header'>
		<pdw-work-state
			[value]='workView.state'
			[type]="WORK_STATE.ABSTRACT_FINISHED"
		>
		</pdw-work-state>
		<div
			*hasAuth='true'
			class='pd-work-card-header-buttons'
		>
			<ng-container *ngIf="cardType === 'client'; else specialistMenu">
				<pdw-work-states-menu
					*ngIf="!isFullMode"
					[workView]='workView'
					(changed)='onChange($event)'
				>
				</pdw-work-states-menu>
			</ng-container>
			<ng-template #specialistMenu>
				<ng-container *ngIf='canSubscribe()'>
					<button
						class='pd-icon-button pd-card-bookmark'
						mat-icon-button
						*ngIf='workView.subscribed === 0'
						[matTooltip]="'Добавить в избранное'"
						(click)='goSubscribe($event)'
					>
						<mat-icon [svgIcon]="'additional-bookmark'"></mat-icon>
					</button>
					<button
						class='pd-icon-button pd-card-bookmark-favorite'
						mat-icon-button
						*ngIf='workView.subscribed === 1'
						[matTooltip]="'Убрать из избранного'"
						(click)='goUnsubscribe($event)'
					>
						<mat-icon [svgIcon]="'additional-bookmark'"></mat-icon>
					</button>
				</ng-container>
			</ng-template>
		</div>
	</div>
	<div class='pd-work-card-body'>
		<div class='pd-work-card-title-row'>
			<p class='pd-work-card-title-text'>{{workView.name}}</p>
			<pdw-work-cost
				[work]='workView'
				[plateView]='false'
			>
			</pdw-work-cost>
		</div>
		<div
			class='pd-work-card-categories'
			*ngIf="groupedCategoriesWithSubCategories | async as arr; else categoryName"
		>
			<tui-scrollbar class="pd-scrollbar">
				<div class="pd-scrollbar-content">
					<ng-container *ngFor='let category of arr'>
						<div *ngIf="category.categoryName"
							class='pd-skill-inner category'
							[matTooltip]="'Категория работы'"
						>
							<p  class='pd-skill-text'>
								{{ category.categoryName }}
							</p>
						</div>
						<ng-container *ngFor='let subCategory of category.group'>
							<div
								class='pd-skill-inner'
								[matTooltip]="'Тип работы'"
							>
								<p class='pd-skill-text'>
									{{ subCategory.name }}
								</p>
							</div>
						</ng-container>
					</ng-container>
				</div>
			</tui-scrollbar>
		</div>
		<ng-template #categoryName>
			<div class='pd-work-card-categories'>
				<tui-scrollbar class="pd-scrollbar">
					<div class="pd-scrollbar-content">
						<div
							*ngFor='let categoryName of workView.categoryNames'
							class='pd-skill-inner category'
							[matTooltip]="'Категория работы'"
						>
							<p class='pd-skill-text'>
								{{ categoryName }}
							</p>
						</div>
					</div>
				</tui-scrollbar>	
			</div>
		</ng-template>
		<div class='pd-work-company-container'>
			<p
				class='pd-work-company-dates'
			>
				{{workView.companyName}}
			</p>
			<p class='pd-work-company-dates' *ngIf="workView.isFreelance; else workCreateDate">
				{{ workView.startDate ? (workView.startDate | date:'dd.MM.YYYY') : '--'}} -
				{{ workView.endDate ? (workView.endDate | date:'dd.MM.YYYY') : '--'}}
			</p>
			<ng-template #workCreateDate>
				<p class='pd-work-company-dates'>
					{{ workView.createTime ?  ( workView.createTime | date:'dd.MM.YYYY') : '--'}}
				</p>
			</ng-template>
		</div>
	</div>
</a>
