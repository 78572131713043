import {Directive, HostListener} from '@angular/core';
import {RouterLink} from "@angular/router";

@Directive({
  selector: '[routerLink][middleClick]'
})
export class MiddleClickRouterLinkDirective extends RouterLink {
  @HostListener('mouseup', ['$event'])
  middleClickEvent(event) {
    if (event.button === 1 || event.button === 0 && event.ctrlKey === true) {
      window.open(this.urlTree.toString(), '_blank');
    }
  }
}
