import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppUserService } from '@core/services/app-user.service';
import { AppUserType } from '@models/app-user-view';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { errorTitle } from '@utils/helpers/error-helpers';
import { catchError, finalize, of, takeUntil } from 'rxjs';

@Component({
  selector: 'pdw-change-role-confirm-dialog',
  templateUrl: './change-role-confirm-dialog.component.html',
  styleUrls: ['./change-role-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeRoleConfirmDialogComponent {

  private readonly data = inject(MAT_DIALOG_DATA);
  private readonly userService = inject(AppUserService);
  private readonly destroy$ = inject(DestroyService);
  private readonly notificationService = inject(NotificationService);
  private readonly dialogRef = inject(MatDialogRef<ChangeRoleConfirmDialogComponent>);

  public activeUser = this.data.activeUser;


  changeTypeBtnTitle(type: AppUserType): string {
		return type === AppUserType.specialist ? "Заказчик" : "Специалист";
	}
  goChangeType(): void {
		const newType = this.activeUser.type == AppUserType.specialist ? AppUserType.client : AppUserType.specialist;
		this.userService
			.changeType(newType)
			.pipe(
				takeUntil(this.destroy$),
				catchError((err) => {
					this.notificationService.showDanger(errorTitle(err));
					return of(err);
				})
			)
			.subscribe(() => {
				this.dialogRef.close();
			});
	}

}
