<ng-container *ngIf='currentCompanyView | async as currentCompanyView'>
	<mat-expansion-panel
		class='pd-expansion-panel pd-company-block mat-elevation-z0'
		[hideToggle]='true'
		expanded='true'
	>
		<mat-expansion-panel-header>
			<div class='pd-company-block-title-wrapper'>
				<h3 class='pd-company-block-title'>Работы</h3>
			</div>
			<button
				class='pd-icon-button'
				mat-icon-button
			>
				<mat-icon
					class='pd-icon pd-profile-block-toggle-icon'
					svgIcon='additional-chevron-down'
				>
				</mat-icon>
			</button>
		</mat-expansion-panel-header>
		<div class='pd-company-block-body'>
			<mat-button-toggle-group
				class='pd-company-works-toggle'
				(change)='onChange($event)'
				value='1'
				*ngIf="canUpdate; else disabledBlock"
			>
				<mat-button-toggle
					class='pd-company-works-toggle-btn vacancy'
					[value]='[0]'
					[class.mat-button-toggle-checked]="(currentToggleValue$ | async) === 0"
				>
					<p class='pd-company-works-toggle-count'>
						{{currentVacancyCount$ | async | cutThousand}}
					</p>
					<p class='pd-company-works-toggle-text'>
						{{currentVacancyCount$ | async | numberToWordDeclension: ['вакансий в&nbsp;поиске', 'вакансия в&nbsp;поиске', 'вакансии в&nbsp;поиске']}}
					</p>
				</mat-button-toggle>
				<!-- <mat-button-toggle
					class='pd-company-works-toggle-btn seeking'
					[value]='[1]'
				>
					<p class='pd-company-works-toggle-count'>
						{{currentFreelanceCount$ | async | cutThousand}}
					</p>
					<p class='pd-company-works-toggle-text'>
						{{currentFreelanceCount$ | async | numberToWordDeclension: ['работ в&nbsp;поиске', 'работа в&nbsp;поиске', 'работы в&nbsp;поиске']}}
					</p>
				</mat-button-toggle>
				<mat-button-toggle
					class='pd-company-works-toggle-btn executing'
					[value]='[4]'
				>
					<p class='pd-company-works-toggle-count'>
						{{currentCompanyView.worksInExecuting | cutThousand}}
					</p>
					<p class='pd-company-works-toggle-text'>
						{{currentCompanyView.worksInExecuting | numberToWordDeclension: ['работ выполняется', 'работа выполняется', 'работы выполняется']}}
					</p>
				</mat-button-toggle>
				<mat-button-toggle
					class='pd-company-works-toggle-btn finished'
					[value]='[99]'
				>
					<p class='pd-company-works-toggle-count'>
						{{currentCompanyView.worksInFinished | cutThousand}}
					</p>
					<p class='pd-company-works-toggle-text' [class.disabled]="true">
						{{currentCompanyView.worksInFinished | numberToWordDeclension : ['работ завершено', 'работа завершена', 'работы завершено']}}
					</p>
				</mat-button-toggle> -->
			</mat-button-toggle-group>
			<ng-template #disabledBlock>
				<mat-button-toggle-group
					class='pd-company-works-toggle disabledBlock'
					value='0'
					(change)='onChangeToggle($event)'
				>
					<mat-button-toggle
						class='pd-company-works-toggle-btn vacancy'
						[class.mat-button-toggle-checked]="(currentToggleValue$ | async) === 0"
						[value]='[0]'
					>
						<p class='pd-company-works-toggle-count'>
							{{currentVacancyCount$ | async | cutThousand}}
						</p>
						<p class='pd-company-works-toggle-text'>
							{{currentVacancyCount$ | async | numberToWordDeclension: ['вакансий в&nbsp;поиске', 'вакансия в&nbsp;поиске', 'вакансии в&nbsp;поиске']}}
						</p>
					</mat-button-toggle>
					<mat-button-toggle
						class='pd-company-works-toggle-btn seeking'
						[value]='[1]'
						[class.mat-button-toggle-checked]="(currentToggleValue$ | async) === 1"
					>
						<p class='pd-company-works-toggle-count'>
							{{currentFreelanceCount$ | async | cutThousand}}
						</p>
						<p class='pd-company-works-toggle-text'>
							{{currentFreelanceCount$ | async | numberToWordDeclension: ['работ в&nbsp;поиске', 'работа в&nbsp;поиске', 'работы в&nbsp;поиске']}}
						</p>
					</mat-button-toggle>
					<mat-button-toggle
						class='pd-company-works-toggle-btn disabledBlock executing'
						[value]='[4]'
						[disabled]="true"
					>
						<p class='pd-company-works-toggle-count disabledBlock'>
							{{currentCompanyView.worksInExecuting | cutThousand}}
						</p>
						<p class='pd-company-works-toggle-text disabledBlock'>
							{{currentCompanyView.worksInExecuting | numberToWordDeclension: ['работ выполняется', 'работа выполняется', 'работы выполняется']}}
						</p>
					</mat-button-toggle>
					<mat-button-toggle
						class='pd-company-works-toggle-btn disabledBlock finished'
						[value]='[99]'
						[disabled]="true"
					>
						<p class='pd-company-works-toggle-count disabledBlock'>
							{{currentCompanyView.worksInFinished | cutThousand}}
						</p>
						<p class='pd-company-works-toggle-text disabledBlock' [class.disabled]="true">
							{{currentCompanyView.worksInFinished | numberToWordDeclension : ['работ завершено', 'работа завершена', 'работы завершено']}}
						</p>
					</mat-button-toggle>
				</mat-button-toggle-group>
			</ng-template>
			<div
				class='pd-carousel-cards-wrapper'
				[ngClass]="{'empty' : (dataSource.data | async).length <= 0}"
				#cardsContainer
			>
				<pdw-works-card-company-slider
					[canUpdate]='canUpdate'
					[dataSource]='dataSource'
					[columnsCount]='columnsCount'
					[cardWidth]='minCardWidth'
					[filterValues]='filterValues'
					[sortValues]='sortValues'
					[companyView]='currentCompanyView'
					[dataSourceShort]="dataSourceShort"
					[loadAll]="true"
				>
				</pdw-works-card-company-slider>
			</div>
		</div>
	</mat-expansion-panel>
</ng-container>
