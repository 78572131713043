<mat-expansion-panel
	class='pd-expansion-panel pd-company-block mat-elevation-z0'
	[hideToggle]='true'
	expanded='true'
>
	<mat-expansion-panel-header>
		<div class='pd-company-block-title-wrapper'>
			<h3 class='pd-company-block-title'>Отзывы</h3>
		</div>
		<button
			class='pd-icon-button'
			mat-icon-button
		>
			<mat-icon
				class='pd-icon pd-profile-block-toggle-icon'
				svgIcon='additional-chevron-down'
			>
			</mat-icon>
		</button>
	</mat-expansion-panel-header>
	<div class='pd-company-block-body'>
		<div
			class='pd-expanded-height'
			#cardsContainer
		>
			<pdw-work-mark-cards
				[dataSource]='dataSource'
				[columnsCount]='columnsCount'
				[cardWidth]='minCardWidth'
			>
			</pdw-work-mark-cards>
		</div>
	</div>
</mat-expansion-panel>

