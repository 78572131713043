import { EventEmitter, Injectable } from '@angular/core';
import {
	HubConnection,
	HubConnectionBuilder,
	HubConnectionState,
	IRetryPolicy,
	RetryContext
} from '@microsoft/signalr';
import { NotificationCounts } from '@models/notification-counts';
import { BehaviorSubject } from 'rxjs';
import { WorkRequestCounts } from '@models/work-request-counts';
import { WorkService } from '@core/services/work.service';
import { CompanyService } from '@core/services/company.service';
import { AppUserService } from '@core/services/app-user.service';

export class OneMinuteReconnectPolicy implements IRetryPolicy {
	nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null {
		if (retryContext.previousRetryCount === 0) {
			return 2000;
		}
		if (retryContext.previousRetryCount === 1) {
			return 10000;
		}
		if (retryContext.previousRetryCount === 2) {
			return 30000;
		}
		return 60000;
	}
}

@Injectable({
	providedIn: 'root'
})
export class NoticeService {
	private hubConnection: HubConnection;
	private reconnectionPolicy = new OneMinuteReconnectPolicy();

	notificationCountsChanged = new EventEmitter<NotificationCounts>();
	workRequestCountsChanged = new EventEmitter<WorkRequestCounts>();
	noticeWorkReload = new EventEmitter<number>();
	connectionEstablished = new BehaviorSubject<boolean>(false);
	joined = new BehaviorSubject<boolean>(false);

	constructor(
		private companyService: CompanyService,
		private workService: WorkService,
		private appUserService: AppUserService
	) {
		this.createConnection();
		this.startConnection();
	}

	public reConnection(): void {
		if (this.hubConnection.state === HubConnectionState.Disconnected) {
			this.startConnection();
		}
	}

	private createConnection() {
		this.hubConnection = new HubConnectionBuilder()
			.withUrl('Notice', {
				accessTokenFactory: () => {
					return window.localStorage.getItem('auth_token');
				},
			})
			.withAutomaticReconnect(this.reconnectionPolicy)
			.build();
	}

	private startConnection(): void {
		this.registerServerEvents();
		this.hubConnection
			.start()
			.then(() => {
				this.connectionEstablished.next(true);
				this.workRequestCountsChanged.next(undefined);
			})
			.then(() => {
				return this.join();
			})
			.catch(err => {
				console.warn('Error while start connection, retrying: ', err);
				this.connectionEstablished.next(false);
			});


		this.hubConnection.onreconnecting(() => {
			this.connectionEstablished.next(false);
		});

		this.hubConnection.onreconnected(() => {
			this.connectionEstablished.next(true);
			this.workRequestCountsChanged.next(undefined);
			this.join();
		});
	}

	public endConnection(): Promise<void> {
		if (this.hubConnection) {
			this.unregisterServerEvents();
			return this.leave()
				.then(() => {
					return this.hubConnection.stop()
				})
				.then(() => {
					this.connectionEstablished.next(false);
				})
				.catch(err => {
					console.warn('Error while end connection: ', err);
				});
		}
		return new Promise<void>(null);
	}

	public async join(): Promise<string> {
		return this.hubConnection
			.invoke('Join')
			.then((value) => {
				console.warn('join hubConnection state: ', value, this.hubConnection.state);
				this.joined.next(true);
				return value;
			})
			.catch(err => {
				console.warn('Error while join: ', err);
			});
	}

	public async leave(): Promise<string> {
		return this.hubConnection
			.invoke('Leave')
			.then((x) => {
				this.joined.next(false);
				return x;
			})
			.catch(err => {
				console.warn('Error while leave: ', err);
			});
	}

	private registerServerEvents(): void {
		this.hubConnection.on('NotificationCountsChanged', (notificationCounts: NotificationCounts) => {
			this.notificationCountsChanged.emit(notificationCounts);
		});
		this.hubConnection.on('WorkRequestCountsChanged', (workRequestCounts: WorkRequestCounts) => {
			this.workRequestCountsChanged.emit(workRequestCounts);
		});
		this.hubConnection.on('NoticeWorkReload', (workId: number) => {
			this.noticeWorkReload.emit(workId);
		});
		this.hubConnection.on('NoticeClearCache', (value: string) => {
			switch (value) {
				case 'companies':
					this.companyService.clearCompanyViews();
					this.companyService.clearOwnerCompanyViews();
					break;
				case 'works':
					this.workService.clearWorkViews();
					break;
				case 'appUsers':
					this.appUserService.clearAppUserViews();
					break;
				default:
					console.warn(value + 'doesn\'t exist');
			}
		});
	}

	private unregisterServerEvents(): void {
		this.hubConnection.off('NotificationCountsChanged');
		this.hubConnection.off('WorkRequestCountsChanged');
	}
}
