import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-custom-icon',
	templateUrl: './custom-icon.component.html',
})
export class CustomIconComponent implements OnInit {
	@Input() name: string;
	@Input() height: number = 24;
	@Input() width: number = 24;
	icons = [
		// 'information',
		// 'warning',
		// 'stop',
	];

	constructor(
		// iconRegistry: MatIconRegistry,
		// sanitizer: DomSanitizer
	) {
		console.log('Constructor: ', this.name);
		// this.icons.forEach(x => {
		//   iconRegistry.addSvgIcon(
		//     x,
		//     sanitizer.bypassSecurityTrustResourceUrl('assets/icons/' + x + '.svg'));
		// });
	}

	ngOnInit() {
		console.log('ngOnInit: ', this.name);
	}
}
