import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppUserView } from '@models/app-user-view';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { errorTitle } from '@utils/helpers/error-helpers';
import { AppUserService } from '@core/services/app-user.service';
import { Router } from '@angular/router';
import { NotificationService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-app-user-contacts',
	templateUrl: './app-user-contacts.component.html',
	styleUrls:['./app-user-contacts.component.scss']
})
export class AppUserContactsComponent implements OnInit, OnDestroy {
	@Input() canUpdate: boolean = false;

	@Input()
	set currentUserView(value: AppUserView) {
		this._currentUserView.next(value);
	};

	get currentUserView(): AppUserView {
		return this._currentUserView.getValue();
	}

	private _currentUserView = new BehaviorSubject<AppUserView>(null);
	currentAppUserView = new BehaviorSubject<AppUserView>(null);
	unsubscribe: Subject<any> = new Subject<any>();

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private appUserService: AppUserService,
		private notificationService: NotificationService,
	) {

	}

	ngOnInit(): void {
		this._currentUserView
			.pipe(
				filter(x => x !== null),
			)
			.subscribe((data: AppUserView) => {
				this.currentAppUserView.next(data);
			});
	}

	goEmailConfirmation(): void {
		this.appUserService.emailConfirmation(this.router.url.trim(), 0)
			.pipe(
				takeUntil(this.unsubscribe)
			)
			.subscribe({
				next: (x) => {
					this.notificationService.showPrimary(x.message);
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	ngOnDestroy() {
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}
}
