import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkExecutorMarkView } from '@models/work-executor-mark-view';
import { UtilsService } from '@core/services/utils.service';
import { WorkStateValue } from '@models/work-view';

@Component({
	selector: 'pdw-work-executor-mark-card-dialog',
	templateUrl: './work-executor-mark-card-dialog.component.html',
})
export class WorkExecutorMarkCardDialogComponent {
	timeForLogo: Date = new Date();
	WorkState = WorkStateValue;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { title: string, workExecutorMarkView: WorkExecutorMarkView },
		public utilsService: UtilsService
	) {
	}


	averageMark(): number {
		const mark = this.data.workExecutorMarkView;
		return +((mark.speedValue + mark.qualityValue + mark.experienceValue) / 3).toFixed(1);
	}

}
