import {
	AfterViewInit,
	Directive, ElementRef,
	EventEmitter,
	HostListener, Inject,
	Input,
	Output,
} from '@angular/core';
import {
	PaginationData,
} from '@shared/directives/scroll-pagination/scroll-pagination.types';


@Directive({
	selector: '[pdwScrollPagination]'
})
export class ScrollPaginationDirective implements AfterViewInit {
	@Input() maxCount!: number;
	@Input() currentIndex!: number;
	@Input() loading: boolean = false;
	@Output() emitterOnBottom: EventEmitter<PaginationData> = new EventEmitter<PaginationData>();
	@Output() emitterOnInit: EventEmitter<PaginationData> = new EventEmitter<PaginationData>();
	private heightItem: number = 0;
	private widthItem: number = 0;
	private itemsPerPage: number = 0;
	private multiple: number = 1;

	constructor(@Inject('WINDOW') private window: Window, private el: ElementRef) {
		this.window.dispatchEvent(new Event('resize'));

	}

	@HostListener('document:scroll', ['$event']) scroll($event: Event) {
		const document = $event.target as Document;
		let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
		let max = document.documentElement.scrollHeight;

		if (pos + 150 >= max && !this.loading) {
			this.itemsPerPage = this.calculateHeight(this.window.screen.height, this.heightItem) * this.multiple;
			if (this.maxCount > (this.currentIndex + 1) * this.itemsPerPage) {
				this.emitterOnBottom.emit({ pageIndex: this.currentIndex + 1, pageSize: this.itemsPerPage });
			}
		}
	}

	private calculateHeight(windowHeight: number, itemHeight: number): number {
		return Math.floor(windowHeight / itemHeight);
	}

	@HostListener('window:resize', ['$event']) onResize(event: Event) {
		if (this.itemsPerPage > this.calculateHeight(this.window.screen.height, this.heightItem) * this.multiple) {
			return
		}

		this.itemsPerPage = this.calculateHeight(this.window.screen.height, this.heightItem) * this.multiple;
	}

	ngAfterViewInit(): void {
		this.heightItem = (this.el.nativeElement.children[0] as HTMLElement).offsetHeight;
		this.widthItem = (this.el.nativeElement.children[0] as HTMLElement).offsetWidth;
		this.multiple = Math.round((this.el.nativeElement as HTMLElement).offsetWidth / this.widthItem) > 1 ? Math.round((this.el.nativeElement as HTMLElement).offsetWidth / this.widthItem) : 2;

		this.itemsPerPage = this.calculateHeight(this.window.screen.height, this.heightItem) * this.multiple;
		this.emitterOnInit.emit({ pageIndex: 0, pageSize: this.itemsPerPage });
	}

}
