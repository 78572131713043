<ng-container *ngLet='both$ | async as users'>
	<ng-container *ngIf='users[0] as currentAppUser'>
		<div class='pd-personal-column'>
			<div class='pd-personal-top-column'>
				<div class='pd-personal-top-row'>
					<h1 class='pd-profile-title'>ИНН</h1>
					<ng-container *ngIf='canUpdate'>
						<button
							class='pd-button-secondary small'
							mat-raised-button
							(click)='goInnVerification()'
						>
							<p class='pd-profile'>Узнать ИНН</p>
						</button>
					</ng-container>
				</div>
				<div>
					<p class='pd-profile-text-medium'>
						{{ appUserInn(currentAppUser) }}
					</p>
				</div>
			</div>
			<div *pdwFeatureFlag class='pd-personal-top-column'>
				<div class='pd-personal-top-row'>
					<h1 class='pd-profile-title'>Статус самозанятости</h1>
					<mat-icon
						class='pd-icon light-gray'
						svgIcon='additional-alert-question-1'
						[matTooltipPosition]="'right'"
						[matTooltip]="'Без статуса самозанятого вы не можете заключать сделки на платформе'"
					></mat-icon>
				</div>
				<div class='pd-personal-top-row'>
					<ng-container *ngIf='canUpdate'>
						<ng-container *ngIf='canUpdate'>
							<button class='pd-personal-state {{ statusColorClass(appUserInnState) }}'>
								{{ statusText(appUserInnState) }}
							</button>
						</ng-container>
						<button
							class='pd-button-secondary small'
							mat-raised-button
							(click)='goCheckStatus()'
						>
							<mat-icon
								class='pd-icon'
								svgIcon='interface-refresh'
							></mat-icon>
							<p class='pd-profile-button-text'>Проверить</p>
						</button>
					</ng-container>
				</div>
				<div>
					<p class='pd-profile-text-medium'>
						{{ statusDateText(appUserInnState) }} {{ getStatusDate(appUserInnState) | date : "dd.MM.yyyy" }}
					</p>
				</div>
			</div>
		</div>
	</ng-container>
</ng-container>
