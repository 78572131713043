import {WorkExecutorMarkView} from "@models/work-executor-mark-view";
import {BehaviorSubject, Observable, tap} from "rxjs";
import {WorkExecutorMarkService} from "@core/services/work-executor-mark.service";
import {CollectionViewer} from "@angular/cdk/collections";
import {AppUserView} from "@models/app-user-view";
import {NoSortAndFilterDataSource} from "@core/dataSources/no-sort-and-filter-data-source";

export class AppUserMarksDataSource implements NoSortAndFilterDataSource<WorkExecutorMarkView> {
  private workExecutorMarkViewsSubject = new BehaviorSubject<WorkExecutorMarkView[]>([]);
  private countSubject = new BehaviorSubject<number>(0);

  constructor(
    private appUserView: AppUserView,
    private workExecutorMarkService: WorkExecutorMarkService
  ) {}
  data = this.workExecutorMarkViewsSubject.asObservable();
  count = this.countSubject.asObservable();

  connect(collectionViewer: CollectionViewer): Observable<WorkExecutorMarkView[]> {
    return this.workExecutorMarkViewsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer) {
    this.workExecutorMarkViewsSubject.complete();
    this.countSubject.complete();
  }

  load(pageIndex: number, pageSize: number): Observable<WorkExecutorMarkView[]>{
    return this.workExecutorMarkService.ofUser(this.appUserView.id, pageIndex, pageSize)
      .pipe(
        tap(workExecutorMarkViews => this.workExecutorMarkViewsSubject.next(workExecutorMarkViews))
      );
  }

  loadMore(pageIndex: number, pageSize: number): Observable<WorkExecutorMarkView[]>{
    return this.workExecutorMarkService.ofUser(this.appUserView.id, pageIndex, pageSize)
      .pipe(
        tap(workExecutorMarkViews => {
          const tmp = this.workExecutorMarkViewsSubject.value;
          const newData = workExecutorMarkViews.filter(x => !tmp.some(w => w.id === x.id));

          this.workExecutorMarkViewsSubject.next(tmp.concat(newData));
        })
      );
  }

  loadCount(): Observable<number> {
    return this.workExecutorMarkService.ofUserCount(this.appUserView.id)
      .pipe(
        tap(count => this.countSubject.next(count))
      );
  }
}
