import { Injectable } from '@angular/core';
import { AbstractModelService } from '@core/services/abstract-model.service';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkTypeView } from '@models/work-type-view';

@Injectable({
	providedIn: 'root'
})
export class WorkTypeService {
	private workTypeUrl = 'api/WorkType';

	constructor(private http: HttpClient, private modelService: AbstractModelService) {
	}

	public static parseDates(subCategoryView: WorkTypeView): WorkTypeView {
		subCategoryView.createTime = new Date(subCategoryView.createTime);
		subCategoryView.modifyTime = new Date(subCategoryView.modifyTime);
		return subCategoryView;
	}

	all(): Observable<WorkTypeView[]> {
		return this.http.get<WorkTypeView[]>(`${this.workTypeUrl}/All`, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => WorkTypeService.parseDates(x))),
			);
	}

	firstFifty(): Observable<WorkTypeView[]> {
		return this.http.get<WorkTypeView[]>(`${this.workTypeUrl}/FirstFifty`, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => WorkTypeService.parseDates(x))),
			);
	}

	filter(query: string): Observable<WorkTypeView[]> {
		return this.http.get<WorkTypeView[]>(`${this.workTypeUrl}/Filter/${query}`, this.modelService.httpOptions)
			.pipe(
				tap(x => console.log('filter: ', query, x)),
				map(xx => xx.map(x => WorkTypeService.parseDates(x))),
			);
	}
}
