<div class='pd-complex-filter-dialog-header wrapper'>
	<div class='pd-complex-filter-dialog-input-field'>
		<mat-icon
			class='pd-complex-filter-dialog-header-icon'
			svgIcon='general-search'
		></mat-icon>
		<tui-scrollbar
			appHorizontalScrollable
			class='pd-complex-filter-dialog-chips-scroll pd-scrollbar'
		>
			<mat-chip-list
				class='pd-chip-list-not-mat'
				#chipList
			>
				<mat-chip
					class='pd-chip'
					*ngFor='let filter of filtersWithoutMainTag | async'
					(removed)='removeFilter(filter.name)'
					[disableRipple]='true'
				>
					{{ titleFilter(filter) }}
					<span
						class='filter-value'
						[matTooltip]='tooltipFilter(filter)'
					>
						: {{ valueFilter(filter) }}
					</span>
					<button
						class='pd-chip-remove-btn'
						matChipRemove
					>
						<mat-icon svgIcon='interface-close-small'></mat-icon>
					</button>
				</mat-chip>
			</mat-chip-list>
		</tui-scrollbar>
		<input
			class='pd-chip-input'
			matInput
			[placeholder]="(filtersWithoutMainTag | async).length && trueData?.placeholder ? '+ ' + trueData?.placeholder : data?.placeholder"
			required
			(keyup)='onAnyFilterKeyUp($event)'
			[matChipInputFor]='chipList'
			[formControl]='anyFilter'
			#anyFilterInput
		>
	</div>
	<ng-container *ngIf='(filtersWithoutMainTag | async).length || anyFilter.value'>
		<button
			class='pd-icon-button'
			mat-icon-button
			[disableRipple]='true'
			(click)='onReset(true)'
		>
			<mat-icon svgIcon='interface-close'></mat-icon>
		</button>
	</ng-container>
</div>
<div class='pd-complex-filter-dialog-body wrapper'>
	<div class='pd-complex-filter-dialog-mobile-header'>
		<p class='pd-dialog-header-mobile-header-title'>Фильтр</p>
		<button
			class='pd-icon-button'
			mat-icon-button
			[mat-dialog-close]='false'
			type='submit'
		>
			<mat-icon svgIcon='interface-close'></mat-icon>
		</button>
	</div>
	<tui-scrollbar class='pd-scrollbar pd-complex-filter-dialog-scroll'>
		<div class="reset-btn-wrapper">
			<button
				form='filterForm'
				mat-raised-button
				class='pd-button-secondary'
				type='button'
				(click)='onReset()'
			>
				Сбросить все ({{(filtersWithoutMainTag | async).length}})
			</button>
		</div>
		<div
			class='pd-complex-filter-dialog-content'
			*ngIf='currentFormGroup'
		>
			<form
				id='filterForm'
				class='pd-complex-filter-dialog-form'
				[formGroup]='currentFormGroup'
				(reset)='onReset()'
				(ngSubmit)='onSubmit(currentFormGroup)'
			>
				<mat-form-field
				class='pd-input-form-field'
				appearance='fill'
				hideRequiredMarker
				>
					<mat-icon
						class='pd-field-prefix-icon'
						svgIcon='general-search'
						matPrefix
					></mat-icon>
					<mat-label>Кого ищем?</mat-label>
					<input
						matInput
						type='text'
						[formControl]='anyFilter'
					>
				</mat-form-field>
				<ng-container
					*ngFor='let filterField of trueData.displayedFields'
					[ngSwitch]='filterField.inputType'
				>
					<ng-container *ngSwitchCase="'select'">
						<p class='pd-complex-filter-field-title'>
							{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
						</p>
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
						>
							<mat-icon
								class='pd-field-prefix-icon'
								svgIcon='{{filterField.icon}}'
								matPrefix
							></mat-icon>
							<mat-label>
								{{ filterField.pipe.transform(filterField.name, 'propertyHint') }}
							</mat-label>
							<pd-select
								[formControlName]='filterField.name'
							>
								<pd-select-option
									*ngFor='let item of filterField.selectOptions.selectValues'
									[value]='item'
								>
									{{filterField.selectOptions.valuesPipe.transform(item)}}
								</pd-select-option>
							</pd-select>
							<button
								matSuffix
								class='pd-icon-button'
								mat-icon-button
								[disableRipple]='true'
								type='button'
								*ngIf='showClearControlBtn(filterField)'
								(click)='resetFormControl($event ,filterField)'
							>
								<mat-icon svgIcon='interface-close'></mat-icon>
							</button>
						</mat-form-field>
					</ng-container>
					<ng-container *ngSwitchCase="'text'">
						<p class='pd-complex-filter-field-title'>
							{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
						</p>
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
						>
							<mat-icon
								class='pd-field-prefix-icon'
								svgIcon='{{filterField.icon}}'
								matPrefix
							></mat-icon>
							<mat-label>
								{{ filterField.pipe.transform(filterField.name, 'propertyHint') }}
							</mat-label>
							<input
								matInput
								type='search'
								[formControlName]='filterField.name'
							>
						</mat-form-field>
					</ng-container>
					<ng-container *ngSwitchCase="'budget'">
						<p class='pd-complex-filter-field-title'>
							{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
						</p>
			
						<div
							class='budget-wrapper'
							*ngIf='filterField.halfInput; else defaultBudget'
						>
							<div class='pd-taiga-input-wrapper'>
								<tui-input-number
									class='pd-taiga-input pd-taiga-input-w-prefix'
									[min]='0'
									[decimal]="'never'"
									[ngClass]="{'pd-taiga-input-empty': isTuiInputEmpty(filterField.name)}"
									[formControlName]='filterField.name'
								>{{ filterField.pipe.transform(filterField.name, 'propertyHint') }}</tui-input-number>
								<mat-icon
									*ngIf='filterField.icon'
									class='pd-icon pd-taiga-input-prefix-icon'
									svgIcon='{{filterField.icon}}'
								></mat-icon>
							</div>
							<div *ngIf='filterField?.selectOptions?.isMultiple' class='pd-taiga-input-wrapper'>
								<tui-input-number
									class='pd-taiga-input pd-taiga-input-w-prefix'
									[min]='0'
									[decimal]="'never'"
									[ngClass]="{'pd-taiga-input-empty': isTuiInputEmpty(secondInputName(filterField.name))}"
									[formControlName]='secondInputName(filterField.name)'
								>{{ filterField.pipe.transform(filterField.name, 'propertySecondHint') }}</tui-input-number>
								<mat-icon
									*ngIf='filterField.icon'
									class='pd-icon pd-taiga-input-prefix-icon'
									svgIcon='{{filterField.icon}}'
								></mat-icon>
							</div>
						</div>
			
						<ng-template #defaultBudget>
			
							<div class='pd-taiga-input-wrapper half-width'>
								<tui-input-number
									class='pd-taiga-input pd-taiga-input-w-prefix'
									[min]='0'
									[decimal]="'never'"
									[ngClass]="{'pd-taiga-input-empty': isTuiInputEmpty(filterField.name), 'non-icon': !filterField?.icon}"
									[formControlName]='filterField.name'
								>{{ filterField.pipe.transform(filterField.name, 'propertyHint') }}</tui-input-number>
								<mat-icon
									*ngIf='filterField.icon'
									class='pd-icon pd-taiga-input-prefix-icon'
									svgIcon='{{filterField.icon}}'
								></mat-icon>
							</div>
							<div *ngIf='filterField?.selectOptions?.isMultiple' class='pd-taiga-input-wrapper half-width'>
								<tui-input-number
									class='pd-taiga-input pd-taiga-input-w-prefix'
									[min]='0'
									[decimal]="'never'"
									[ngClass]="{'pd-taiga-input-empty': isTuiInputEmpty(secondInputName(filterField.name))}"
									[formControlName]='secondInputName(filterField.name)'
								>{{ filterField.pipe.transform(filterField.name, 'propertySecondHint') }}</tui-input-number>
								<mat-icon
									*ngIf='filterField.icon'
									class='pd-icon pd-taiga-input-prefix-icon'
									svgIcon='{{filterField.icon}}'
								></mat-icon>
							</div>
						</ng-template>
					</ng-container>
					<ng-container *ngSwitchCase="'rating'">
						<p class='pd-complex-filter-field-title'>
							{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
						</p>
						<div class='pd-taiga-input-wrapper'>
							<tui-input-number
								class='pd-taiga-input pd-taiga-input-w-prefix'
								[min]='0'
								[precision]='1'
								[max]='5'
								[ngClass]="{'pd-taiga-input-empty': isTuiInputEmpty(filterField.name)}"
								[formControlName]='filterField.name'
							>{{ filterField.pipe.transform(filterField.name, 'propertyHint') }}</tui-input-number>
							<mat-icon
								class='pd-icon pd-taiga-input-prefix-icon'
								svgIcon='{{filterField.icon}}'
							></mat-icon>
						</div>
						<div class='pd-taiga-input-wrapper '>
							<tui-input-number
								class='pd-taiga-input pd-taiga-input-w-prefix'
								[min]='0'
								[precision]='1'
								[max]='5'
								[ngClass]="{'pd-taiga-input-empty': isTuiInputEmpty(secondInputName(filterField.name))}"
								[formControlName]='secondInputName(filterField.name)'
							>{{ filterField.pipe.transform(filterField.name, 'propertySecondHint') }}</tui-input-number>
							<mat-icon
								class='pd-icon pd-taiga-input-prefix-icon'
								svgIcon='{{filterField.icon}}'
							></mat-icon>
						</div>
					</ng-container>
					<ng-container *ngSwitchCase="'autocomplete'">
						<p class='pd-complex-filter-field-title'>
							{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
						</p>
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
						>
							<mat-label>{{ filterField.pipe.transform(filterField.name, 'propertyHint') }}</mat-label>
							<mat-icon
								class='pd-field-prefix-icon'
								svgIcon='{{filterField.icon}}'
								matPrefix
							></mat-icon>
							<input
								matInput
								[id]='filterField.name'
								[formControlName]='filterField.name'
								[matAutocomplete]='auto'
							>
							<mat-autocomplete
								class='pd-autocomplete'
								#auto='matAutocomplete'
								(optionSelected)='selectedItem(filterField, $event)'
							>
								<tui-scrollbar class='pd-scrollbar pd-autocomplete-scrollbar'>
									<mat-option
										class='pd-option'
										[disabled]='disabledItem(filterField.name, item)'
										[value]='item'
										*ngFor='let item of filterSource(filterField.name).source | async'
									>
										{{ item.titleLong ? item.titleLong : item.title }}
									</mat-option>
								</tui-scrollbar>
							</mat-autocomplete>
						</mat-form-field>
						<ng-container *ngIf='filter(filterField.name) as filter'>
							<mat-chip-list
								aria-label='Skills selection'
								class='pd-chip-list-wrap pd-chip-list-not-mat'
							>
								<mat-chip
									class='pd-chip'
									*ngFor='let item of filter.value'
									[removable]='true'
									(removed)='removeItem(filterField.name, item)'
								>
									<span class='pd-chip-label'>{{ item.title }}</span>
									<button
										class='pd-chip-remove-btn'
										matChipRemove
									>
										<mat-icon svgIcon='interface-close-small'></mat-icon>
									</button>
								</mat-chip>
							</mat-chip-list>
						</ng-container>
					</ng-container>
					<ng-container *ngSwitchCase="'autocomplete-without-icon'">
						<p class='pd-complex-filter-field-title'>
							{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
						</p>
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
						>
							<mat-label>{{ filterField.pipe.transform(filterField.name, 'propertyHint') }}</mat-label>
							<input
								matInput
								[id]='filterField.name'
								[formControlName]='filterField.name'
								[matAutocomplete]='auto'
							>
							<mat-autocomplete
								class='pd-autocomplete'
								#auto='matAutocomplete'
								(optionSelected)='selectedItem(filterField, $event)'
							>
								<tui-scrollbar class='pd-scrollbar pd-autocomplete-scrollbar'>
									<mat-option
										class='pd-option'
										[disabled]='disabledItem(filterField.name, item)'
										[value]='item'
										*ngFor='let item of filterSource(filterField.name).source | async'
									>
										{{ item.titleLong ? item.titleLong : item.title }}
									</mat-option>
								</tui-scrollbar>
							</mat-autocomplete>
						</mat-form-field>
						<ng-container *ngIf='filter(filterField.name) as filter'>
							<mat-chip-list
								aria-label='Skills selection'
								class='pd-chip-list-wrap pd-chip-list-not-mat'
							>
								<mat-chip
									class='pd-chip'
									*ngFor='let item of filter.value'
									[removable]='true'
									(removed)='removeItem(filterField.name, item)'
								>
									<span class='pd-chip-label'>{{ item.title }}</span>
									<button
										class='pd-chip-remove-btn'
										matChipRemove
									>
										<mat-icon svgIcon='interface-close-small'></mat-icon>
									</button>
								</mat-chip>
							</mat-chip-list>
						</ng-container>
					</ng-container>
					<ng-container *ngSwitchCase="'dateRange'">
						<p class='pd-complex-filter-field-title'>
							{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
						</p>
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
							hideRequiredMarker
						>
							<mat-icon
								class='pd-field-prefix-icon'
								svgIcon='{{filterField.icon}}'
								matPrefix
							></mat-icon>
							<mat-label>
								{{ filterField.pipe.transform(filterField.name, 'propertyHint') }}
							</mat-label>
							<input
								matInput
								[formControlName]='filterField.name'
								(beforeinput)='onDateInput($event)'
								[matDatepicker]='datePicker'
							>
							<mat-datepicker-toggle
								class='pd-datepicker-toggle'
								matSuffix
								[for]='datePicker'
							>
								<mat-icon
									svgIcon='arrow-down-2'
									matDatepickerToggleIcon
								></mat-icon>
							</mat-datepicker-toggle>
							<mat-datepicker
								[panelClass]="'pd-calendar'"
								#datePicker
								[calendarHeaderComponent]='calendarHeader'
							></mat-datepicker>
						</mat-form-field>
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
							hideRequiredMarker
						>
							<mat-icon
								class='pd-field-prefix-icon'
								svgIcon='{{filterField.icon}}'
								matPrefix
							></mat-icon>
							<mat-label>
								{{ filterField.pipe.transform(filterField.name, 'propertySecondHint') }}
							</mat-label>
							<input
								matInput
								[formControlName]='secondInputName(filterField.name)'
								[matDatepicker]='secondDatePicker'
							>
							<mat-datepicker-toggle
								class='pd-datepicker-toggle'
								matSuffix
								[for]='secondDatePicker'
							>
								<mat-icon
									svgIcon='arrow-down-2'
									matDatepickerToggleIcon
								></mat-icon>
							</mat-datepicker-toggle>
							<mat-datepicker
								[panelClass]="'pd-calendar'"
								#secondDatePicker
								[calendarHeaderComponent]='calendarHeader'
							></mat-datepicker>
						</mat-form-field>
					</ng-container>
					<ng-container *ngSwitchCase="'multi-toggle'">
						<p class='pd-complex-filter-field-title'>
							{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
						</p>
						<mat-button-toggle-group
							class='pd-button-toggle-group-divided'
							[formControlName]='filterField.name'
							multiple='true'
						>
							<ng-container *ngFor='let item of filterField.selectOptions.selectValues'>
								<mat-button-toggle
									class='pd-button-toggle'
									[value]='item'
								>
									{{filterField.selectOptions.valuesPipe.transform(item)}}
								</mat-button-toggle>
							</ng-container>
						</mat-button-toggle-group>
					</ng-container>
					<ng-container *ngSwitchCase="'main-tag'">
						<p class='pd-complex-filter-field-title' *ngIf="filterField.isToShowLabel">
							{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
						</p>
						<mat-button-toggle-group
							class='pd-button-toggle-group-divided'
							[formControlName]='filterField.name'
						>
							<ng-container *ngFor='let item of filterField.selectOptions.selectValues'>
								<mat-button-toggle
									class='pd-button-toggle'
									[value]='item'
								>
									{{filterField.selectOptions.valuesPipe.transform(item)}}
								</mat-button-toggle>
							</ng-container>
						</mat-button-toggle-group>
					</ng-container>
					<ng-container *ngSwitchCase="'number'">
						<p class='pd-complex-filter-field-title'>
							{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
						</p>
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
						>
							<mat-icon
								class='pd-field-prefix-icon'
								svgIcon='{{filterField.icon}}'
								matPrefix
							></mat-icon>
							<mat-label>
								{{ filterField.pipe.transform(filterField.name, 'propertyHint') }}
							</mat-label>
							<input
								matInput
								oninput='this.value = Math.abs(this.value)'
								type='number'
								min='0'
								[formControlName]='filterField.name'
							>
						</mat-form-field>
						<mat-form-field
							class='pd-input-form-field'
							appearance='fill'
						>
							<mat-icon
								class='pd-field-prefix-icon'
								svgIcon='{{filterField.icon}}'
								matPrefix
							></mat-icon>
							<mat-label>
								{{ filterField.pipe.transform(filterField.name, 'propertySecondHint') }}
							</mat-label>
							<input
								matInput
								min='0'
								type='number'
								[formControlName]='secondInputName(filterField.name)'
							>
						</mat-form-field>
					</ng-container>
					<ng-container *ngSwitchCase="'checkbox-list'">
						<p
							*ngIf='filterField.pipe.transform(filterField.name, "propertyTitle")?.length'
							class='pd-complex-filter-field-title'
						>
							{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
						</p>
						<pdw-checkbox-list
							[label]='filterField.checkboxLabel'
							[formControlName]='filterField.name'
							[options]='filterField.selectOptions.selectValues'
						></pdw-checkbox-list>
					</ng-container>
					<ng-container *ngSwitchCase="'checkbox'">
						<p
							*ngIf='filterField.pipe.transform(filterField.name, "propertyTitle")?.length'
							class='pd-complex-filter-field-title'
						>
							{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
						</p>
						<pdw-checkbox-list
							[label]='filterField.checkboxLabel'
							[formControlName]='filterField.name'
							[options]='filterField.selectOptions.selectValues'
						></pdw-checkbox-list>
					</ng-container>
					<ng-container *ngSwitchCase="'autocomplete-chips'">
						<p class='pd-complex-filter-field-title'>
							{{ filterField.pipe.transform(filterField.name, 'propertyTitle') }}
						</p>
						<pdw-chips-select
							[icon]='filterField.icon'
							[isMultiple]='filterField?.selectOptions?.isMultiple ?? false'
							[optionLabel]='filterField.labelOption'
							[optionKey]='filterField.labelOption'
							[formControlName]='filterField.name'
							[options]='filterField.source'
							[label]='filterField.pipe.transform(filterField.name, "propertyTitle")'
						>
							<ng-container *ngIf='filterField.children as children'>
								<ng-container *ngFor='let child of children'>
									<pdw-checkbox-list
										class='checkbox-relocate'
										*ngIf='child.inputType === "checkbox-list"'
										[label]='child.checkboxLabel'
										[formControlName]='child.name'
										[options]='child.selectOptions.selectValues'
									></pdw-checkbox-list>
								</ng-container>
							</ng-container>
						</pdw-chips-select>
					</ng-container>
				</ng-container>
			</form>
			<div class='pd-complex-filter-dialog-footer'>
				<p class='pd-complex-filter-dialog-footer-text'>
					<mat-icon
						class='pd-icon'
						svgIcon='interface-check'
					></mat-icon>
					Подходит {{(currentCountFilter | async) | numberToWordDeclension: trueData.counterWordsToDeclension : true}}
				</p>
			</div>
		</div>
	</tui-scrollbar>
</div>
