<pdw-dialog-header title='Выберите специалиста'>

</pdw-dialog-header>

<pdw-dialog-body>
	<mat-form-field
		class='pd-input-form-field'
		appearance='fill'
		hideRequiredMarker
	>
		<mat-icon
			matPrefix
			class='pd-field-prefix-icon gray'
			svgIcon='general-search'
		></mat-icon>
		<mat-label>Поиск специалистов</mat-label>
		<input
			type='text'
			matInput
			class='pd-chip-input'
			[formControl]='searchControl'
		>
		<button
			class='pd-icon-button with-line-height gray'
			mat-icon-button
			matSuffix
			matTooltip='Очистить фильтр'
			*ngIf='!isSearchEmpty()'
			(click)='searchControl.reset("")'
		>
			<mat-icon
				class='pd-icon'
				svgIcon='additional-close'
			></mat-icon>
		</button>
	</mat-form-field>
	<ul class='executor-list'>
		<li
			class='executor-list-item'
			*ngFor='let spec of (executors$ | async)'
		>
			<a
				[routerLink]='"/users/" + spec.executor.id'
				target='_blank'
			>
				{{spec.executor.firstname + " " + spec.executor.lastname}}
			</a>

			<pd-rounded-checkbox
				[formControl]='executorControl'
				(change)='toggleSelected(spec.id)'
				[checked]='hasSelected(spec.id)'
			>
			</pd-rounded-checkbox>
		</li>
		<ng-container *ngIf='isSearchEmpty()'>
			<li class='executor-list-item without-line'>
				<span>
					Никого не выбрал
				</span>

				<pd-rounded-checkbox
					(change)='toggleSelected(-1)'
					[checked]='hasSelected(-1)'
				>
				</pd-rounded-checkbox>
			</li>
			<li class='executor-list-item without-line'>
				<span>
					Выбрал на другой платформе
				</span>

				<pd-rounded-checkbox
					(change)='toggleSelected(-2)'
					[checked]='hasSelected(-2)'
				>
				</pd-rounded-checkbox>
			</li>
		</ng-container>
	</ul>
</pdw-dialog-body>

<pdw-dialog-footer>
	<button
		mat-raised-button
		class='pd-button large'
		(click)='sendRequest()'
		[disabled]='(loading$ | async) || isValueEmpty'
	>
		Выбрать
	</button>

</pdw-dialog-footer>
