import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggingService } from '@core/services/logging.service';
import { ErrorService } from '@core/services/error.service';
import { NotificationService } from '@profdepo-ui/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor(private injector: Injector) {
	}

	handleError(error: Error | HttpErrorResponse) {
		const errorService = this.injector.get(ErrorService);
		const logger = this.injector.get(LoggingService);
		const notifier = this.injector.get(NotificationService);

		let message;
		let stackTrace;

		if (error instanceof HttpErrorResponse) {
			console.warn('GlobalErrorHandler server error: ', error);
			// Server Error
			message = errorService.getServerMessage(error);
			stackTrace = errorService.getServerStack(error);
			notifier.showDanger(message);
		} else {
			console.warn('GlobalErrorHandler client error: ', error);
			// Client Error
			message = errorService.getClientMessage(error);
			stackTrace = errorService.getClientStack(error);
			notifier.showDanger(message);
		}

		// Always log errors
		logger.logError(message, stackTrace);

		console.error(error);
	}
}
