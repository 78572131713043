<ng-container
	*ngIf='formGroup'
	[formGroup]='formGroup'
>
	<div
		class='pd-checkbox-list'
		[formArrayName]="'values'"
	>
		<pd-checkbox
			*ngFor='let option of options; let i = index'
			[formControlName]='i'
		>{{displayedName(option)}}</pd-checkbox>
	</div>
</ng-container>
