import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AppUserDialogData, AppUserView } from '@models/app-user-view';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { SubCategoryService } from '@core/services/sub-category.service';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { SubCategoryView } from '@models/sub-category-view';
import { errorTitle } from '@utils/helpers/error-helpers';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { FormControl } from '@angular/forms';
import { TreeFlatNode, TreeNode } from '@shared/company-industries-dialog/company-industries-dialog.component';
import { groupByField } from '@utils/helpers/array-helpers';
import { AppUserService } from '@core/services/app-user.service';
import { NotificationService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-app-user-sub-categories-dialog',
	templateUrl: './app-user-sub-categories-dialog.component.html',
	styleUrls: ['./app-user-sub-categories-dialog.component.scss']
})
export class AppUserSubCategoriesDialogComponent implements OnInit, OnDestroy {
	dataSource: MatTreeFlatDataSource<TreeNode, TreeFlatNode>;
	treeControl: FlatTreeControl<TreeFlatNode>;
	treeFlattener: MatTreeFlattener<TreeNode, TreeFlatNode>;
	treeFilter = new FormControl('');
	subCategoryViewsSource: SubCategoryView[];
	subCategorySelection: SelectionModel<SubCategoryView>;
	emptySourceOnFilter = new BehaviorSubject<boolean>(false);
	saving = new BehaviorSubject<boolean>(false);
	unsubscribe: Subject<any> = new Subject<any>();

	constructor(
		public dialogRef: MatDialogRef<AppUserDialogData, boolean | AppUserView>,
		@Inject(MAT_DIALOG_DATA) public data: AppUserDialogData,
		private appUserService: AppUserService,
		private subCategoryService: SubCategoryService,
		private notificationService: NotificationService,
	) {
	}

	ngOnInit(): void {
		this.subCategoryService.all()
			.pipe(
				takeUntil(this.unsubscribe)
			)
			.subscribe({
				next: (subCategoryViews: SubCategoryView[]) => {
					subCategoryViews
						.forEach(x => x.categoryName = x.category.name);
					this.subCategoryViewsSource = subCategoryViews;
					this.treeFlattener = new MatTreeFlattener(
						this._transformer,
						node => node.level,
						node => node.expandable,
						node => node.children,
					);
					this.treeControl = new FlatTreeControl<TreeFlatNode>(
						node => node.level,
						node => node.expandable,
					);
					this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener, this.buildTree(subCategoryViews));
					// let tmp = this.treeControl.dataNodes
					//   .filter(x => x.level === 0 && this.data.appUserView.subCategories.some(i => i.category === x.name));
					let tmp = subCategoryViews
						.filter(x => this.data.appUserView?.subCategories?.some(i => i.id === x.id));
					this.treeControl.dataNodes
						.filter(x => x.level === 0 && tmp.some(i => i.category.name === x.name))
						.forEach(x => {
							this.treeControl.expand(x)
						});

					this.subCategorySelection = new SelectionModel<SubCategoryView>(true, this.subCategoryViewsSource
						.filter(x => this.data.appUserView?.subCategories?.some(i => i.id === x.id)));
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});

		this.treeFilter.valueChanges
			.pipe(
				takeUntil(this.unsubscribe),
				distinctUntilChanged(),
				debounceTime(300),
				filter(x => x !== null)
			).subscribe({
			next: v => {
				if (v.length > 1) {
					this.filterByName(v);
				} else {
					this.onClearFilter();
				}
			}
		});
	}

	private _transformer = (node: TreeNode, level: number) => {
		let treeFlatNode = new TreeFlatNode();
		treeFlatNode.id = node.id;
		treeFlatNode.name = node.name;
		treeFlatNode.level = level;
		treeFlatNode.expandable = !!node.children && node.children.length > 0;
		return treeFlatNode;
	};
	hasChild = (_: number, node: TreeFlatNode) => node.expandable;

	buildTree(subCategories: SubCategoryView[]): TreeNode[] {
		return groupByField(subCategories, 'categoryName').map(x => {
			let item = new TreeNode();
			item.name = x.categoryName;
			item.children = x.group.map(w => {
				let subItem = new TreeNode();
				subItem.id = w.id;
				subItem.name = w.name;
				return subItem;
			})
			return item;
		});
	}

	isSelected(node: TreeFlatNode): boolean {
		return this.subCategorySelection.selected.some(x => x.id === node.id);
	}

	selectionToggle(node: TreeFlatNode): void {
		let industryView = this.subCategoryViewsSource.find(x => x.id === node.id);
		if (this.isSelected(node)) {
			this.subCategorySelection.deselect(industryView)
		} else {
			this.subCategorySelection.select(industryView);
		}
	}

	disabled(node: TreeFlatNode): boolean {
		return this.subCategorySelection.selected.length >= 5 && !this.isSelected(node);
	}

	private concatToIds(array: any[]): string {
		if (array) {
			return array
				.map(x => x.id)
				.sort((a, b) => a - b)
				.join(':');
		}
		return '';
	}

	canSave(): boolean {
		if (this.subCategorySelection) {
			return this.concatToIds(this.subCategorySelection.selected) !== this.concatToIds(this.data.appUserView.subCategories);
		}
		return true;
	}

	filterByName(term: string): void {
		const filteredItems = this.treeControl.dataNodes.filter(
			x => x.name.toLowerCase().indexOf(term.toLowerCase()) === -1 && x.expandable === false
		);

		const parentNodesToHide = this.treeControl.dataNodes.filter(x => x.expandable === true);
		const tmpSource = this.subCategoryViewsSource;
		const tmpTreeSource = this.treeControl.dataNodes;
		const parentNodesToShow = [];

		filteredItems.forEach(x => {
			x.visible = false;
		});

		const visibleItems = this.treeControl.dataNodes.filter(
			x => x.name.toLowerCase().indexOf(term.toLowerCase()) > -1 && x.expandable === false
		);

		visibleItems.forEach(x => {
			x.visible = true;
			const parentNodeName = tmpSource.find(n => n.id === x.id).categoryName;
			const indexOfHiddenNode = parentNodesToHide.findIndex(x => x.name === parentNodeName);
			if (parentNodeName && indexOfHiddenNode >= 0) {
				parentNodesToHide.splice(indexOfHiddenNode, 1);
			}
			const indexOfShownNode = parentNodesToShow.findIndex(x => x.name === parentNodeName);
			if (parentNodeName && indexOfShownNode < 0) {
				parentNodesToShow.push(tmpTreeSource.find(x => x.name === parentNodeName));
			}
		});

		if (visibleItems.length === 0) {
			this.emptySourceOnFilter.next(true);
		} else {
			this.emptySourceOnFilter.next(false);
		}

		parentNodesToHide.forEach(x => {
			x.visible = false;
			this.treeControl.collapse(x);
		});
		parentNodesToShow.forEach(x => {
			x.visible = true;
			x.expanded = true;
			this.treeControl.expand(x);
		});
	}

	onClearFilter(): void {
		const parentNodes = this.treeControl.dataNodes.filter(x => x.expandable === true);
		const parentNodeNames: string[] = [];
		this.emptySourceOnFilter.next(false);

		this.treeControl.dataNodes.forEach((x, i) => {
			x.visible = true;

			const selectedItem = this.subCategorySelection.selected.find(r => r.name === x.name);
			if (selectedItem) {
				parentNodeNames.push(selectedItem.categoryName);
			}
		});

		parentNodes.forEach(x => {
			if (parentNodeNames.includes(x.name)) {
				this.treeControl.expand(x);
			} else {
				this.treeControl.collapse(x);
			}
		});
	}


	goSave(): void {
		// copy form values to object
		let appUserView = Object.assign({}, this.data.appUserView);
		appUserView.subCategories = this.subCategorySelection.selected;
		// update data
		this.appUserService.updateAppUserView(appUserView)
			.pipe(
				takeUntil(this.unsubscribe)
			)
			.subscribe({
				next: (appUserView) => {
					this.saving.next(false);
					this.dialogRef.close(appUserView);
				},
				error: (err) => {
					this.saving.next(false);
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	ngOnDestroy() {
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}
}
