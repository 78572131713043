import { Component, OnInit } from '@angular/core';
import {HeaderService} from "@core/services/header.service";

@Component({
  selector: 'app-tariffs',
  templateUrl: './tariffs.component.html',
})
export class TariffsComponent implements OnInit {

  constructor(
    private headerService: HeaderService,
  ) { }

  ngOnInit(): void {
    this.headerService.changeCurrentTitle('Тарифы на услуги по договору условного депонирования (эскроу)');
  }

}
