<div fxLayout="column" fxLayoutGap="20px" *ngIf="dataSource">
  <div>
    <mat-table matSort matSortActive="title" matSortDirection="desc" [dataSource]="dataSource" class="pd-width-full">

      <!-- Title Column -->
      <ng-container matColumnDef="title">
        <mat-header-cell mat-sort-header matTooltip="{{ 'title' | work: 'propertyHint' }}" *matHeaderCellDef>
          {{ 'title' | work: 'propertyTitle' }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a [routerLink]="otherLink.concat([element.id])">
            {{ element.title }}
          </a>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <!-- Company Column -->
      <ng-container matColumnDef="company">
        <mat-header-cell mat-sort-header matTooltip="{{ 'company' | work: 'propertyHint' }}" *matHeaderCellDef>
          {{ 'company' | work: 'propertyTitle' }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.company.title }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns | async"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns | async;"></mat-row>
    </mat-table>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10" hidePageSize showFirstLastButtons></mat-paginator>
  </div>
</div>
