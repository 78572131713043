import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class AbstractModelService {
	constructor() {
	}

	get httpOptions() {
		const authToken = localStorage.getItem('auth_token');
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		if (authToken) {
			headers = headers.append('Authorization', `Bearer ${authToken}`);
		}
		return { headers };
	}

	get uploadHttpHeaders() {
		const authToken = localStorage.getItem('auth_token');
		let headers = new HttpHeaders();
		// headers = headers.append('Content-Type', 'multipart/form-data');
		headers = headers.append('Accept', 'application/json');
		if (authToken) {
			headers = headers.append('Authorization', `Bearer ${authToken}`);
		}
		return { headers };
	}

	get downloadImageHttpHeaders() {
		const authToken = localStorage.getItem('auth_token');
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'image/*');
		headers = headers.append('Accept', 'image/*');
		if (authToken) {
			headers = headers.append('Authorization', `Bearer ${authToken}`);
		}
		return headers;
	}

	downloadHttpHeaders(contentType: string) {
		const authToken = localStorage.getItem('auth_token');
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Accept', contentType);
		if (authToken) {
			headers = headers.append('Authorization', `Bearer ${authToken}`);
		}
		return headers;
	}

	get anonymusSupportHttpHeaders() {
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		return { headers };
	}

	get textHeaders(): HttpHeaders {
		return new HttpHeaders().append('Content-Type', 'text/plain')
	}
}
