import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, iif, mergeMap, of } from 'rxjs';
import { distinctUntilChanged, filter, startWith, takeUntil } from 'rxjs/operators';
import { AbstractComponentDirective } from '@shared/abstract-component.directive';
import { isEmptyValue } from '@utils/helpers/filter-helpers';
import { errorTitle } from '@utils/helpers/error-helpers';
import { AppUserDialogData, AppUserView } from '@models/app-user-view';
import { HardSkillView } from '@models/hard-skill-view';
import { AppUserService } from '@core/services/app-user.service';
import { HardSkillService } from '@core/services/hard.skill.service';
import { SkillStateValue } from '@models/abstract-skill-view';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { onlySpaceValidator } from '@utils/functions';

@Component({
	selector: 'pdw-user-hard-skills-dialog',
	templateUrl: './app-user-hard-skills-dialog.component.html',
	styleUrls: ['./app-user-hard-skills-dialog.component.scss']
})
export class AppUserHardSkillsDialogComponent extends AbstractComponentDirective implements OnInit {
	hardSkillForm: FormGroup;
	hardSkills$ = new BehaviorSubject<HardSkillView[]>([]);
	hardSkillViews$ = new BehaviorSubject<HardSkillView[]>([]);
	firstFiftySkills: HardSkillView[];
	@ViewChild('invisibleInput', { read: ElementRef }) invisibleInput: ElementRef;
	@ViewChild('skillInput', { read: ElementRef }) skillInput: ElementRef;
	saving$ = new BehaviorSubject<boolean>(false);

	constructor(
		public dialogRef: MatDialogRef<AppUserDialogData, boolean | AppUserView>,
		@Inject(MAT_DIALOG_DATA) public data: AppUserDialogData,
		private formBuilder: FormBuilder,
		private hardSkillService: HardSkillService,
		private appUserService: AppUserService,
		private notificationService: NotificationService,
		private destroy$: DestroyService
	) {
		super();
	}

	ngOnInit(): void {
		this.hardSkills$.next([...this.data.appUserView?.hardSkills ?? []]);
		this.hardSkillForm = this.formBuilder.group({
			hardSkill: new FormControl('', {
				validators: [
					this.uniqueRequired.bind(this),
					this.maxHardSkills.bind(this),
					Validators.maxLength(50),
					onlySpaceValidator(),
				],
			})
		});

		this.hardSkillService.firstFiftyUser()
			.pipe(
				takeUntil(this.destroy$)
			).subscribe({
			next: hardSkills => {
				this.firstFiftySkills = hardSkills.filter(skill => typeof skill.title === 'string' && Boolean(skill.title.trim()));
				this.hardSkillViews$.next(this.firstFiftySkills);
			}
		});

		this.hardSkill.valueChanges
			.pipe(
				startWith(''),
				distinctUntilChanged(),
				mergeMap(query => iif(
					() => typeof query === 'string' && query.trim().length > 1,
					this.hardSkillService.filter(query)
						.pipe(
							takeUntil(this.destroy$),
						),
					of(this.firstFiftySkills)
						.pipe(
							takeUntil(this.destroy$),
							filter(x => x !== undefined),
						)
				)),
			).subscribe({
			next: hardSkills => {
				this.hardSkills$.value.forEach(selectedSkill => {
					const index = hardSkills.findIndex(x => x.id === selectedSkill.id);

					if (index >= 0) {
						hardSkills.splice(index, 1);
					}
				});
				this.hardSkillViews$.next(hardSkills);
			}
		});

	}

	uniqueRequired(control: AbstractControl) {
		return control.value &&
		typeof control.value === 'object' &&
		this.hardSkills$.value.some(x => x.id == control.value.id)
			? { uniqueRequired: true }
			: null;
	}

	maxHardSkills() {
		return this.hardSkills$.value.length > 30 ? { maxHardSkills: true } : null;
	}

	isEmptyValue(control: FormControl): boolean {
		return isEmptyValue(control);
	}

	get hardSkill(): FormControl {
		return this.hardSkillForm.get('hardSkill') as FormControl;
	}

	getSkillViewTitle(option) {
		return option ? option.titleLong : '';
	}

	getTooltip(hardSkillView: HardSkillView): string {
		if (hardSkillView.state === SkillStateValue.created) {
			return 'Навык на модерации';
		}

		return hardSkillView.name;
	}

	setSelectedSkillView(event) {
		let tmp = this.hardSkills$.value;
		tmp.push(event.option.value);
		this.hardSkills$.next(tmp);
		this.hardSkill.setValue('');
	}

	goAdd(event?: Event) {
		if (event) {
			event.stopPropagation();
		}

		if (this.hardSkills$.value.some(x => x.name === this.hardSkill.value)) {
			this.hardSkill.setErrors({ uniqueRequired: true });
			this.hardSkillViews$.next([]);
			return null;
		}

		this.saving$.next(true);
		const hardSkillView = new HardSkillView();
		hardSkillView.section = '.';
		hardSkillView.code = null;
		hardSkillView.name = this.hardSkill.value;
		hardSkillView.state = SkillStateValue.created;
		this.hardSkillService.addSkillView(hardSkillView)
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: (hardSkillView) => {
					this.saving$.next(false);
					let tmp = this.hardSkills$.value;
					tmp.push(hardSkillView);
					this.hardSkills$.next(tmp);
					this.hardSkill.setValue('');
					this.invisibleInput.nativeElement.click();
				},
				error: (err) => {
					this.saving$.next(false);
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	goRemove(hardSkill: HardSkillView): void {
		let tmp = this.hardSkills$.value;
		tmp.splice(tmp.findIndex(x => x.id == hardSkill.id), 1);
		this.hardSkills$.next(tmp);
		this.hardSkill.updateValueAndValidity();
		this.hardSkillViews$.next([...this.hardSkillViews$.getValue(), hardSkill].sort((skillPrev, skillNext) => skillPrev.id - skillNext.id))
	}

	goSave(): void {
		if (this.hardSkillForm.invalid) {
			return;
		}
		this.saving$.next(true);
		// copy form values to object
		let appUserView = Object.assign({}, this.data.appUserView);
		appUserView.hardSkills = this.hardSkills$.value;
		// update data
		this.appUserService.updateAppUserView(appUserView)
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: (appUserView) => {
					this.saving$.next(false);
					this.dialogRef.close(appUserView);
				},
				error: (err) => {
					this.saving$.next(false);
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	disabledItem(item: any): boolean {
		return this.hardSkills$.value.findIndex(x => x.id === item.id) > -1;
	}

	onEnter(): void {
		if (!this.skillInput.nativeElement.value.trim()) {
			return;
		}
		this.skillInput.nativeElement.blur();
		this.goAdd();
	}
}
