import {AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2} from '@angular/core';

@Directive({
  selector: 'tui-scrollbar[appHorizontalScrollable]',
})
export class HorizontalScrollableDirective implements AfterViewInit, OnDestroy{

  listenerFn: () => void;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.listenerFn = this.renderer.listen(this.el.nativeElement, 'wheel', event => {
      this.renderer.setProperty(this.el.nativeElement, 'scrollLeft', this.scrollLeft + event.deltaY);
    });
  }

  ngOnDestroy() {
    this.listenerFn();
  }

  get scrollLeft(){
    return this.el.nativeElement.scrollLeft;
  }
}
