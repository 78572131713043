import { AppUserService } from '@core/services/app-user.service';
import { AppUserView } from '@models/app-user-view';
import { pushFakeHistoryState } from '@utils/functions';
import { BehaviorSubject } from 'rxjs';
export class AppUserRoleHintHelper {
	private activeAppUserView = new BehaviorSubject<AppUserView>(null);

	constructor(private appUserService: AppUserService) {
		this.activeAppUserView.next(appUserService.getCurrentActiveUserView())
	}

	initAfterAuth() {
		pushFakeHistoryState()
	}
}
