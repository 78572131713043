<div class='pd-chat-requests-cell-body' *ngIf='!isCancelled'>
	<div
		class='pd-chat-requests-cell-img-wrapper'
		[style.background-color]='avatarColor(appUserView)'
	>
		<ng-container *ngIf='appUserView.hasAvatar === 1; else noAvatar'>
			<img
				alt='Avatar image'
				[src]="appUserView | avatar | async"
				class='pd-chat-requests-cell-img'
			>
		</ng-container>
		<ng-template #noAvatar>
			<pdw-user-initial-letters
				[user]='appUserView'
				[canUpdate]='false'
				[size]="IMAGE_SIZE.MEDIUM"
			>
			</pdw-user-initial-letters>
		</ng-template>
		<div
			class='pd-profile-online-status pd-profile-online-status-into'
			[ngClass]="{'online' : isUserOnline(appUserView)}"
			[matTooltip]="isUserOnline(appUserView) ? 'В сети' : appUserView.lastConnection | appUserLastConnection"
		>
		</div>
	</div>
	<div class='pd-chat-requests-cell-inner'>
		<p class='pd-chat-requests-cell-user-title'>
			{{ appUserView.title }}
		</p>
		<p
			*ngIf='isAcceptedUser()'
			class='pd-chat-requests-cell-accepted'
		>
			Назначенный исполнитель
		</p>
		<p
			*ngIf='workRequestView.lastWorkRequestChatMessage'
			class='pd-chat-requests-cell-message'
		>{{workRequestView.lastWorkRequestChatMessage.message}}</p>
	</div>
</div>


<div class='pd-chat-requests-cell-notice'>
	<div class='pd-chat-requests-cell-notice-row'>
		<ng-container *ngIf='workRequestView.lastWorkRequestChatMessage as lastMessage'>
			<ng-container *ngIf='lastMessage.createAppUser.id === user.id'>
				<mat-icon
					*ngIf='!lastMessage.received'
					svgIcon='additional2-message-check1'
					class='pd-chat-requests-sent'
				>
				</mat-icon>
				<mat-icon
					*ngIf='lastMessage.received'
					svgIcon='additional2-message-check2'
					class='pd-chat-requests-received'
				>
				</mat-icon>
			</ng-container>
		</ng-container>
		<span
			class='pd-chat-requests-cell-notice-time'
			*ngIf='workRequestView.lastWorkRequestChatMessage'
		>
			{{workRequestView.lastWorkRequestChatMessage.createTime | date:'HH:mm'}}
		</span>
	</div>
	<div class='pd-chat-requests-cell-notice-row'>
		<span
			*ngIf='messageCount() > 0'
			class='pd-chat-requests-cell-notice-count'
		>
			{{messageCount()}}
		</span>
	</div>
</div>


