import { YesNo } from '@models/enums';
import { HardSkillView } from '@models/hard-skill-view';
import { SoftSkillView } from '@models/soft-skill-view';
import { EducationView } from '@models/education-view';
import { ExperienceView } from '@models/experience-view';
import { EducationAdditionalView } from '@models/education-additional-view';
import { CategoryMarkView } from '@models/category-mark-view';
import { SubCategoryView } from '@models/sub-category-view';
import { AbstractModifiedModelView } from '@models/abstract-modified-model-view';
import { CityModel } from '@models/city-model';
import { WorkTypeView } from '@models/work-type-view';

export interface AppUserDialogData {
	title: string;
	message?: string;
	method?: string;
	appUserView: AppUserView;
}

export interface WorkFormatView {
	id: number,
	name: string
}


export const SpecialistMenuTitles = {
	icon: 'groups',
	primaryTitleItems: 'Специалисты',
	descriptionItems: 'Все специалисты',
	primaryTitleCreate: 'Создание специалиста',
	descriptionCreate: 'Создание нового специалиста',
	primaryTitleItem: 'Специалист',
	descriptionItem: 'Профиль специалиста',
	wallet: 'PRO-аккаунт'
};

export enum AppUserGender {
	None = -1,
	Male,
	Female
}

export enum AppUserType {
	none = -1,
	administrator,
	specialist,
	client
}

export enum AppUserWorkState {
	none = 0,
	inSearch = 1,
	requested = 2,
	invited = 4,
	inWork = 8
}

export enum AppUserTag {
	none = -1,
	inSearch,
	requested,
	invited,
	inWork,
	favorites,
	all
}

export class AppUserView extends AbstractModifiedModelView {
	public id: string;
	public title: string;
	public titleLong: string;
	public titleShort: string;
	public firstname: string;
	public middlename: string;
	public lastname: string;
	public birthday: Date | null;
	public gender: AppUserGender;
	public incognito: YesNo;
	public preferredReward?: number;
	public isReadyToRelocate?: YesNo
	public enabledRoleHintDialog: boolean;
	public qualityRatingPercent: number;
	public speedRatingPercent: number;
	public experienceRatingPercent: number;
	public workCountAll: number;
	public workCountSuccessed: number;
	public ratingPercent: number;
	public userName: string;
	public positiveQuality?: string;
	public email: string;
	public specialistProfit: number;
	public emailConfirmed: boolean;
	public phoneNumber: string;
	public isLockedOut: boolean;
	public type: AppUserType;
	public subCategories: SubCategoryView[];
	public categoryMarks: CategoryMarkView[];
	public hardSkills: HardSkillView[];
	public softSkills: SoftSkillView[];
	public experiences: ExperienceView[];
	public educations: EducationView[];
	public educationAdditionals?: EducationAdditionalView[];
	public hasAvatar: YesNo;
	public favorite: YesNo;
	public cropperPosition: any;
	public avatar: string;
	public lastConnection: Date | null;
	public oneUsePassword: string;
	public openForWork: YesNo;
	public workState: AppUserWorkState;
	public errors: any[];
	public inn: string | null;
	public city: CityModel;
	public profession?: string;
	public workFormats: WorkFormatView[];
	public workTypes: WorkTypeView[];
}
