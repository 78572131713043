import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EMPTY, Observable, switchMap, tap } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppUserDialogData, AppUserView } from '@models/app-user-view';
import { AbstractComponentDirective } from '@shared/abstract-component.directive';
import { ExperienceView } from '@models/experience-view';
import * as moment from 'moment';
import { isMoment, Moment } from 'moment';
import { YesNo } from '@models/enums';
import { catchError, debounceTime, distinctUntilChanged, filter, startWith, takeUntil } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { AppUserService } from '@core/services/app-user.service';
import { isEmptyValue } from '@utils/helpers/filter-helpers';
import { CompanyView } from '@models/company-view';
import { CompanyService } from '@core/services/company.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import {
	CALENDAR_HEADER_WITHOUT_DAYS,
	MaterialCalendarHeaderComponent,
	PD_CALENDAR_HEADER_TYPE
} from '@shared/material-calendar-header/material-calendar-header.component';
import { FORMAT_DATES_MONTHS_DAYS } from '@utils/helpers/date-helpers';
import { HttpErrorResponse } from '@angular/common/http';
import { toLowerCaseFirstLetter } from '@utils/helpers/string-helpers';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { dateNotEarlierValidator, onlySpaceValidator } from '@utils/functions';
import { TrueLoadingService } from '@core/services/true-loading.service';
import { UtilsService } from '@core/services/utils.service';

@Component({
	selector: 'pdw-app-user-experiences-dialog',
	templateUrl: './app-user-experiences-dialog.component.html',
	styleUrls: ['./app-user-experiences-dialog.component.scss'],
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		{ provide: MAT_DATE_FORMATS, useValue: FORMAT_DATES_MONTHS_DAYS },
		{ provide: PD_CALENDAR_HEADER_TYPE, useValue: CALENDAR_HEADER_WITHOUT_DAYS },
		DestroyService,
		TrueLoadingService
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppUserExperiencesDialogComponent extends AbstractComponentDirective implements OnInit {
	experiencesForm: FormGroup;
	calendarHeader = MaterialCalendarHeaderComponent;
	companyViews: Observable<CompanyView[]>[];
	@ViewChild('MyFormField') myFormField: any;

	constructor(
		public dialogRef: MatDialogRef<AppUserDialogData, boolean | AppUserView>,
		@Inject(MAT_DIALOG_DATA) public data: AppUserDialogData,
		private formBuilder: FormBuilder,
		private appUserService: AppUserService,
		private companyService: CompanyService,
		private notificationService: NotificationService,
		private destroy$: DestroyService,
		public loading$: TrueLoadingService
	) {
		super();
		loading$.next(false);
	}

	ngOnInit(): void {
		this.companyViews = [];
		this.experiencesForm = this.formBuilder.group({
			experiences: this.formBuilder.array(
				this.data.appUserView?.experiences?.map((v, i, a) => this.createExperience(i, v)) ?? []
			)
		});
		if (!this.experiences.length) {
			this.goAddExperience(0);
		}
	}

	isEmptyValue(control: FormControl): boolean {
		return isEmptyValue(control);
	}

	compareDates(control: FormGroup) {
		if (control &&
			control.get('startDate').valid &&
			control.get('startDate').value &&
			control.get('endDate').valid &&
			control.get('endDate').value) {
			const startDate = moment.isMoment(control.get('startDate').value)
				? control.get('startDate').value.toDate()
				: control.get('startDate').value;
			const endDate = moment.isMoment(control.get('endDate').value)
				? control.get('endDate').value.toDate()
				: control.get('endDate').value;
			if (new Date(startDate) > new Date(endDate)) {
				control.get('endDate').setErrors({ invalidEndDate: true });
				if (!control.get('endDate').touched) {
					control.get('endDate').markAsTouched();
				}
			}
		}
		return null;
	}

	compareCurrentDateToStartDate(control: FormGroup) {
		const currentDate = new Date();

		if (control && control.get('startDate').value) {
			const startDate = moment.isMoment(control.get('startDate').value)
				? control.get('startDate').value.toDate()
				: control.get('startDate').value;

			if (startDate > currentDate) {
				control.get('startDate').setErrors({ invalidStartDate: true });
			}
		}

		if (control && control.get('endDate').value) {
			const endDate = moment.isMoment(control.get('endDate').value)
				? control.get('endDate').value.toDate()
				: control.get('endDate').value;

			if (endDate > currentDate) {
				control.get('endDate').setErrors({ invalidEndDateRelativeToCurrentDate: true });
			}
		}
		return null;
	}

	get experiences(): FormArray {
		return this.experiencesForm.get('experiences') as FormArray;
	}

	experiencesStartDate(index: number): FormControl {
		return this.experiences.controls[index].get('startDate') as FormControl;
	}

	experiencesEndDate(index: number): FormControl {
		return this.experiences.controls[index].get('endDate') as FormControl;
	}

	experiencesTillNow(index: number) {
		return this.experiences.controls[index].get('tillNow');
	}

	experiencesCompany(index: number): FormControl {
		return this.experiences.controls[index].get('company') as FormControl;
	}

	experiencesPosition(index: number) {
		return this.experiences.controls[index].get('position');
	}

	experiencesDescription(index: number) {
		return this.experiences.controls[index].get('description');
	}

	createExperience(index: number, experienceView: ExperienceView): FormGroup {
		const formGroup = this.formBuilder.group({
			id: new FormControl(experienceView.id),
			startDate: new FormControl(experienceView.startDate, {
				validators: [Validators.required, dateNotEarlierValidator(-2209003353000)],
			}),
			endDate: new FormControl({
				value: experienceView.endDate,
				disabled: experienceView.tillNow == YesNo.yes
			}, {
				validators: [Validators.required, dateNotEarlierValidator(-2209003353000)],
			}),
			tillNow: new FormControl(experienceView.tillNow == YesNo.yes),
			company: new FormControl(experienceView.company ? experienceView.company : experienceView.companyName, {
				validators: [Validators.required, Validators.maxLength(500), onlySpaceValidator()],
			}),
			position: new FormControl(experienceView.position, {
				validators: [Validators.maxLength(500), onlySpaceValidator()],
			}),
			description: new FormControl(experienceView.description, {
				validators: [Validators.maxLength(1000)],
			}),
		}, {
			validators: [this.compareDates, this.compareCurrentDateToStartDate]
		});

		formGroup.get('tillNow').valueChanges
			.pipe(
				takeUntil(this.destroy$),
				distinctUntilChanged()
			)
			.subscribe((x: boolean) => {
				console.warn('tillNow valueChanges x: ', x);
				if (x) {
					this.experiencesEndDate(index).disable({ onlySelf: true, emitEvent: false });
				} else {
					this.experiencesEndDate(index).enable({ onlySelf: true, emitEvent: false });
				}
			});
		formGroup.get('startDate').valueChanges
			.pipe(
				takeUntil(this.destroy$),
				// distinctUntilChanged()
			).subscribe({
			next: () => {
				formGroup.get('endDate').updateValueAndValidity();
			}
		});
		this.companyViews.splice(index, 0, formGroup.get('company').valueChanges
			.pipe(
				startWith(''),
				debounceTime(300),
				filter(value => typeof value === 'string' && Boolean(value.trim())),
				catchError(err => {
					this.notificationService.showDanger(err);
					return EMPTY;
				}),
				switchMap(x => this.companyService.find(encodeURIComponent(x))),
				takeUntil(this.destroy$)
			)
		);

		return formGroup;
	}

	goAddExperience(index: number): void {
		const experienceView = new ExperienceView();
		experienceView.id = 0;
		this.experiences.insert(index, this.createExperience(index, experienceView));
		this.experiencesForm.updateValueAndValidity();
	}

	goRemoveExperience(index: number): void {
		if (index <= this.experiences.length - 1) {
			this.companyViews.splice(index, 1);
			this.experiences.removeAt(index);
		}
	}

	getCompanyViewTitle(option) {
		if (option) {
			if (typeof option === 'string')
				return option;
			return option.title;
		}
		return null;
	}

	setSelectedCompanyView(index: number, event) {
		this.experiencesCompany(index).setValue(event.option.value);
	}

	setMonthAndYear(control: FormControl, normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
		if (!control.value) {
			control.setValue(moment());
		}
		const ctrlValue = isMoment(control.value) ? control.value : moment(control.value);

		ctrlValue.month(normalizedMonthAndYear.month());
		ctrlValue.year(normalizedMonthAndYear.year());
		control.setValue(ctrlValue);
		// datepicker.

		datepicker.close();
	}

	onSubmit({ value, valid }): void {
		if (valid) {
			this.loading$.next(true);
			// copy form values to object
			let appUserView = Object.assign({}, this.data.appUserView);
			appUserView.experiences = [...this.experiences.value];
			appUserView.experiences.forEach(x => {
				// company name or company object?
				if (typeof x.company === 'string') {
					x.companyName = (x.company as string).trim();
					x.company = null;
				} else {
					x.companyName = null;
				}
				// remove timezone from startDate
				x.startDate = isMoment(x.startDate)
					? x.startDate.utcOffset(0, true).toDate()
					: moment(x.startDate).utcOffset(0, true).toDate();
				// remove timezone from endDate
				x.endDate = isMoment(x.endDate)
					? x.endDate.utcOffset(0, true).toDate()
					: moment(x.endDate).utcOffset(0, true).toDate();
			});
			// update data
			this.appUserService.updateAppUserView(appUserView)
				.pipe(
					takeUntil(this.destroy$),
				)
				.subscribe({
					next: (appUserView) => {
						this.loading$.next(false);
						this.dialogRef.close(appUserView);
					},
					error: (err) => {
						this.loading$.next(false);
						if (err instanceof HttpErrorResponse) {
							if (err.status === 400) {
								for (let i = 0; i < this.experiences.length; i++) {
									const pattern = 'Experiences[' + i + '].';
									Object.keys(err.error.errors)
										.filter(x => x.startsWith(pattern))
										.forEach(v => {
											const key = toLowerCaseFirstLetter(v.substring(pattern.length));
											const msg = Array.isArray(err.error.errors[v]) ? err.error.errors[v][0] : err.error.errors[v];
											this.experiences.controls[i].get(key).setErrors({
												serverError: msg,
											});
										});
								}
							} else {
								this.notificationService.showDanger(errorTitle(err));
							}
						}
					}
				});
		}
	}

	clearAutocomplete(event: Event, index: number) {
		event.stopPropagation();

		this.experiencesCompany(index).markAsTouched();
		this.experiencesCompany(index).setValue(null);
	}
}
