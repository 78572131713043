import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, iif, mergeMap, of } from 'rxjs';
import { distinctUntilChanged, filter, startWith, takeUntil } from 'rxjs/operators';
import { AbstractComponentDirective } from '@shared/abstract-component.directive';
import { isEmptyValue } from '@utils/helpers/filter-helpers';
import { errorTitle } from '@utils/helpers/error-helpers';
import { AppUserDialogData, AppUserView } from '@models/app-user-view';
import { SoftSkillView } from '@models/soft-skill-view';
import { AppUserService } from '@core/services/app-user.service';
import { SoftSkillService } from '@core/services/soft-skill.service';
import { SkillStateValue } from '@models/abstract-skill-view';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { onlySpaceValidator } from '@utils/functions';

@Component({
	selector: 'pdw-user-soft-skills-dialog',
	templateUrl: './user-soft-skills-dialog.component.html',
	styleUrls: ['./user-soft-skills-dialog.component.scss'],
	providers: [DestroyService]
})
export class UserSoftSkillsDialogComponent extends AbstractComponentDirective implements OnInit {
	softSkillForm: FormGroup;
	softSkills$ = new BehaviorSubject<SoftSkillView[]>([]);
	softSkillViews$ = new BehaviorSubject<SoftSkillView[]>([]);
	firstFiftySkills: SoftSkillView[];
	@ViewChild('invisibleInput', { read: ElementRef }) invisibleInput: ElementRef;
	@ViewChild('skillInput', { read: ElementRef }) skillInput!: ElementRef;
	saving$ = new BehaviorSubject<boolean>(false);

	constructor(
		public dialogRef: MatDialogRef<AppUserDialogData, boolean | AppUserView>,
		@Inject(MAT_DIALOG_DATA) public data: AppUserDialogData,
		private formBuilder: FormBuilder,
		private softSkillService: SoftSkillService,
		private appUserService: AppUserService,
		private notificationService: NotificationService,
		private destroy$: DestroyService,
	) {
		super();
	}

	ngOnInit(): void {
		this.softSkills$.next([...this.data.appUserView?.softSkills ?? []]);
		this.softSkillForm = this.formBuilder.group({
			softSkill: new FormControl('', {
				validators: [
					this.uniqueRequired.bind(this),
					this.maxSoftSkills.bind(this),
					onlySpaceValidator(),
					Validators.maxLength(50),
				]
			})
		});

		this.softSkillService.firstFiftyUser()
			.pipe(
				takeUntil(this.destroy$),
			).subscribe({
			next: softSkills => {
				this.firstFiftySkills = softSkills.filter(skill => typeof skill.title === 'string' && Boolean(skill.title.trim()));
				this.softSkillViews$.next(this.firstFiftySkills);
			}
		});

		this.softSkill.valueChanges
			.pipe(
				startWith(''),
				distinctUntilChanged(),
				mergeMap(query => iif(
					() => typeof query === 'string' && query.trim().length > 1,
					this.softSkillService.filter(query)
						.pipe(
							takeUntil(this.destroy$),
						),
					of(this.firstFiftySkills)
						.pipe(
							takeUntil(this.destroy$),
							filter(x => x !== undefined),
						)
				)),
			).subscribe({
			next: softSkills => {
				this.softSkills$.value.forEach(selectedSkill => {
					const index = softSkills.findIndex(x => x.id === selectedSkill.id);

					if (index >= 0) {
						softSkills.splice(index, 1);
					}
				});
				this.softSkillViews$.next(softSkills);
			}
		});
	}

	uniqueRequired(control: AbstractControl) {
		return control.value &&
		typeof control.value === 'object' &&
		this.softSkills$.value.some(x => x.id == control.value.id)
			? { uniqueRequired: true }
			: null;
	}

	maxSoftSkills() {
		return this.softSkills$.value.length > 30 ? { maxSoftSkills: true } : null;
	}

	isEmptyValue(control: FormControl): boolean {
		return isEmptyValue(control);
	}

	get softSkill(): FormControl {
		return this.softSkillForm.get('softSkill') as FormControl;
	}

	getSkillViewTitle(option) {
		return option ? option.titleLong : null;
	}

	getTooltip(softSkillView: SoftSkillView): string {
		if (softSkillView.state === SkillStateValue.created) {
			return 'Качество на модерации';
		}

		return '';
	}

	setSelectedSkillView(event) {
		let tmp = this.softSkills$.value;
		tmp.push(event.option.value);
		this.softSkills$.next(tmp);
		this.softSkill.setValue(null);
	}

	goAdd(event?: Event): void {
		if (event) {
			event.stopPropagation();
		}

		if (this.softSkills$.value.some(x => x.name === this.softSkill.value)) {
			this.softSkill.setErrors({ uniqueRequired: true });
			this.softSkillViews$.next([]);
			return null;
		}

		this.saving$.next(true);
		const softSkillView = new SoftSkillView();
		softSkillView.section = '.';
		softSkillView.code = null;
		softSkillView.name = this.softSkill.value;
		softSkillView.state = SkillStateValue.created;
		this.softSkillService.addSkillView(softSkillView)
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: (softSkillView) => {
					this.saving$.next(false);
					let tmp = this.softSkills$.value;
					tmp.push(softSkillView);
					this.softSkills$.next(tmp);
					this.softSkill.setValue(null);
					this.invisibleInput.nativeElement.click();
				},
				error: (err) => {
					this.saving$.next(false);
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	onEnter(): void {
		if (!this.skillInput.nativeElement.value.trim()) {
			return;
		}
		this.skillInput.nativeElement.blur();
		this.goAdd();
	}

	goRemove(softSkill: SoftSkillView): void {
		let tmp = this.softSkills$.value;
		tmp.splice(tmp.findIndex(x => x.id == softSkill.id), 1);
		this.softSkills$.next(tmp);
		this.softSkill.updateValueAndValidity();
		this.softSkillViews$.next([...this.softSkillViews$.getValue(), softSkill].sort((skillPrev, skillNext) => skillPrev.id - skillNext.id));
		this.firstFiftySkills.push(softSkill);
		this.firstFiftySkills.sort((skillPrev, skillNext) => skillPrev.id - skillNext.id);
	}

	goSave(): void {
		if (this.softSkillForm.invalid) {
			return;
		}
		this.saving$.next(true);
		// copy form values to object
		let appUserView = Object.assign({}, this.data.appUserView);
		appUserView.softSkills = this.softSkills$.value;
		// update data
		this.appUserService.updateAppUserView(appUserView)
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe({
				next: (appUserView) => {
					this.saving$.next(false);
					this.dialogRef.close(appUserView);
				},
				error: (err) => {
					this.saving$.next(false);
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	disabledItem(item: any): boolean {
		return this.softSkills$.value.findIndex(x => x.id === item.id) > -1;
	}

}
