import { Injectable } from '@angular/core';
import { AbstractModelService } from '@core/services/abstract-model.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WorkHistoryView } from '@models/work-history-view';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class WorkHistoryService {
	private workHistoriesUrl = 'api/WorkHistories';

	constructor(private http: HttpClient, private modelService: AbstractModelService) {

	}

	/**
	 * Преобразование string в Date после десериализации
	 * @param workHistoryView юр. лицо
	 */
	public static parseDates(workHistoryView: WorkHistoryView): WorkHistoryView {
		if (workHistoryView?.value?.dateTimeValue) {
			workHistoryView.value.dateTimeValue = new Date(workHistoryView.value.dateTimeValue);
		}

		if (workHistoryView?.createTime) {
			workHistoryView.createTime = new Date(workHistoryView.createTime);
		}

		if (workHistoryView?.modifyTime) {
			workHistoryView.modifyTime = new Date(workHistoryView.modifyTime);
		}
		
		return workHistoryView;
	}

	work(id: number): Observable<WorkHistoryView[]> {
		const url = `${this.workHistoriesUrl}/Work/${id}`;
		return this.http.get<WorkHistoryView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => WorkHistoryService.parseDates(x)))
			);
	}

	accept(id: number): Observable<WorkHistoryView> {
		const url = `${this.workHistoriesUrl}/Accept/${id}`;
		return this.http.get<WorkHistoryView>(url, this.modelService.httpOptions)
			.pipe(
				map(x => WorkHistoryService.parseDates(x))
			);
	}

	reject(id: number): Observable<WorkHistoryView> {
		const url = `${this.workHistoriesUrl}/Reject/${id}`;
		return this.http.get<WorkHistoryView>(url, this.modelService.httpOptions)
			.pipe(
				map(x => WorkHistoryService.parseDates(x))
			);
	}
}
