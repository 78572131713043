import { Component, Inject, Input, OnInit } from '@angular/core';
import { ManagerWorkRequestDataSource } from '@core/dataSources/manager-work-request-data-source';
import { BehaviorSubject, switchMap, tap, withLatestFrom } from 'rxjs';
import { AnySort } from '@utils/any-sort';
import { WorkRequestService, WorkRequestsFilter } from '@core/services/work-request.service';
import { distinctUntilChanged, filter, finalize, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { WorkRequestView } from '@models/work-request-view';
import { AppUserView } from '@models/app-user-view';
import { errorTitle } from '@utils/helpers/error-helpers';
import { pushFakeHistoryState } from '@utils/functions';
import {
	ConfirmationDialogComponent,
	ConfirmationDialogType
} from '@shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SingleWorkRequestDataSource } from '@core/dataSources/single-work-request-data-source';
import { UtilsService } from '@core/services/utils.service';
import { AppUserService } from '@core/services/app-user.service';
import { WorkService } from '@core/services/work.service';
import { NoticeService } from '@core/services/notice.service';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { PaginationData } from '@shared/directives/scroll-pagination/scroll-pagination.types';
import { TrueLoadingService } from '@core/services/true-loading.service';

@Component({
	selector: 'pdw-work-requests-manager-cards',
	templateUrl: './work-requests-manager-cards.component.html',
	host: { 'class': 'pd-expanded-height' },
	providers: [DestroyService,
		{
			provide: 'update',
			useClass: TrueLoadingService
		}, {
			provide: 'loading',
			useClass: TrueLoadingService
		}
	],
	styleUrls: ['./work-requests-manager-cards.component.scss']
})
export class WorkRequestsManagerCardsComponent implements OnInit {
	/**
	 * Источник данных для получения записей удовлетворяющих критериям поиска
	 */
	@Input() dataSource: ManagerWorkRequestDataSource | SingleWorkRequestDataSource;

	/**
	 * Количество колонок
	 * @param value количество колонок
	 */
	@Input()
	set columnsCount(value: number) {
		this._columnsCount.next(value);
	};

	get columnsCount(): number {
		return this._columnsCount.getValue();
	}

	private _columnsCount = new BehaviorSubject<number>(null);
	/**
	 * Ширина одной карточки специалиста
	 * Размер должен быть согласован так, чтобы перенос карточек в браузере
	 * при изменении размера окна совпадал с пересчетом количеста колонок
	 */
	@Input() cardWidth: number = 392;

	@Input()
	set filterValues(value: WorkRequestsFilter) {
		this._filterValues.next(value);
	};

	get filterValues(): WorkRequestsFilter {
		return this._filterValues.getValue();
	}

	private _filterValues = new BehaviorSubject<WorkRequestsFilter>(null);

	@Input()
	set sortValues(value: AnySort) {
		this._sortValues.next(value);
	};

	get sortValues(): AnySort {
		return this._sortValues.getValue();
	}

	private _sortValues = new BehaviorSubject<AnySort>(null);
	pageIndex = 0;
	pageSize = 0;
	remain = new BehaviorSubject<number>(null);

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private workService: WorkService,
		private workRequestService: WorkRequestService,
		private notificationService: NotificationService,
		private utilsService: UtilsService,
		private noticeService: NoticeService,
		private destroy$: DestroyService,
		@Inject('update') public update$: TrueLoadingService,
		@Inject('loading') public loading$: TrueLoadingService
	) {
	}

	ngOnInit(): void {
		this.loading$.next(true);

		this._columnsCount
			.pipe(
				withLatestFrom(this.dataSource.data),
			)
			.subscribe(data => {
				this.pageSize = this.calcPageSize(data[0]);
				const p = this.calcPageIndex(data[1].length, this.pageSize);
				this.pageIndex = p.pageIndex;
				if (p.reload) {
					this.loadMore(this.pageIndex, this.pageSize);
				}
				this.loadCount();
			});

		this._filterValues
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe(data => {

				this.loading$.next(true);
				this.pageIndex = 0;
				this.load(this.pageIndex, this.pageSize);
				this.loadCount();
			});

		this._sortValues
			.pipe(
				distinctUntilChanged(),
			)
			.subscribe(data => {
				this.loading$.next(true);
				this.load(0, this.pageSize * (this.pageIndex + 1));
			});

		this.noticeService.workRequestCountsChanged
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: () => {
					this.load(this.pageIndex, this.pageSize);
					this.loadCount();
				}
			});
	}

	/**
	 * Определяет актуальный номер страницы исходя из количества загруженных данных и размера страницы
	 * @param dataLength количества загруженных данных
	 * @param pageSize размера страницы
	 */
	calcPageIndex(dataLength: number, pageSize: number): any {
		const pageIndex = dataLength / pageSize;
		const pageIndexTrunc = Math.floor(pageIndex);

		/**
		 * Если вычисленный номер страницы не дотягивает до двух,
		 * то значит загрузить только первую страницу
		 */
		if (pageIndex < 2.0) {
			return {
				pageIndex: 0,
				reload: dataLength < pageSize
			};
		}

		/**
		 * В остальных случаях округлять страницу в меньшую сторону
		 */
		return {
			pageIndex: pageIndexTrunc - 1,
			reload: dataLength < pageSize * pageIndexTrunc
		}
	}

	/**
	 * Определяет размер страницы для указанного количества колонок
	 * @param columnsCount количество колонок
	 */
	calcPageSize(columnsCount: number): number {
		switch (columnsCount) {
			case 1:
				return 3;
			case 2:
				return 6;
			case 3:
				return 9;
			case 4:
				return 12;
			case 6:
				return 18;
			case 7:
				return 21;
			default:
				return 15;
		}
	}

	loadByObject(params: PaginationData): void {
		this.pageSize = params.pageSize;
		this.load(params.pageIndex, params.pageSize)
	}

	goNextPageByData(data: PaginationData): void {
		this.pageSize = data.pageSize;
		if (!this.update$.value) {
			this.update$.next(true);
			this.pageIndex++;
			this.loadMore(data.pageIndex, data.pageSize);
			this.loadCount();
		}
	}

	loadCount(): void {
		this.dataSource.loadCount(this.filterValues)
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe(x => {
				const remain = x - (this.pageIndex + 1) * this.pageSize;
				this.remain.next(remain > this.pageSize ? this.pageSize : remain);
			});
	}

	/**
	 * Загружает указанную страницу данных указанного размера
	 * @param pageIndex номер страницы
	 * @param pageSize размер страницы
	 */
	load(pageIndex: number, pageSize: number): void {
		this.dataSource.load(
				this.filterValues,
				this.sortValues?.name + ',' + this.sortValues?.direction,
				pageIndex,
				pageSize)
			.pipe(
				finalize(() => {
					this.loading$.next(false);
					this.update$.next(false);
				}),
				takeUntil(this.destroy$),
			)
			.subscribe(() => {
				this.loading$.next(false);
				this.update$.next(false);
			});
	}

	/**
	 * Загружает указанную страницу данных указанного размера
	 * @param pageIndex номер страницы
	 * @param pageSize размер страницы
	 */
	loadMore(pageIndex: number, pageSize: number): void {
		this.dataSource.loadMore(
				this.filterValues,
				this.sortValues?.name + ',' + this.sortValues?.direction,
				pageIndex,
				pageSize)
			.pipe(
				finalize(() => {
					this.loading$.next(false);
					this.update$.next(false);
				}),
				takeUntil(this.destroy$),
			)
			.subscribe(() => {
				this.loading$.next(false);
				this.update$.next(false);
			});
	}

	reload(): void {
		this.dataSource.count
			.pipe(
				switchMap(count => this.dataSource.load(
							this.filterValues,
							this.sortValues?.name + ',' + this.sortValues?.direction,
							0,
							count
						)
						.pipe(
							finalize(() => {
								this.loading$.next(false);
								this.update$.next(false);
							}),
							takeUntil(this.destroy$),
						)
				)
			)
			.subscribe(() => {
				this.loading$.next(false);
				this.update$.next(false);
			});
	}

	/**
	 * Загружает следующую страницу данных
	 */
	goNextPage(): void {
		this.update$.next(true);
		this.pageIndex++;
		this.loadMore(this.pageIndex, this.pageSize);
		this.loadCount();
	}

	/**
	 * Обрабатывает нажатие средней кнопкой мыши на карточке отклика
	 * @param workRequestView
	 * @param event
	 */
	onMouseUp(workRequestView: WorkRequestView, event: MouseEvent): void {
		if (event.button === 1) {
			this.router.navigate([])
				.then(result => {
					window.open('/users/' + workRequestView.executor.id, '_blank');
				});
		}
	}

	isUserOnline(appUserView: AppUserView): boolean | null {
		if (appUserView.lastConnection) {
			const delta = (new Date().getTime() - appUserView.lastConnection.getTime()) / 1000 / 60;
			return delta < 5;
		}
		return null;
	}

	goDelete(event: MouseEvent, workExecutorRequestView: WorkRequestView): void {
		event.stopPropagation();
		pushFakeHistoryState();

		this.dialog.open(ConfirmationDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Внимание',
					message: 'Вы действительно хотите отменить приглашение для ' + workExecutorRequestView.executor.title + ' на',
					value: workExecutorRequestView.work.title,
					questionMark: true,
					type: ConfirmationDialogType.question,
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
				switchMap(() => this.workRequestService.delete(workExecutorRequestView.id)
					.pipe(
						takeUntil(this.destroy$)
					)
				)
			)
			.subscribe({
				next: () => {
					this.dataSource.remove(workExecutorRequestView);
					this.utilsService.changeWorkRequestCount.emit('Delete request');
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	stateColorClass(workRequestView: WorkRequestView): string {
		return WorkRequestService.stateColorClass(workRequestView.state, workRequestView.direction);
	}

	avatarColor(appUserView: AppUserView): string {
		return AppUserService.avatarColor(appUserView);
	}

}
