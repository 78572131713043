import { Pipe, PipeTransform } from '@angular/core';
import {AbstractEnumPipe} from "@public/pipes/abstract-enum.pipe";

@Pipe({
  name: 'appUserDialogGender'
})
export class AppUserDialogGenderPipe extends AbstractEnumPipe implements PipeTransform {

  values = [
    'Мужской',
    'Женский',
  ];
  transform(value: any, ...args: any[]): any {
    return this.getTransform(value, args);
  }

}
