export enum YesNoFlag {
	none = 0,
	no = 1,
	yes = 2
}

export enum YesNo {
	none = -1,
	no = 0,
	yes = 1
}

export enum CreateFreelanceJobMethods {
	create = 0,
	update = 1,
	accept = 2
}

export enum ProjectFilter {
	None = -1,
	Member,
	All = 99
}

export class Enums {
}

export function makeYesNo(value: any): YesNo {
	return value && (value === true || value === 1)
		? YesNo.yes
		: YesNo.no;
}

// Статус задачи для таблицы, string добавляется к классу и определяет цвет. Текст определяется в компоненте
export enum taskStatus {
	opened = 'opened',
	closed = 'closed',
	inProgress = 'in-progress',
	waiting = 'waiting',
	canceled = 'canceled',
	notPayed = 'not-payed'
}

// Приоритет задачи для таблицы, string добавляется к классу и определяет цвет. Текст определяется в компоненте
export enum taskPriority {
	high = 'high',
	medium = 'medium',
	low = 'low',
	no = 'no',
}
