<ng-container *ngIf='currentCompanyView | async as currentCompanyView'>
	<div class='pd-company-title-wrapper'>
		<mat-icon
			class='pd-icon light-gray'
			svgIcon='additional-bag-2-check'
		></mat-icon>
		<h2 class='pd-company-title'>
			{{ currentCompanyView.title }}
		</h2>
		<ng-container *ngIf='canUpdate'>
			<button
				class='pd-button-secondary small'
				mat-raised-button
				(click)='goUpdateLegalDetails()'
			>
				<mat-icon
					class='pd-icon'
					svgIcon='additional2-edit'
				></mat-icon>
				<p class='pd-company-button-text'>Редактировать</p>
			</button>
		</ng-container>
	</div>
	<ng-container *ngIf='currentCompanyView.url; else hasNoUrl'>
			<div class='pd-link-w-icon-black link'  (click)="openConfirmModal()">
				<mat-icon
					class='pd-icon light-gray'
					svgIcon='interface-link'
				></mat-icon>
				<p class='pd-link-w-icon-black-text'>
					{{currentCompanyView.url | companyWebsite}}
				</p>
			</div>
	</ng-container>
	<ng-template #hasNoUrl>
		<div class='pd-link-w-icon-black not-hoverable'>
			<mat-icon
				class='pd-icon light-gray'
				svgIcon='interface-link'
			></mat-icon>
			<p class='pd-link-w-icon-black-text'>
				Сайт не указан
			</p>
		</div>
	</ng-template>
	<ng-container *ngIf='currentCompanyView.emails[0]; else hasNoEmail'>
		<a
			class='pd-link-w-icon-black'
			href='mailto: {{currentCompanyView.emails[0].address}}'
			target='_blank'
		>
			<mat-icon
				class='pd-icon light-gray'
				svgIcon='additional-mail-check'
			></mat-icon>
			<p class='pd-link-w-icon-black-text'>
				{{currentCompanyView.emails[0].address}}
			</p>
		</a>
	</ng-container>
	<ng-template #hasNoEmail>
		<div class='pd-link-w-icon-black not-hoverable'>
			<mat-icon
				class='pd-icon light-gray'
				svgIcon='additional-mail-new-red'
			></mat-icon>
			<p class='pd-link-w-icon-black-text'>
				Почта не указана
			</p>
		</div>
	</ng-template>

	<div class='pd-link-w-icon-black not-hoverable'>
		<mat-icon
			class='pd-icon light-gray'
			svgIcon='additional-location'
		></mat-icon>
		<p class='pd-link-w-icon-black-text'>
			{{
				currentCompanyView.legal.city || currentCompanyView.legal.region ?
				currentCompanyView.legal.city ?? currentCompanyView.legal.region :
				'Город не указан'
			}}
		</p>
	</div>
</ng-container>
