<ng-container *ngIf="afterForgotPassword">
  <div class="pd-form-hint confirmation-after-forgot-password">
    Ссылка для подтверждения сброса пароля
    отправлена на вашу почту
  </div>
</ng-container>
<ng-container *ngIf="afterRegistration">
  <div class="pd-form-hint after-registration">
    Подтверждение отправлено на
    указанную вами почту
  </div>
</ng-container>
