import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import { AppUserView } from "@models/app-user-view";
import { WorkStateEnum, WorkView } from '@models/work-view';
import { WorkRequestService } from "@core/services/work-request.service";
import { filter, takeUntil } from "rxjs/operators";
import { YesNo } from "@models/enums";
import { WorkService } from "@core/services/work.service";
import { WorkRequestState } from "@models/work-request-view";
import { WorkAbstractComponent } from "@shared/work-abstract-component/work-abstract-component";
import { DestroyService, NotificationService } from "@profdepo-ui/core";
import { Router } from "@angular/router";
import { WorkViewShort } from "@models/work-view-short";

export type workCardType = "specialist" | "client";

@Component({
	selector: "pdw-work-card-slider",
	templateUrl: "./work-card-slider.component.html",
	styleUrls: ["./work-card-slider.component.scss"],
	host: { class: "pd-work-card" },
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService],
})
export class WorkCardSliderComponent extends WorkAbstractComponent implements OnInit {
	/**
	 * Тип карточки для специалиста/заказчика
	 */
	@Input() cardType: workCardType;

	@Input() isFullMode = true;
	/**
	 * Массив с url без work.Id!!!
	 */
	@Input() commands: any[];
	/**
	 * Для карточки специалиста
	 */
	@Input() appUserView: AppUserView | null;
	@Input() border: boolean = false;

	@HostBinding("class") get class() {
		return this.border ? "with-border" : "";
	}

	readonly WORK_STATE = WorkStateEnum;

	/**
	 * Изменение статуса работы(заказчик), отклик(специалист)
	 */
	@Output() changed = new EventEmitter<any>();

	constructor(
		private workRequestService: WorkRequestService,
		private notificationService: NotificationService,
		private workService: WorkService,
		private destroy$: DestroyService,
		private cdr: ChangeDetectorRef,
		private router: Router
	) {
		super();
	}

	ngOnInit(): void {
		this.workViewSubject.pipe(filter((x) => x !== null)).subscribe((workView: WorkView) => {
			this.currentWorkView.next(workView);
			this.groupWorkCategories(this.groupedCategoriesWithSubCategories, workView);
		});

		this.workViewShortSubject.pipe(filter((x) => x !== null)).subscribe((workView: WorkViewShort) => {
			this.currentWorkViewShort.next(workView);
		});
	}

	onChange(event): void {
		event.stopPropagation();
		this.changed.emit(event);
	}

	canSubscribe(): boolean {
		if (
			this.appUserView &&
			this.workView.requests?.some(
				(x) => x.executor.id == this.appUserView.id && x.state === WorkRequestState.accepted
			)
		) {
			return false;
		}

		return this.cardType !== "client";
	}

	goSubscribe(event: MouseEvent): void {
		event.stopPropagation();
		this.workService
			.subscribe(this.workView.id)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (v) => (this.workView.subscribed = YesNo.yes),
			});
	}

	goUnsubscribe(event: MouseEvent): void {
		event.stopPropagation();
		this.workService
			.unsubscribe(this.workView.id)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (v) => {
					this.workService.shouldReload.next(true);
					this.workView.subscribed = YesNo.no;
				},
			});
	}

	public goToLink(event: Event): void {
		event.preventDefault();
		event.stopPropagation();
		this.router.navigate(this.commands);
	}
}
