import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@core/services/auth.service';

@Directive({
	selector: '[hasAuth]'
})
export class HasAuthDirective {

	constructor(
		private auth: AuthService,
		private viewContainerRef: ViewContainerRef,
		private templateRef: TemplateRef<any>
	) {
	}

	@Input('hasAuth') set isVisible(flag: boolean) {
		if (this.auth.isLoggedIn() === flag) {
			this.viewContainerRef.createEmbeddedView(this.templateRef)
		} else {
			this.viewContainerRef.clear();
		}
	}

}
