import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AppUserView } from '@models/app-user-view';
import { BehaviorSubject, forkJoin, of} from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { catchError, debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { WorkService, WorksFilter } from '@core/services/work.service';
import { WorkRequestService } from '@core/services/work-request.service';
import { NotificationItem, NotificationItemType } from '@core/services/notifications-request.service';
import { errorTitle } from '@utils/helpers/error-helpers';
import { WorkStateValue, WorkView } from '@models/work-view';
import { AnySort } from '@utils/any-sort';
import { AvailableWorkDataSource } from '@core/dataSources/available-work-data-source';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { Router } from '@angular/router';

export interface WorksSelectDialogData {
	title: string;
	message: string;
	method: string;
	appUserView: AppUserView;
}

@Component({
	selector: 'pdw-works-select-dialog',
	templateUrl: './works-select-dialog.component.html',
	styleUrls: ['./works-select-dialog.component.scss'],
	providers: [DestroyService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorksSelectDialogComponent implements OnInit {
	dataSource = new AvailableWorkDataSource(this.workService);
	filter = new WorksFilter();
	sort = new AnySort('title', 'asc');
	pageIndex = 0;
	pageSize: number = 20;
	debounceTime = 500;
	remain = new BehaviorSubject<number>(null);
	selection = new SelectionModel<WorkView>(true, []);
	hasExecutorsToSelect = new BehaviorSubject<boolean>(false);
	isDataLoading = new BehaviorSubject<boolean>(false);
	saving = new BehaviorSubject<boolean>(false);
	displayedFields = { first: 'Вакансии', second: 'Фриланс' };
	form: FormGroup;
	workViewSubTitleToShow: WorkView = null;

	constructor(
		public dialogRef: MatDialogRef<WorksSelectDialogData, boolean | AppUserView>,
		@Inject(MAT_DIALOG_DATA) public data: WorksSelectDialogData,
		private workService: WorkService,
		private workRequestService: WorkRequestService,
		private notificationService: NotificationService,
		private destroy$: DestroyService,
		private fb: FormBuilder,
		private router: Router
	) {
		this.form = this.fb.group({
			searchControl: [''],
			switchControl: [true],
		});
	}

	ngOnInit(): void {
		this.filter.states = [WorkStateValue.seeking]

		this.load();
		this.loadCount();

		this.form.valueChanges
			.pipe(
				debounceTime(this.debounceTime),
				distinctUntilChanged(),
				takeUntil(this.destroy$)
			)
			.subscribe((values) => {
				this.isDataLoading.next(true);
				this.filter.any = values?.searchControl;
				this.filter.isFreelance = values.switchControl ? 0 : 1;
				this.load();
				this.loadCount();
			});
	}

	filterWorks(works: Array<WorkView>): Array<WorkView> {
		return works
	}

	get titleFilter() {
		return this.form.get('searchControl');
	}

	load(): void {
		this.isDataLoading.next(true);

		if (this.filter.isFreelance === -1) {
			this.filter.isFreelance = 0;
		}
		
		this.dataSource.load(
				this.filter,
				this.sort.name + ',' + this.sort.direction,
				this.pageIndex,
				this.pageSize,
				false
			)
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe(v => {
				this.isDataLoading.next(false);
			});
	}

	loadCount(): void {
		this.dataSource.loadCount(this.filter)
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe(x => {
				const remain = x - (this.pageIndex + 1) * this.pageSize;
				this.remain.next(remain > this.pageSize ? this.pageSize : remain);
			});
	}

	loadMore(pageIndex: number, pageSize: number): void {
		this.dataSource.loadMore(
				this.filter,
				this.sort.name + ',' + this.sort.direction,
				pageIndex,
				pageSize)
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe(x => {
			});
	}

	goNextPage(): void {
		this.pageIndex++;
		this.loadMore(this.pageIndex, this.pageSize);
		this.loadCount();
	}

	canInvite(workView: WorkView): boolean {
		return !workView.requests.some(x => x.executor.id === this.data.appUserView.id);
	}

	goSave(): void {
		this.saving.next(true);
		forkJoin(
			this.selection.selected.map(x => this.workRequestService
				.invite(x.id, this.data.appUserView.id)
				.pipe(
					map(() => {
						return { error: null, value: x };
					}),
					catchError(e => of({ error: e, value: x })),
				)
			)
		)
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe({
				next: (data) => {
					this.saving.next(false);
					const failed = data
						.filter(x => x.error);
					const failedNotificationItem = failed
						.map(x => NotificationItem.make(x.error.error, NotificationItemType.danger));
					if (failedNotificationItem.length) {
						this.notificationService.show(failedNotificationItem);
						let tmp = this.selection.selected.filter(x => failed.some(f => f.value.id === x.id));
						this.selection.clear();
						this.selection = new SelectionModel<WorkView>(true, tmp);
					} else {
						this.dialogRef.close(true);
					}
				},
				error: (err: any) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	/**
	 * Возвращает подсказку для checkbox'а, если он блокирован
	 * @param workView участник
	 */
	checkboxTooltip(workView: WorkView): string {
		let request = workView.requests.find(x => x.executor.id == this.data.appUserView.id);
		if (!this.canInvite(workView)) {
			if (request.direction == 0 && request.state == 2) {
				return 'Вы уже отклонили этого специалиста'
			}
			if (request.direction == 1 && request.state == 2) {
				return 'Специалист отказался от работы'
			}
			return 'Приглашение уже отправлено'
		}
		return null;
	}

	hasSelected(workView: WorkView): boolean {
		return this.selection.selected.some(x => x.id === workView.id);
	}

	/**
	 * Обертка для selection.toggle
	 * @param  workView участник
	 */
	toggleSelected(workView: WorkView): void {
		const tmp = this.selection.selected.find(x => x.id === workView.id);
		this.selection.deselect(...this.selection.selected);
		this.selection.select(tmp ? tmp : workView);
	}

	getDisabledSubTitle(workView: WorkView): string {
		let subTitle = 'Специалист уже приглашён или отправил отклик';
		let request = workView.requests.find(x => x.executor.id == this.data.appUserView.id);
		if ((request.direction == 1 && request.state == 2) || (request.direction == 0 && request.state == 2)) {
			subTitle = 'Отказ';
		}
		return subTitle;
	}

	onShowSubTitle(workView: WorkView): void {
		this.workViewSubTitleToShow = workView;
	}

	onHideSubTitle(workView: WorkView): void {
		this.workViewSubTitleToShow = null;
	}

	isToShowSubTitle(workView: WorkView): boolean {
		return this.workViewSubTitleToShow && this.workViewSubTitleToShow.id == workView.id;

	}

	roundedCheckboxTooltip(workView: WorkView): string {
		let request = workView.requests.find(x => x.executor.id == this.data.appUserView.id);
		if (!this.canInvite(workView)) {
			if (request.direction == 0 && request.state == 2) {
				return 'Отказ'
			}
			if (request.direction == 1 && request.state == 2) {
				return 'Отказ'
			}
			return null;
		}
		return null;
	}

	onRouterNavigate(id) {
		const url = this.router.serializeUrl(
			this.router.createUrlTree(['/works', id])
		);
		
		window.open(url, '_blank');
	}
}
