<div
	class='pd-login'
	*ngIf='loginForm'
>
	<form
		id='loginForm'
		[formGroup]='loginForm'
		(ngSubmit)='onSubmit()'
	>
		<div class='pd-login-form-wrapper'>
			<div class='pd-login-fields-wrapper'>
				<h3 class='pd-login-title'>Войти</h3>
				<p class='pd-login-text'>Новый пользователь? <a
					class='pd-auth-login-text'
					[routerLink]="['/register']"
				>Создать учетную запись</a></p>

				<ng-container *ngIf='afterResetPassword'>
					<div class='form-field-reseted-container'>
						Пароль успешно изменен!
					</div>
				</ng-container>
				<ng-container *ngIf='afterForgotPassword'>
					<div class='form-field-reseted-container'>
						Ссылка для подтверждения сброса пароля
						отправлена на вашу почту
					</div>
				</ng-container>

				<ng-container *ngIf='afterConfirmationEmail'>
					<div class='form-field-reseted-container'>
						Ваша почта успешно подтверждена!
					</div>
				</ng-container>
				<!--Email-->
				<mat-form-field
					class='pd-input-form-field'
					appearance='fill'
					hideRequiredMarker
				>
					<!--          <mat-icon class="pd-field-prefix-icon" svgIcon="additional-user-1" matPrefix></mat-icon>-->
					<mat-label class='pd-auth-new-font'>Email</mat-label>
					<input
						matInput
						formControlName='userName'
					>
					<mat-error
						*ngIf='userName?.errors?.required'
						i18n='mat-error@@login-userName-required'
					>Обязательное поле
					</mat-error>
					<mat-error
						*ngIf='userName?.errors?.pattern'
					>
						Неверный формат почты
					</mat-error>
					<mat-error
						*ngIf='userName?.errors?.serverError'
						i18n='mat-error@@login-userName-serverError'
					>
						{{ userName?.errors?.serverError }}
					</mat-error>
				</mat-form-field>
				<!--Password-->
				<mat-form-field
					class='pd-input-form-field form-field'
					appearance='fill'
					hideRequiredMarker
				>
					<!--          <mat-icon class="pd-field-prefix-icon" svgIcon="additional-shield-done" matPrefix></mat-icon>-->
					<mat-label class='pd-auth-new-font'>Пароль</mat-label>
					<input
						matInput
						formControlName='password'
						[type]="passwordHide ? 'password' : 'text'"
					>
					<button
						mat-icon-button
						matSuffix
						class='pd-icon-button light-gray new-color'
						type='button'
						[attr.aria-label]="authPipe.transform('passwordHide', 'propertyTitle')"
						[attr.aria-pressed]='passwordHide'
						(click)='passwordHide = !passwordHide'
					>
						<mat-icon [svgIcon]="passwordHide ? 'additional-view' : 'additional-view-hide'"></mat-icon>
					</button>
					<mat-error
						i18n='mat-error@@login-password-required'
						*ngIf='password?.errors?.required'
					>Обязательное поле
					</mat-error>
					<mat-error
						*ngIf='password?.errors?.minlength'
					>Введите не менее 8 символов
					</mat-error>
					<mat-error
						i18n='mat-error@@login-password-serverError'
						*ngIf='password?.errors?.serverError'
					>
						{{ password?.errors?.serverError }}
					</mat-error>
				</mat-form-field>
				<div class='pd-login-forgot-wrapper'>
					<a
						class='pd-link pd-login-link'
						[href]="'/forgotPassword'"
					>Забыли пароль?</a>
				</div>
			</div>
			<button
				*ngIf='!(loading$ | async); else isLoading'
				class='pd-button new-color'
				form='loginForm'
				mat-raised-button
				color='primary'
			>
				Войти
			</button>
			<p class='pd-login-text additional'>или</p>
			<!--Open Auth-->
			<div
				class='pd-oauth-container'
			>
				<button
					class='pd-button-oauth'
					type='button'
					mat-flat-button
					[disabled]='!isProduction'
					(click)='goOAuthLink(provider.name)'
					*ngFor='let provider of openAuthProviders'
				>
					<mat-icon
						class='pd-icon {{ provider.name | lowercase }}'
						svgIcon='{{ provider.icon }}'
					></mat-icon>
					<span>{{ provider.title }}</span>
				</button>
			</div>
		</div>
	</form>
</div>

<ng-template #isLoading>
	<mat-spinner
		class='loading-wrapper'
		strokeWidth='3'
		diameter='50'
	></mat-spinner>
</ng-template>

