import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { AppUserDialogData, AppUserGender, AppUserView } from "@models/app-user-view";
import { AppUserService } from "@core/services/app-user.service";
import { AbstractComponentDirective } from "@shared/abstract-component.directive";
import { takeUntil } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { errorTitle } from "@utils/helpers/error-helpers";
import { MaterialCalendarHeaderComponent } from "@shared/material-calendar-header/material-calendar-header.component";
import { WhitespaceValidator } from "@utils/helpers/validator-helpers";
import {
	accountNumberMask,
	accountNumberRegEx,
	bikNumberMask,
	bikNumberRegEx,
	personNamesRegEx,
} from "@utils/constants";
import { ConfirmationDialogType, DestroyService, NotificationService } from "@profdepo-ui/core";
import { AppUserInnDialogService } from "@core/services/app-user-inn-dialog.service";
import { RequisitesNoteConfirmationDialogValue } from "@shared/app-user-requisites-note-confirmation/app-user-requisites-note-confirmation.component";
import { AppUserRequisitesRequest } from "@requests/app-user-requisites-request";
import { MaskitoOptions } from "@maskito/core";

@Component({
	selector: "app-app-user-requisites-dialog",
	templateUrl: "./app-user-requisites-dialog.component.html",
})
export class AppUserRequisitesDialogComponent extends AbstractComponentDirective implements OnInit {
	form: FormGroup;
	calendarHeader = MaterialCalendarHeaderComponent;
	genders = AppUserGender;
	saving$ = new BehaviorSubject<boolean>(false);
	accountNumberMaskOptions: MaskitoOptions = {
		mask: accountNumberMask,
	};
	bikNumberMaskOptions: MaskitoOptions = {
		mask: bikNumberMask,
	};
	isRequisitesVerificationError = false;
	isRequisitesVerificationSuccess = false;
	currentDate = new Date();

	constructor(
		public dialogRef: MatDialogRef<AppUserDialogData, boolean | AppUserView>,
		@Inject(MAT_DIALOG_DATA) public data: AppUserDialogData,
		private formBuilder: FormBuilder,
		private appUserService: AppUserService,
		private notificationService: NotificationService,
		private appUserInnDialogService: AppUserInnDialogService,
		private destroy$: DestroyService
	) {
		super();
	}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			firstname: new FormControl(this.data.appUserView.firstname, {
				validators: [
					Validators.maxLength(40),
					Validators.pattern(personNamesRegEx),
					WhitespaceValidator,
					Validators.required,
				],
			}),
			middlename: new FormControl(this.data.appUserView.middlename, {
				validators: [Validators.pattern(personNamesRegEx), WhitespaceValidator],
			}),
			lastname: new FormControl(this.data.appUserView.lastname, {
				validators: [
					Validators.maxLength(40),
					Validators.pattern(personNamesRegEx),
					WhitespaceValidator,
					Validators.required,
				],
			}),
			account: new FormControl(null, {
				validators: [Validators.required, Validators.pattern(accountNumberRegEx)],
			}),
			bik: new FormControl(null, {
				validators: [Validators.required, Validators.pattern(bikNumberRegEx)],
			}),
			accountName: new FormControl(null, {
				validators: [],
			}),
		});
	}

	get firstname(): FormControl {
		return this.form.get("firstname") as FormControl;
	}

	get middlename(): FormControl {
		return this.form.get("middlename") as FormControl;
	}

	get lastname(): FormControl {
		return this.form.get("lastname") as FormControl;
	}

	get account(): FormControl {
		return this.form.get("account") as FormControl;
	}

	get bik(): FormControl {
		return this.form.get("bik") as FormControl;
	}

	get accountName(): FormControl {
		return this.form.get("accountName") as FormControl;
	}

	onSubmit({ value, valid }): void {
		if (valid) {
			this.saving$.next(true);
			const appUserRequisites: AppUserRequisitesRequest = {
				appUserId: this.data.appUserView.id,
				account: value.account,
				bik: value.bik,
				forPayment: true,
			};
			this.appUserService
				.postAppUserRequisites(appUserRequisites)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: (innValue) => {
						if (innValue) {
							this.isRequisitesVerificationSuccess = true;
						} else {
							this.isRequisitesVerificationError = true;
						}

						this.saving$.next(false);
					},
					error: (err) => {
						this.saving$.next(false);
						if (err instanceof HttpErrorResponse) {
							if (err.status === 400) {
								this.filloutFormError(this.form, err);
							} else {
								this.notificationService.showDanger(errorTitle(err));
							}
						}
					},
				});
		}
	}

	showNoteDialog() {
		this.appUserInnDialogService
			.showRequisitesNoteConfirmationDialog(ConfirmationDialogType.question)
			.afterClosed()
			.pipe(takeUntil(this.destroy$))
			.subscribe((res) => {
				if (res === RequisitesNoteConfirmationDialogValue.skip) {
					this.dialogRef.close();
				}
			});
	}
}
