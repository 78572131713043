<button
	class='pdw-button pdw-landing-text'
	[ngClass]='buttonClass'
	(click)='switch()'
	[disabled]='value'
>
	{{names.first}}
</button>

<button
	class='pdw-button pdw-landing-text'
	[ngClass]='buttonClass'
	(click)='switch()'
	[disabled]='!value'
>
	{{names.second}}
</button>
