import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
export interface ConfirmationDialogData {
  title: string;
  message: string;
  value: string;
  questionMark: boolean;
  type: ConfirmationDialogType;
  isNextBtnDisabled: boolean;
}

export enum ConfirmationDialogType {
  question = 1,
  warning
}

export enum OnCloseValue {
	update,
	goNext
}
@Component({
  selector: 'app-user-inn-step-three-confirmation',
  templateUrl: './app-user-inn-step-three-confirmation.component.html',
})
export class AppUserInnStepThreeConfirmationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AppUserInnStepThreeConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
  ) {
  }

  onNext(): void {
    this.dialogRef.close(OnCloseValue.goNext);
  }

  onUpdate(): void {
    this.dialogRef.close(OnCloseValue.update);
  }
}
