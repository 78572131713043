import { AppUserType, AppUserView } from '@models/app-user-view';
import { AppUserFollowerView } from '@models/app-user-follower-view';
import { AppUserRoleView } from '@models/app-user-role-view';
import { AppUserCompanyView } from '@models/app-user-company-view';
import { WorkRequestView } from '@models/work-request-view';
import { WorkView } from '@models/work-view';

export enum NotificationType {
	none = -1,
	simple = 0,
	appUserFollower = 1,
	appUserFollowerRequest = 2,
	work = 30,
	workRequest = 31,
	chatMessage = 32,
}

export class NotificationCard {
	public id: number;
	public primaryTitle: string;
	public secondaryTitle: string;
	public text: string;
	public creator: AppUserView;
	public createTime: Date;
	public type: NotificationType;
	public category: NotificationCategory;
	public state: NotificationState;
	public routerLink: any[];
	public object: any;
	public workRequestId: number;

	static card(appUserType: AppUserType, notificationView: NotificationView): NotificationCard {
		const card = new NotificationCard();
		card.id = notificationView.id;
		card.creator = notificationView.createAppUser;
		card.createTime = notificationView.createTime;
		card.type = notificationView.type;
		card.category = notificationView.category;
		card.state = notificationView.state;

		switch (notificationView.type) {
			case NotificationType.simple:
				card.text = notificationView.message;
				card.object = notificationView;
				break;
			case NotificationType.work:
				card.primaryTitle = notificationView.work.title;
				card.text = notificationView.message;
				card.routerLink = ['works', notificationView.work.id];
				card.object = notificationView.work;
				break;
			case NotificationType.workRequest:
				card.primaryTitle = notificationView.workRequest.work.title;
				card.text = notificationView.message;
				card.routerLink = ['works', notificationView.workRequest.work.id];
				card.object = notificationView.workRequest;
				break;
			case NotificationType.chatMessage:
				card.primaryTitle = notificationView.workRequest.work.title;
				card.text = notificationView.message;
				card.routerLink = ['works', notificationView.workRequest.work.id];
				card.object = notificationView.workRequest;
				break;
		}
		return card;
	}
}

export enum NotificationCategory {
	none = -1,
	usual,
	success,
	danger,
	warning,
	information
}

export enum NotificationState {
	none = -1,
	sent,
	received
}

export class NotificationView {
	public id: number;
	public type: NotificationType;
	public category: NotificationCategory;
	public message: string;
	public recipient: AppUserView;
	public work: WorkView;
	public workRequest: WorkRequestView;
	public state: NotificationState;
	public createAppUser: AppUserView;
	public createTime: Date;

	static fromType(value: NotificationType): NotificationView {
		const result = new NotificationView();
		result.type = value;
		return result;
	}

	static fromCategory(value: NotificationCategory): NotificationView {
		const result = new NotificationView();
		result.category = value;
		return result;
	}
}
