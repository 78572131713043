import {
	ChangeDetectionStrategy,
	Component,
	forwardRef,
	Input, OnInit,
	Optional,
	Self,
	ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DestroyService } from '@profdepo-ui/core';
import { takeUntil } from 'rxjs';

@Component({
	selector: "pdw-switch",
	templateUrl: "./app-switch.component.html",
	styleUrls: ["./app-switch.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class AppSwitchComponent implements ControlValueAccessor, OnInit {
	onChange = (_: any) => {};
	private _value: boolean = false;

	@Input() names: { first: string; second: string };
	@Input() buttonClass: string;
	isFreelance: boolean;

	@Input()
	set value(val) {
		this._value = val;
		this.onChange(this._value);
	}

	constructor(
		@Optional() @Self() private ngControl: NgControl,
		private route: ActivatedRoute,
		private destroy$: DestroyService
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = this;
		}
	}

	switch(flag?: boolean) {
		if (flag !== undefined) {
			this.onChange(flag);
			this._value = flag;
			return;
		}

		this.onChange(!this.value);
		this._value = !this.value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {}

	writeValue(obj: boolean): void {
		this._value = obj;
	}

	get value() {
		return this._value;
	}

	ngOnInit(): void {
		this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
			if (params.isFreelance === "false") {
				this.isFreelance = JSON.parse(params.isFreelance);
				this.switch(this.isFreelance);
			}
		});
		this.switch(this.ngControl.value);
	}
}
