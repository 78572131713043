import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkStateValue, WorkView } from '@models/work-view';
import { takeUntil } from 'rxjs/operators';
import { YesNo } from '@models/enums';
import { groupByField } from '@utils/helpers/array-helpers';
import { WorkStateView } from '@models/work-state-view';
import { WorkAbstractComponent } from '@shared/work-abstract-component/work-abstract-component';
import { DestroyService } from '@profdepo-ui/core';
import { AppUserType } from '@models/app-user-view';

interface WorkStateHistory {
	fake: boolean;
	value: WorkStateValue;
	date?: Date;
	isLast?: boolean;
}

@Component({
	selector: 'pdw-work-states',
	templateUrl: './work-states.component.html',
	host: { 'class': 'pd-work-states' },
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService]
})
export class WorkStatesComponent extends WorkAbstractComponent implements OnInit {
	workStateHistoryList: WorkStateHistory[];

	@Input() showBtn = false;
	@Output() addRequestEvt = new EventEmitter<void>();

	constructor(private destroy$: DestroyService) {
		super();
	}

	ngOnInit(): void {
		this.workViewSubject
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: workView => {
					this.currentWorkView.next(workView);
					this.fillWorkStateHistory(workView);
				}
			});
	}

	fillWorkStateHistory(workView: WorkView): void {
		const result: WorkStateHistory[] = [];

		const lastValue: WorkStateView = workView.states.find(x => x.isLast);
		const groupedStates = groupByField(workView.states.filter(x => x.value <= lastValue.value), 'value');

		groupedStates.forEach(x => {
			const latestState: WorkStateView = x.group.at(-1);
			if ((latestState.value >= WorkStateValue.draft &&
					latestState.value <= WorkStateValue.packaged) ||
				latestState.value === WorkStateValue.executing) {

				if ((latestState.value === WorkStateValue.draft) && this.roleService.hasPermission([AppUserType.specialist, AppUserType.none])) {
					return;
				}
				result.push({
					fake: false,
					value: latestState.value,
					date: latestState.createTime,
					isLast: latestState.isLast === YesNo.yes
				});
			}
		});

		Object.keys(WorkStateValue).forEach(x => {
			const tmpValue: WorkStateValue = Number(x);
			if (!isNaN(tmpValue)) {
				if (tmpValue === WorkStateValue.executingAwait ||
					(tmpValue >= WorkStateValue.checking && tmpValue <= WorkStateValue.failedByClient) ||
					tmpValue === WorkStateValue.none || tmpValue === WorkStateValue.vacancyIsFinished) {
					return;
				}

				if (tmpValue <= lastValue.value) {
					return;
				}

				if ((tmpValue === WorkStateValue.abstractFinished || tmpValue === WorkStateValue.successed) && this.workView.isFreelance === YesNo.no) {
					result.push({
						fake: true,
						value: WorkStateValue.vacancyIsFinished,
					});
					return;
				}

				const fakeState: WorkStateHistory = {
					fake: true,
					value: tmpValue,
				}
				result.push(fakeState);
			}
		});

		if (this.workView.isFreelance === YesNo.no) {
			this.workStateHistoryList = result.filter(state => {
				if (state.value !== WorkStateValue.packaged && state.value !== WorkStateValue.executing) {
					return state;
				}
				return null;
			});
		} else {
			this.workStateHistoryList = result;
		}
	}

	addState(): WorkStateHistory {
		return null;
	}
}
