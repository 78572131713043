<button
	class='pdw-button pdw-landing-text'
	[ngClass]='buttonClass'
	(click)='switch()'
	[class.disabled]='value'
>
	{{names.first}}
</button>

<button
	class='pdw-button pdw-landing-text'
	[ngClass]='buttonClass'
	(click)='switch()'
	[class.disabled]="!value"
>
	{{names.second}}
</button>
