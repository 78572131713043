<ng-container *ngIf='!(loading$ | async); else isLoading'>
	<ng-container
		*ngIf='{ data: dataSource.data | async, count: dataSource.count | async, hasNoCompanies: dataSource.hasNoCompanies | async } as both'
	>
		<ng-container *ngIf='both.data.length; else emptySource'>
			<div
				pdwScrollPagination
				[maxCount]='dataSource.count | async'
				[currentIndex]='pageIndex'
				(emitterOnBottom)='goNextPageByData($event)'
				(emitterOnInit)='loadByObject($event)'
				class='pd-works'
			>
				<pdw-work-card
					*ngFor='let element of both.data | slice: 0 : (pageIndex + 1) * pageSize'
					[commands]="['/works', element.id]"
					[cardType]="'client'"
					[work]='element'
					(changed)='onStateChange($event)'
				>
				</pdw-work-card>

			</div>
			<ng-container *ngIf='!(update$ | async); else paginationLoading'>
				<ng-container
					*ngIf='(remain | async) <= 0')
				>
					<p class='pd-basis-no-remain'>Вы посмотрели всех специалистов</p>
				</ng-container>
			</ng-container>
			<ng-template #paginationLoading>
				<p class='pd-basis-no-remain'>Загружаем</p>
			</ng-template>

		</ng-container>
		<ng-template #emptySource>
			<div class='pd-basis-card-empty pd-basis-card-empty-with-add-button'>
				<div class='pd-basis-card-empty-inner'>
					<ng-container *ngIf='hasFilter(filterValues); else noFilterValues'>
						<div class='pd-basis-card-empty-inner'>
							<mat-icon
								class='pd-icon'
								[svgIcon]="'additional2-alert-exclamation-2-100-100'"
							></mat-icon>
							<h2 class='pd-basis-card-empty-title'>По вашему запросу ничего не найдено</h2>
							<p class='pd-basis-card-empty-subtitle'>Измените поисковый запрос</p>
						</div>
					</ng-container>
					<ng-template #noFilterValues>
						<ng-container *ngIf='companiesOfActiveUser.length; else goToCompanies'>
							<mat-icon
								class='pd-icon'
								svgIcon='additional-plus-rectangle-100-100'
							></mat-icon>
							<h2 class='pd-basis-card-empty-title'>У вас пока
								нет вакансий</h2>
							<button
								mat-raised-button
								*ngIf='currentAppUserView; else spinner'
								(click)='goAdd()'
								class='pd-button'
							>
								Добавить вакансию
							</button>
							<ng-template #spinner>
								<mat-spinner
									diameter='20'
									strokeWidth='2'
								></mat-spinner>
							</ng-template>
						</ng-container>
						<ng-template #goToCompanies>
							<mat-icon
								class='pd-icon'
								svgIcon='additional-plus-rectangle-100-100'
							></mat-icon>
							<h2 class='pd-basis-card-empty-title'>
								Для создания {{freelanceValue ? 'работ' : 'вакансий'}}<br>
								необходимо добавить компанию
							</h2>

							<button
								mat-raised-button
								[routerLink]="['/companies']"
								class='pd-button'
								[queryParams]='{ goCreate: true }'
							>
								Добавить компанию
							</button>
						</ng-template>

						<ng-template #noWorks>
							<mat-icon
								class='pd-icon'
								[svgIcon]="'additional2-alert-exclamation-2-100-100'"
							></mat-icon>
							<h2 class='pd-basis-card-empty-title'>У вас пока нет компаний</h2>
							<button
								mat-raised-button
								[routerLink]="['/companies']"
								class='pd-button'
							>
								Создать компанию
							</button>
						</ng-template>
					</ng-template>
				</div>
			</div>
		</ng-template>
	</ng-container>
</ng-container>
<ng-template #isLoading>
	<div class='pd-basis-card-empty'>
		<div class='pd-basis-card-empty-inner'>
			<mat-spinner
				[diameter]='70'
				[strokeWidth]='5'
			></mat-spinner>
		</div>
	</div>
</ng-template>
