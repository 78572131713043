import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AppUserView} from "@models/app-user-view";
import {BehaviorSubject, Subject} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {filter} from "rxjs/operators";
import {errorTitle} from "@utils/helpers/error-helpers";
import {SoftSkillView} from "@models/soft-skill-view";
import {
  UserSoftSkillsDialogComponent
} from "@shared/app-user-soft-skills-dialog/user-soft-skills-dialog.component";
import {pushFakeHistoryState} from "@utils/functions";
import {SkillStateValue} from "@models/abstract-skill-view";
import {NotificationService} from "@profdepo-ui/core";

@Component({
  selector: 'app-app-user-soft-skills',
  templateUrl: './app-user-soft-skills.component.html',
})
export class AppUserSoftSkillsComponent implements OnInit, OnDestroy {
  @Input() canUpdate: boolean;

  @Input()
  set currentUserView(value: AppUserView) {
    this._currentUserView.next(value);
  };
  get currentUserView(): AppUserView {
    return this._currentUserView.getValue();
  }
  private _currentUserView = new BehaviorSubject<AppUserView>(null);

  currentAppUserView = new BehaviorSubject<AppUserView>(null);
  @Output() changed = new EventEmitter<any>();
  unsubscribe: Subject<any> = new Subject<any>();
  constructor(
    private dialog: MatDialog,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this._currentUserView
      .pipe(
        filter(x => x !== null),
      )
      .subscribe((appUserView: AppUserView) => {
        this.currentAppUserView.next(appUserView);
      });
  }
  getTooltip(softSkillView: SoftSkillView): string{
    if (softSkillView.state === SkillStateValue.created){
      return 'Качество на модерации';
    }
    return softSkillView.tooltip;
  }
  goUpdateSoftSkills(): void {
    pushFakeHistoryState();

    this.dialog.open(UserSoftSkillsDialogComponent, {
      autoFocus: true,
      disableClose: false,
      width: '581px',
      panelClass: 'pd-dialog',
      data: {
        title: 'Личные качества',
        method: 'update',
        appUserView: this.currentAppUserView.value
      }
    })
      .afterClosed()
      .pipe(
        filter(result => result),
      )
      .subscribe({
        next: (data) => {
          this.changed.emit('softSkills');
        },
        error: (err) => {
          this.notificationService.showDanger(errorTitle(err));
        }
      });
  }
  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }
}
