import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, merge} from "rxjs";
import {SkillStateValue, AbstractSkillView} from "@models/abstract-skill-view";
import {Grouping, MatGroupBy} from "../../public/components/mat-group-by";
import {MatTableDataSource} from "../../public/components/mat-table-data-source";
import {MatSort} from "@angular/material/sort";
import {debounceTime, distinctUntilChanged, filter} from "rxjs/operators";
import {HardSkillService, SkillsFilter} from "@core/services/hard.skill.service";
import {AppUserTypeValuePipe} from "@public/pipes/app-user-type-value.pipe";
import {FormControl} from "@angular/forms";
import {isEmptyValue, filterNumber, filterString} from "@utils/helpers/filter-helpers";
import {ThemePalette} from "@angular/material/core";

@Component({
  selector: 'app-skills-table',
  templateUrl: './skills-table.component.html',
})
export class SkillsTableComponent implements OnInit {
  @Input() otherLink: any[];
  @Input('data')
  set data(value: AbstractSkillView[]) {
    this._data.next(value);
  };
  get data(): AbstractSkillView[] {
    return this._data.getValue();
  }
  private _data = new BehaviorSubject<AbstractSkillView[]>([]);
  dataSource: MatTableDataSource<AbstractSkillView>;
  initDisplayedColumns = [
    'name',
    'code',
    'personsCount',
    'state'
  ];
  displayedColumns = new BehaviorSubject<string[]>(this.initDisplayedColumns);
  initFilteredColumns = [
    'name.filter',
    'code.filter',
    'personsCount.filter',
    'state.filter'
  ];
  filteredColumns = new BehaviorSubject<string[]>(this.initFilteredColumns);
  groupBy = new MatGroupBy();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  // @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  titleFilter = new FormControl('');
  nameFilter = new FormControl('');
  codeFilter = new FormControl('');
  personsCountFilter = new FormControl('');
  stateFilter = new FormControl('');
  debounceTime = 500;
  filterValues = new SkillsFilter();
  appUserTypeValuePipe = new AppUserTypeValuePipe();
  constructor(
    private skillService: HardSkillService
  ) {}

  ngOnInit() {
    this.groupBy.grouping = new Grouping([{
      name: 'section',
      sort: 'asc',
    }]);
    this._data
      .subscribe(x => {
        this.dataSource = new MatTableDataSource(x);
        this.dataSource.sort = this.sort;
        this.dataSource.groupBy = this.groupBy;
        this.dataSource.filterPredicate = this.filterPredicate();
      });

    merge(
      this.titleFilter.valueChanges,
      this.nameFilter.valueChanges,
      this.codeFilter.valueChanges,
      this.personsCountFilter.valueChanges,
    )
      .pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged()
      )
      .subscribe(() => {
        console.warn('this.filterValues: ', this.filterValues);
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });

    this.titleFilter.valueChanges
      .subscribe(x => this.filterValues.title = x);

    this.nameFilter.valueChanges
      .subscribe(x => this.filterValues.name = x);

    this.codeFilter.valueChanges
      .subscribe(x => this.filterValues.code = x);

    this.personsCountFilter.valueChanges
      .subscribe(x => this.filterValues.personCount = x);
  }

  filterPredicate(): (data: AbstractSkillView, filter: string) => boolean {
    return (data, filterValue): boolean => {
      const searchTerms = JSON.parse(filterValue) as SkillsFilter;
      return filterString(data.name, searchTerms.name) &&
        filterString(data.code, searchTerms.code) &&
        filterNumber(data.personsCount, searchTerms.personCount);
    };
  }
  personsCountSum(): number {
    return this.dataSource.filteredData
      .map(x => x.personsCount)
      .reduce((sum, current) => sum + current, 0);
  }
  skillStateColor(state: SkillStateValue): ThemePalette | null {
    return this.skillService.skillStateColor(state) as ThemePalette;
  }
  isEmptyValue(control: FormControl): boolean {
    return isEmptyValue(control);
  }
}
