<ng-container *ngIf='currentCompanyView | async as currentCompanyView'>
	<mat-expansion-panel
		class='pd-expansion-panel pd-company-block accordion mat-elevation-z0'
		[ngClass]="{'empty': isEmptyBlock(currentCompanyView)}"
		[hideToggle]='true'
		expanded='true'
	>
		<mat-expansion-panel-header>
			<div class='pd-company-block-title-wrapper'>
				<h3 class='pd-company-block-title'>О компании</h3>
				<ng-container *ngIf='canUpdate'>
					<button
						class='pd-button-secondary small'
						mat-raised-button
						(click)='goUpdateDescription($event)'
					>
						<ng-container *ngIf='!currentCompanyView.description; else updateAddress'>
							<mat-icon
								class='pd-icon'
								svgIcon='additional-plus'
							></mat-icon>
							<p class='pd-company-button-text'>Добавить</p>
						</ng-container>
						<ng-template #updateAddress>
							<mat-icon
								class='pd-icon'
								svgIcon='additional2-edit'
							></mat-icon>
							<p class='pd-company-button-text'>Редактировать</p>
						</ng-template>
					</button>
				</ng-container>
			</div>
			<button
				class='pd-icon-button'
				mat-icon-button
			>
				<mat-icon
					class='pd-icon pd-profile-block-toggle-icon'
					svgIcon='additional-chevron-down'
				>
				</mat-icon>
			</button>
		</mat-expansion-panel-header>
		<div class='pd-company-block-body'>
			<p
				class='pd-company-description-text'
				[class.accordion-description]='moreThan1500Symbols(currentCompanyView)'
				[innerHTML]='currentCompanyView.description | appPlainTextFormat'
			>
			</p>
			<ng-container *ngIf='canUpdate && !currentCompanyView.description'>
				<pdw-alert
					[alertType]="'success'"
					(closeEmit)='onCloseSnack($event)'
				>
					Добавьте информацию о компании, чтобы воспользоваться всеми возможностями платформы
				</pdw-alert>
			</ng-container>
			<ng-container *ngIf='!canUpdate && !currentCompanyView.description'>
				<p class='pd-company-description-text'>
					Описание не указано
				</p>
			</ng-container>
		</div>
	</mat-expansion-panel>
</ng-container>

