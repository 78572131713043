import { Component, OnInit } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-auth-layout',
	templateUrl: './auth-layout.component.html',
	styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent implements OnInit {

	recoveryMode: boolean = false;
	method: string;

	imgClass: string = 'pd-auth-image-default pd-auth-image-1';

	titleClass: string;

	navLinks: Observable<any[]>;
	welcomeTitle: boolean = true;

	constructor(
		private location: Location,
		private route: ActivatedRoute,
		private router: Router,
	) {
	}

	ngOnInit(): void {
		timer(100)
			.subscribe({
				next: () => {
					this.navLinks = of([
						{
							path: ['/login'],
							label: 'Вход',
						},
						{
							path: ['/register'],
							label: 'Регистрация',
						}
					]);
				}
			});

		this.method = this.route.snapshot.data.method;

		switch (this.method) {
			case 'confirm-email': {
				this.imgClass = 'pd-auth-confirm-img';
				this.recoveryMode = true;
				this.welcomeTitle = false;
				break;
			}
			case 'sent-confirmation': {
				this.imgClass = 'pd-auth-sent-img';
				this.recoveryMode = true;
				this.welcomeTitle = true;
				break;
			}
			case 'forgot-password': {
				this.imgClass = 'pd-auth-recovery-img';
				this.recoveryMode = true;
				this.welcomeTitle = false;
				break;
			}
			case 'reset-password': {
				this.imgClass = 'pd-auth-reset-img';
				this.recoveryMode = true;
				this.welcomeTitle = false;
				break;
			}
			case 'login': {
				this.titleClass = 'pd-auth-title-login';
				//setInterval(this.randomBackground.bind(this), 10000);
				this.recoveryMode = false;
				break;
			}
			case 'register': {
				this.titleClass = 'pd-auth-title-register';
				this.imgClass = 'pd-auth-image-register';
				//setInterval(this.randomBackground.bind(this), 10000);
				this.recoveryMode = false;
				break;
			}
			default: {
				this.imgClass = 'pd-auth-image-default';
				// setInterval(() => {
				//   this.imgClass = `pd-auth-image-default ${ Math.ceil(Math.random() * 3)}`
				// }, 10000);
				this.recoveryMode = false;
			}
		}
	}

	back() {
		if (this.method === 'forgot-password' || this.method === 'sent-confirmation') {
			this.router.navigate(['/login']);
			return;
		}
		this.location.back();
	}
}
