<ng-container *ngLet='both | async as users'>
	<ng-container *ngLet='users?.length ? users[0] : null as currentAppUser'>
		<ng-container *ngLet='(activeAppUserView$ | async) as activeAppUser'>
			<ng-container *ngIf='(currentAppUserView$ | async) || (activeAppUserView$ | async)'>

				<div
					class='user-top-info-grid'
					*ngIf='isCurrentUserSpecialist(currentAppUser) || !authService.isLoggedIn(); else clientDetails'
				>
					<div class='pd-profile-brief pd-profile-plate grid-brief'>
						<!-- Avatar -->
						<pdw-app-user-avatar
							[canUpdate]='canUpdate(currentAppUser, activeAppUser)'
							[currentUserView]='currentAppUserView$ | async'
							(changed)='onChanged($event)'
						>
						</pdw-app-user-avatar>
						<!-- Personal -->
						<pdw-app-user-personal
							[canUpdate]='canUpdate(currentAppUser, activeAppUser)'
							[currentUserView]='currentAppUserView$ | async'
							[activeUserView]='activeAppUserView$ | async'
							(changed)='onChanged($event)'
						>
						</pdw-app-user-personal>
					</div>

					<div class='rating-container pd-profile-rating pd-profile-plate grid-rating'>
						<pdw-user-rating [currentUserView]='currentAppUserView$ | async'></pdw-user-rating>
					</div>

					<div
						class='work-container pd-profile-plate grid-profession'
					>
						<ng-container *ngLet='currentAppUserView$ | async as user'>
							<h2 class='pd-profile-title'>
								{{user.profession ? user.profession : 'Специализация не указана'}}
							</h2>

							<button
								*ngIf='canUpdate(currentAppUser, activeAppUser)'
								class='pd-button-secondary small pd-button-fit'
								mat-raised-button
								(click)='openSpecializationDialog()'
							>
								<mat-icon
									class='pd-icon'
									svgIcon='additional2-edit'
								></mat-icon>
								<p class='pd-profile-button-text'>Редактировать</p>
							</button>

							<div>
								<ng-container *ngIf='user?.workFormats?.length || user?.workTypes?.length; else emptySpecialization'>
									<p *ngIf='user?.workFormats?.length'>
										{{transformArray(user?.workFormats) | convertArrayToString: 'name' : ", "}}
									</p>
									<p *ngIf='user?.workTypes?.length'>
										{{transformArray(user?.workTypes) | convertArrayToString: 'name' : ", "}}
									</p>
								</ng-container>

								<ng-template #emptySpecialization>
									<p>
										Желаемый график работы не указан
									</p>
								</ng-template>
							</div>
							<h2 class='pd-profile-title'>
								{{user.preferredReward ? (user.preferredReward | space) + " руб. в месяц" : 'Предполагаемая заработная плата не указана'}}
							</h2>
						</ng-container>
					</div>

					<div class='categories-container pd-profile-sub-categories pd-profile-plate grid-categories'>
						<pdw-app-user-sub-categories
							[canUpdate]='canUpdate(currentAppUser, activeAppUser)'
							[currentUserView]='currentAppUserView$ | async'
							(changed)='onChanged($event)'
						>
						</pdw-app-user-sub-categories>
					</div>
				</div>

				<ng-template #clientDetails>

					<div class='pd-profile-join-row-with-gap'>
						<div class='pd-profile-brief pd-profile-plate'>
							<!-- Avatar -->
							<pdw-app-user-avatar
								[canUpdate]='canUpdate(currentAppUser, activeAppUser)'
								[currentUserView]='currentAppUserView$ | async'
								(changed)='onChanged($event)'
							>
							</pdw-app-user-avatar>
							<!-- Personal -->
							<pdw-app-user-personal
								[canUpdate]='canUpdate(currentAppUser, activeAppUser)'
								[currentUserView]='currentAppUserView$ | async'
								[activeUserView]='activeAppUserView$ | async'
								(changed)='onChanged($event)'
							>
							</pdw-app-user-personal>
						</div>

						<!-- <div
							class='inn-block pd-profile-plate'
						>
							<pdw-user-rating [currentUserView]='currentAppUserView$ | async'></pdw-user-rating>
						</div>
						<ng-template #specRating>
							<div
								*ngIf='isCurrentUserSpecialist(currentAppUser)'
								class='inn-block pd-profile-plate'
							>
								<pdw-user-rating [currentUserView]='currentAppUserView$ | async'></pdw-user-rating>
							</div>
						</ng-template> -->
					</div>

					<!-- SubCategories -->
					<ng-container *ngIf='isCurrentUserSpecialist(currentAppUser) || !authService.isLoggedIn()'>
						<div [ngClass]='canUpdate(currentAppUser, activeAppUser) ? "info-container" : "info-container-other"'>
							<div class='rating-container pd-profile-rating pd-profile-plate'>
								<pdw-user-rating [currentUserView]='currentAppUserView$ | async'></pdw-user-rating>
							</div>

							<div
								*ngLet='currentAppUserView$ | async as currentUser'
								class='work-container pd-profile-plate'
							>
								<div class='row'>
									<h2 class='pd-profile-title'>
										{{currentUser.profession ? currentUser.profession : 'Специализация не указана'}}
									</h2>

									<button
										*ngIf='canUpdate(currentAppUser, activeAppUser)'
										class='pd-button-secondary small'
										mat-raised-button
										(click)='openSpecializationDialog()'
									>
										<mat-icon
											class='pd-icon'
											svgIcon='additional2-edit'
										></mat-icon>
										<p class='pd-profile-button-text'>Редактировать</p>
									</button>
								</div>

								<div *ngIf='authService.isLoggedIn(); else noAuthWorkFormats'>
									<p>
										График
										работы: {{currentAppUser.workFormats | convertArrayToString: 'name' : ", " : "Не указано"}}
									</p>
									<p>
										Занятость: {{currentAppUser?.workTypes | convertArrayToString: 'name' : ", " : "Не указана"}}
									</p>
								</div>
								<ng-template #noAuthWorkFormats>
									<div>
										<p>
											График
											работы: {{(currentAppUserView$ | async).workFormats | convertArrayToString: 'name' : ", " : "Не указано"}}
										</p>
										<p>
											Занятость: {{(currentAppUserView$ | async)?.workTypes | convertArrayToString: 'name' : ", " : "Не указана"}}
										</p>
									</div>
								</ng-template>
								<h2 class='pd-profile-title'>
									{{currentUser.preferredReward ? currentUser.preferredReward + " ₽" : 'Предполагаемая заработная плата не указана'}}
								</h2>
							</div>
							<div class='categories-container pd-profile-sub-categories pd-profile-plate'>
								<pdw-app-user-sub-categories
									[canUpdate]='canUpdate(currentAppUser, activeAppUser)'
									[currentUserView]='currentAppUserView$ | async'
									(changed)='onChanged($event)'
								>
								</pdw-app-user-sub-categories>
							</div>
						</div>
					</ng-container>
				</ng-template>
				<!-- TODO: Вернуть если даст добро Рамиля -->
				<!--				<ng-container *ngIf='isCurrentUserSpecialist(currentAppUser)'>-->
				<!--					<mat-expansion-panel-->
				<!--						class='pd-expansion-panel pd-profile-categories-rate pd-profile-block'-->
				<!--						[hideToggle]='true'-->
				<!--					>-->
				<!--						<mat-expansion-panel-header>-->
				<!--							<div class='pd-profile-title-wrapper'>-->
				<!--								<h3 class='pd-profile-block-title'>Рейтинг категорий</h3>-->
				<!--								<mat-icon-->
				<!--									class='pd-icon light-gray'-->
				<!--									svgIcon='additional-alert-question-1'-->
				<!--									[matTooltipPosition]="'right'"-->
				<!--									[matTooltip]="'Скоро здесь будет подсказка'"-->
				<!--								>-->
				<!--								</mat-icon>-->
				<!--							</div>-->
				<!--							<button-->
				<!--								class='pd-icon-button'-->
				<!--								mat-icon-button-->
				<!--							>-->
				<!--								<mat-icon-->
				<!--									class='pd-icon pd-profile-block-toggle-icon'-->
				<!--									svgIcon='additional-chevron-down'-->
				<!--								>-->
				<!--								</mat-icon>-->
				<!--							</button>-->
				<!--						</mat-expansion-panel-header>-->
				<!--						<div class='pd-profile-block-body'>-->
				<!--							<app-app-user-categories-rate [currentUserView]='currentAppUser'>-->
				<!--							</app-app-user-categories-rate>-->
				<!--						</div>-->
				<!--					</mat-expansion-panel>-->
				<!--				</ng-container>-->
				<mat-expansion-panel
					expanded='true'
					class='pd-expansion-panel pd-profile-block pd-profile-about mat-elevation-z0'
					[hideToggle]='true'
				>
					<mat-expansion-panel-header>
						<h3 class='pd-profile-block-title'>Обо мне</h3>
						<button
							class='pd-icon-button'
							mat-icon-button
						>
							<mat-icon
								class='pd-icon pd-profile-block-toggle-icon'
								svgIcon='additional-chevron-down'
							>
							</mat-icon>
						</button>
					</mat-expansion-panel-header>
					<div class='pd-profile-block-body'>
						<div
							class='pd-profile-app-alert-wrapper'
							*ngIf='isEmptyUserAboutFields()'
						>
							<pdw-alert
								[alertType]="'success'"
								[canClose]='true'
							>
								Заполните информацию в разделах ниже, чтобы повысить свой рейтинг
							</pdw-alert>
						</div>
						<div class='pd-profile-column-with-gap'>

							<!-- Experiences -->
							<pdw-app-user-experiences
								class='pd-profile-experiences'
								[canUpdate]='canUpdate(currentAppUser, activeAppUser)'
								[currentUserView]='currentAppUserView$ | async'
								(changed)='onChanged($event)'
							>
							</pdw-app-user-experiences>

							<!-- EducationAdditionals -->
							<app-app-user-education-additionals
								class='pd-profile-education-additionals'
								[canUpdate]='canUpdate(currentAppUser, activeAppUser)'
								[currentUserView]='currentAppUserView$ | async'
								(changed)='onChanged($event)'
							>
							</app-app-user-education-additionals>

							<!-- Educations -->
							<app-app-user-educations
								[canUpdate]='canUpdate(currentAppUser, activeAppUser)'
								[currentUserView]='currentAppUserView$ | async'
								(changed)='onChanged($event)'
							>
							</app-app-user-educations>

							<!-- Hard Skills -->
							<pdw-user-hard-skills
								class='pd-profile-hard-skills'
								[canUpdate]='canUpdate(currentAppUser, activeAppUser)'
								[currentUserView]='currentAppUserView$ | async'
								(changed)='onChanged($event)'
							>
							</pdw-user-hard-skills>

							<!-- Soft Skills -->
							<app-app-user-soft-skills
								[canUpdate]='canUpdate(currentAppUser, activeAppUser)'
								[currentUserView]='currentAppUserView$ | async'
								(changed)='onChanged($event)'
							>
							</app-app-user-soft-skills>
						</div>
					</div>
				</mat-expansion-panel>

				<ng-container *ngIf='!authService.isLoggedIn()'>
					<mat-expansion-panel
						expanded='true'
						class='pd-expansion-panel pd-profile-block mat-elevation-z0'
						[hideToggle]='true'
					>
						<mat-expansion-panel-header>
							<h3 class='pd-profile-block-title'>Отзывы</h3>
							<button
								class='pd-icon-button'
								mat-icon-button
							>
								<mat-icon
									class='pd-icon pd-profile-block-toggle-icon'
									svgIcon='additional-chevron-down'
								>
								</mat-icon>
							</button>
						</mat-expansion-panel-header>
						<div class='pd-profile-block-body'>
							<pdw-app-user-marks [currentUserView]='currentAppUserView$ | async'></pdw-app-user-marks>
						</div>
					</mat-expansion-panel>
				</ng-container>
				<ng-container *ngIf='isCurrentUserSpecialist(currentAppUser)'>
					<mat-expansion-panel
						expanded='true'
						class='pd-expansion-panel pd-profile-block mat-elevation-z0'
						[hideToggle]='true'
					>
						<mat-expansion-panel-header>
							<h3 class='pd-profile-block-title'>Отзывы</h3>
							<button
								class='pd-icon-button'
								mat-icon-button
							>
								<mat-icon
									class='pd-icon pd-profile-block-toggle-icon'
									svgIcon='additional-chevron-down'
								>
								</mat-icon>
							</button>
						</mat-expansion-panel-header>
						<div class='pd-profile-block-body'>
							<pdw-app-user-marks [currentUserView]='currentAppUser'></pdw-app-user-marks>
						</div>
					</mat-expansion-panel>
				</ng-container>

				<ng-container *ngIf='canUpdate(currentAppUser, activeAppUser)'>
					<mat-expansion-panel
						expanded='true'
						class='pd-expansion-panel pd-profile-block pd-profile-settings mat-elevation-z0'
						[hideToggle]='true'
					>
						<mat-expansion-panel-header>
							<h3 class='pd-profile-block-title'>Управление аккаунтом</h3>
							<button
								class='pd-icon-button'
								mat-icon-button
							>
								<mat-icon
									class='pd-icon pd-profile-block-toggle-icon'
									svgIcon='additional-chevron-down'
								>
								</mat-icon>
							</button>
						</mat-expansion-panel-header>
						<div class='pd-profile-block-body'>
							<button
								(click)='confirmationOfRoleChange()'
								[disabled]='isChangingType$ | async'
								class='pd-button-transparent revert-font-color'
							>
								<mat-icon svgIcon='interface-compare'></mat-icon>
								<p class='pd-button-transparent-text'>
									Стать {{ changeTypeBtnTitle(activeUser.type) }}
								</p>
							</button>
							<button
								(click)='goChangePass()'
								class='pd-button-transparent revert-font-color'
							>
								<mat-icon svgIcon='additional-edit-rectangle'></mat-icon>
								<p class='pd-button-transparent-text'>Изменить пароль</p>
							</button>
							<button
								(click)='goSupport()'
								class='pd-button-transparent revert-font-color'
							>
								<mat-icon svgIcon='media-headphone'></mat-icon>
								<p class='pd-button-transparent-text'>Поддержка</p>
							</button>
						</div>
					</mat-expansion-panel>
				</ng-container>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-container>