<div
	class='pd-work-plate-info'
	*ngIf='company'
>
	<div class='company-container'>
		<div class='company-description-container'>
			<a [routerLink]='"/companies/" + company.id'>
				{{company.name}}
			</a>
			<span class='company-description'>
				<app-rating-one-small [percent]='company.ratingPercent'>
				</app-rating-one-small>

				<p>
					{{pluralize(count)}}
				</p>
			</span>

			<p class='company-geoposition'>
				{{company.legal?.region ?? company.legal?.city}}
			</p>
		</div>
		<div
			class='company-avatar-wrapper'
			[style]="'background: linear-gradient(200.87deg, ' + logoColor(company) + ' 20.98%, rgba(219, 189, 207, 0.1) 91.37%)'"
		>
			<img
				*ngIf='company.cropperPosition;'
				[alt]='company.name'
				[src]='company | avatar: "api/Companies/Logo" | async'
			/>
		</div>
	</div>
</div>
