import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,

} from '@angular/core';
import { BehaviorSubject, withLatestFrom } from 'rxjs';
import { SpecialistRequestsDataSource } from '@core/dataSources/specialist-requests-data-source';
import { WorkRequestsFilter } from '@core/services/work-request.service';
import { distinctUntilChanged, filter, finalize, takeUntil } from 'rxjs/operators';
import { AnySort } from '@utils/any-sort';
import { UtilsService } from '@core/services/utils.service';
import { NoticeService } from '@core/services/notice.service';
import { DestroyService } from '@profdepo-ui/core';

@Component({
	selector: 'app-work-requests-specialist-cards',
	templateUrl: './work-requests-specialist-cards.component.html',
	host: { 'class': 'pd-expanded-height' },
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService],
	styleUrls: ['./work-requests-specialist-cards.component.scss']
})
export class WorkRequestsSpecialistCardsComponent implements OnInit {

	/**
	 * Источник данных для получения записей удовлетворяющих критериям поиска
	 */
	@Input() dataSource: SpecialistRequestsDataSource;

	/**
	 * Количество колонок
	 * @param value количество колонок
	 */
	@Input()
	set columnsCount(value: number) {
		this._columnsCount.next(value);
	};

	get columnsCount(): number {
		return this._columnsCount.getValue();
	}

	private _columnsCount = new BehaviorSubject<number>(null);
	/**
	 * Ширина одной карточки специалиста
	 * Размер должен быть согласован так, чтобы перенос карточек в браузере
	 * при изменении размера окна совпадал с пересчетом количеста колонок
	 */
	@Input() cardWidth: number = 460;

	@Input()
	set filterValues(value: WorkRequestsFilter) {
		this._filterValues.next(value);
	};

	get filterValues(): WorkRequestsFilter {
		return this._filterValues.getValue();
	}

	private _filterValues = new BehaviorSubject<WorkRequestsFilter>(null);

	@Input()
	set sortValues(value: AnySort) {
		this._sortValues.next(value);
	};

	get sortValues(): AnySort {
		return this._sortValues.getValue();
	}

	private _sortValues = new BehaviorSubject<AnySort>(null);
	pageIndex = 0;
	pageSize = 0;
	remain = new BehaviorSubject<number>(null);
	loadingData = new BehaviorSubject<boolean>(false);
	loadingNextPage = new BehaviorSubject<boolean>(false);

	constructor(private utilsService: UtilsService,
				private noticeService: NoticeService,
				private destroy$: DestroyService,
				private cdr: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		this.loadingData.next(true);
		this._columnsCount
			.pipe(
				filter(x => x !== null && x > 0),
				distinctUntilChanged(),
				withLatestFrom(this.dataSource.data),
			)
			.subscribe(data => {
				this.pageSize = this.calcPageSize(data[0]);
				const p = this.calcPageIndex(data[1].length, this.pageSize);
				this.pageIndex = p.pageIndex;
				if (p.reload) {
					this.loadMore(this.pageIndex, this.pageSize);
				}
				this.loadCount();
				this.cdr.markForCheck();
			});

		this._filterValues
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.loadingData.next(true);
				this.pageIndex = 0;
				this.load(this.pageIndex, this.pageSize);
				this.loadCount();
			});

		this._sortValues
			.pipe(
				filter(x => x !== null && this.columnsCount > 0),
				distinctUntilChanged(),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.loadingData.next(true);
				this.load(0, this.pageSize * (this.pageIndex + 1));
			});

		this.noticeService.workRequestCountsChanged
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: () => {
					this.load(this.pageIndex, this.pageSize);
					this.loadCount();
				}
			});
	}

	/**
	 * Определяет актуальный номер страницы исходя из количества загруженных данных и размера страницы
	 * @param dataLength количества загруженных данных
	 * @param pageSize размера страницы
	 */
	calcPageIndex(dataLength: number, pageSize: number): any {
		const pageIndex = dataLength / pageSize;
		const pageIndexTrunc = Math.floor(pageIndex);

		/**
		 * Если вычисленный номер страницы не дотягивает до двух,
		 * то значит загрузить только первую страницу
		 */
		if (pageIndex < 2.0) {
			return {
				pageIndex: 0,
				reload: dataLength < pageSize
			};
		}

		/**
		 * В остальных случаях округлять страницу в меньшую сторону
		 */
		return {
			pageIndex: pageIndexTrunc - 1,
			reload: dataLength < pageSize * pageIndexTrunc
		}
	}

	/**
	 * Определяет размер страницы для указанного количества колонок
	 * @param columnsCount количество колонок
	 */
	calcPageSize(columnsCount: number): number {
		switch (columnsCount) {
			case 1:
				return 3;
			case 2:
				return 6;
			case 3:
				return 9;
			case 4:
				return 12;
			case 6:
				return 18;
			case 7:
				return 21;
			default:
				return 15;
		}
	}

	loadCount(): void {
		this.dataSource.loadCount(this.filterValues)
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe(x => {
				const remain = x - (this.pageIndex + 1) * this.pageSize;
				this.remain.next(remain > this.pageSize ? this.pageSize : remain);
			});
	}

	/**
	 * Загружает указанную страницу данных указанного размера
	 * @param pageIndex номер страницы
	 * @param pageSize размер страницы
	 */
	load(pageIndex: number, pageSize: number): void {
		this.dataSource.load(
				this.filterValues,
				this.sortValues?.name + ',' + this.sortValues?.direction,
				pageIndex,
				pageSize)
			.pipe(
				finalize(() => {
					this.loadingData.next(false);
					this.loadingNextPage.next(false);
				}),
				takeUntil(this.destroy$),
			)
			.subscribe(() => {
				this.loadingData.next(false);
				this.loadingNextPage.next(false);
			});
	}

	/**
	 * Загружает указанную страницу данных указанного размера
	 * @param pageIndex номер страницы
	 * @param pageSize размер страницы
	 */
	loadMore(pageIndex: number, pageSize: number): void {
		this.dataSource.loadMore(
				this.filterValues,
				this.sortValues?.name + ',' + this.sortValues?.direction,
				pageIndex,
				pageSize)
			.pipe(
				finalize(() => {
					this.loadingData.next(false);
					this.loadingNextPage.next(false);
				}),
				takeUntil(this.destroy$),
			)
			.subscribe(() => {
				this.loadingData.next(false);
				this.loadingNextPage.next(false);
			});
	}

	/**
	 * Загружает следующую страницу данных
	 */
	goNextPage(): void {
		this.loadingNextPage.next(true);
		this.pageIndex++;
		this.loadMore(this.pageIndex, this.pageSize);
		this.loadCount();
	}

	onChange(event) {
		this.loadingData.next(false);
		this.load(0, this.pageSize * (this.pageIndex + 1));
		this.loadCount();
		this.utilsService.changeWorkRequestCount.emit(event);
	}


}
