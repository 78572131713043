import {
	AfterViewInit,
	Directive,
	ElementRef, Inject,
	OnDestroy, Renderer2
} from '@angular/core';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'mat-form-field[appExtendableTextArea]'
})
export class ExtendableTextAreaDirective implements AfterViewInit, OnDestroy {

	private textarea: HTMLTextAreaElement;
	private button: HTMLButtonElement;
	private scrollbar: HTMLElement;
	private pseudo: HTMLElement;
	private unlistenMouseDown: () => void;
	private unlistenChangeValue: () => void;
	private unlistenButtonClick: () => void;

	constructor(
		private _el: ElementRef,
		private renderer: Renderer2,
		@Inject('WINDOW') private window: Window) {
	}

	ngAfterViewInit() {

		this.textarea = this._el.nativeElement.querySelector('textarea');
		this.button = this._el.nativeElement.querySelector('button');
		this.scrollbar = this._el.nativeElement.querySelector('.pd-textarea-scrollbar');
		this.pseudo = this._el.nativeElement.querySelector('.pd-textarea-pseudo');


		this.pseudo.style.height = this.calculateInitialHeight() + 'px';
		/**
		 * Скролл должен быть чуть больше высоты псевдо элемента
		 */
		this.scrollbar.style.height = this.calculateInitialHeight() + 4 + 'px';
		this.pseudo.textContent = this.textarea.value;

		if (this.isTouchDevice()) {
			this.unlistenMouseDown = this.renderer.listen(this.button, 'touchstart', (e: TouchEvent) => {
				const rectButton = this.button.getBoundingClientRect();
				const rectScroll = this.scrollbar.getBoundingClientRect();


				/**
				 * Смещение относительно клика
				 */
				const shiftY = e.touches[0].clientY - rectButton.top - 5;
				const rectScrollTop = rectScroll.top;
				let unlistenTouch = this.renderer.listen(this.window, 'touchmove', (e: TouchEvent) => {

					const dynamicHeight = e.touches[0].clientY - shiftY - rectScrollTop;
					if (dynamicHeight >= 36) {
						this.pseudo.style.height = dynamicHeight + 'px';
						this.scrollbar.style.height = dynamicHeight + 4 + 'px';
					}
				});

				this.renderer.listen(this.window, 'touchend', () => unlistenTouch());
			});
		} else {
			this.unlistenMouseDown = this.renderer.listen(this.button, 'mousedown', (e) => {
				const rectButton = this.button.getBoundingClientRect();
				const rectScroll = this.scrollbar.getBoundingClientRect();

				/**
				 * Смещение относительно клика
				 */
				const shiftY = e.clientY - rectButton.top;
				const rectScrollTop = rectScroll.top;

				let unlistenMouseMove = this.renderer.listen(this.window, 'mousemove', (e) => {

					const dynamicHeight = e.clientY - shiftY - rectScrollTop;

					if (dynamicHeight >= 36) {
						this.pseudo.style.height = dynamicHeight + 'px';
						this.scrollbar.style.height = dynamicHeight + 4 + 'px';
					}
				});

				this.renderer.listen(this.window, 'mouseup', () => unlistenMouseMove());
			});

		}
		/**
		 * Убираем при клике на кнопку прокрутку вниз
		 */
		this.unlistenButtonClick = this.renderer.listen(this.button, 'click', (e) => e.stopPropagation());
		this.unlistenChangeValue = this.renderer.listen(this.textarea, 'input', (e) => {
			this.pseudo.textContent = e.target.value;
		});
	}

	ngOnDestroy() {
		this.unlistenMouseDown();
		this.unlistenChangeValue();
		this.unlistenButtonClick();
	}

	calculateInitialHeight(): number | null {
		if (this.textarea.rows) {
			return this.textarea.rows * 18;
		}
		return 36;
	}

	isTouchDevice() {
		return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
	}
}
