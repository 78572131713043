import { Component, Input } from '@angular/core';
import { WorkStateEnum, WorkStateValue } from '@models/work-view';
import { WorkService } from '@core/services/work.service';

@Component({
	selector: 'pdw-work-state',
	templateUrl: './work-state.component.html',
})
export class WorkStateComponent {

	readonly WORK_STATE = WorkStateEnum;

	@Input() value: WorkStateValue = WorkStateValue.none;
	@Input() type: WorkStateEnum = this.WORK_STATE.CERTAIN;
	@Input() isActiveUserAssigned: boolean = false;

	stateColorClass(): string {

		switch (this.type) {
			case WorkStateEnum.CERTAIN:
				return WorkService.stateColorClass(WorkService.StateToAbstractFinish(this.value));

			case WorkStateEnum.ABSTRACT_FINISHED:
				return WorkService.stateColorClass(WorkService.StateToAbstractFinish(this.value));

			case WorkStateEnum.EXCLUSIVE_FOR_SPECIALIST:
				return WorkService.stateColorClass(WorkService.StateExclusiveForSpecialist(this.value, this.isActiveUserAssigned));

			case WorkStateEnum.ABSTRACT_FINISHED_EXCLUDE_SUCCEED:
				return WorkService.stateColorClass(WorkService.StateToAbstractFinishExcludeSuccessed(this.value));

			default:
				return WorkService.stateColorClass(this.value);
		}
	}

	toAbstractFinish(state: WorkStateValue): WorkStateValue {
		return WorkService.StateToAbstractFinish(state);
	}

	toAbstractFinishExcludeSuccessed(state: WorkStateValue): WorkStateValue {
		return WorkService.StateToAbstractFinishExcludeSuccessed(state);
	}

	toExclusiveForSpecialist(state: WorkStateValue): WorkStateValue {
		return WorkService.StateExclusiveForSpecialist(state, this.isActiveUserAssigned);
	}

	hasValue(): boolean {
		return this.value !== WorkStateValue.none && this.value !== null;
	}

	toNormalizedOutputValue(state: WorkStateValue): WorkStateValue {
		switch (state) {
			case WorkStateValue.executingAwait:
			case WorkStateValue.checking:
				return WorkStateValue.packaged;
			default:
				return state;
		}
	}
}
