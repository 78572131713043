<ng-container *ngIf='authService.isLoggedIn(); else noAuthWorkDetails'>
	<ng-container *ngIf='currentWorkView | async as workView'>
		<div class='pd-work'>
			<div class='pd-work-main-column'>
				<div class='pd-work-plate-info'>
					<div class='pd-work-title-wrapper'>
						<h1 class='pd-work-title-text'>{{workView.name}}</h1>
					</div>
					<!--Cost, Dates, Company-->
					<div class='pd-work-preamble'>
						<div
							class='pd-work-company-row'
						>
							<ng-container *ngIf='!(utilsService.mobileQuery980px | async); else mobileTemplate'>
								<app-rating-one-small [percent]='workView.company.ratingPercent'>
								</app-rating-one-small>
								<a
									[routerLink]="['/companies', workView.company.id]"
									class='pd-work-company-link'
								>
									{{workView.company?.name}}
								</a>
								<ng-container *ngIf='isFreelance'>
									<span class='pd-work-company-slash'>/</span>
									<p class='pd-work-company-dates'>
										{{ workView.startDate ? (workView.startDate | date:'dd.MM.YYYY') : '--'}} -
										{{ workView.endDate ? (workView.endDate | date:'dd.MM.YYYY') : '--'}}
									</p>
								</ng-container>
								<span class='pd-work-company-slash'>/</span>
								<p class='pd-work-company-dates'>
									{{ workView?.city ? workView.city.name : 'не указан' }}
								</p>
							</ng-container>

							<ng-template #mobileTemplate>
								<div class='pd-work-company-container'>
									<app-rating-one-small [percent]='workView.company.ratingPercent'>
									</app-rating-one-small>
									<a
										[routerLink]="['/companies', workView.company.id]"
										class='pd-work-company-link'
									>
										{{workView.company.name}}
									</a>
								</div>
								<ng-container *ngIf='isFreelance'>
									<span class='pd-work-company-slash'>/</span>
									<p class='pd-work-company-dates'>
										{{ workView.startDate ? (workView.startDate | date:'dd.MM.YYYY') : '--'}} -
										{{ workView.endDate ? (workView.endDate | date:'dd.MM.YYYY') : '--'}}
									</p>
								</ng-container>
								<span class='pd-work-company-slash'>/</span>
								<p class='pd-work-company-dates'>
									{{ workView?.city ? workView.city.name : 'не указан' }}
								</p>
							</ng-template>
						</div>
						<pdw-work-cost
							[work]='workView'
							[plateView]='false'
						></pdw-work-cost>

					</div>
					<div>
						<p
							class='work-types'
							*ngIf='workView.workFormat'
						>
							{{workView.workFormat.name}}
						</p>
						<p
							class='work-types'
							*ngIf='workView.workType'
						>
							{{workView.workType.name}}
						</p>
					</div>
					<ng-container *ngIf='workView.categories.length || workView.subCategories.length;'>
						<div
							class='pd-profile-skills-wrapper'
							*ngIf='groupedCategoriesWithSubCategories | async as arr'
						>
							<ng-container *ngFor='let category of arr'>
								<div
								   *ngIf="category.categoryName"
									class='pd-skill-inner category'
									[matTooltip]="'Категория работы'"
								>
									<p class='pd-skill-text'>
										{{ category.categoryName }}
									</p>
								</div>
								<ng-container *ngFor='let subCategory of category.group'>
									<div
										class='pd-skill-inner'
										[matTooltip]="'Тип работы'"
									>
										<p class='pd-skill-text'>
											{{ subCategory.name }}
										</p>
									</div>
								</ng-container>
							</ng-container>
						</div>
					</ng-container>
					<div
						*ngIf='workView.description || workView.resultDescription'
						class='pd-work-description'
					>
						<!-- WorkDescription -->
						<p
							*ngIf='workView.description'
							[innerHTML]='(workView.description | appPlainTextFormat)'
						></p>
						<!-- WorkResultDescription -->
						<p
							*ngIf='workView.resultDescription'
							[innerHTML]='(workView.resultDescription | appPlainTextFormat)'
						></p>
					</div>
				</div>

				<app-work-all-files
					*ngIf='isExecutor()'
					[workView]='workView'
				>
				</app-work-all-files>
				<!-- History -->
				<ng-container *ngIf='isFreelance'>
					<ng-container *ngIf='!isFinished; else mark'>
						<pdw-work-history
							*ngIf='isActiveUserAssigned(workView) | async'
							[workView]='workView'
							(changed)='onChanged($event)'
							(edit)='onEdit($event)'
						>
						</pdw-work-history>
					</ng-container>
				</ng-container>
				<ng-template #mark>
					<!--  WorkMarks -->
					<app-work-marks-specialist
						[workView]='workView'
						[isActiveUserAssigned]='isActiveUserAssigned(workView) | async'
						(changed)='onChanged($event)'
					>
					</app-work-marks-specialist>
				</ng-template>
				<ng-container *ngIf='isRequested(workView) | async'>
					<ng-container *ngIf='(utilsService.mobileQuery980px | async)'>
						<div
							*ngIf='specialistExecutorRequest'
							class='pd-work-plate-chat'
						>
							<pdw-work-details-chat-specialist
								[workView]='workView'
							>
							</pdw-work-details-chat-specialist>
						</div
						>
					</ng-container>
				</ng-container>
			</div>
			<div class='pd-work-side-column left-column'>
				<!--  WorkStateChange    -->
				<div
					class='pd-work-plate-states'
					[ngClass]="{'finished' : isFinished}"
				>
					<ng-container *ngIf='!isFinished; else finishState'>
						<mat-expansion-panel
							class='pd-expansion-panel pd-work-state-panel'
							[hideToggle]='true'
							[expanded]='true'
						>
							<mat-expansion-panel-header>
								<h2 class='pd-work-title-text'>Статус работы</h2>
								<button
									class='pd-icon-button'
									mat-icon-button
								>
									<mat-icon
										class='pd-icon pd-profile-block-toggle-icon'
										svgIcon='additional-chevron-down'
									>
									</mat-icon>
								</button>
							</mat-expansion-panel-header>
							<pdw-work-states [workView]='workView'>
								<pdw-work-state-change-specialist
									[workView]='workView'
									[isActiveUserAssigned]='isActiveUserAssigned(workView) | async'
									(changed)='onChanged($event)'
								>
								</pdw-work-state-change-specialist>
							</pdw-work-states>
						</mat-expansion-panel>
					</ng-container>
					<ng-template #finishState>
						<pdw-work-state
							[value]='lastState.value'
							[isActiveUserAssigned]='isActiveUserAssigned(workView) | async'
							[type]="WORK_STATE.ABSTRACT_FINISHED"
						></pdw-work-state>
						<p class='pd-work-last-state-date'>{{lastState.createTime | date:'dd.MM.YYYY'}}</p>
					</ng-template>

				</div>

				<ng-container *ngIf='isRequested(workView) | async'>
					<ng-container *ngIf='!(utilsService.mobileQuery980px | async)'>
						<div
							*ngIf='specialistExecutorRequest'
							class='pd-work-plate-chat'
						>
							<pdw-work-details-chat-specialist
								[workView]='workView'
							>
							</pdw-work-details-chat-specialist>
						</div>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</ng-container>
</ng-container>
<ng-template #noAuthWorkDetails>
	<ng-container *ngIf='currentWorkView | async as workView'>
		<div class='pd-work'>
			<div class='pd-work-main-column'>
				<div class='pd-work-plate-info'>
					<div class='pd-work-title-wrapper'>
						<h1 class='pd-work-title-text'>{{workView.name}}</h1>
					</div>
					<!--Cost, Dates, Company-->
					<div class='pd-work-preamble'>
						<div class='pd-work-company-row'>
							<ng-container *ngIf='!(utilsService.mobileQuery980px | async); else mobileTemplate'>
								<app-rating-one-small [percent]='workView.company.ratingPercent'>
								</app-rating-one-small>
								<div class='pd-work-company-container'>
									<a
										[routerLink]="['/companies', workView.company.id]"
										class='pd-work-company-link'
									>
										{{workView.company.name}}
									</a>
									<ng-container *ngIf='isFreelance'>
										<span class='pd-work-company-slash'>/</span>
										<p
											class='pd-work-company-dates'
										>
											{{ workView.startDate ? (workView.startDate | date:'dd.MM.YYYY') : '--'}} -
											{{ workView.endDate ? (workView.endDate | date:'dd.MM.YYYY') : '--'}}
										</p>
									</ng-container>
									<span class='pd-work-company-slash'>/</span>
									<p class='pd-work-company-dates'>
										{{ workView?.city ? workView.city.name : 'не указан' }}
									</p>
								</div>

							</ng-container>
							<ng-template #mobileTemplate>
								<div class='pd-work-company-container'>
									<app-rating-one-small [percent]='workView.company.ratingPercent'>
									</app-rating-one-small>
									<a
										[routerLink]="['/companies', workView.company.id]"
										class='pd-work-company-link'
									>
										{{workView.company.name}}
									</a>
								</div>
								<span class='pd-work-company-slash'>/</span>
								<p
									*ngIf='isFreelance'
									class='pd-work-company-dates'
								>
									{{ workView.startDate ? (workView.startDate | date:'dd.MM.YYYY') : '--'}} -
									{{ workView.endDate ? (workView.endDate | date:'dd.MM.YYYY') : '--'}}
								</p>
							</ng-template>
						</div>
						<pdw-work-cost
							[work]='workView'
							[plateView]='false'
						></pdw-work-cost>
					</div>

					<div>
						<p
							class='work-types'
							*ngIf='workView.workFormat'
						>
							{{workView.workFormat.name}}
						</p>
						<p
							class='work-types'
							*ngIf='workView.workType'
						>
							{{workView.workType.name}}
						</p>
					</div>
					<ng-container *ngIf='workView.categories.length || workView.subCategories.length;'>
						<div
							class='pd-profile-skills-wrapper'
							*ngIf='groupedCategoriesWithSubCategories | async as arr'
						>
							<ng-container *ngFor='let category of arr'>
								<div *ngIf="category.categoryName"
									class='pd-skill-inner category'
									[matTooltip]="'Категория работы'"
								>
									<p class='pd-skill-text'>
										{{ category.categoryName }}
									</p>
								</div>
								<ng-container *ngFor='let subCategory of category.group'>
									<div
										class='pd-skill-inner'
										[matTooltip]="'Тип работы'"
									>
										<p class='pd-skill-text'>
											{{ subCategory.name }}
										</p>
									</div>
								</ng-container>
							</ng-container>
						</div>
					</ng-container>
					<div
						*ngIf='workView.description || workView.resultDescription'
						class='pd-work-description'
					>
						<!-- WorkDescription -->
						<p
							*ngIf='workView.description'
							[innerHTML]='(workView.description | appPlainTextFormat)'
						></p>
						<!-- WorkResultDescription -->
						<p
							*ngIf='workView.resultDescription'
							[innerHTML]='(workView.resultDescription | appPlainTextFormat)'
						></p>
					</div>
				</div>


				<app-work-all-files
					*ngIf='isExecutor()'
					[workView]='workView'
				>
				</app-work-all-files>
				<!-- History -->
				<ng-container *ngIf='!isFinished; else mark'>
					<pdw-work-history
						*ngIf='isActiveUserAssigned(workView) | async'
						[workView]='workView'
						(changed)='onChanged($event)'
						(edit)='onEdit($event)'
					>
					</pdw-work-history>
				</ng-container>
				<ng-template #mark>
					<!--  WorkMarks -->
					<app-work-marks-specialist
						[workView]='workView'
						[isActiveUserAssigned]='isActiveUserAssigned(workView) | async'
						(changed)='onChanged($event)'
					>
					</app-work-marks-specialist>
				</ng-template>
				<ng-container *ngIf='isRequested(workView) | async'>
					<ng-container *ngIf='(utilsService.mobileQuery980px | async)'>
						<div
							class='pd-work-plate-chat'
							*ngIf='specialistExecutorRequest'
						>
							<pdw-work-details-chat-specialist
								[workView]='workView'
							>
							</pdw-work-details-chat-specialist>
						</div>
					</ng-container>
				</ng-container>
			</div>
			<div class='pd-work-side-column'>
				<!--  WorkStateChange    -->
				<div
					class='pd-work-plate-states'
					[ngClass]="{'finished' : isFinished}"
				>
					<ng-container *ngIf='!isFinished; else finishState'>
						<mat-expansion-panel
							class='pd-expansion-panel pd-work-state-panel'
							[hideToggle]='true'
							[expanded]='true'
						>
							<mat-expansion-panel-header>
								<h2 class='pd-work-title-text'>Статус работы</h2>
								<button
									class='pd-icon-button'
									mat-icon-button
								>
									<mat-icon
										class='pd-icon pd-profile-block-toggle-icon'
										svgIcon='additional-chevron-down'
									>
									</mat-icon>
								</button>
							</mat-expansion-panel-header>
							<pdw-work-states
								[workView]='workView'
								(addRequestEvt)='onAddRequest()'
							>
							</pdw-work-states>
						</mat-expansion-panel>
					</ng-container>
					<ng-template #finishState>
						<pdw-work-state
							[value]='lastState.value'
							[isActiveUserAssigned]='isActiveUserAssigned(workView) | async'
							[type]="WORK_STATE.ABSTRACT_FINISHED"
						></pdw-work-state>
						<p class='pd-work-last-state-date'>{{lastState.createTime | date:'dd.MM.YYYY'}}</p>
					</ng-template>
				</div>

				<ng-container *ngIf='isRequested(workView) | async'>
					<ng-container *ngIf='!(utilsService.mobileQuery980px | async)'>
						<div
							*ngIf='specialistExecutorRequest'
							class='pd-work-plate-chat'
						>
							<pdw-work-details-chat-specialist
								[workView]='workView'
							>
							</pdw-work-details-chat-specialist>
						</div>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</ng-container>
</ng-template>
