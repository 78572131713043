import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'cutThousand'
})
export class CutThousandPipe implements PipeTransform {
  /**
   * Оберзает число больше 1000, до 1suffix
   * @param suffix
   * @param fractionDigits количество цифр после запятой
   */
  transform(value: number, suffix: string = 'к', fractionDigits: number = 1): string | number {
    if(value >= 1000){
      return (value / 1000).toFixed(fractionDigits) + suffix;
    }
    return value;
  }
}
