<ng-container *ngIf='isLoad | async; else loading'>
	<tui-scrollbar class='pd-scrollbar pd-chat-requests-scrollbar'>
		<mat-table
			[dataSource]='dataSource'
			class=''
		>
			<!-- Title -->
			<ng-container matColumnDef='title'>
				<mat-cell
					(click)='selectingWorkRequest(element)'
					class='pd-chat-requests-cell'
					*matCellDef='let element; let i = index'
				>
					<pdw-work-requests-in-work-item
						[workRequestView]='element'
						[currentWorkRequest]='currentWorkRequest'
						[user]='user'
						class='pd-chat-requests-cell-wrapper'
					>
					</pdw-work-requests-in-work-item>
				</mat-cell>
			</ng-container>
			<mat-row
				*matRowDef="let row; columns: ['title']"
				class='pd-chat-requests-row'
			></mat-row>
		</mat-table>
	</tui-scrollbar>
</ng-container>
<ng-template #loading>
	<div class='pd-chat-loading'>
		<mat-spinner
			[strokeWidth]='2'
			[diameter]='30'
		></mat-spinner>
	</div>
</ng-template>
