import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbstractModelService } from '@core/services/abstract-model.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NotificationCounts } from '@models/notification-counts';
import { map } from 'rxjs/operators';
import { NotificationView } from '@models/notification-view';

export enum NotificationItemType {
	primary,
	success,
	danger,
	warning,
	information
}

export class NotificationItem {
	public message: string;
	public type: NotificationItemType;

	static make(message: string, type: NotificationItemType): NotificationItem {
		const result = new NotificationItem();
		result.message = message;
		result.type = type;
		return result;
	}
}

@Injectable({
	providedIn: 'root'
})
export class NotificationsRequestService {
	public notificationChatMessageRead = new EventEmitter<number>();
	private notificationsUrl = 'api/Notifications';

	constructor(
		public snackBar: MatSnackBar,
		private zone: NgZone,
		private http: HttpClient,
		private modelService: AbstractModelService) {
	}

	/**
	 * Преобразование string в Date после десериализации
	 * @param notificationView уведомление
	 */
	public static parseDates(notificationView: NotificationView): NotificationView {
		if (notificationView) {
			notificationView.createTime = new Date(notificationView.createTime);
		}
		return notificationView;
	}

	count(): Observable<NotificationCounts> {
		const url = `${this.notificationsUrl}/Counts`;
		return this.http.get<NotificationCounts>(url, this.modelService.httpOptions);
	}

	/**
	 * Возвращает список прочитанных уведомлений
	 */
	read(pageIndex = 0, pageSize = 0): Observable<NotificationView[]> {
		const url = `${this.notificationsUrl}/Read`;
		return this.http.post<NotificationView[]>(url, null, {
				params: new HttpParams()
					.set('pageIndex', pageIndex.toString())
					.set('pageSize', pageSize.toString()),
				headers: this.modelService.httpOptions.headers
			})
			.pipe(
				map(xx => xx.map(x => NotificationsRequestService.parseDates(x))),
			);
	}

	readCount(): Observable<number> {
		const url = `${this.notificationsUrl}/ReadCount`;
		return this.http.get<number>(url, this.modelService.httpOptions);
	}

	/**
	 * Возвращает список непрочитанных уведомлений
	 */
	unread(pageIndex = 0, pageSize = 0): Observable<NotificationView[]> {
		const url = `${this.notificationsUrl}/Unread`;
		return this.http.post<NotificationView[]>(url, null, {
				params: new HttpParams()
					.set('pageIndex', pageIndex.toString())
					.set('pageSize', pageSize.toString()),
				headers: this.modelService.httpOptions.headers
			})
			.pipe(
				map(xx => xx.map(x => NotificationsRequestService.parseDates(x))),
			);
	}

	unreadCount(): Observable<number> {
		const url = `${this.notificationsUrl}/UnreadCount`;
		return this.http.get<number>(url, this.modelService.httpOptions);
	}

	/**
	 * Отмечает полученным уведомление
	 * @param id идентификатор уведомления
	 */
	makeAsRead(id: number): Observable<any> {
		const url = `${this.notificationsUrl}/MakeAsRead/${id}`;
		return this.http.get(url, this.modelService.httpOptions);
	}
}
