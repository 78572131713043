import {Component, Input, OnInit} from '@angular/core';
import {AppUserView, AppUserWorkState} from "@models/app-user-view";
import {BehaviorSubject} from "rxjs";
import {filter} from "rxjs/operators";
import {AppUserService} from "@core/services/app-user.service";

@Component({
  selector: 'app-app-user-work-state',
  templateUrl: './app-user-work-state.component.html',
  styleUrls: ['./app-user-work-state.component.scss']
})
export class AppUserWorkStateComponent implements OnInit {

  @Input('currentUserView')
  set currentUserView(value: AppUserView) {
    this._currentUserView.next(value);
  };
  get currentUserView(): AppUserView {
    return this._currentUserView.getValue();
  }
  private _currentUserView = new BehaviorSubject<AppUserView>(null);
  currentAppUserView = new BehaviorSubject<AppUserView>(null);
  values: AppUserWorkState[] = [];
  workStateColorClass: (value: number) => {};

  constructor() { }

  ngOnInit(): void {
    this._currentUserView
      .pipe(
        filter(x => x !== null),
      )
      .subscribe((appUserView: AppUserView) => {
        this.currentAppUserView.next(appUserView);
        this.fillValues(appUserView);
      });

    this.workStateColorClass = AppUserService.WorkStateColorClass;
  }

  fillValues(appUserView: AppUserView): void {
    if (AppUserService.hasAppUserWorkState(appUserView.workState, AppUserWorkState.inWork)) {
      this.values.push(AppUserWorkState.inWork);
    }
    if (AppUserService.hasAppUserWorkState(appUserView.workState, AppUserWorkState.invited)) {
      this.values.push(AppUserWorkState.invited);
    }
    if (AppUserService.hasAppUserWorkState(appUserView.workState, AppUserWorkState.requested)) {
      this.values.push(AppUserWorkState.requested);
    }
    if (AppUserService.hasAppUserWorkState(appUserView.workState, AppUserWorkState.inSearch) &&
      !this.values.find(x => x == AppUserWorkState.inWork)) {
      this.values.unshift(AppUserWorkState.inSearch);
    }
  }
}
