export const HomeMenuTitles = {
  icon: 'home',
  primaryTitleItems: 'Главное меню',
  descriptionItems: 'Главное меню пользователя',
};
export const DashboardMenuTitles = {
  icon: 'dashboard',
  primaryTitleItems: 'Информация',
  descriptionItems: 'Сводная информационная панель',
};
export class Dashboard {
}
