import {NoSortAndFilterDataSource} from "@core/dataSources/no-sort-and-filter-data-source";
import {WorkView} from "@models/work-view";
import {BehaviorSubject, Observable, tap} from "rxjs";
import {AppUserView} from "@models/app-user-view";
import {CollectionViewer} from "@angular/cdk/collections";
import {WorkService} from "@core/services/work.service";
import {CategoryView} from "@models/category-view";

export class AppUserCategoryWorksDataSource implements NoSortAndFilterDataSource<WorkView> {
  private workViewsSubject = new BehaviorSubject<WorkView[]>([]);
  private countSubject = new BehaviorSubject<number>(0);

  constructor(
    private appUserView: AppUserView,
    private categoryView: CategoryView,
    private workService: WorkService
  ) {}
  data = this.workViewsSubject.asObservable();
  count = this.countSubject.asObservable();

  connect(collectionViewer: CollectionViewer): Observable<WorkView[]> {
    return this.workViewsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer) {
    this.workViewsSubject.complete();
    this.countSubject.complete();
  }

  load(pageIndex: number, pageSize: number): Observable<WorkView[]>{
    return this.workService.ofUserCategory(this.appUserView.id, this.categoryView.id, pageIndex, pageSize)
      .pipe(
        tap(workViews => this.workViewsSubject.next(workViews))
      );
  }

  loadMore(pageIndex: number, pageSize: number): Observable<WorkView[]>{
    return this.workService.ofUserCategory(this.appUserView.id, this.categoryView.id, pageIndex, pageSize)
      .pipe(
        tap(workViews => {
          const tmp = this.workViewsSubject.value;
          const newData = workViews.filter(x => !tmp.some(w => w.id === x.id));

          this.workViewsSubject.next(tmp.concat(newData));
        })
      );
  }

  loadCount(): Observable<number> {
    return this.workService.ofUserCategoryCount(this.appUserView.id, this.categoryView.id)
      .pipe(
        tap(count => this.countSubject.next(count))
      );
  }
}
