import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
	AbstractControl,
	AsyncValidatorFn,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from "@angular/forms";
import { AppUserDialogData, AppUserGender, AppUserView } from "@models/app-user-view";
import { AppUserService } from "@core/services/app-user.service";
import { AbstractComponentDirective } from "@shared/abstract-component.directive";
import { catchError, filter, map, startWith, switchMap, takeUntil, tap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { errorTitle } from "@utils/helpers/error-helpers";
import { MaterialCalendarHeaderComponent } from "@shared/material-calendar-header/material-calendar-header.component";
import { SubCategoryView } from "@models/sub-category-view";
import { SubCategoryService } from "@core/services/sub-category.service";
import { isMoment } from "moment/moment";
import { diffDate } from "@utils/helpers/date-helpers";
import { WhitespaceValidator } from "@utils/helpers/validator-helpers";
import { DATE_01011900_MS, dateMask, personNamesRegEx } from "@utils/constants";
import { DestroyService, NotificationService } from "@profdepo-ui/core";
import { TrueLoadingService } from "@core/services/true-loading.service";
import { Observable } from "rxjs";
import { CityModel } from "@models/city-model";
import { FiltersService } from "@core/services/filters.service";
import { YesNo } from "@models/enums";
import { WorkFormatView } from "@models/work-format-view";
import { WorkFormatService } from "@core/services/work-format.service";
import { WorkTypeView } from "@models/work-type-view";
import { WorkTypeService } from "@core/services/work-type.service";
import { MaskitoOptions } from "@maskito/core";
import { dateNotEarlierValidator, dateNotLaterValidator } from "@utils/functions";

@Component({
	selector: "pdw-user-personal-dialog",
	templateUrl: "./user-personal-dialog.component.html",
	providers: [DestroyService, TrueLoadingService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ["./user-personal-dialog.component.scss"],
})
export class UserPersonalDialogComponent extends AbstractComponentDirective implements OnInit {
	personalForm: FormGroup;
	calendarHeader = MaterialCalendarHeaderComponent;
	genders = [AppUserGender.Male, AppUserGender.Female];
	maskitoOptions: MaskitoOptions = {
		mask: dateMask,
	};
	workFormats$: Observable<Array<WorkFormatView>>;
	workTypes$: Observable<Array<WorkTypeView>>;

	constructor(
		public dialogRef: MatDialogRef<AppUserDialogData, boolean | AppUserView>,
		@Inject(MAT_DIALOG_DATA) public data: AppUserDialogData,
		private formBuilder: FormBuilder,
		private appUserService: AppUserService,
		private subCategoryService: SubCategoryService,
		private notificationService: NotificationService,
		private destroy$: DestroyService,
		public loading$: TrueLoadingService,
		private cdr: ChangeDetectorRef,
		private filtersService: FiltersService,
		private workFormatService: WorkFormatService,
		private workTypeService: WorkTypeService
	) {
		super();

		this.personalForm = this.formBuilder.group({
			firstname: new FormControl(this.data.appUserView.firstname, {
				validators: [
					Validators.maxLength(40),
					Validators.minLength(2),
					Validators.pattern(personNamesRegEx),
					WhitespaceValidator,
				],
			}),
			middlename: new FormControl(this.data.appUserView.middlename, {
				validators: [
					Validators.pattern(personNamesRegEx),
					WhitespaceValidator,
					Validators.minLength(2),
					Validators.maxLength(40),
				],
			}),
			lastname: new FormControl(this.data.appUserView.lastname, {
				validators: [
					Validators.maxLength(40),
					Validators.minLength(2),
					Validators.pattern(personNamesRegEx),
					WhitespaceValidator,
				],
			}),
			birthday: new FormControl({ value: this.data.appUserView.birthday, disabled: this.isInnExists }, [
				this.comingOfAgeValidator(),
				dateNotEarlierValidator(DATE_01011900_MS),
				dateNotLaterValidator(new Date().getTime()),
			]),
			gender: new FormControl(this.data.appUserView.gender),
			city: new FormControl(this.data.appUserView.city),
			phoneNumber: new FormControl(this.data.appUserView.phoneNumber, Validators.minLength(12)),
			email: new FormControl(this.data.appUserView.email, {
				validators: [Validators.email],
			}),
			isReadyToRelocate: [this.data.appUserView.isReadyToRelocate === YesNo.yes],
		});

		this.workFormats$ = this.workFormatService.all().pipe(filter((arr) => Array.isArray(arr)));
		this.workTypes$ = this.workTypeService.all().pipe(filter((arr) => Array.isArray(arr)));
	}

	ngOnInit(): void {
		this.loading$.next(false);

		this.city.valueChanges
			.pipe(
				takeUntil(this.destroy$),
				startWith(this.data.appUserView.city),
				filter((item) => item),
				tap(() => this.loading$.next(true)),
				switchMap((city) => this.filtersService.approveCity(city))
			)
			.subscribe((approvedCity) => {
				this.loading$.next(false);
				this.city.patchValue(approvedCity, { emitEvent: false });
			});
	}

	getCities(query: string = ""): Observable<CityModel[]> {
		return this.filtersService.getCities(query);
	}

	get isInnExists(): boolean {
		return !!this.data.appUserView.inn;
	}

	get firstname(): FormControl {
		return this.personalForm.get("firstname") as FormControl;
	}

	get middlename(): FormControl {
		return this.personalForm.get("middlename") as FormControl;
	}

	get lastname(): FormControl {
		return this.personalForm.get("lastname") as FormControl;
	}

	get birthday(): FormControl {
		return this.personalForm.get("birthday") as FormControl;
	}

	get gender(): FormControl {
		return this.personalForm.get("gender") as FormControl;
	}

	get city(): FormControl {
		return this.personalForm.get("city") as FormControl;
	}

	get phoneNumber(): FormControl {
		return this.personalForm.get("phoneNumber") as FormControl;
	}

	get email(): FormControl {
		return this.personalForm.get("email") as FormControl;
	}

	get userName(): FormControl {
		return this.personalForm.get("userName") as FormControl;
	}

	get isReadyToRelocate(): AbstractControl {
		return this.personalForm.get("isReadyToRelocate");
	}

	get profession(): AbstractControl {
		return this.personalForm.get("profession");
	}

	get preferredReward(): AbstractControl {
		return this.personalForm.get("preferredReward");
	}

	get workType(): AbstractControl {
		return this.personalForm.get("workType");
	}

	compareSubCategoryView(x1: SubCategoryView, x2: SubCategoryView): boolean {
		return x1 && x2 ? x1.id === x2.id : false;
	}

	comingOfAgeValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value) {
				return null;
			}

			const value: Date = isMoment(control.value) ? control.value.toDate() : control.value;
			const diff = diffDate(new Date(Date.now()), value);

			return diff.years >= 18 ? null : { comingOfAge: true };
		};
	}

	onSubmit({ value, valid }): void {
		if (valid) {
			this.loading$.next(true);
			let appUserView = Object.assign({}, this.data.appUserView);
			appUserView = this.filloutRelationships<AppUserView>(appUserView, this.personalForm);

			appUserView = { ...appUserView, isReadyToRelocate: this.isReadyToRelocate.value ? YesNo.yes : YesNo.no };

			this.appUserService
				.updateAppUserView(appUserView)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: (appUserView) => {
						this.loading$.next(false);
						this.dialogRef.close(appUserView);
					},
					error: (err) => {
						this.loading$.next(false);
						if (err instanceof HttpErrorResponse) {
							if (err.status === 400) {
								this.filloutFormError(this.personalForm, err);
								this.phoneNumber.setErrors({ serverError: err.error });
							} else {
								this.notificationService.showDanger(errorTitle(err));
							}
						}
					},
				});
		}
	}

	isTuiInputEmpty(): boolean {
		const input = this.phoneNumber;
		return input.value == null || input.value == "";
	}

	protected readonly YesNo = YesNo;
}
