import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { PublicLayoutComponent } from '@public/components/layouts/public-layout/public-layout.component';
import { AdministratorLayoutComponent } from '@public/components/layouts/administrator-layout/administrator-layout.component';
import { ConfirmEmailComponent } from '@public/pages/confirm-email/confirm-email.component';
import { LandingComponent } from '@public/landing/landing.component';
import { AuthLayoutComponent } from '@public/components/layouts/auth-layout/auth-layout.component';
import { RegisterComponent } from '@public/register/register.component';
import { LoginComponent } from '@public/pages/login/login.component';
import { MarketLayoutComponent } from '@public/components/layouts/market-layout/market-layout.component';
import { ResetPasswordComponent } from '@public/recovery/reset-password/reset-password.component';
import { ForgotPasswordComponent } from '@public/recovery/forgot-password/forgot-password.component';
import { SentConfirmationComponent } from '@public/recovery/sent-confirmation/sent-confirmation.component';
import { TariffsComponent } from '@public/tariffs/tariffs.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { OAuthComponent } from '@public/pages/oauth/oauth.component';
import { RolePermissionGuard } from '@core/guards/role-permission.guard';
import { AppUserType } from '@models/app-user-view';
import { NotFoundPageComponent } from '@public/pages/not-found-page/not-found-page.component';
import { SalaryRegisterPageComponent } from '@public/pages/salary-register-page/salary-register-page.component';
import { SpecialistLandingComponent } from '@public/pages/specialist-landing/specialist-landing.component';
import { CustomerLandingComponent } from '@public/pages/customer-landing/customer-landing.component';
import { JobSearchLandingComponent } from '@public/pages/job-search-landing/job-search-landing.component';
import { JobSearchGuard } from '@core/guards/job-search.guard';
import { JobSearchEngeneeringLandingComponent } from '@public/pages/job-search-engeneering-landing/job-search-engeneering-landing.component';

const routes: Routes = [
	{
		path: 'confirmEmail',
		component: AuthLayoutComponent,
		data: { method: 'confirm-email' },
		children: [
			{
				path: '',
				component: ConfirmEmailComponent,
			}
		]
	},
	{
		path: '404',
		component: NotFoundPageComponent
	},
	{
		path: 'salary/reg',
		component: SalaryRegisterPageComponent,
		pathMatch: 'prefix',
		canActivate: [RolePermissionGuard],
		data: {
			permissions: [AppUserType.none]
		}
	},
	{
		path: '',
		component: PublicLayoutComponent,
		children: [
			{
				path: '',
				component: LandingComponent
			},
			{
				path: 'comp',
				component: CustomerLandingComponent
			}
		]
	},
	{
		path: 'register',
		component: AuthLayoutComponent,
		canActivate: [AuthGuard],
		data: { method: 'register' },
		children: [
			{
				path: '',
				component: RegisterComponent
			}
		]
	},
	{
		path: 'login',
		component: AuthLayoutComponent,
		canActivate: [AuthGuard],
		data: { method: 'login' },
		children: [
			{
				path: '',
				component: LoginComponent
			}
		]
	},
	{
		path: 'forgotPassword',
		component: AuthLayoutComponent,
		data: { method: 'forgot-password' },
		children: [
			{
				path: '',
				component: ForgotPasswordComponent
			}
		]
	},
	{
		path: 'resetPassword',
		component: AuthLayoutComponent,
		data: { method: 'reset-password' },
		children: [
			{
				path: '',
				component: ResetPasswordComponent
			}
		]
	},
	{
		path: 'sentConfirmation',
		component: AuthLayoutComponent,
		data: { method: 'sent-confirmation' },
		children: [
			{
				path: '',
				component: SentConfirmationComponent
			}
		]
	},
	{
		path: 'tariffs',
		component: PublicLayoutComponent,
		children: [
			{
				path: '',
				component: TariffsComponent
			}
		]
	},
	{
		path: 'oauth',
		children: [
			{
				path: 'google',
				component: OAuthComponent,
				data: { provider: 'Google' }
			},
			{
				path: 'vk',
				component: OAuthComponent,
				data: { provider: 'Vk' }
			},
			{
				path: 'yandex',
				component: OAuthComponent,
				data: { provider: 'Yandex' }
			}
		]
	},
	{
		path: '',
		component: MarketLayoutComponent,
		loadChildren: () => import('./general/general.module')
			.then(m => m.GeneralModule)
	},
	{
		path: 'administrator',
		component: AdministratorLayoutComponent,
		canActivate: [RolePermissionGuard],
		data: {
			permissions: [AppUserType.administrator]
		},
		loadChildren: () => import('./administrator/administrator.module')
			.then(m => m.AdministratorModule),
	},
	{
		path: 'spec',
		component: PublicLayoutComponent,
		children: [{
			path: '',
			component: SpecialistLandingComponent
		}]
	},
	{
		path: 'jobSearch',
		component: JobSearchLandingComponent,
		canActivate: [JobSearchGuard],
		data: {
			permissions: [ AppUserType.none]
		},
	},
	{
		path: 'jobSearch_engeneering',
		component: JobSearchEngeneeringLandingComponent,
		canActivate: [JobSearchGuard],
		data: {
			permissions: [ AppUserType.none]
		},
	},
	{
		path: '**',
		redirectTo: '404',
		pathMatch: 'full'
	},
];

const routerOptions: ExtraOptions = {
	useHash: false,
	// anchorScrolling: 'enabled',  //  заменено кодом для плавной прокрутки в public-layout.component.ts
	onSameUrlNavigation: 'reload',
	relativeLinkResolution: 'legacy',
	// scrollPositionRestoration: 'enabled',
	// enableTracing: true  //  for debug router
};

@NgModule({
	imports: [RouterModule.forRoot(routes, routerOptions)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
