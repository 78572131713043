import {Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {SkillStateValue, AbstractSkillView} from "@models/abstract-skill-view";
import {HardSkillService} from "@core/services/hard.skill.service";
import {filter, switchMap, takeUntil, tap} from "rxjs/operators";
import {MatTableDataSource} from "@angular/material/table";
import {SkillStateView} from "@models/skill-state-view";
import {MatSort} from "@angular/material/sort";
import {Router} from "@angular/router";
import {AppUserTypeValuePipe} from "@public/pipes/app-user-type-value.pipe";
import {ThemePalette} from "@angular/material/core";
import {AbstractSkillService} from "@core/services/abstract-skill.service";

@Component({
  selector: 'app-skill-states',
  templateUrl: './skill-states.component.html',
})
export class SkillStatesComponent implements OnDestroy {
  @Input('skillView')
  set skillView(value: AbstractSkillView) {
    this._skillView.next(value);
  };
  get skillView(): AbstractSkillView {
    return this._skillView.getValue();
  }
  private _skillView = new BehaviorSubject<AbstractSkillView>(null);
  @Input() skillService: AbstractSkillService;
  dataSource: MatTableDataSource<SkillStateView>;
  initDisplayedColumns = [
    'value',
    'createAppUser',
    'createtime',
    'isLast'
  ];
  displayedColumns = new BehaviorSubject<string[]>(this.initDisplayedColumns);
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  appUserTypeValuePipe = new AppUserTypeValuePipe();
  unsubscribe: Subject<any> = new Subject<any>();
  constructor(
    private router: Router,
  ) {
    this._skillView
      .pipe(
        filter(data => data !== null),
        switchMap(skillView => this.skillService.states(skillView.id)
          .pipe(
            takeUntil(this.unsubscribe)
          )
        )
      )
      .subscribe(skillStateViews => {
        this.dataSource = new MatTableDataSource(skillStateViews);
        this.dataSource.sort = this.sort;
      });
  }
  skillStateColor(state: SkillStateValue): ThemePalette | null {
    return this.skillService.skillStateColor(state) as ThemePalette;
  }
  goApprove() {
    this.goStateChange(SkillStateValue.approved);
  }
  goDecline() {
    this.goStateChange(SkillStateValue.declined);
  }
  goStateChange(stateValue: SkillStateValue) {
    this.skillService.stateChange(this.skillView.id, stateValue)
      .pipe(
        takeUntil(this.unsubscribe),
        filter(result => result),
        tap(() => this.skillService.clearSkillView(this.skillView.id)),
        switchMap(() => this.skillService.getSkillView(this.skillView.id)
          .pipe(
            takeUntil(this.unsubscribe),
          ))
      )
      .subscribe(skillView => {
        this.skillView = skillView;
      });
  }
  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }
}
