import { AbstractControl, FormArray, FormGroup } from "@angular/forms";
import { toLowerCaseFirstLetter } from "@utils/helpers/string-helpers";
import { HttpErrorResponse } from "@angular/common/http";
import { errorMap } from "./helpers/error-helpers";

export function filloutRelationships<T>(model: T, form: FormGroup) {
	Object.keys(form.getRawValue()).forEach((key) => {
		// console.warn('key 1: ', key);
		const formGroup = form.get(key) as FormGroup;
		// console.warn('formGroup: ', key, formGroup, model);
		if (formGroup.controls) {
			model[key] = this.filloutRelationships(model[key], formGroup);
		} else {
			// console.warn('key 2: ', key, formGroup, form.get(key), form.value[key], model);//, model[key]);
			model[key] = form.get(key).disabled ? model[key] : form.value[key];
		}
	});
	return model;
}

function getFormControl(form: FormGroup, prop: string): AbstractControl {
	const subProps = prop.split(".");
	let control = form;
	subProps.forEach((v) => {
		const m = v.match(/(\w+)\[(\d+)\]|(\w+)/);
		control =
			m[1] && m[2]
				? (control.get(toLowerCaseFirstLetter(m[1])) as FormArray).controls[m[2]]
				: control.get(toLowerCaseFirstLetter(m[3]));
	});
	return control;
}

export function filloutFormError(form: FormGroup, err: HttpErrorResponse, ignore: string[] = []) {
	const e = err.error.errors ? err.error.errors : err.error;

	if (typeof e === 'string' && errorMap(e)) {
		const formControl = getFormControl(form, errorMap(e));
		if (formControl) {
			formControl.setErrors({
				serverError: e,
			});
		}
	}
	
	Object.keys(e)
		.filter((prop) => !ignore.some((x) => prop.startsWith(x)))
		.forEach((prop) => {
			const formControl = getFormControl(form, prop);
			if (formControl) {
				// activate the error message
				formControl.setErrors({
					serverError: e[prop],
				});
			}
		});
}

export function filloutFormErrorByPropName(form: FormGroup, propName: string) {
	const formControl = getFormControl(form, propName);
	if (formControl) {
		formControl.setErrors({
			serverEmailError: true,
		});
	}
}
