<ng-container *ngIf='currentAppUserView | async as currentAppUser'>
	<div
		class='pd-profile-rating-content'
		[ngClass]="{'no-rating': !(currentAppUser.ratingPercent > 0)}"
	>
		<div class='pd-profile-inner-column'>
			<ng-container *ngIf='currentAppUser.ratingPercent > 0 else noRating'>
				<h2 class='pd-profile-rating-title pd-profile-title'>Рейтинг
					<app-rating-one-small
						class='pd-profile-rating-one-small'
						[percent]='currentAppUser.ratingPercent'
					>
					</app-rating-one-small>
				</h2>
				<div class='pd-profile-rating-row'>
					<app-rating-stars
						[showRating]='false'
						[stars]='currentAppUser.qualityRatingPercent / 100 * 5'
						[rating]='currentAppUser.qualityRatingPercent'
					>
					</app-rating-stars>
					<p class='pd-profile-text'>
						{{ 'qualityValue' | workExecutorMark:'propertyTitle' }}
					</p>
				</div>
				<div class='pd-profile-rating-row'>
					<app-rating-stars
						[showRating]='false'
						[stars]='currentAppUser.speedRatingPercent / 100 * 5'
						[rating]='currentAppUser.speedRatingPercent'
					>
					</app-rating-stars>
					<p class='pd-profile-text'>
						{{ 'speedValue' | workExecutorMark:'propertyTitle' }}
					</p>
				</div>
				<div class='pd-profile-rating-row'>
					<app-rating-stars
						[showRating]='false'
						[stars]='currentAppUser.experienceRatingPercent / 100 * 5'
						[rating]='currentAppUser.experienceRatingPercent'
					>
					</app-rating-stars>
					<p class='pd-profile-text'>
						{{ 'experienceValue' | workExecutorMark:'propertyTitle' }}
					</p>
				</div>
			</ng-container>
			<ng-template #noRating>
				<h2 class='pd-profile-rating-title pd-profile-title'>Рейтинг
					<app-rating-one-small
						class='pd-profile-rating-one-small'
						[percent]='currentAppUser.ratingPercent'
					>
					</app-rating-one-small>
				</h2>
				<h2 class='pd-profile-subtitle pd-profile-no-rating-title'>Рейтинга пока нет</h2>
				<p class='pd-profile-text'>Скоро он появится здесь</p>
				<div class="pd-profile-no-successful-works-wrapper">
					<div class="pd-profile-no-successful-works-title">Успешно завершено:</div>
					<mat-icon
						class='pd-icon light-gray'
						svgIcon='additional-alert-question-1'
						[matTooltipPosition]="'right'"
						[matTooltip]="'Скоро здесь будет подсказка'"
					>
					</mat-icon>
				</div>
			</ng-template>
		</div>
		<app-rating-one-big
			class='pd-profile-rating-one-big'
			[rating]='currentAppUser.ratingPercent / 100 * 5'
		>
		</app-rating-one-big>
	</div>
</ng-container>
