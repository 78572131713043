import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'convertArrayToString'
})
export class ConvertArrayToStringPipe<T> implements PipeTransform {

	transform(value: T[], key: string, separator: string = '', whenEmpty?: string): string {
		if (!value?.length || !value[0][key]) {
			return whenEmpty ?? ''
		}
		return value.map(value => value[key]).join(separator);
	}

}
