import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(
			request.clone({
				url:
					request.url.startsWith('https') || request.url.startsWith('/') || request.url.startsWith('icons')
						? request.url
						: [environment.baseUrl, request.url].join('/'),
			})
		);
	}
}
