import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit
} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from "@angular/material/core";
import {MatCalendar} from "@angular/material/datepicker";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

export const yearsPerPage = 24;

export const PD_CALENDAR_HEADER_TYPE = new InjectionToken<calendarHeaderType>('')

export type calendarHeaderType = 'full' | 'without-days';

export const CALENDAR_HEADER_FULL: calendarHeaderType = 'full';
export const CALENDAR_HEADER_WITHOUT_DAYS: calendarHeaderType = 'without-days';

@Component({
  selector: 'app-material-calendar-header',
  templateUrl: './material-calendar-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialCalendarHeaderComponent<D> implements OnInit, OnDestroy {

  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    @Inject(PD_CALENDAR_HEADER_TYPE) private headerType: calendarHeaderType,
    cdr: ChangeDetectorRef,
  ) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
  }

  onToggleClick() {
    if (this.headerType === 'without-days'){
      this.monthYearToggle();
    } else {
      this.fullToggle();
    }
  }

  fullToggle() {
    if (this._calendar.currentView === 'month') {
      this._calendar.currentView = 'multi-year';
    } else if (this._calendar.currentView === 'multi-year') {
      this._calendar.currentView = 'year';
    } else {
      this._calendar.currentView = 'month';
    }
  }

  monthYearToggle() {
    if (this._calendar.currentView === 'year') {
      this._calendar.currentView = 'multi-year';
    } else {
      this._calendar.currentView = 'year';
    }
  }

    previousClicked(): void {
      this._calendar.activeDate =
        this._calendar.currentView == 'month'
          ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
          : this._dateAdapter.addCalendarYears(
            this._calendar.activeDate,
            this._calendar.currentView == 'year' ? -1 : -yearsPerPage,
          );
    }

    nextClicked(): void {
      this._calendar.activeDate =
        this._calendar.currentView == 'month'
          ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
          : this._dateAdapter.addCalendarYears(
            this._calendar.activeDate,
            this._calendar.currentView == 'year' ? 1 : yearsPerPage,
          );
    }

  ngOnInit(): void {
  }

}
