import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CompanyView } from '@models/company-view';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { CompanyService } from '@core/services/company.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { WorkService } from '@core/services/work.service';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { AppUserService } from '@core/services/app-user.service';
import { RoleService } from '@core/services/role.service';
import { AppUserType } from '@models/app-user-view';
import { YesNo } from '@models/enums';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { AuthDialogComponent } from '@shared/auth-dialog/auth-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'pdw-company-details',
	templateUrl: './company-details.component.html',
	host: { 'class': 'pd-company pd-expanded-height' },
	providers: [DestroyService],
	styleUrls: ['./company-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyDetailsComponent implements OnInit {
	ownerCompanyViews: CompanyView[] = [];
	subscribed$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);

	@Input()
	set companyView(value: CompanyView) {
		this._companyView.next(value);
	}

	get companyView(): CompanyView {
		return this._companyView.getValue();
	}

	private _companyView = new BehaviorSubject<CompanyView>(null);
	currentCompanyView = new BehaviorSubject<CompanyView>(null);

	constructor(
		private companyService: CompanyService,
		private notificationService: NotificationService,
		private workService: WorkService,
		private destroy$: DestroyService,
		private userService: AppUserService,
		private roleService: RoleService,
		private route: ActivatedRoute,
		public authService: AuthService,
		private dialog: MatDialog,
	) {
	}

	ngOnInit(): void {
		this.route.queryParamMap.subscribe((params: ParamMap) => this.subscribed$.next(Number(params.get('subscribed'))));
		this._companyView
			.pipe(
				takeUntil(this.destroy$),
				filter(x => x !== null)
			)
			.subscribe({
				next: companyView => {
					this.currentCompanyView.next(companyView);
				}
			});
	}

	get canUpdate$(): Observable<boolean> {
		return this.userService.getActiveUserAsObservable()
			.pipe(takeUntil(this.destroy$),
				switchMap(res => of((res?.id === this.companyView?.owner?.id) && this.roleService.hasPermission(AppUserType.client)))
			)

	}

	moreThan1500Symbols(company: CompanyView): boolean {
		return company.description?.length > 1500 ?? false;
	}

	onChanged(event): void {
		this.companyService.clearCompanyView(this.companyView.id);
		this.workService.clearWorkViews();
		this.companyService.changeCurrentCompanyView(this.companyView.id)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
				},
				error: (err: any) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	goSubscribe(event: MouseEvent, companyView: CompanyView): void {
		event.stopPropagation();
		this.companyService.subscribe(companyView.id)
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: () => {
					companyView.subscribed = YesNo.yes;
					this.subscribed$.next(Number(YesNo.yes));
				}
			});
	}

	goUnsubscribe(event: MouseEvent, companyView: CompanyView): void {
		event.stopPropagation();
		this.companyService.unsubscribe(companyView.id)
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: () => {
					companyView.subscribed = YesNo.no;
					this.subscribed$.next(Number(YesNo.no));
					this.companyService.shouldReload.next(true);
				}
			});
	}

	goSubscribeWithoutAuth(event: MouseEvent, companyView: CompanyView): void {
		event.stopPropagation();
		this.dialog.open(AuthDialogComponent, {
			autoFocus: true,
			disableClose: false,
			width: '581px',
			panelClass: ['auth-dialog'],
		}).afterClosed().pipe(filter(Boolean)).subscribe(() => {
			this.goSubscribe(event, companyView);
			localStorage.setItem('currentHref', window.location.href);
			window.location.reload();
		});
	}

	goUnsubscribeWithoutAuth(event: MouseEvent, companyView: CompanyView): void {
		event.stopPropagation();
		this.dialog.open(AuthDialogComponent, {
			autoFocus: true,
			disableClose: false,
			width: '581px',
			panelClass: ['auth-dialog'],
		}).afterClosed().pipe(filter(Boolean)).subscribe(() => {
			this.goUnsubscribe(event, companyView);
			localStorage.setItem('currentHref', window.location.href);
			window.location.reload();
		});
	}

	canSubscribe(companyView: CompanyView): Observable<boolean> {
		return this.userService.getActiveUserAsObservable()
			.pipe(
				filter(x => x !== null),
				map(x => !this.ownerCompanyViews?.some(a => a.id === companyView.id) && x.type === AppUserType.specialist)
			);
	}
}
