<div class='pd-dialog-header'>
	<p class='pd-dialog-header-title'>{{ data.title }}</p>
	<button
		class='pd-icon-button'
		mat-icon-button
		[mat-dialog-close]='false'
	>
		<mat-icon svgIcon='interface-close'></mat-icon>
	</button>
</div>
<tui-scrollbar class='pd-scrollbar pd-dialog-scroll'>
	<div class='pd-dialog-content large-padding'>
		<div *ngIf='workResultFilesForm'>
			<form
				id='workResultFilesForm'
				[formGroup]='workResultFilesForm'
				(ngSubmit)='onSubmit(workResultFilesForm)'
			>
				<div class='pd-dialog-column'>
					<button
						class='pd-button-additional'
						(click)='fileInput.click()'
						type='button'
					>
						<mat-icon
							class='pd-icon black'
							svgIcon='files-attachment'
						></mat-icon>
						Выберите один или несколько файлов
					</button>
					<input
						hidden
						type='file'
						multiple
						(change)='onSelectResultFiles($event)'
						(click)="onResetFileInputValue(fileInput)"
						#fileInput
					/>
					<ng-container *ngIf='resultFiles && resultFiles.length > 0'>
						<div
							formArrayName='resultFiles'
							*ngFor='let workResultFileFormGroup of resultFiles.controls; let i = index'
						>
							<div [formGroupName]='i'>

								<mat-form-field class='pd-input-invisible'>
									<input
										matInput
										formControlName='id'
									>
								</mat-form-field>

								<mat-form-field class='pd-input-invisible'>
									<input
										matInput
										formControlName='work'
										required
									>
								</mat-form-field>

								<mat-form-field class='pd-input-invisible'>
									<input
										matInput
										formControlName='sourceFilename'
									>
								</mat-form-field>

								<mat-form-field class='pd-input-invisible'>
									<input
										matInput
										formControlName='temporaryFilename'
									>
								</mat-form-field>

								<div class='pd-dialog-column'>
									<div class='pd-dialog-column pd-dialog-files-column'>
										<div class='pd-dialog-row pd-dialog-files-row'>
											<div class='pd-dialog-files-file'>
												<p class='pd-dialog-files-name'>{{workResultFilesName(i).value}}</p>
												<mat-progress-bar
													[mode]="workResultFilesUploaded(i).value ? 'determinate' : 'indeterminate'"
													[value]='100'
													[color]="workResultFilesHasNoError(i).value ? 'primary' : 'warn'"
												>
												</mat-progress-bar>
											</div>
											<div class='pd-dialog-row pd-dialog-icon-buttons'>
												<!--Change Item-->
												<button
													type='button'
													class='pd-icon-button'
													mat-icon-button
													(click)='singleFileInput.click()'
												>
													<mat-icon svgIcon='additional2-regroup'></mat-icon>
												</button>
												<input
													hidden
													type='file'
													(change)='onSelectResultFile(i, $event)'
													#singleFileInput
												/>
												<!--Remove Item-->
												<button
													type='button'
													class='pd-icon-button'
													mat-icon-button
													(click)='goRemoveWorkResultFile(i)'
												>
													<mat-icon svgIcon='additional-trash-2'></mat-icon>
												</button>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</ng-container>

				</div>
			</form>
		</div>
	</div>
</tui-scrollbar>
<div
	class='pd-dialog-footer align-end'
	*ngIf='workResultFilesForm'
>
	<div class='pd-dialog-footer-buttons'>
		<button
			mat-raised-button
			class='pd-button-secondary large'
			type='reset'
			[mat-dialog-close]='false'
			(click)="onGoWithOutFiles()"
			[disabled]="disabledGoWithOutFilesButton()"
		>
			Продолжить без файла
		</button>
		<button
			form='workResultFilesForm'
			mat-raised-button
			class='pd-button large'
			type='submit'
			[disabled]='disabledSubmitButton()'
		>
			<ng-container *ngIf='!(loading$ | async); else isSaving'>Продолжить</ng-container>
			<ng-template #isSaving>
				Сохраняем
			</ng-template>
		</button>
	</div>
</div>
