import { Pipe, PipeTransform } from '@angular/core';
import {toLowerCaseFirstLetter} from "@utils/helpers/string-helpers";

@Pipe({
  name: 'lowerCaseFirstLetter'
})
export class LowerCaseFirstLetterPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return toLowerCaseFirstLetter(value);
  }

}
