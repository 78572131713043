import {
	Directive,
	HostListener,
	Output,
	EventEmitter
  } from "@angular/core";  
  
  @Directive({
	selector: "[dragDrop]"
  })
  export class DragDropDirective {
	@Output() files = new EventEmitter();
	@Output() dragoverEvt = new EventEmitter();
	@Output() dragleaveEvt = new EventEmitter();
	@Output() dropEvt = new EventEmitter();
  
    
	@HostListener("dragover", ["$event"]) public onDragOver(e: DragEvent) {
	  this.cancelDefaultBrowserActions(e);
	  this.dragoverEvt.emit();
	}
  
	@HostListener("dragleave", ["$event"]) public onDragLeave(e: DragEvent) {
	  this.cancelDefaultBrowserActions(e);
	  this.dragleaveEvt.emit()
	}
  
	@HostListener('drop', ['$event']) public onDrop(e: DragEvent) {
	  this.cancelDefaultBrowserActions(e);
	  this.dropEvt.emit()
	  const files = e.dataTransfer.files;
	  this.files.emit(files);
	}

	cancelDefaultBrowserActions(e: DragEvent) {
	  e.preventDefault();
	  e.stopPropagation();
	}
  }
