import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Tokens } from '@requests/tokens';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class ServerErrorInterceptor implements HttpInterceptor {
	refreshToken$ = new BehaviorSubject<string | null>(null);
	private isRefreshing = false;

	constructor(private auth: AuthService, private router: Router,
	) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let authReq = req;
		const tokens: Tokens = {
			authToken: this.auth.authToken,
			refreshToken: this.auth.refreshToken,
			expiration: 7000
		};

		if (tokens.authToken) {
			authReq = this.addToken(req, tokens.authToken);
		}

		return next.handle(authReq).pipe(
			catchError(error => {
				if (error instanceof HttpErrorResponse && error.status === 401) {
					return this.handle401Error(authReq, next);
				}

				return throwError(error);
			}),
		);
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshToken$.next(null);

			const token = this.auth.refreshToken;
			if (token) {
				return this.auth.updateTokens().pipe(
					switchMap((token: Tokens) => {
						if (token.authToken !== undefined) {
							this.isRefreshing = false;
							this.auth.storeTokens(token);
							this.refreshToken$.next(token.authToken);
							return next.handle(this.addToken(request, token.authToken));
						}
						return EMPTY;
					}),
					catchError(err => {
						this.isRefreshing = false;
						this.auth.doLogoutUser();
						this.router.navigate(['/login']);
						return throwError(err);
					}),
				);
			} else {
				this.auth.doLogoutUser();
				this.isRefreshing = false;
				this.router.navigate(['/login']);
			}
		}
		return this.refreshToken$.pipe(
			filter(token => token !== null),
			take(1),
			switchMap(token => next.handle(this.addToken(request, token))),
		);
	}

	addToken(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
		const headers = token ? request.headers.set('Authorization', `Bearer ${token}`) : request.headers;
		return request.clone({
			headers,
		});
	}
}

