<div class='pd-dialog-header'>
	<p class='pd-dialog-header-title'>{{ title }}</p>
	<ng-content></ng-content>
	<button
		class='pd-icon-button'
		mat-icon-button
		[mat-dialog-close]='false'
	>
		<mat-icon svgIcon='interface-close'></mat-icon>
	</button>
</div>
