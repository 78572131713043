import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppUserDialogData, AppUserView } from '@models/app-user-view';
import { AppUserService } from '@core/services/app-user.service';
import { AbstractComponentDirective } from '@shared/abstract-component.directive';
import { takeUntil } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { YesNo } from '@models/enums';
import { EducationView } from '@models/education-view';
import { onlySpaceValidator } from '@utils/functions';

@Component({
	selector: 'app-app-user-education-additionals-dialog',
	templateUrl: './app-user-education-additionals-dialog.component.html',
	styleUrls: ['./app-user-education-additionals-dialog.component.scss']
})
export class AppUserEducationAdditionalsDialogComponent extends AbstractComponentDirective implements OnInit {
	educationAdditionalsForm: FormGroup;
	years: number[];
	saving = new BehaviorSubject<boolean>(false);

	constructor(
		public dialogRef: MatDialogRef<AppUserDialogData, boolean | AppUserView>,
		@Inject(MAT_DIALOG_DATA) public data: AppUserDialogData,
		private formBuilder: FormBuilder,
		private appUserService: AppUserService,
		private notificationService: NotificationService,
		private destroy$: DestroyService
	) {
		super();
		this.years = [];
		for (let i = 1950; i <= new Date().getFullYear(); i++) {
			this.years.push(i);
		}
		this.years.reverse();
	}

	ngOnInit(): void {
		this.educationAdditionalsForm = this.formBuilder.group({
			educationAdditionals: this.formBuilder?.array(
				this.data.appUserView?.educations?.filter(item => item.isCourse === YesNo.yes)?.map((v, i, a) => this.createEducationAdditional(i, v)) ?? []
			)
		});

		if (this.educationAdditionals.length == 0) {
			this.goAddEducationAdditional(0);
		}
	}

	get educationAdditionals(): FormArray {
		return this.educationAdditionalsForm.get('educationAdditionals') as FormArray;
	}

	educationAdditionalsName(index: number): FormControl {
		return this.educationAdditionals.controls[index].get('name') as FormControl;
	}

	educationAdditionalsSector(index: number): FormControl {
		return this.educationAdditionals.controls[index].get('sector') as FormControl;
	}

	educationAdditionalsSpeciality(index: number): FormControl {
		return this.educationAdditionals.controls[index].get('speciality') as FormControl;
	}

	educationAdditionalsYear(index: number): FormControl {
		return this.educationAdditionals.controls[index].get('year') as FormControl;
	}

	createEducationAdditional(index: number, educationAdditionalView: EducationView): FormGroup {
		const formGroup = this.formBuilder.group({
			id: new FormControl(educationAdditionalView.id),
			name: new FormControl(educationAdditionalView.name, {
				validators: [Validators.required, Validators.maxLength(500), onlySpaceValidator()],
			}),
			sector: new FormControl(educationAdditionalView.sector, {
				validators: [Validators.required, Validators.maxLength(500), onlySpaceValidator()],
			}),
			speciality: new FormControl(educationAdditionalView.speciality),
			year: new FormControl(educationAdditionalView.year, {
				validators: [Validators.required],
			}),
			isCourse: new FormControl(YesNo.yes)
		});

		return formGroup;
	}

	goAddEducationAdditional(index: number): void {
		const educationAdditionalView = new EducationView();
		educationAdditionalView.id = 0;
		this.educationAdditionals.insert(index, this.createEducationAdditional(index, educationAdditionalView));
		this.educationAdditionalsForm.updateValueAndValidity();
	}

	goRemoveEducationAdditional(index: number): void {
		if (index <= this.educationAdditionals.length - 1) {
			this.educationAdditionals.removeAt(index);
		}
	}

	onSubmit({ value, valid }): void {
		if (valid) {
			this.saving.next(true);
			// copy form values to object
			let appUserView = Object.assign({}, this.data.appUserView);
			const value = this.educationAdditionals.value?.map(item => {
				return { ...item, isCourse: YesNo.yes }
			});
			if (appUserView?.educations) {
				appUserView.educations = [...appUserView?.educations?.filter(item => item.isCourse === YesNo.no), ...value];
			}
			// update data
			this.appUserService.updateAppUserView(appUserView)
				.pipe(
					takeUntil(this.destroy$),
				)
				.subscribe({
					next: (appUserView) => {
						this.saving.next(false);
						this.dialogRef.close(appUserView);
					},
					error: (err) => {
						this.saving.next(false);
						this.notificationService.showDanger(errorTitle(err));
					}
				});
		}
	}

}
