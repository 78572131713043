import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'companyWebsite'
})
export class CompanyWebsitePipe implements PipeTransform {

  /**
   * Обрезает http:// и https://
   * @param value
   */
  transform(value: string): string {
    if (value.startsWith('http://')){
      return value.replace('http://', '');
    }
    if (value.startsWith('https://')){
      return value.replace('https://', '');
    }
    return value;
  }

}
