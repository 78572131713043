export enum supportCategories {
  Offer,
  Error,
  Partnership,
  Violation
}

export class SupportRequest {
  public name: string;
  public email: string;
  public category: supportCategories;
  public description: string;
}

export class AppUserSupportRequest {
  public category: supportCategories;
  public description: string;
}
