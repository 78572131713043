export class MenuItem {
	title: string;
	description: string;
	routerLink: any[];
	counter: string | null;
	icon: string;
	children: MenuItem[];

	constructor(
		title: string,
		description: string,
		routerLink: any[] = ['#'],
		counter: string | null,
		icon: string,
		children: MenuItem[] = []
	) {
		this.title = title;
		this.description = description;
		this.routerLink = routerLink;
		this.counter = counter;
		this.icon = icon;
		this.children = children;
	}
}
