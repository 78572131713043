import { Component, OnInit } from '@angular/core';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';
import { AppUserService } from '@core/services/app-user.service';
import { WorkService } from '@core/services/work.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '@core/services/loading.service';
import { WorkExecutorMarkService } from '@core/services/work-executor-mark.service';
import { WorkRequestService } from '@core/services/work-request.service';
import { WorkStateChangeManagerBase } from '@shared/work-state-change-manager-base/work-state-change-manager-base';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { CompleteInviteSpecialistDialogComponent } from '../../general/pages/general-work/components/dialogs/complete-invite-specialist-dialog/complete-invite-specialist-dialog.component';
import { WorkRequestState } from '@models/work-request-view';
import { WorkView } from '@models/work-view';

@Component({
	selector: 'pdw-work-state-change-manager',
	templateUrl: './work-state-change-manager.component.html',
	providers: [DestroyService],
	styleUrls: ['./work-state-change-manager.component.scss']
})
export class WorkStateChangeManagerComponent extends WorkStateChangeManagerBase implements OnInit {

	constructor(
		private appUserService: AppUserService,
		private workService: WorkService,
		private dialog: MatDialog,
		private loadingService: LoadingService,
		private notificationService: NotificationService,
		private workExecutorMarkService: WorkExecutorMarkService,
		private workRequestService: WorkRequestService,
		private destroy$: DestroyService
	) {
		super(
			appUserService,
			workService,
			dialog,
			loadingService,
			notificationService,
			workExecutorMarkService,
			workRequestService
		);
	}

	ngOnInit(): void {
		this.workViewSubject
			.pipe(
				filter(x => x !== null),
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: workView => {
					this.currentWorkView.next(workView);
				}
			});
	}

	inviteSpecialistToVacancy(): void {
		this.dialog.open(CompleteInviteSpecialistDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					executors: this.workView.requests.filter(req => req.state === WorkRequestState.CandidatesList),
					workId: this.workView.id
				}
			})
			.afterClosed()
			.pipe(
				switchMap(() => this.workService.getWorkView(this.workView.id)),
				catchError(err => {
					this.notificationService.showDanger('Что-то пошло не так');
					return null;
				}),
				takeUntil(this.destroy$)
			)
			.subscribe((work) => {
				if (work) {
					this.changed.emit(work);
					this.updateWorkView(work as WorkView);
				}
			});
	}

	get canSeeking(): boolean {
		return !!this.workView.amount && !!this.workView.endDate &&
			!!this.workView.startDate;
	}

}
