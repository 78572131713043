<div
	[routerLink]='commands'
	(mousedown)='onMouseDown($event)'
	(mouseup)='onMouseUp($event)'
	class='pd-work-card-inner'
>
	<div class='pd-work-card-header'>
		<pdw-work-state
			[value]='work.state'
			[type]='WORK_STATE.ABSTRACT_FINISHED'
		>
		</pdw-work-state>
		<button
			*ngIf='!authService.isLoggedIn()'
			class='pd-icon-button pd-card-bookmark'
			mat-icon-button
			[matTooltip]="'Добавить в избранное'"
			(click)='goSubscribeWithoutAuth($event)'
		>
			<mat-icon [svgIcon]="'additional-bookmark'"></mat-icon>
		</button>
		<div
			*hasAuth='true'
			class='pd-work-card-header-buttons'
		>
			<ng-template #specialistMenu>
				<ng-container *ngIf='showFavButton'>
					<ng-container *ngIf='canSubscribe()'>
						<button
							class='pd-icon-button pd-card-bookmark'
							mat-icon-button
							*ngIf='work.subscribed === YesNo.no'
							[matTooltip]="'Добавить в избранное'"
							(click)='subscribeToWork($event)'
						>
							<mat-icon [svgIcon]="'additional-bookmark'"></mat-icon>
						</button>
						<button
							class='pd-icon-button pd-card-bookmark-favorite'
							mat-icon-button
							*ngIf='work.subscribed === YesNo.yes'
							[matTooltip]="'Убрать из избранного'"
							(click)='unsubscribeFromWork($event)'
						>
							<mat-icon [svgIcon]="'additional-bookmark'"></mat-icon>
						</button>
					</ng-container>
				</ng-container>
			</ng-template>
		</div>
	</div>
	<div class='pd-work-card-body'>
		<div class='pd-work-card-title-row'>
			<p class='pd-work-card-title-text'>{{work.name}}</p>
			<pdw-work-cost
				[work]='work'
				[plateView]='false'
			>
			</pdw-work-cost>
		</div>
		<span>
			<p class='pd-work-card-company-name'>
				{{isShortModel(work) ? work.companyName : work.company?.name}}
			</p>
			<p class='pd-work-card-city'>
				{{work.city?.name ?? 'Город не указан'}}
			</p>
		</span>
		<div
			class='pd-work-card-categories'
		>
			<ng-container *ngIf='isShortModel(work); else sub'>
				<div
					*ngFor='let subCategory of work.subCategoryNames'
					class='pd-skill-inner'
					[matTooltip]="'Тип работы'"
				>
					<p class='pd-skill-text'>
						{{ subCategory.name }}
					</p>
				</div>
			</ng-container>
			<ng-template #sub>
				<ng-container *ngIf='!isShortModel(work)'>
					<div
						*ngFor='let subCategory of work.subCategories'
						class='pd-skill-inner'
						[matTooltip]="'Тип работы'"
					>
						<p class='pd-skill-text'>
							{{ subCategory.name }}
						</p>
					</div>
				</ng-container>
			</ng-template>
		</div>
		<p class='pd-work-card-description'>
			{{work.description}}
		</p>
	</div>
</div>

