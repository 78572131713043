import { Injectable } from '@angular/core';
import { AbstractModelService } from '@core/services/abstract-model.service';
import { HttpClient } from '@angular/common/http';
import { WorkFileView } from '@models/work-file-view';

@Injectable({
	providedIn: 'root'
})
export class WorkFileService {
	private workFilesUrl = 'api/WorkFiles';

	constructor(private http: HttpClient, private modelService: AbstractModelService) {
	}

	download(workFileView: WorkFileView): void {
		const url = `${this.workFilesUrl}/Download/${workFileView.id}`;
		this.http.get(url, {
				headers: this.modelService.downloadHttpHeaders(workFileView.contentType),
				observe: 'response',
				responseType: 'blob'
			})
			.subscribe({
				next: (data) => {
					const contentType = data.headers.get('content-type');
					const contentDisposition = data.headers.get('content-disposition');
					let filename = 'unknown.tmp';
					if (contentDisposition !== undefined) {
						const regex = /filename="?(.*\.\w+)"?;/;
						const match = regex.exec(contentDisposition);
						filename = match[1];
					}
					const blob = new Blob([data.body], { type: contentType });
					if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
						(window.navigator as any).msSaveOrOpenBlob(blob);
					} else {
						const url = window.URL.createObjectURL(blob);
						const link = document.createElement('a');
						link.href = url;
						link.download = filename;
						// this is necessary as link.click() does not work on the latest firefox
						link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
						setTimeout(() => {
							// For Firefox it is necessary to delay revoking the ObjectURL
							window.URL.revokeObjectURL(url);
						}, 100);
					}
				},
				error: (err) => {
					console.warn('download error', err);
				}
			});
	}
}
