<mat-expansion-panel
	class='pd-expansion-panel pd-work-chat-panel'
	[hideToggle]='true'
	[expanded]='!(utilsService.mobileQuery980px | async)'
	#panel
	[disabled]='(isConnecting | async) || isPanelDisabled'
	(opened)='selectedWorkRequest = lastSelectedWorkRequest'
	(afterCollapse)='onPanelCollapse()'
	(closed)='onPanelClose()'
>
	<mat-expansion-panel-header>
		<ng-container *ngIf='lastSelectedWorkRequest as requestView; else listTitle'>
			<div class='pd-chat-panel-title'>
				<button
					*ngIf='panel.expanded'
					mat-icon-button
					class='pd-icon-button'
					(click)='unselectingWorkExecutorRequest($event)'
				>
					<mat-icon
						class='pd-icon'
						[svgIcon]="'additional-arrow-left'"
					>
					</mat-icon>
				</button>
				<a
					class='pd-chat-panel-title-inner link'
					[routerLink]="['/users', requestView.executor.id]"
					(click)='$event.preventDefault()'
				>
					<div
						class='pd-chat-panel-title-img-wrapper'
						[style.background-color]='avatarColor(requestView.executor)'
					>
						<ng-container *ngIf='requestView.executor.hasAvatar === 1'>
							<img
								alt='Avatar image'
								[src]="requestView.executor | avatar | async"
								class='pd-chat-panel-title-img'
							>
						</ng-container>
						<div
							class='pd-profile-online-status pd-profile-online-status-into small'
							[ngClass]="{'online' : isUserOnline(requestView.executor)}"
							[matTooltip]="isUserOnline(requestView.executor) ? 'В сети' : requestView.executor.lastConnection | appUserLastConnection"
						>
						</div>
					</div>
					<p class='pd-chat-panel-title-text'>
						{{requestView.executor.title}}
					</p>
				</a>
			</div>

		</ng-container>
		<ng-template #listTitle>
			<h2 class='pd-chat-panel-title-text'>
				Переписка
			</h2>
		</ng-template>
		<button
			class='pd-icon-button'
			mat-icon-button
		>
			<mat-icon
				class='pd-icon pd-profile-block-toggle-icon'
				svgIcon='additional-chevron-down'
			>
			</mat-icon>
		</button>
	</mat-expansion-panel-header>
	<ng-template matExpansionPanelContent>
		<div
			class='pd-chat'
			[ngClass]="{'selected' : selectedWorkRequest}"
		>
			<app-work-requests-in-work
				[selectWorkRequest]='selectedWorkRequest'
				(selectWorkRequestChange)='selectingWorkExecutorRequest($event)'
				[user]='appUserView'
				class='pd-chat-requests-list'
				[work]='workView'
			>
			</app-work-requests-in-work>
			<app-work-requests-chat-messenger
				[disabled]='isDraft'
				[selectedWorkRequest]='selectedWorkRequest'
				[user]='appUserView'
				(isConnecting)='isConnectingChange($event)'
				class='pd-chat-messenger'
			>
			</app-work-requests-chat-messenger>
		</div>
	</ng-template>
</mat-expansion-panel>
