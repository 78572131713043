import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarPipe } from '@shared/pipes/avatar/avatar.pipe';


@NgModule({
	declarations: [AvatarPipe],
	exports: [AvatarPipe],
	imports: [
		CommonModule
	]
})
export class AvatarModule {
}
