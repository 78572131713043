<ng-container *ngIf='selectedWorkRequest'>
	<ng-container *ngIf='isLoad | async; else loading'>
		<ng-container *ngIf='!(isEmpty | async); else startChatBlock'>
			<tui-scrollbar
				class='pd-scrollbar pd-chat-scrollbar'
				#scrollRef
			>
				<mat-table
					[dataSource]='dataSource'
					#matTable
					matSort
					matSortActive='createtime'
					matSortDirection='desc'
				>
					<ng-container matColumnDef='messages'>
						<mat-cell
							*matCellDef='let element; let i = index'
							[ngClass]="{
                  'pd-chat-messenger-export': element.createAppUser.id == user.id,
                  'pd-chat-messenger-import': element.createAppUser.id != user.id,
                  'message': true
                  }"
							class='pd-chat-messenger-cell'
							(contextmenu)='rightClick($event, element.createAppUser.id == user.id, element)'
						>
							<mat-icon
								svgIcon='additional2-rectangle-left-message'
								class='pd-chat-rectangle-message-import'
								[ngClass]="{
                    'pd-chat-rectangle-message-import': element.createAppUser.id != user.id,
                    'pd-chat-rectangle-message-export': element.createAppUser.id == user.id
                    }"
							>
							</mat-icon>
							<!--          regular message-->
							<ng-container>
								<div class='pd-chat-messenger-cell-message'>
									<p>{{element.message}}</p>
									<div class='pd-chat-messenger-cell-message-footer'>
										<!--          message mark-->
										<mat-icon
											*ngIf='!element.received && element.createAppUser.id == this.user.id'
											svgIcon='additional2-message-check1'
											class='pd-chat-messenger-sent pd-chat-messenger-check-position'
										>
										</mat-icon>
										<mat-icon
											*ngIf='element.received && element.createAppUser.id == this.user.id'
											svgIcon='additional2-message-check2'
											class='pd-chat-messenger-received pd-chat-messenger-check-position'
										>
										</mat-icon>
										<div class='pd-chat-messenger-cell-message-time'>
											{{element.modifyTime | date:'HH:mm'}}
										</div>
										<span
											class='pd-chat-messenger-edit-mark'
											*ngIf='element.isEdited == 1'
										>ред.</span>
									</div>
								</div>
							</ng-container>
						</mat-cell>
					</ng-container>
					<!-- Group header -->
					<ng-container matColumnDef='groupHeader'>
						<mat-cell
							*matCellDef='let group'
							class='pd-chat-messenger-time-header'
						>
							<div class='pd-chat-messenger-cell-system-message'>
								{{group.value | dateToDayAndMonth}}
							</div>
						</mat-cell>
					</ng-container>
					<mat-row
						class='pd-chat-messenger-row'
						*matRowDef="let row; columns: ['messages'];"
					></mat-row>
					<mat-row
						*matRowDef="let row; columns: ['groupHeader']; when: groupBy.isGroup"
						class='pd-chat-messenger-time-header-row'
					></mat-row>
				</mat-table>
			</tui-scrollbar>
		</ng-container>
		<ng-template #startChatBlock>
			<div class='pd-chat-messenger-start'>
				<p>
					<mat-icon
						class='pd-icon'
						[svgIcon]="'additional2-info'"
					>
					</mat-icon>
					Скоро здесь появятся сообщения
				</p>
			</div>
		</ng-template>
		<app-work-requests-chat-messenger-form
			[selectedWorkRequest]='selectedWorkRequest'
			[disabled]='disabled'
			[user]='user'
			[oldMessage]='oldMessage'
			(isEdited)='isEdited($event)'
			(isNotEmpty)='this.isEmpty.next(false)'
		>
		</app-work-requests-chat-messenger-form>
		<app-work-requests-chat-messenger-context-menu
			[contextMenuData]='(contextMenu | async)'
			(edit)='editMessage($event)'
			(delete)='deleteMessage($event)'
		>
		</app-work-requests-chat-messenger-context-menu>
	</ng-container>
	<ng-template #loading>
		<div class='pd-chat-loading'>
			<mat-spinner
				[strokeWidth]='2'
				[diameter]='30'
			></mat-spinner>
		</div>
	</ng-template>
</ng-container>
