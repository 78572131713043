import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompanyView } from '@models/company-view';
import { CompanyService } from '@core/services/company.service';
import { number2string } from '@utils/helpers/string-helpers';
import { TrueLoadingService } from '@core/services/true-loading.service';

@Component({
	selector: 'pdw-company-information',
	templateUrl: './company-information.component.html',
	styleUrls: ['./company-information.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TrueLoadingService]
})
export class CompanyInformationComponent {
	@Input() company: CompanyView = null;
	@Input() count: number;

	constructor(public loading$: TrueLoadingService) {
	}

	logoColor(companyView: CompanyView): string {
		return CompanyService.logoColor(companyView);
	}

	pluralize(value: number): string {
		return `${value} ${number2string(value, ['отзывов', 'отзывов', 'отзыва'])}`
	}
}
