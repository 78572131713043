<ng-container *ngIf="utilsService.mobileQuery980px | async; else desktop">
	<button mat-raised-button
		(click)="goCreate()"
		class="pd-button btn-mobile">
	<mat-icon class="pd-icon" svgIcon="additional-plus"></mat-icon>
	Добавить компанию
	</button>
</ng-container>
<ng-template #desktop>
	<div
		*ngIf="isToShow"
		class="pd-company-card-inner">
		<div class="pd-basis-card-empty-inner pd-company-add-card-inner">
			<mat-icon class="pd-icon pd-icon-big" svgIcon="additional-plus-rectangle-100-100"></mat-icon>
			<button mat-raised-button
					(click)="goCreate()"
					class="pd-button">
				<mat-icon class="pd-icon" svgIcon="additional-plus"></mat-icon>
				Добавить компанию
			</button>
		</div>
	</div>
</ng-template>
