<div *ngIf="currentAppUserView | async as currentAppUser">
  <div class="pd-profile-about-top-wrapper"
       [ngClass]="(currentAppUser.softSkills && currentAppUser.softSkills.length) ? '' : 'empty'">
    <h3 class="pd-profile-subtitle">
      <mat-icon class="pd-icon light-gray" svgIcon="additional-check-rectangle"></mat-icon>
      {{'softSkillsTitle' | skill: 'propertyTitle'}}
    </h3>
    <ng-container *ngIf="canUpdate">
      <button *ngIf="currentAppUser.softSkills && currentAppUser.softSkills.length; else noSoftSkills"
              class="pd-button-secondary small"
              mat-raised-button
              (click)="goUpdateSoftSkills()">
        <mat-icon class="pd-icon" svgIcon="additional2-edit"></mat-icon>
        <p class="pd-profile-button-text">Редактировать</p>
      </button>
      <ng-template #noSoftSkills>
        <button class="pd-button-secondary small" mat-raised-button (click)="goUpdateSoftSkills()">
          <mat-icon class="pd-icon" svgIcon="additional-plus"></mat-icon>
          <p class="pd-profile-button-text">Добавить</p>
        </button>
      </ng-template>
    </ng-container>
  </div>
  <div class="pd-profile-skills-wrapper" *ngIf="currentAppUser.softSkills && currentAppUser.softSkills.length;">
    <ng-container *ngFor="let softSkill of currentAppUser.softSkills">
      <!-- Без возможности оценки -->
      <div class="pd-skill-inner" [ngClass]="{ 'created': softSkill.state === 0 }">
        <p class="pd-skill-text" [matTooltip]="getTooltip(softSkill)">
          {{ softSkill.title }}
        </p>
      </div>
    </ng-container>
  </div>
</div>

