import {AbstractSkillView} from "@models/abstract-skill-view";

export const HardSkillMenuTitles = {
  icon: 'school',
  primaryTitleItems: 'Проф. качества',
  descriptionItems: 'Все проф. качества',
  primaryTitleCreate: 'Создание профессионального качества',
  descriptionCreate: 'Создание нового профессионального качества',
  descriptionItem: 'Профессиональное качество',
};
export class HardSkillView extends AbstractSkillView {
}
