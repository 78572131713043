import { ChangeDetectionStrategy, Component, OnInit, } from '@angular/core';
import { AppUserService } from '@core/services/app-user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '@core/services/header.service';
import { DestroyService } from '@profdepo-ui/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { AppUserView } from '@models/app-user-view';
import { YesNo } from '@models/enums';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AuthService } from '@core/services/auth.service';


enum ProfessionalCardEnum {
	DEFAULT = 'default',
	SMALL = 'small'
}

interface ProfessionalCardData {
	title: string,
	subtitle: string,
	count: number,
	svgName: string,
	type: ProfessionalCardEnum
}

@Component({
	selector: 'pdw-landing',
	templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.scss'],
	providers: [DestroyService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingComponent implements OnInit {

	isMobileVersion$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 980px)'])
		.pipe(map(observer => observer.matches))
	user: AppUserView;
	isLoggedIn: boolean;
	constructor(
		private appUserService: AppUserService,
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private headerService: HeaderService,
		private breakpointObserver: BreakpointObserver,
	) {
		this.user = new AppUserView();
		this.user.id = 'assets/landing/user-avatar.png';
		this.user.firstname = 'Александр';
		this.user.lastname = 'test';
		this.user.userName = 'Александр';
		this.user.hasAvatar = YesNo.yes;
		this.user.avatar = 'assets/landing/user-avatar.png';
		this.user.city = {id: 0, name: 'г. Тюмень', district: '', region: ''};
		this.user.ratingPercent = 97;
		this.user.specialistProfit = 86;
		this.user.preferredReward = 45000;
		this.user.profession = 'Инженер-проектировщик (младший специалист/ стажер)';
		this.user.birthday = new Date((moment().year() - 36));
	}



	ngOnInit(): void {
		history.replaceState(null, null, location.origin + location.pathname);
		this.headerService.changeCurrentTitle('Соединяем компании и специалистов в проекты');
		this.isLoggedIn = this.authService.isLoggedIn()

	}

	uniqItem(index: number, card: ProfessionalCardData) {
		return card.title ?? undefined;
	}

	get professionalCardsData(): ProfessionalCardData[] {
		return [
			{
				title: 'Архитектура и строительные конструкции',
				subtitle: 'Визуализация, ИЖС, МАФы, жилое и промышленное проектирование и другое',
				count: 28,
				svgName: 'city',
				type: ProfessionalCardEnum.DEFAULT
			},
			{
				title: 'Инженерные сети',
				subtitle: 'Сети водоснабжения,  отопления, газоснабжения, вентиляции, электроснабжения и другое.',
				count: 53,
				svgName: 'scheme',
				type: ProfessionalCardEnum.DEFAULT
			},
			{
				title: 'Машиностроение и механика',
				subtitle: 'Машины: детали, механизмы, малые станки, приспособления, ремонт и ТО',
				count: 26,
				svgName: 'mechanic',
				type: ProfessionalCardEnum.DEFAULT
			},
			{
				title: 'Проектирование основных разделов',
				subtitle: 'Генплан, благоустройство, охрана среды, пожарная и промышленная безопасность, сметы и другое.',
				count: 83,
				svgName: 'files',
				type: ProfessionalCardEnum.DEFAULT
			},
			{
				title: 'Чертежи / 3D модели',
				subtitle: '3D моделирование, векторизация, оцифровка',
				count: 74,
				svgName: 'blueprint',
				type: ProfessionalCardEnum.SMALL
			},
			{
				title: 'Инженерные изыскания',
				subtitle: 'Геодезические, геологические, геотехнические, гидрометеорологические, экологические изыскания',
				count: 12,
				svgName: 'microscope',
				type: ProfessionalCardEnum.SMALL
			},
			{
				title: 'Промышленная безопасность',
				subtitle: 'Лицензирование, декларации пром. безопасности, обоснование безопасности, ПЛАРН, ПМЛЛА',
				count: 4,
				svgName: 'prom-security',
				type: ProfessionalCardEnum.SMALL
			}, {
				title: 'Экспертиза и диагностика',
				subtitle: 'Экспертизы пром. безопасности, негосударственные ИИ, ПД, тех. диагностика, неразрушающий контроль экспертиза смет',
				count: 32,
				svgName: 'expert',
				type: ProfessionalCardEnum.SMALL
			},
			{
				title: 'Охрана труда',
				subtitle: 'Оценка профессиональных рисков, работы по охране труда',
				count: 11,
				svgName: 'def',
				type: ProfessionalCardEnum.SMALL
			}
		]
	}

	filterCards(type: ProfessionalCardEnum, isReturnsAll?: boolean): ProfessionalCardData[] {
		if (isReturnsAll) {
			return this.professionalCardsData;
		}
		return this.professionalCardsData.filter(card => card.type === type);
	}

	protected readonly ProfessionalCardEnum = ProfessionalCardEnum;
}
