import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { WorkStateValue } from '@models/work-view';
import { BehaviorSubject, forkJoin, Subject, switchMap } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { WorkMarkService } from '@core/services/work-mark.service';
import { pushFakeHistoryState } from '@utils/functions';
import { errorTitle } from '@utils/helpers/error-helpers';
import { MatDialog } from '@angular/material/dialog';
import { WorkMarksSpecialistDialogComponent } from '@shared/work-marks-specialist-dialog/work-marks-specialist-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { WorkMarkView } from '@models/work-mark-view';
import { AppUserService } from '@core/services/app-user.service';
import { WorkExecutorMarkService } from '@core/services/work-executor-mark.service';
import { WorkExecutorMarkView } from '@models/work-executor-mark-view';
import { WorkAbstractComponent } from '@shared/work-abstract-component/work-abstract-component';
import { NotificationService } from '@profdepo-ui/core';

@Component({
	selector: 'app-work-marks-specialist',
	templateUrl: './work-marks-specialist.component.html',
})
export class WorkMarksSpecialistComponent extends WorkAbstractComponent implements OnInit, OnDestroy {
	@Input('isActiveUserAssigned') isActiveUserAssigned: boolean = false;
	_canAssign = new BehaviorSubject<boolean>(false);
	dataSource: MatTableDataSource<WorkMarkView>;
	workExecutorMarkView = new BehaviorSubject<WorkExecutorMarkView>(null);
	workMarkView = new BehaviorSubject<WorkMarkView>(null);
	isLoading = new BehaviorSubject<boolean>(false);

	@Output() changed = new EventEmitter<any>();
	unsubscribe: Subject<any> = new Subject<any>();

	constructor(
		private appUserService: AppUserService,
		private workMarkService: WorkMarkService,
		private workExecutorMarkService: WorkExecutorMarkService,
		private dialog: MatDialog,
		private notificationService: NotificationService
	) {
		super();
	}

	ngOnInit(): void {
		this.isLoading.next(true);

		this.workViewSubject
			.pipe(
				filter(x => x !== null),
				switchMap(x => forkJoin([
						this.workMarkService.canAssign(x.id),
						this.workMarkService.one(x.id),
						this.workExecutorMarkService.one(x.id)
					])
						.pipe(
							takeUntil(this.unsubscribe)
						)
				)
			)
			.subscribe({
				next: (data) => {
					this.isLoading.next(false);
					this._canAssign.next(data[0]);
					this.workMarkView.next(data[1]);
					this.workExecutorMarkView.next(data[2]);
				}
			});
	}

	getTooltip(): string {
		const canAssign = this._canAssign.getValue();

		if (this.workView.state !== WorkStateValue.successed && canAssign) {
			return 'Вы сможете поставить оценку, после завершения работы';
		} else if (this.workView.state < WorkStateValue.successed && !canAssign) {
			return 'Вы не можете поставить оценку'
		}

		return null;
	}

	get canAssign(): boolean {
		return this._canAssign.getValue();
	}

	goAssign(): void {
		pushFakeHistoryState();

		this.dialog.open(WorkMarksSpecialistDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				minHeight: '320px',
				panelClass: ['pd-dialog'],
				data: {
					title: 'Оценка компании',
					workView: this.workView
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
			)
			.subscribe({
				next: (data) => {
					this.changed.emit(this.workView);
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	ngOnDestroy() {
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}
}
