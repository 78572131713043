import { Directive, inject, Input } from '@angular/core';
import { WorkStateValue, WorkView } from '@models/work-view';
import { BehaviorSubject } from 'rxjs';
import { groupByField } from '@utils/helpers/array-helpers';
import { WorkViewShort } from '@models/work-view-short';
import { YesNo } from '@models/enums';
import { RoleService } from '@core/services/role.service';
import { AppUserType } from '@models/app-user-view';
import { SubCategoryView } from '@models/sub-category-view';
import { CategoryView } from '@models/category-view';

@Directive()
export class WorkAbstractComponent {
	@Input()
	set workView(value: WorkView) {
		this._workView.next(value);
	};

	@Input()
	set workViewShort(value: WorkViewShort) {
		this._workViewShort.next(value);
	};

	get workView(): WorkView {
		return { ...this._workView.getValue(), requests: this._workView.getValue().requests ?? [] };
	}

	updateWorkView(work: WorkView): void {
		this._workView.next(work);
	}


	get workViewShort(): WorkViewShort {
		return this._workViewShort.getValue();
	}

	get workViewSubject(): BehaviorSubject<WorkView> {
		return this._workView;
	}

	get workViewShortSubject(): BehaviorSubject<WorkViewShort> {
		return this._workViewShort;
	}

	private _workView = new BehaviorSubject<WorkView>(null);
	currentWorkView = new BehaviorSubject<WorkView>(null);
	groupedCategoriesWithSubCategories = new BehaviorSubject<any>(null);

	private _workViewShort = new BehaviorSubject<WorkViewShort>(null);
	currentWorkViewShort = new BehaviorSubject<WorkViewShort>(null);
	roleService = inject(RoleService);

	get canSeeking(): boolean {
		return !!this.workView.amount &&
			!!this.workView.endDate &&
			!!this.workView.startDate;
	}

	get canUpdate(): boolean {
		return this.workView.state == WorkStateValue.draft ||
			this.workView.state == WorkStateValue.packaged;
	}

	get canDelete(): boolean {
		return this.workView.state < WorkStateValue.packaged;
	}

	get isDraft(): boolean {
		return this.workView.state == WorkStateValue.draft;
	}

	get isPackaged(): boolean {
		return this.workView.state == WorkStateValue.packaged && this.isFreelance;
	}

	get isSeeking(): boolean {
		return this.workView.state == WorkStateValue.seeking;
	}

	get isChecking(): boolean {
		return this.workView.state == WorkStateValue.checking;
	}

	get isReadyToInvite(): boolean {
		return this.workView.state === WorkStateValue.seeking && !this.isFreelance && this.roleService.hasPermission(AppUserType.client);
	}

	get isFreelance(): boolean {
		return this.workView.isFreelance === YesNo.yes;
	}

	get isExecutingAwait(): boolean {
		return this.workView.state == WorkStateValue.executingAwait;
	}

	get isFinished(): boolean {
		return this.workView.state >= WorkStateValue.successed;
	}

	get isExecuting(): boolean {
		return this.workView.state === WorkStateValue.executing;
	}

	/**
	 * Группирует подкатегории по категориям
	 * @param subject объект для хранения сгруппированного списка
	 * @param workView работа
	 */
	groupWorkCategories(subject: BehaviorSubject<any>, workView: WorkView): void {
		workView.subCategories?.forEach((category: SubCategoryView) => {
			const currentCategoryName: CategoryView | undefined  = workView.categories.find((item) => item.id === category.id)
				return category.categoryName = currentCategoryName?.name ?? ''
		});
		subject.next(groupByField(workView?.subCategories, 'categoryName'));
		workView.categories?.forEach(c => {
			const tmpArr = subject.value;
			if (!tmpArr.some(g => g.categoryName === c.name)) {
				tmpArr.push({
					categoryName: c.name,
					group: []
				});
				subject.next(tmpArr);
			}
		});
	}
}
