import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {filter, takeUntil} from "rxjs/operators";
import {AppUserService} from "@core/services/app-user.service";
import {BehaviorSubject, Subject} from "rxjs";
import {MenuItem} from "@models/menu-item";
import {AppUserView} from "@models/app-user-view";
import {HeaderService} from "@core/services/header.service";
import {LoadingService} from "@core/services/loading.service";
import {AdministratorMenuService} from "@core/services/administrator-menu.service";
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { errorTitle } from '@utils/helpers/error-helpers';

@Component({
  selector: 'app-administrator-layout',
  templateUrl: './administrator-layout.component.html',
  styleUrls: ['./administrator-layout.component.scss']
})
export class AdministratorLayoutComponent implements OnInit, OnDestroy {
  menuItems = new BehaviorSubject<MenuItem[]>([]);
  loading = new Subject<boolean>();
  currentUser = new BehaviorSubject<AppUserView | null>(null);
  currentIcon = new BehaviorSubject<string | null>(null);
  currentPrimary = new BehaviorSubject<string | null>(null);
  currentSecondary = new BehaviorSubject<string | null>(null);
  currentDescription = new BehaviorSubject<string | null>(null);
  unsubscribe: Subject<any> = new Subject<any>();
  constructor(
    private appUserService: AppUserService,
    private headerService: HeaderService,
    private loadingService: LoadingService,
    private menuService: AdministratorMenuService,
    private changeDetectorRef: ChangeDetectorRef,
	private auth: AuthService,
	private destroy$: DestroyService,
	private router: Router,
	private notificationService: NotificationService,
) { }

  ngOnInit(): void {
    this.appUserService.getActiveUserView()
      .pipe(
        filter(x => x !== null),
        takeUntil(this.unsubscribe)
      )
      .subscribe(value => {
        this.currentUser.next(value);
      });

    this.loadingService.currentLoading
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(state => {
        this.loading.next(state);
        this.changeDetectorRef.detectChanges();
      });

    this.menuService.currentMenuItems
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(menuItems => {
        this.menuItems.next(menuItems);
        this.changeDetectorRef.detectChanges();
      });
  }
  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }

  onLogout() {
	this.auth.logout()
			.pipe(
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: () => {
					this.router
						.navigate(['login']);
					this.auth.doLogoutUser();
				},
				error: (err) => {
					if (err instanceof HttpErrorResponse) {
						this.notificationService.showDanger(errorTitle(err));
					}
				}
			});
  }
}
