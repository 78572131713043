import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { WorkRequestView } from '@models/work-request-view';
import { AppUserView } from '@models/app-user-view';
import { AppUserService } from '@core/services/app-user.service';
import { map, takeUntil } from 'rxjs/operators';
import { WorkRequestService } from '@core/services/work-request.service';
import { errorTitle } from '@utils/helpers/error-helpers';
import { ChatService } from '@core/services/chat.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { UtilsService } from '@core/services/utils.service';
import { WorkAbstractComponent } from '@shared/work-abstract-component/work-abstract-component';
import { DestroyService, NotificationService } from '@profdepo-ui/core';

@Component({
	selector: 'app-work-details-chat-manager',
	templateUrl: './work-details-chat-manager.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService]
})
export class WorkDetailsChatManagerComponent extends WorkAbstractComponent implements OnInit, OnDestroy {
	selectedWorkRequest: WorkRequestView = null;
	lastSelectedWorkRequest: WorkRequestView = null;
	appUserView: AppUserView;
	unsubscribe: Subject<any> = new Subject<any>();
	isConnecting = new BehaviorSubject<boolean>(false);
	isPanelDisabled: boolean = false;

	constructor(
		private appUserService: AppUserService,
		private chatService: ChatService,
		private notificationService: NotificationService,
		public utilsService: UtilsService
	) {
		super();
	}

	ngOnInit(): void {
		this.appUserView = this.appUserService.getCurrentActiveUserView();

		//получено новое сообщение
		this.chatService.workRequestLastMessageUpdated
			.pipe(
				takeUntil(this.unsubscribe),
				map(x => WorkRequestService.parseDates(x))
			)
			.subscribe({
				next: (data: WorkRequestView) => {
					if (data.id === this.lastSelectedWorkRequest?.id) {
						this.lastSelectedWorkRequest.executor = data.executor;
					}
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	selectingWorkExecutorRequest(value: WorkRequestView) {
		this.selectedWorkRequest = value;
		this.lastSelectedWorkRequest = value;
	}

	unselectingWorkExecutorRequest(event: Event): void {
		event.stopPropagation();
		this.selectedWorkRequest = null;
		this.lastSelectedWorkRequest = null;
	}

	isUserOnline(appUserView: AppUserView): boolean | null {
		if (appUserView.lastConnection) {
			const delta = (new Date().getTime() - appUserView.lastConnection.getTime()) / 1000 / 60;
			return delta < 5;
		}
		return null;
	}

	avatarColor(appUserView: AppUserView): string {
		return AppUserService.avatarColor(appUserView);
	}

	isConnectingChange(event: boolean): void {
		setTimeout(() => {
			this.isConnecting.next(event);
		}, 0);
	}

	onPanelCollapse(): void {
		this.selectedWorkRequest = null;
		this.isPanelDisabled = false;
	}

	onPanelClose(): void {
		this.isPanelDisabled = true;
	}

	ngOnDestroy() {
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}
}
