import { Injectable } from '@angular/core';
import {AbstractModelService} from "@core/services/abstract-model.service";
import {HttpClient} from "@angular/common/http";
import {IndustryView} from "@models/industry-view";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class IndustryService {
  private companiesUrl = 'api/Industries';
  constructor(private http: HttpClient, private modelService: AbstractModelService) {}
  /**
   * Преобразование string в Date после десериализации
   * @param industryView опыт
   */
  public static parseDates(industryView: IndustryView): IndustryView {
    industryView.createTime = new Date(industryView.createTime);
    industryView.modifyTime = new Date(industryView.modifyTime);
    return industryView;
  }

  all(): Observable<IndustryView[]> {
    const url = `${this.companiesUrl}/All`;
    return this.http.get<IndustryView[]>(url, this.modelService.httpOptions)
      .pipe(
        map(xx => xx.map(x => IndustryService.parseDates(x)))
      );
  }
}
