import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WorkView } from '@models/work-view';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, mergeWith, Observable, of } from 'rxjs';
import { WorkResultFileView } from '@models/work-result-file-view';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { WorkFileView } from '@models/work-file-view';
import { FileService } from '@core/services/file.service';
import { WorkService } from '@core/services/work.service';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { TrueLoadingService } from '@core/services/true-loading.service';

export interface WorkResultFilesDialogData {
	title: string;
	workView: WorkView;
}

@Component({
	selector: 'pdw-work-result-files-dialog',
	templateUrl: './work-result-files-dialog.component.html',
	styleUrls: ['./work-result-files-dialog.component.scss'],
	providers: [TrueLoadingService, DestroyService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkResultFilesDialogComponent implements OnInit {
	workResultFilesForm: FormGroup;
	uploading$ = new BehaviorSubject<boolean>(false);

	constructor(
		public dialogRef: MatDialogRef<WorkResultFilesDialogData, boolean | WorkView>,
		@Inject(MAT_DIALOG_DATA) public data: WorkResultFilesDialogData,
		private workService: WorkService,
		private formBuilder: FormBuilder,
		private fileService: FileService,
		private notificationService: NotificationService,
		private destroy$: DestroyService,
		public loading$: TrueLoadingService,
	) {
		this.loading$.next(false);
	}

	ngOnInit(): void {
		this.workResultFilesForm = this.formBuilder.group({
			resultFiles: this.formBuilder.array(
				this.data.workView.resultFiles
					.map((v, i, a) => {
						v.work = new WorkView();
						v.work.id = this.data.workView.id;
						return this.createWorkResultFile(i, v, true)
					})
			)
		});
	}

	get resultFiles(): FormArray {
		return this.workResultFilesForm.get('resultFiles') as FormArray;
	}

	get isFilesToUpload(): boolean {
		return !!this.resultFiles.length;
	}

	workResultFilesName(index: number): FormControl {
		return this.resultFiles.controls[index].get('name') as FormControl;
	}

	workResultFilesDescription(index: number): FormControl {
		return this.resultFiles.controls[index].get('description') as FormControl;
	}

	workResultFilesSourceFilename(index: number): FormControl {
		return this.resultFiles.controls[index].get('sourceFilename') as FormControl;
	}

	workResultFilesTemporaryFilename(index: number): FormControl {
		return this.resultFiles.controls[index].get('temporaryFilename') as FormControl;
	}

	workResultFilesUploaded(index: number): FormControl {
		return this.resultFiles.controls[index].get('uploaded') as FormControl;
	}

	workResultFilesHasNoError(index: number): FormControl {
		return this.resultFiles.controls[index].get('hasNoError') as FormControl;
	}

	workResultFilesWork(index: number): FormControl {
		return this.resultFiles.controls[index].get('work') as FormControl;
	}

	workResultFilesId(index: number): FormControl {
		return this.resultFiles.controls[index].get('id') as FormControl;
	}

	createWorkResultFile(index: number, workResultFileView: WorkResultFileView, uploaded: boolean): FormGroup {
		return this.formBuilder.group({
			id: new FormControl(workResultFileView.id),
			work: new FormControl(workResultFileView.work, {
				validators: [Validators.required],
			}),
			name: new FormControl(workResultFileView.name),
			uploaded: new FormControl(uploaded, {
				validators: [Validators.requiredTrue]
			}),
			hasNoError: new FormControl(true, {
				validators: [Validators.requiredTrue]
			}),
			description: new FormControl(workResultFileView.description),
			sourceFilename: new FormControl(workResultFileView.sourceFilename),
			temporaryFilename: new FormControl(workResultFileView.temporaryFilename),
		});
	}

	updateWorkResultFile(index: number, workResultFileView: WorkResultFileView): void {
		this.workResultFilesId(index).setValue(workResultFileView.id);
		this.workResultFilesWork(index).setValue(workResultFileView.work);
		this.workResultFilesName(index).setValue(workResultFileView.name);
		this.workResultFilesDescription(index).setValue(workResultFileView.description);
		this.workResultFilesSourceFilename(index).setValue(workResultFileView.sourceFilename);
		this.workResultFilesTemporaryFilename(index).setValue(workResultFileView.temporaryFilename);
	}

	onSelectResultFile(index: number, event): void {
		const file: File = event.target.files[0];
		if (!file) {
			return;
		}

		this.workResultFilesSourceFilename(index).setValue(file.name);
		this.workResultFilesName(index).setValue(file.name);
		this.workResultFilesUploaded(index).setValue(false);
		this.workResultFilesHasNoError(index).setValue(true);

		this.uploading$.next(true);
		this.fileService.upload(file)
			.pipe(
				takeUntil(this.destroy$),
			)
			.subscribe({
				next: (data) => {
					this.uploading$.next(false);
					console.warn('onSelectFile data: ', data);
					this.workResultFilesUploaded(index).setValue(true);
					this.workResultFilesTemporaryFilename(index).setValue(data);
				},
				error: (err: any) => {
					this.uploading$.next(false);
					this.workResultFilesUploaded(index).setValue(true);
					this.workResultFilesHasNoError(index).setValue(false);
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	goRemoveWorkResultFile(index: number): void {
		if (index <= this.resultFiles.length - 1) {
			const temporaryFilename = this.resultFiles.controls[index].value.temporaryFilename;
			this.resultFiles.removeAt(index);
			this.uploading$.next(true);
			this.fileService.deleteTmpFile(temporaryFilename)
						.pipe(takeUntil(this.destroy$))
						.subscribe({
							next: () => {
								this.uploading$.next(false);
							},
							error: () => {
								this.uploading$.next(false);
							}
						});
		}
	}

	onSelectResultFiles(event): void {
		this.uploading$.next(true);
		let selectedFiles = [...event.target.files];
		let filesLength = this.resultFiles.length;
		const observable = new Observable;

		selectedFiles
			.map(x => {
				const workFileView = new WorkFileView();
				workFileView.id = 0;
				workFileView.name = x.name;
				return workFileView;
			}).forEach((v, i, a) => {
			const index = filesLength + i;
			this.resultFiles.insert(index, this.createWorkResultFile(index, v, false));
			this.workResultFilesForm.updateValueAndValidity();
		});

		observable.pipe(
				mergeWith(
					selectedFiles.map((x, initIndex) => {
							return this.fileService
								.upload(x)
								.pipe(
									takeUntil(this.destroy$),
									map((r) => {
										return { error: null, value: x, result: r, index: initIndex + filesLength };
									}),
									catchError(e => of({ error: e, value: x, result: null, index: initIndex + filesLength })),
								)
						}
					)
				)
			)
			.subscribe({
				next: ({ error, value, result, index }) => {
					this.workResultFilesUploaded(index).setValue(true);

					if (error) {
						this.workResultFilesHasNoError(index).setValue(false);
						this.notificationService.showDanger(error.error);
					} else if (!error) {
						console.log('Success');
						this.workResultFilesHasNoError(index).setValue(true);
						const workFileView = new WorkFileView();
						workFileView.id = 0;
						workFileView.work = this.data.workView;
						workFileView.name = value.name;
						workFileView.sourceFilename = value.name;
						workFileView.temporaryFilename = result;

						this.updateWorkResultFile(index, workFileView);
						this.workResultFilesForm.updateValueAndValidity();
					}
				},
				error: (err: any) => {
					this.uploading$.next(false);
					this.notificationService.showDanger(errorTitle(err));
				},
			});
	}

	disabledSubmitButton(): boolean {
		return this.loading$.value ||
			this.workResultFilesForm.invalid || !this.isFilesToUpload;
	}

	disabledGoWithOutFilesButton(): boolean {
		return this.isFilesToUpload;
	}

	onSubmit({ value, valid }): void {
		if (valid) {
			this.loading$.next(true);
			// copy form values to object
			let workView = Object.assign({}, this.data.workView);
			workView.resultFiles = this.resultFiles.value.slice();
			this.workService.call('update', workView)
				.pipe(
					takeUntil(this.destroy$)
				)
				.subscribe({
					next: (workView) => {
						this.loading$.next(false);
						this.dialogRef.close(workView);
					},
					error: (err) => {
						this.loading$.next(false);
						this.notificationService.showDanger(errorTitle(err));
					}
				});
		}
	}

	onGoWithOutFiles() {
		this.loading$.next(false);
		this.dialogRef.close(this.data.workView);
	}

	onResetFileInputValue(event: HTMLInputElement): void {
		event.value = null;
	}
}
