<div fxLayout="column" fxLayoutGap="20px">
  <div *ngIf="dataSource && dataSource.data.length">
    <table mat-table matSort matSortActive="title" matSortDirection="asc" [dataSource]="dataSource" class="pd-width-full">

      <!-- Title Column -->
      <ng-container matColumnDef="title">
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="['/', appUserTypeValuePipe.transform(appUserType), 'skill', element.id]">
            {{ element.title }}
          </a>
        </td>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: displayedColumns | async;"></tr>
    </table>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="!readonly">
    <button mat-icon-button matTooltip="Изменить список специализаций" color="primary" (click)="goChange()">
      <mat-icon>edit_note</mat-icon>
    </button>
  </div>
</div>
