import { Pipe, PipeTransform } from '@angular/core';
import {AbstractEnumPipe} from "@public/pipes/abstract-enum.pipe";

@Pipe({
  name: 'appUserGender'
})
export class AppUserGenderPipe extends AbstractEnumPipe implements PipeTransform {
  values = [
    'Мужчина',
    'Женщина',
  ];
  transform(value: any, ...args: any[]): any {
    return this.getTransform(value, args);
  }
}
