<div class="pd-calendar-header">
  <button class="pd-icon-button" mat-icon-button (click)="previousClicked()">
    <mat-icon svgIcon="additional-arrow-left"></mat-icon>
  </button>
  <button class="pd-calendar-header-button" (click)="onToggleClick()">
    <span class="pd-calendar-header-label">{{periodLabel}}</span>
    <mat-icon svgIcon="arrow-down-2"></mat-icon>
  </button>
  <button class="pd-icon-button" mat-icon-button (click)="nextClicked()">
    <mat-icon svgIcon="additional-arrow-right"></mat-icon>
  </button>
</div>
