<ng-container *ngIf="isToShowFullMode; else lightMode">
  <div class="pd-marks-card-inner" (mouseup)="openDialog(mark)" *ngIf="currentWorkMarkView | async as mark">
    <!--Header-->
    <div class="pd-marks-card-header">
      <!--Avatar-->
      <div class="pd-marks-card-avatar" *ngIf="!isToShowFullMode">
        <ng-container *ngIf="userHasAvatar(mark.assigner); else noAvatar">
          <img class="pd-marks-card-avatar-img"
               [src]="'/Avatars/Cropped/' + mark.assigner.avatar"
               alt="Avatar Image"/>
        </ng-container>
        <ng-template #noAvatar>
          <mat-icon class="pd-marks-card-no-avatar-icon" [svgIcon]="'additional2-img-box'"></mat-icon>
        </ng-template>
      </div>
      <!--Right side-->
      <div class="pd-marks-card-title">
        <p class="pd-marks-card-title-text" [class.fit-height]="isToShowFullMode">{{mark.work.name}}</p>
        <div class="pd-marks-card-title-company-row" *ngIf="!isToShowFullMode">
          <mat-icon class="pd-icon" svgIcon="additional-bag-2-check"></mat-icon>
          <p class="pd-marks-card-title-text-company">{{mark.assigner | userTitleChecker}}</p>
        </div>
      </div>
    </div>
    <mat-divider class="pd-divider-line-1"></mat-divider>
    <div class="pd-marks-card-rating">
      <div class="pd-marks-card-rating-item">
        <p class="pd-marks-card-rating-text">
          {{ 'speedValue' | workMark:'propertyTitle' }}:
        </p>
        <app-rating-one-small
          [useInitValue]="true"
          [fractionDigits]="0"
          [percent]="mark.speedValue">
        </app-rating-one-small>
      </div>
      <div class="pd-marks-card-rating-item">
        <p class="pd-marks-card-rating-text">
          {{ 'targetryValue' | workMark:'propertyTitle' }}:
        </p>
        <app-rating-one-small
          [useInitValue]="true"
          [fractionDigits]="0"
          [percent]="mark.targetryValue">
        </app-rating-one-small>
      </div>
      <div class="pd-marks-card-rating-item">
        <p class="pd-marks-card-rating-text">
          {{ 'adequacyValue' | workMark:'propertyTitle' }}:
        </p>
        <app-rating-one-small
          [useInitValue]="true"
          [fractionDigits]="0"
          [percent]="mark.adequacyValue">
        </app-rating-one-small>
      </div>
    </div>
    <mat-divider class="pd-divider-line-1"></mat-divider>
    <div class="pd-marks-card-body">
      <div class="pd-marks-card-modify-time">
        <mat-icon class="pd-icon" svgIcon="additional-check"></mat-icon>
        <p>
          {{(mark.modifyTime | date:'dd.MM.YYYY')}}
        </p>
      </div>
      <p class="pd-marks-card-comment" [ngClass]="{'empty' : !mark.comment}">
        {{mark.comment ? mark.comment : ''}}
      </p>
    </div>
  </div>
</ng-container>
<ng-template #lightMode>
	<ng-container *ngIf="currentLighTModeWorkMarkView | async as lightMark">
		<div class="pd-marks-card-inner" (mouseup)="openLightModeDialog(lightMark)">
			<!--Header-->
			<div class="pd-marks-card-header">
			<!--Right side-->
				<div class="pd-marks-card-title">
					<p class="pd-marks-card-title-text work-name">{{lightMark.workName}}</p>
				</div>
			</div>
			<mat-divider class="pd-divider-line-1"></mat-divider>
			<div class="pd-marks-card-rating">
			<div class="pd-marks-card-rating-item">
				<p class="pd-marks-card-rating-text marks-speed-value-text">
				{{ 'speedValue' | workMark:'propertyTitle' }}:
				</p>
				<app-rating-one-small
				[useInitValue]="true"
				[fractionDigits]="0"
				[percent]="lightMark.speedValue">
				</app-rating-one-small>
			</div>
			<div class="pd-marks-card-rating-item">
				<p class="pd-marks-card-rating-text marks-targetry-value-text">
				{{ 'targetryValue' | workMark:'propertyTitle' }}:
				</p>
				<app-rating-one-small
				[useInitValue]="true"
				[fractionDigits]="0"
				[percent]="lightMark.targetryValue">
				</app-rating-one-small>
			</div>
			<div class="pd-marks-card-rating-item">
				<p class="pd-marks-card-rating-text">
				{{ 'adequacyValue' | workMark:'propertyTitle' }}:
				</p>
				<app-rating-one-small
				[useInitValue]="true"
				[fractionDigits]="0"
				[percent]="lightMark.adequacyValue">
				</app-rating-one-small>
			</div>
			</div>
			<mat-divider class="pd-divider-line-1"></mat-divider>
			<div class="pd-marks-card-body">
			<div class="pd-marks-card-modify-time">
				<mat-icon class="pd-icon" svgIcon="additional-check"></mat-icon>
				<p>
				{{(lightMark.modifyTime | date:'dd.MM.YYYY')}}
				</p>
			</div>
			<p class="pd-marks-card-comment" [ngClass]="{'empty' : !lightMark.comment}">
				{{lightMark.comment ? lightMark.comment : ''}}
			</p>
			</div>
		</div>
	</ng-container>
</ng-template>
