import { Pipe, PipeTransform } from '@angular/core';
import {AbstractEnumPipe} from "@public/pipes/abstract-enum.pipe";

@Pipe({
  name: 'supportCategories'
})
export class SupportCategoriesPipe extends AbstractEnumPipe implements PipeTransform {

  values = [
    'Предложение',
    'Ошибка',
    'Партнерство',
    'Нарушение'
  ];

  transform(value: any, ...args: any[]): any {
    return this.getTransform(value, args);
  }

}
