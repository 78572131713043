import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import {
	BehaviorSubject, fromEvent,
	Subject,
} from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AppUsersFilter } from '@core/services/app-user.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AnySort } from '@utils/any-sort';
import { MatDialog } from '@angular/material/dialog';


@Injectable({
	providedIn: 'root'
})
export class UtilsService implements OnDestroy {
	scrollPrev: number = 0;

	/**
	 * Скролл > 0 оснвоной страницы
	 */
	public hasScrolledMainPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isVisibleHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

	/**
	 * Брейкпойнты
	 */
	public mobileQuery1470px = new BehaviorSubject<boolean>(false);
	public mobileQuery1220px = new BehaviorSubject<boolean>(false);
	public mobileQuery1140px = new BehaviorSubject<boolean>(false);
	public mobileQuery980px = new BehaviorSubject<boolean>(false);
	public mobileQuery900px = new BehaviorSubject<boolean>(false);
	public mobileQuery610px = new BehaviorSubject<boolean>(false);

	unsubscribe: Subject<any> = new Subject<any>();

	constructor(
		private breakpointObserver: BreakpointObserver,
		private dialog: MatDialog
	) {
		this.breakpointObserver
			.observe(['(max-width: 1470px)'])
			.subscribe({
				next: (state) => {
					this.mobileQuery1470px.next(state.matches);
				}
			});

		this.breakpointObserver
			.observe(['(max-width: 1220px)'])
			.subscribe({
				next: (state) => {
					this.mobileQuery1220px.next(state.matches);
				}
			});

		this.breakpointObserver
			.observe(['(max-width: 1140px)'])
			.subscribe({
				next: (state) => {
					this.mobileQuery1140px.next(state.matches);
				}
			});

		this.breakpointObserver
			.observe(['(max-width: 980px)'])
			.subscribe({
				next: (state) => {
					this.mobileQuery980px.next(state.matches);
				}
			});

		this.breakpointObserver
			.observe(['(max-width: 900px)'])
			.subscribe({
				next: (state) => {
					this.mobileQuery900px.next(state.matches);
				}
			});

		this.breakpointObserver
			.observe(['(max-width: 610px)'])
			.subscribe({
				next: (state) => {
					this.mobileQuery610px.next(state.matches);
				}
			});

		fromEvent(document, 'scroll')
			.pipe(
				filter(x => this.dialog.openDialogs.length == 0),
				map(() => document.scrollingElement.scrollTop),
				takeUntil(this.unsubscribe),
			)
			.subscribe({
				next: v => {
					if (v > 0) {
						this.hasScrolledMainPage.next(true);
					} else {
						this.hasScrolledMainPage.next(false);
					}
				}
			});

		fromEvent(document, 'scroll')
			.pipe(
				takeUntil(this.unsubscribe),
				map(() => document.scrollingElement.scrollTop)
			).subscribe({
			next: x => {
				if (x > 200 && x > this.scrollPrev) {
					this.isVisibleHeader.next(true);
				} else {
					this.isVisibleHeader.next(false);
				}
				this.scrollPrev = x;
			}
		});

	}

	/**
	 * Значение фильтра для подходящих специалистов
	 */
	public memberMatchedFilter = new BehaviorSubject<AppUsersFilter>(null);

	/**
	 * Значение сортировки для откликов внутри работы
	 */
	public workRequestsSort = new BehaviorSubject<AnySort>(null);

	/**
	 * Изменение счетчика активных откликов
	 */
	public changeWorkRequestCount = new EventEmitter<any>();

	/**
	 * Событие прокрутки наверх при переходе на новую страницу
	 */
	public shouldScrollTopOnInit = new EventEmitter<any>();

	ngOnDestroy() {
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}
}
