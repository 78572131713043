import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkStateEnum, WorkView } from '@models/work-view';

@Component({
	selector: 'pdw-work-card-short-dialog',
	templateUrl: './work-card-short-dialog.component.html',
})
export class WorkCardShortDialogComponent {

	readonly WORK_STATE = WorkStateEnum;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { title: string, workView: WorkView }
	) {
	}
}
