import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingOneSmallComponent } from '@shared/rating-one-small/rating-one-small.component';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
	declarations: [RatingOneSmallComponent],
	exports: [RatingOneSmallComponent],
	imports: [
		CommonModule,
		MatIconModule,
	]
})
export class RatingOneSmallModule {
}
