import {Component, Input, OnInit} from '@angular/core';
import {YesNo} from "@models/enums";

@Component({
  selector: 'app-bool-value',
  templateUrl: './bool-value.component.html',
})
export class BoolValueComponent {
  @Input() value: boolean;
  @Input() trueMessage: string = null;
  @Input() falseMessage: string = null;
  constructor() { }
}
