import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ImageCroppedEvent, ImageCropperComponent, LoadedImage } from 'ngx-image-cropper';
import { Dimensions } from 'ngx-image-cropper/lib/interfaces';
import { AppUserService } from '@core/services/app-user.service';
import { AppUserDialogData } from '@models/app-user-view';
import { errorTitle } from '@utils/helpers/error-helpers';
import { YesNo } from '@models/enums';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { ImageRequest } from '@requests/image-request';
import { CompanyView } from '@models/company-view';
import { CompanyService } from '@core/services/company.service';
import { TrueLoadingService } from '@core/services/true-loading.service';

export interface CompanyLogoDialogData {
	title?: string;
	message?: string;
	method?: string;
	companyView: CompanyView;
}

@Component({
	selector: 'pdw-user-avatar-dialog',
	templateUrl: './app-user-avatar-dialog.component.html',
	providers: [DestroyService,
		{
			provide: 'deleting',
			useClass: TrueLoadingService
		},
		{
			provide: 'saving',
			useClass: TrueLoadingService
		},
		{
			provide: 'loading',
			useClass: TrueLoadingService
		}
	],
	styleUrls: ['./pdw-avatar-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppUserAvatarDialogComponent implements OnInit {
	isLoadingFile = new BehaviorSubject<boolean>(false);
	imageFile: any;
	imageChangedEvent: any = '';
	croppedImage: any = '';
	originalImage: any = '';
	cropperPosition: any = {};
	private UPLOAD_MAX_FILE_SIZE: number = 20971520;
	hasImage$ = new BehaviorSubject<boolean>(false);
	hasAvatar: YesNo;
	@ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
	@ViewChild('cropperWrapper') cropperWrapper: ElementRef;

	constructor(
		public dialogRef: MatDialogRef<ImageRequest>,
		@Inject(MAT_DIALOG_DATA) public data: AppUserDialogData | CompanyLogoDialogData,
		private appUserService: AppUserService,
		private notificationService: NotificationService,
		private destroy$: DestroyService,
		private renderer: Renderer2,
		private companyService: CompanyService,
		@Inject('deleting') public deleting$: TrueLoadingService,
		@Inject('saving') public saving$: TrueLoadingService,
		@Inject('loading') public loading$: TrueLoadingService
	) {
		this.hasAvatar = ('appUserView' in data ? data.appUserView.hasAvatar : data.companyView.hasLogo) ?? null;
		this.saving$.next(false);
		this.loading$.next(false);
		this.deleting$.next(false);
	}

	ngOnInit(): void {
		if (this.hasAvatar === YesNo.yes) {
			this.isLoadingFile.next(true);
		}

		if ('appUserView' in this.data && this.data.appUserView.hasAvatar === YesNo.yes) {
			this.appUserService.original(this.data.appUserView.id)
				.pipe(
					takeUntil(this.destroy$),
				)
				.subscribe(x => {
					this.imageFile = x.body;
					this.isLoadingFile.next(false);
				});
		}
		if ('companyView' in this.data && this.data.companyView?.hasLogo === YesNo.yes) {
			this.companyService.original(this.data.companyView.id)
				.pipe(
					takeUntil(this.destroy$),
					catchError(() => {
						this.imageFile = null;
						this.isLoadingFile.next(false);
						return of()
					})
				)
				.subscribe(x => {
					this.imageFile = x.body;
					this.isLoadingFile.next(false);
				});
		}

	}

	fileChangeEvent(event: any): void {
		if (event.target.files.length) {
			if (event.target?.files[0]?.size < this.UPLOAD_MAX_FILE_SIZE) {
				const URL = window.URL || window.webkitURL;
				const image = new Image();
				image.src = URL.createObjectURL(event.target.files[0]);
				image.onload = () => {
					if (image.height < 150 || image.width < 150) {
						this.notificationService.showDanger('Минимальное разрешение составляет 150х150')
						return;
					} else {
						this.imageChangedEvent = event;
						this.isLoadingFile.next(true);
						this.hasImage$.next(true);
					}
				}
			} else {
				this.notificationService.showDanger('Вес изображения не должен превышать 20 МБ')
			}
		}
	}

	onImageLoaded(image: LoadedImage) {
		this.originalImage = image.original;
		this.isLoadingFile.next(false);
		this.hasAvatar = YesNo.yes;
		this.hasImage$.next(true);
	}

	onImageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
		this.cropperPosition = event.cropperPosition;
	}

	onCropperReady(event: Dimensions) {
		if ('appUserView' in this.data && this.data?.appUserView.hasAvatar === YesNo.yes && this.data.appUserView.cropperPosition) {
			this.imageCropper.cropper = this.data.appUserView.cropperPosition ?? 0;
			this.imageCropper.crop();
		}

		if ('companyView' in this.data && this.data?.companyView.hasLogo === YesNo.yes && this.data.companyView.cropperPosition) {
			this.imageCropper.cropper = this.data.companyView.cropperPosition;
			this.imageCropper.crop();
		}
	}

	onLoadImageFailed() {
		this.notificationService.showDanger('Ошибка при загрузке изображения!');
	}

	onDelete(): void {
		this.saving$.next(true);
		this.deleting$.next(true);
		if ('appUserView' in this.data) {
			this.appUserService.deleteAvatar(this.data.appUserView.id)
				.pipe(
					takeUntil(this.destroy$),
				)
				.subscribe({
					next: (appUserView) => {
						this.saving$.next(false);
						this.deleting$.next(false);
						this.dialogRef.close(appUserView);
					},
					error: (err) => {
						this.saving$.next(false);
						this.notificationService.showDanger(errorTitle(err));
					}
				});
		} else {
			this.companyService.deleteLogo(this.data.companyView.id)
				.pipe(
					takeUntil(this.destroy$),
				)
				.subscribe({
					next: (companyView) => {
						this.loading$.next(false);
						this.deleting$.next(false);
						this.dialogRef.close({method: 'delete', companyView: companyView});
					},
					error: (err) => {
						this.loading$.next(false);
						this.notificationService.showDanger(errorTitle(err));
					}
				});
		}
	}

	onSave(): void {
		this.saving$.next(true);
		const imageRequest = {
			original: this.originalImage.base64,
			cropped: this.croppedImage,
			cropperPosition: this.cropperPosition
		};

		if ('appUserView' in this.data) {
			this.appUserService.updateAvatar(this.data.appUserView.id, imageRequest)
				.pipe(takeUntil(this.destroy$)).subscribe((result) => {
				this.saving$.next(false);
				this.loading$.next(false);
				this.deleting$.next(false);
				// this.appUserService.setActiveUser({...result, ...imageRequest});
				this.dialogRef.close(result);
			});
		} else {
			this.dialogRef.close({method: 'update', imageRequest: imageRequest });
		}
	}

	fileDropped(files) {
		if (files.length) {
			if (files[0]?.size < this.UPLOAD_MAX_FILE_SIZE) {
				const URL = window.URL || window.webkitURL;
				const image = new Image();
				image.src = URL.createObjectURL(files[0]);
				image.onload = () => {
					if (image.height < 150 || image.width < 150) {
						this.notificationService.showDanger('Минимальное разрешение составляет 150х150')
						return;
					} else {
						this.imageFile = files[0];
						this.isLoadingFile.next(true);
						this.hasImage$.next(true);
					}
				}
			} else {
				this.notificationService.showDanger('Вес изображения не должен превышать 20 МБ')
			}
		}
	}

	onDragleave() {
		this.renderer.setStyle(this.cropperWrapper.nativeElement, 'opacity', 1);
	}

	onDragover() {
		this.renderer.setStyle(this.cropperWrapper.nativeElement, 'opacity', 0.5);
	}

	onDrop() {
		this.renderer.setStyle(this.cropperWrapper.nativeElement, 'opacity', 1);
	}
}
