import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppUserView } from '@models/app-user-view';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppUserService } from '@core/services/app-user.service';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { checkPasswords } from '@utils/check-passwords';
import { ConfirmPassMatcher } from '@utils/errors/confirm-pass-matcher';
import { AuthPipe } from '@public/pipes/auth.pipe';
import { ResetAnyPasswordRequest } from '@requests/reset-any-password-request';
import { takeUntil } from 'rxjs/operators';
import { DestroyService, NotificationService } from '@profdepo-ui/core';
import { passwordPattern } from '@utils/constants';

export interface ChangePassDialogData {
	title: string;
	appUserView: AppUserView;
}

@Component({
	selector: 'pdw-app-user-reset-any-password-dialog',
	templateUrl: './app-user-reset-any-password-dialog.component.html',
	styleUrls: ['./app-user-reset-any-password-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService]
})
export class AppUserResetAnyPasswordDialogComponent implements OnInit {
	changePassForm: FormGroup;
	saving = new BehaviorSubject<boolean>(false);
	checkPasswords = checkPasswords;
	matcher = new ConfirmPassMatcher();

	passwordHide = true;
	passwordConfirmHide = true;
	authPipe = new AuthPipe();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ChangePassDialogData,
		public dialogRef: MatDialogRef<ChangePassDialogData, boolean | AppUserView>,
		private appUserService: AppUserService,
		private notificationService: NotificationService,
		private router: Router,
		private formBuilder: FormBuilder,
		private destroy$: DestroyService
	) {
	}

	ngOnInit(): void {
		this.changePassForm = this.formBuilder.group({
			password: new FormControl('', [
				Validators.required,
				Validators.pattern(passwordPattern)
			]),
			passwordConfirm: new FormControl('', [
				Validators.required,
				Validators.minLength(6),
			]),
		}, {
			validators: [
				this.checkPasswords,
			]
		});

	}

	get password(): AbstractControl {
		return this.changePassForm.get('password');
	}

	get passwordConfirm(): AbstractControl {
		return this.changePassForm.get('passwordConfirm');
	}

	onSubmit({ value, valid }): void {
		if (valid) {
			this.saving.next(true);

			this.appUserService.setNewPasswordForAuthorizedPerson(value.password)
				.pipe(
					takeUntil(this.destroy$),
				)
				.subscribe({
					next: (data) => {
						this.saving.next(false);
						this.dialogRef.close(true);
						this.notificationService.showSuccess('Ваш пароль был успешно изменен');
					},
					error: (err) => {
						console.log(err);
						this.saving.next(false);
						this.notificationService.showDanger(err.error);
					}
				});
		}
	}
}
