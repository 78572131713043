import { AfterViewInit, Component,Inject, OnInit } from "@angular/core";
import { map, takeUntil } from "rxjs/operators";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ComplexFilterHelpers, ComplexFilterResult } from "@utils/helpers/complex-filter-helpers";
import { ComplexFilterBase, ComplexFilterDialogData } from "@utils/helpers/complex-filter-base";
import { DestroyService } from "@profdepo-ui/core";

@Component({
	selector: "pdw-universal-work-filter-dialog",
	templateUrl: "./universal-work-filter-dialog.component.html",
	styleUrls: ["./universal-work-filter-dialog.component.scss"],
	providers: [DestroyService],
})
export class UniversalWorkFilterDialogComponent extends ComplexFilterBase implements OnInit, AfterViewInit {
	constructor(
		public dialogRef: MatDialogRef<UniversalWorkFilterDialogComponent, ComplexFilterResult | boolean>,
		@Inject(MAT_DIALOG_DATA) public trueData: ComplexFilterDialogData,
		private destroy$: DestroyService,
	) {
		super();
	}

	ngOnInit(): void {
		this.initialize(this.trueData);
		this.dialogRef
			.beforeClosed()
			.pipe(
				map(
					() =>
						new ComplexFilterResult(
							this.anyFilter.value,
							this.activeFilters.getValue(),
							this.mainTagFilter.value
						)
				),
				takeUntil(this.destroy$)
			)
			.subscribe((v) => {
				if (!ComplexFilterHelpers.isEqualComplexFiltersResult(v, this.initialComplexFilterResult)) {
					this.dialogRef.close(v);
				}
			});
	}

	onAnyFilterKeyUp(event: KeyboardEvent) {
		if (event.code === "Enter") {
			this.dialogRef.close(new ComplexFilterResult(this.anyFilter.value, this.activeFilters.value));
		}
	}

	/**
	 * Отправляет текущие значения поиска в строку фильтра
	 * @param value форма
	 * @param valid
	 */
	onSubmit({ value, valid }): void {
		this.dialogRef.close(
			new ComplexFilterResult(this.anyFilter.value, this.activeFilters.value, this.mainTagFilter.value)
		);
	}

}
