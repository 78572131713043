<div class="pd-dialog-header">
	<p class="pd-dialog-header-title">{{ data.title }}</p>
	<button class="pd-icon-button" mat-icon-button [mat-dialog-close]="false">
		<mat-icon svgIcon="interface-close"></mat-icon>
	</button>
</div>
<tui-scrollbar class="pd-scrollbar pd-dialog-scroll">
	<div class="pd-dialog-content large-padding">
		<div *ngIf="form">
			<form id="personalForm" [formGroup]="form" (ngSubmit)="onSubmit(form)">
				<div class="pd-dialog-column">
					<div class="pd-dialog-row">
						<!--  Lastname   -->
						<mat-form-field
							class="pd-input-form-field pd-dialog-half-row"
							appearance="fill"
							hideRequiredMarker
						>
							<mat-label>{{ lastname | appUser : "propertyTitle" }}</mat-label>
							<input matInput formControlName="lastname" type="text" />
							<mat-error
								*ngIf="lastname.errors?.maxlength"
								i18n="mat-error@@app-user-personal-dialog-lastname-maxlength"
								>Введите максимум 40 символов!</mat-error
							>
							<mat-error
								*ngIf="lastname.errors?.pattern"
								i18n="mat-error@@app-user-personal-dialog-lastname-pattern"
								>Недопустимые символы!</mat-error
							>
							<mat-error
								*ngIf="lastname.errors?.whitespace"
								i18n="mat-error@@app-user-personal-dialog-lastname-whitespace"
								>Введите символы!</mat-error
							>
							<mat-error *ngIf="lastname.errors?.serverError">
								{{ lastname.errors?.serverError }}
							</mat-error>
						</mat-form-field>
						<!--  Firstname   -->
						<mat-form-field
							class="pd-input-form-field pd-dialog-half-row"
							appearance="fill"
							hideRequiredMarker
						>
							<mat-label>{{ firstname | appUser : "propertyTitle" }}</mat-label>
							<input matInput formControlName="firstname" type="text" />
							<mat-error
								*ngIf="firstname.errors?.maxlength"
								i18n="mat-error@@app-user-personal-dialog-firstname-maxlength"
								>Введите максимум 40 символов!</mat-error
							>
							<mat-error
								*ngIf="firstname.errors?.pattern"
								i18n="mat-error@@app-user-personal-dialog-firstname-pattern"
								>Недопустимые символы!</mat-error
							>
							<mat-error
								*ngIf="firstname.errors?.whitespace"
								i18n="mat-error@@app-user-personal-dialog-firstname-whitespace"
								>Введите символы!</mat-error
							>
							<mat-error *ngIf="firstname.errors?.serverError">
								{{ firstname.errors?.serverError }}
							</mat-error>
						</mat-form-field>
					</div>

					<!--  Middlename   -->
					<mat-form-field class="pd-input-form-field" appearance="fill" hideRequiredMarker>
						<mat-label>{{ middlename | appUser : "propertyTitle" }}</mat-label>
						<input matInput formControlName="middlename" type="text" />
						<mat-error
							*ngIf="middlename.errors?.pattern"
							>Недопустимые символы!</mat-error
						>
						<mat-error
							*ngIf="middlename.errors?.whitespace"
							>Введите символы!</mat-error
						>
						<mat-error *ngIf="middlename.errors?.serverError">
							{{ middlename.errors?.serverError }}
						</mat-error>
					</mat-form-field>

					<!--  Account Number   -->
					<mat-form-field class="pd-input-form-field" appearance="fill" hideRequiredMarker>
						<mat-label>{{ account | appUser : "propertyTitle" }}</mat-label>
						<input matInput type="text" formControlName="account" [maskito]="accountNumberMaskOptions" />
						<mat-error
							*ngIf="account?.errors?.pattern"
							>Некорректное значение</mat-error
						>
						<mat-error
							*ngIf="account?.errors?.required"
							>Обязательное поле</mat-error
						>
						<mat-error *ngIf="account?.errors?.serverError">
							{{ account?.errors?.serverError }}
						</mat-error>
					</mat-form-field>

					<!--  BIK Number   -->
					<mat-form-field class="pd-input-form-field" appearance="fill" hideRequiredMarker>
						<mat-label>{{ bik | appUser : "propertyTitle" }}</mat-label>
						<input matInput type="text" formControlName="bik" [maskito]="bikNumberMaskOptions" />
						<mat-error *ngIf="bik?.errors?.pattern"
							>Некорректное значение</mat-error
						>
						<mat-error *ngIf="bik?.errors?.required"
							>Обязательное поле</mat-error
						>
						<mat-error *ngIf="bik?.errors?.serverError">
							{{ bik?.errors?.serverError }}
						</mat-error>
					</mat-form-field>

					<!--  Account Name   -->
					<mat-form-field class="pd-input-form-field" appearance="fill" hideRequiredMarker>
						<mat-label>{{ accountName | appUser : "propertyTitle" }}</mat-label>
						<input matInput type="text" formControlName="accountName" />
					</mat-form-field>
				</div>
			</form>
		</div>
		<ng-container *ngIf="isRequisitesVerificationError">
			<pdw-alert [alertType]="'danger'" [canClose]="false">
				Расчетный счет не найден. Введите корректные данные
			</pdw-alert>
		</ng-container>
		<ng-container *ngIf="isRequisitesVerificationSuccess">
			<pdw-alert [alertType]="'secondary'" [canClose]="false"> Данные подтверждены и сохранены </pdw-alert>
		</ng-container>
	</div>
</tui-scrollbar>
<div class="pd-dialog-footer align-end">
	<div class="pd-dialog-footer-buttons" *ngIf="form">
		<button mat-raised-button class="pd-button-secondary large" (click)="showNoteDialog()">Пропустить</button>
		<button
			form="personalForm"
			mat-raised-button
			class="pd-button large"
			type="submit"
			[disabled]="saving$ | async"
		>
			<ng-container *ngIf="!(saving$ | async); else isSaving">Сохранить</ng-container>
			<ng-template #isSaving> Сохраняем </ng-template>
		</button>
	</div>
</div>
