import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingThemeDirective } from './rating-theme.directive';


@NgModule({
	declarations: [
		RatingThemeDirective
	],
	exports: [
		RatingThemeDirective
	],
	imports: [
		CommonModule
	]
})
export class RatingThemeModule {
}
