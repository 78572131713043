import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {NewsPostService} from "@core/services/news-post.service";
import {NewsPostView} from "@models/news-post-view";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-landing-news-posts',
  templateUrl: './landing-news-posts.component.html',
  styleUrls: ['./landing-news-posts.component.scss']
})
export class LandingNewsPostsComponent implements OnInit, OnDestroy {
  unsubscribe: Subject<any> = new Subject<any>();
  isLoading = new BehaviorSubject<boolean>(true);
  data = new BehaviorSubject<NewsPostView[]>([]);
  constructor(private newsPostService: NewsPostService) { }

  ngOnInit(): void {
    this.newsPostService.all()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe({
        next: posts => {
          this.data.next(posts);
          this.isLoading.next(false);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }

}
