import { ErrorHandler, InjectionToken, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { LoginComponent } from '@public/pages/login/login.component';

import { ConfirmEmailComponent } from '@public/pages/confirm-email/confirm-email.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/environment';
import { PublicLayoutComponent } from '@public/components/layouts/public-layout/public-layout.component';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE,
	MAT_RIPPLE_GLOBAL_OPTIONS,
	RippleGlobalOptions
} from '@angular/material/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatTooltipDefaultOptions } from '@angular/material/tooltip';
import {
	MAT_MOMENT_DATE_ADAPTER_OPTIONS,
	MomentDateAdapter
} from '@angular/material-moment-adapter';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { RegisterComponent } from '@public/register/register.component';
import { NavMenuListItemsComponent } from '@public/components/layouts/nav-menu-list-items/nav-menu-list-items.component';
import { AdministratorLayoutComponent } from '@public/components/layouts/administrator-layout/administrator-layout.component';
import { ServerErrorInterceptor } from '@core/interceptors/server-error-interceptor';
import { GlobalErrorHandler } from '@utils/errors/global-error-handler';
import {
	NGX_MAT_DATE_FORMATS, NgxMatDateAdapter, NgxMatDateFormats,
	NgxMatDatetimePickerModule,
	NgxMatNativeDateModule,
	NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import {
	NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
	NgxMatMomentAdapter
} from '@angular-material-components/moment-adapter';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { LandingComponent } from '@public/landing/landing.component';
import { AuthLayoutComponent } from '@public/components/layouts/auth-layout/auth-layout.component';
import { MarketLayoutComponent } from '@public/components/layouts/market-layout/market-layout.component';
import { SideMenuComponent } from '@public/components/layouts/side-menu/side-menu.component';
import { MarketHeaderComponent } from '@public/components/layouts/market-header/market-header.component';
import { ForgotPasswordComponent } from '@public/recovery/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@public/recovery/reset-password/reset-password.component';
import { SentConfirmationComponent } from '@public/recovery/sent-confirmation/sent-confirmation.component';
import { TariffsComponent } from '@public/tariffs/tariffs.component';
import { SupportDialogComponent } from '@public/support-dialog/support-dialog.component';
import { GeneralComponent } from '@general/general.component';
import { OAuthComponent } from '@public/pages/oauth/oauth.component';
import {
	CALENDAR_HEADER_FULL,
	PD_CALENDAR_HEADER_TYPE
} from '@shared/material-calendar-header/material-calendar-header.component';
import { LandingNewsPostsComponent } from '@public/landing/landing-news-posts/landing-news-posts.component';
import { LandingNewsPostComponent } from '@public/landing/landing-news-post/landing-news-post.component';
import { AnimationScrollbarDirective } from '@shared/directives/animation-scrollbar.directive';
import { FormsModule } from '@angular/forms';
import { UrlInterceptor } from '@core/interceptors/url.interceptor';
import { NotFoundPageComponent } from '@public/pages/not-found-page/not-found-page.component';
import { UserProfileLinkComponent } from '@public/components/user-profile-link/user-profile-link.component';
import { EMAIL_REGEX, emailRegEx, PASSWORD_REGEX, passwordPattern } from '@utils/constants';
import { FeatureFlagDirective } from '@shared/directives/feature-flag.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MenuAccordionLinkComponent } from '@public/components/ui/menu-accordion-link/menu-accordion-link.component';
import { AvatarModule } from '@shared/pipes/avatar/avatar.module';
import { UserInitialLettersModule } from '@shared/components/ui/user-initial-letters/user-initial-letters.module';
import { ProfessionalCardModule } from '@public/landing/components/professional-card/professional-card.module';
import { CardMatchedEmployeeModule } from '@general/pages/general-work/general-work-matched/components/card-matched-employee/card-matched-employee.module';
import { BannerModule } from '@general/components/ui/banner/banner.module';
import { ENVIRONMENT } from '@core/services/environment.service';
import { SalaryRegisterPageComponent } from '@public/pages/salary-register-page/salary-register-page.component';
import { StepsComponent } from '@public/pages/salary-register-page/components/steps/steps.component';
import { SalaryRegisterEducationFormComponent } from '@public/pages/salary-register-page/components/salary-register-education-form/salary-register-education-form.component';
import { SalaryExperienceFormComponent } from '@public/pages/salary-register-page/components/salary-experience-form/salary-experience-form.component';
import { SalaryRegistrationFormComponent } from '@public/pages/salary-register-page/components/salary-registration-form/salary-registration-form.component';
import { PasswordInputModule } from '@shared/components/ui/password-input/password-input.module';
import { SpecialistLandingComponent } from '@public/pages/specialist-landing/specialist-landing.component';
import { CustomerLandingComponent } from '@public/pages/customer-landing/customer-landing.component';
import { MaskitoModule } from '@maskito/angular';
import { JobSearchLandingComponent } from './public/pages/job-search-landing/job-search-landing.component';
import { JobSearchEngeneeringLandingComponent } from './public/pages/job-search-engeneering-landing/job-search-engeneering-landing.component';

const CUSTOM_MOMENT_FORMATS: NgxMatDateFormats = {
	parse: {
		dateInput: 'DD.MM.YYYY HH:mm',
	},
	display: {
		dateInput: 'DD.MM.YYYY HH:mm',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'MMM',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};
const globalRippleConfig: RippleGlobalOptions = {
	disabled: true,
	animation: {
		enterDuration: 0,
		exitDuration: 0
	}
};

const API_URL = new InjectionToken<string>('api');

const WITHOUT_TIME_MOMENT_FORMATS: NgxMatDateFormats = {
	parse: {
		dateInput: 'DD.MM.YYYY',
	},
	display: {
		dateInput: 'DD.MM.YYYY',
		monthYearLabel: 'MMMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMM YYYY',
	},
};

export let InjectorInstance: Injector;

registerLocaleData(localeRu, 'ru');


export const appCustomTooltipDefaults: MatTooltipDefaultOptions = {
	showDelay: 500,
	hideDelay: 250,
	touchendHideDelay: 250,
};

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		ConfirmEmailComponent,
		PublicLayoutComponent,
		RegisterComponent,
		NavMenuListItemsComponent,
		AdministratorLayoutComponent,
		LandingComponent,
		AuthLayoutComponent,
		MarketLayoutComponent,
		SideMenuComponent,
		MarketHeaderComponent,
		ForgotPasswordComponent,
		ResetPasswordComponent,
		SentConfirmationComponent,
		TariffsComponent,
		SupportDialogComponent,
		GeneralComponent,
		OAuthComponent,
		LandingNewsPostsComponent,
		LandingNewsPostComponent,
		AnimationScrollbarDirective,
		NotFoundPageComponent,
		UserProfileLinkComponent,
		FeatureFlagDirective,
		MenuAccordionLinkComponent,
		SalaryRegisterPageComponent,
		StepsComponent,
		SalaryRegisterEducationFormComponent,
		SalaryExperienceFormComponent,
		SalaryRegistrationFormComponent,
		SpecialistLandingComponent,
		CustomerLandingComponent,
        JobSearchLandingComponent,
        JobSearchEngeneeringLandingComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			// enabled: true,
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		}),
		AppRoutingModule,
		HttpClientModule,
		NgxMatDatetimePickerModule,
		NgxMatTimepickerModule,
		NgxMatNativeDateModule,
		NgxMaterialTimepickerModule.setLocale('ru'),
		FormsModule,
		DragDropModule,
		SharedModule,
		AvatarModule,
		UserInitialLettersModule,
		ProfessionalCardModule,
		CardMatchedEmployeeModule,
		BannerModule,
		PasswordInputModule,
		MaskitoModule,

	],
	providers: [
		{
			provide: ENVIRONMENT,
			useValue: environment
		},
		{
			provide: LOCALE_ID,
			useValue: 'ru'
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UrlInterceptor,
			multi: true
		},
		{
			provide: PASSWORD_REGEX,
			useValue: passwordPattern
		},
		{
			provide: EMAIL_REGEX,
			useValue: emailRegEx
		},
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		{
			provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
			useValue: { useUtc: false }
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: WITHOUT_TIME_MOMENT_FORMATS
		},
		{
			provide: NgxMatDateAdapter,
			useClass: NgxMatMomentAdapter,
			deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		{
			provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
			useValue: { useUtc: false }
		},
		{
			provide: NGX_MAT_DATE_FORMATS,
			useValue: CUSTOM_MOMENT_FORMATS /* NGX_MAT_MOMENT_FORMATS */
		},
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler
		},
		{
			provide: PD_CALENDAR_HEADER_TYPE,
			useValue: CALENDAR_HEADER_FULL
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ServerErrorInterceptor, multi: true
		},
		{
			provide: MAT_RIPPLE_GLOBAL_OPTIONS,
			useValue: globalRippleConfig
		},
		{
			provide: 'WINDOW',
			useValue: window
		},
		{
			provide: API_URL,
			useValue: environment.baseUrl
		}
	],
	exports: [],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private injector: Injector) {
		InjectorInstance = this.injector;
	}
}
