<a
	*ngIf='user'
	class='auth-link'
	routerLink='/profile'
>
	<div
		*ngIf='!user.avatar; else avatar'
		class='auth-stub'
		[style.background-color]='avatarColor(user)'
	>
		<pdw-user-initial-letters [size]='IMAGE_SIZE.SMALL' [user]='user'>

		</pdw-user-initial-letters>
	</div>
	<p>
		{{user.titleShort}}
	</p>
</a>

<ng-template #avatar>
	<img
		[src]='user | avatar | async'
		alt='user avatar'
	/>
</ng-template>
