import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractSkillView} from "../../models/abstract-skill-view";
import {BehaviorSubject} from "rxjs";
import {MatTableDataSource} from "../../public/components/mat-table-data-source";
import {MatDialog} from "@angular/material/dialog";
import {MatSort} from "@angular/material/sort";
import {AppUserType} from "@models/app-user-view";
import {AppUserTypeValuePipe} from "@public/pipes/app-user-type-value.pipe";

@Component({
  selector: 'app-skills-list',
  templateUrl: './skills-list.component.html',
})
export class SkillsListComponent implements AfterViewInit {
  @Input() appUserType: AppUserType;
  // @Input() owner: PersonView | ProjectView | TaskView | QuestionView;
  @Input() type: string;
  @Input() readonly: boolean = false;
  @Input('data')
  set data(value: AbstractSkillView[]) {
    // set the latest value for _data BehaviorSubject
    this._data.next(value);
  };
  get data(): AbstractSkillView[] {
    // get the latest value from _data BehaviorSubject
    return this._data.getValue();
  }
  private _data = new BehaviorSubject<AbstractSkillView[]>([]);
  dataSource: MatTableDataSource<AbstractSkillView>;
  initDisplayedColumns = [
    'title',
  ];
  displayedColumns = new BehaviorSubject<string[]>(this.initDisplayedColumns);
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  appUserTypeValuePipe = new AppUserTypeValuePipe();
  constructor(
    private dialog: MatDialog,
  ) {
    this._data
      .subscribe(x => {
        this.dataSource = new MatTableDataSource(x);
        this.dataSource.sort = this.sort;
      });
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  goChange() {
    // this.dialog.open(SkillsListDialogComponent, {
    //   autoFocus: true,
    //   disableClose: true,
    //   width: '800px',
    //   data: {
    //     owner: this.owner,
    //     type: this.type,
    //     selectedSkills: this.data
    //   }
    // })
    //   .afterClosed()
    //   .pipe(
    //     filter(result => result),
    //   )
    //   .subscribe(x => {
    //     this.data = x;
    //   });
  }
}
