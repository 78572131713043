import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {WorkView} from "@models/work-view";
import {BehaviorSubject, Subject} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {filter} from "rxjs/operators";
import {WorkResultFileView} from "@models/work-result-file-view";
import {WorkResultFileService} from "@core/services/work-result-file.service";

@Component({
  selector: 'app-work-result-files',
  templateUrl: './work-result-files.component.html',
})
export class WorkResultFilesComponent implements OnInit, OnDestroy {
  @Input('workView')
  set workView(value: WorkView) {
    this._workView.next(value);
  };
  get workView(): WorkView {
    return this._workView.getValue();
  }
  private _workView = new BehaviorSubject<WorkView>(null);

  workResultFileDataSource = new MatTableDataSource<WorkResultFileView>();
  unsubscribe: Subject<any> = new Subject<any>();
  constructor(
    private workResultFileService: WorkResultFileService,
  ) { }

  ngOnInit(): void {
    this._workView
      .pipe(
        filter(x => x !== null)
      )
      .subscribe(x => {
        this.workResultFileDataSource = new MatTableDataSource<WorkResultFileView>(x.resultFiles);
      });
  }
  goDownload(workResultFileView: WorkResultFileView): void {
    this.workResultFileService.download(workResultFileView);
  }
  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }
}
