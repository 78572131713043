<pdw-dialog-header title='Верификация'>

</pdw-dialog-header>
<pdw-dialog-body>
	<pdw-alert
		class='snack'
		alertType='success'
		[canClose]='false'
	>
		Верификация успешно пройдена
	</pdw-alert>
</pdw-dialog-body>
<pdw-dialog-footer>
	<button
		mat-raised-button
		class='pd-button'
		mat-dialog-close
	>
		Закрыть
	</button>
</pdw-dialog-footer>
