import {AbstractSkillView} from "@models/abstract-skill-view";

export const SoftSkillMenuTitles = {
  icon: 'school',
  primaryTitleItems: 'Личные качества',
  descriptionItems: 'Все личные качества',
  primaryTitleCreate: 'Создание личного качества',
  descriptionCreate: 'Создание нового личного качества',
  descriptionItem: 'Личное качество',
};
export class SoftSkillView extends AbstractSkillView {
}
