import { Component, Input } from '@angular/core';
import { AppUserService } from '@core/services/app-user.service';

@Component({
	selector: 'app-rating-one-big',
	templateUrl: './rating-big-one.component.html',
	host: { 'class': 'pd-rating-big-one' },
	styleUrls: ['./rating-big-one.component.scss']

})
export class RatingBigOneComponent {
	@Input() rating: number;
	ratingColorClass: (value: number) => string = AppUserService.ratingColorClass;
	constructor() {
	}
}
