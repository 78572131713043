import { Component, Input } from '@angular/core';
import { progressBarAnimations } from '@shared/components/ui/progress-bar/progress-bar.animations';
import { hundredRatingColorClass } from '@utils/functions';

type colorTypes = 'main-blue' | 'main-red' | 'main-orange' | 'main-green' | 'transparent' | 'default-gray';


@Component({
	selector: 'pdw-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss'],
	animations: [progressBarAnimations]
})
export class ProgressBarComponent {

	@Input() value: number;
	@Input() depsColorOnValue: boolean = false;
	@Input() color: colorTypes = 'main-blue';
	@Input() backgroundColor: colorTypes = 'default-gray';

	colorClass(value: number): colorTypes | string {
		if (!this.depsColorOnValue)
			return this.color;

		return hundredRatingColorClass(value);
	}


}
