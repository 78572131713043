import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { AppUserView } from '@models/app-user-view';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ExperienceView } from '@models/experience-view';
import { YesNo } from '@models/enums';
import { diffDate } from '@utils/helpers/date-helpers';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { errorTitle } from '@utils/helpers/error-helpers';
import {
	AppUserExperiencesDialogComponent
} from '@shared/app-user-experiences-dialog/app-user-experiences-dialog.component';
import { pushFakeHistoryState } from '@utils/functions';
import { number2string } from '@utils/helpers/string-helpers';
import { NotificationService } from '@profdepo-ui/core';
import { AppUserService } from '@core/services/app-user.service';

@Component({
	selector: 'pdw-app-user-experiences',
	templateUrl: './app-user-experiences.component.html',
	styleUrls: ['./app-user-experiences.component.scss']
})
export class AppUserExperiencesComponent implements OnInit {
	@Input() canUpdate: boolean;

	@Input()
	set currentUserView(value: AppUserView) {
		this._currentUserView.next(value);
	};

	get currentUserView(): AppUserView {
		return this._currentUserView.getValue();
	}

	protected readonly YesNo = YesNo;

	private _currentUserView = new BehaviorSubject<AppUserView>(null);

	currentAppUserView = new BehaviorSubject<AppUserView>(null);
	experienceDataSource = new MatTableDataSource<ExperienceView>();
	@Output() changed = new EventEmitter<any>();

	constructor(
		private dialog: MatDialog,
		private notificationService: NotificationService,
		@Inject(LOCALE_ID) public locale: string,
		private userService: AppUserService,
		private cdr: ChangeDetectorRef
	) {
		moment.locale(this.locale);
	}

	ngOnInit(): void {
		this._currentUserView
			.pipe(
				filter(x => x !== null)
			)
			.subscribe((appUserView: AppUserView) => {
				this.currentAppUserView.next(appUserView);
				this.experienceDataSource = new MatTableDataSource<ExperienceView>(appUserView.experiences);
			});
	}

	experienceDelta(experience: ExperienceView): string {
		const diff = experience.tillNow === YesNo.yes
			? diffDate(new Date(Date.now()), experience.startDate)
			: diffDate(new Date(experience.endDate), new Date(experience.startDate));
		return this.formatDiff(diff);
	}

	experienceTotalDelta(experiences: ExperienceView[]): string {
		if (experiences && experiences.length) {
			const startExperience = experiences
				.sort((x1, x2) => (x1.startDate > x2.startDate ? 1 : -1))[0];
			const endExperience = experiences
				.sort((x1, x2) => {
					const dt1 = x1.tillNow === YesNo.yes
						? new Date(Date.now())
						: x1.endDate;
					const dt2 = x2.tillNow === YesNo.yes
						? new Date(Date.now())
						: x2.endDate;
					return dt1 < dt2 ? 1 : -1;
				})[0];
			const diff = endExperience.tillNow === YesNo.yes
				? diffDate(new Date(Date.now()), new Date(startExperience.startDate))
				: diffDate(new Date(endExperience.endDate), new Date(startExperience.startDate));
			return this.formatDiff(diff);
		} else {
			return null;
		}
	}

	formatDiff(diff: any): string {
		let words = [];
		if (diff.years) {
			words.push(
				diff.years > 1 ? null : 1,
				moment.duration(diff.years, 'year').locale(this.locale).humanize());
		}
		if (diff.months) {
			words.push(diff.months, number2string(diff.months, ['месяцев', 'месяц', 'месяца']));
		}
		return words.join(' ');
	}

	formatDate(date: string): any {
		const reformedDate = new Date(date);
		return moment.months(reformedDate?.getMonth()) + ' ' + reformedDate?.getFullYear();
	}

	goUpdateExperiences(): void {
		pushFakeHistoryState();

		this.dialog.open(AppUserExperiencesDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				minHeight: '300px',
				panelClass: ['pd-dialog'],
				data: {
					title: 'Опыт работы',
					method: 'update',
					appUserView: this.currentAppUserView.value
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
			)
			.subscribe({
				next: (data) => {
					console.warn('goUpdateHardSkills this.currentAppUserView.value: ', this.currentAppUserView.value);
					this.userService.setActiveUser(data);
					this.currentAppUserView.next(data);

					this.cdr.markForCheck();
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}
}
