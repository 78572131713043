import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardMatchedEmployeeComponent } from './card-matched-employee.component';
import { RouterModule } from '@angular/router';
import { ProfdepoAiBlockModule } from '../profdepo-ai-block/profdepo-ai-block.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AvatarModule } from '@shared/pipes/avatar/avatar.module';
import { RatingOneSmallModule } from '@shared/rating-one-small/rating-one-small.module';
import { ImageAvatarModule } from '@shared/components/ui/image-avatar/image-avatar.module';
import { UserInitialLettersModule } from '@shared/components/ui/user-initial-letters/user-initial-letters.module';


@NgModule({
	declarations: [
		CardMatchedEmployeeComponent
	],
	exports: [
		CardMatchedEmployeeComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		ProfdepoAiBlockModule,
		MatButtonModule,
		MatIconModule,
		MatTooltipModule,
		AvatarModule,
		RatingOneSmallModule,
		ImageAvatarModule,
		UserInitialLettersModule,
	]
})
export class CardMatchedEmployeeModule {
}
