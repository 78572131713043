<div class='chip'>
	<span>
		{{chipData}}
	</span>
	<button (click)='delete()'>
		<mat-icon
			class='chip_close'
			svgIcon='additional-close'
			matPrefix
		></mat-icon>
	</button>
</div>
