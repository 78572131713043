import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from "@angular/forms";
import { BehaviorSubject, of } from "rxjs";
import { AppUserDialogData, AppUserGender, AppUserView } from "@models/app-user-view";
import { AppUserService } from "@core/services/app-user.service";
import { AbstractComponentDirective } from "@shared/abstract-component.directive";
import { catchError, switchMap, take, takeUntil } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { errorTitle } from "@utils/helpers/error-helpers";
import { MaterialCalendarHeaderComponent } from "@shared/material-calendar-header/material-calendar-header.component";
import { isMoment } from "moment/moment";
import { diffDate } from "@utils/helpers/date-helpers";
import { WhitespaceValidator } from "@utils/helpers/validator-helpers";
import { passportNumberRegEx, personNamesRegEx } from "@utils/constants";
import { ConfirmationDialogType, DestroyService, NotificationService } from "@profdepo-ui/core";
import { OnCloseValue } from "@shared/app-user-inn-step-three-confirmation/app-user-inn-step-three-confirmation.component";
import { AppUserInnDialogService } from "@core/services/app-user-inn-dialog.service";
import { AppUserInnStateView, InnStateValue } from "@models/app-user-inn-state-view";
import { StepTwoConfirmationDialogValue } from "@shared/app-user-inn-step-two-confirmation/app-user-inn-step-two-confirmation.component";
import { InnNoteConfirmationDialogValue } from "@shared/app-user-inn-note-confirmation/app-user-inn-note-confirmation.component";
import { MaskitoOptions } from "@maskito/core";

@Component({
	selector: "app-app-user-inn-dialog",
	templateUrl: "./app-user-inn-dialog.component.html",
})
export class AppUserInnDialogComponent extends AbstractComponentDirective implements OnInit {
	form: FormGroup;
	calendarHeader = MaterialCalendarHeaderComponent;
	genders = AppUserGender;
	saving = new BehaviorSubject<boolean>(false);
	maskitoOptions: MaskitoOptions = {
		mask: [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
	};
	isInnVerificationError = false;
	currentDate = new Date();

	constructor(
		public dialogRef: MatDialogRef<AppUserDialogData, boolean | AppUserView>,
		@Inject(MAT_DIALOG_DATA) public data: AppUserDialogData,
		private formBuilder: FormBuilder,
		private appUserService: AppUserService,
		private notificationService: NotificationService,
		private appUserInnDialogService: AppUserInnDialogService,
		private destroy$: DestroyService
	) {
		super();
	}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			firstname: new FormControl(this.data.appUserView.firstname, {
				validators: [
					Validators.maxLength(40),
					Validators.pattern(personNamesRegEx),
					WhitespaceValidator,
					Validators.required,
				],
			}),
			middlename: new FormControl(this.data.appUserView.middlename, {
				validators: [Validators.pattern(personNamesRegEx), WhitespaceValidator],
			}),
			lastname: new FormControl(this.data.appUserView.lastname, {
				validators: [
					Validators.maxLength(40),
					Validators.pattern(personNamesRegEx),
					WhitespaceValidator,
					Validators.required,
				],
			}),
			birthday: new FormControl(this.data.appUserView.birthday, [
				this.comingOfAgeValidator(),
				Validators.required,
			]),
			passportDay: new FormControl(null, [Validators.required]),
			passportNumber: new FormControl(null, {
				validators: [Validators.required, Validators.pattern(passportNumberRegEx)],
			}),
		});
	}

	statusDateText(appUserInnState: AppUserInnStateView): string {
		return appUserInnState && appUserInnState.value === InnStateValue.selfImpolyeed
			? "Статус подтвержден на"
			: "Статус не подтвержден на";
	}

	getStatusDate(appUserInnState: AppUserInnStateView): Date {
		return appUserInnState && appUserInnState.value === InnStateValue.selfImpolyeed
			? new Date(appUserInnState.createTime)
			: this.currentDate;
	}

	get firstname(): FormControl {
		return this.form.get("firstname") as FormControl;
	}

	get middlename(): FormControl {
		return this.form.get("middlename") as FormControl;
	}

	get lastname(): FormControl {
		return this.form.get("lastname") as FormControl;
	}

	get birthday(): FormControl {
		return this.form.get("birthday") as FormControl;
	}

	get userName(): FormControl {
		return this.form.get("userName") as FormControl;
	}

	get passportDay(): FormControl {
		return this.form.get("passportDay") as FormControl;
	}

	get passportNumber(): FormControl {
		return this.form.get("passportNumber") as FormControl;
	}

	comingOfAgeValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value) {
				return null;
			}

			const value: Date = isMoment(control.value) ? control.value.toDate() : control.value;
			const diff = diffDate(new Date(Date.now()), value);

			return diff.years >= 18 ? null : { comingOfAge: true };
		};
	}

	onSubmit({ value, valid }): void {
		if (valid) {
			this.saving.next(true);
			const appUserView = this.filloutRelationships(this.data.appUserView, this.form);
			const passportNumber = this.passportNumber.value.replace(/\s/g, "");
			this.appUserService
				.getAppUserInnFromFio(passportNumber)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: (innValue) => {
						if (innValue) {
							this.appUserInnDialogService
								.showInnVerificationStepTwoDialog(innValue, ConfirmationDialogType.question)
								.afterClosed()
								.pipe(
									switchMap((res) => {
										switch (res) {
											case StepTwoConfirmationDialogValue.noCheck:
												return this.appUserInnDialogService
													.showInnNoteConfirmationDialog(ConfirmationDialogType.question)
													.afterClosed();
											case StepTwoConfirmationDialogValue.check:
												this.goCheckStatus();
												return of(null);
											default:
												return of(null);
										}
									}),
									catchError((err) => {
										this.notificationService.showDanger(errorTitle(err));
										return of(err);
									})
								)
								.subscribe({
									next: (res) => {
										if (res === InnNoteConfirmationDialogValue.check) {
											this.goCheckStatus();
										}
									},
									error: (err) => {
										this.notificationService.showDanger(errorTitle(err));
									},
								});
							this.dialogRef.close(appUserView);
						} else {
							this.isInnVerificationError = true;
						}

						this.saving.next(false);
					},
					error: (err) => {
						this.saving.next(false);
						if (err instanceof HttpErrorResponse) {
							if (err.status === 400) {
								this.filloutFormError(this.form, err);
							} else {
								this.notificationService.showDanger(errorTitle(err));
							}
						}
					},
				});
		}
	}

	goCheckStatus(): void {
		this.appUserService
			.getCheckAppUserInn()
			.pipe(take(1))
			.subscribe((res) => {
				this.appUserInnDialogService
					.showInnVerificationStepThreeDialog(
						this.statusDateText(res),
						this.getStatusDate(res),
						ConfirmationDialogType.question,
						!!res
					)
					.afterClosed()
					.pipe(takeUntil(this.destroy$))
					.subscribe({
						next: (res) => {
							if (res === OnCloseValue.goNext) {
								const appUserView = this.data.appUserView;
								this.appUserInnDialogService.showRequisitesStepFourDialog(appUserView);
							} else if (res === OnCloseValue.update) {
								this.goCheckStatus();
							}
						},
						error: (err) => {
							this.notificationService.showDanger(errorTitle(err));
						},
					});
			});
	}
}
