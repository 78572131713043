import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ExperienceView} from "@models/experience-view";
import {YesNo} from "@models/enums";

@Injectable({
  providedIn: 'root'
})
export class ExperienceService {
  constructor(private http: HttpClient) {
  }

  /**
   * Преобразование string в Date после десериализации
   * @param experienceView опыт
   */
  public static parseDates(experienceView: ExperienceView): ExperienceView {
    experienceView.startDate = new Date(experienceView.startDate);
    experienceView.endDate = experienceView.tillNow === YesNo.yes
      ? null
      : new Date(experienceView.endDate);
    experienceView.createTime = new Date(experienceView.createTime);
    experienceView.modifyTime = new Date(experienceView.modifyTime);
    return experienceView;
  }
}
