import { Pipe, PipeTransform } from '@angular/core';
import {AbstractEnumPipe} from "@public/pipes/abstract-enum.pipe";

@Pipe({
  name: 'phonePurpose'
})
export class PhonePurposePipe extends AbstractEnumPipe implements PipeTransform {
  values = [
    'Сотовый',
    'Домашний',
    'Рабочий',
    'Факс домашний',
    'Факс рабочий',
    'Другой',
  ];
  transform(value: any, ...args: any[]): any {
    return this.getTransform(value, args);
  }
}
