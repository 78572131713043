<pdw-banner
	*ngIf='isNonVerificated'
>
	<h2>
		Получайте в 3 раза больше приглашений на работу с верифицированной учетной записью
	</h2>
	<button
		(click)='openVerificationDialog()'
		mat-raised-button
		class='pd-button'
	>
		Пройти верификацию
	</button>
</pdw-banner>

<pdw-banner *ngIf='isNotAuthorized'>
	<h2>
		{{'Запустите вакансию и найдите своего специалиста'}}
	</h2>
	<a
		routerLink='/register'
		mat-raised-button
		class='pd-button'
	>
		Зарегистрироваться
	</a>
</pdw-banner>

<router-outlet></router-outlet>

