export class AbstractExtensionEnumPipe {
	values = {
		0: 'Zero',
		// 1: "One",
		// 2: "Two",
	}

	public getTransform(value: any, ...args: any[]): any {
		if (typeof value === 'number') {
			return this.values[value];
		} else {
			const keys = [];
			for (const member in value) {
				if (!isNaN(parseInt(member, 10))) {
					keys.push({ key: member, value: this.values[member] });
				}
			}
			return keys;
		}
	}
}
