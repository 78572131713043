import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppUserType } from '@models/app-user-view';

export interface MenuItem {
	icon: string;
	linkTo?: any[];
	text: string;
	permissions?: AppUserType[],
	children?: MenuItem[]
}

export interface SwitcherCommands {
	commands?: string[];
	canSwitch: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class SideMenuService {
	workRequestCounts = new BehaviorSubject<number | null>(null);

	constructor() {
	}

	setWorkRequestCounts(value: number | null): void {
		this.workRequestCounts.next(value);
	}
}
