import {Injectable} from '@angular/core';
import {AbstractSkillService} from "@core/services/abstract-skill.service";

export class SkillsFilter {
  title: string = null;
  code: string = null;
  name: string = null;
  personCount: string = null;
}
@Injectable({
  providedIn: 'root'
})
export class HardSkillService extends AbstractSkillService {
  protected skillsUrl = 'api/HardSkills';
}
