import {Component, Inject, Input, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {WorkService} from "@core/services/work.service";
import {BehaviorSubject, Subject, withLatestFrom} from "rxjs";
import {TuiCarouselComponent} from "@taiga-ui/kit";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {NotificationsRequestService} from "@core/services/notifications-request.service";
import {UtilsService} from "@core/services/utils.service";
import {TUI_IS_MOBILE} from "@taiga-ui/cdk";
import {distinctUntilChanged, filter, finalize, takeUntil} from "rxjs/operators";
import {WorkView} from "@models/work-view";
import {AppUserCategoryWorksDataSource} from "@core/dataSources/app-user-category-works-data-source";

@Component({
  selector: 'pdw-works-category-card-slider',
  templateUrl: './works-category-card-slider.component.html',
})
export class WorksCategoryCardSliderComponent implements OnInit {
  /**
   * Источник данных для получения записей удовлетворяющих критериям поиска
   */
  @Input() dataSource: AppUserCategoryWorksDataSource;

  /**
   * Количество колонок
   * @param value количество колонок
   */
  @Input()
  set columnsCount(value: number) {
    this._columnsCount.next(value);
  };

  get columnsCount(): number {
    return this._columnsCount.getValue();
  }

  private _columnsCount = new BehaviorSubject<number>(null);
  /**
   * Ширина одной карточки специалиста
   * Размер должен быть согласован так, чтобы перенос карточек в браузере
   * при изменении размера окна совпадал с пересчетом количеста колонок
   */
  @Input() cardWidth: number = 391;

  pageIndex = 0;
  pageSize = 10;
  remain = new BehaviorSubject<number>(null);
  unsubscribe: Subject<any> = new Subject<any>();
  loadingData = new BehaviorSubject<boolean>(false);
  loadingNextPage = new BehaviorSubject<boolean>(false);
  @ViewChild('carousel') carousel: TuiCarouselComponent;
  isMobile: boolean;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private workService: WorkService,
    private notificationService: NotificationsRequestService,
    @Inject(LOCALE_ID) public locale: string,
    public utilsService: UtilsService,
    @Inject(TUI_IS_MOBILE) private readonly _isMobile: boolean,
  ) {
    this.isMobile = _isMobile;
  }

  ngOnInit(): void {
    this.loadingData.next(true);

    this._columnsCount
      .pipe(
        filter(x => x !== null && x > 0),
        distinctUntilChanged(),
        withLatestFrom(this.dataSource.data),
      )
      .subscribe(data => {
        this.pageSize = this.calcPageSize(data[0]);
        const p = this.calcPageIndex(data[1].length, this.pageSize);
        this.pageIndex = p.pageIndex;
        if (p.reload) {
          this.loadMore(this.pageIndex, this.pageSize);
        }
        this.loadCount();
      });
  }

  /**
   * Определяет актуальный номер страницы исходя из количества загруженных данных и размера страницы
   * @param dataLength количества загруженных данных
   * @param pageSize размера страницы
   */
  calcPageIndex(dataLength: number, pageSize: number): any {
    const pageIndex = dataLength / pageSize;
    const pageIndexTrunc = Math.floor(pageIndex);

    /**
     * Если вычисленный номер страницы не дотягивает до двух,
     * то значит загрузить только первую страницу
     */
    if (pageIndex < 2.0) {
      return {
        pageIndex: 0,
        reload: dataLength < pageSize
      };
    }

    /**
     * В остальных случаях округлять страницу в меньшую сторону
     */
    return {
      pageIndex: pageIndexTrunc - 1,
      reload: dataLength < pageSize * pageIndexTrunc
    }
  }

  /**
   * Определяет размер страницы для указанного количества колонок
   * @param columnsCount количество колонок
   */
  calcPageSize(columnsCount: number): number {
    if (columnsCount > 2)
      return columnsCount;

    return 3;
  }

  loadCount(): void {
    this.dataSource.loadCount()
      .pipe(
        takeUntil(this.unsubscribe),
      )
      .subscribe(x => {
        const remain = x - (this.pageIndex + 1) * this.pageSize;
        this.remain.next(remain > this.pageSize ? this.pageSize : remain);
      });
  }

  /**
   * Загружает указанную страницу данных указанного размера
   * @param pageIndex номер страницы
   * @param pageSize размер страницы
   */
  load(pageIndex: number, pageSize: number): void {
    this.dataSource.load(pageIndex, pageSize)
      .pipe(
        finalize(() => {
          this.loadingData.next(false);
          this.loadingNextPage.next(false);
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(x => {
        this.loadingData.next(false);
        this.loadingNextPage.next(false);
      });
  }

  /**
   * Загружает указанную страницу данных указанного размера
   * @param pageIndex номер страницы
   * @param pageSize размер страницы
   * @param shouldSwipe Триггер прокрутки слайдера, после загрузки
   * @param carousel Карусель
   */
  loadMore(pageIndex: number, pageSize: number, shouldSwipe = false, carousel: TuiCarouselComponent = null): void {
    this.dataSource.loadMore(pageIndex, pageSize)
      .pipe(
        finalize(() => {
          this.loadingData.next(false);
          this.loadingNextPage.next(false);
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(x => {
        this.loadingData.next(false);
        if (shouldSwipe) {
          carousel.next();
        }
        this.loadingNextPage.next(false);
      });
  }

  /**
   * Загружает следующую страницу данных
   */
  goNextPage(carousel: TuiCarouselComponent, data: WorkView[]): void {
    if (this.remain.value > 0 && carousel.index >= data.length - this.columnsCount) {
      this.loadingNextPage.next(true);
      this.pageIndex++;
      this.loadMore(this.pageIndex, this.pageSize, true, carousel);
      this.loadCount();
    } else {
      carousel.next();
    }
  }

  isNextButtonDisabled(carousel: TuiCarouselComponent, data: WorkView[]): boolean {
    if (this.remain.value <= 0 && carousel.index >= data.length - this.columnsCount)
      return true;

    if (this.loadingNextPage.value)
      return true;

    return false
  }

  /**
   * Загружает данные на свайп слайдера
   * @param carousel
   * @param data
   */
  onSwipe(carousel: TuiCarouselComponent, data: WorkView[]): void {
    if (!this.isMobile)
      return;

    if (this.remain.value > 0 && carousel.index >= data.length - this.columnsCount) {
      this.loadingNextPage.next(true);
      this.pageIndex++;
      this.loadMore(this.pageIndex, this.pageSize);
      this.loadCount();
    }
  }

  /**
   * Отписывает подписки при уничтожении компоненты
   */
  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }
}
