import { isMoment } from 'moment';
import * as moment from 'moment';

export class DateHelpers {
}

export function millisecondsToDiff(value: number): any {
	const seconds = Math.floor(value / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);
	return {
		seconds: seconds - minutes * 60,
		minutes: minutes - hours * 60,
		hours: hours - days * 24,
		days
	}
}

export function diffToString(value: any): string {
	return (value.days > 0
		? [value.days, value.hours, value.minutes, value.seconds]
		: [value.hours, value.minutes, value.seconds])
		.map(x => pad(x))
		.join(':');
}

export function pad(num): string {
	return ('00' + num).slice(-2);
};

export function formatDateToHHmm(date: Date): string {
	const hours = date.getHours();
	const minutes = date.getMinutes();
	return pad(hours) + ':' + pad(minutes);
}

export function formatHHmmToDate(value: string): Date {
	const values = value.split(':', 2);
	return new Date(1970, 0, 1, +values[0], +values[1]);
}

export function zeroTime(date: Date): Date {
	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();
	return new Date(year, month, day, 0, 0, 0, 0);
}

export function lastTime(date: Date): Date {
	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();
	return new Date(year, month, day, 23, 59, 59, 999);
}

export function isValidDate(date): boolean {
	return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
}

// export function dateDayOfWeek(dayOfWeek: CalendarDayOfWeek): number {
//   if (dayOfWeek === CalendarDayOfWeek.sunday)
//     return 0;
//   return dayOfWeek;
// }
export function dateFirstDayOfWeek(date: Date, dayOfWeek: number): Date {
	let currentDay = date.getDay();
	let diff = date.getDate() - (currentDay - dayOfWeek);
	return new Date(date.setDate(diff));
}

export function roundDateToQuarterHour(date: Date): Date {
	let result = new Date(date);
	result.setMilliseconds(Math.round(result.getMilliseconds() / 1000) * 1000);
	result.setSeconds(Math.round(result.getSeconds() / 60) * 60);
	result.setMinutes(Math.round(result.getMinutes() / 15) * 15);
	return result;
}

export function removeTimeZone(date: Date): Date {
	return isMoment(date)
		? date.utcOffset(0, true).toDate()
		: moment(date).utcOffset(0, true).toDate();
}

/**
 * Возвращает количество дней в году
 * @param year номер года
 */
export function daysInYear(year) {
	return ((year % 4 === 0 && year % 100 > 0) || year % 400 == 0) ? 366 : 365;
}

/**
 * Возвращает количество дней в годах между указанными датами
 * @param startDate начальная дата
 * @param endDate конечная дата
 */
export function daysInYears(startDate, endDate) {
	if (startDate.getTime() > endDate.getTime()) {
		const tmp = startDate;
		startDate = endDate;
		endDate = tmp;
	}
	let days = 0;
	for (let i = startDate.getFullYear(); i < endDate.getFullYear(); i++) {
		days += daysInYear(i);
	}
	return days;
}

/**
 * Возвращает количество дней в месяце
 * @param year год
 * @param month месяц
 */
function daysInMonth(year, month) {
	return new Date(year, month, 0).getDate();
}

/**
 * Возвращает количество дней между указанными датами
 * @param startDate начальная дата
 * @param endDate конечная дата
 */
export function daysBetween(startDate, endDate) {
	if (startDate.getTime() > endDate.getTime()) {
		const tmp = startDate;
		startDate = endDate;
		endDate = tmp;
	}
	const day = 1000 * 60 * 60 * 24;
	return Math.floor((endDate.getTime() - startDate.getTime()) / day);
}

export function monthsBetween(startDate, endDate) {
	if (startDate.getTime() > endDate.getTime()) {
		const tmp = startDate;
		startDate = endDate;
		endDate = tmp;
	}
	return (endDate.getMonth() + 12 * endDate.getFullYear()) - (startDate.getMonth() + 12 * startDate.getFullYear());
}

/**
 * Возвращает разницу в годах, месяцах и днях между двумя датами
 * @param startDate начальная дата
 * @param endDate конечная дата
 */
export function diffDate(startDate: Date, endDate: Date) {
	endDate = typeof endDate === 'string' ? new Date(endDate) : endDate;

	if (startDate?.getTime() > endDate?.getTime()) {
		const tmp = startDate;
		startDate = endDate;
		endDate = tmp;
	}
	const startDateYearBegin = new Date(startDate.getFullYear(), 0, 1);
	const endDateYearBegin = new Date(endDate.getFullYear(), 0, 1);
	const daysInStartYear = daysBetween(startDateYearBegin, startDate);
	const daysInEndYear = daysBetween(endDateYearBegin, endDate);

	let months = monthsBetween(startDate, endDate);
	let years = endDate.getFullYear() - startDate.getFullYear();
	if (daysInStartYear > daysInEndYear) {
		years--;
	}
	if (startDate.getDate() > endDate.getDate()) {
		months--;
	}
	let preDate = new Date(startDate.getFullYear(), startDate.getMonth() + months, startDate.getDate());

	return {
		years: years,
		months: months - years * 12,
		days: daysBetween(preDate, endDate)
	};
}

/**
 * Настройки для провайдера даты
 * dateInput: ['MM.YYYY', 'DD.MM.YYYY', 'YYYY']
 */
export const FORMAT_DATES_MONTHS_DAYS = {
	parse: {
		dateInput: ['MM.YYYY', 'DD.MM.YYYY', 'YYYY'],
	},
	display: {
		dateInput: 'MM.YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};
