import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { EMAIL_REGEX, PASSWORD_REGEX, dateMask } from "@utils/constants";
import { AppUserService } from "@core/services/app-user.service";
import { RegistrationRequest } from "@requests/registration-request";
import { AppUserType } from "@models/app-user-view";
import { DestroyService, NotificationService } from "@profdepo-ui/core";
import { catchError, debounceTime,  finalize, map, switchMap, takeUntil } from "rxjs/operators";
import { EMPTY, Observable } from "rxjs";
import { Router } from "@angular/router";
import { TrueLoadingService } from "@core/services/true-loading.service";
import { HttpErrorResponse } from "@angular/common/http";
import { AggregatorService } from "@core/services/aggregator.service";
import { AuthService } from "@core/services/auth.service";
import { CustomerAndSpecialistRegisterForm } from "../customer-landing/customer.landing.form";
import * as moment from "moment";
import { MaskitoOptions } from "@maskito/core";

@Component({
	selector: "pdw-specialist-landing",
	templateUrl: "./specialist-landing.component.html",
	styleUrls: ["./specialist-landing.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialistLandingComponent implements OnInit {
	formGroup: CustomerAndSpecialistRegisterForm = new CustomerAndSpecialistRegisterForm(this.regex, this.emailRegex);
	searchFormgroup: FormGroup;
	allCount$: Observable<number>;
	count$: Observable<number>;
	cities$: Observable<string[]>;

	currentProffession: string;
	currentCity: string;
	maskitoOptions: MaskitoOptions = {
		mask: dateMask,
	};

	constructor(
		private fb: FormBuilder,
		@Inject(PASSWORD_REGEX) private readonly regex: string,
		@Inject(EMAIL_REGEX) private readonly emailRegex: string,
		private userService: AppUserService,
		private destroy$: DestroyService,
		private notificationService: NotificationService,
		private router: Router,
		public loading$: TrueLoadingService,
		private aggregatorService: AggregatorService,
		private authService: AuthService
	) {
		this.searchFormgroup = this.fb.group({
			proffession: new FormControl(""),
			cities: new FormControl(""),
		});
		this.loading$.next(false);
		this.allCount$ = this.aggregatorService.showAllCount().pipe(
			map((count) => count / 1000),
			catchError(async () => 0)
		);
	}
	ngOnInit(): void {
		this.cities$ = this.citiesControl.valueChanges.pipe(
			debounceTime(300),
			switchMap((value) => {
				return this.aggregatorService.filterCity(value);
			})
		);

		this.citiesControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			this.currentCity = value;
		});
	}

	round(count: number): number {
		return Math.floor(count) * 1000;
	}

	get citiesControl() {
		return this.searchFormgroup.controls.cities;
	}

	submit(formGroup: CustomerAndSpecialistRegisterForm): void {
		this.loading$.next(true);
		const birthday = moment(formGroup.birthday.value).format('YYYY-MM-DD');
		const updatedFormValue = { ...formGroup.value, birthday: birthday };
		this.userService
			.registration({
				...updatedFormValue,
				type: AppUserType.specialist,
			} as RegistrationRequest)
			.pipe(
				catchError((error) => {
					this.notificationService.showDanger(
						error instanceof HttpErrorResponse && typeof error.error === "object"
							? error.error.message
							: error
					);
					return EMPTY;
				}),
				finalize(() => {
					this.loading$.next(false);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.notificationService.showSuccess("Пользователь успешно зарегистрирован");
				this.router.navigate(["/sentConfirmation"], {
					state: { afterRegistration: true },
				});
			});
	}

	isAuthorized(): boolean {
		return this.authService.isLoggedIn();
	}

	openlink() {
		this.currentProffession = this.searchFormgroup.controls.proffession.value;
	     if((this.currentProffession && this.currentCity) || this.currentCity) {
			 this.count$ = this.aggregatorService.showAllCount({ any: this.currentProffession, cities: [this.currentCity] });
			 this.loading$.next(true);
			 return;
		 }

		 if(this.currentProffession) {
			 this.count$ = this.aggregatorService.showAllCount({ any: this.currentProffession});
			 this.loading$.next(true);
			 return;
		 }
	}

	goToAgregator() {
		if (this.isAuthorized()) {
			const stateParams = {
				any: this.currentProffession,
				cities: this.currentCity,
			};

			const queryParams = new URLSearchParams();
			queryParams.set("state", JSON.stringify(stateParams));

			const url = "/jobs/careergid?" + queryParams.toString();

			window.open(url, "_blank");
			return;
		}
		window.open("/register");
	}
}
