import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pdw-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogHeaderComponent {
	@Input() title: string = '';
}
