import { toLowerCaseFirstLetter } from '@utils/helpers/string-helpers';

export class ObjectHelpers {
}

export function toLowerCasePropertiesFirstLetter(o: any): any {
	const result = {};
	Object
		.keys(o)
		.forEach((v, i, a) => {
			result[toLowerCaseFirstLetter(v)] = typeof o[v] === 'object' && o[v] !== null
				? toLowerCasePropertiesFirstLetter(o[v])
				: o[v];
		});
	return result;
}

/**
 * Проверка на объект без ключей
 * @param obj
 */
export function isEmptyObject(obj) {
	for (let key in obj) {
		return false;
	}
	return true;
}

type ExpandRecursively<T> = T extends object
	? T extends infer O ? { [K in keyof O]: ExpandRecursively<O[K]> } : never
	: T;

type RemoveNull<T> = ExpandRecursively<{ [K in keyof T]: Exclude<RemoveNull<T[K]>, null> }>

export function removeEmpty<T>(obj: T): RemoveNull<T> {
	return Object.fromEntries(
		Object.entries(obj)
			.filter(([_, v]) => v != null)
			.map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
	) as RemoveNull<T>
}
