import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvDevelopmentDirective } from './env-development.directive';


@NgModule({
	declarations: [
		EnvDevelopmentDirective
	],
	exports: [
		EnvDevelopmentDirective
	],
	imports: [
		CommonModule
	]
})
export class EnvFlagModule {
}
