import { Component, OnDestroy } from '@angular/core';
import { AppUserService } from '@core/services/app-user.service';
import { WorkService } from '@core/services/work.service';
import { LoadingService } from '@core/services/loading.service';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { MatButton } from '@angular/material/button';
import {
	ConfirmationDialogComponent,
	ConfirmationDialogType
} from '@shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { errorTitle } from '@utils/helpers/error-helpers';
import { MatDialog } from '@angular/material/dialog';
import { pushFakeHistoryState } from '@utils/functions';
import { WorkStateChangeManagerBase } from '@shared/work-state-change-manager-base/work-state-change-manager-base';
import { WorkExecutorMarkService } from '@core/services/work-executor-mark.service';
import { WorkRequestService } from '@core/services/work-request.service';
import { NotificationService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-work-states-menu',
	templateUrl: './work-states-menu.component.html',
})
export class WorkStatesMenuComponent extends WorkStateChangeManagerBase implements OnDestroy {
	constructor(
		private appUserService: AppUserService,
		private workService: WorkService,
		private dialog: MatDialog,
		private loadingService: LoadingService,
		private notificationService: NotificationService,
		private workExecutorMarkService: WorkExecutorMarkService,
		private workRequestService: WorkRequestService
	) {
		super(
			appUserService,
			workService,
			dialog,
			loadingService,
			notificationService,
			workExecutorMarkService,
			workRequestService
		);
	}


	get canSeeking(): boolean {
		return !!this.workView.endDate &&
			!!this.workView.startDate;
	}

	isTriggerExist(): boolean {
		return this.isPackaged || this.isDraft || this.canDelete || this.isSeeking;
	}

	goDraft(event: MouseEvent): void {
		event.stopPropagation();
		this._goDraft();
	}

	goSeeking(event: MouseEvent): void {
		event.stopPropagation();
		this._goSeeking();
	}

	goExecutingAwait(event: MouseEvent): void {
		event.stopPropagation();
		this._goExecutingAwait();
	}

	goExecuting(event: MouseEvent): void {
		event.stopPropagation();
		this._goExecuting();
	}

	goDelete(event: MouseEvent): void {
		event.stopPropagation();
		pushFakeHistoryState();

		this.dialog.open(ConfirmationDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Подтверждение',
					message: 'Вы действительно хотите удалить работу',
					value: this.workView.title,
					questionMark: true,
					type: ConfirmationDialogType.question,
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
				switchMap(() => this.workService.delete(this.workView.id)
					.pipe(
						takeUntil(this.unsubscribe)
					)
				)
			)
			.subscribe({
				next: () => {
					this.changed.emit('deleted');
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	/**
	 * Обрабаывает нажатие левой кнопкой мыши на меню карточки (блокирует переход)
	 * @param event
	 * @param menuTriggerButton
	 */
	onMoreClick(event: MouseEvent, menuTriggerButton: MatButton): void {
		menuTriggerButton._elementRef.nativeElement.closest('.pd-work-card-old, .pd-work-card').classList.add('focused');
		event.stopPropagation();
	}

	/**
	 * Сбрасывает класс focused у карточки, после закрытия меню
	 * @param menuTriggerButton
	 */
	onCloseMenu(menuTriggerButton: MatButton): void {
		menuTriggerButton._elementRef.nativeElement.closest('.pd-work-card-old, .pd-work-card').classList.remove('focused');
	}


	ngOnDestroy() {
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}

}
