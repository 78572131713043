import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { FormEventData } from '@public/pages/salary-register-page/salary-register-page.types';
import {
	CALENDAR_HEADER_WITHOUT_DAYS,
	MaterialCalendarHeaderComponent,
	PD_CALENDAR_HEADER_TYPE
} from '@shared/material-calendar-header/material-calendar-header.component';
import { DestroyService } from '@profdepo-ui/core';
import { takeUntil } from 'rxjs/operators';
import { isMoment, Moment } from 'moment/moment';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment/moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { FORMAT_DATES_MONTHS_DAYS } from '@utils/helpers/date-helpers';
import { TrueLoadingService } from '@core/services/true-loading.service';

@Component({
	selector: 'pdw-salary-experience-form',
	templateUrl: './salary-experience-form.component.html',
	styleUrls: ['./salary-experience-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		{ provide: MAT_DATE_FORMATS, useValue: FORMAT_DATES_MONTHS_DAYS },
		{ provide: PD_CALENDAR_HEADER_TYPE, useValue: CALENDAR_HEADER_WITHOUT_DAYS },
	],
})
export class SalaryExperienceFormComponent implements OnInit {

	@Input() index: number;
	@Input() parentForm: FormGroup | FormArray;
	@Input() formGroup: FormGroup;
	@Output() deleteFormGroup: EventEmitter<FormEventData> = new EventEmitter<FormEventData>();

	calendarHeader = MaterialCalendarHeaderComponent;

	constructor(private destroy$: DestroyService) {
	}

	ngOnInit(): void {
		this.tillNowControl.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((value) => {
				if (value) {
					this.endDateControl.disable();
				} else {
					this.endDateControl.enable();
				}
			})

	}

	deleteCurrentGroup(): void {
		this.deleteFormGroup.next({ parentForm: this.parentForm, index: this.index });
	}

	get description(): FormControl {
		return this.formGroup.get('description') as FormControl;
	}

	get tillNowControl(): FormControl {
		return this.formGroup.get('tillNow') as FormControl;
	}

	get company(): FormControl {
		return this.formGroup.get('companyName') as FormControl;
	}

	get position(): FormControl {
		return this.formGroup.get('position') as FormControl;
	}

	get endDateControl(): FormControl {
		return this.formGroup.get('endDate') as FormControl;
	}

	get startDateControl(): FormControl {
		return this.formGroup.get('startDate') as FormControl;
	}

	setMonthAndYear(control: FormControl, normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {

		if (!control.value) {
			control.setValue(moment().toDate());
		}
		const ctrlValue = isMoment(control.value) ? control.value : moment(control.value);

		ctrlValue.month(normalizedMonthAndYear.month());
		ctrlValue.year(normalizedMonthAndYear.year());

		control.setValue(ctrlValue.toDate());
		datepicker.close();
	}
}
