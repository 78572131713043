<ng-container *ngIf='!(loadingData | async); else isLoading'>
	<ng-container *ngIf='{ data: dataSource.data | async, count: dataSource.count | async } as both'>
		<ng-container *ngIf='both.data.length; else emptySource'>
			<button
				*ngIf='!isMobile'
				mat-icon-button
				class='pd-carousel-cards-button prev'
				[disabled]='carousel.index === 0'
				(click)='carousel.prev()'
			>
				<mat-icon
					class='pd-icon'
					[svgIcon]="'additional-chevron-left'"
				></mat-icon>
			</button>
			<tui-carousel
				class='pd-carousel-cards'
				[itemsCount]='columnsCount'
				(indexChange)='onSwipe(carousel, both.data)'
				#carousel
			>
				<ng-container *ngFor='let element of both.data'>
					<pdw-work-card-slider
						class='card mobile'
						*tuiItem
						[commands]="['/works', element.id]"
						[cardType]="canUpdate ? 'client' : 'specialist'"
						[border]='true'
						[workView]='element'
						[appUserView]='activeAppUserView | async'
						(changed)='onStateChange($event)'
					>
					</pdw-work-card-slider>
				</ng-container>
			</tui-carousel>
			<button
				*ngIf='!isMobile'
				mat-icon-button
				class='pd-carousel-cards-button next'
				[disabled]='carousel.index === setNextButtonDisabled(columnsCount, both.data.length, carousel.index)'
				(click)='goNextPage(carousel, both.data)'
			>
				<mat-icon
					class='pd-icon'
					[svgIcon]="'additional-chevron-right'"
				></mat-icon>
			</button>
		</ng-container>
		<ng-template #emptySource>
			<div class='pd-carousel-cards-empty'>
				<h2 class='pd-carousel-cards-empty-title'>Работ пока нет</h2>
				<p class='pd-carousel-cards-empty-subtitle'>Скоро они появятся здесь</p>
			</div>
		</ng-template>
	</ng-container>
</ng-container>
<ng-template #isLoading>
	<mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
</ng-template>
