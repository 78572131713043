import {DataSource} from "@core/dataSources/data-source";
import {WorkView} from "@models/work-view";
import {BehaviorSubject, EMPTY, iif, mergeMap, Observable, of, Subject} from "rxjs";
import {WorkService, WorksFilter} from "@core/services/work.service";
import {CollectionViewer} from "@angular/cdk/collections";
import {map, shareReplay, tap} from "rxjs/operators";
import {ComplexFilterResult} from "@utils/helpers/complex-filter-helpers";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ManagerWorksDataSource implements DataSource<WorkView> {
  private workViewsSubject = new BehaviorSubject<WorkView[]>([]);
  private countSubject = new BehaviorSubject<number>(0);
  private hasNoCompaniesSubject = new Subject<boolean>();

  constructor(
    private workService: WorkService
  ) { }

  data = this.workViewsSubject.asObservable();
  count = this.countSubject.asObservable();
  hasNoCompanies = this.hasNoCompaniesSubject
    .asObservable()
    .pipe(
      shareReplay(1)
    );

  connect(collectionViewer: CollectionViewer): Observable<WorkView[]> {
    return this.workViewsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.workViewsSubject.complete();
    this.countSubject.complete();
  }

  load(worksFilter: WorksFilter, sortDirection: string, pageIndex: number, pageSize: number, dropOne?: boolean, companies?: number): Observable<WorkView[] | any> {
    return this.workService.manager(worksFilter, sortDirection, pageIndex, pageSize,)
      .pipe(
        mergeMap(x => iif(
            () => Array.isArray(x),
            of(x).pipe(
              tap(workViews => {
                this.workViewsSubject.next(workViews);
                this.hasNoCompaniesSubject.next(false);
              })
            ),
            of(x).pipe(
              tap((x) => this.hasNoCompaniesSubject.next(x.hasNoCompanies))
            )
          )
        )
      )
  }

  loadMore(worksFilter: WorksFilter, sortDirection: string, pageIndex: number, pageSize: number): Observable<WorkView[] | any> {
    return this.workService.manager(worksFilter, sortDirection, pageIndex, pageSize)
      .pipe(
        mergeMap(x => iif(
            () => Array.isArray(x),
            of(x).pipe(
              map(xx => xx.filter(x => !this.workViewsSubject.value.some(a => a.id === x.id))),
              tap(workViews => {
                this.workViewsSubject.next(this.workViewsSubject.value.concat(workViews));
                this.hasNoCompaniesSubject.next(false);
              })
            ),
            of(x).pipe(
              tap((x) => this.hasNoCompaniesSubject.next(x.hasNoCompanies))
            )
          )
        )
      )
  }

  loadOne(id: number): Observable<WorkView> {
    const tmp = this.workViewsSubject.value;
    const index = tmp.findIndex(x => x.id === id);
    if (index >= 0) {
      return this.workService.getWorkView(id)
        .pipe(
          tap(x => {
            tmp.splice(index, 1, x);
            this.workViewsSubject.next(tmp);
          })
        );
    }
    return EMPTY;
  }

  loadCount(worksFilter: WorksFilter): Observable<number> {
    let tmpWorksFilter: WorksFilter;

    if (worksFilter instanceof ComplexFilterResult){
      tmpWorksFilter = worksFilter.toWorksFilter();
    } else {
      tmpWorksFilter = worksFilter;
    }

    return this.workService.managerCount(tmpWorksFilter)
      .pipe(
        tap(count => this.countSubject.next(count))
      );
  }
}
