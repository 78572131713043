<ng-container *ngIf='!(loadingData | async); else isLoading'>
	<ng-container *ngIf='{ data: dataSource.data | async, count: dataSource.count | async } as both'>
		<ng-container *ngIf='both.data.length > 0; else emptySource'>
			<div
				class='pd-marks'
				[ngClass]="{'first-page-without-padding' : pageIndex === 0 && (remain | async) <= 0}"
				[ngStyle]="{'align-items': 'stretch'}"
			>
				<app-work-executor-mark-card
					[isShortMode]="true"
					*ngFor='let element of both.data | slice: 0 : (pageIndex + 1) * pageSize'
					[workExecutorMarkView]='element'
				>
				</app-work-executor-mark-card>
			</div>
			<div *ngIf='remain | async as remain; else noRemain'>
				<button
					class='pd-button-additional'
					(click)='goNextAll()'
					*ngIf='remain > 0; else noRemain'
					[disabled]='loadingNextPage | async'
				>
					{{loadingNextPage.value ? 'Загружаем' : 'Показать еще' }}
				</button>
			</div>
			<ng-template #noRemain>
				<ng-container *ngIf='pageIndex !== 0'>
					<button
						class='pd-button-additional'
						(click)='showFirstReview()'
					>
						Показать только последние отзывы
					</button>
				</ng-container>
			</ng-template>

		</ng-container>
		<ng-template #emptySource>
			<h2 class='pd-marks-empty-title'>Отзывов пока нет</h2>
			<p class='pd-marks-empty-subtitle'>Скоро они появятся здесь</p>
		</ng-template>
	</ng-container>
</ng-container>
<ng-template #isLoading>
	<div class='pd-marks-loading'>
		<mat-spinner
			[diameter]='70'
			[strokeWidth]='5'
		></mat-spinner>
	</div>
</ng-template>
