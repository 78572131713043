import { Pipe, PipeTransform } from '@angular/core';
import {AbstractModelPipe} from "@public/pipes/abstract-model.pipe";

@Pipe({
  name: 'company'
})
export class CompanyPipe extends AbstractModelPipe implements PipeTransform {
  properties = [
    {name: 'title', title: 'Наименование', hint: 'Наименование'},
    {name: 'name', title: 'Краткое наименование', hint: 'Краткое наименование организации'},
    {name: 'fullName', title: 'Полное наименование', hint: 'Полное наименование организации'},
    {name: 'description', title: 'Описание', hint: 'Описание организации'},
    {name: 'inn', title: 'ИНН', hint: 'Идентификационный номер налогоплательщика'},
    {name: 'kpp', title: 'КПП', hint: 'Код причины постановки на учет'},
    {name: 'ogrn', title: 'ОГРН', hint: 'Основной государственный регистрационный номер'},
    {name: 'okpo', title: 'ОКПО', hint: 'Код общероссийского классификатора предприятий и организаций'},
    {name: 'emails', title: 'Эл. почта', hint: 'Адреса эл. почты'},
    {name: 'phones', title: 'Телефоны', hint: 'Номера телефонов'},
    {name: 'query', title: 'Поиск', hint: 'Критерий поиска организации'},
    {name: 'url', title: 'Сайт компании', hint: 'Сайт компании'},
  ];
  transform(value: any, type: string): any {
    return this.getTransform(value, type);
  }
  allProperties() {
    return this.properties;
  }
}
