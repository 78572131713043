import {
  Component,
  ElementRef, EventEmitter, Input,
  NgZone,
  OnDestroy,
  OnInit, Output,
  ViewChild
} from '@angular/core';
import {WorkRequestChatMessageView} from "@models/work-request-chat-message-view";
import {TuiScrollbarComponent} from "@taiga-ui/core";
import {BehaviorSubject, Subject} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ChatMessageService} from "@core/services/chat-message.service";
import {distinctUntilChanged, filter, takeUntil} from "rxjs/operators";
import {errorTitle} from "@utils/helpers/error-helpers";
import {WorkRequestView} from "@models/work-request-view";
import {AppUserView} from "@models/app-user-view";
import {NotificationService} from "@profdepo-ui/core";

@Component({
  selector: 'app-work-requests-chat-messenger-form',
  templateUrl: './work-requests-chat-messenger-form.component.html',
})
export class WorkRequestsChatMessengerFormComponent implements OnInit, OnDestroy {
  @Input('oldMessage')
  set oldMessage(value: WorkRequestChatMessageView){
    this._oldMessage.next(value);
  }
  get oldMessage(): WorkRequestChatMessageView {
    return this._oldMessage.value;
  }
  @Input() disabled = false;
  @Input() selectedWorkRequest: WorkRequestView;
  @Input() user: AppUserView;
  @Output() isNotEmpty = new EventEmitter<boolean>();
  @Output() isEdited = new EventEmitter<string>();
  @ViewChild('scrollRef') messagesPlace: TuiScrollbarComponent;
  @ViewChild('messageRef') messageRef: ElementRef;
  @ViewChild('costRef') costRef: ElementRef;

  unsubscribe: Subject<any> = new Subject<any>();
  messageForm: FormGroup;
  private _oldMessage = new BehaviorSubject<WorkRequestChatMessageView>(null);

  constructor(
    private chatMessageService: ChatMessageService,
    private notificationService: NotificationService,
    private zone: NgZone,
    private formBuilder: FormBuilder,
  ) {}
  ngOnInit(): void {
    //создание форм
    this.messageForm = this.formBuilder.group({
      message: new FormControl("", {
        validators: [
          Validators.required,
          Validators.pattern(/[^#\r\n].*/),
        ],
      })
    });
    this.focusMessageRef();
    this._oldMessage.subscribe(x =>{
      if (x) {
        this.message.setValue(x.message);
        this.focusMessageRef();
      }
    });

    if (this.disabled){
      this.message.disable();
    }
  }

  get message(): FormControl {
    return this.messageForm.get('message') as FormControl;
  }

  onSubmit({value, valid}): void {
    if (valid && !this.oldMessage) {
      this.isNotEmpty.emit(true);
      let chatMessage: WorkRequestChatMessageView = new WorkRequestChatMessageView();
      chatMessage.message = this.message.value.replace(/^\n*|\n*$/g, '');
      chatMessage.workRequest = this.selectedWorkRequest;
      chatMessage.createAppUser = this.user;
      chatMessage.modifyAppUser = this.user;
      this.message.reset();
      this.chatMessageService.add(this.selectedWorkRequest.id, chatMessage)
        .pipe(
          takeUntil(this.unsubscribe)
        )
        .subscribe({
          next: v => {
            this.chatMessageService.lastClientAddedMessage.emit(v);
          },
          error: (err) => {
            this.notificationService.showDanger(errorTitle(err));
          }
        });
    }
    if (valid && this.oldMessage) {
      this.chatMessageService.edit(this.oldMessage.id, this.message.value)
        .pipe(
          takeUntil(this.unsubscribe)
        )
        .subscribe({
          next: () => {
            this.isEdited.emit(this.message.value);
            this.messageForm.reset();
          },
          error: (err) =>{
            this.notificationService.showDanger(errorTitle(err));
          }
        });
    }
  }

  onKeydown(event) {
    event.preventDefault();
    return false;
  }

  focusMessageRef() {
    this.zone.onStable
      .pipe(
        filter(() => this.messageRef != undefined),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.messageRef.nativeElement.focus();
      });
  }
  ngOnDestroy() {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }
}
