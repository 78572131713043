export class AbstractEnumPipe {
  values = [
    'Используется',
    'Не используется',
  ];
  public getTransform(value: any, ...args: any[]): any {
    if (typeof value === 'number') {
      return this.values[value];
    } else {
      const keys = [];
      for (const member in value) {
        if (!isNaN(parseInt(member, 10))) {
          // @ts-ignore
          keys.push({key: member, value: this.values[member]});
        }
      }
      return keys;
    }
  }
}
