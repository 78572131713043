import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@core/services/auth.service";
import { RoleService } from "@core/services/role.service";
import { AppUserType } from "@models/app-user-view";

@Injectable({
	providedIn: "root",
})
export class JobSearchGuard {
	constructor(private router: Router, private roleService: RoleService, private auth: AuthService) {}

	canActivate() {
		if (this.auth.isLoggedIn()) {
			if (!this.roleService.hasPermission(AppUserType.none)) {
				this.router.navigate(["/careerConsultant"]);
			}
		}
	}
}