import {Component, EventEmitter, Input, Output} from '@angular/core';
import { UtilsService } from '@core/services/utils.service';

@Component({
  selector: 'company-add-card',
  templateUrl: 'company-add-card.component.html',
  styleUrls: ['./company-add-card.component.scss'],
  host: {'class': 'pd-company-card'},
})
export class CompanyAddCardComponent {
  @Input() isToShow = true;
  @Output() createCompanyEvent = new EventEmitter<void>();

  constructor(public utilsService: UtilsService) {}
  
  goCreate(): void {
	this.createCompanyEvent.emit()
  }
}
