import {AppUserView} from "@models/app-user-view";
import {YesNo} from "@models/enums";

export class ChatMessageView {
  public id: number;
  public message: string;
  public createAppUser: AppUserView;
  public modifyAppUser: AppUserView;
  public createTime: Date;
  public modifyTime: Date;
  public isDeleted: YesNo;
  public isEdited: YesNo;
}
