import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AppUserDialogData, AppUserView } from '@models/app-user-view';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { AbstractComponentDirective } from '@shared/abstract-component.directive';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppUserService } from '@core/services/app-user.service';
import { EducationLevel, EducationView } from '@models/education-view';
import { removeNone } from '@utils/helpers/filter-helpers';
import { takeUntil } from 'rxjs/operators';
import { errorTitle } from '@utils/helpers/error-helpers';
import { sortByTwoField } from '@utils/helpers/array-helpers';
import { NotificationService } from '@profdepo-ui/core';
import { YesNo } from '@models/enums';
import { onlySpaceValidator } from '@utils/functions';

@Component({
	selector: 'app-app-user-educations-dialog',
	templateUrl: './app-user-educations-dialog.component.html',
	styleUrls: ['./app-user-educations-dialog.component.scss']
})
export class AppUserEducationsDialogComponent extends AbstractComponentDirective implements OnInit, OnDestroy {
	educationsForm: FormGroup;
	levels = EducationLevel;
	years: number[];
	saving = new BehaviorSubject<boolean>(false);
	unsubscribe: Subject<any> = new Subject<any>();

	constructor(
		public dialogRef: MatDialogRef<AppUserDialogData, boolean | AppUserView>,
		@Inject(MAT_DIALOG_DATA) public data: AppUserDialogData,
		private formBuilder: FormBuilder,
		private appUserService: AppUserService,
		private notificationService: NotificationService,
	) {
		super();
		this.years = [];
		for (let i = 1950; i <= new Date().getFullYear(); i++) {
			this.years.push(i);
		}
		this.years.reverse();
	}

	ngOnInit(): void {
		removeNone(this.levels);

		this.educationsForm = this.formBuilder.group({
			educations: this.formBuilder.array(
				this.data.appUserView?.educations?.filter(item => item.isCourse == YesNo.no).sort(sortByTwoField('level', 'year', true))
					?.map((v, i, a) => {
						return this.createEducation(i, v)
					}) ?? []
			)
		});

		if (!this.educations.length) {
			this.goAddEducation(0);
		}
	}

	get educations(): FormArray {
		return this.educationsForm.get('educations') as FormArray;
	}

	educationsLevel(index: number): FormControl {
		return this.educations.controls[index].get('level') as FormControl;
	}

	educationsName(index: number): FormControl {
		return this.educations.controls[index].get('name') as FormControl;
	}

	educationsSector(index: number): FormControl {
		return this.educations.controls[index].get('sector') as FormControl;
	}

	educationsSpeciality(index: number): FormControl {
		return this.educations.controls[index].get('speciality') as FormControl;
	}

	educationsYear(index: number): FormControl {
		return this.educations.controls[index].get('year') as FormControl;
	}

	createEducation(index: number, educationView: EducationView): FormGroup {

		const formGroup = this.formBuilder.group({
			id: new FormControl(educationView.id),
			level: new FormControl(educationView.level, {
				validators: [Validators.required],
			}),
			name: new FormControl(educationView.name, {
				validators: [Validators.required, Validators.maxLength(500), onlySpaceValidator()],
			}),
			sector: new FormControl(educationView.sector, [Validators.maxLength(500), onlySpaceValidator()]),
			speciality: new FormControl(educationView.speciality, [Validators.maxLength(500), onlySpaceValidator()]),
			year: new FormControl(educationView.year, {
				validators: [Validators.required],
			}),
			isCourse: [YesNo.no]
		});

		return formGroup;
	}

	goAddEducation(index: number): void {
		const educationView = new EducationView();
		educationView.id = 0;
		this.educations.insert(index, this.createEducation(index, educationView));
		this.educationsForm.updateValueAndValidity();
	}

	goRemoveEducation(index: number): void {
		if (index <= this.educations.length - 1) {
			this.educations.removeAt(index);
		}
	}

	onSubmit({ value, valid }): void {
		if (valid) {
			this.saving.next(true);
			// copy form values to object
			const value = this.educations.value?.map(item => {
				return { ...item, isCourse: YesNo.no }
			})
			let appUserView = Object.assign({}, this.data.appUserView);
			if (appUserView.educations) {
				appUserView.educations = [...appUserView.educations?.filter(item => item.isCourse === YesNo.yes), ...value];

			}
			// update data
			this.appUserService.updateAppUserView(appUserView)
				.pipe(
					takeUntil(this.unsubscribe),
				)
				.subscribe({
					next: (appUserView) => {
						this.saving.next(false);
						this.dialogRef.close(appUserView);
					},
					error: (err) => {
						this.saving.next(false);
						this.notificationService.showDanger(errorTitle(err));
					}
				});
		}
	}

	ngOnDestroy() {
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}
}
