import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageAvatarComponent } from '@shared/components/ui/image-avatar/image-avatar.component';
import { AvatarModule } from '@shared/pipes/avatar/avatar.module';


@NgModule({
	declarations: [ImageAvatarComponent],
	exports: [ImageAvatarComponent],
	imports: [
		CommonModule,
		AvatarModule
	]
})
export class ImageAvatarModule {
}
