<div class="pd-dialog-header">
  <p class="pd-dialog-header-title">{{ data.title }}</p>
  <button class="pd-icon-button" mat-icon-button [mat-dialog-close]="false">
    <mat-icon svgIcon="interface-close"></mat-icon>
  </button>
</div>
<tui-scrollbar class="pd-scrollbar pd-dialog-scroll">
  <div class="pd-invite-dialog-wrapper">
    <div class="pd-invite-dialog-search pd-invite-dialog-search-sm">
      <mat-form-field class="pd-input-form-field" appearance="fill" hideRequiredMarker>
        <mat-icon matPrefix class="pd-field-prefix-icon gray" svgIcon="general-search"></mat-icon>
        <mat-label>Поиск</mat-label>
        <input type="text"
               matInput
               class="pd-chip-input"
               [formControl]="treeFilter">
        <button
          class="pd-icon-button with-line-height gray"
          mat-icon-button
          matSuffix
          matTooltip="Очистить фильтр"
          *ngIf="treeFilter.value"
          (click)="treeFilter.setValue('')"
        >
          <mat-icon class="pd-icon" svgIcon="additional-close"></mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="pd-dialog-content large-padding without-padding-top">
      <div *ngIf="dataSource">
        <ng-container *ngIf="!(emptySourceOnFilter | async); else emptySource">
          <div class="pd-dialog-column">

            <mat-tree class="pd-tree-industry"
                      [dataSource]="dataSource"
                      [treeControl]="treeControl">
              <div class="trest">
                <mat-tree-node
                  class="pd-tree-industry-node"
                  *matTreeNodeDef="let node"
                  [ngClass]="{'hidden': !node.visible}">
                  <pd-checkbox
                    [checked]="isSelected(node)"
                    (change)="selectionToggle(node)"
                    [disabled]="disabled(node)"
                  >
                    {{ node.name }}
                  </pd-checkbox>
                </mat-tree-node>
              </div>
              <mat-tree-node
                class="pd-tree-industry-parent-node"
                *matTreeNodeDef="let node; when: hasChild"
                matTreeNodeToggle
                [ngClass]="{'hidden': !node.visible}">
                {{node.name}}
                <button
                  class="pd-icon-button"
                  mat-icon-button
                  [attr.aria-label]="'Toggle ' + node.name"
                >
                  <mat-icon class="pd-icon" svgIcon="arrow-down-2"></mat-icon>
                </button>
              </mat-tree-node>
            </mat-tree>
          </div>
        </ng-container>
        <ng-template #emptySource>
          <div class="pd-invite-dialog-no-data">
            <mat-icon class="pd-dialog-confirmation-icon"
                      [svgIcon]="'additional-alert-rectangle-100-100'"></mat-icon>
            <p class="pd-invite-dialog-no-data-text">По вашему запросу ничего не найдено</p>
          </div>
        </ng-template>
      </div>

    </div>
  </div>
</tui-scrollbar>
<div class="pd-dialog-footer align-end">
  <div class="pd-dialog-footer-buttons">
    <button mat-raised-button class="pd-button-secondary large" type="reset" [mat-dialog-close]="false">
      Отменить
    </button>
    <button mat-raised-button class="pd-button large" type="submit" [disabled]="(saving | async) || !canSave()"
            (click)="goSave()">
      <ng-container *ngIf="!(saving | async); else isSaving">Сохранить</ng-container>
      <ng-template #isSaving>
        Сохраняем
      </ng-template>
    </button>
  </div>
</div>
