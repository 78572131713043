import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AbstractSkillView, SkillStateValue } from '@models/abstract-skill-view';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay, tap } from 'rxjs/operators';
import { SkillStateView } from '@models/skill-state-view';
import { AbstractModelService } from '@core/services/abstract-model.service';

@Injectable({
	providedIn: 'root'
})
export class AbstractSkillService {
	protected skillsUrl = 'api/Skills';
	private skillViewCache: { id: number, observable: Observable<AbstractSkillView> }[] = [];
	private currentSkillViewSource = new BehaviorSubject<AbstractSkillView>(null);

	constructor(private http: HttpClient, private modelService: AbstractModelService) {
	}

	/**
	 * Возвращает активную специализацию
	 */
	currentSkillView = this.currentSkillViewSource.asObservable();

	/**
	 * Делает активной указанную специализацию
	 * @param id идентификатор специализации
	 */
	changeCurrentSkillView(id: number): Observable<AbstractSkillView> {
		if (id && typeof id === 'number') {
			return this.getSkillView(id)
				.pipe(
					tap(skillView => this.currentSkillViewSource.next(skillView))
				);
		} else {
			console.warn('Invalid type of id argument in changeCurrentSkill', id);
			return of(null);
		}
	}

	/**
	 * Преобразование string в Date после десериализации
	 * @param hardSkillView проект
	 */
	public static parseDates(hardSkillView: AbstractSkillView): AbstractSkillView {
		hardSkillView.createTime = new Date(hardSkillView.createTime);
		hardSkillView.modifyTime = new Date(hardSkillView.modifyTime);
		return hardSkillView;
	}

	/**
	 * Преобразование string в Date после десериализации
	 * @param skillStateView состояние
	 */
	public static parseStateDates(skillStateView: SkillStateView): SkillStateView {
		skillStateView.createTime = new Date(skillStateView.createTime);
		return skillStateView;
	}

	/**
	 * Загружает, кэширует и возвращает специализацию
	 * @param id идентификатор специализации
	 */
	getSkillView(id: number): Observable<AbstractSkillView> {
		if (id) {
			if (!this.skillViewCache || !this.skillViewCache.find(x => x.id === id)) {
				console.warn('getSkillView load from server', id);
				const url = `${this.skillsUrl}/${id}`;
				this.skillViewCache.push({
					id,
					observable: this.http.get<AbstractSkillView>(url, this.modelService.httpOptions)
						.pipe(
							map(x => AbstractSkillService.parseDates(x)),
							shareReplay(1)
						)
				});
			}
			return this.skillViewCache.find(x => x.id === id).observable;
		} else {
			console.warn('Invalid type of id argument in getSkillView', id);
			return of(null);
		}
	}

	/**
	 * Очищает кэш с указанной специализацией
	 * @param id идентификатор специализации для очистки записи в кэше
	 */
	clearSkillView(id: number): void {
		if (id && typeof id === 'number') {
			if (this.skillViewCache) {
				const index = this.skillViewCache.findIndex(x => x.id === id);
				if (index >= 0) {
					this.skillViewCache.splice(index, 1);
				}
			}
		} else {
			console.warn('Invalid type of id argument in clearSkillView', id);
		}
	}

	/**
	 * Добавление новой специализации
	 * @param skillView специализация
	 */
	addSkillView(skillView: AbstractSkillView): Observable<AbstractSkillView> {
		return this.http.post<AbstractSkillView>(this.skillsUrl, skillView, this.modelService.httpOptions)
			.pipe(
				map(x => AbstractSkillService.parseDates(x)),
			);
	}

	/**
	 * Изменение существующей специализации
	 * @param skillView специализация
	 */
	updateSkillView(skillView: AbstractSkillView): Observable<AbstractSkillView> {
		const url = `${this.skillsUrl}/${skillView.id}`;
		return this.http.put<AbstractSkillView>(url, skillView, this.modelService.httpOptions)
			.pipe(
				map(x => AbstractSkillService.parseDates(x)),
				tap(x => this.clearSkillView(x.id))
			);
	}

	callSkillView(method: string, skillView: AbstractSkillView): Observable<AbstractSkillView> {
		if (method === 'create') {
			return this.addSkillView(skillView);
		}
		return this.updateSkillView(skillView);
	}

	states(id: number): Observable<SkillStateView[]> {
		const url = `${this.skillsUrl}/States/${id}`;
		return this.http.get<SkillStateView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => AbstractSkillService.parseStateDates(x))),
			);
	}

	stateChange(id: number, stateValue: SkillStateValue): Observable<boolean> {
		const url = `${this.skillsUrl}/stateChange/${id}/${stateValue}`;
		return this.http.get<boolean>(url, this.modelService.httpOptions);
	}

	filter(query: string): Observable<AbstractSkillView[]> {
		const url = `${this.skillsUrl}/Filter`;
		return this.http.post<AbstractSkillView[]>(url, {filter: query })
			.pipe(
				map(xx => xx.map(x => AbstractSkillService.parseDates(x))),
			);
	}

	/**
	 * Возвращает список первых 50 специализаций, исключая имеющиеся у текущего пользователя
	 */
	firstFiftyUser(): Observable<AbstractSkillView[]> {
		const url = `${this.skillsUrl}/FirstFiftyUser`;
		return this.http.get<AbstractSkillView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => AbstractSkillService.parseDates(x))),
			);
	}

	/**
	 * Возвращает список первых 50 специализаций
	 */
	firstFifty(): Observable<AbstractSkillView[]> {
		const url = `${this.skillsUrl}/FirstFifty`;
		return this.http.get<AbstractSkillView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => AbstractSkillService.parseDates(x))),
			);
	}

	all(): Observable<AbstractSkillView[]> {
		const url = `${this.skillsUrl}/All`;
		return this.http.get<AbstractSkillView[]>(url, this.modelService.httpOptions)
			.pipe(
				map(xx => xx.map(x => AbstractSkillService.parseDates(x))),
			);
	}

	sections(): Observable<string[]> {
		const url = `${this.skillsUrl}/Sections`;
		return this.http.get<string[]>(url, this.modelService.httpOptions);
	}

	skillStateColor(state: SkillStateValue): string | null {
		switch (state) {
			case SkillStateValue.declined:
				return 'warn';
			case SkillStateValue.approved:
				return 'primary';
			case SkillStateValue.created:
				return 'accent'
		}
		return null;
	}
}
