<div class="pd-rating-stars">
  <div *ngIf="showRating">
    <h3 class="pd-rating-stars-number">{{ rating | number:'1.1-1' }}</h3>
  </div>
    <ul class="pd-rating-stars-list" *ngIf="showStars">
      <li *ngFor="let star of allStars" [ngClass]="{ 'active': isActive(star) }">
        <mat-icon class="pd-icon filled-star" *ngIf="isFillStar(star)" svgIcon="additional2-star-filled"></mat-icon>
        <mat-icon class="pd-icon empty-star" *ngIf="isEmptyStar(star)" svgIcon="additional2-star-filled"></mat-icon>
      </li>
    </ul>
</div>
