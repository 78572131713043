import { Component, Input, ViewEncapsulation } from '@angular/core';
import { WorkView } from '@models/work-view';
import { Observable, Subject } from 'rxjs';
import { AppUserService } from '@core/services/app-user.service';
import { AppUserType, AppUserView } from '@models/app-user-view';
import { YesNo } from '@models/enums';
import { WorkRequestState } from '@models/work-request-view';

@Component({
	selector: 'app-work-all-files',
	templateUrl: './work-all-files.component.html',
	styleUrls: ['./work-all-files.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class WorkAllFilesComponent {
	@Input() workView: WorkView;
	activeUser$: Observable<AppUserView>;
	unsubscribe: Subject<any> = new Subject<any>();

	constructor(
		private userService: AppUserService,
	) {
		this.activeUser$ = userService.getActiveUserAsObservable();
	}

	get haveFiles(): boolean {
		return this.workView.files && this.workView.files.length > 0;
	}

	get haveResultFiles(): boolean {
		return this.workView.resultFiles && this.workView.resultFiles.length > 0;
	}

	isClientOrExecutor(user: AppUserView): boolean {
		return user?.type == AppUserType.client ||
			this.workView.specialistAssigned == YesNo.yes &&
			this.workView.requests &&
			this.workView.requests.some(x =>
				x.executor.id === user?.id &&
				x.state === WorkRequestState.accepted);
	}
}
