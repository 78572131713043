import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppUserView } from '@models/app-user-view';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil,} from 'rxjs/operators';
import { pushFakeHistoryState } from '@utils/functions';
import { errorTitle } from '@utils/helpers/error-helpers';
import {
	AppUserSubCategoriesDialogComponent
} from '@shared/app-user-sub-categories-dialog/app-user-sub-categories-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UtilsService } from '@core/services/utils.service';
import { DestroyService, NotificationService } from '@profdepo-ui/core';

@Component({
	selector: 'pdw-app-user-sub-categories',
	templateUrl: './app-user-sub-categories.component.html',
})
export class AppUserSubCategoriesComponent implements OnInit, OnDestroy {
	@Input() canUpdate: boolean;

	@Input()
	set currentUserView(value: AppUserView) {
		this._currentUserView.next(value);
	};

	get currentUserView(): AppUserView {
		return this._currentUserView.getValue();
	}

	private _currentUserView = new BehaviorSubject<AppUserView>(null);

	currentAppUserView = new BehaviorSubject<AppUserView>(null);
	@Output() changed = new EventEmitter<any>();
	unsubscribe: Subject<any> = new Subject<any>();

	constructor(
		public utilsService: UtilsService,
		private dialog: MatDialog,
		private notificationService: NotificationService,
		private destroy$: DestroyService,
	) {
	}

	ngOnInit(): void {
		this._currentUserView
			.pipe(
				filter(x => x !== null),
			)
			.subscribe((appUserView: AppUserView) => {
				this.currentAppUserView.next(appUserView);
			});
	}

	goUpdateSubCategories(): void {
		pushFakeHistoryState();

		this.dialog.open(AppUserSubCategoriesDialogComponent, {
				autoFocus: true,
				disableClose: false,
				width: '581px',
				panelClass: 'pd-dialog',
				data: {
					title: 'Типы работ',
					method: 'update',
					appUserView: this.currentAppUserView.value
				}
			})
			.afterClosed()
			.pipe(
				filter(result => result),
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: () => {
					this.changed.emit('subCategories');
				},
				error: (err) => {
					this.notificationService.showDanger(errorTitle(err));
				}
			});
	}

	ngOnDestroy() {
		this.unsubscribe.next(undefined);
		this.unsubscribe.complete();
	}
}
